<template>
  <div class="text-center">
    <b-spinner v-if="second >= 1" variant="dark" type="grow" label="Spinning" />
    <span v-else class="text-white">.......</span>

    <b-spinner
      v-if="second >= 2"
      variant="warning"
      type="grow"
      label="Spinning"
    />
    <span v-else class="text-white">.......</span>

    <b-spinner v-if="second >= 3" variant="dark" type="grow" label="Spinning" />
    <span v-else class="text-white">.......</span>

    <b-spinner
      v-if="second >= 4"
      variant="warning"
      type="grow"
      label="Spinning"
    />
    <span v-else class="text-white">.......</span>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      second: 0
    };
  },
  mounted() {
    let refreshId = setInterval(() => {
      this.second++;
      if (this.second > 4) {
        this.second = 1;
        clearInterval(refreshId);
      }
    }, 230);
  }
};
</script>

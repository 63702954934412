<template>
  <div id="Example">
    <canvas id="chartCanvas" width="360" style="display: none"></canvas>
  </div>
</template>

<script>
import api from "@/services/api";
import attendanceMixin from "@/mixins/attendace-report-mixin.js"; // Import Chart.js
import generateAttendanceReportPdfMixin from "@/mixins/generate-attendance-report-pdf-mixin.js";

export default {
  mixins: [attendanceMixin, generateAttendanceReportPdfMixin],
  name: "example",
  components: {},
  props: {},
  data() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear();
    // Adding leading zeros if necessary
    const formattedMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
    return {
      data: {},
      cId: this.$route.params.cId,
      comId: this.$route.params.comId,
      accessToken: this.$route.params.accessToken,
      start: "",
      end: `${currentYear}-${formattedMonth}`,
      courseOnlineType: "",
      chartWidth: 360,
      chartHeight: 200,
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      thaiMonths: [
          "มกราคม", "กุมภาพันธ์", "มีนาคม",
          "เมษายน", "พฤษภาคม", "มิถุนายน",
          "กรกฎาคม", "สิงหาคม", "กันยายน",
          "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ],
      pdfOption: {
        orientation: "p",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true,
      },
      pdfConfig: {
        typo: {
          header: 16,
          large: 18,
          normal: 11,
          small: 14,
          chartHeader: 18,
        },
        margin: {
          t: 40,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5,
        },
        endNormal: 14,
        endRow: 18,
        endLegend: 32,
      },
    };
  },
  created() {
    this.generatePdf();
  },
  watch: {},
  methods: {
    async getMember() {
      await api
        .get(`/v1/attendance-report/${this.comId}/coures/${this.cId}/${this.accessToken}`)
        .then(({ data }) => {
          this.data = data.data;
          const startDate = new Date(data.data.courseEnrollStartDate);
          const year = startDate.getFullYear();
          const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Adjust month index to start from 1
          this.start = `${year}-${month}`;
          console.log(this.start);
          this.courseOnlineType = (data.data.courseOnlineType === "ONLINE") ? "คอร์สเรียนแบบออนไลน์" : "คอร์สเรียนแบบผสมผสาน";
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>

<template>
  <div class>
    <b-row>
      <b-col cols="6" class="p-1">
        <div
          class="d-flex flex-column justify-content-center h-100 p-2"
          style="border: 1px solid rgb(200, 200, 200)"
        >
          <span class="text-left">เวลาเรียนเฉลี่ยต่อคน</span>

          <h1 class="text-head pt-4">
            {{ result ? Number(result.avgUser).toFixed(2) : 0 }}
          </h1>
          <span class="text-right">นาที</span>
        </div>
      </b-col>
      <b-col cols="6" class="p-1">
        <div
          class="d-flex flex-column justify-content-center h-100 p-2"
          style="border: 1px solid rgb(200, 200, 200)"
        >
          <span class="text-left">เฉลี่ยเวลาเรียนในหนึ่งวันต่อสัปดาห์</span>

          <h1 class="text-head pt-4">
            {{ result ? Number(result.avgDay).toFixed(2) : 0 }}
          </h1>
          <span class="text-right">ชั่วโมง</span>
        </div>
      </b-col>
      <b-col cols="12" class="p-1">
        <div
          class="d-flex flex-column justify-content-center h-100 p-2"
          style="border: 1px solid rgb(200, 200, 200)"
        >
          <span class="text-left">เปอร์เซ็นต์นักเรียนที่เรียนจบ</span>

          <h1 class="text-head pt-4">
            {{
              result
                ? (
                    (result.complete / (result.complete + result.inComplete)) *
                    100
                  ).toFixed(2)
                : 0
            }}
          </h1>
          <span class="text-right">เปอร์เซ็นต์</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import layout from '@/components/pages/analytics/shared/Layout'
import api from "@/services/api.js";
export default {
  components: {
    // layout,
  },
  props: ["apiRoute"],
  data() {
    return {
      result: [],
      isBusy: true,
      items: [],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.text-head {
  color: rgb(235, 129, 35);
  font-size: 63;
  text-align: center;
  font-weight: bold;
  font-family: "Sarabun", sans-serif;
}
</style>

import async from "@/config/store";

export const participationData = state => {
  return state[async.ASYNC_PARTICIPATION.stateKey];
};

export const participationPending = state => {
  return state[async.ASYNC_PARTICIPATION.loadingKey];
};

export const participationError = state => {
  return state[async.ASYNC_PARTICIPATION.errorKey];
};

export const examAnalyticsData = state => {
  return state[async.ASYNC_EXAM_ANALYTICS.stateKey];
};

export const examAnalyticsPending = state => {
  return state[async.ASYNC_EXAM_ANALYTICS.loadingKey];
};

export const examAnalyticsError = state => {
  return state[async.ASYNC_EXAM_ANALYTICS.errorKey];
};

export const examAnswerSheetsData = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEETS.stateKey];
};

export const examAnswerSheetsPending = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEETS.loadingKey];
};

export const examAnswerSheetsError = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEETS.errorKey];
};

export const examStatData = state => {
  return state[async.ASYNC_EXAM_STAT.stateKey];
};

export const examStatPending = state => {
  return state[async.ASYNC_EXAM_STAT.loadingKey];
};

export const examStatError = state => {
  return state[async.ASYNC_EXAM_STAT.errorKey];
};

export const examInfoData = state => {
  return state[async.ASYNC_EXAM_INFO.stateKey];
};

export const examInfoPending = state => {
  return state[async.ASYNC_EXAM_INFO.loadingKey];
};

export const examInfoError = state => {
  return state[async.ASYNC_EXAM_INFO.errorKey];
};

export const companyExamsData = state => {
  return state[async.ASYNC_COMPANY_EXAMS.stateKey];
};

export const companyExamsPending = state => {
  return state[async.ASYNC_COMPANY_EXAMS.loadingKey];
};

export const companyExamsError = state => {
  return state[async.ASYNC_COMPANY_EXAMS.errorKey];
};

export const examAnswerSheetData = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEET.stateKey];
};

export const examAnswerSheetPending = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEET.loadingKey];
};

export const examAnswerSheetError = state => {
  return state[async.ASYNC_EXAM_ANSWERSHEET.errorKey];
};

export const examMemberData = state => {
  return state[async.ASYNC_EXAM_MEMBER.stateKey];
};

export const examMemberPending = state => {
  return state[async.ASYNC_EXAM_MEMBER.loadingKey];
};

export const examMemberError = state => {
  return state[async.ASYNC_EXAM_MEMBER.errorKey];
};

<template>
  <div style="margin-left: 3em">
    <PageHeader
      style="margin-left: -3em"
      title="รายงานข้อมูลผู้เรียนรายบุคคล"
    ></PageHeader>
    <br />
    <div v-if="!isLoading">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h5 style="font-weight: bold; color: #343a40">รายชื่อผู้เรียน</h5>
            <div name="search">
              <input
                type="text"
                style="margin-right: 2em"
                placeholder="ค้นหารายชื่อ"
                v-model="nameSearch"
              />
              <input
                type="text"
                style="margin-right: 2em"
                placeholder="ค้นหาคอร์ส"
                v-model="courseSearch"
              />
              <b-button
                variant="primary"
                style="margin-right: 0.5em"
                :disabled="
                  (!nameSearch || nameSearch.match(/^ *$/) !== null) &&
                  (!courseSearch || courseSearch.match(/^ *$/) !== null)
                "
                @click="getIndividualStudentData()"
                >ค้นหา</b-button
              >
              <b-spinner style="display: none" id="loadingApiData"></b-spinner>
            </div>
          </b-col>
          <b-col cols="12" class="mt-3">
            <div>
              <b-table
                striped
                hover
                :per-page="perPage"
                :current-page="currentPage"
                small
                :items="individualStudentData"
                :fields="fields"
              >
                <template #cell(สถานะ)="data">
                  <span
                    v-if="data.item.สถานะ == 'ไม่ผ่าน'"
                    :style="`color: red`"
                  >
                    {{ data.item.สถานะ }}
                  </span>
                  <span
                    v-if="data.item.สถานะ == 'ผ่าน'"
                    :style="`color: green`"
                  >
                    {{ data.item.สถานะ }}
                  </span>
                </template>
                <template #cell(cer_id)="data">
                  <router-link
                    :to="`individual-student-report-info?cerId=${data.item.cer_id}`"
                    ><b-button variant="outline-primary"
                      >ดูข้อมูล</b-button
                    ></router-link
                  >
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else><b-spinner></b-spinner></div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api";
export default {
  components: { PageHeader },
  data() {
    return {
      message: null,
      nameSearch: "",
      courseSearch: "",
      isLoading: true,
      individualStudentData: [],
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "ผู้เรียน",
        },
        {
          key: "คอร์สเรียน",
        },
        {
          key: "สถานะ",
        },
        {
          key: "cer_id",
          label: "",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.individualStudentData.length;
    },
  },
  async mounted() {
    await api
      .get(
        `v1/tfac/get-individual-student-data?studentName=${this.nameSearch}&courseName=${this.courseSearch}`
      )
      .then(({ data }) => {
        this.individualStudentData = data;
        this.isLoading = false;
      });
  },
  methods: {
    async getIndividualStudentData() {
      if (this.nameSearch && this.nameSearch.match(/^ *$/) !== null) {
        this.nameSearch = "";
      }
      if (this.courseSearch && this.courseSearch.match(/^ *$/) !== null) {
        this.courseSearch = "";
      }
      document.getElementById("loadingApiData").style.display = "inline-block";
      await api
        .get(
          `v1/tfac/get-individual-student-data?studentName=${this.nameSearch}&courseName=${this.courseSearch}`
        )
        .then(({ data }) => {
          this.individualStudentData = data;
          document.getElementById("loadingApiData").style.display = "none";
        });
    },
  },
};
</script>
<template>
  <div>
    <exam-management-header />
    <exam-management-content />
  </div>
</template>

<script>
import ExamManagementHeader from "@/components/pages/exam-management/ExamManagementHeader.vue";
import ExamManagementContent from "@/components/pages/exam-management/ExamManagementContent.vue";

export default {
  components: {
    ExamManagementHeader,
    ExamManagementContent
  }
};
</script>

<style lang="scss"></style>

import { render, staticRenderFns } from "./IndividualExamRecentAnswerSheetPanel.vue?vue&type=template&id=ab35cee4&scoped=true&"
import script from "./IndividualExamRecentAnswerSheetPanel.vue?vue&type=script&lang=js&"
export * from "./IndividualExamRecentAnswerSheetPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab35cee4",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"title":"คำขอลงทะเบียนใบหน้า"}}),_c('br'),_c('br'),_c('b-container',{attrs:{"fluid":"xl"}},[(!_vm.isTutor || _vm.IsTutorAdmin)?_c('layout',[_c('b-row',{staticClass:"pb-3"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-input',{staticStyle:{"padding":"1rem","display":"inline-block","width":"100%"},attrs:{"placeholder":"ค้นหารายชื่อ"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-select',{attrs:{"options":_vm.options},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('b-table',{attrs:{"id":"face-verification-table","per-page":_vm.perPage,"current-page":_vm.currentPage,"table-class":"tbNumber text-left ","items":_vm.search == null
            ? _vm.result
            : _vm.result.filter((i) =>
                (i.mp_firstName + ' ' + i.mp_lastName).includes(_vm.search)
              ),"fields":_vm.fields,"small":"","sort-icon-left":"","responsive":"","busy":_vm.isBusy,"hover":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item && data.item.mp_firstName + " " + data.item.mp_lastName))])]}},{key:"cell(email)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.m_email ? data.item.m_email : "-"))])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("thaiDate")(data.item && data.item.created_at)))])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{staticClass:"p-2",attrs:{"variant":data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED'
                ? 'warning'
                : data.item.verify_status == 'APPROVED' || data.item.verify_status == 'SYSTEM_APPROVED' || data.item.verify_status == 'ADMIN_APPROVED' || data.item.verify_status == 'AUTO_APPROVED'
                ? 'success'
                : data.item.verify_status == 'REJECTED' && 'danger'}},[_vm._v(_vm._s(data.item.verify_status == "PENDING" ? "รอการตรวจสอบ" : data.item.verify_status == "APPROVED" || data.item.verify_status == "ADMIN_APPROVED" ? "ตรงกัน ยืนยันโดยแอดมิน" : data.item.verify_status == "SYSTEM_APPROVED" ? "ตรงกัน ยืนยันโดยระบบ" : data.item.verify_status == "AUTO_APPROVED" ? "ตรงกัน ยืนยันโดยระบบอัตโนมัติ" : data.item.verify_status == "FORCE_APPROVED" ? "ไม่ตรงกัน ยืนยันโดยระบบ" : data.item.verify_status == "REJECTED" && "ไม่ผ่านการตรวจสอบ"))])]}},{key:"cell(button)",fn:function(data){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-' + data.item.id),expression:"'modal-' + data.item.id"}],attrs:{"variant":data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED' ? 'primary' : 'light',"disabled":data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED'  ? false : true}},[_vm._v(" ตรวจสอบ ")]),_c('b-modal',{attrs:{"size":"xl","id":'modal-' + data.item.id,"title":"ยืนยันตัวตนผู้สมัคร","hide-footer":true}},[_c('VerifyModal',{attrs:{"data":data.item},on:{"posted":function($event){return _vm.fetch()}}})],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}],null,false,1237542713)}),_c('b-pagination',{attrs:{"total-rows":_vm.search == null
            ? _vm.rows
            : _vm.result.filter((i) =>
                (i.mp_firstName + ' ' + i.mp_lastName).includes(_vm.search)
              ).length,"per-page":_vm.perPage,"aria-controls":"face-verification-table","align":"end"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
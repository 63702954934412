<template>
  <div>
    <label>
      จำนวนผู้สมัครเรียน
      <b-icon
        id="applicant-warning"
        icon="info-circle-fill"
        variant="warning"
      ></b-icon>
    </label>
    <b-tooltip target="applicant-warning" placement="right">
      <label>
        การจำกัดจำนวนผู้สมัครเรียน
        ระบบจะซ่อนคอร์สนี้เมื่อมีผู้ชำระเงินสำเร็จถึงจำนวนที่จำกัดไว้
      </label>
    </b-tooltip>
    <div class="d-flex justify-content-baseline align-items-center">
      <b-button-group>
        <b-button
          v-for="(label, index) in btnLabels"
          :key="index"
          class="applicant-tag"
          variant="outline-primary"
          :class="{ active: activeIndex === index }"
          @click="setActive(index)"
        >
          {{ label }}
        </b-button>
      </b-button-group>
      <b-form-input
        v-if="activeIndex == 1"
        v-model="amount"
        class="mx-3"
        type="number"
        min="1"
        :state="applicantState"
        :disabled="onUpdating"
        @blur="editApplicantAmount(amount)"
        @keyup.enter="editApplicantAmount(amount)"
        aria-describedby="input-applicant-amount"
        style="width: 105px;"
      ></b-form-input>
      <p v-if="activeIndex == 1">คน</p>
    </div>
    <div
      v-if="!applicantState && activeIndex == 1"
      style=" margin-left: 25%; margin-top: 3px;"
    >
      <p style="font-size: 12px; color: red;">
        จำนวนผู้สมัครเรียนไม่น้อยกว่า 1 คน
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      activeIndex: null,
      btnLabels: ["ไม่จำกัด", "จำกัด"],
      amount: null,
      onUpdating: false,
      courseId: this.$route.params.courseId,
    };
  },
  mounted() {
    if (this.amount != null) {
      this.activeIndex = 1;
    } else {
      this.activeIndex = 0;
    }
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseInfo",
      "courseAttributeUpdated",
    ]),
    applicantState() {
      return this.amount > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("CourseManagement", ["updateCourseInfo", "getCourseInfo"]),
    setActive(index) {
      this.activeIndex = index;
      if (index == 0) {
        this.editApplicantAmount(null);
      }
    },
    async editApplicantAmount(amount) {
      this.onUpdating = true;
      await this.updateCourseInfo({
        courseId: this.courseId,
        attribute: {
          applicantLimit: amount,
        },
      }).then(() => {
        this.onUpdating = false;
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
      });
    },
  },
  watch: {
    courseInfo: {
      deep: true,
      handler(newCourseInfo) {
        this.amount = newCourseInfo.applicantLimit;
        if (this.amount != null) {
          this.activeIndex = 1;
        } else {
          this.activeIndex = 0;
        }
      },
    },
  },
};
</script>

<style scoped>
p {
  font-family: "prompt";
}

.applicant-tag.active {
  background-color: #027bff;
  color: #fff;
}
</style>

<template>
  <b-container :class="$options.name" fluid class="p-0" style="width: 100%">
    <b-row align-start justify-start column class="list-table">
      <div class="col-md-7">
        <b-form inline>
          <label for="inline-form-input-name">ช่วงวันที่สอบ</label>
          <b-input
            v-model="periodDateFrom"
            type="date"
            class="mx-3"
            :max="periodDateTo"
            @change="handlePeriodDateChange"
          ></b-input>
          <label for="inline-form-input-name">ถึง</label>
          <b-input
            v-model="periodDateTo"
            type="date"
            class="mx-3"
            :max="periodDateToMax"
            @change="handlePeriodDateChange"
          ></b-input>
        </b-form>
      </div>
      <div class="col-md-5">
        <b-input-group class="mb-4 mr-sm-4 mb-sm-0">
          <b-input
            v-model="nameOrEmailSearchText"
            type="search"
            class="ml-3"
            :class="`${$options.name}__search-box`"
            append-icon="search"
            placeholder="ชื่อ/อีเมล ผู้สอบ"
            hide-details
            outline
          ></b-input>
          <b-input
            v-model="examTitleSearchText"
            type="search"
            class="ml-3"
            :class="`${$options.name}__search-box`"
            append-icon="search"
            placeholder="ชื่อข้อสอบ"
            hide-details
            outline
          ></b-input>
          <b-button variant="outline-primary" @click="handleSearchClicked"
            >ค้นหา</b-button
          >
        </b-input-group>
      </div>
    </b-row>

    <div>
      <br />
      <b-table
        :items="dataset"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="examAnswerSheetsPending || companyAnswersheetsCountPending"
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
        sort-icon-left
      >
        <template v-slot:cell(overallResult.score)="props">
          {{ props.item.overallResult.score }} (

          {{
            formatNumber(
              (props.item.overallResult.score /
                props.item.overallResult.fullScore) *
                100
            )
          }}
          %)
        </template>
        <template v-slot:cell(totalTimeUsed)="props">
          {{
            new Date(
              new Date(props.item.markedAt).getTime() -
                calculateTimeUsed(props.item.sections) * 1000
            )
              .toLocaleString("en-GB")
              .split(", ")[1]
          }}
        </template>
        <template v-slot:cell(info)="props">
          <b-button
            variant="primary"
            @click="toExamTakerDetails(props.item._id)"
            >ดูรายละเอียด</b-button
          >
        </template>
        <div slot="table-busy" class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>กำลังโหลด...</strong>
        </div>
      </b-table>
      <b-row>
        <b-form class="mb-2" inline>
          <label class="mb-2 mr-sm-2 mb-sm-0" for="inline-form-input-name"
            >แสดง</label
          >
          <b-form-select
            v-model="perPage"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="pageOptions"
          ></b-form-select>
          จาก{{ totalItems }}
        </b-form>
        <b-col md="10" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="right"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "AnswersheetListTable",
  data() {
    return {
      fields: [
        { label: "ชื่อผู้สอบ", key: "examTakerName", sortable: true },
        { label: "ชื่อข้อสอบ", key: "exam.title", sortable: true },
        { label: "วันที่สอบ", key: "markedAt", sortable: true },
        { label: "เวลาที่เริ่ม", key: "totalTimeUsed" },
        { label: "คะแนน (%)", key: "overallResult.score", sortable: true },
        { label: "รายละเอียด", key: "info" },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      periodDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      periodDateTo: moment().format("YYYY-MM-DD"),
      periodDateToMax: moment().format("YYYY-MM-DD"),
      examTitleSearchText: "",
      nameOrEmailSearchText: "",
      totalItems: 0,
      sort: {
        by: "markedAt",
        desc: true,
      },
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyInfo",
      "examAnswerSheetsData",
      "examAnswerSheetsPending",
      "companyAnswersheetsCountData",
      "companyAnswersheetsCountPending",
    ]),
    dataset() {
      return _.map(this.examAnswerSheetsData, (answerSheet) => {
        answerSheet.markedAt = moment(answerSheet.markedAt)
          .zone("+07:00")
          .format("LLLL");
        return answerSheet;
      });
    },
  },
  watch: {
    sort: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    currentPage: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    perPage: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("Company", [
      "fetchAnswersheets",
      "fetchAnswersheetsCountByCompanyId",
    ]),

    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },
    async getDataFromApi() {
      const payload = {
        companyId: this.companyInfo.id,
        examTitle: this.examTitleSearchText || null,
        nameOrEmail: this.nameOrEmailSearchText || null,
        markedFrom: moment(this.periodDateFrom)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        markedTo: moment(this.periodDateTo)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      let pageRequest = {
        page: this.currentPage,
        rowsPerPage: this.perPage,
        sortBy: this.sort.by,
        descending: this.sort.desc,
      };
      await this.fetchAnswersheetsCountByCompanyId(payload);
      await this.fetchAnswersheets({
        ...payload,
        pagination: pageRequest,
      });
      this.totalItems = this.companyAnswersheetsCountData;
    },
    handlePeriodDateChange() {
      this.presetPeriod = "";

      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
    },
    handleSearchClicked() {
      this.currentPage = 1;
      this.getDataFromApi();
    },
    toExamTakerDetails(answersheetId) {
      this.$router.push({
        name: "analytics-exam-taker-details",
        params: {
          id: answersheetId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.AnswersheetListTable {
  &__search-box {
    margin-right: 0.5em;
  }
}
.list-table {
  padding-top: 1.2em;
}
</style>

<template>
  <div class="">
    <QuestionInfo
      :mainQuestionId="question.id"
      :mainQuestionIndex="questionIndex"
      :questions="question.subQuestions"
      :section="section"
      :sectionIndex="sectionIndex"
      :isSubQuestion="isSubQuestion"
      :isBundle="isBundle"
    />

    <div class="button-create-new mt-4">
      <b-button
        right
        variant="primary"
        no-caret
        active
        @click="
          generateSubQuestion(
            section.id,
            sectionIndex,
            question.id,
            questionIndex
          )
        "
      >
        <i class="fa fa-plus" aria-hidden="true"></i> เพิ่มคำถามย่อย
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    section: Object,
    question: Object,
    questionIndex: Number,
    sectionIndex: Number,
    isDeleteQuestion: Boolean,
    isBundle: Boolean,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      sectionId: "",
      questionId: "",
      questionChoicesNew: [],
      questionType: "MULTIPLE_CHOICE",
      isSubQuestion: true,
    };
  },
  components: {
    QuestionInfo: () => import("./QuestionInfo.vue"),
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exam", "tempQuestion", "tempChoice"]),
  },
  mounted() {},
  methods: {
    ...mapActions("ExamBuilder", [
      "createNewSubQuestion",
      "createChoiceForSubQuestion",
      "addCollapsedQuestions",
      "setQuestionUnanswered",
      "countSectionAndQuestion"
    ]),

    async generateSubQuestion(sectionId, sectionIndex, questionId, questionIndex) {
      const request = {
        body: {
          title: "ข้อสอบ",
          index: this.question.subQuestions.length
        },
        id: this.exam.id,
        sectionId,
        sectionIndex,
        questionId,
        questionIndex,
        token: this.token,
      };

      if (this.isBundle) request.body.score = 0;

      const res = await this.createNewSubQuestion(request);
      this.question.subQuestions.push(res);
      const newSubQuestionIndex = this.question.subQuestions.findIndex(
        (question) => question.id == res.id
      );
      this.addCollapsedQuestions(res.id);
      this.setQuestionUnanswered(res.id);

      setTimeout(() => {
          this.countSectionAndQuestion(this.$route.params.quizExamId);
      }, 500);
      
      const choiceData = {
        body: {
          text: "คำตอบ",
          index: 0,
        },
        questionId,
        subQuestionId: res.id,
        sectionId,
        token: this.token,
        id: this.exam.id,
        sectionIndex,
        questionIndex
      };

      await this.createChoiceForSubQuestion(choiceData);
      this.question.subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);
      await this.createChoiceForSubQuestion({ ...choiceData, body: { ...choiceData.body, index: 1 } });
      this.question.subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);
    }

    
  },
};
</script>

<style lang="scss" scoped>
.button-create-new {
  display: flex;
  justify-content: flex-end;
}
</style>
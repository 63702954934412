<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>รายละเอียดผู้เรียนในคอร์ส</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="
                `/${companyInfo.url}/analytics/courses/${cId}/student-info`
              "
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <b-form-input
                  v-model="search"
                  placeholder="ค้นหารายชื่อ"
                  style="padding: 1rem; display: inline-block; width: 15%"
                >
                </b-form-input>
                <b-button
                  variant="info"
                  @click="fetch()"
                  class="m-2"
                  :disabled="isBusy"
                  >ค้นหา</b-button
                >

                <p style="margin-left: 0.5rem; display: inline-block;" class="course-stats">
                  สถานะการเรียน
                </p>

                <b-form-select
                  id="recent-st"
                  style="margin-left: 1rem; display: inline-block; width: 160px"
                  v-model="setOption"
                  :options="options"
                  @change="fetchSelectStatus"
                ></b-form-select>

                <p style="margin-left: 1rem; display: inline-block;">
                  ค้นหาวันที่หมดอายุ
                </p>
                <b-input
                  id="start-d"
                  style="padding: 1rem; margin-left: 1rem; display: inline-block; width: 170px"
                  v-model="periodDateFrom"
                  type="date"
                  @change="handlePeriodDateChange"
                  :disabled="isBusy"
                  :max="periodDateTo"
                ></b-input>
                <p style="margin-left: 1rem; display: inline-block;">ถึง</p>
                <b-input
                  id="end-d"
                  style="padding: 1rem; margin-left: 1rem; display: inline-block; width: 170px"
                  v-model="periodDateTo"
                  type="date"
                  @change="handlePeriodDateChange"
                  :disabled="isBusy"
                  :max="periodDateToMax"
                ></b-input>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="course-student-info-table"
            :per-page="perPage"
            table-class="tbNumber text-left "
            :items="result.list"
            :fields="checkFields()"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(fullName)="data"
              ><span>{{
                data.item.mp_firstName + " " + data.item.mp_lastName
              }}</span></template
            >
            <template v-slot:cell(email)="data"
              ><span>{{ data.item.m_email }}</span></template
            >
            <template v-slot:cell(staffId)="data"
              ><span>{{ data.item.mp_staffId ? data.item.mp_staffId : "-" }}</span></template
            >
            <template v-slot:cell(phone)="data"
              ><span>{{ data.item.mp_mobilePhone }}</span></template
            >

            <template v-slot:cell(group)="data">
              <span>{{ data.item.g_name ? data.item.g_name : "-" }}</span>
            </template>

            <template v-slot:cell(branch)="data">
              <span>{{ data.item.mp_department }}</span>
            </template>

            <template v-slot:cell(status)="data">
              <span>{{
                data.item.cer_status == "COMPLETED"
                  ? "เรียนจบแล้ว"
                  : data.item.cer_status == "IN_PROGRESS"
                  ? "กำลังเรียน"
                  : data.item.cer_status == "NOT_STARTED"
                  ? "ยังไม่เริ่มเรียน"
                  : data.item.cer_status == "TIMEOUT"
                  ? "หมดเวลาเรียน"
                  : data.item.cer_status == "HOLD"
                  ? "ยังเข้าเรียนไม่ได้"
                  : "-"
              }}</span>
            </template>

            <template v-slot:cell(timeLearned)="data">
              <span style="float: right">{{
                data.item.totalTime
                  ? data.item.totalTime + " ชั่วโมง"
                  : "ไม่จำกัดชั่วโมงเรียน"
              }}</span>
            </template>
            <template v-slot:cell(timeRemain)="data">
              <span style="float: right">{{
                data.item.learnedTime == null && data.item.totalTime != null
                  ? data.item.totalTime
                  : data.item.totalTime != null &&
                    data.item.learnedTime != null &&
                    parseInt(data.item.totalTime) -
                      parseInt(data.item.learnedTime) >=
                      0
                  ? parseInt(data.item.totalTime) -
                    parseInt(data.item.learnedTime) +
                    " ชั่วโมง"
                  : data.item.totalTime != null &&
                    data.item.learnedTime != null &&
                    0 >
                      parseInt(data.item.totalTime) -
                        parseInt(data.item.learnedTime)
                  ? 0 + " ชั่วโมง"
                  : "-"
              }}</span>
            </template>
            <template v-slot:cell(expiration)="data">
              <span style="text-align: center">{{
                data.item.cer_endDate
                  ? formatDate(data.item.cer_endDate)
                  : "ไม่มีวันหมดอายุ"
              }}</span>
            </template>
            <template v-slot:cell(learned)="data">
              <span style="text-align: center"
                >{{ data.item.learnedLecture ? data.item.learnedLecture : 0 }}
                บทเรียน
              </span>
              <span style="text-align: center"
                >{{
                  data.item.learnedTime &&
                  data.item.totalTime &&
                  data.item.learnedTime >= data.item.totalTime
                    ? data.item.totalTime
                    : data.item.learnedTime
                    ? data.item.learnedTime
                    : 0
                }}
                ชั่วโมง
              </span>
            </template>
            <template v-slot:cell(learningPercent)="data">
              <span
                >{{
                  (
                    (data.item.completeLecture / result.totalLecture) *
                    100
                  ).toFixed(2)
                }}% ({{ data.item.completeLecture }} /
                {{ result.totalLecture }})</span
              >
            </template>
            <template v-slot:cell(action)="data">
                <a
                  :href="tableActionPath ? tableActionPath + data.item.cer_id : `/${companyInfo.url}/analytics/courses/${cId}/student-info`"
                  target="_blank"
                  style="border: 1px solid; padding: 4px 5px; border-radius: 3px;"
                >
                  <b-icon icon="file-text-fill" variant="primary"></b-icon>
                </a>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="result.count"
            :per-page="perPage"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import exp from "@/services/export.js";
import api from "@/services/api.js";
import moment from "moment";
import AuthMixin from "@/mixins/auth-mixin.js";
export default {
  components: {
    layout
  },
  mixins: [AuthMixin],
  props: ["apiRoute", "perPage", "tableActionPath"],
  data() {
    return {
      periodDateFrom: "",
      periodDateTo: "",
      periodDateToMax: moment().format("YYYY-MM-DD"),
      options: [
        { value: "", text: "ดูทั้งหมด" },
        { value: "COMPLETED", text: "เรียนจบแล้ว" },
        { value: "IN_PROGRESS", text: "กำลังเรียน" },
        { value: "NOT_STARTED", text: "ยังไม่เริ่มเรียน" },
        { value: "TIMEOUT", text: "หมดเวลาเรียน" },
        { value: "HOLD", text: "ยังเข้าเรียนไม่ได้" }
      ],
      invoiceIssueDateFromSearch: null,
      invoiceIssueDatetoSearch: null,
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      search: "",
      setOption: "",
      searchStatus: "",
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "fullName", label: "ผู้เรียน" },
        { key: "email", label: "อีเมล" },
        { key: "staffId", label: "รหัสพนักงาน" },
        { key: "phone", label: "เบอร์โทร" },
        { key: "group", label: "กลุ่มตําแหน่ง" },
        {
          key: "branch",
          label: "สาขา"
        },
        {
          key: "status",
          label: "สถานะการเรียน"
        },
        {
          key: "timeLearned",
          label: "จำนวนชั่วโมงเรียน",
          class: "text-right"
        },
        {
          key: "timeRemain",
          label: "จำนวนชั่วโมงที่เหลือ",
          class: "text-right"
        },
        {
          key: "expiration",
          label: "วันที่หมดอายุ"
        },
        {
          key: "learned",
          label: "เรียนไปแล้ว"
        },
        {
          key: "learningPercent",
          label: "เปอร์เซ็นต์บทเรียนที่เรียนจบ"
        },
        {
          key: "action",
          label: ""
        }
      ],
      tutorFields: [
        { key: "fullName", label: "ผู้เรียน" },
        { key: "email", label: "อีเมล" },
        { key: "staffId", label: "รหัสพนักงาน" },
        { key: "phone", label: "เบอร์โทร" },
        {
          key: "status",
          label: "สถานะการเรียน"
        },
        {
          key: "timeLearned",
          label: "จำนวนชั่วโมงเรียน",
          class: "text-right"
        },
        {
          key: "timeRemain",
          label: "จำนวนชั่วโมงที่เหลือ",
          class: "text-right"
        },
        {
          key: "expiration",
          label: "วันที่หมดอายุ"
        },
        {
          key: "learned",
          label: "เรียนไปแล้ว"
        },
        {
          key: "learningPercent",
          label: "เปอร์เซ็นต์บทเรียนที่เรียนจบ"
        },
        {
          key: "action",
          label: ""
        }
      ],
      items: []
    };
  },
  watch: {
    currentPage() {
      this.fetch();
    }
  },
  methods: {
    async exportData() {
      let value = [];

      let res = await api.get(
        this.apiRoute +
          "&limit=" +
          this.result.count +
          "&page=" +
          1 +
          "&filter=" +
          this.search +
          "&status=" +
          this.searchStatus +
          "&date=" +
          this.periodDateFrom +
          "&enddate=" +
          this.periodDateTo
      );
      let set = res.data.list;

      set.forEach(i => {
        let temp = {
          name: i.mp_firstName + " " + i.mp_lastName,
          email: i.m_email,
          staffId: i.mp_staffId ? i.mp_staffId : "-",
          tel: i.mp_mobilePhone ? i.mp_mobilePhone : "-",
          group: i.g_name,
          department: i.mp_department ? i.mp_department : "-",
          ...(this.companyInfo.id === 514 ? { about: i.mp_about ? i.mp_about : "-" } : {}),
          status: i.cer_status == "COMPLETED"
                  ? "เรียนจบแล้ว"
                  : i.cer_status == "IN_PROGRESS"
                  ? "กำลังเรียน"
                  : i.cer_status == "NOT_STARTED"
                  ? "ยังไม่เริ่มเรียน"
                  : i.cer_status == "TIMEOUT"
                  ? "หมดเวลาเรียน"
                  : i.cer_status == "HOLD"
                  ? "ยังเข้าเรียนไม่ได้"
                  : "-",
          totalTime: i.totalTime
            ? i.totalTime + " ชั่วโมง"
            : "ไม่จำกัดชั่วโมงเรียน",
          remainingTime:
            i.learnedTime == null && i.totalTime != null
              ? i.totalTime
              : i.totalTime != null &&
                i.learnedTime != null &&
                parseInt(i.totalTime) - parseInt(i.learnedTime) >= 0
              ? parseInt(i.totalTime) - parseInt(i.learnedTime) + " ชั่วโมง"
              : i.totalTime != null &&
                i.learnedTime != null &&
                0 > parseInt(i.totalTime) - parseInt(i.learnedTime)
              ? 0 + " ชั่วโมง"
              : "-",
          expire: i.cer_endDate
            ? this.formatDate(i.cer_endDate)
            : "ไม่มีวันหมดอายุ",
          complete: i.learnedLecture
            ? i.learnedLecture +
              " บทเรียน " +
              ((i.learnedTime && i.totalTime && i.learnedTime >= i.totalTime) ? i.totalTime : (i.learnedTime || 0)) +
              " ชั่วโมง"
            : 0 + " บทเรียน",
          percent:
            ((i.completeLecture / this.result.totalLecture) * 100).toFixed(2) +
            " %"
        };
        let tutortemp = {
          name: i.mp_firstName + " " + i.mp_lastName,
          email: i.m_email,
          staffId: i.mp_staffId ? i.mp_staffId : "-",
          tel: i.mp_mobilePhone ? i.mp_mobilePhone : "-",
          group: i.g_name,
          department: i.mp_department ? i.mp_department : "-",
          ...(this.companyInfo.id === 514 ? { about: i.mp_about ? i.mp_about : "-" } : {}),
          status: i.cer_status == "COMPLETED"
                  ? "เรียนจบแล้ว"
                  : i.cer_status == "IN_PROGRESS"
                  ? "กำลังเรียน"
                  : i.cer_status == "NOT_STARTED"
                  ? "ยังไม่เริ่มเรียน"
                  : i.cer_status == "TIMEOUT"
                  ? "หมดเวลาเรียน"
                  : i.cer_status == "HOLD"
                  ? "ยังเข้าเรียนไม่ได้"
                  : "-",
          totalTime: i.totalTime
            ? i.totalTime + " ชั่วโมง"
            : "ไม่จำกัดชั่วโมงเรียน",
          remainingTime:
            i.learnedTime == null && i.totalTime != null
              ? i.totalTime
              : i.totalTime != null &&
                i.learnedTime != null &&
                parseInt(i.totalTime) - parseInt(i.learnedTime) >= 0
              ? parseInt(i.totalTime) - parseInt(i.learnedTime) + " ชั่วโมง"
              : i.totalTime != null &&
                i.learnedTime != null &&
                0 > parseInt(i.totalTime) - parseInt(i.learnedTime)
              ? 0 + " ชั่วโมง"
              : "-",
          expire: i.cer_endDate
            ? this.formatDate(i.cer_endDate)
            : "ไม่มีวันหมดอายุ",
          complete:
            (i.learnedLecture
              ? i.learnedLecture + " บทเรียน"
              : 0 + " บทเรียน") +
            " " +
            ((i.learnedTime && i.totalTime && i.learnedTime >= i.totalTime) ? i.totalTime : (i.learnedTime || 0)) +
             " ชั่วโมง",

          percent:
            ((i.completeLecture / this.result.totalLecture) * 100).toFixed(2) +
            "% " +
            "(" +
            i.completeLecture +
            "/" +
            this.result.totalLecture +
            ")"
        };
        if (this.isTutor == true) {
          temp = tutortemp;
        }
        value.push(temp);
      });

      let key = [
        "ผู้เรียน",
        "อีเมล",
        "รหัสพนักงาน",
        "เบอร์โทร",
        "กลุ่มตําแหน่ง",
        "สาขา",
        ...(this.companyInfo.id === 514 ? ["ตำแหน่ง"] : []),
        "สถานะการเรียน",
        "จำนวนชั่วโมงเรียน",
        "จำนวนชั่วโมงที่เหลือ",
        "วันที่หมดอายุ",
        "เรียนไปแล้ว",
        "เปอร์เซ็นต์บทเรียนที่เรียนจบ"
      ];
      let tutorKey = [
        "ผู้เรียน",
        "อีเมล",
        "รหัสพนักงาน",
        "เบอร์โทร",
        "กลุ่มตําแหน่ง",
        "สาขา",
        ...(this.companyInfo.id === 514 ? ["ตำแหน่ง"] : []),
        "สถานะการเรียน",
        "จำนวนชั่วโมงเรียน",
        "จำนวนชั่วโมงที่เหลือ",
        "วันที่หมดอายุ",
        "เรียนไปแล้ว",
        "เปอร์เซ็นต์บทเรียนที่เรียนจบ"
      ];
      if (this.isTutor == true) {
        key = tutorKey;
      }
      let filename =
        "Individual-Course-Learner-Student-Info-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    formatDate: function(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
    checkFields() {
      if (this.isTutor == true) {
        return this.tutorFields;
      } else {
        return this.fields;
      }
    },
    async fetchSelectStatus() {
      this.searchStatus = await this.setOption.toString();
      this.fetch();
    },
    handlePeriodDateChange() {
      if (this.periodDateFrom != "" && this.periodDateTo != "") {
        this.fetch();
      } else if (this.periodDateFrom == "" && this.periodDateTo == "") {
        this.fetch();
      } else {
        return;
      }
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(
        this.apiRoute +
          "&limit=" +
          this.perPage +
          "&page=" +
          this.currentPage +
          "&filter=" +
          this.search +
          "&status=" +
          this.searchStatus +
          "&date=" +
          this.periodDateFrom +
          "&enddate=" +
          this.periodDateTo
      );
      this.result = res.data;
      this.rows = this.result.list.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    }
  },

  async mounted() {
    this.fetch();
  }
};
</script>

<style scoped>
p {
  margin-left: 1rem !important;
}

.course-stats {
 margin-left: 0.5rem !important;
}
</style>
<template>
  <div>
    <!-- Affiliate table -->
    <b-container fluid="xl">
      <layout-card>
        <template v-slot:header>
          <span>Affiliate ลิงค์ทั้งหมด</span>
        </template>
        <template v-slot:body>
          <div>
            <b-row class="d-flex justify-content-end">
              <b-col cols="12" sm="8" lg="5" xl="4">
                <div class="d-flex align-items-center mb-3">
                  <span class="mr-2">ค้นหา</span>
                  <b-form-input
                    v-model="searchKeyword"
                    placeholder="พิมพ์ชื่อคอร์ส หรือ ลิงค์"
                    @keydown.enter="setItems()"
                  ></b-form-input>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex justify-content-between text-primary">
                <span>มีทั้งหมด {{ items.length }} ลิงค์</span>
                <span>คลิกที่ลิงค์เพื่อคัดลอก</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  id="affiliate-data-table"
                  table-class="tbNumber text-left my-2"
                  class="fixed-height"
                  :items="items"
                  :fields="fields"
                  small
                  responsive
                  :busy="isLoading"
                  hover
                >
                  <template v-slot:cell(courseName)="data">
                    <span>{{ data.item.courseName }}</span>
                  </template>
                  <template v-slot:cell(linkName)="data">
                    <span>{{ data.item.linkName }}</span>
                  </template>
                  <template v-slot:cell(linkUrl)="data">
                    <div @click="copyButtonClicked(data.item.affiliateId)">
                      <span class="affiliate-link">{{
                        data.item.linkUrl | shortenLink
                      }}</span>
                    </div>
                    <input
                      type="hidden"
                      :id="`affid-${data.item.affiliateId}-copied-linkurl`"
                      :value="data.item.linkUrl"
                    />
                  </template>
                  <template v-slot:cell(option)="data">
                    <div>
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        class="mr-1"
                        @click="buttonClicked('EDIT', data.item.affiliateId)"
                      >
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="buttonClicked('DELETE', data.item.affiliateId)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </div>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </template>
      </layout-card>
      <!-- Edit modal -->
      <b-modal v-model="editModalShow" title="แก้ไขลิงค์ Affiliate">
        <edit-modal
          :affiliateItem="
            items.find((el) => el.affiliateId == selectedAffiliateId)
          "
          @linkNameChanged="linkNameChangedHandler"
        ></edit-modal>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="w-100 py-2"
              @click="closeEditModalHandler()"
              >ปิดหน้าต่าง</b-button
            >
          </div>
        </template>
      </b-modal>
      <!-- Delete modal -->
      <b-modal v-model="deleteModalShow" title="ต้องการลบลิงค์ใช่หรือไม่">
        <delete-modal
          :affiliateItem="
            items.find((el) => el.affiliateId == selectedAffiliateId)
          "
        ></delete-modal>
        <template v-slot:modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              variant="outline-primary"
              class="w-25 py-2 mr-2"
              @click="deleteModalShow = false"
              >ไม่ต้องการ</b-button
            >
            <b-button variant="primary" @click="deleteButtonClickedHandler"
              >ใช่</b-button
            >
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import LayoutCard from "@/components/pages/analytics/shared/Layout.vue";
import EditModal from "@/components/pages/affiliate/link/components/EditModal.vue";
import DeleteModal from "@/components/pages/affiliate/link/components/DeleteModal.vue";
import service from "@/services/api.js";
import ToastMixin from "@/mixins/toast-mixin.js";
import AffiliateLinkMixin from "@/components/pages/affiliate/link/mixins/affiliate-link.js";
import api from "@/services/api.js";

export default {
  mixins: [ToastMixin, AffiliateLinkMixin],
  components: {
    LayoutCard,
    EditModal,
    DeleteModal,
  },
  props: {
    affiliateLinks: Array,
    publisherId: String,
  },
  data() {
    return {
      searchKeyword: "",
      fields: [
        { key: "courseName", label: "ชื่อคอร์ส" },
        { key: "linkName", label: "ชื่อลิงค์" },
        { key: "linkUrl", label: "ลิงค์" },
        { key: "option", label: "" },
      ],
      items: [],
      isLoading: false,
      selectedAffiliateId: null,
      editModalShow: false,
      currentEditedItem: null,
      deleteModalShow: false,
    };
  },
  watch: {
    affiliateLinks() {
      this.setItems();
    },
    publisherId() {
      this.setItems();
    },
  },
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    this.setItems();
  },
  methods: {
    buttonClicked(type, id) {
      this.selectedAffiliateId = id;
      this.currentEditedItem = null;
      switch (type) {
        case "EDIT":
          this.editModalShow = true;
          break;
        case "DELETE":
          this.deleteModalShow = true;
          break;
        default:
          break;
      }
    },
    linkNameChangedHandler(event) {
      this.currentEditedItem = {
        affiliateId: event.id,
        newLinkName: event.value.trim(),
      };
    },
    async closeEditModalHandler() {
      if (this.currentEditedItem) {
        try {
          await service.put(
            `/v1/affiliate/links/${this.currentEditedItem.affiliateId}`,
            this.currentEditedItem
          );
          this.displayToast({
            variant: "success",
            title: "Success",
            content: "Edit affiliate link successfully",
          });
          this.$emit("linkUpdated");
        } catch (err) {
          console.log("Update affiliate link error.");
          console.log(err.response);
          this.displayToast({
            variant: "danger",
            title: "Fail",
            content: "Edit affiliate link failed",
          });
        }
      }
      this.editModalShow = false;
    },
    async deleteButtonClickedHandler() {
      try {
        await service.delete(`/v1/affiliate/links/${this.selectedAffiliateId}`);
        this.displayToast({
          variant: "success",
          title: "Success",
          content: "Delete affiliate link successfully",
        });
        this.$emit("linkDeleted");
      } catch (err) {
        console.log("Delte affiliate link error.");
        console.log(err.response);
        this.displayToast({
          variant: "danger",
          title: "Fail",
          content: "Delete affiliate link failed",
        });
      }
      this.deleteModalShow = false;
    },
    copyButtonClicked(affiliateId) {
      let testingCodeToCopy = document.querySelector(
        `#affid-${affiliateId}-copied-linkurl`
      );
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      document.execCommand("copy");
      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      // Show some notification
      this.displayToast({
        variant: "success",
        title: "Success",
        content: `
          Copy ${
            this.items.find((el) => el.affiliateId === affiliateId).linkName
          } 
          link to clipboard !
        `,
      });
    },
    setItems() {
      let items = [...this.affiliateLinks];
      // Filter by publisherId
      if (this.publisherId !== "*") {
        items = items.filter((el) => `${el.publisherId}` === this.publisherId);
      }
      // Filter by search keyword
      if (this.searchKeyword.trim().length > 0) {
        items = items.filter((el) => {
          const keyword = `${el.courseName}${el.linkName}`.toLowerCase();
          return keyword.includes(this.searchKeyword.toLowerCase());
        });
      }
      this.items = items;
    },
  },
};
</script>

<style scoped>
.affiliate-link:hover {
  cursor: pointer;
  opacity: 0.6;
}
</style>

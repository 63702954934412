import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    selectedItems: null,
    validatedEnrollmentData: {
      coursesCount: 0,
      membersCount: 0,
      existingEnrollments: []
    },
    alert: null
  },
  mutations: {
    init() {},
    SYNC_SELECTED_ITEMS(state, { itemKey, itemValue }) {
      const index = state.selectedItems[itemKey].findIndex(
        item => item.id === itemValue.id
      );

      if (index > -1) {
        state.selectedItems[itemKey].splice(index, 1);
      } else {
        state.selectedItems[itemKey].push(itemValue);
      }
    },
    SET_SELECTED_ITEMS(state, { memberIds, courseIds, courseGroupIds }) {
      state.selectedItems["memberIds"] = memberIds;
      state.selectedItems["courseIds"] = courseIds;
      state.selectedItems["courseGroupIds"] = courseGroupIds;
    },
    SET_VALIDATED_ENROLLMENT_DATA(
      state,
      { coursesCount, membersCount, existingEnrollments }
    ) {
      state.validatedEnrollmentData = {
        coursesCount,
        membersCount,
        existingEnrollments
      };
    },
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
    CLEAR_SELECTED_ITEMS(state) {
      state.selectedItems = {
        memberIds: [],
        courseIds: [],
        courseGroupIds: [],

        courses: [],
        courseGroups: [],
        members: [],
        memberGroups: []
      };
    }
  },
  getters: {
    selectedItems(state) {
      return state.selectedItems;
    },
    validatedEnrollmentData(state) {
      return state.validatedEnrollmentData;
    },
    alert(state) {
      return state.alert;
    }
  },
  actions: {
    init(context) {
      context.commit("init");
      context.commit("CLEAR_SELECTED_ITEMS");
    },
    completeEnrollment({ commit }, cerIds) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/v1/courseEnroll/completeEnroll`, {
            cerIds: cerIds
          })
          .then(response => {
            commit("success");
            resolve(response);
          })
          .catch(error => {
            commit("error");
            reject(error);
          });
      });
    },

    enrollMembers({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post(`/v1/courses/${data.courseId}/members`, {
            members: data.members
          })
          .then(response => {
            commit("success");
            resolve(response);
          })
          .catch(error => {
            commit("error");
            reject(error);
          });
      });
    },

    enrollMembersGroups({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post(`/v1/courses/${data.courseId}/member-groups`, {
            groups: data.groups
          })
          .then(response => {
            commit("success");
            resolve(response);
          })
          .catch(error => {
            commit("error");
            reject(error);
          });
      });
    },

    syncSelectedItems({ commit }, item) {
      commit("SYNC_SELECTED_ITEMS", item);
    },

    setSelectedItems({ commit }, data) {
      commit("SET_SELECTED_ITEMS", data);
    },

    clearSelectedItems({ commit }) {
      commit("CLEAR_SELECTED_ITEMS");
    },

    setAlert({ commit }, value) {
      commit("SET_ALERT", value);
    },

    async validateEnrollment({ state, commit }) {
      const { memberIds, courseIds, courseGroupIds } = state.selectedItems;
      try {
        const response = await api.post(`/v1/course-groups/enroll/validate`, {
          memberIds,
          courseIds,
          courseGroupIds
        });
        const { data } = response;
        commit("SET_VALIDATED_ENROLLMENT_DATA", data.result);

        return true;
      } catch (err) {
        return false;
      }
    },

    async enrolls({ commit, state }) {
      const { memberIds, courseIds, courseGroupIds } = state.selectedItems;
      try {
        const response = await api.post(`/v1/course-groups/enrolls`, {
          memberIds,
          courseIds,
          courseGroupIds
        });

        const { data } = response;
        if (data.status === "success")
          commit("SET_ALERT", { type: "success", message: "ลงทะเบียนสำเร็จ" });
      } catch (err) {
        commit("SET_ALERT", { type: "danger", message: "ลงทะเบียนไม่สำเร็จ" });
      }

      commit("CLEAR_SELECTED_ITEMS");
    },

    async unenroll({ commit }, payload) {
      commit("unenroll");
      return api.post(`/v1/courses/${payload.courseId}/unenroll`, {
        cerIds: payload.cerIds
      });
    }
  }
};

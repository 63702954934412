<template>
  <div class="d-flex flex-column my-3">
    <div v-if="mainQuestionTitle">
      <mark-down
        :message="mainQuestionTitle + '<br><br><strong>' + result.questionTitle + '</strong>'"
      ></mark-down>
    </div>
    <div v-else>
      <mark-down
        :message="result.questionTitle"
      ></mark-down>
    </div>

    <div
      v-if="result.questionType == 'MULTIPLE_CHOICE'"
      class="d-flex flex-column my-3"
    >
      <span
        v-for="(item, index) in result.questionChoices"
        :key="index"
        :class="
          result.answerKey.choiceId.filter(
            (i) => i.choiceId == item.choiceId
          ).length > 0 && 'correct'
        "
      >
        <strong>
          <mark-down 
            :message="(index + 1) + 
              '. ' +
              item.choiceText + 
              ' (' + 
              ((result.stat[item.choiceId] / result.total) * 100).toFixed(2) + 
              '%)'"
          >
          </mark-down> 
        </strong>
      </span>
    </div>
    <div
      v-if="result.questionType == 'FILL_BLANK'"
      class="d-flex flex-column my-3"
    >
      <span
        v-for="(item, index) in result.stat"
        :key="index"
        :class="result.answerKey.answerText == index && 'correct'"
      >
        <strong v-if="index != 'null'">
          {{ index }} ({{
            ((result.stat[index] / result.total) * 100).toFixed(2)
          }}%)</strong
        >
      </span>
    </div>
    <div>
      <span class="float-right"
        ><strong>
          ไม่ได้ตอบ ({{ ((result.blank / result.total) * 100).toFixed(2) }}%)</strong
        ></span
      >
    </div>
  </div>
</template>

<script>
import api from '@/services/exam-api.js';
import MarkDown from "@/components/shared/Markdown.vue";

export default {
  components: {
    MarkDown
  },
  props: ['apiRoute', 'mainQuestionTitle'],
  data() {
    return {
      result: [],

      items: [],
      total: null,
    };
  },
  async mounted() {
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped>
.correct {
  background: #bacfa1;
}
</style>
<style>
.markdown img {
  max-width: 100%;
}
</style>

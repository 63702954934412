<template>
  <div class>
    <b-row>
      <b-col md="12" lg="6" class="mb-3">
        <layout class="">
          <template v-slot:header>
            <h4>
              เปอร์เซ็นต์การเรียนจบ <b-icon-info-circle id="tooltip-target-1" />
              <b-tooltip target="tooltip-target-1" triggers="hover">
                เปอร์เซนต์จากผู้เรียนที่ลงทะเบียนในแต่ละแบบ
              </b-tooltip>
            </h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="6" class="text-center saparate-column">
                <h1 class="primary-color">
                  {{
                    result.paidCourse && result.totalPaid
                      ? ((result.paidCourse / result.totalPaid) * 100).toFixed(
                          2
                        )
                      : '0.00'
                  }}
                  %
                </h1>
                <p class="description">
                  คอร์สที่ต้องชำระเงิน
                </p>
              </b-col>
              <b-col md="6" class="text-center">
                <h1 class="blue">
                  {{
                    result.freeCourse && result.totalFree
                      ? ((result.freeCourse / result.totalFree) * 100).toFixed(
                          2
                        )
                      : '0.00'
                  }}
                  %
                </h1>
                <p class="description">
                  คอร์สเรียนฟรี
                </p>
              </b-col>
            </b-row>
          </template>
        </layout>
      </b-col>
      <b-col md="6" lg="3" class="mb-3">
        <layout class="">
          <template v-slot:header>
            <h4>จำนวนคอร์ส</h4>
          </template>
          <template v-slot:body>
            <h1 v-if="result.totalCourse >= 1">{{ result.totalCourse }}</h1>
            <h1 v-else>0.00</h1>
            <p class="description">คอร์ส</p>
          </template>
        </layout>
      </b-col>
      <b-col md="6" lg="3" class="mb-3">
        <layout class="">
          <template v-slot:header>
            <h4>ราคาคอร์สเฉลี่ย</h4>
          </template>
          <template v-slot:body>
            <h1 class="text-head" v-if="result.totalCourse >= 1">
              {{ result.totalCourse }}
            </h1>
            <h1 v-else class="text-null">0.00</h1>
            <p class="description">บาท/ชั่วโมง</p>
          </template>
        </layout>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" lg="4">
        <layout class="mini-card">
          <template v-slot:header>
            <h4>วิดีโอ</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="6">
                <h1 v-if="result.totalVideo >= 1">
                  {{ result.totalVideo }} <span>ไฟล์</span>
                </h1>
                <h1 v-else>0 <span style="font-size: 0.5em;">ไฟล์</span></h1>
              </b-col>
              <b-col cols="6" align="right">
                <b-icon-camera-video-fill
                  font-scale="3"
                  style="color:#F29E54"
                />
              </b-col>
            </b-row>
          </template>
        </layout>
      </b-col>
      <b-col md="12" lg="4">
        <layout class="mini-card">
          <template v-slot:header>
            <h4>pdf / เอกสาร</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="6">
                <h1 v-if="result.totalSlide >= 1">
                  {{ result.totalSlide }} <span>ไฟล์</span>
                </h1>
                <h1 v-else>0 <span style="font-size: 0.5em;">ไฟล์</span></h1>
              </b-col>
              <b-col cols="6" align="right">
                <b-icon-file-earmark-fill
                  font-scale="3"
                  style="color:#6F54EF"
                />
              </b-col>
            </b-row>
          </template>
        </layout>
      </b-col>
      <b-col md="12" lg="4">
        <layout class="mini-card">
          <template v-slot:header>
            <h4>ข้อสอบ</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="6">
                <h1 v-if="result.totalQuiz >= 1">
                  {{ result.totalQuiz }} <span>ชุด</span>
                </h1>
                <h1 v-else>0 <span style="font-size: 0.5em;">ชุด</span></h1>
              </b-col>
              <b-col cols="6" align="right">
                <b-icon-file-earmark-check-fill
                  font-scale="3"
                  style="color:#F29E54"
                />
              </b-col>
            </b-row>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import api from '@/services/api.js';
export default {
  components: {
    layout,
  },
  props: ['apiRoute'],
  data() {
    return {
      result: [],
      isBusy: true,
      items: [],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>

<style scoped>
.blue {
  color: #233ceb;
}
</style>

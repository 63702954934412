<template>
  <div>
    <b-form-group
      class="font-weight-bold"
      id="input-1-group"
      label="หัวข้อ"
      label-for="topic-input"
    >
      <b-form-input
        id="topic-input"
        type="text"
        v-model="topic"
        size="lg"
        debounce="500"
        required
      />
    </b-form-group>

    <div class="my-4">
      <b-form-group
        class="mt-3"
        id="input-3-group"
        label="ผู้สอน"
        label-for="instructor-select"
        label-class="font-weight-bold"
        required
      >
        <v-select
          id="owner"
          v-model="instructor"
          :options="instructors"
          :reduce="(owner) => owner.ownerId"
          :clearable="false"
          label="ownerName"
          placeholder="เลือกผู้สอน"
        ></v-select>
      </b-form-group>
    </div>

    <div class="my-4">
      <label class="font-weight-bold" for="scope-radio">สำหรับผู้เรียน</label>
      <b-form-radio-group id="scope-radio" v-model="liveScope" required>
        <b-form-radio value="COMPANY">ผู้เรียนทุกคนในระบบ</b-form-radio>
        <b-form-radio value="COURSE">ผู้เรียนในคอร์ส</b-form-radio>
      </b-form-radio-group>

      <v-select
        v-show="liveScope === 'COURSE'"
        v-model="courseId"
        :items="instructorCourses"
        :options="instructorCourses"
        :reduce="(course) => course.courseId"
        :clearable="false"
        label="courseName"
        placeholder="เลือกคอร์สเรียน"
        class="mt-2"
      ></v-select>
    </div>

    <div class="my-4">
      <label class="font-weight-bold mb-2" for="input-2-group">
        ช่วงเวลา
      </label>
      <b-row>
        <b-col sm="6">
          <b-input-group prepend="เริ่ม" class="mb-2">
            <b-form-datepicker
              id="live-datepicker"
              v-model="startLiveDate"
              label-align-sm="right"
              locale="th"
              :min="today"
              :hide-header="true"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              required
            ></b-form-datepicker>
            <b-form-timepicker
              id="live-start-timepicker"
              v-model="startLiveTime"
              locale="th"
              label-align-sm="right"
              now-button
              :hide-header="true"
              required
            ></b-form-timepicker>
          </b-input-group>
        </b-col>
        <b-col sm="6">
          <b-input-group prepend="ถึง">
            <b-form-datepicker
              id="live-end-datepicker"
              v-model="endLiveDate"
              label-align-sm="right"
              locale="th"
              :min="startLiveDate"
              :hide-header="true"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :state="checkEndDateValid"
              required
            ></b-form-datepicker>
            <b-form-timepicker
              id="live-end-timepicker"
              v-model="endLiveTime"
              locale="th"
              label-align-sm="right"
              now-button
              :hide-header="true"
              :state="checkEndTimeValid"
              required
            ></b-form-timepicker>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6"></b-col>
        <b-col sm="3">
          <b-form-invalid-feedback :state="checkEndDateValid">
            กรุณากรอกวันที่ให้ถูกต้อง
          </b-form-invalid-feedback>
        </b-col>
        <b-col sm="3">
          <b-form-invalid-feedback :state="checkEndTimeValid">
            กรุณากรอกเวลาให้ถูกต้อง
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "FormData",
  components: { vSelect },
  data() {
    return {
      instructors: [],
      today: new Date().toLocaleDateString("en-CA"),
    };
  },
  computed: {
    ...mapGetters("Company", ["companyInstructorsData", "companyInfo"]),
    ...mapGetters("CourseManagement", ["courseList"]),
    topic: {
      get() {
        return this.getTopic();
      },
      set(newTopic) {
        return this.setTopic(newTopic);
      },
    },
    startLiveDate: {
      get() {
        return this.getStartDate();
      },
      set(newDate) {
        return this.setStartDate(newDate);
      },
    },
    startLiveTime: {
      get() {
        return this.getStartTime();
      },
      set(newTime) {
        return this.setStartTime(newTime);
      },
    },
    endLiveDate: {
      get() {
        return this.getEndDate();
      },
      set(newDate) {
        return this.setEndDate(newDate);
      },
    },
    endLiveTime: {
      get() {
        return this.getEndTime();
      },
      set(newTime) {
        return this.setEndTime(newTime);
      },
    },
    instructor: {
      get() {
        return this.getInstructor();
      },
      set(newInst) {
        this.courseId = null;
        return this.setInstructor(newInst);
      },
    },
    courseId: {
      get() {
        return this.getCourseID();
      },
      set(newCourse) {
        return this.setCourseID(newCourse);
      },
    },
    liveScope: {
      get() {
        return this.getLiveScope();
      },
      set(newCat) {
        return this.setLiveScope(newCat);
      },
    },
    instructorCourses() {
      return this.instructor
        ? this.courseList.data.filter((course) => {
            if (course.courseOwner)
              return course.courseOwner.ownerId === this.instructor;
          })
        : this.courseList.data;
    },
    checkEndDateValid() {
      if (
        this.endLiveDate &&
        this.startLiveDate &&
        new Date(this.endLiveDate) < new Date(this.startLiveDate)
      ) {
        this.$emit("checkEndDateValid", false);
        return false;
      }
      this.$emit("checkEndDateValid", true);
      return true;
    },
    checkEndTimeValid() {
      let hour = this.endLiveTime.substr(0, 2);
      let minute = this.endLiveTime.substr(3, 2);
      let startHour = this.startLiveTime.substr(0, 2);
      let startMinute = this.startLiveTime.substr(3, 2);
      if (this.startLiveDate == this.endLiveDate) {
        if (hour < startHour || (hour == startHour && minute < startMinute)) {
          this.$emit("checkEndTimeValid", false);
          return false;
        }
      }
      this.$emit("checkEndTimeValid", true);
      return true;
    },
  },
  async created() {
    this.instructors = this.companyInstructorsData.data.filter(
      (item, pos, self) =>
        self.findIndex((v) => v.ownerId === item.ownerId) === pos
    );
  },
  methods: {
    ...mapActions("LiveForm", [
      "setTopic",
      "setStartDate",
      "setStartTime",
      "setEndDate",
      "setEndTime",
      "setInstructor",
      "setCourseID",
      "setLiveScope",
    ]),
    ...mapGetters("LiveForm", [
      "getTopic",
      "getStartDate",
      "getStartTime",
      "getEndDate",
      "getEndTime",
      "getInstructor",
      "getCourseID",
      "getLiveScope",
    ]),
    ...mapActions("CourseManagement", ["fetchCourseList"]),
  },
  async mounted() {
    await this.fetchCourseList({
      company_id: this.companyInfo.id,
      page: null,
      limit: null,
      q: null,
      sort_by: "name",
      sort_order: "asc",
      excepts: "coursePrice",
    });
  },
};
</script>

<template>
  <div>
    <ExamTakerDetailsHeader />
    <ExamTakerDetailsContent />
  </div>
</template>

<script>
import ExamTakerDetailsHeader from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerDetailsHeader.vue";
import ExamTakerDetailsContent from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerDetailsContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ExamTakerDetailsHeader,
    ExamTakerDetailsContent
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters("Company", ["examAnswerSheetData", "companyInfo"]),
    ...mapGetters("Authentication", ["authToken"])
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions("Company", ["fetchAnswerSheetById", "fetchExamById"]),
    async fetch() {
      await this.fetchAnswerSheetById({
        authToken: this.authToken,
        answersheetId: this.id,
        companyId: this.companyInfo.id
      });
      await this.fetchExamById(this.examAnswerSheetData.examId);
    }
  }
};
</script>

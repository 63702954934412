var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('div',[_c('span',[_vm._v("ชื่อผู้สมัคร: "+_vm._s(_vm.data.mp_firstName + ' ' + _vm.data.mp_lastName))]),_c('span',{staticStyle:{"float":"right"}},[_vm._v("อีเมล: "+_vm._s(_vm.data.m_email ? _vm.data.m_email : '-')+" เบอร์โทร : "+_vm._s(_vm.data.mp_mobilePhone ? _vm.data.mp_mobilePhone : '-'))])]),_c('hr'),_c('div',[_vm._v(" รูปบัตรประชาชน ")]),(_vm.data.verify_status === 'FORCE_APPROVED')?_c('div',[(_vm.cardIdBusy)?_c('b-spinner',{staticClass:"mb-1",attrs:{"small":""}}):_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.card}?token=${_vm.token}`}})],1):_c('div',[_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage +
          '/faces/' +
          _vm.data.m_id +
          '/' +
          _vm.data.com_id +
          '/face-idcard.jpg'}})]),_c('hr'),_c('div',[_vm._v(" รูปผู้สมัคร "),(_vm.data.verify_status === 'FORCE_APPROVED')?_c('div',[_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.faceFrontImage}?token=${_vm.token}`}}),_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.faceLeftImage}?token=${_vm.token}`}}),_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.faceRightImage}?token=${_vm.token}`}}),_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.faceUpImage}?token=${_vm.token}`}}),_c('img',{staticClass:"img-border",attrs:{"src":`${_vm.baseUrl}/${_vm.faceDownImage}?token=${_vm.token}`}})]):_c('div',[_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage +
            '/faces/' +
            _vm.data.m_id +
            '/' +
            _vm.data.com_id +
            '/face-front.jpg'}}),_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage +
            '/faces/' +
            _vm.data.m_id +
            '/' +
            _vm.data.com_id +
            '/face-right.jpg'}}),_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage +
            '/faces/' +
            _vm.data.m_id +
            '/' +
            _vm.data.com_id +
            '/face-left.jpg'}}),_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage + '/faces/' + _vm.data.m_id + '/' + _vm.data.com_id + '/face-up.jpg'}}),_c('img',{staticClass:"img-border",attrs:{"src":_vm.storage +
            '/faces/' +
            _vm.data.m_id +
            '/' +
            _vm.data.com_id +
            '/face-down.jpg'}})])]),_c('hr'),_c('div',[_vm._v(" อนุมัติรูปภาพ "),_c('div',{staticClass:"pt-3 d-flex"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":_vm.selected == 'APPROVED' ? 'primary' : 'light'},on:{"click":function($event){_vm.selected = 'APPROVED'}}},[_vm._v(" อนุมัติ ")]),_c('b-button',{attrs:{"variant":_vm.selected == 'REJECTED' ? 'primary' : 'light'},on:{"click":function($event){_vm.selected = 'REJECTED'}}},[_vm._v(" ไม่อนุมัติ ")]),(_vm.selected == 'REJECTED')?_c('b-input',{staticClass:"ml-3",staticStyle:{"padding":"1rem","width":"800px"},attrs:{"placeholder":"ระบุเหตุผล"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}):_vm._e()],1),_c('div',[_c('b-button',{staticStyle:{"float":"right"},attrs:{"variant":"primary","disabled":_vm.selected == null || _vm.busy},on:{"click":function($event){return _vm.sendVerifyResult()}}},[(_vm.busy)?_c('b-spinner',{staticClass:"mb-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(!_vm.busy ? 'ยืนยัน' : 'รอสักครู่')+" ")],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
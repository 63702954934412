var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column my-3"},[_c('div',[_c('mark-down',{attrs:{"message":'<strong>' + _vm.data.questionTitle}})],1),(_vm.data.questionType == 'MULTIPLE_CHOICE')?_c('div',{staticClass:"d-flex flex-column my-3"},_vm._l((_vm.data.questionChoices),function(item,index){return _c('span',{key:index,class:{
        correct:
          _vm.data.answerKey.choiceId.filter((i) => i.choiceId == item.choiceId)
            .length > 0,
        answer:
          _vm.data.answer.choiceId == item.choiceId
      }},[_c('strong',[_c('mark-down',{attrs:{"message":index + 1 + '. ' + item.choiceText}})],1)])}),0):_vm._e(),(_vm.data.questionType == 'FILL_BLANK')?_c('div',{staticClass:"d-flex flex-column my-3"},[_c('span',{class:'correct'},[_c('strong',[_vm._v(" "+_vm._s(_vm.data.answerKey.answerText)+" ")])]),_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.data.answer.answerText)+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <PageHeader title="จัดการกลุ่มคอร์ส"></PageHeader>

    <b-container fluid="xl">
      <div class="py-5">
        <div class="inner bg-container">
          <div class="card card-layout">
            <div class="mb-3 text-right">
              <b-button variant="primary" @click="_createCourseGroup()">
                <b-icon-plus />เพิ่มกลุ่มคอร์สเรียน
              </b-button>
            </div>

            <b-table
              id="my-table"
              :items="courseGroupItems"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="onLoading"
              responsive="sm"
            >
              <template v-slot:cell(action)="row">
                <div class="text-right" style="width: 150px">
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="_openCourseGroupEditPage(row.item.courseGroupId)"
                    >แก้ไขกลุ่มคอร์ส</b-button
                  >
                  <span
                    :id="`button-delete-${row.item.courseGroupId}`"
                    class="d-inline-block"
                    tabindex="0"
                  >
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      :disabled="row.item.allStudentsCount > 0"
                      @click="_deleteCourseGroup(row.item.courseGroupId)"
                    >
                      <b-icon-trash-fill />
                    </b-button>
                  </span>
                  <b-tooltip
                    v-if="row.item.allStudentsCount > 0"
                    :target="`button-delete-${row.item.courseGroupId}`"
                    variant="danger"
                    >ไม่สามารถลบกลุ่มคอร์สได้ เนื่องจากมีผู้ลงทะเบียนเรียนแล้ว
                  </b-tooltip>
                </div>
              </template>
              <template v-slot:table-busy>
                <Loading class="mt-5" />
              </template>
            </b-table>
            <div class="mt-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="courseGroupItems.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pull-right"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import { mapGetters, mapActions } from "vuex";
import Loading from "../../shared/Loading";
import Numeral from "numeral";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  name: "CourseGroupListPage",
  components: { PageHeader, Loading },
  mixins: [AuthMixin],
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      onLoading: true,
      companyId: this.company.id,
      perPage: 10,
      currentPage: 1,
      sortBy: "courseGroupName",
      sortDesc: false,
      fields: [
        { key: "courseGroupName", sortable: true, label: "ชื่อกลุ่มคอร์ส" },
        { key: "coursesCount", sortable: true, label: "จำนวนคอร์ส" },
        {
          key: "allStudentsCount",
          sortable: true,
          label: "จำนวนผู้ลงทะเบียนเรียน",
          formatter: (value) => {
            return Numeral(value).format("0,0");
          },
        },
        { key: "action", sortable: false, label: "" },
      ],
    };
  },
  computed: {
    ...mapGetters("CourseGroupManagement", ["courseGroupItems"]),
  },

  mounted() {
    this._fetchCourseGroupList();
  },
  methods: {
    ...mapActions("CourseGroupManagement", [
      "fetchCourseGroupItems",
      "createCourseGroup",
      "deleteCourseGroup",
    ]),
    _fetchCourseGroupList() {
      this.onLoading = true;
      this.fetchCourseGroupItems({
        companyId: this.companyId,
      }).then(() => (this.onLoading = false));
    },
    _createCourseGroup() {
      this.onLoading = true;
      this.createCourseGroup(this.companyId).then(({ data }) => {
        this.onLoading = false;
        this._openCourseGroupEditPage(data.cgId);
      });

      // TODO: Course-group edit confirmation
      // this.$bvModal
      //   .msgBoxConfirm(
      //     'หลังจากสร้างกลุ่มคอร์สแล้วไม่สามารถเปลี่ยนแปลงลำดับของ Prerequisite ได้อีก ยืนยันที่จะดำเนินการต่อหรือไม่?',
      //     {
      //       buttonSize: 'sm',
      //       okTitle: 'ยืนยัน',
      //       okVariant: 'primary',
      //       cancelTitle: 'ยกเลิก',
      //       cancelVariant: 'link',
      //       hideHeader: true,
      //       hideHeaderClose: true,
      //       bodyClass: 'p-4',
      //       footerClass: 'p-3 border-0',
      //       centered: true,
      //     },
      //   )
      //   .then((confirm) => {
      //     if (confirm) {
      //       this.onLoading = true
      //       this.createCourseGroup(this.companyId).then(({ data }) => {
      //         this.onLoading = false
      //         this._openCourseGroupEditPage(data.cgId)
      //       })
      //     }
      //   })
    },
    _deleteCourseGroup(courseGroupId) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบกลุ่มคอร์สเรียนใช่หรือไม่?", {
          title: "แจ้งเตือนการลบกลุ่มคอร์ส",
          buttonSize: "sm",
          okTitle: "ลบกลุ่มคอร์ส",
          okVariant: "primary",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          headerCloseVariant: "white",
          headerCloseContent: "&times;",
          headerBgVariant: "danger",
          headerClass: "py-2 px-3 border-0",
          footerClass: "p-3 border-0",
          centered: true,
        })
        .then((confirm) => {
          if (confirm) {
            this.onLoading = true;

            this.deleteCourseGroup(courseGroupId).then(() => {
              this._fetchCourseGroupList();
            });
          }
        });
    },
    _openCourseGroupEditPage(courseGroupId) {
      this.$router.push({
        name: "course-group.management.edit",
        params: { id: courseGroupId },
      });
    },
  },
};
</script>
<style scoped>
.card-layout {
  box-shadow: 2px 2px 9px rgb(0 0 0 / 16%);
  border-radius: 6px;
  padding: 20px 35px;
  border: 0;
}
</style>

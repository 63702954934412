<template>
  <div>
    <PageHeader
      v-if="comId == 513"
      title="สรุปภาพรวม NYC"
      style="margin-left: -10%"
    ></PageHeader>
    <PageHeader
      v-else
      title="สรุปภาพรวมสภาบัญชี"
      style="margin-left: -10%"
    ></PageHeader>
    <b-container class="widthPage pt-5">
      <!-- <layout> -->
      <!-- <template v-slot:body> -->
      <b-row class="mb-3">
        <b-col cols="12">
          <paymentSummary
            :apiRoute="`/v1/analytics/tfac/get-payment-summary`"
            :perPage="10"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="12">
          <studentInfo
            :apiRoute="`/v1/analytics/tfac/get-student-info-overview`"
            :perPage="10"
          />
        </b-col>
      </b-row>
      <!-- </template> -->
      <!-- </layout> -->
    </b-container>
  </div>
</template>

<script>
// import Layout from "@/components/pages/analytics/shared/Layout.vue";
import paymentSummary from "./panel/PaymentSummaryPanel";
import studentInfo from "./panel/TfacStudentInfoPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";

export default {
  components: {
    // Layout,
    PageHeader,
    studentInfo,
    paymentSummary,
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
    };
  },
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

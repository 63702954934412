<template>
  <div>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>จํานวนการเข้าดูของวิดีโอ/สไลด์</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="
                `/${companyInfo.url}/analytics/courses/${cId}/lecture-views`
              "
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <b-form-input
                  v-model="search"
                  placeholder="ค้นหาวิดีโอหรือสไลด์"
                  style="padding: 1rem; display: inline-block; width: 20%"
                >
                </b-form-input>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            id="course-view-table"
            :per-page="perPage"
            :current-page="currentPage"
            striped
            table-class="tbNumber text-left "
            :items="
              search == null
                ? result
                : result.filter((i) => i.lec_name.includes(search))
            "
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(vdoName)="data">
              <span>{{ data.item.lec_name }}</span></template
            >

            <template v-slot:cell(courseLength)="data">
              <template>
                <span>{{
                  data.item.lec_type == 'VIDEO' && data.item.vid_length
                    ? data.item.vid_length + ' ชั่วโมง'
                    : 'ไม่มีข้อมูล'
                }}</span>
              </template>
            </template>
            <template v-slot:cell(learningTime)="data">
              <span>{{
                data.item.learning_time
                  ? data.item.learning_time + ' ชั่วโมง'
                  : 'ไม่มีข้อมูล'
              }}</span>
            </template>
            <template v-slot:cell(view)="data">
              <span>{{ data.item.viewCounter }}</span>
            </template>

            <template v-slot:cell(btn)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-modal="`modal-${data.item.lec_id}`"
              >
                <span>ดูข้อมูล</span>
              </b-button>
              <b-modal
                size="xl"
                :id="`modal-${data.item.lec_id}`"
                :title="`จํานวนการเข้าเรียนบทเรียน ${data.item.lec_name}`"
              >
                <lectureViewModal
                  :apiRoute="
                    `/v1/analytics/courses/getLectureViewDetail?cId=${cId}&lecId=${data.item.lec_id}&start=${startDate}&end=${endDate}`
                  "
                ></lectureViewModal>
              </b-modal>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) => i.lec_name.includes(search)).length
            "
            :per-page="perPage"
            aria-controls="course-view-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import lectureViewModal from '../modal/CourseLectureViewModal';
import api from '@/services/api.js';
import exp from '@/services/export.js';
export default {
  components: {
    layout,
    lectureViewModal,
  },
  props: ['apiRoute', 'perPage', 'startDate', 'endDate'],
  data() {
    return {
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      search: null,
      currentPage: 1,
      rows: 0,
      result: [],
      sortBy: 'viewCounter',
      sortDesc: true,
      isBusy: true,
      fields: [
        { key: 'vdoName', label: 'ชื่อวิดิโอหรือสไลด์' },
        {
          key: 'courseLength',
          label: 'ความยาววิดีโอ/สไลด์',
        },
        {
          key: 'learningTime',
          label: 'เวลาที่ใช้เรียนรวม',
        },
        {
          key: 'view',
          label: 'จำนวนคนที่เข้าดู',
        },
        {
          key: 'btn',

          label: '',
        },
      ],
      items: [],
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) => i.lec_name.includes(this.search));
      set.forEach((i) => {
        let temp = {
          name: i.lec_name,
          length:
            i.lec_type == 'VIDEO' && i.vid_length
              ? i.vid_length + ' ชั่วโมง'
              : 'ไม่มีข้อมูล',
          learning: i.learning_time
            ? i.learning_time + ' ชั่วโมง'
            : 'ไม่มีข้อมูล',
          view: i.viewCounter ? i.viewCounter : 0,
        };
        value.push(temp);
      });
      let key = [
        'ชื่อวิดีโอหรือสไลด์',
        'ความยาววิดีโอ/สไลด์',
        'เวลาที่ใช้เรียนรวม',
        'จำนวนคนที่เข้าดู',
      ];
      let filename =
        'Individual-Course-Learner-View-Detail' + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.rows = this.result.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>

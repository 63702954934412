<template>
  <div>
    <b-card title="ผลสอบทั้งหมด" class="text-center st-score mb-4">
      <b-card-text>
        <template v-if="examAnswerSheetPending">
          กำลังโหลด
        </template>
        <template v-else>
          <ExamTakerAnswerChart :height="chartHeight" :dataset="dataset" />
        </template>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExamTakerAnswerChart from "@/components/pages/exam-analytics/exam-taker-details/charts/ExamTakerAnswerChart.vue";

export default {
  name: "ExamTakerAnswerDetails",
  components: {
    ExamTakerAnswerChart
  },
  data() {
    return {
      chartHeight: 350
    };
  },
  computed: {
    ...mapGetters("Company", ["examAnswerSheetPending", "examAnswerSheetData"]),
    dataset() {
      const {
        blank,
        correct,
        incorrect,
        totalQuestion
      } = this.examAnswerSheetData.overallResult;
      return [
        ((correct / totalQuestion) * 100).toFixed(2),
        ((incorrect / totalQuestion) * 100).toFixed(2),
        ((blank / totalQuestion) * 100).toFixed(2)
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamTakerAnswerDetails {
  width: 555px;
  height: 280px;
}
.st-score {
  padding-top: 1.8em;
}
</style>

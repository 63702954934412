<template>
  <div>
    <PageHeader title="ตรวจสอบใบหน้าลงทะเบียน"></PageHeader>
    <br />
    <br />
    <b-container v-if="isLoading" fluid="xl">
      <div style="display: flex">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text style="padding-right: 2em">
              <b-icon icon="search" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="กรุณาพิมพ์เพื่อค้นหา"
            style="width: 40%"
          ></b-form-input>
        </b-input-group>
        <b-form-select
          v-model="selected"
          :options="options"
          style="margin-left: 1em; width: 20%"
          @input="onFaceStatusFilter()"
        ></b-form-select>
      </div>
      <br />
      <b-table
        hover
        :fields="fields"
        :items="filteredItems"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template v-slot:cell(regDate)="data">
          <span>{{ data.item.regDate | thaiDateFilter }}</span>
        </template>
        <template v-slot:cell(hasId)="data">
          <b-icon
            v-if="data.item.hasId"
            class="h2"
            icon="check-circle-fill"
            style="color: green"
          />
          <b-icon
            v-else
            class="h2"
            icon="x-circle-fill"
            style="color: #d0312d"
          />
        </template>
        <template v-slot:cell(hasFace)="data">
          <b-icon
            v-if="data.item.hasFace"
            class="h2"
            icon="check-circle-fill"
            style="color: green"
          />
          <b-icon
            v-else
            class="h2"
            icon="x-circle-fill"
            style="color: #d0312d"
          />
        </template>
        <template v-slot:cell(faceStatus)="data">
          <span
            v-if="data.item.faceStatus == 'ไม่ตรงกัน'"
            style="color: #d0312d"
            >{{ data.item.faceStatus }}</span
          >
          <span
            v-else-if="data.item.faceStatus == 'ตรงกัน'"
            style="color: green"
            >{{ data.item.faceStatus }}</span
          >
          <span
            v-else-if="data.item.faceStatus == 'รอตรวจสอบ'"
            style="color: #fccf55"
            >{{ data.item.faceStatus }}</span
          >
          <span
            v-else-if="data.item.faceStatus == 'ไม่ตรงกัน ยืนยันโดยระบบ'"
            style="color: #fccf55"
            >{{ data.item.faceStatus }}</span
          >

          <span v-else>{{ data.item.faceStatus }}</span>
        </template>
        <template v-slot:cell(info)="data">
          <b-button
            size="sm"
            @click="info(data.item, data.index, $event.target)"
            variant="outline-primary"
          >
            ดูข้อมูลการยืนยันตัวตน
          </b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        @hide="resetInfoModal"
        :header-bg-variant="infoModal.headerBgVariant"
        :header-text-variant="infoModal.headerTextVariant"
        centered
      >
        <div class="box-content">
          <div class="box text">
            <h6>
              <strong>ชื่อผู้สมัคร: </strong> {{ infoModal.content.name }}
            </h6>
          </div>
          <div class="box email">
            <h6><strong>อีเมล: </strong> {{ infoModal.content.email }}</h6>
          </div>
          <div class="box phone">
            <h6>
              <strong>เบอร์โทรศัพท์: </strong> {{ infoModal.content.tel }}
            </h6>
          </div>
        </div>
        <b-row>
          <b-col cols="6">
            <div name="id-card" class="text-center">
              <div v-if="infoModal.content.idPath">
                <img
                  height="100"
                  :src="`${imgBaseUrl}/${infoModal.content.idPath}?token=${token}`"
                />
                <h6>รูปบัตรประชาชน</h6>
              </div>

              <div v-else class="warning-card">ไม่พบภาพบัตรประชาชน</div>
            </div>
          </b-col>
          <b-col cols="6">
            <div name="face-card" class="text-center">
              <div v-if="faceImgPath">
                <img
                  height="100"
                  :src="`${imgBaseUrl}/${faceImgPath}?token=${token}`"
                />
                <h6>รูปหน้าตรง</h6>
              </div>

              <div v-else class="warning-card">ไม่พบรูปภาพหน้าตรง</div>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="
            infoModal.content.faceStatus
              ? infoModal.content.faceStatus.slice(0, 6) != 'ตรงกัน'
              : ''
          "
          style="
            border: solid #90f4e3;
            border-radius: 5px;
            padding-left: 1em;
            padding-top: 0.5em;
          "
        >
          <b-form-group v-slot="{ ariaDescribedby }">
            <div style="margin-bottom: 10px"><b>อนุมัติรูปภาพ</b></div>
            <b-form-radio
              v-model="isApproved"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="APPROVED"
              >อนุมัติ</b-form-radio
            >
            <b-form-radio
              v-model="isApproved"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="REJECTED"
              >ไม่อนุมัติ</b-form-radio
            >
          </b-form-group>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <div class="float-right">
              <b-button
                v-if="
                  infoModal.content.faceStatus
                    ? infoModal.content.faceStatus.slice(0, 6) != 'ตรงกัน'
                    : ''
                "
                variant="primary"
                @click="approveFaceStatus(infoModal)"
                :disabled="!isApproved"
              >
                ยืนยัน
              </b-button>
              <b-spinner
                v-if="updatingStatus"
                style="margin-left: 0.25em"
              ></b-spinner>
            </div>
          </div>
        </template>
      </b-modal>
    </b-container>
    <b-container v-else>
      <b-spinner class="align-middle mr-2"></b-spinner>
      <strong>Loading...</strong>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader.vue";
import api from "@/services/api.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import axios from "axios";
export default {
  components: {
    PageHeader,
  },
  mixins: [dateMixins],
  data() {
    return {
      verifier: JSON.parse(localStorage.getItem("user")).id,
      perPage: 10,
      currentPage: 1,
      filter: null,
      selected: null,
      rows: null,
      filteredItems: null,
      isApproved: null,
      updatingStatus: false,
      faceImgPath: null,
      comId: this.$store.state.Company.companyInfo.id,
      imgBaseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com",
      faceBaseUrl:
        process.env.VUE_APP_FACE_RECOGNITION_API_URL ||
        "https://sightgain-dev-26082022.coursesquare.com/",
      token: localStorage.getItem("token"),
      infoModal: {
        id: "info-modal",
        title: "ข้อมูลการลงทะเบียนใบหน้า",
        content: "",
        headerBgVariant: "light",
        headerTextVariant: "dark",
      },
      options: [
        { value: null, text: "สถานะทั้งหมด" },
        { value: "ตรงกัน ยืนยันโดยระบบ", text: "ตรงกัน ยืนยันโดยระบบ" },
        { value: "ไม่ตรงกัน ยืนยันโดยระบบ", text: "ไม่ตรงกัน ยืนยันโดยระบบ" },
      ],
      fields: [
        {
          key: "name",
          label: "ชื่อ-สกุล",
        },
        {
          key: "email",
          label: "อีเมล",
        },
        {
          key: "regDate",
          label: "วันที่สมัคร",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "hasId",
          label: "บัตรประชาชน",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "hasFace",
          label: "ภาพใบหน้า",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "faceStatus",
          label: "สถานะ",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "info",
          label: "ตรวจสอบข้อมูล",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      isLoading: false,
    };
  },
  methods: {
    async fetch() {
      this.items = [];
      this.selected = null;
      await api.get("/v1/recognition/info").then(({ data }) => {
        this.items = data.data;
        this.filteredItems = data.data;
        this.rows = this.items.length;
        this.isLoading = true;
      });
    },
    onFaceStatusFilter() {
      this.filteredItems = this.items;
      if (this.selected != null) {
        this.filteredItems = this.filteredItems.filter(
          (item) => item.faceStatus == this.selected
        );
      }
      this.rows = this.filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    info(item, button) {
      this.infoModal.content = item;
      this.getRegisterFace64(item.mId);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.content = "";
      this.isApproved = null;
    },
    async getRegisterFace64(mId) {
      await axios
        .get(
          `${this.faceBaseUrl}v2/register-face?m_id=${mId}&com_id=${this.comId}`
        )
        .then((data) => {
          this.faceImgPath =
            data.data == "Data not found" ? null : data.data.face_path[0];
        });
    },
    async approveFaceStatus(info) {
      this.updatingStatus = true;
      await api
        .put(`/v1/faceverify/${info.content.mId}/approveFaceVerifyStatus`, {
          verifier: this.verifier,
          status: this.isApproved,
        })
        .then(() => {
          this.updatingStatus = false;
          this.$bvModal.hide(info.id);
          this.fetch();
        });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
.warning-card {
  color: red;
  border: solid red;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  flex: 1;
  width: 200px;
}
.box-content .box {
  padding: 10px 0;
}
</style>

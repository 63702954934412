.<template>
  <div class="exam-info-page">
    <PageHeader title="จัดการข้อสอบ"></PageHeader>
    <div class="outer ml-3">
      <div class="bg-container inner">
        <div class="exam-edit-header">
          <div>
            <h5 class="exam-edit-title">แก้ไขข้อสอบ</h5>
          </div>
          <div class="exam-edit-button">
            <b-button class="mr-2  d-none" variant="success" size="md"
              >เผยแพร่ข้อสอบ</b-button
            >
            <b-button
              class="mr-2"
              variant="outline-primary"
              size="md"
              @click="previewExam()"
            >
              <b-icon icon="eye"></b-icon> พรีวิวข้อสอบ
            </b-button>
            <b-button class="mr-2 d-none" variant="outline-danger" size="md">
              <b-icon icon="trash"></b-icon> ลบข้อสอบ</b-button
            >
          </div>
        </div>
        <template v-if="isLoading === true">
          <div class="text-center mt-5 text-csq">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <p>Loading...</p>
          </div>
        </template>
        <div v-else-if="isLoading === false">
          <div class="exam-total-all">
            <div class="total-section mr-3">
              <span>จำนวน Section: </span>
              <span> {{ sectionCount }}</span>
              <span> sections</span>
            </div>
            <div class="total-exam">
              <span>จำนวนคำถาม: </span>
              <span>{{ questionCount }}</span>
              <span> ข้อ</span>
            </div>
          </div>
          <div class="exam-title-name">
            <b-col class="b-col-examtitle">
              <span class="exam-name" for="input-with-list">ชื่อข้อสอบ</span>
              <span class="exam-status ml-2">status: {{ exam.status }} </span>
              <b-form-input
                autocomplete="off"
                v-model="exam.title"
                @change="onUpdateExamtitle()"
                class="mt-2"
                :state="titleExamState"
              ></b-form-input>
            </b-col>
            <b-col class="d-none">
              <span class="exam-name" for="input-with-list">ระดับความยาก</span>
              <div class="exam-level">
                <b-button
                  squared
                  variant="outline-primary"
                  class="exam-button-level level-one"
                  >1</b-button
                >
                <b-button
                  squared
                  variant="outline-primary"
                  class="ml-2 exam-button-level level-two"
                  >2</b-button
                >
                <b-button
                  squared
                  variant="outline-primary"
                  class="ml-2 exam-button-level level-three"
                  >3</b-button
                >
                <b-button
                  squared
                  variant="outline-primary"
                  class="ml-2 exam-button-level level-four"
                  >4</b-button
                >
              </div>
            </b-col>
          </div>
          <div class="mt-3">
            <b-tabs v-model="tabIndex">
              <b-tab title="รายละเอียดข้อสอบ" :title-link-class="linkClass(0)"
                ><ExamDetails />
              </b-tab>
              <b-tab title="จัดการข้อสอบ" :title-link-class="linkClass(1)"
                ><EditExamInfo />
              </b-tab>
              <b-tab title="ตั้งค่าข้อสอบ" :title-link-class="linkClass(2)"
                ><SettingExam />
              </b-tab>
              <b-tab title="เกณฑ์การตัดเกรด" :title-link-class="linkClass(3)"
                ><GradingCriteria />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class="exam-preview">
      <b-modal
        v-model="ModalPreviewExam"
        title="พรีวิวข้อสอบ"
        size="xl"
        :hide-footer="true"
        ref="modal-editor"
        modal-class="preview-modal"
      >
        <iframe
          v-bind:src="examPreviewUrl + examId + '?preview=1'"
          style="width: 100%"
        >
        </iframe>
      </b-modal>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import EditExamInfo from "./partials/EditExamInfo.vue";
import SettingExam from "./partials/SettingExam.vue";
import GradingCriteria from "./partials/GradingCriteria.vue";
import { mapActions, mapGetters } from "vuex";
import ExamDetails from './partials/ExamDetails.vue';

export default {
  components: { PageHeader, EditExamInfo, SettingExam, GradingCriteria, ExamDetails },
  data() {
    return {
      total_exams: 0,
      examsInfo: [],
      token: localStorage.getItem("token"),
      examId: this.$route.params.quizExamId,
      tabIndex: 0,
      isLoading: true,
      titleExamState: null,
      ModalPreviewExam: false,
      examPreviewUrl: "",
    };
  },

  async mounted() {
    await this.fetchExamsById();
    this.examsInfo = this.exam;

    this.examPreviewUrl =
      process.env.VUE_APP_EXAM_PREVIEW_BASE_URL ||
      "https://exam-frontend-dev-26082018.coursesquare.co/embed/exam/";

    this.setRandomQuestion();
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exam", "questionCount", "sectionCount"]),
    countExams() {
      let sumExams = 0;
      for (let i of this.exam.sections) {
        sumExams += i.questions.length;
      }
      return sumExams;
    },
    isRandomQuestionExam() {
      let sum = 0;
      for (let i = 0; i < this.exam.sections.length; i++) {
        if (this.exam.sections[i].options === undefined) {
          sum = sum + 0;
        } else if (Object.keys(this.exam.sections[i].options).length != 0) {
          sum = sum + 1;
        }
      }

      if (sum === 0) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "findExamsbyId",
      "updateExamInfo",
      "sectionOptionsSetting",
      "fetchQuestionAllAnswer"
    ]),

    async fetchExamsById() {
      this.isLoading = true;
      await this.fetchQuestionAllAnswer(this.examId);
      await this.findExamsbyId(this.examId).then(() => {});
      this.isLoading = false;
    },
    async setRandomQuestion() {
      for (let i = 0; i < this.exam.sections.length; i++) {
        if (!this.exam.sections[i].options) {
          let request = {
            body: {
              options: {},
            },
            id: this.examId,
            token: this.token,
            sectionIndex: i,
            sectionId: this.exam.sections[i].id,
          };
          await this.sectionOptionsSetting(request);
        }
      }
    },

    async previewExam() {
      this.ModalPreviewExam = true;
    },

    async onUpdateExamtitle() {
      if (this.exam.title == "" || this.exam.title == null) {
        this.titleExamState = false;
        return;
      }
      let request = {
        body: { title: this.exam.title },
        id: this.examId,
        token: this.token,
      };
      await this.updateExamInfo(request)
        .then(() => {
          this.titleExamState = true;
        })
        .catch(() => {
          this.titleExamState = false;
        });
      await this.fetchExamsById();
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-warning", "text-dark "];
      } else {
        return ["bg-light", "text-dark "];
      }
    },
  },
};
</script>

<style lang="css">
.preview-modal .modal-dialog {
  max-width: 100% !important;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.exam-info-page {
  font-family: "Prompt", sans-serif;
}
.exam-edit-header {
  display: flex;
  justify-content: space-between;
}
.exam-total-all {
  display: flex;
}

.exam-total-all {
  font-size: 16px;
  font-weight: 700;
}

.total-section {
  padding: 10px;
  background-color: #e99a54;
  border-radius: 5px;
}

.total-exam {
  padding: 10px;
  background-color: #99c3ef;
  border-radius: 5px;
}

.exam-edit-title {
  font-size: 18px;
  font-weight: 700;
}

.b-col-examtitle {
  margin: 0 !important;
  padding: 0 !important;
}

.exam-title-name {
  display: flex;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 700;
}

.exam-status {
  font-size: 12px;
  background-color: #007d19;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
}

.exam-level .exam-button-level {
  padding: 5px 15px;
  border-width: 2px;
}

.exam-button-level:active {
  background-color: #007d19;
  border-color: #007bff !important;
}
.exam-checkbox {
  display: flex;
  align-items: center;
}

.tab-title-class {
  color: #495057;
  background-color: #eb8123 !important;
}

.icon.active {
  color: #28a745;
}

.invalid-feedback {
  white-space: pre !important;
}
</style>

<template>
  <div>
    <Comment
      v-for="(comment, index) in comments"
      :key="index"
      :collection="collection"
      :comment="comment"
      :level="level + 1"
      @posted="$emit('posted')"
    ></Comment>
  </div>
</template>
<script>
export default {
  name: 'CommentList',
  components: {},
  props: ['collection', 'comments', 'level'],
  data() {
    return {
      isOpened: false,
      cId: this.$route.params.cId,
      isBusy: true,
      items: [],
      user: JSON.parse(localStorage.getItem('user')),
      mId: JSON.parse(localStorage.getItem('user')).id,
      busy: false,
      response: {},
    };
  },
  methods: {
    setIsOpened(isOpened) {
      this.isOpened = isOpened;
    },
  },
  beforeCreate: function() {
    this.$options.components.Comment = require('./Comment.vue').default;
  },
};
</script>
<style scoped>
.text-head {
  color: #f0a502;
  text-align: center;
  font-weight: bold;
  font-family: 'Sarabun' , sans-serif;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
  width: 600px;
}
.expand-collapse-button:hover {
  cursor: pointer;
}
</style>

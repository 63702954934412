<template>
  <b-card no-body class="mb-2 no-shadow">
    <b-card-header
      header-tag="header"
      class="bg-white border-bottom-0 pl-2"
      role="tab"
    >
      <b-media no-body>
        <b-media-aside vertical-align="center">
          <img
            src="/images/icons/icon_movable.png"
            alt="movable"
            height="20"
            class="align-middle pointer"
          />
        </b-media-aside>
        <b-media-body>
          <b-row>
            <b-col>
              <a
                v-if="!onEditing"
                href="#"
                class="lead"
                @click="toggleEditing(true)"
              >
                <u>{{ item.moduleName }}</u>
              </a>
              <div v-else>
                <b-input-group>
                  <b-form-input
                    v-model="moduleName"
                    type="text"
                    class="border-right-0"
                    autofocus
                    :state="validateModuleName"
                    @blur="toggleEditing(false)"
                    @keydown.esc="toggleEditing(false)"
                    @keyup.enter="submit"
                  />
                  <template v-slot:append>
                    <b-input-group-text class="bg-white">
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm text-warning"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <span v-if="!validateModuleName" class="mt-1 small text-danger">
                  ไม่เกิน 100 ตัวอักษร
                </span>
              </div>
            </b-col>
            <b-col cols="auto">
              <span
                v-b-toggle="`collapse-${item.moduleId}`"
                class="float-right"
              >
                <i class="fa fa-angle-up when-opened" aria-hidden="true"></i>
                <i class="fa fa-angle-down when-closed" aria-hidden="true"></i>
              </span>
            </b-col>
          </b-row>
        </b-media-body>
      </b-media>
    </b-card-header>

    <b-collapse
      :id="`collapse-${item.moduleId}`"
      :visible="true"
      role="tabpanel"
    >
      <b-card-body style="padding-left: 34px">
        <b-row>
          <b-col>
            <b-form-checkbox
              :id="`autoNaming_${item.moduleId}`"
              v-model="autoNaming"
              :disabled="!hasLectures"
              name="autoNaming"
              size="sm"
            >
              ตั้งชื่อไฟล์อัตโนมัติ
            </b-form-checkbox>
            <b-form v-if="autoNaming" inline>
              <b-input-group>
                <b-input
                  v-model="allLectureName"
                  class="mb-2 mb-sm-0"
                  placeholder=""
                  autofocus
                ></b-input>
                <b-input-group-append>
                  <b-button
                    :variant="
                      allLectureName || onUpdating
                        ? 'outline-secondary'
                        : 'primary'
                    "
                    :disabled="onUpdating"
                    @click="setAllLectuceName"
                  >
                    ปรับใช้
                    <span
                      v-if="onUpdating"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="outline-danger" @click="remove">
              <i class="fa fa-trash" aria-hidden="true"></i> ลบหัวข้อนี้
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col :class="{ 'pt-5': !hasLectures }">
            <draggable
              :id="item.moduleId"
              tag="div"
              :list="courseModuleList[this.index].lectures"
              :disabled="!enabledDraggable"
              ghost-class="ghost"
              v-bind="dragOptions"
              :move="checkMove"
              @start="onDragStart"
              @end="dragging = false"
            >
              <LectureItem
                v-for="lecture in courseModuleList[this.index].lectures"
                :id="lecture.lectureId"
                :key="lecture.lectureId"
                :item="lecture"
                :module-id="item.moduleId"
                :company="company"
                @on-editing="toggleDraggable"
              />
            </draggable>
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="addLecture()"
            >
              <i class="fa fa-plus" aria-hidden="true"></i> เพิ่มบทเรียนใหม่
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
    <b-modal v-model="isOverlayVisible" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered>
      <div class="text-center">
        <div class="p-5 rounded d-flex align-items-center justify-content-center">
          <div class="align-items-center">
            <b-spinner variant="primary" label="Spinning" style="width: 3rem; height: 3rem;"></b-spinner>
            <div class="ml-3">
              <h2 class="mt-2">กรุณารอสักครู่</h2>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import draggable from "csq-vue-multi-draggable";
import LectureItem from "./LectureItem";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ModuleItem",
  components: {
    draggable,
    LectureItem,
  },
  props: {
    company: { default: null, type: Object },
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      moduleName: this.item.moduleName,
      courseId: this.$route.params.courseId,
      onEditing: false,
      onUpdating: false,
      autoNaming: false,
      allLectureName: null,
      dragging: false,
      enabledDraggable: true,
      dragMovedContext: {
        fromIndex: null,
        fromElement: null,
        toIndex: null,
        toElement: null,
      },
      isOverlayVisible: false
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseModuleList",
      "courseAttributeUpdated",
    ]),
    hasLectures() {
      try {
        const courseLength = this.courseModuleList[this.index].lectures.length;
        return courseLength;
      } catch (error) {
        return false;  
      }
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    dragOptions() {
      return {
        animation: 100,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    validateModuleName() {
      return this.moduleName.length >= 0 && this.moduleName.length <= 100;
    },
  },
  watch: {
    dragging: function (newValue) {
      if (newValue === false) {
         setTimeout(() => {
          this.reorder();
          }, 100);
      }
    },
    hasLectures(newHasLectures) {
      if (newHasLectures === false) {
        this.reloadModule();
      }
    }
  },
  methods: {
    ...mapActions("CourseManagement", [
      "updateModule",
      "fetchCourseModulesList",
      "deleteModule",
      "createLecture",
      "reorderLecture",
      "updateLecture",
      "getCourseInfo",
    ]),
    addLecture: function () {
      this.createLecture({
        courseId: this.courseId,
        moduleId: this.item.moduleId,
      }).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.fetchCourseModulesList({
          courseId: this.courseId,
        });
      });
    },

    reloadModule() {
      this.isOverlayVisible = true;
      this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });
      this.fetchCourseModulesList({
        courseId: this.courseId,
      }).then(() => {
        this.isOverlayVisible = false;
      });
    },
    onDragStart: function(e) {
      this.dragMovedContext = {
        fromIndex: e.oldDraggableIndex,
        toIndex: e.oldIndex,
      };
      this.dragging = true
    },

    checkMove: function (e) {
      this.dragMovedContext = {
        fromIndex: e.draggedContext.index,
        fromElement: e.draggedContext.element,
        toIndex: e.relatedContext.index,
        toElement: e.relatedContext.element,
        toModuleId: e.to.closest('div').id,
      };
    },

    reorder: function () {
      const fromIndex = this.dragMovedContext.fromIndex;
      const fromModuleId = this.dragMovedContext.fromElement !== undefined ? this.dragMovedContext.fromElement.moduleId : "";
      const toModuleId = this.dragMovedContext.toModuleId;
      const selectedLectureId = this.dragMovedContext.fromElement !== undefined ? this.dragMovedContext.fromElement.lectureId : "";

      if (toModuleId) {
        const toModuleIndex = this.courseModuleList.findIndex(item => item.moduleId === parseInt(toModuleId));
        const toIndex = this.courseModuleList[toModuleIndex].lectures.findIndex(item => item.lectureId === selectedLectureId);

        if (fromIndex != toIndex){
        const moveToAfterIndex = toIndex - 1;
        let moveToAfterLectureId = null;

        if (toIndex === 0) {
          moveToAfterLectureId = null;
        } else {
          // หา lectureId ที่อยู่เหนือ lecture ที่ถูกแทนที่ 1 ระดับ
          const moveToAfterLecture =
            this.courseModuleList[toModuleIndex]?.lectures[moveToAfterIndex] ||
            this.courseModuleList[this.index].lectures[moveToAfterIndex];
          moveToAfterLectureId = moveToAfterLecture.lectureId;
        }

        this.isOverlayVisible = true;
        this.reorderLecture({
          courseId: this.courseId,
          lectureId: selectedLectureId,
          moduleId: fromModuleId,
          to: {
            moduleId: toModuleId,
            afterLectureId: moveToAfterLectureId,
          },
        }).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: "numberOfStudent,numberOfActiveStudent",
          });
          this.fetchCourseModulesList({
            courseId: this.courseId,
          }).then(() => {
            this.isOverlayVisible = false;
          });
        });
      } else {
        this.isOverlayVisible = true;
        this.lectures = this.courseModuleList[this.index].lectures;
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.fetchCourseModulesList({
          courseId: this.courseId,
        }).then(() => {
          this.isOverlayVisible = false;
        });
      }} else {
        this.isOverlayVisible = true;
        this.lectures = this.courseModuleList[this.index].lectures;
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.fetchCourseModulesList({
          courseId: this.courseId,
        }).then(() => {
          this.isOverlayVisible = false;
        });
      }
    },

    async submit() {
      if (this.validateModuleName) {
        await this.updateModule({
          courseId: this.courseId,
          moduleId: this.item.moduleId,
          moduleName: this.moduleName,
        }).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: "numberOfStudent,numberOfActiveStudent",
          });
          this.toggleEditing(false);
          this.fetchCourseModulesList({
            courseId: this.courseId,
          });
        });
      }
    },

    async setAllLectuceName() {
      const lectures = this.courseModuleList[this.index].lectures;
      let i = 1;
      await lectures.forEach((lecture) => {
        this.onUpdating = true;
        this.updateLecture({
          courseId: this.courseId,
          lectureId: lecture.lectureId,
          lectureName: `${this.allLectureName} ${i++}`,
          lectureFreeFlag: lecture.lectureFreeFlag,
          lectureType: lecture.lectureType || "VIDEO",
        });
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });

      await this.fetchCourseModulesList({
        courseId: this.courseId,
      }).then(() => (this.onUpdating = false));
    },

    remove() {
      this.$bvModal
        .msgBoxConfirm(
          `คุณต้องการลบหัวข้อ ${this.item.moduleName} ใช่หรือไม่?`,
          {
            title: "แจ้งเตือนการลบหัวข้อ",
            buttonSize: "sm",
            okTitle: "ลบหัวข้อ",
            okVariant: "primary",
            cancelTitle: "ยกเลิก",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            headerCloseVariant: "white",
            headerCloseContent: "&times;",
            headerBgVariant: "danger",
            headerClass: "py-2 px-3 border-0",
            footerClass: "p-3 border-0",
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteModule({
              courseId: this.courseId,
              moduleId: this.item.moduleId,
            }).then(() => {
              this.getCourseInfo({
                courseId: this.courseId,
                temp: true,
                excepts: "numberOfStudent,numberOfActiveStudent",
              });
              this.fetchCourseModulesList({
                courseId: this.courseId,
              });
            });
          }
        });
    },

    toggleEditing(onEditing) {
      this.onEditing = onEditing;
      this.$emit("on-editing", onEditing);
    },

    toggleDraggable(onEditing) {
      this.$emit("on-editing", onEditing);
      this.enabledDraggable = !onEditing;
    },

    toggleCollapse(collapseId) {
      this.$root.$emit("bv::toggle::collapse", collapseId);
    },
  },
};
</script>

<style lang="scss" scope>
#menu > li.dropdown_menu > a.collapsed > .fa.arrow:before {
  content: "\f105";
}

.fa.arrow:before {
  content: "\f107";
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.ghost {
  opacity: 0;
  background: #fff;
}
</style>

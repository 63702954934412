<template>
  <div>
    <div class="mb-3">
      <b-row>
        <b-col cols="4">
          <b-input-group>
            <b-form-input
              v-model="q"
              placeholder="ค้นหาโปรโมชั่น"
              style="padding: 1rem; display: inline-block; width: 30%"
              @keyup.enter="sendKeyword"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="sendKeyword">ค้นหา</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="8" class="text-right">
          <b-button
            @click="modalPromotionShow((ccd_id = null))"
            class="create-new-link-button"
            variant="primary"
          >
            <i class="fa fa-plus-square" aria-hidden="true"></i>
            สร้างโปรโมชั่นใหม่
          </b-button>
        </b-col>
      </b-row>
    </div>

    <template v-if="isLoading">
      <div class="text-center mt-5 text-csq">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
        <p>Loading...</p>
      </div>
    </template>
    <div style="text-align: center" v-else-if="listResult.length == 0">
      <p class="mt-5 promotion-on-info">{{ "ไม่มีโปรโมชั่น" }}</p>
    </div>
    <b-table
      v-else
      id="promotion-list-table"
      hover
      :fields="fields"
      :items="listResult"
    >
      <template v-slot:cell(ccd_conditionType)="data">
        <template>
          <span>{{
            data.item.ccd_conditionType === "COURSE_NUMBER"
              ? "คอร์สที่ซื้อขั้นต่ำ"
              : "ยอดซื้อขั้นต่ำ"
          }}</span>
        </template>
      </template>
      <template v-slot:cell(ccd_discountValue)="data">
        <template>
          <span
            >{{
              data.item.ccd_discountType === "FIX"
                ? comId == 519 ? data.item.ccd_discountValue/1.07 : data.item.ccd_discountValue
                : data.item.ccd_discountPercent | formatNumber
            }}
            {{ data.item.ccd_discountType === "FIX" ? " บาท" : " %" }}
          </span>
        </template>
      </template>

      <template v-slot:cell(ccd_startDate)="data">
        <template>
          <span>{{ data.item.ccd_startDate | thaiDateFilter }}</span></template
        >
      </template>
      <template v-slot:cell(ccd_endDate)="data">
        <template>
          <span>{{ data.item.ccd_endDate | thaiDateFilter }}</span></template
        >
      </template>
      <template v-slot:cell(option)="data">
        <div>
          <b-button
            variant="outline-primary"
            size="sm"
            class="mr-1"
            @click="modalPromotionShow(data.item.ccd_id)"
          >
            แก้ไขโปรโมชั่น
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="confirmRemovePromotion(data.item.ccd_id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row class="mt-4">
      <b-col cols="6 ">
        <div class="d-flex justify-content-start align-items-center">
          <p class="pr-3">แสดง</p>
          <b-form-select
            v-model="selectedShow"
            style="width: 75px"
            @change="changeShowData"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </b-form-select>
          <p class="pl-3">จาก {{ rows }} รายการ</p>
        </div>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="promotion-list-table"
          align="right"
          last-number
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      centered
      v-model="ModalShowPromotion"
      title="เกณฑ์การให้ส่วนลด"
      size="lg"
      :hide-footer="true"
      ref="modal-promotion"
    >
      <div>
        <ModalPromotion
          :ccd_id="ccd_id"
          @hideModalPromotion="hideModalPromotion($event)"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import ModalPromotion from "../modal/ModalPromotion.vue";
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
export default {
  components: {
    ModalPromotion,
  },
  mixins: [dateMixins],
  mounted() {
    this.fetchPromotionList();
  },

  filters: {
    formatNumber: function (value) {
      if (!isNaN(value)) {
        return Numeral(value).format("0,0.00");
      } else {
        return value;
      }
    },
  },
  props: {
    company: {
      type: Object,
      default: null,
    },
    user: { default: null, type: Object },
  },
  data() {
    return {
      q: "",
      comId: this.$store.state.Company.companyInfo.id,
      fields: [
        { key: "ccd_name", label: "โปรโมชั่น", thStyle: "width: 250px" },
        { key: "ccd_conditionType", label: "ประเภทเงื่อนไขส่วนลด" },
        { key: "ccd_discountValue", label: "ส่วนลด" },
        { key: "ccd_startDate", label: "วันที่เริ่มโปรโมชั่น" },
        { key: "ccd_endDate", label: "วันที่สิ้นสุดโปรโมชั่น" },
        { key: "option", label: "" },
      ],
      items: [],
      ccd_id: null,
      ModalShowPromotion: false,
      coursePromotionsDetails: [],
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
    };
  },

  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "coursePromotions"]),

    rows() {
      return this.coursePromotions.length;
    },

    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      var showData = this.coursePromotions.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      );
      return showData;
    },
  },
  created() {},
  methods: {
    ...mapActions("CourseManagement", [
      "getPromotionList",
      "ondeletePromotion",
    ]),
    async fetchPromotionList() {
      await this.getPromotionList(null).then({});
    },

    hideModalPromotion() {
      this.$refs["modal-promotion"].hide();
    },

    changeShowData() {
      this.perPage = this.selectedShow;
    },

    modalPromotionShow(ccd_id) {
      this.ModalShowPromotion = true;
      this.ccd_id = ccd_id;
    },

    async sendKeyword() {
      if (this.q) {
        await this.getPromotionList(this.q).then({});
      } else {
        await this.getPromotionList(null).then({});
      }
    },

    async confirmRemovePromotion(ccd_id) {
      this.$bvModal
        .msgBoxConfirm(`คุณต้องการลบโปรโมชั่นหรือไม่ ?`, {
          title: "แจ้งเตือนการลบโปรโมชั่น",
          size: "ml",
          buttonSize: "sm px-3",
          okVariant: "primary",
          okTitle: "ลบโปรโมชั่น",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-primary",
          footerClass: "p-2 msgbox-footer",
          hideHeaderClose: false,
          centered: true,
          bodyClass: "msgbox-content",
          headerBgVariant: "danger",
        })
        .then((confirm) => {
          if (confirm) {
            this.ondeletePromotion(ccd_id).then(() => {
              this.getPromotionList(null);
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.promotion-on-info {
  font-size: 30px;
  font-weight: 500;
}
</style>
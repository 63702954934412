<template>
  <div>
    <b-row>
      <b-col md="6" lg="4">
        <SearchBox :title="`ชื่อกลุ่มคอร์สเรียน`" @typed="search" />
      </b-col>
    </b-row>

    <b-table
      :fields="fields"
      :items="items"
      :busy="isLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      small
      sort-icon-left
      responsive
      table-class="mt-4 border-bottom"
    >
      <template v-slot:cell(name)="props">{{ props.item.courseName }}</template>
      <template v-slot:cell(courses)="{ item }">
        <ShowCoursesInCourseGroupsTable :course-group="item" />
      </template>
      <template v-slot:cell(selection)="{ item }">
        <ToggleSelectionButton
          item-key="courseGroups"
          :item-value="{
            id: item.courseGroupId,
            name: item.courseGroupName,
            type: 'กลุ่มคอร์สเรียน'
          }"
        />
      </template>
      <div slot="table-busy" class="text-center text-danger my-2">
        <Loading />
      </div>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      prev-text="ก่อนหน้า"
      next-text="ถัดไป"
      :hide-goto-end-buttons="true"
      size="sm"
      align="end"
    ></b-pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import SearchBox from "@/components/shared/SearchBox";
import Loading from "@/components/shared/Loading";
import ShowCoursesInCourseGroupsTable from "./ShowCoursesInCourseGroupsTable";
import ToggleSelectionButton from "./ToggleSelectionButton";

export default {
  name: "SelectCourseGroupsTable",
  components: {
    SearchBox,
    Loading,
    ShowCoursesInCourseGroupsTable,
    ToggleSelectionButton
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "courseGroupName", sortable: true, label: "ชื่อกลุ่มคอร์ส" },
        {
          key: "allStudentsCount",
          sortable: false,
          label: "จำนวนผู้เข้าเรียนทั้งหมด",
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        },
        {
          key: "activeStudentsCount",
          sortable: false,
          label: "จำนวนผู้เข้าเรียนปัจจุบัน",
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        },
        {
          label: "จำนวนคอร์สในกลุ่ม",
          key: "coursesCount",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        },
        {
          label: "ดูรายชื่อคอร์สในกลุ่ม",
          key: "courses",
          sortable: false,
          class: "text-center"
        },
        {
          label: "",
          key: "selection",
          sortable: false,
          thStyle: "width: 70px"
        }
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: "courseGroupName",
      sortDesc: false,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters("Company", ["companyInfo"]),
    ...mapGetters("CourseGroupManagement", ["courseGroupItems"])
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    ...mapActions("CourseGroupManagement", ["fetchCourseGroupItems"]),
    async fetch() {
      this.isLoading = true;
      await this.fetchCourseGroupItems({
        companyId: this.companyInfo.id
      }).then(() => {
        this.isLoading = false;
        this.items = this.courseGroupItems;
      });
    },
    search(q) {
      this.items = this.courseGroupItems.filter(item =>
        item.courseGroupName.toLowerCase().includes(q.toLowerCase())
      );
    }
  }
};
</script>

<template>
  <b-modal
    v-model="show"
    title="ยืนยันการเผยแพร่คอร์ส"
    hide-footer
    @hide="close()"
  >
    <div class="modal-body p-0">
      <div class="text-center">
        <h1 class="py-4">
          <b-icon-exclamation-circle
            scale="2.4"
            class="text-danger"
          />
        </h1>
        <span>{{ message }}</span>
      </div>
    </div>

    <div class="modal-footer text-center border-top-0 px-0 pt-5">
      <div>
        <b-button
          variant="outline-secondary"
          data-dismiss="modal"
          @click="close()"
        >
          ปิด
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmPublishCourseModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
  },
  methods: {
    async close() {
      this.$emit('closed');
    },
  }
};
</script>

<template>
  <div class="outer">
    <div class="inner bg-container">
      <div class="row">
        <div class="col">
          <b-card-group>
            <b-card>
              <div slot="header">
                <i class="fas fa-list-alt" aria-hidden="true"></i>
                รายการข้อสอบ
              </div>
              <ExamsDetailsTable />
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExamsDetailsTable from "@/components/pages/exam-analytics/exams-details/exams-details-table/ExamsDetailsTable.vue";
export default {
  components: {
    ExamsDetailsTable
  }
};
</script>

<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="รายละเอียดผู้เรียนในคอร์ส"></PageHeader>
      <b-row>
        <b-col cols="12">
          <courseIntructor
            :apiRoute="`/v1/analytics/courses/getCourseForIntructors`"
            :perPage="20"
          ></courseIntructor>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import courseIntructor from "./panel/CourseInstructorPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {};
  },
  components: {
    PageHeader,
    courseIntructor
  }
};
</script>
<style scoped></style>

import Numeral from "numeral";

export default {
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    }
  },

  data() {
    return {
      tableFields: [
        { label: "", key: "image", sortable: false },
        { label: "ชื่อคอร์สเรียน", key: "courseName", sortable: true },
        {
          label: "ผู้สอน",
          key: "courseOwner.ownerName",
          sortable: false
        },
        {
          label: "จำนวนผู้เรียนทั้งหมด",
          key: "numberOfStudent",
          sortable: true,
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        },
        {
          label: "จำนวนผู้เรียนปัจจุบัน",
          key: "numberOfActiveStudent",
          sortable: true,
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        }
      ],
      sortOptions: [
        {
          value: { sort_by: "updated", sort_order: "desc" },
          text: "อัปเดตล่าสุด"
        },
        {
          value: { sort_by: "name", sort_order: "asc" },
          text: "ชื่อคอร์ส"
        }
      ],
      sort: {
        by: "name",
        desc: false
      },
      fetchParams: {
        company_id: null,
        page: null,
        limit: 10,
        q: null,
        sort_by: "name",
        sort_order: "asc",
        excepts: "coursePrice,tfac"
      },
      limitOptions: [10, 30, 50],
      imageProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 64,
        height: 48,
        class: "course-image"
      },
      learningStatusOptions: [
        { value: "completeEnrollment", text: "ผ่านหลักสูตร", modalShow: false },
        {
          value: "unenroll",
          text: "ยกเลิกการลงทะเบียน",
          modalShow: false
        }
      ],
      selectedLearningStatusOption: null
    };
  },

  computed: {
    sortOrder() {
      if (this.sort.desc) {
        return "desc";
      } else {
        return "asc";
      }
    }
  },
  watch: {
    sort: {
      async handler() {
        if (this.sort.by === "courseName") {
          this.fetchParams.sort_by = "name";
        } else {
          this.fetchParams.sort_by = this.sort.by;
        }
        if (this.sort.desc) {
          this.fetchParams.sort_order = "desc";
        } else {
          this.fetchParams.sort_order = "asc";
        }
        await this.fetchCourseListEnrollment(this.fetchParams);
      },
      deep: true
    }
  },
  methods: {
    setFetchParamsPage(page) {
      this.fetchParams.page = page;
      this.fetchCourseList(this.fetchParams);
    },
    setFetchParamsLimit(limit) {
      this.fetchParams.limit = limit;
      this.fetchParams.page = 1;
      this.fetchCourseList(this.fetchParams);
    },
    getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchParams.page = 1;
      this.fetchCourseList(this.fetchParams);
    }
  }
};

<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="คอร์สทั้งหมดในระบบ"></PageHeader>
      <b-row>
        <b-col cols="12">
          <courseTable
            :apiRoute="`/v1/analytics/courses/getCourseOverview?template=A`"
            :perPage="20"
          ></courseTable>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import courseTable from "./panel/CourseAllPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {};
  },
  components: {
    PageHeader,
    courseTable
  }
};
</script>
<style scoped></style>

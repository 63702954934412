<template>
  <b-container fluid="xl">
    <layout>
      <template v-slot:header>รวม หมวดหมู่คอร์ส ทั้งหมด</template>
      <template v-slot:body>
        <div style="float: right">
          <b-button variant="primary" class="m-1 p-2" v-b-modal="'create-tag'"
            >สร้าง หมวดหมู่คอร์ส ใหม่</b-button
          >
        </div>
        <div>
          <b-button
            :variant="selected == null ? 'primary' : 'outline-primary'"
            class="m-1 p-2"
            @click="changeFilter(null)"
            >แสดงทั้งหมด</b-button
          >
          <b-button
            :variant="selected == 'own' ? 'primary' : 'outline-primary'"
            class="m-1 p-2"
            @click="changeFilter('own')"
            >แสดง หมวดหมู่คอร์ส ของคุณ</b-button
          >
          <b-button
            :variant="selected == 'default' ? 'primary' : 'outline-primary'"
            @click="changeFilter('default')"
            class="m-1 p-2"
            >แสดง หมวดหมู่คอร์ส ตั้งต้น</b-button
          >
        </div>

        <div style="float: right" class="mt-2 mb-4">
          <span> ค้นหา </span
          ><b-form-input
            v-model="search"
            placeholder="พิมพ์ชื่อ หมวดหมู่คอร์ส"
            style="padding: 1rem; display: inline-block; width: 80%"
          >
          </b-form-input>
        </div>
        <div class="mt-2 mb-4">
          <span style="color: #60aaff"
            >กำลังแสดง {{ getItems().length }} รายการ (จากทั้งหมด
            {{ result.length }} รายการ)</span
          >
        </div>
        <div>
          <b-table
            id="tag-list"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="getItems()"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
            striped
            class="align-v"
          >
            <template v-slot:cell(type)="data">
              <span
                class="py-1 px-5"
                :style="{
                  color: 'white',
                  'background-color': data.item.comId ? '#eb8123' : '#3464ff',
                }"
                >{{
                  data.item.comId
                    ? "หมวดหมู่คอร์ส ของคุณ"
                    : "หมวดหมู่คอร์ส ตั้งต้น"
                }}</span
              ></template
            >

            <template v-slot:cell(btn)="data">
              <div v-if="data.item.comId">
                <b-button
                  variant="outline-warning"
                  class="mx-1 py-0 px-4"
                  style="background-color: white"
                  v-b-modal="'update-tag-' + data.item.tagId"
                  >แก้ไข</b-button
                >
                <b-button
                  variant="outline-danger"
                  class="mx-1 py-0 px-4"
                  style="background-color: white"
                  v-b-modal="'delete-tag-' + data.item.tagId"
                  >ลบ</b-button
                >
              </div>
              <div>
                <b-modal
                  size="lg"
                  :id="'update-tag-' + data.item.tagId"
                  title="แก้ไข หมวดหมู่คอร์ส"
                  :hide-footer="true"
                >
                  <UpdateTagModal
                    @edited="fetch()"
                    :tag="result"
                    :id="data.item.tagId"
                    :course="course"
                  ></UpdateTagModal>
                </b-modal>
              </div>
              <div>
                <b-modal
                  size="lg"
                  :id="'delete-tag-' + data.item.tagId"
                  title="ลบ หมวดหมู่คอร์ส"
                  :hide-footer="true"
                >
                  <DeleteTagModal
                    @deleted="fetch()"
                    :tag="result"
                    :id="data.item.tagId"
                    :course="course"
                  ></DeleteTagModal>
                </b-modal>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div>
          <b-row>
            <b-col cols="6">
              <span>แสดง </span>
              <b-form-select
                v-model="perPage"
                :options="options"
                class="ml-2"
                style="width: 20%"
              ></b-form-select>
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="getItems().length"
                :per-page="perPage"
                aria-controls="tag-list"
                align="end"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-modal
            size="lg"
            :id="'create-tag'"
            title="สร้าง หมวดหมู่คอร์ส ใหม่"
            :hide-footer="true"
          >
            <NewTagModal @created="fetch()" :tag="result"></NewTagModal>
          </b-modal>
        </div>
      </template>
    </layout>
  </b-container>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import NewTagModal from "./modal/NewTagModal";
import UpdateTagModal from "./modal/UpdateTagModal";
import DeleteTagModal from "./modal/DeleteTagModal";
export default {
  components: {
    layout,
    NewTagModal,
    UpdateTagModal,
    DeleteTagModal,
  },
  props: ["apiRoute", "apiRoute2"],
  data() {
    return {
      result: [],
      course: [],
      options: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      search: null,
      isBusy: true,
      perPage: 10,
      selected: null,
      currentPage: 1,
      fields: [
        {
          key: "tagName",
          label: "หมวดหมู่คอร์ส",
          class: "align-middle",
          sortable: true,
        },
        {
          key: "course",
          label: "จำนวนคอร์ส",
          class: "align-middle",
          sortable: true,
        },
        {
          key: "type",
          label: "ประเภท",
          class: "align-middle",
        },
        {
          key: "btn",
          label: "",
          class: "align-middle",
        },
      ],
    };
  },

  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data.data;
      let res2 = await api.get(this.apiRoute2);
      this.course = res2.data;
      this.result.forEach((i) => {
        i.course = this.course.filter((c) => c.tag_id == i.tagId).length;
      });
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
    },
    changeFilter(selected) {
      this.selected = selected;
      this.currentPage = 1;
    },
    getItems() {
      var temp = null;
      if (this.selected == null) {
        temp = this.result;
      } else if (this.selected == "own") {
        temp = this.result.filter((i) => i.comId);
      } else if (this.selected == "default") {
        temp = this.result.filter((i) => !i.comId);
      }

      if (this.search != null)
        return temp.filter((i) =>
          i.tagName
            .toLowerCase()
            .trim()
            .includes(this.search.toLowerCase().trim())
        );
      else {
        return temp;
      }
    },
  },

  async mounted() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    this.fetch();
  },
};
</script>
<style scoped>
h6 {
  margin-bottom: 0 !important;
}
</style>

<template>
  <div class="d-flex flex-column">
    <span class="detail-font font-weight-bold">Loading...</span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>

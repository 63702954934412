<template>
  <div>
    <PageHeader title="สร้าง Live ใหม่"></PageHeader>
    <div class="outer">
      <b-container fluid="md" class="create-page">
        <b-form @submit="saveToListPage(false)">
          <FormData
            @checkEndTimeValid="checkEndTimeValid"
            @checkEndDateValid="checkEndDateValid"
          />

          <div class="my-4">
            <label class="font-weight-bold" for="type-radio">รูปแบบ Live</label>
            <b-form-radio-group id="type-radio" v-model="liveType" required>
              <b-form-radio value="ZOOM-SDK">
                ZOOM SDK
              </b-form-radio>
              <b-form-radio value="ZOOM-URL">
                ZOOM URL
              </b-form-radio>
              <b-form-radio value="OTHER">
                Youtube Live
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <component :is="selectedCom" class="mt-3"></component>

          <hr />

          <b-button
            variant="primary"
            type="submit"
            :disabled="!isEndTimeValid || !isEndDateValid"
          >
            สร้าง Live
          </b-button>
          <b-button variant="outline-secondary" :to="{ name: 'live.index' }">
            ยกเลิก
          </b-button>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import FormData from "./form/CreateEditMeetingFormData.vue";
import FormSettings from "./form/CreateEditMeetingFormSetting.vue";
import FromThirdParty from "./form/CreateEditMeetingFormThirdParty.vue";
import ZoomUrl from "./form/CreateEditMeetingFormUrl.vue";
import PageHeader from "@/components/layouts/PageHeader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LiveCreatePage",
  data() {
    return {
      liveType: "ZOOM-SDK",
      isEndTimeValid: true,
      isEndDateValid: true,
    };
  },
  props: {
    company: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions("LiveForm", [
      "setFormDatetime",
      "resetModified",
      "resetForm",
    ]),
    ...mapActions("LiveMeeting", [
      "submitZoomCreateForm",
      "submitOtherCreateForm",
      "submitZoomUrlCreateForm",
    ]),
    onSubmit() {
      console.log("Submit is called");
    },
    saveToListPage(isDraft = false) {
      if (this.liveType == "ZOOM-SDK") {
        this.submitZoomCreateForm({ vm: this, isDraft: isDraft });
      } else if (this.liveType == "ZOOM-URL") {
        this.submitZoomUrlCreateForm({ vm: this, isDraft: isDraft });
      } else if (this.liveType == "OTHER") {
        this.submitOtherCreateForm({ vm: this, isDraft: isDraft });
      }
      this.$router.push({
        name: "live.index",
      });
    },
    checkEndDateValid(isValid) {
      this.isEndDateValid = isValid;
    },
    checkEndTimeValid(isValid) {
      this.isEndTimeValid = isValid;
    },
  },
  computed: {
    ...mapGetters("LiveForm", ["isModified", "isEnableSaveAsDraft"]),
    selectedCom() {
      if (this.liveType == "ZOOM-SDK") {
        return FormSettings;
      } else if (this.liveType == "ZOOM-URL") {
        return ZoomUrl;
      } else {
        return FromThirdParty;
      }
    },
  },
  components: {
    FormData,
    FormSettings,
    FromThirdParty,
    PageHeader,
  },
  created() {
    this.resetForm();
    this.setFormDatetime();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isModified) next();
    else {
      this.$bvModal
        .msgBoxConfirm(
          "หากคุณออกจากหน้านี้ ข้อมูลที่คุณกรอกไว้จะไม่ได้บันทึก",
          {
            title: "คุณยังไม่ได้บันทึกข้อมูล",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "ออกจากหน้านี้",
            cancelVariant: "primary",
            cancelTitle: "บันทึกแบบ draft",
            hideHeaderClose: false,
          }
        )
        .then((confirm) => {
          if (confirm == null) {
            next(false);
            return;
          } else if (!confirm) {
            if (this.selectedCom.name == "FormSettings") {
              this.submitZoomCreateForm({ vm: this, isDraft: true });
            } else if (this.selectedCom.name == "FromThirdParty") {
              this.submitOtherCreateForm({ vm: this, isDraft: true });
            } else if (this.selectedCom.name == "ZoomUrl") {
              this.submitZoomUrlCreateForm({ vm: this, isDraft: true });
            }
          } else {
            // Leave without saving
            this.resetModified();
          }
          next();
        });
    }
  },
};
</script>

<template>
  <div style>
    <b-row>
      <b-col cols>
        <layout :height="250">
          <template v-slot:header>
            <h6>สถิติการเรียนจบคอร์ส</h6>
          </template>
          <template v-slot:body>
            <div style="padding: 0.5rem">
              <b-row>
                <b-col class="text-left pl-0 mt-2" cols="7" offset="5">
                  <h6>ทั้งหมด {{ legendValue[0] + legendValue[1] }} คน</h6>
                </b-col>
                <b-col cols="4">
                  <PieChart
                    :chart-data="datacollection"
                    :options="option"
                    :height="200"
                  ></PieChart>
                </b-col>
                <b-col cols="8">
                  <my-legend
                    :num="2"
                    :color="['rgb(235,129,35)', 'rgb(237,237,237)']"
                  >
                    <template v-slot:1
                      >ผู้เรียนที่เรียนจบ{{ legendValue[0] }} คน</template
                    >
                    <template v-slot:2
                      >ผู้เรียนที่เรียนยังไม่จบ
                      {{ legendValue[1] }} คน</template
                    >
                  </my-legend>
                </b-col>
              </b-row>
            </div>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import layout from "@/components/pages/analytics/shared/Layout";
import myLegend from "@/components/pages/analytics/shared/LegendPieChart";
import api from "@/services/api.js";

export default {
  components: {
    PieChart,
    layout,
    myLegend,
  },
  props: ["apiRoute"],
  data() {
    return {
      result: {},
      datacollection: {},
      option: {},
      legendValue: [0, 0],
    };
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.fillData();
    this.fillOption();
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },
  methods: {
    fillData() {
      let data = [this.result.complete, this.result.inComplete];
      this.legendValue[0] = this.result.complete;
      this.legendValue[1] = this.result.inComplete;
      this.datacollection = {
        labels: [`ผู้เรียนที่เรียนจบ`, `ผู้เรียนที่เรียนยังไม่จบ`],
        datasets: [
          {
            backgroundColor: ["rgb(235,129,35)", "rgb(237,237,237)"],
            data: data,
          },
        ],
        borderWidth: 5,
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000",
          },
        },
        plugins: {
          datalabels: {
            color: "#343a40;",
            labels: {
              title: {
                font: {
                  weight: "600",
                  size: 16,
                },
              },
            },
            formatter: (value) => {
              // if (value > 0) {
              //   let sum = 0;
              //   let dataArr = ctx.chart.data.datasets[0].data;
              //   dataArr.map((data) => {
              //     sum += data;
              //   });
              //   sum = sum.toString();
              //   sum = sum.split(/(?=(?:...)*$)/);
              //   sum = sum.join(",");
              //   return sum;
              // } else {
              //   value = "";
              return value;
              // }
            },
          },
        },

        elements: {
          arc: {
            borderWidth: 0,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      };
    },
    getRandomInt() {
      let n = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      let n1 = Math.floor(Math.random() * (n - 0 + 1)) + 0;
      let n2 = n - n1;

      return { n1, n2 };
    },
  },
};
</script>

<style scoped>
h6 {
  font-size: 19px;
  color: #737373;
}
</style>

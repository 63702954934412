<template>
  <div class="mb-5">
    <PageHeader title="ลงทะเบียนเรียน" />
    <b-container fluid="xl" class="outer">
      <div>
        <b-nav tabs>
          <b-nav-item
            v-for="(item, index) in tabs"
            :key="index"
            :active="activeTabIndex === index"
            @click="activeTabIndex = index"
            >{{ item.label }}</b-nav-item
          >
        </b-nav>
      </div>
      <b-card class="border-top-0 rounded-0 no-shadow" body-class="py-4">
        <div class="border text-right p-2">
          <span class="text-primary">จำนวนคอร์สที่เลือก:</span>
          {{ selectedItems["courses"].length }},
          <span class="text-primary">จำนวนกลุ่มคอร์สที่เลือก:</span>
          {{ selectedItems["courseGroups"].length }}
        </div>
        <SelectCoursesTable v-if="activeTabIndex === 0" class="mt-4" />
        <SelectCourseGroupsTable v-if="activeTabIndex === 1" class="mt-4" />
      </b-card>
    </b-container>
    <CourseEnrollNavbar :sidebar-opened="sidebarOpened" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageHeader from "@/components/layouts/PageHeader";
import CourseEnrollNavbar from "./partials/CourseEnrollNavbar";
import SelectCoursesTable from "./partials/SelectCoursesTable";
import SelectCourseGroupsTable from "./partials/SelectCourseGroupsTable";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  name: "CourseEnrollStep1Page",
  components: {
    PageHeader,
    CourseEnrollNavbar,
    SelectCoursesTable,
    SelectCourseGroupsTable,
  },
  props: {
    sidebarOpened: Boolean,
  },
  mixins: [AuthMixin],
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      tabs: [{ label: "เลือกคอร์สเรียน", isActive: false }],
      activeTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters("CourseEnrollment", ["selectedItems"]),
  },
  mounted() {
    if (!this.IsTutorAdmin || this.comId == 524) {
      this.tabs.push({ label: "เลือกกลุ่มคอร์สเรียน", isActive: false });
    }
  },
};
</script>

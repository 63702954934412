import CourseAttendanceList from "@/components/pages/attendance/CourseAttendanceList.vue";
import ClassAttendanceList from "@/components/pages/attendance/ClassAttendanceList.vue";
import AddClassAttendance from "@/components/pages/attendance/AddClassAttendance.vue";
import ClassAttendanceInfo from "@/components/pages/attendance/ClassAttendanceInfo.vue";
import MemberAttendanceReport from "@/components/pages/attendance/MemberAttendanceReport.vue";

export default [
  {
    path: "attendance/overview",
    name: "attendance.overview",
    component: CourseAttendanceList,
  },
  {
    path: "members/attendance-report",
    name: "members.attendance.report",
    component: MemberAttendanceReport,
  },
  {
    path: "courses/:courseId/attendance/overview",
    name: "attendance.info",
    component: ClassAttendanceList,
    meta: {
      authRequired: true,
      permsRequired: ["crud-class-attendance"]
    }
  },
  {
    path: "courses/:courseId/attendance/add",
    name: "attendance.add",
    component: AddClassAttendance,
    meta: {
      authRequired: true,
      permsRequired: ["crud-class-attendance"]
    }
  },
  {
    path: "classes/:classId/attendance",
    name: "class.attendance.info",
    component: ClassAttendanceInfo,
    meta: {
      authRequired: true,
      permsRequired: ["crud-class-attendance"]
    }
  }
];

<template>
  <li class="dropdown_menu" :class="mainTagListCssObj">
    <a v-b-toggle.purchaseCollapse @click.prevent="toggleCollapse">
      <b-icon icon="cash" />
      <span class="link-title menu_hide ml-1">การชำระเงิน</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="purchaseCollapse" :visible="false" accordion="accordion">
      <main-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import _ from "lodash";
import AuthMixin from "@/mixins/auth-mixin.js";
import CustomUserPermissionMixin from "@/mixins/custom-user-permission-mixin.js";
import MainCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";

export default {
  components: {
    MainCollapseMenu,
  },
  mixins: [AuthMixin, CustomUserPermissionMixin],
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      memberId: JSON.parse(localStorage.getItem("user")).id,
      isActive: false,
      collapseList: [],
    };
  },
  mounted() {
    if ((this.isAccountingType() || this.isInsuranceType() || this.isTutorType()) && this.IsTutorAdmin) {
      this.collapseList.push(
        {
          title: "จัดการโปรโมชั่น",
          to: {
            name: "purchase.course.promotion.list",
          },
        },
        {
          title: "รายการแจ้งโอนเงิน",
          to: {
            name: "purchase.course-transfer-money-list",
          },
        },
        {
          title: "จัดการคูปอง",
          to: {
            name: "purchase.course.coupon.list"
          },
        }
      )
    }
    if ((this.isAccountingType() || this.isInsuranceType() || this.isTutorType()) && (this.IsTutorAdmin || this.isTutorInstructor)) {
      this.collapseList.push(
        {
          title: "รายการสั่งซื้อคอร์สเรียน",
          to: {
            name: "purchase.course.order.list",
          },
        },
      )
    }
    if ((this.isTutorType() && this.IsTutorAdmin) || this.comId == 519 && !this.isTutorInstructor) {
      this.collapseList.push(
        {
          title: "ลงทะเบียนจ่ายเงินสด",
          to: {
            name: "purchase.register.cash",
          },
        },
      )
    }
    if (
      (this.comId == 277 || this.comId == 505) &&
      this.isHideTransferMoney == true
    ) {
      this.collapseList.splice(2, 1);
    }
    if (this.canSpecificUserAccess) {
      this.collapseList = [
        {
          title: "รายการแจ้งโอนเงิน",
          to: {
            name: "purchase.course-transfer-money-list",
          },
        },
        {
          title: "ลงทะเบียนจ่ายเงินสด",
          to: {
            name: "purchase.register.cash",
          },
        },
      ]
    }
  },
  methods: {
    toggleCollapse() {},
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>

<template>
  <div>
    <label id="fieldset-1__BV_label_" for="duration" class="d-block mt-4">
      หมวดหมู่คอร์ส *
      <span v-if="courseAttributeUpdated['courseTags']" class="text-success">
        <i class="fa fa-check" aria-hidden="true"></i>
      </span>
    </label>
    <v-select
      v-model="courseInfo.courseTags"
      multiple
      :options="tags"
      label="tagName"
      placeholder="เลือกแท็ก"
      @input="setSelectedTags"
      :selectable="(option) => option.tagId != null"
      id="tagList"
    >
      <template #selected-option="{ comId, tagName }">
        <div v-if="comId != null" class="myTags">
          <h6>{{ tagName }}</h6>
        </div>
        <div v-else>
          <h6>{{ tagName }}</h6>
        </div>
      </template>

      <template v-slot:option="option">
        <div
          v-if="option.tagId == null && option.comId != null"
          class="myHeadGroup"
        >
          <span>
            {{ option.tagName }}
          </span>
        </div>
        <span
          v-else-if="option.tagId == null && option.comId == null"
          class="defaultHeadGroup"
        >
          <hr style="margin: 0px 0px 5px 0px" />
          <span>
            {{ option.tagName }}
          </span>
        </span>
        <span v-else-if="option.comId != null" class="myTagList">
          {{ option.tagName }}
        </span>
        <span v-else class="commonTagList">
          {{ option.tagName }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import api from "@/services/api";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "TagsForm",
  components: {
    vSelect
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      tags: [],
      selectedTags: []
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseInfo",
      "courseAttributeUpdated"
    ]),
    courseTags: {
      get() {
        let tags = this.courseInfo.courseTags;
        return tags;
      }
    }
  },
  created() {
    this.getTags();
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateCourseInfo",
      "updateCourseTags"
    ]),
    setSelectedTags() {
      this.selectedTags = this.courseInfo.courseTags.map(x => x.tagId);
      this.syncCourseTags();
    },
    getTags() {
      api
        .get(`/v1/tags`)
        .catch(({ response }) => {
          console.error(response);
        })
        .then(({ data }) => {
          this.tags = data.data;
          let findMyTag = 0;
          let findCommonTag = 0;
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].comId != null && findMyTag == 0) {
              findMyTag = 1;
              this.tags.splice(i, 0, {
                tagId: null,
                tagName: "หมวดหมู่ของฉัน",
                comId: this.tags[i].comId
              });
              this.tags.join();
            } else if (this.tags[i].comId == null && findCommonTag == 0) {
              this.tags.splice(i, 0, {
                tagId: null,
                tagName: "หมวดหมู่พิ้นฐาน",
                comId: null
              });
              this.tags.join();
              findCommonTag = 1;
            }
          }
        });
    },
    async syncCourseTags() {
      await this.updateCourseTags({
        courseId: this.courseId,
        tags: this.selectedTags
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent"
      });
    }
  }
};
</script>
<style>
#tagList .vs__dropdown-option {
  padding: 0px;
  display: flex;
}
#tagList .vs__selected {
  background: white;
  padding: 0.3em;
}
.myTags {
  padding: 0.3em;
  border-radius: 3px;
  background: #fde4cc;
}
.myTagList {
  background: #fde4cc;
  width: 100%;
  padding-left: 25px;
}
.myTagList:hover {
  background-color: #5897fb;
}
.commonTagList {
  width: 100%;
  padding-left: 25px;
}
.myHeadGroup {
  padding: 10px 0px 5px 25px;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  background: #fde4cc;
}
.defaultHeadGroup {
  padding: 0px 0px 7px 25px;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  background: white;
}
</style>
<template>
  <div>
    <PageHeader title="สรุปการเข้าชั้นเรียน"></PageHeader>
    <b-container>
      <b-row>
        <b-col>
          <div class="bg-container py-5">
            <b-row>
              <b-col cols="12" md="4">
                <search-box
                  title="พิมพ์เพื่อค้นหาคอร์ส"
                  @typed="getSearchKeyword"
                ></search-box>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-select
                  v-model="selectedStatusOption"
                  :options="status"
                  @change="onStatusOptionChanged($event)"
                ></b-form-select>
              </b-col>
            </b-row>

            <p v-if="!isLoading && fetchParams.q" class="mt-2">
              ผลการค้นหา {{ courseAttentionList.meta.total }} คอร์ส
            </p>

            <b-table
              id="course-list"
              :items="courseAttentionList.data"
              :fields="fields"
              :busy="isLoading"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              :per-page="perPage"
              sort-icon-left
              hover
              responsive
              class="mt-4 table-course"
            >
              <template v-slot:cell(image)="props">
                <b-img-lazy
                  slot="aside"
                  v-bind="imageProps"
                  :src="props.item.courseThumbnail"
                  class="course-image"
                  alt
                ></b-img-lazy>
              </template>
              <template v-slot:cell(courseName)="props">
                <router-link
                  :to="{
                    name: 'attendance.info',
                    params: { courseId: props.item.courseId },
                  }"
                >
                  {{ props.item.courseName }}
                </router-link>
              </template>
              <template v-slot:cell(tutorName)="props">
                {{ props.item.courseOwner.ownerName }}
              </template>
              <template v-slot:cell(status)="props">
                <span :style="getStatusStyle(props.item.courseStatus)">
                  {{ getStatusText(props.item.courseStatus) }}
                </span>
              </template>
              <div class="spinner-loading" slot="table-busy">
                <b-spinner></b-spinner>
              </div>
            </b-table>

            <div class="row mt-3">
              <div class="col-lg-6">
                <form class="form-inline">
                  <div class="form-group mb-2">
                    <label>แสดง</label>
                  </div>
                  <div class="form-group ml-2 mb-2">
                    <b-form-select
                      v-model="perPage"
                      :options="[10, 15, 30, 50]"
                      size="sm"
                      @input="setFetchParamsPerPage"
                    ></b-form-select>
                  </div>
                  <div class="form-group ml-2 mb-2">
                    <label>
                      จาก
                      {{ courseAttentionList.meta.total | formatNumber }}
                      รายการ
                    </label>
                  </div>
                </form>
              </div>
              <div class="col-lg-6">
                <nav class="float-right" aria-label="Page navigation example">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="courseAttentionList.meta.total"
                    :per-page="perPage"
                    @change="setFetchParamsPage"
                    aria-controls="course-list"
                  ></b-pagination>
                </nav>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "../../layouts/PageHeader";
import SearchBox from "../../shared/SearchBox";
import CourseMixin from "../../../mixins/course-mixin";
import authMixin from "@/mixins/auth-mixin.js";
import Numeral from "numeral";

export default {
  components: { PageHeader, SearchBox },
  mixins: [CourseMixin, authMixin],
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    },
  },
  data() {
    return {
      selectedStatusOption: null,
      fetchParams: {
        page: null,
        limit: null,
        q: null,
        sort_by: "name",
        sort_order: "asc",
      },
      fields: [
        { label: "", key: "image", sortable: false },
        { label: "ชื่อคอร์สเรียน", key: "courseName", sortable: true },
        {
          label: "ชื่อผู้สอน",
          key: "tutorName",
          sortable: false,
        },
        {
          label: "สถานะ",
          key: "status",
          sortable: false,
        },
        {
          label: "จำนวนคลาส",
          key: "numberOfClass",
          sortable: true,
          class: "text-right",
          formatter: (value) => {
            return Numeral(value).format("0,0");
          },
        },
        {
          label: "จำนวนผู้เรียนในปัจจุบัน",
          key: "numberOfActiveStudent",
          sortable: true,
          class: "text-right",
          formatter: (value) => {
            return Numeral(value).format("0,0");
          },
        },
      ],
      sort: {
        by: "name",
        desc: false,
      },
      imageProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 64,
        height: 48,
        class: "course-image",
      },
      perPage: 10,
      currentPage: 1,
      status: [],
    };
  },
  computed: {
    ...mapGetters("AttendanceManagement", ["isLoading", "courseAttentionList"]),
  },
  watch: {
    sort: {
      async handler() {
        if (this.sort.by === "courseName") {
          this.fetchParams.sort_by = "name";
        } else {
          this.fetchParams.sort_by = this.sort.by;
        }
        if (this.sort.desc) {
          this.fetchParams.sort_order = "desc";
        } else {
          this.fetchParams.sort_order = "asc";
        }
        await this.fetchCourseAttentionList(this.fetchParams);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchCourseAttentionList(this.fetchParams);
    this.getStatus();

    // Update limit and page in fetchParams
    this.fetchParams.limit = this.perPage;
    this.fetchParams.page = this.currentPage;
    await this.fetchCourseAttentionList(this.fetchParams);
  },
  methods: {
    ...mapActions("AttendanceManagement", ["fetchCourseAttentionList"]),
    getStatus() {
      let options = this.courseAttentionList.data
        .filter(
          (item, pos, self) =>
            !item.courseStatus.includes("PUBLISH_") &&
            self.findIndex((v) => v.courseStatus === item.courseStatus) === pos
        )
        .map((obj) => {
          return {
            value: obj.courseStatus,
            text: this.statusOptions.find(
              (data) => data.value === obj.courseStatus
            ).text,
          };
        });
      this.status = [this.statusOptions[0], ...options];
    },
    getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchParams.page = this.currentPage = 1;
      this.fetchCourseAttentionList(this.fetchParams);
    },
    onStatusOptionChanged(event) {
      this.fetchParams.page = this.currentPage = 1;
      this.fetchParams.status = event;
      this.fetchCourseAttentionList(this.fetchParams);
    },
    getStatusText(statusValue) {
      const statusOption = this.statusOptions.find(
        (data) => data.value === statusValue
      );
      return statusOption ? statusOption.text : "-";
    },
    getStatusStyle(statusValue) {
      let color = "";

      if (statusValue === "PUBLISH") {
        color = "#28a745";
      } else if (statusValue === "SUSPEND") {
        color = "#dc3545";
      } else if (statusValue === "PUBLISH_EDIT") {
        color = "#007bff";
      } else if (statusValue === "DRAFT") {
        color = "#ffc107";
      } else if (statusValue === "DISABLED") {
        color = "#6c757d";
      }
      return { color };
    },
    async setFetchParamsPerPage() {
      this.fetchParams.limit = this.perPage;
      this.fetchParams.page = this.currentPage = 1;
      await this.fetchCourseAttentionList(this.fetchParams);
    },
    async setFetchParamsPage(page) {
      this.fetchParams.page = page;
      await this.fetchCourseAttentionList(this.fetchParams);
    },
  },
};
</script>

<style scoped>
.table-course {
  overflow-x: unset !important;
}
.spinner-loading {
  text-align: center;
}
</style>

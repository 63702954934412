import LiveListPage from "@/components/pages/live-management/LiveListPage";
import LiveCreatePage from "@/components/pages/live-management/LiveCreatePage";
import LiveEditPage from "@/components/pages/live-management/LiveEditPage";

export default [
  {
    path: "/:company/live",
    name: "live.index",
    props: true,
    component: LiveListPage,
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  {
    path: "/:company/live/create",
    name: "live.form.create",
    props: true,
    component: LiveCreatePage,
    beforeEnter: (to, from, next) => {
      // If required param is missing
      if (Object.keys(to.params).length < 2) {
        next({
          name: "live.index",
          params: to.params
        });
      } else next();
    }
  },
  {
    path: "/:company/live/edit",
    name: "live.form.edit",
    props: true,
    beforeEnter: (to, from, next) => {
      // If no param
      if (Object.keys(to.params).length < 3)
        next({
          name: "live.index",
          params: to.params
        });
      else next();
    },
    component: LiveEditPage
  },
  {
    path: "/:company/live/*",
    redirect: {
      name: "live.index",
      path: "/:company/live"
    }
  }
];

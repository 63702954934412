<template>
  <div class="exams-page">
    <PageHeader title="จัดการข้อสอบ"></PageHeader>
    <b-container>
      <div class="bg-container">
        <div class="exams-topper mt-4">
          <h5 class="font-weight-bold">ข้อสอบทั้งหมดในระบบ</h5>
          <div class="exams-search">
            <search-box
              class="search-box-exams"
              title="พิมพ์ชื่อคอร์สเพื่อค้นหาข้อสอบ"
              @typed="getSearchKeyword"
              style="height: 30px"
            ></search-box>
          </div>
          <div class="exams-input">
            <div cols="12" sm="6" lg="3" class="mr-2">
              <b-button
                variant="outline-primary"
                @click="
                  addModalShow = true;
                  improtExam();
                "
              >
                <b-icon icon="plus-square-fill" class="mr-2"></b-icon
                >นำเข้าข้อสอบ
              </b-button>
            </div>
            <div cols="12" sm="6" lg="3">
              <b-button
                @click="createNewExam()"
                class="create-new-link-button"
                variant="primary"
                :disabled="isButtonDisabled"
              >
                <i class="fa fa-plus-square" aria-hidden="true"></i>
                เพิ่มข้อสอบใหม่
              </b-button>
            </div>
          </div>
        </div>
        <template v-if="isLoading">
          <div class="text-center mt-5 text-csq">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <p>Loading...</p>
          </div>
        </template>
        <div style="text-align: center" v-else-if="exams.length == 0">
          <p class="mt-5 exam-on-info">{{ "ไม่มีข้อสอบ" }}</p>
        </div>
        <div
          v-else
          v-for="exam in listExams"
          :key="exam.id"
          class="exams-list-outer"
          :busy="isLoading"
        >
          <div>
            <ul>
              <li class="exams-list" id="my-exams">
                <div class="exams-info">
                  <div
                    class="exams-title text-truncate"
                    @click="openExamInfoPage(exam.quizExamId || exam._id)"
                  >
                    {{ exam.lecName || exam.examName || exam.title }}
                  </div>
                  <span v-if="exam.examCategory" class="exams-categoty">
                    {{ exam.examCategory }}
                  </span>
                </div>

                <div class="exams-button">
                  <div
                    class="exams-coursename text-truncate mr-2"
                    v-if="exam.courseName == null"
                  >
                    <b-icon
                      icon="bookmark-check-fill"
                      class="icon-coursename"
                    ></b-icon>
                  </div>
                  <div
                    class="
                      exams-coursename
                      text-truncate
                      mr-2
                      d-flex
                      align-items-center
                    "
                    v-else
                  >
                    <b-icon
                      icon="bookmark-check-fill"
                      class="icon-coursename"
                      variant="success"
                    ></b-icon>
                    <p class="text-coursename">{{ exam.courseName }}</p>
                  </div>
                  <b-button
                    class="exams-edit-button mr-2"
                    variant="warning"
                    size="sm"
                    @click="openExamInfoPage(exam.quizExamId || exam._id)"
                  >
                    <b-icon icon="pencil-square" aria-hidden="true"> </b-icon>
                  </b-button>
                  <b-button
                    class="exams-delete-button"
                    variant="outline-danger"
                    size="sm"
                    @click="confirmRemoveExams(exam)"
                  >
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-exams"
          class="mt-4"
          align="right"
          last-number
        ></b-pagination>
      </div>
    </b-container>

    <div>
      <b-modal
        v-model="addModalShow"
        title="อัปโหลดข้อสอบ"
        size="xl"
        :hide-footer="true"
        ref="modal-editor"
      >
        <ImportExam />
      </b-modal>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import SearchBox from "@/components/shared/SearchBox";
// import AddModal from "./components/AddModelUploadExam.vue";
import ImportExam from "./modal/ModalUploadExam.vue";
import { mapActions, mapGetters } from "vuex";
// import axios from "@/services/exam-api.js";

export default {
  components: {
    PageHeader,
    SearchBox,
    ImportExam,
  },
  data() {
    return {
      addModalShow: false,
      token: localStorage.getItem("token"),
      comId: this.$store.state.Company.companyInfo.id,
      title: "",
      category: "",
      companyUrl: "",
      isLoading: true,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      fetchParams: {
        page: 1,
        limit: 24,
        from: 0,
        to: 23,
      },
      q: "",
      isButtonDisabled: false
    };
  },
  created() {
    // this.fetch();
    this.companyUrl = this.$route.params.company;
    // this.rows = this.exams.length;
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exams"]),
    ...mapGetters("Authentication", ["authUser"]),
    rows() {
      return this.exams.length;
    },
    listExams() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }
      var showData = this.exams.slice(firstIndex, firstIndex + this.perPage);
      return showData;
    },
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "fetchExams",
      "createExams",
      "updateExams",
      "deleteExams",
      "createSection",
      "searchExams",
    ]),

    async getSearchKeyword(value) {
      this.q = value;
      this.isLoading = true;
      if (this.q) {
        await this.searchExams(this.q).then(() => {});
      } else {
        await this.fetchExams(this.comId).then(() => {});
      }
      this.isLoading = false;
    },

    improtExam() {
      this.addModalShow = true;
    },
    async fetch() {
      this.isLoading = true;
      await this.fetchExams(this.comId).then(() => {});
      this.isLoading = false;
    },
    addModalCloseHandler() {
      this.addModalShow = false;
    },

    async createNewExam() {
      this.isButtonDisabled = true;
      let body = {
        title: "Exam" + new Date().getTime(),
        category: "Draft",
        shortDescription: "Short Description",
        companyId: this.comId,
        _options: {
          timeLimit: {},
        },
      };
      await this.createExams(body, this.token).then((res) => {
        let request = {
          body: {
            title: "Default Section",
          },
          token: this.token,
          id: res.id,
        };
        this.createSection(request).then(() => {
          this.openExamInfoPage(res.id);
        });
      });
    },
    confirmRemoveExams(exam) {
      if (exam.courseName == null) {
        this.$bvModal
          .msgBoxConfirm(
            `ต้องการลบ ${
              exam.lecName || exam.examName || exam.title
            } ใช่หรือไม่?`,
            {
              title: "แจ้งเตือนการลบ",
              size: "ml",
              buttonSize: "sm px-3",
              okVariant: "primary",
              okTitle: "ใช่",
              cancelTitle: "ยกเลิก",
              cancelVariant: "outline-secondary",
              footerClass: "p-2 msgbox-footer",
              hideHeaderClose: false,
              centered: true,
              bodyClass: "msgbox-content",
            }
          )
          .then((confirm) => {
            if (confirm) this.ondeleteExams(exam.quizExamId || exam._id);
          });
      } else {
        this.$bvModal.msgBoxConfirm(
          `ไม่สามารถลบข้อสอบได้ เนื่องจากข้อสอบนี้อยู่ในคอร์ส ${exam.courseName}`,
          {
            title: "แจ้งเตือนการลบ",
            size: "ml",
            buttonSize: "sm px-3",
            oklVariant: "outline-secondary",
            okTitle: "ปิด",
            cancelTitle: false,
            cancelVariant: "light",
            footerClass: "p-2 msgbox-footer",
            hideHeaderClose: false,
            centered: true,
          }
        );
      }
    },
    async ondeleteExams(examId) {
      await this.deleteExams(examId, this.token).then(() => {});
    },
    openExamInfoPage(id) {
      this.$router.push({
        name: "exam.info",
        params: { quizExamId: id },
      });
    },
    setFetchParamsPage(page) {
      this.fetchParams.page = page;
    },
    setFetchParamsLimit(limit) {
      this.fetchParams.limit = limit;
      this.fetchParams.page = 1;
    },
  },
};
</script>

<style lang="css" scope>

ul{
  padding-left: 0px;
}
.outer {
  margin-top: 0 !important;
}
.exams-page {
  font-family: "Prompt", sans-serif;
}
.exams-topper {
  display: flex;
  justify-content: space-between;
}
.exams-input {
  display: flex;
  justify-content: space-between;
}
.exams-list-outer {
  margin-top: 25px;
}

.exams-search {
  width: 35%;
}
.exams-list-inner {
  margin-top: 15px;
}

.exams-list {
  list-style-type: none;
  padding: 20px;
  border: 1px solid #d3d9de;
  display: flex;
  justify-content: space-between;
}

.exams-list:hover {
  box-shadow: 1px 1px 5px #d5d5d5;
}
.exams-info {
  display: flex;
  align-items: center;
}

.exams-info .exams-title {
  font-size: 18px;
  font-weight: 600;
  max-width: 30vw;
  cursor: pointer;
}

.exams-list:hover .exams-info .exams-title {
  color: #027bff;
}
.exams-info .exams-categoty {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  background-color: #120088;
  padding: 3px 6px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 10px;
}

.exams-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;
}

.exams-coursename {
  font-size: 12px;
  max-width: 20vw;
}

.exam-on-info {
  font-size: 30px;
  font-weight: 500;
}

.msgbox-content {
  margin-bottom: 20px;
}
.msgbox-footer button {
  padding: 5px 12px;
}

@media screen and (max-width: 994px) {
  .exams-info .exams-title {
    max-width: 30vw;
  }
  .exams-coursename {
    max-width: 15vw;
  }
}

.icon-coursename {
  font-size: 20px;
}
.exams-coursename .text-coursename {
  display: none;
  transition: all 2s;
}

.exams-coursename {
  transition: all 2s;
}
.exams-coursename:hover .text-coursename {
  display: block;
}
</style>

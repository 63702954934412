<template>
  <b-card :class="$options.name">
    <div slot="header">
      <i class="fas fa-clipboard-list" aria-hidden="true"></i> ผลสอบทั้งหมด
    </div>
    <b-container fluid class="p-0" style="width: 100%">
      <b-row align-start justify-start column class="st-answer">
        <div class="col-md-8">
          <!-- <b-form inline>
                        <label for="inline-form-input-name">ข้อสอบ: &nbsp;</label>
                        <b-button-group v-model="answerStatus">
                                <b-button :value="status.all" variant="primary">
                                    ข้อทั้งหมด
                                </b-button>
                                <b-button :value="status.correct" variant="primary">
                                    ข้อถูก
                                </b-button>
                                <b-button :value="status.incorrect" variant="primary">
                                    ข้อผิด
                                </b-button>
                                <b-button :value="status.blank" variant="primary">
                                    ไม่ได้ทำ
                                </b-button>
                        </b-button-group>
                    </b-form> -->
        </div>
        <div class="col-md-4">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <!-- <b-input
                            v-model="examTitleSearchText"
                            type="text"
                            class="ml-3 search-box"
                            :class="`${$options.name}__search-box`"
                            append-icon="search"
                            hide-details
                            outline
                        ></b-input>
                        <b-button variant="outline-primary" @click="handleSearchClicked">ค้นหา</b-button>            -->
          </b-input-group>
        </div>
      </b-row>
      <div style="width: 100%">
        <template v-if="!examAnswerSheetPending && examAnswerSheetData">
          <b-table
            striped
            hover
            :items="paginatedDataset"
            :fields="fields"
            :current-page="currentPage"
            :busy="examAnswerSheetPending && examAnswerSheetData"
          >
            <template v-slot:cell(question)="props">
              <template v-if="props.item.questionTitle">
                <Markdown
                  :message="props.item.questionTitle"
                  :height="markdownSize.height"
                />
              </template>
            </template>
            <template v-slot:cell(btn)="props">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-modal="props.item.questionId"
              >
                <span>ดูคำตอบ</span>
              </b-button>
              <b-modal :id="props.item.questionId" size="xl" :hide-footer="true">
                <ExamTakerAnswerModal
                  :data="props.item"
                ></ExamTakerAnswerModal>
              </b-modal>
            </template>
            <template v-slot:cell(result)="props">
              <template v-if="props.item.result && !props.item.subQuestions">
                <i
                  class="fa"
                  :class="mapAnswerResult(props.item.result)"
                  aria-hidden="true"
                  :height="markdownSize.height"
                />
              </template>
            </template>
            <template v-slot:cell(correction)="props">
              <template v-if="props.item.answerKey">
                <Markdown
                  :message="mapAnswer(props.item.answerKey)"
                  :height="markdownSize.height"
                />
              </template>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>กำลังโหลด...</strong>
            </div>
          </b-table>
          <b-row>
            <b-col md="2" class="my-1">
              <b-form class="mb-2" inline>
                <label class="mb-2 mr-sm-2 mb-sm-0" for="inline-form-input-name"
                  >แสดง</label
                >
                <b-form-select
                  v-model="perPage"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form>
            </b-col>
            <b-col md="10" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filteredDataset.length"
                :per-page="perPage"
                align="right"
              >
              </b-pagination>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-container>
  </b-card>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import { flattenDeep, map, filter, slice, join } from "lodash";
import ExamTakerAnswerModal from "./../modal/ExamTakerAnswerModal";
import Markdown from "@/components/shared/Markdown.vue";

export default {
  name: "ExamTakerAnswerTable",
  components: {
    Markdown,
    ExamTakerAnswerModal
  },
  data() {
    return {
      markdownSize: {
        height: 100
      },
      pagination: {
        status: "all"
      },
      fields: [
        { label: "คำถาม", key: "question", sortable: false, thStyle: { width: "70%" }},
        { label: "", key: "btn", sortable: false, thStyle: { width: "7%" }},
        { label: "ผลลัพธ์", key: "result", sortable: false, thStyle: { width: "5%" } },
        { label: "คำตอบที่ถูก", key: "correction", sortable: false },
        
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      answerStatus: "all",
      status: {
        all: "all",
        incorrect: "incorrect",
        correct: "correct",
        blank: "blank"
      }
    };
  },
  computed: {
    ...mapGetters("Company", ["examAnswerSheetPending", "examAnswerSheetData"]),
    mappedExamAnswerSheetData() {
      return {
        ...this.examAnswerSheetData,
        ...(this.examAnswerSheetData._guest && {
          examTakerName: this.examAnswerSheetData._guest.name,
          email: this.examAnswerSheetData._guest.email
        })
      };
    },
    dataset() {
      return flattenDeep(
        map(this.mappedExamAnswerSheetData.sections, section => {
          return map(section.answers, (answer, index) => {
            const indexedAnswer = {
              ...answer,
              questionTitle: `ข้อที่ ${index + 1}: ${answer.questionTitle}`
            };
            if (!answer.subQuestions) return indexedAnswer;
            const subQuestionsAnswer = map(
              answer.subQuestions,
              (sub, index) => {
                return {
                  ...sub,
                  questionTitle: `ข้อย่อยที่ ${index + 1}: ${sub.questionTitle}`
                };
              }
            );
            return [
              {
                ...indexedAnswer
              },
              ...subQuestionsAnswer
            ];
          });
        })
      );
    },
    filteredDataset() {
      const { status } = this.pagination;
      return filter(this.dataset, ech => {
        switch (status) {
          case this.status.all: {
            return true;
          }
          case this.status.blank: {
            return !!ech.result.blank;
          }
          case this.status.correct: {
            return !!ech.result.correct;
          }
          case this.status.incorrect: {
            return !!ech.result.incorrect;
          }
          default:
            return true;
        }
      });
    },
    paginatedDataset() {
      // const { rowsPerPage, page } = this.pagination
      // const start = (page - 1) * rowsPerPage
      // const end = start + rowsPerPage
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return slice(this.filteredDataset, start, end);
    }
  },
  methods: {
    handleSearchClicked() {
      this.currentPage = 1;
      this.pagination.status = this.answerStatus;
    },
    mapAnswer(answerKey) {
      if (answerKey.choiceId) {
        return join(
          map(answerKey.choiceId, ech => {
            return ech.choiceText;
          }),
          ", "
        );
      }
      if (answerKey.answerText) return answerKey.answerText;
      return "";
    },
    mapAnswerResult(result) {
      return {
        ...(result.blank && {
          "fa-minus": true,
          "text-warning": true
        }),
        ...(result.correct && {
          "fa-check": true,
          "text-success": true
        }),
        ...(result.incorrect && {
          "fa-times": true,
          "text-danger": true
        })
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamTakerAnswerTable {
  &__table {
    &__title {
      width: 70%;
    }
  }
}
.st-answer {
  padding-top: 1em;
}
.search-box {
  margin-right: 0.5em;
}
</style>

<style lang="scss">
.ExamTakerAnswerTable {
  &__table {
    .markdown {
      overflow: scroll;
      display: inline-block;
    }
  }
}
</style>

import { render, staticRenderFns } from "./ExamQuestionOverviewModal.vue?vue&type=template&id=c4b3fd40&scoped=true&"
import script from "./ExamQuestionOverviewModal.vue?vue&type=script&lang=js&"
export * from "./ExamQuestionOverviewModal.vue?vue&type=script&lang=js&"
import style0 from "./ExamQuestionOverviewModal.vue?vue&type=style&index=0&id=c4b3fd40&prod&scoped=true&lang=css&"
import style1 from "./ExamQuestionOverviewModal.vue?vue&type=style&index=1&id=c4b3fd40&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4b3fd40",
  null
  
)

export default component.exports
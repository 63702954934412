<template>
  <form @submit.prevent="update">
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title"><a href="#" class="" @click="backPaymentAddress()">ที่อยู่การชำระเงิน</a>/ที่อยู่สำหรับออกใบกำกับภาษี</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <b-form-group class="col-md-12">
            <b-form-radio-group v-model="form.type">
            <div class="row">
              <b-form-radio value="PERSON" style="width: 31%; margin-left: 15px">ออกในนามบุคคลธรรมดา</b-form-radio>
              <b-form-radio value="CORPERATE">ออกในนามนิติบุคคล</b-form-radio>
              <b-form-checkbox
                v-if="this.comId == 519"
                v-show="form.type == 'CORPERATE'"
                v-model="form.withHoldingTaxFlag"
                value="1"
                unchecked-value="0"
              >
                หักภาษี ณ ที่จ่าย 3%
              </b-form-checkbox>
            </div>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div v-if="form.type == 'CORPERATE'" class="form-row">
          <b-form-group class="col-md-4">
            <label>ชื่อนิติบุลคล/บริษัท</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.corperateName"
              type="text"
              placeholder="ชื่อนิติบุลคล/บริษัท"
              :class="{ 'is-invalid': hasError('corperateName') && form.type == 'CORPERATE' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อนิติบุลคล/บริษัท
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>เลขประจำตัวผู้เสียภาษี</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.cIdNo"
              type="text"
              placeholder="เลขประจำตัวผู้เสียภาษี"
              v-mask="['#############']"
              :class="{ 'is-invalid': hasError('cIdNo') && form.type == 'CORPERATE' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกเลขประจำตัวผู้เสียภาษี
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div v-if="form.type == 'PERSON'" class="form-row">
          <b-form-group class="col-md-2">
            <label>คำนำหน้า</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.titleName"
              type="text"
              placeholder="คำนำหน้า"
              :class="{ 'is-invalid': hasError('titleName') && form.type == 'PERSON' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกคำนำหน้า
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-3">
            <label>ชื่อ</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.firstName"
              type="text"
              placeholder="ชื่อ"
              :class="{ 'is-invalid': hasError('firstName') && form.type == 'PERSON' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อ
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-3">
            <label>นามสกุล</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.lastName"
              type="text"
              placeholder="นามสกุล"
              :class="{ 'is-invalid': hasError('lastName') && form.type === 'PERSON' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกนามสกุล
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group v-if="form.type == 'CORPERATE'" class="col-md-4">
            <label>สาขา</label><span class="text-danger">*</span>
            <div>
              <b-form-radio v-model="form.branch" value="UNDEFINED">ไม่ระบุ</b-form-radio>
            </div>
            <div>
              <b-form-radio v-model="form.branch" value="MAIN_OFFICE">สำนักงานใหญ่</b-form-radio>
            </div>
            <div>
              <b-form-radio v-model="form.branch" value="BRANCH_OFFICE">สำนักงานสาขา</b-form-radio>
            </div>
            <b-form-input
              v-model="form.branchId"
              type="text"
              placeholder="ชื่อสำนักงานสาขา"
              v-mask="['#####']"
              :disabled="!(form.branch == 'BRANCH_OFFICE')"
              :class="{ 'is-invalid': hasError('branchId') && form.type == 'CORPERATE' && form.branch == 'BRANCH_OFFICE'}"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อสำนักงานสาขา
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group v-if="form.type == 'PERSON'" class="col-md-4">
            <label>รหัสบัตรประชาชน</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.idNo"
              type="text"
              placeholder="เลข 13 หลัก"
              v-mask="['#############']"
              maxlength="13"
              :class="{ 'is-invalid': hasError('idNo') && form.type == 'PERSON' }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกรหัสบัตรประชาชน
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>บ้านเลขที่</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.number"
              type="text"
              placeholder="บ้านเลขที่"
              :class="{ 'is-invalid': hasError('number') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกบ้านเลขที่
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>สถานที่/อาคาร (ไม่ต้องระบุคำว่าอาคาร)</label>
            <b-form-input
              v-model="form.buildingName"
              type="text"
              placeholder="สถานที่/อาคาร"
              trim
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>ชั้นที่ (ไม่ต้องระบุคำว่าชั้น)</label>
            <b-form-input
              v-model="form.floor"
              type="text"
              placeholder="ชั้นที่"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>หมู่บ้าน (ไม่ต้องระบุคำว่าหมู่บ้าน)</label>
            <b-form-input
              v-model="form.village"
              type="text"
              placeholder="หมู่บ้าน"
              trim
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>หมู่</label>
            <b-form-input
              v-model="form.moo"
              type="text"
              placeholder="หมู่"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>ซอย (ไม่ต้องระบุคำว่าซอย)</label>
            <b-form-input 
              v-model="form.soi"
              type="text"
              placeholder="ซอย"
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>ถนน (ไม่ต้องระบุคำว่าถนน)</label>
            <b-form-input
              v-model="form.street"
              type="text"
              placeholder="ถนน"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>แขวง/ตำบล</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.subDistrict"
              :class="{ 'is-invalid': hasError('subDistrict') }"
              type="text"
              placeholder="แขวง/ตำบล"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกแขวง/ตำบล
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>เขต/อำเภอ</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.district"
              :class="{ 'is-invalid': hasError('district') }"
              type="text"
              placeholder="เขต/อำเภอ"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกเขต/อำเภอ
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>จังหวัด</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.province"
              :class="{ 'is-invalid': hasError('province') }"
              type="text"
              placeholder="จังหวัด"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกจังหวัด
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>รหัสไปรษณีย์</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.postCode"
              :class="{ 'is-invalid': hasError('postCode') }"
              v-mask="['#####']"
              type="text"
              placeholder="รหัสไปรษณีย์"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกรหัสไปรษณีย์
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>ตั้งชื่อให้ข้อมูลที่อยู่นี้ (เช่น บ้าน ที่ทำงาน)</label>
            <b-form-input
              v-model="form.billingName"
              :class="{ 'is-invalid': hasError('billingName') }"
              type="text"
              placeholder="ชื่อที่อยู่"
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อที่อยู่
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="text-left">
          <button
            class="btn btn-dark"
            type="button"
            @click="handleSubmit"
            :disabled="submitted"
          >
            บันทึก
          </button>
          <button
            class="btn btn-outline-dark ml-3"
            type="button"
            @click="backPaymentAddress()"
            :disabled="submitted"
          >
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import api from "../../../../services/api.js"
import {mask} from 'vue-the-mask';

export default {
  components: {},
  directives:{mask},
  props: {
    memberId: { type: String, default: null },
    billingAddress: { type: Object, default: null }
  },

  data() {
    return {
      isEditing: this.billingAddress ? true : false,
      comId: this.$store.state.Company.companyInfo.id,
      form: {
        type: this.billingAddress ? this.billingAddress.mba_type : "PERSON",
        branch: this.billingAddress ? this.billingAddress.mba_branch : "UNDEFINED",
        titleName: this.billingAddress ? this.billingAddress.mba_titleName : "",
        firstName: this.billingAddress ? this.billingAddress.mba_firstName : "",
        lastName: this.billingAddress ? this.billingAddress.mba_lastName : "",
        number: this.billingAddress ? this.billingAddress.mba_number : null,
        buildingName: this.billingAddress ? this.billingAddress.mba_buildingName : null,
        floor: this.billingAddress ? this.billingAddress.mba_floor : null,
        village: this.billingAddress ? this.billingAddress.mba_village : null,
        moo: this.billingAddress ? this.billingAddress.mba_moo : null,
        soi: this.billingAddress ? this.billingAddress.mba_soi : null,
        street: this.billingAddress ? this.billingAddress.mba_street : null,
        idNo: this.billingAddress ? this.billingAddress.mba_idNo : null,
        cIdNo: this.billingAddress && this.billingAddress.mba_type == 'CORPERATE' ? this.billingAddress.mba_idNo : null,
        subDistrict: this.billingAddress ? this.billingAddress.mba_subDistrict : null,
        district: this.billingAddress ? this.billingAddress.mba_district : null,
        province: this.billingAddress ? this.billingAddress.mba_province : null,
        postCode: this.billingAddress ? this.billingAddress.mba_postcode : null,
        billingName: this.billingAddress ? this.billingAddress.mba_name : null,
        corperateName: this.billingAddress && this.billingAddress.mba_type == 'CORPERATE' ? this.billingAddress.mba_corperateName : null,
        withHoldingTaxFlag: this.billingAddress && this.billingAddress.mba_type == 'CORPERATE' ? this.billingAddress.mba_withHoldingTaxFlag : 0,
        branchId: this.billingAddress && this.billingAddress.mba_type == 'CORPERATE' && this.billingAddress.mba_branch == 'BRANCH_OFFICE' ? this.billingAddress.mba_branchId : null,
      },
      submitted: false,
      errors: [],
      msaNumber: "",
      msaBuildingName: "",
      errorMessage: ""
    };
  },

  computed: {},

  mounted() {},

  methods: {
    async handleSubmit() {
      this.submitted = true;

      if (await this.validateForm() && !this.isEditing) {
        this.addBillingAddress(this.form);
        this.submitted = false;
      } else if (this.isEditing) {
        this.editBillingAddress(this.form);
        this.submitted = false;
      } else {
        console.log(this.errors);
        this.submitted = false;
      }
    },
    validateForm: async function() {
      this.errors = [];

      const form = this.form;

      if (!form.titleName && form.type == 'PERSON') this.errors.push("titleName");
      if (!form.firstName && form.type == 'PERSON') this.errors.push("firstName");
      if (!form.lastName && form.type == 'PERSON') this.errors.push("lastName");
      if (!form.idNo && form.type == 'PERSON') this.errors.push("idNo");
      if (!form.corperateName && form.type == 'CORPERATE') this.errors.push("corperateName");
      if (!form.branchId && form.type == 'CORPERATE' && form.branch == 'BRANCH_OFFICE') this.errors.push("branchId");
      if (!form.cIdNo && form.type == 'CORPERATE') this.errors.push("cIdNo");
      if (!form.number) this.errors.push("number");
      if (!form.subDistrict) this.errors.push("subDistrict");
      if (!form.district) this.errors.push("district");
      if (!form.province) this.errors.push("province");
      if (!form.postCode) this.errors.push("postCode");
      if (!form.billingName) this.errors.push("billingName");
      return !this.errors.length;
    },

    addBillingAddress(form) {
      api
        .post(`/v1/members/${this.memberId}/billing-address`, {
          mba_name: form.billingName,
          mba_type: form.type,
          mba_corperateName: form.type == 'CORPERATE' ? form.corperateName : null,
          mba_branch: form.type == 'CORPERATE' ? form.branch : null,
          mba_branchId: form.type == 'CORPERATE' && form.branch == 'BRANCH_OFFICE' ? form.branchId : null,
          mba_titleName: form.type == 'PERSON' ? form.titleName : null,
          mba_firstName: form.type == 'PERSON' ? form.firstName : null,
          mba_lastName: form.type == 'PERSON' ? form.lastName : null,
          mba_buildingName: form.buildingName,
          mba_withHoldingTaxFlag: form.type == 'CORPERATE' ? form.withHoldingTaxFlag : 0,
          mba_floor: form.floor,
          mba_number: form.number,
          mba_moo: form.moo,
          mba_village: form.village,
          mba_soi: form.soi,
          mba_street: form.street,
          mba_subDistrict: form.subDistrict,
          mba_district: form.district,
          mba_province: form.province,
          mba_postcode: form.postCode,
          mba_idNo: form.type == 'PERSON' ? form.idNo : form.cIdNo
        })
        .then(() => {
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },
    editBillingAddress(form) {
      api
        .put(`/v1/members/${this.memberId}/billing-address/${this.billingAddress.mba_id}`, {
          mba_name: form.billingName,
          mba_type: form.type,
          mba_corperateName: form.type == 'CORPERATE' ? form.corperateName : null,
          mba_branch: form.type == 'CORPERATE' ? form.branch : null,
          mba_branchId: form.type == 'CORPERATE' && form.branch == 'BRANCH_OFFICE' ? form.branchId : null,
          mba_titleName: form.type == 'PERSON' ? form.titleName : null,
          mba_firstName: form.type == 'PERSON' ? form.firstName : null,
          mba_lastName: form.type == 'PERSON' ? form.lastName : null,
          mba_buildingName: form.buildingName,
          mba_withHoldingTaxFlag: form.type == 'CORPERATE' ? form.withHoldingTaxFlag : 0,
          mba_floor: form.floor,
          mba_number: form.number,
          mba_moo: form.moo,
          mba_village: form.village,
          mba_soi: form.soi,
          mba_street: form.street,
          mba_subDistrict: form.subDistrict,
          mba_district: form.district,
          mba_province: form.province,
          mba_postcode: form.postCode,
          mba_idNo: form.type == 'PERSON' ? form.idNo : form.cIdNo
        })
        .then(() => {
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },

    backPaymentAddress() {
      this.$emit("backPaymentAddressList");
    },

    hasError(field) {
      const errors = this.errors;
      return errors.includes(field);
    }
  }
};
</script>

<template>
  <div>
    <PageHeader
      :title="`ประวัติการเข้าเรียนของ ${memberName}`"
      style="margin-left: -10%;"
    ></PageHeader>
    <b-container class="widthPage py-5">
      <div class="card card-csq no-shadow" style="min-height: 450px;">
        <div v-if="courseList.length > 0" class="card-body pt-4">
          <b-row class="py-2 mx-1" style="border-bottom: 2px solid #d8d8d8">
            <b-col cols="4"><strong>คอร์สเรียน</strong></b-col>
            <b-col cols="2"><strong>วันที่ลงทะเบียน</strong></b-col>
            <b-col cols="3"><strong>ความยาวคอร์ส</strong></b-col>
            <b-col cols="3"><strong>เข้าเรียนแล้ว</strong></b-col>
          </b-row>
          <b-row
            class="text-font py-2 mx-1"
            v-for="(course, courseIndex) in listCourseLearningHistory"
            :key="courseIndex"
            style="border-bottom: 1px solid #d8d8d8"
          >
            <b-col cols="4">
              <router-link
                :to="{
                  name: 'report.learning-history-report.enrolls',
                  params: { cerId: course.cerId },
                }"
                class="text-csq"
              >
                {{ course.courseName }}
              </router-link>
            </b-col>
            <b-col cols="2">
              <p>{{ course.cerStartDate | thaiDateFilter }}</p>
            </b-col>
            <b-col cols="3">
              <p>{{ course.courseDuration | duration | thaiDurationFilter }}</p>
            </b-col>
            <b-col cols="3">
              <p>{{ course.learningTime | duration | thaiDurationFilter }}</p>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="courseList.length > 0" class="text-font mt-4 mx-1">
          <b-col cols="6">
            <div class="d-flex justify-content-start align-items-center">
              <p class="pr-2">แสดง</p>
              <b-form-select v-model="rowPerPage" style="width: 75px;">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </b-form-select>
              <p class="pl-2">จาก {{ rows }} รายการ</p>
            </div>
          </b-col>
          <b-col cols="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="rowPerPage"
              aria-controls="course-learning-history"
              align="right"
              last-number
            ></b-pagination>
          </b-col>
        </b-row>

        <template v-else>
          <div class="text-center mt-5">
            <p>ไม่มีประวัติการเข้าเรียน</p>
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";

export default {
  components: {
    PageHeader,
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      mId: this.$route.params.mId,
      memberName: "",
      courseList: [],
      rowPerPage: 5,
      currentPage: 1,
    };
  },
  created() {
    this.fetchMemberInfo();
    this.fetchCourseEnroll();
  },
  computed: {
    rows() {
      return this.courseList.length;
    },
    listCourseLearningHistory() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.rowPerPage;
      }
      var showData = this.courseList.slice(
        firstIndex,
        firstIndex + this.rowPerPage
      );
      return showData;
    },
  },
  methods: {
    async fetchMemberInfo() {
      if (this.mId) {
        await api.get(`/v1/members/${this.mId}`).then(({ data }) => {
          this.memberName = data.data.firstName + " " + data.data.lastName;
        });
      }
    },
    async fetchCourseEnroll() {
      try {
        await api.get(`v1/members/${this.mId}/enrolls`).then(({ data }) => {
          this.courseList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

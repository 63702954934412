<template>
  <div>
    <PageHeader title="ภาพรวมคอร์ส" style="margin-left: -10%"></PageHeader>
    <b-container class="widthPage mt-5 mb-5">
      <b-row class="mb-3">
        <b-col sm="12">
          <courseGeneralInfo
            :apiRoute="`/v1/analytics/courses/getCourseOverviewInfo`"
          ></courseGeneralInfo>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col sm="12">
          <courseTable
            :apiRoute="`/v1/analytics/courses/getCourseOverview?template=panel`"
            :perPage="10"
          ></courseTable>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col sm="12" md="5">
          <courseInstructor
            :apiRoute="`/v1/analytics/courses/getCourseForIntructors`"
            :perPage="10"
          ></courseInstructor>
        </b-col>
      </b-row>
    </b-container>
    <!-- 
    <b-row class="pl-5 pr-5 pt-2">
      <b-col cols="4 pr-1 pl-1">
        <courseInstructor
          :apiRoute="`/v1/analytics/courses/getCourseForIntructors`"
          :perPage="10"
        ></courseInstructor
      ></b-col>
      <b-col cols="8 pl-1">
        <courseDiscuss
          :apiRoute="`/v1/analytics/courses/getCourseOverviewDiscuss`"
          :perPage="10"
          :tab="false"
        ></courseDiscuss>
      </b-col>
    </b-row>
    <b-row class="pl-5 pr-5 pt-3">
      <b-col cols="12">
        <courseReview
          :apiRoute="`/v1/analytics/courses/getCourseOverviewReview`"
          :perPage="2"
          :tab="false"
        ></courseReview
      ></b-col>
    </b-row> -->
  </div>
</template>
<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import courseTable from './panel/CourseAllPanel';
import courseGeneralInfo from './panel/CourseOverviewGeneralInfo';
import courseInstructor from './panel/CourseInstructorPanel';
// import courseDiscuss from "./panel/CourseDiscussPanel";
// import courseReview from "./panel/CourseReviewPanel";

export default {
  components: {
    PageHeader,
    courseTable,
    courseGeneralInfo,
    courseInstructor,
    // courseDiscuss,
    // courseReview
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

<template>
  <b-card :class="$options.name">
    <header slot="header" :class="[`${$options.name}__head`]">
      <div class="main-bar">
        <div class="row">
          <div class="col-lg-5">
            <h5 class="nav_top_align">
              <i class="fa fa-file"  aria-hidden="true" /> เลือกไฟล์ข้อสอบ
            </h5>
          </div>
        </div>
      </div>
    </header>
    <b-container fluid :class="[`${$options.name}__content`]">
      <b-row align-v="center">
        <b-col align-self="start">
          <b-container
            fluid
            :class="css.uploadBtnContainer"
            @dragover.prevent="toggleDrop(true)"
            @dragleave.prevent="toggleDrop(false)"
            @drop="onDrop"
          >
            <b-row>
              <b-col>
                <b-form-group
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback"
                  :state="fileBrowserState"
                  :class="`${$options.name}__content__upload-btn__form-group`"
                >
                  <div>
                    <h3>ลากไฟล์ลงมาที่นี่</h3>
                    <h4>หรือ</h4>
                    <label class="btn btn-secondary">
                      เลือกไฟล์จากคอมพิวเตอร์
                      <b-form-file
                        v-model="file"
                        :class="[
                          `${$options.name}__content__upload-btn__file-input`,
                        ]"
                        accept=".csv"
                        :state="fileBrowserState"
                        @change="toggleDrop(true)"
                      >
                      </b-form-file
                    ></label>
                  </div>
                  <p class="text-warning" v-if="!file">รองรับเฉพาะไฟล์ CSV เท่านั้น</p>
                  <p v-if="isFail && !file" class="feedback-error">
                    {{ message }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-btn
                  :loading="uploading"
                  :hidden="!fileSubmitReady"
                  :disabled="upload"
                  @click="handleSubmitFileClicked"
                  variant="primary"
                >
                  อัปโหลดเอกสาร
                </b-btn>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";

export default {
  name: "ExamManagementUpload",
  components: {},
  data() {
    return {
      file: null,
      uploading: false,
      css: {
        uploadBtnContainer: {
          [`${this.$options.name}__content__upload-btn`]: true,
          [`${this.$options.name}__content__upload-btn--dropped`]: false,
        },
      },
      isFail: false,
      message: null,
      upload: false
    };
  },
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
  computed: {
    ...mapGetters("Authentication", ["accessToken"]),
    ...mapGetters("Company", ["companyInfo"]),
    ...mapGetters("ExamManagement", ["companyUploadedCsvError"]),
    fileBrowserState() {
      if (!this.file) return null;
      return (
        this.isFileExtensionValid(this.file) &&
        this.isFileMimeTypeValid(this.file)
      );
    },
    fileSubmitReady() {
      return !!this.file && !!this.fileBrowserState;
    },
    validFeedback() {
      return !this.file ? "" : `อัปโหลดไฟล์ ${this.file.name}`;
    },
    invalidFeedback() {
      if (!this.file) return "";
      if (!this.isFileExtensionValid(this.file)) {
        return "ข้อผิดพลาด ไม่รองรับการอัปโหลดไฟล์ \n โปรดเลือกเฉพาะไฟล์ CSV เท่านั้น";
      }

      if (!this.isFileMimeTypeValid(this.file)) {
        return "Invalid file type.";
      }
      return "";
    },
  },
  methods: {
    ...mapActions("ExamManagement", ["uploadExamCSV"]),
    async handleSubmitFileClicked() {
      this.upload = true
      if (!this.file) {
        return;
      }
      if (!this.fileSubmitReady) {
        return;
      }

      this.uploading = true;
      await this.submitFile();
      this.uploading = false;
    },
    toggleDrop(isDropped) {
      this.css.uploadBtnContainer[
        `${this.$options.name}__content__upload-btn--dropped`
      ] = isDropped;
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      this.file = files[0];
    },
    async submitFile() {
      const formData = new FormData();
      formData.append("uploads", this.file);
      formData.append("companyId", this.companyInfo.id);
      await this.uploadExamCSV({
        data: formData,
        accessToken: this.accessToken,
      });
      if (this.companyUploadedCsvError) {
        this.isFail = true;
        this.file = null;

        this.message =
          "เกิดข้อผิดพลาดดังนี้ " +
          this.companyUploadedCsvError.message +
          " กรุณาอัปโหลดไฟล์ข้อสอบใหม่";
        return;
      }
      this.toConfirmationPage();
    },
    toConfirmationPage() {
      this.$router.push({
        name: "company.exam-management.confirmation",
      });
    },
    isFileExtensionValid(file) {
      const ext = file.name.split(".").pop();
      const allowedExtensions = ["csv"];
      return allowedExtensions.includes(ext);
    },
    isFileMimeTypeValid(file) {
      const allowedTypes = [
        "text/csv",
        "text/x-csv",
        "text/comma-separated-values",
        "text/x-comma-separated-values",
        "application/csv",
        "application/x-csv",
        "application/vnd.ms-excel",
      ];
      return allowedTypes.includes(file.type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_variables.scss";
.ExamManagementUpload {
  &__content {
    padding: 1rem 0;
    &__upload-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 600px;
      border: 2px dashed;
      .card-body {
        padding: 0;
      }
      &__file-input {
        z-index: -1;
        opacity: 0;
        width: 0px;
        position: absolute;
      }
      &__form-group {
        text-align: center;
      }
      &--dropped {
        border-color: $csq-base-color;
      }
    }
  }
  .card-header {
    padding: 0 auto;
  }
  .feedback-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .text-warning{
    color: #027BFF !important;
    font-size: 12px;
  }
}
</style>

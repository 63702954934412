<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>อันดับนักเรียน</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${companyInfo.url}/analytics/courses/${cId}/student-ranking`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <h5 style="font-weight: bold">
                  {{ courseInfo.data.courseName }}
                </h5>

                <b-form-input
                  v-model="search"
                  placeholder="ค้นหารายชื่อ"
                  style="padding: 1rem; display: inline-block; width: 20%"
                >
                </b-form-input>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="course-student-ranking-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="
              search == null
                ? result
                : result.filter((i) =>
                    (i.mp_firstName + i.mp_lastName).replace(/ /g, '').includes(search.replace(/ /g, '')) ||
                    i.m_email.includes(search)
                  )
            "
            :fields="filteredFields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(fullName)="data"
              ><a
                :href="`/${company.url}/analytics/users/${data.item.m_id}/learning-stats`"
                target="_blank"
                ><span>{{
                  (data.item.mp_firstName + " " + data.item.mp_lastName)
                    | cut(perPage)
                }}</span></a
              ></template
            >
            <template v-slot:cell(email)="data"
              >{{ data.item.m_email }}
            </template>
            <template v-slot:cell(mobilePhone)="data"
              >{{ data.item.mp_mobilePhone }}
            </template>
            <template v-slot:cell(startDate)="data"
              >{{ data.item.cer_startDate | thaiDate }}
            </template>
            <template v-slot:cell(endDate)="data"
              >{{ data.item.cer_endDate | thaiDate }}
            </template>
            <template v-slot:cell(learning_time)="data">
              <span style="float: right"
                >{{
                  data.item.learning_time ? data.item.learning_time : 0
                }}
                ชั่วโมง</span
              >
            </template>
            <template v-slot:cell(remainingTime)="data">
              <span style="float: right" v-text="data.item.learning_alltime ? data.item.cer_timeLimit - data.item.learning_alltime <= 0 ? '0 ชั่วโมง' : convertTime(data.item.cer_timeLimit - data.item.learning_alltime) + ' ชั่วโมง' : convertTime(data.item.cer_timeLimit) + ' ชั่วโมง'"></span>
            </template>
            <template v-slot:cell(completeLecture)="data">
              <span style="float: right"
                >{{ data.item.completeLecture }} บทเรียน</span
              >
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) =>
                    (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
                  ).length
            "
            :per-page="perPage"
            aria-controls="course-student-ranking-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  components: {
    layout
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      search: null,
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "fullName", label: "ผู้เรียน" },
        { key: "email", label: "อีเมล" },
        { key: "mobilePhone", label: "เบอร์โทรศัพท์" },
        { key: "startDate", label: "วันที่ลงทะเบียน", class: "text-right" },
        { key: "endDate", label: "วันที่หมดอายุ", class: "text-right" },
        {
          key: "learning_time",
          label: "เวลาที่ใช้เรียน",
          class: "text-right"
        },
        {
          key: "remainingTime",
          label: "เวลาที่เหลือ",
          class: "text-right"
        },
        {
          key: "completeLecture",
          label: "เรียนจบไปแล้ว",
          class: "text-right"
        }
      ],
      items: [],
      courseInfo: [],
      company: {}
    };
  },
  computed: {
    filteredFields() {
      if (this.comId !== 277 && this.comId !== 180) {
        return this.fields.filter(field => field.key !== "remainingTime");
      }
      return this.fields;
    }
  },

  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter(i =>
              (i.mp_firstName + " " + i.mp_lastName).includes(this.search)
            );
      set.forEach((i) => {
        let cleanedNumber = i.mp_mobilePhone.replace(/\D/g, ""); // Remove non-digit characters
        let formattedNumber = `0${cleanedNumber.substring(1,3)}-${cleanedNumber.substring(3)}`;

        let temp = {
          name: i.mp_firstName + " " + i.mp_lastName,
          id : i.mp_staffId,
          email: i.m_email,
          username: i.m_username,
          tel: formattedNumber,
          dob: i.mp_dob,
          startDate: i.cer_startDate,
          endDate: i.cer_endDate,
          time: i.learning_time
            ? i.learning_time + " ชั่วโมง"
            : "00:00:00 ชั่วโมง",
          remainingTime: i.learning_alltime
            ? this.convertTime(i.cer_timeLimit - i.learning_alltime) + " ชั่วโมง"
            : this.convertTime(i.cer_timeLimit) + " ชั่วโมง" ,
          lecture: i.completeLecture + " บทเรียน",
        };
        if (this.comId != 277 && this.comId != 180) {
          delete temp.remainingTime;
        }
        value.push(temp);
      });
      let key = [
        "ผู้เรียน",
        "รหัสผู้เรียน",
        "อีเมล",
        "ชื่อผู้ใช้",
        "เบอร์โทรศัพท์",
        "วันเดือนปีเกิด",
        "วันที่ลงทะเบียน",
        "วันที่หมดอายุ",
        "เวลาที่ใช้เรียน",
        "เวลาที่เหลือ",
        "เรียนจบไปแล้ว"
      ];
      if (this.comId !== 277 && this.comId !== 180) {
        const index = key.indexOf("เวลาที่เหลือ");
        if (index !== -1) {
          key.splice(index, 1);
        }
      }
      let filename = "Individual-Course-Student-Ranking" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    convertTime(diffTime) {
      const hours = Math.floor(diffTime / 3600);
      const minutes = Math.floor((diffTime % 3600) / 60);
      const seconds = diffTime % 60;

      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  async mounted() {
    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data;
    });
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
    this.company = this.$store.state.Company.companyInfo;
  }
};
</script>
<style scoped></style>

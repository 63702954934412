<template>
  <div>
    <b-button
      v-b-modal.modal-1
      style="display: inline"
      variant="outline-primary"
      class="ml-5 mb-2"
    >
      <font-awesome-icon icon="lightbulb" />
      วิธีตั้งค่าแบบประเมิน</b-button
    >

    <b-modal
      size="lg"
      id="modal-1"
      title="วิธีตั้งค่าแบบประเมินเพื่อใช้เป็นเกณฑ์การจบคอร์ส"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div v-if="currentPage == 1">
        <span style="font-weight: bold">ขั้นตอนที่ 1:</span>
        <span>
          สร้างแบบฟอร์มประเมินบน Jotform และกดปุ่มแก้ไขฟอร์ม (Edit Form)</span
        >
        <img class="mt-2" :src="'/gif/1-edit-form.gif'" style="width: 100%" />
      </div>
      <div v-if="currentPage == 2">
        <span style="font-weight: bold">ขั้นตอนที่ 2:</span>
        <span>
          สร้าง Hidden Field ของแบบฟอร์มขึ้นมาและตั้งชื่อว่า
          <em>enroll_id</em> โดยให้ค่า default ของฟิลด์นี้เป็นค่าว่างไว้</span
        >
        <img
          class="mt-2"
          :src="'/gif/2-hidden-field.gif'"
          style="width: 100%"
        />
      </div>
      <div v-if="currentPage == 3">
        <span style="font-weight: bold">ขั้นตอนที่ 3:</span>
        <span> กำหนด Webhook API ของ Jotform โดยใช้ Webhook URL ดังนี้</span>
        <div class="mt-2">
          <span class="ml-1">Webhook URL: </span>
          <b-input
            class="ml-1"
            style="width: 60%; display: inline; font-size: 14px"
            type="text"
            :value="webhook"
            id="webhookUrl"
          />
          <b-button
            style="font-size: 12px"
            class="ml-1"
            variant="outline-primary"
            @click="copyUrl()"
            >คัดลอก</b-button
          >
        </div>
        <img class="mt-2" :src="'/gif/3-add-webhook.gif'" style="width: 100%" />
      </div>

      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <b-pagination
          style="width: 40%"
          pills
          size="lg"
          hide-goto-end-buttons
          class="mt-4"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
        <b-button
          v-if="checkCurrentPage()"
          class="ml-3 mt-2"
          size="lg"
          variant="primary"
          @click="navButton($bvModal)"
          >{{ navText }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
library.add(faLightbulb);
export default {
  data() {
    return {
      perPage: 1,
      currentPage: 1,
      items: [1, 2, 3],
      navText: "",
      webhook: process.env.VUE_APP_AUTH_SERVICE_API_URL + "/take-evaluation",
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {
    navButton(modal) {
      if (this.currentPage < 3) {
        this.currentPage += 1;
      } else {
        modal.hide("modal-1");
      }
    },
    copyUrl() {
      /* Get the text field */
      var copyText = document.getElementById("webhookUrl");
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
    checkCurrentPage() {
      if (this.currentPage < 3) {
        this.navText = "ถัดไป";
      } else {
        this.navText = "เสร็จสิ้น";
      }
      return true;
    },
  },
};
</script>
<template>
  <b-row>
    <b-col cols='12'>
      <div class='font-weight-bold text-danger mb-3'>โปรดตรวจสอบข้อมูลก่อนลบ</div>
    </b-col>
    <b-col cols='12'>
      <div class='font-weight-bold mb-2'>คอร์ส</div>
      <div class='mb-2'>{{affiliateItem.courseName}}</div>
    </b-col>
    <b-col cols='12'>
      <div class='font-weight-bold mb-2'>Share Party</div>
      <div class='mb-2'>{{affiliateItem.publisherName}}</div>
    </b-col>
    <b-col cols='12'>
      <div class='font-weight-bold mb-2'>ชื่อลิงค์</div>
      <div class='mb-2'>{{affiliateItem.linkName}}</div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    affiliateItem: Object
  }
}
</script>

<style>
</style>
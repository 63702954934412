<template v-slot:body>
  <div style="padding:0.6rem">
    <b-row>
      <b-col cols="6 p-2">
        <PieChart
          :chart-data="datacollection"
          :options="option"
          :height="200"
        ></PieChart>
      </b-col>
      <b-col cols="6 p-2">
        <my-legend :num="2" :color="['rgb(235,129,35)', 'rgb(237,237,237)']">
          <template v-slot:1>ที่เรียนจบแล้ว</template>
          <template v-slot:2>ที่เรียนยังไม่จบ</template>
        </my-legend>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import myLegend from "@/components/pages/analytics/shared/LegendPieChart";
import api from "@/services/api.js";

export default {
  components: {
    PieChart,
    myLegend
  },
  props: {
    memberId: String
  },
  data() {
    return {
      datacollection: {},
      option: {},
      legendValue: [0, 0],
      memberCourseProgress: []
    };
  },
  mounted() {
    this.fetchMemberCourseProgress();
    this.fillOption();
  },
  methods: {
    fetchMemberCourseProgress() {
      api
        .get(`/v1/members/${this.memberId}/courses-progress`)
        .then(res => {
          this.memberCourseProgress = res.data.data;
          this.fillData();
        })
        .catch(err => {
          console.log("fetchMemberCourseProgress error", err);
        });
    },
    fillData() {
      let courseAmount = this.memberCourseProgress.length;
      let completeCourseAmount = this.memberCourseProgress.filter(
        el => el.lectureStatus === "COMPLETED"
      ).length;
      let data = [completeCourseAmount, courseAmount - completeCourseAmount];
      this.legendValue[0] = completeCourseAmount;
      this.legendValue[1] = courseAmount - completeCourseAmount;

      console.log(data);
      this.datacollection = {
        labels: [`บทเรียนที่เรียนไปแล้ว`, `บนเรียนที่เรียนยังไม่จบ`],
        datasets: [
          {
            backgroundColor: ["rgb(235,129,35)", "rgb(237,237,237)"],
            data: data
          }
        ],
        borderWidth: 5
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000"
          }
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16
                }
              }
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage == "0.00%" ? null : percentage;
            }
          }
        },

        elements: {
          arc: {
            borderWidth: 0
          }
        },

        maintainAspectRatio: false,
        responsive: true
      };
      // console.log(this.option)
    }
  }
};
</script>

<style scoped>
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
</style>

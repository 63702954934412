<template>
  <div>
    <b-card :class="$options.name" class="cstudent-info">
      <div slot="header" class="mb-0">
        <i class="fa fa-user" aria-hidden="true"></i> ข้อมูลส่วนตัว
      </div>
      <!-- <b-container class="card-body mb-3" fluid>
            <template v-if="examAnswerSheetPending || examInfoPending">
                <i class="fas fa-spinner fa-pulse"></i>
            </template>
            <template v-else>
                <b-row column>
                    <b-col> ชื่อ - สกุล {{ member.memberName }} </b-col>
                    <b-col> อีเมล {{ member.memberEmail }} </b-col>
                    <b-col> ข้อสอบ {{ examTitle }} </b-col>
                </b-row>
            </template>
        </b-container> -->
      <template v-if="examAnswerSheetPending || examInfoPending">
        <b-row no-gutters>
          <b-col>
            <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row no-gutters>
          <b-col>
            <b-card-body>
              <b-card-text class="student-info">
                <h5>
                  ชื่อ-สกุล: <span>{{ member.memberName }}</span>
                </h5>
                <h5>
                  อีเมล: <span>{{ member.memberEmail }}</span>
                </h5>
                <h5 class="text-primary">
                  ข้อสอบ: <span>{{ examTitle }}</span>
                </h5>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import _ from "lodash";

export default {
  name: "ExanTakerProfileDetail",
  computed: {
    ...mapGetters("Company", [
      "examAnswerSheetData",
      "examAnswerSheetPending",
      "examInfoData",
      "examInfoPending"
    ]),
    member() {
      return this.examAnswerSheetData.metadata;
    },
    examTitle() {
      return this.examInfoData.title;
    }
  }
};
</script>

<style lang="scss" scoped>
.ExanTakerProfileDetail {
  // height: 280px;
}
.card-header {
  background-color: #5c97e8;
  color: #fff;
}
.student-info {
  span {
    font-weight: 300;
  }
  h5 {
    line-height: 2em;
  }
}
.cstudent-info {
  margin-bottom: 1em;
}
</style>

const time_24h = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00"
];
function getRandomInt() {
  return Math.floor(Math.random() * (400 - 0 + 1)) + 0;
}
const dataRandom1 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom2 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom3 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom4 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom5 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom6 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];
const dataRandom7 = [
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt(),
  getRandomInt()
];

export default {
  time_24h,
  dataRandom1,
  dataRandom2,
  dataRandom3,
  dataRandom4,
  dataRandom5,
  dataRandom6,
  dataRandom7
};

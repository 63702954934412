<template>
    <div class="card-layout">
      <b-row>
        <b-col cols="12" class="main">
          <slot name="header"></slot>
        </b-col>
        <b-col cols="12" class="body" style="border-top: 0px;">
          <div :style="{ height: height + 'px' }">
            <slot></slot>
            <slot name="body"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  props: ["height"]
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_dashboard.scss";
.main {
  font-family: "Prompt", sans-serif;
  height: auto;
  padding-top: 30px;
}
.main h6 {
  margin-bottom: 0 !important;
}
.body {
  height: auto;
  padding: 10px;
}

.D-HD-layout {
  padding: 10px;
}
</style>

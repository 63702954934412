import jsPDF from "jspdf";
import { font, fontBold } from "@/assets/fonts/Sarabun-normal.js";

export default {
  methods: {
    async generatePdf() {
      try {
        this.localBtnDisabled = true;
        await this.getMember();
        let pdf = new jsPDF(this.pdfOption);
        await this.generateChart();

        const pdf_width = pdf.internal.pageSize.width;
        const pdf_height = pdf.internal.pageSize.height;

        const margin_l = this.pdfConfig.margin.l;
        const margin_t = this.pdfConfig.margin.t;
        
        const cellWidth = 90;
        const cellHeight = 18;

        const addNewPageIfNeeded = (incrementValue) => {
          if ((pdf_position_y + incrementValue) >= pdf_height - margin_t) {
            pdf.addPage();
            pdf_position_y = margin_t; // Reset the position to the top of the new page
          } else {
            pdf_position_y += incrementValue; // Add the input value
          }
        };

        let pdf_position_y = 0;
        pdf.addFileToVFS("Sarabun-Regular.ttf", fontBold);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "bold");
        pdf.addFileToVFS("Sarabun-Regular.ttf", font);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "normal");

        pdf.setFont("Sarabun", "bold");

        pdf.setFontSize(this.pdfConfig.typo.header);
        pdf_position_y += margin_t;
        pdf.text(
          "รายงานการเข้าเรียน",
          pdf_width / 2,
          pdf_position_y,
          null,
          null,
          "center"
        );
        addNewPageIfNeeded(this.pdfConfig.endLegend);

        pdf.setFont("Sarabun", "normal");

        pdf.setFontSize(this.pdfConfig.typo.normal);
        this.thaitext(
          pdf,
          "ชื่อผู้เรียน : " + this.data.firstName + " " + this.data.lastName,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);

        pdf.text(
          "อีเมล : " + this.data.email,
          margin_l,
          pdf_position_y,
          null,
          null,
          "left"
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);

        this.thaitext(
          pdf,
          "ชื่อคอร์ส : " + this.data.courseName,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);

        pdf.text(
          "ประเภทคอร์ส : " + this.courseOnlineType,
          margin_l,
          pdf_position_y,
          null,
          null,
          "left"
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);

        pdf.text(
          "รหัสคอร์สเรียน : " + (this.data.courseCode !== null ? this.data.courseCode : ""),
          margin_l,
          pdf_position_y,
          null,
          null,
          "left"
        );

        this.thaitext(
          pdf,
          "วันที่สมัครเรียน : " + this.formatDateToThai(this.data.courseEnrollStartDate),
          margin_l + 185,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);
        this.thaitext(
          pdf,
          "ข้อมูลวันที่ : " + this.formatThaiMonthRange(this.start, this.end),
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfConfig.endNormal);

        //table 1
        const table1Data = [
          { column1: 'จำนวนชั่วโมงที่เข้าชั้นเรียน', column2: (this.data.SumLearningClassDuration.length === 0) ? "-" : this.secondsToHHMM(this.data.SumLearningClassDuration[0].total_duration) + ' จากทั้งหมด ' + this.secondsToHHMM(this.data.SumClassDuration[0].total_duration) + ' ชั่วโมง' },
          { column1: 'จำนวนชั่วโมงที่เข้าเรียนออนไลน์', column2: (this.data.LearningOnlineDuration.length === 0) ? "-" : this.secondsToHHMM(this.data.LearningOnlineDuration[0].total_learning_time) + ' ชั่วโมง' },
          { column1: 'จำนวนข้อสอบที่ทำ', column2: this.data.CountExam + ' ชุด' },
        ];

        if (this.data.CountExam === 0) {
          table1Data.splice(2, 1);
        }
        if (this.data.courseOnlineType === 'ONLINE') {
          table1Data.splice(0, 1); // Remove the first element from the array
        }

        pdf.setDrawColor(0, 0, 0);

        table1Data.forEach((row) => {
          // Draw the cell borders
          pdf.rect(margin_l, pdf_position_y, cellWidth + 90, cellHeight);
          pdf.rect(margin_l + cellWidth + 90, pdf_position_y, cellWidth + 90 , cellHeight);

          this.thaitext(pdf, row.column1, margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, row.column2, margin_l + 185, pdf_position_y + 12);
          addNewPageIfNeeded(this.pdfConfig.endRow);
        });


        addNewPageIfNeeded(this.pdfConfig.endRow);
        if (this.data.courseOnlineType === 'HYBRID'){
          pdf.setFont("Sarabun", "bold");
          this.thaitext(
            pdf,
            "สรุปการเข้าชั้นเรียน",
            margin_l,
            pdf_position_y
          );
          pdf_position_y += this.pdfConfig.margin.bHeaderTable;
          //table 2
          const table2Data = [
            { 
              column1: 'เข้าเรียน ' + this.countPresent() + ' ครั้ง', 
              column2: 'มาสาย ' + this.countLate() + ' ครั้ง', 
              column3: 'ขาดเรียน ' + this.countAbsent() + ' ครั้ง', 
              column4: 'ลา ' + this.countLeave() + ' ครั้ง',
            },
          ];


          pdf.setFont("Sarabun", "normal");
          table2Data.forEach((row) => {
            // Draw the cell borders
            pdf.rect(margin_l, pdf_position_y, cellWidth , cellHeight);
            pdf.rect(margin_l + cellWidth , pdf_position_y, cellWidth , cellHeight);
            pdf.rect(margin_l + cellWidth*2 , pdf_position_y, cellWidth , cellHeight);
            pdf.rect(margin_l + cellWidth*3 , pdf_position_y, cellWidth , cellHeight);

            this.thaitext(pdf, row.column1, margin_l + 5, pdf_position_y + 12);
            this.thaitext(pdf, row.column2, margin_l + 95, pdf_position_y + 12);
            this.thaitext(pdf, row.column3, margin_l + 185, pdf_position_y + 12);
            this.thaitext(pdf, row.column4, margin_l + 275, pdf_position_y + 12);
            addNewPageIfNeeded(this.pdfConfig.endRow);
          });

          addNewPageIfNeeded(this.pdfConfig.endRow);

          pdf.setFont("Sarabun", "bold");
          this.thaitext(
            pdf,
            "รายละเอียดการเข้าชั้นเรียน",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          pdf_position_y += this.pdfConfig.margin.bHeaderTable;

          pdf.rect(margin_l, pdf_position_y, 120 , cellHeight);
          pdf.rect(margin_l + 120 , pdf_position_y, 120 , cellHeight);
          pdf.rect(margin_l + 240  , pdf_position_y, 120 , cellHeight);

          this.thaitext(pdf, 'วันที่', margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, 'สถานะ', margin_l + 125, pdf_position_y + 12);
          this.thaitext(pdf, 'จำนวนชั่วโมง (ชั่วโมง:นาที)', margin_l + 245, pdf_position_y + 12);
          addNewPageIfNeeded(this.pdfConfig.endRow);

          pdf.setFont("Sarabun", "normal");
          if (this.data.classAttendanceStat.length === 0) {
            pdf.rect(margin_l, pdf_position_y, 120 , cellHeight);
            pdf.rect(margin_l + 120 , pdf_position_y, 120 , cellHeight);
            pdf.rect(margin_l + 240 , pdf_position_y, 120 , cellHeight);
            this.thaitext(pdf, "-", margin_l + 5, pdf_position_y + 12);
            this.thaitext(pdf, "-", margin_l + 125, pdf_position_y + 12);
            this.thaitext(pdf, "-", margin_l + 245, pdf_position_y + 12);
            addNewPageIfNeeded(this.pdfConfig.endRow);
          } else {
            this.data.classAttendanceStat.forEach((row) => {
              pdf.rect(margin_l, pdf_position_y, 120 , cellHeight);
              pdf.rect(margin_l + 120 , pdf_position_y, 120 , cellHeight);
              pdf.rect(margin_l + 240 , pdf_position_y, 120 , cellHeight);

              this.thaitext(pdf, this.formatDateToThaiShort(row.cls_date), margin_l + 5, pdf_position_y + 12);
              this.thaitext(pdf, this.classAttendanceStat(row.clsa_status), margin_l + 125, pdf_position_y + 12);
              this.thaitext(pdf, this.secondsToHHMM(row.clsa_duration), margin_l + 245, pdf_position_y + 12);
              addNewPageIfNeeded(this.pdfConfig.endRow);
            });
          }
          addNewPageIfNeeded(this.pdfConfig.endRow);

        }
        pdf.setFont("Sarabun", "bold");

        this.thaitext(
          pdf,
          "สรุปการเข้าเรียนออนไลน์",
          margin_l,
          pdf_position_y,
          null,
          null,
          "left"
        );
        pdf_position_y += this.pdfConfig.margin.bHeaderTable;

        pdf.rect(margin_l, pdf_position_y, 180 , cellHeight);
        pdf.rect(margin_l + 180 , pdf_position_y, 180 , cellHeight);

        this.thaitext(pdf, 'วันที่', margin_l + 5, pdf_position_y + 12);
        this.thaitext(pdf, 'จำนวนชั่วโมง (ชั่วโมง:นาที)', margin_l + 185, pdf_position_y + 12);
        addNewPageIfNeeded(this.pdfConfig.endRow);

        pdf.setFont("Sarabun", "normal");

        if (this.data.LearningSummary.length == 0 ) {
          pdf.rect(margin_l, pdf_position_y, 180 , cellHeight);
          pdf.rect(margin_l + 180 , pdf_position_y, 180 , cellHeight);
          this.thaitext(pdf, "-", margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, "-", margin_l + 185, pdf_position_y + 12);
        } else {
          this.data.LearningSummary.forEach((row) => {
            pdf.rect(margin_l, pdf_position_y, 180 , cellHeight);
            pdf.rect(margin_l + 180 , pdf_position_y, 180 , cellHeight);
            this.thaitext(pdf, this.formatDateToThaiShort(row.date), margin_l + 5, pdf_position_y + 12);
            this.thaitext(pdf, this.secondsToHHMM(row.total_learning_time), margin_l + 185, pdf_position_y + 12);
            addNewPageIfNeeded(this.pdfConfig.endRow);
          });
        }
        addNewPageIfNeeded(this.pdfConfig.endRow);
        pdf.addPage();
        pdf_position_y = margin_t;
        if ( this.data.ExamResult.length == 0 ) {
          pdf.setFont("Sarabun", "bold");
          pdf.text(
            "รายงานผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          addNewPageIfNeeded(this.pdfConfig.endRow);
          pdf.setFont("Sarabun", "normal");
          pdf.text(
            "ไม่มีผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          addNewPageIfNeeded(this.pdfConfig.endRow);
          pdf.setFont("Sarabun", "bold");
          pdf.text(
            "กราฟแสดงผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          addNewPageIfNeeded(this.pdfConfig.endRow);
          pdf.setFont("Sarabun", "normal");
          pdf.text(
            "ไม่มีผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          addNewPageIfNeeded(this.pdfConfig.endRow);
        } else {
          pdf.setFont("Sarabun", "bold");
          pdf.text(
            "รายงานผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          pdf_position_y += this.pdfConfig.margin.bHeaderTable;
          pdf.rect(margin_l, pdf_position_y, 45 , cellHeight);
          pdf.rect(margin_l + 45 , pdf_position_y, 195 , cellHeight);
          pdf.rect(margin_l + 240  , pdf_position_y, 120 , cellHeight);

          this.thaitext(pdf, 'ครั้งที่', margin_l + 12.5, pdf_position_y + 12);
          this.thaitext(pdf, 'ชื่อข้อสอบ', margin_l + 50, pdf_position_y + 12);
          this.thaitext(pdf, 'ผลคะแนน', margin_l + 245, pdf_position_y + 12);
          addNewPageIfNeeded(this.pdfConfig.endRow);

          pdf.setFont("Sarabun", "normal");
          this.data.ExamResult.forEach((row, index) => {
            pdf.rect(margin_l, pdf_position_y, 45 , cellHeight);
            pdf.rect(margin_l + 45 , pdf_position_y, 195 , cellHeight);
            pdf.rect(margin_l + 240 , pdf_position_y, 120 , cellHeight);
            this.thaitext(pdf, `${index + 1}`, margin_l + 20, pdf_position_y + 12);
            this.thaitext(pdf, row.lec_name, margin_l + 50, pdf_position_y + 12);
            this.thaitext(pdf, `${row.lel_examResultPercent}`, margin_l + 245, pdf_position_y + 12);
            addNewPageIfNeeded(this.pdfConfig.endRow);
          });
          pdf.rect(margin_l, pdf_position_y, 240 , cellHeight);
          pdf.rect(margin_l + 240  , pdf_position_y, 120 , cellHeight);

          this.thaitext(pdf, 'คะแนนเฉลี่ย', margin_l + 185, pdf_position_y + 12);
          this.thaitext(pdf, this.data.AverageExamResultPercent[0].average_percent + ' %', margin_l + 245, pdf_position_y + 12);
          addNewPageIfNeeded(this.pdfConfig.endRow * 2);
          if ((pdf_position_y + 252) >= pdf_height - margin_t) {
            pdf.addPage();
            pdf_position_y = margin_t;
          }
          pdf.setFont("Sarabun", "bold");
          pdf.text(
            "กราฟแสดงผลคะแนนสอบ",
            margin_l,
            pdf_position_y,
            null,
            null,
            "left"
          );
          addNewPageIfNeeded(this.pdfConfig.margin.tHeaderChart);

          pdf.setFontSize(this.pdfConfig.typo.chartHeader);
          pdf.setTextColor("#7E7E7E");
          pdf.text(
            "ผลคะแนนสอบ",
            margin_l + 5,
            pdf_position_y,
            null,
            null,
            "left"
          );

          addNewPageIfNeeded(this.pdfConfig.margin.tChart);
          await this.generateChart();
          setTimeout(() => {
            const canvas = document.getElementById("chartCanvas");
            const imageData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addImage(imageData, "JPEG", margin_l, pdf_position_y, this.chartWidth, this.chartHeight);
          }, 0);
        }

        setTimeout(() => {
          pdf.save("รายงานการเข้าเรียน.pdf");
        }, 0);
        this.localBtnDisabled = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
}
<template>
  <div
    class="card bg-frosted-glass border-0 shadow rounded-lg overflow-hidden set-position"
  >
    <div class="row no-gutters">
      <div class="col-md-6 login-box">
        <div
          class="text-right h-100 col-12 col-md-9 offset-md-3"
          style="padding: 80px 50px 0px 50px;"
        >
          <img src="@/assets/images/cs_logo.png" width="100%" />
          <div class="border-bottom border-light mt-4"></div>
          <h5 class="mt-4 font-weight-light text-light">Hub of Learning</h5>
        </div>
      </div>
      <div class="col-md-6 bg-light py-5 px-3">
        <div class="row">
          <div class="col-12 col-md-9">
            <form class="form-signin" @submit.prevent="login">
              <div class="text-left mb-4">
                <p class="text-secondary">
                  ลงชื่อเข้าสู่ระบบโดยใส่ชื่อผู้ใช้หรืออีเมล และรหัสผ่าน
                </p>
              </div>

              <div class="form-group">
                <input
                  id="inputEmail"
                  v-model="email"
                  type="text"
                  class="form-control form-rounded shadow-sm bg-white pl-4"
                  :class="{ 'is-invalid': hasErrors }"
                  placeholder="ชื่อผู้ใช้ หรือ อีเมล"
                  required="true"
                  autofocus="true"
                  :disabled="isLoading"
                />
              </div>

              <div class="form-group">
                <input
                  id="inputPassword"
                  v-model="password"
                  type="password"
                  class="form-control form-rounded shadow-sm bg-white pl-4"
                  :class="{ 'is-invalid': hasErrors }"
                  placeholder="รหัสผ่าน"
                  required="true"
                  :disabled="isLoading"
                />
                <div v-if="hasErrors" class="invalid-feedback pl-4">
                  <strong>ข้อมูลการเข้าสู่ระบบไม่ถูกต้อง</strong>
                </div>
              </div>

              <div v-if="showCaptcha">
                <vue-recaptcha  ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="captchaSiteKey">
                </vue-recaptcha>
              </div>

              <button
                class="btn btn-md btn-csq form-rounded shadow-sm mt-4 col-md-7"
                type="submit"
                :disabled="isLoading"
              >
                เข้าสู่ระบบ
                <span
                  v-if="isLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <i v-else class="fas fa-long-arrow-alt-right ml-2" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  data() {
    return {
      email: "",
      password: "",
      hasErrors: false,
      isLoading: false,
      loginAttempts: 0,
      showCaptcha: false,
      captchaSiteKey: "6Ld1RlwkAAAAAJepHhsF9_o3xwLATmYHVRw95vQa", //Captcha v2 checkbox Site Key 
      captchaVerify: false
    };
  },
  components: { 'vue-recaptcha': VueRecaptcha },
  computed: {
    ...mapGetters("Authentication", ["authUser"]),

    userWorkAtCompanies() {
      const roles = this.authUser.roles;
      const profiles = this.authUser.profiles;

      return profiles.filter(profile => {
        return roles.find(role => {
          return role.companyId == profile.company.id;
        });
      });
    }
  },
  methods: {
    login: function() {
      this.isLoading = true;
      let email = this.email;
      let password = this.password;
      if((this.showCaptcha == false)||(this.showCaptcha == true && this.captchaVerify == true))
      {
        this.$store
          .dispatch("Authentication/login", { email, password })
          .then(() => {
            this.hasErrors = false;
            this.isLoading = false;
            const strongPasswordAt = this.userWorkAtCompanies[0].strongPasswordAt;
            const companyUrl = this.userWorkAtCompanies[0].company.url;
            if(strongPasswordAt === null)
            {
              window.location.href = "/reset-password";
            } else {
              window.location.href = "/" + companyUrl;
            }
            // this.$router.push({
            //   name: "dashboard",
            //   params: { company: companyUrl }
            // });
          })
          .catch(() => {
            if(this.showCaptcha == true){
              this.$refs.recaptcha.reset();
              this.captchaVerify = false;
            }
            this.loginAttempts++;
            this.isLoading = false;
            this.hasErrors = true;
            if(this.loginAttempts >= 1){
              this.showCaptcha = true;
            }
          });
      }else{
        this.loginAttempts++;
        this.isLoading = false;
        this.hasErrors = false;
      }
    },
    onVerify: function() {
      return this.captchaVerify = true;
    },
    onExpired: function() {
      console.log('Expired');
    },
  },
};
</script>

<style lang="css" scoped>
.form-rounded {
  border-radius: 2rem;
}

.btn-csq {
  font-family: "Prompt", sans-serif;
  color: #f9fafb;
  background-color: #df812f;
  border-color: #e67424;
}

.btn-csq:hover {
  color: #f9fafb;
  background-color: #f3801c;
  border-color: #f3801c;
}

.text-csq {
  color: #df812f;
}

.login-box {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.text-secondary {
  font-family: "Prompt", sans-serif;
}

.set-position {
  margin-top: -9rem !important;
}
</style>

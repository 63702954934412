import IndividualCourseLearnerPage from "@/components/pages/analytics/course/individual-learner/IndividualCourseLearnerPage.vue";
import CourseAllPage from "@/components/pages/analytics/course/overview/CourseAllPage.vue";
import CourseOverviewPage from "@/components/pages/analytics/course/overview/CourseOverviewPage.vue";
import IndividualCoursePage from "@/components/pages/analytics/course/individual/IndividualCoursePage.vue";
import LearnerIndividualCourseCertificatePage from "@/components/pages/analytics/course/individual-learner/LearnerIndividualCourseCertificatePage.vue";
import IndividualCourseStudentRankingPage from "@/components/pages/analytics/course/individual/IndividualCourseStudentRankingPage.vue";
import IndividualCourseLectureRankingPage from "@/components/pages/analytics/course/individual/IndividualCourseLectureRankingPage.vue";
import CourseLectureViewPage from "@/components/pages/analytics/course/individual-learner/CourseLectureViewPage.vue";
import CourseStudentInfoPage from "@/components/pages/analytics/course/individual-learner/CourseStudentInfoPage.vue";
import CourseInstructorPage from "@/components/pages/analytics/course/overview/CourseInstructorPage.vue";
import CourseDiscussPage from "@/components/pages/analytics/course/overview/CourseDiscussPage.vue";
import IndividualDiscussPage from "@/components/pages/analytics/discuss/individual/IndividualDiscussPage.vue";
import DiscussOverviewPage from "@/components/pages/analytics/discuss/overview/DiscussOverviewPage.vue";
import CourseReviewPage from "@/components/pages/analytics/course/overview/CourseReviewPage.vue";
import CourseStudentNotStartedPage from "@/components/pages/analytics/course/individual-learner/CourseStudentNotStartedPage.vue";
import CourseMaterialDownloadPage from "@/components/pages/analytics/course/individual-learner/CourseMaterialDownloadPage.vue";
import UserOverviewPage from "@/components/pages/analytics/user/overview/UserOverviewPage.vue";
import UserOverviewRecentStudyPage from "@/components/pages/analytics/user/overview/recent-study/UserRecentStudyPage.vue";
import UserOverviewProvincePage from "@/components/pages/analytics/user/overview/province/UserProvincePage.vue";
import UserOverviewProvinceLoginPage from "@/components/pages/analytics/user/overview/province-login/UserProvinceLoginPage.vue";
import UserOverviewMostLoginPage from "@/components/pages/analytics/user/overview/most-login/UserMostLoginPage.vue";
import UserLearningStatsPage from "@/components/pages/analytics/user/learning-stats/UserLearningStatsPage.vue";
import UserLearningStatusPage from "@/components/pages/analytics/user/learning-stats/UserLearningStatusPage.vue";
import UserLearningStatsRecentStudyPage from "@/components/pages/analytics/user/learning-stats/recent-study/UserRecentStudyPage.vue";
import UserLearningStatsLearnProgressPage from "@/components/pages/analytics/user/learning-stats/learn-progress/UserLearnProgressPage.vue";
import UserLearningStatsCertificatePage from "@/components/pages/analytics/user/learning-stats/certificate/UserCertificatePage.vue";
import IndividualExamPage from "@/components/pages/analytics/exam/individual/IndividualExamPage.vue";
import ExamOverviewPage from "@/components/pages/analytics/exam/overview/ExamOverview.vue";
import ExamRecentExamPage from "@/components/pages/analytics/exam/overview/recent-exam/ExamRecentExam.vue";
import ExamMostExamPage from "@/components/pages/analytics/exam/overview/most-exam/ExamMostExam.vue";
import ExamIndividualLearnerPage from "@/components/pages/analytics/exam/individual-learner/ExamIndividualLearner.vue";
import ExamIndividualLearnerExamResultPage from "@/components/pages/analytics/exam/individual-learner/exam-result/ExamIndividualLearnerExamResult.vue";
import IndividualExamRecentAnswerSheetPage from "@/components/pages/analytics/exam/individual/IndividualExamRecentAnswerSheetPage.vue";
import IndividualExamQuestionOverviewPage from "@/components/pages/analytics/exam/individual/IndividualExamQuestionOverviewPage.vue";
import RevenueOverviewPage from "@/components/pages/analytics/revenue/RevenueOverviewPage.vue";
import RevenueSummaryPage from "@/components/pages/analytics/revenue/RevenueSummaryPage.vue";
import CouponPage from "@/components/pages/analytics/revenue/CouponPage.vue";
import TfacSummaryPage from "@/components/pages/analytics/tfac/TfacSummaryPage.vue";
import PaymentSummaryPage from "@/components/pages/analytics/tfac/PaymentSummaryPage.vue";
import TfacStudentInfoPage from "@/components/pages/analytics/tfac/TfacStudentInfoPage.vue";
import CertificateOverViewPage from "@/components/pages/analytics/certificate/CertificateOverview.vue";
import IndividualCertificateOverviewPage from "@/components/pages/analytics/certificate/individual/IndividualCertificateOverview.vue";
import UserLearningStatsLearnProgressOverviewPage from "@/components/pages/analytics/user/learning-stats/learn-progress/UserLearnProgressOverviewPage.vue";
import UserLearningStatsLearnProgressFaceDatactionPage from "@/components/pages/analytics/user/learning-stats/learn-progress/panel/StudentUserLearnDetectionPage.vue";
import UserLearningHistoryPage from "@/components/pages/analytics/user/overview/UserLearningHistoryPage.vue";
import CourseLearningHistoryListPage from "../components/pages/analytics/user/overview/CourseLearningHistoryListPage.vue";
import UserLearningHistoryLogPage from "../components/pages/analytics/user/overview/UserLearningHistoryLogPage.vue";


import store from "@/store/index.js";

function getUserRoles() {
  let companyId = localStorage.getItem("ComId")
  let roles = store.state.Authentication.authUser.roles;
  let userRoles = roles
    .filter((el) => el.companyId == companyId)
    .map((el) => el.roleName);
  return userRoles;
}

function getCompanyUrl() {
  return store.state.Authentication.authUser.profiles[0].company.url;
}

function hasRoles(roles, allowedRoles) {
  for (let allowedRole of allowedRoles) {
    if (roles.includes(allowedRole)) return true;
  }
  return false;
}

export default [
  {
    path: "analytics/users/overview",
    name: "analytics.user.overview",
    component: UserOverviewPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
  },

  {
    path: "report/learning-history-report",
    name: "report.learning-history-report",
    component: UserLearningHistoryPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member-analytic"]
    }
  },
  {
    path: "report/learning-history-report/members/:mId",
    name: "report.learning-history-report.members",
    component: CourseLearningHistoryListPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member-analytic"]
    }
  },
  {
    path: "report/learning-history-report/enrolls/:cerId",
    name: "report.learning-history-report.enrolls",
    component: UserLearningHistoryLogPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member-analytic"]
    }
  },
  {
    path: "analytics/users/provinces",
    name: "analytics.user.overview.province",
    component: UserOverviewProvincePage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
    beforeEnter: (to, from, next) => {
      let userRoles = getUserRoles();
      if (hasRoles(userRoles, ["org/admin", "org/hr", "org/audit", "tutor/admin"])) next();
      else next(`/${getCompanyUrl()}/analytics/users/overview`);
    },
  },
  {
    path: "analytics/users/:mId/learning-stats",
    name: "analytics.user.learning-stats",
    component: UserLearningStatsPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
  },
  {
    path: "analytics/users/:cerId/learning-status",
    name: "analytics.user.learning-status",
    component: UserLearningStatusPage,
    meta: {
      template: "A",
    },
  },
  {
    path: "analytics/users/recent-study",
    name: "analytics.user.overview.recent-study",
    component: UserOverviewRecentStudyPage,
    props: { template: "A" },
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
  },
  {
    path: "analytics/users/most-login",
    name: "analytics.user.overview.most-login",
    component: UserOverviewMostLoginPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
    beforeEnter: (to, from, next) => {
      let userRoles = getUserRoles();
      if (hasRoles(userRoles, ["org/admin", "org/hr", "org/audit", "tutor/admin"])) next();
      else next(`/${getCompanyUrl()}/analytics/users/overview`);
    },
  },
  {
    path: "analytics/users/province-login",
    name: "analytics.user.overview.province-login",
    component: UserOverviewProvinceLoginPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-member-analytic"],
    },
    beforeEnter: (to, from, next) => {
      let userRoles = getUserRoles();
      if (hasRoles(userRoles, ["org/admin", "org/hr", "org/audit", "tutor/admin"])) next();
      else next(`/${getCompanyUrl()}/analytics/users/overview`);
    },
  },
  {
    path: "analytics/users/:mId/learning-stats/recent-study",
    name: "analytics.user.learning-stats.recent-study",
    component: UserLearningStatsRecentStudyPage,
    meta: {
      template: "A",
    },
  },
  {
    path: "analytics/users/:mId/learning-stats/learn-progress",
    name: "analytics.user.learning-stats.learn-progress",
    component: UserLearningStatsLearnProgressPage,
    meta: {
      template: "A",
    },
  },
  {
    path: "analytics/users/:mId/learning-stats/certificate",
    name: "analytics.user.learning-stats.certificate",
    component: UserLearningStatsCertificatePage,
    meta: {
      template: "A",
    },
  },
  {
    path: "analytics/courses/overview",
    name: "analytics.course.overview",
    component: CourseOverviewPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },

  {
    path: "analytics/courses/instructors",
    name: "analytics.course.instructor",
    component: CourseInstructorPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/discuss",
    name: "analytics.course.discuss",
    component: CourseDiscussPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/discuss/:cId",
    name: "analytics.individual.discuss",
    component: IndividualDiscussPage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-discussion"],
    },
  },

  {
    path: "analytics/discuss",
    name: "analytics.discuss",
    component: DiscussOverviewPage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-discussion"],
    },
  },
  {
    path: "analytics/courses/review",
    name: "analytics.course.review",
    component: CourseReviewPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/courses-list",
    name: "analytics.course.list",
    component: CourseAllPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId",
    name: "analytics.learner.course",
    component: IndividualCourseLearnerPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/overview",
    name: "analytics.individual.course",
    component: IndividualCoursePage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/lecture-views",
    name: "analytics.course.lecture-views",
    component: CourseLectureViewPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/material-downloads",
    name: "analytics.course.material-downloads",
    component: CourseMaterialDownloadPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/student-info",
    name: "analytics.course.student-info",
    component: CourseStudentInfoPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/student-ranking",
    name: "analytics.course.student-ranking",
    component: IndividualCourseStudentRankingPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/certificate",
    name: "analytics.course.certificate",
    component: LearnerIndividualCourseCertificatePage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/lecture-ranking",
    name: "analytics.course.lecture-ranking",
    component: IndividualCourseLectureRankingPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/courses/:cId/student-not-started",
    name: "analytics.course.student-not-started",
    component: CourseStudentNotStartedPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-course-analytic"],
    },
  },
  {
    path: "analytics/exam/:examId",
    name: "analytics.individual.exam",
    component: IndividualExamPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/exam/:examId/recent-answersheet",
    name: "analytics.recent.answersheet",
    component: IndividualExamRecentAnswerSheetPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },

  {
    path: "analytics/exam/:examId/exam-question-stat",
    name: "analytics.question.stat",
    component: IndividualExamQuestionOverviewPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  //#endregion
  {
    path: "analytics/exams/overview",
    name: "analytics.exam.overview",
    component: ExamOverviewPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/exams/most-exams",
    name: "analytics.exam.most-exams",
    component: ExamMostExamPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/exams/recent-exams",
    name: "analytics.exam.recent-exams",
    component: ExamRecentExamPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/exams/member/:memberId/exam/:examId",
    name: "analytics.exam.individual-learner",
    component: ExamIndividualLearnerPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/exams/member/:memberId/exam/:examId/exam-result",
    name: "analytics.exam.individual-learner/exam-result",
    component: ExamIndividualLearnerExamResultPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-exam-analytic"],
    },
  },
  {
    path: "analytics/revenue/overview",
    name: "analytics.revenue.overview",
    component: RevenueOverviewPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-revenue-analytic"],
    },
  },
  {
    path: "analytics/revenue/summary",
    name: "analytics.revenue.summary",
    component: RevenueSummaryPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-revenue-analytic"],
    },
  },
  {
    path: "analytics/revenue/coupon",
    name: "analytics.revenue.coupon",
    component: CouponPage,
    meta: {
      template: "A",
      authRequired: true,
      permsRequired: ["read-revenue-analytic"],
    },
  },

  {
    path: "analytics/tfac/overview",
    name: "analytics.tfac.overview",
    component: TfacSummaryPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-revenue-analytic"],
    },
  },
  {
    path: "analytics/tfac/payment-summary",
    name: "analytics.tfac.payment.summary",
    component: PaymentSummaryPage,
    meta: {
      template: "A",
    },
  },
  {
    path: "analytics/tfac/student-info",
    name: "analytics.tfac.student.info",
    component: TfacStudentInfoPage,
    meta: {
      template: "A",
    },
  },

  {
    path: "analytics/certificate/overview",
    name: "analytics.certificate.overview",
    component: CertificateOverViewPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-certificate-report"],
    },
  },

  {
    path: "analytics/certificate/:cId/overview",
    name: "analytics.individual.certificate",
    component: IndividualCertificateOverviewPage,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "analytics/users/:mId/learning-stats/learn-progress/:cerId/courses/:cId/overview",
    name: "analytics.user.learning-stats.learn-progress.courses.overview",
    component: UserLearningStatsLearnProgressOverviewPage,
    meta: {
      template: "A",
    },
  },

  {
      path: "analytics/users/:mId/learning-stats/learn-progress/:cerId/courses/:cId/face-detect",
      name: "analytics.user.learning-stats.learn-progress.courses.face-detect",
      component: UserLearningStatsLearnProgressFaceDatactionPage,
      meta: {
        template: "A",
      },
  },

];

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-header',{attrs:{"title":`ประวัติการตรวจสอบใบหน้า`}}),_c('b-container',{staticClass:"widthPage mx"},[_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('b-breadcrumb',[_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{
                name: 'members.face-approve-search-history',
              }}},[_vm._v("ประวัติการตรวจสอบใบหน้า ")])],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.courseInfo.courseName))])],1)],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('div',[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.courseInfo.courseName))])])])]),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('layout',{staticClass:"user-learn-progress",scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"body",fn:function(){return [_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-prepend',{staticClass:"input-search"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.searchName()}}},[_vm._v("ค้นหา")])],1)],1)],1)])],1),_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.listResult,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"cell(cer_completeDate)",fn:function(data){return [[_c('span',[_vm._v(_vm._s(data.item.cer_completeDate ? data.item.cer_completeDate : "ยังเรียนไม่จบ")+" ")])]]}},{key:"cell(cer_status)",fn:function(data){return [[_c('span',[_vm._v(_vm._s(data.item.cer_status === "NOT_STARTED" ? "ยังไม่เริ่มเรียน" : data.item.cer_status === "IN_PROGRESS" ? "กำลังเรียน" : data.item.cer_status === "COMPLETED" ? "เรียนจบแล้ว" : "-")+" ")])]]}},{key:"cell(cer_approveFaceStatus)",fn:function(data){return [[_c('span',{class:[
                      data.item.cer_approveFaceStatus === 'NOT_APPROVED'
                        ? 'text-danger'
                        : data.item.cer_approveFaceStatus === 'APPROVING'
                        ? 'text-warning'
                        : data.item.cer_approveFaceStatus === 'APPROVED'
                        ? 'text-success'
                        : '',
                    ]},[_vm._v(_vm._s(data.item.cer_approveFaceStatus === "NOT_APPROVED" ? "ยังไม่ได้ยืนยัน" : data.item.cer_approveFaceStatus === "APPROVING" ? "กำลังยืนยัน" : data.item.cer_approveFaceStatus === "APPROVED" ? "ยืนยันแล้ว" : "-")+" ")])]]}},{key:"cell(option)",fn:function(data){return [_c('div',[_c('router-link',{staticClass:"text-light btn btn-primary btn-sm",attrs:{"to":{
                      name: 'members.face-verification.face-approve.courses',
                      params: { cerId: data.item.cer_id, cId: _vm.cId },
                    },"target":"_blank"}},[_vm._v(" ตรวจสอบ ")])],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}])}),(!_vm.rows)?_c('div',{staticClass:"text-center"},[_c('h6',[_vm._v("ไม่มีข้อมูล")])]):_vm._e()]},proxy:true}])}),_c('div',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"6 "}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('p',{staticClass:"pr-3"},[_vm._v("แสดง")]),_c('b-form-select',{staticStyle:{"width":"75px"},on:{"change":_vm.changeShowData},model:{value:(_vm.selectedShow),callback:function ($$v) {_vm.selectedShow=$$v},expression:"selectedShow"}},[_c('option',{attrs:{"value":"5"}},[_vm._v("5")]),_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"20"}},[_vm._v("20")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])]),_c('p',{staticClass:"pl-3"},[_vm._v("จาก "+_vm._s(_vm.rows)+" รายการ")])],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"promotion-list-table","align":"right","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
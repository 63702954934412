<template>
  <b-card
    :class="$options.name"
    title="จำนวนครั้งที่ไม่ผ่าน"
    class="text-center ex-pass"
  >
    <b-container :class="`${$options.name}__body`" class="mb-3" fluid>
      <template v-if="examStatPending || examInfoPending">
        กำลังโหลด
      </template>
      <template v-else>
        <b-card-text>
          <h1>{{ examStatData.answerSheetFailCount }}</h1>
        </b-card-text>
        <b-card>
          <em slot="footer">คิดเป็น {{ failPercentage }} ของจำนวนครั้งที่ทำ</em>
        </b-card>
      </template>
    </b-container>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExamFailCount",
  computed: {
    ...mapGetters("Company", [
      "examStatPending",
      "examStatData",
      "examInfoPending",
      "examInfoData"
    ]),
    failPercentage() {
      let percentage =
        (this.examStatData.answerSheetFailCount /
          this.examStatData.answerSheetCount) *
        100;
      return `${percentage.toFixed(2)} %`;
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamAvgScore {
  &__body {
    padding-bottom: 0;
    padding-top: 2.8em;
  }
}
.ex-pass {
  padding-top: 1.2em;
  h1 {
    font-size: 6em;
  }
}
.card {
  margin-bottom: 1em;
}
.card-title {
  color: #ffb12e;
  text-align: start;
  font-size: 1.2em;
}
.card-footer {
  background-color: #fff;
  border: 0;
  text-align: end;
  color: #707070;
}
</style>

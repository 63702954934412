<template>
  <div>
    <b-nav tabs align="center" class="csq-steps row no-gutters ml-0 mr-0">
      <b-nav-item :to="{ name: 'course.management.info' }" class="col-1.95">
        1 <span class="d-none d-xl-inline-block">: ข้อมูลคอร์ส</span>
        <div class="icon-check float-center"></div>
      </b-nav-item>
      <b-nav-item  @click="goToContents()" :active="isCurrentPage('contents')" class="col-1.95">
        2 <span class="d-none d-xl-inline-block">: เนื้อหาคอร์ส</span>
        <div class="icon-check float-center"></div>
      </b-nav-item>
      <b-nav-item  @click="goToMaterials()" :active="isCurrentPage('materials')" class="col-1.95">
        3 <span class="d-none d-xl-inline-block">: เอกสารประกอบ</span>
        <div class="icon-check float-center"></div>
      </b-nav-item>
      <b-nav-item  @click="goToRules()" :active="isCurrentPage('rules')" class="col-1.95">
        4 <span class="d-none d-xl-inline-block">: เกณฑ์การจบคอร์ส</span>
        <div class="icon-check float-center">
          <i v-show="false" class="fa fa-check" aria-hidden="true"></i>
        </div>
      </b-nav-item>
      <b-nav-item  @click="goToEvaluation()" :active="isCurrentPage('evaluation')" class="col-1.95">
        5 <span class="d-none d-xl-inline-block">: ตั้งค่าอื่นๆ</span>
        <div class="icon-check float-center">
          <i v-show="false" class="fa fa-check" aria-hidden="true"></i>
        </div>
      </b-nav-item>
    </b-nav>
    <AlertIncompleteModal
      :show="isShowAlertIncompleteModal"
      :message="errorMessage"
      @closed="isShowAlertIncompleteModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AlertIncompleteModal from './AlertIncompleteModal';
import RequireFieldChecking from './RequireFieldChecking.js';
export default {
  name: "NavSteps",
  data() {
    return {
      isShowAlertIncompleteModal: false,
      errorMessage: "",
    };
  },
  components: { AlertIncompleteModal },
  computed: {
    ...mapGetters("CourseManagement", [
        "isLoading",
        "courseInfo",
      ],
    ),
    ...mapGetters("Company",[
        "companyInfo"
      ],
    ),
  },
  methods:{
    goToContents(){
      if(!this.isCurrentPage('contents') && RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.$router.push({
          name: "course.management.contents",
        });
      }
      else{
        this.errorMessage = "กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    goToMaterials(){
      if(!this.isCurrentPage('materials') && RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.$router.push({
          name: "course.management.materials",
        });
      }
      else{
        this.errorMessage = "กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    goToRules(){
      if(!this.isCurrentPage('rules') && RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.$router.push({
          name: "course.management.rules",
        });
      }
      else{
        this.errorMessage = "กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    goToEvaluation(){
      if(!this.isCurrentPage('evaluation') && RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.$router.push({
          name: "course.management.evaluation",
        });
      }
      else{
        this.errorMessage = "กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    checkCourseInfoRequire(){
      let pass = this.courseInfo.courseName != null && this.courseInfo.courseName.length>0 &&
      this.courseInfo.courseOwner != null && this.courseInfo.courseOwner.ownerId != null;
      if(this.isCompany([485])){
        pass = pass && this.courseInfo.tfac.cpdCourseCode != null && this.courseInfo.approveDate != null &&
        this.courseInfo.startLearningDate != null && this.courseInfo.finishLearningDate != null &&
        this.courseInfo.price != null && this.courseInfo.membershipPrice[0] != null && this.courseInfo.membershipPrice[0].price != null;
      }
      return pass;
    },
    isCompany(comIdArr) {
      return comIdArr.includes(this.companyInfo.id);
    },
    isCurrentPage(name){
      return this.$route.name == 'course.management.'+name;
    }
  },
};
</script>

<style scoped lang="scss">
.nav-tabs {
  border-bottom: 4px solid #dee2e6;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .router-link-exact-active,
.nav-tabs .router-link-active {
  color: #495057 !important;
  font-weight: 500 !important;
  background-color: transparent;
  border: 0px !important;
  border-bottom: 5px solid #f99f4e !important;
}
.nav-tabs .nav-link {
  border: 0px;
  position: relative;
  bottom: -3px;
  text-align: center;
  color: #b2b2b2;
  font-size: 1.2em;
  font-weight: 100;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.icon-check {
  background-color: #f99f4e;
  color: #fff;
  width: 32px;
  height: 32px;
  font-size: 16px;
  padding: 4px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: -19px;
  border-radius: 50%;
}
.csq-steps {
  font-family: 'Prompt', sans-serif;
  font-size: 15px;
}
</style>

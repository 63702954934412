<script type="text/babel">
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    dataset: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        animation: {
          animateScale: true
        }
      }
    };
  },
  computed: {
    datacollection() {
      return {
        labels: ["จำนวนข้อที่ถูก", "จำนวนข้อที่ไม่ถูก", "จำนวนข้อที่ไม่ได้ตอบ"],
        datasets: [
          {
            data: this.dataset,
            backgroundColor: ["#7BB8FF", "#FFB538", "#445C7E"]
          }
        ]
      };
    }
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  }
};
</script>

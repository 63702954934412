<template>
  <div>
    <PageHeader title="กลุ่มส่วนลด">></PageHeader>

    <!-- LearningStatSummary -->
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <div class="inner bg-container">
          <b-row>
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="เลือกหมวดหมู่คอร์ส*"
                class="mb-0"
              >
                <b-form-select v-model="tagSelect" @change="getCourseInTag()">
                  <option value="null">กรุณาเลือกหมวดหมู่คอร์ส</option>
                  <option
                    v-for="(tagsItems, tagsIndex) in tags"
                    :key="tagsIndex"
                    :value="tagsItems.tagId"
                  >
                    {{ tagsItems.tagName }}
                  </option></b-form-select
                >
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-5">
              <h3 class="text-dark">คอร์สที่อยู่ในหมวดหมู่คอร์สนี้</h3>
              <div class="card no-shadow px-4">
                <template v-if="isLoading">
                  <div class="text-center my-5 text-csq">
                    <b-spinner
                      class="align-middle"
                      variant="primary"
                    ></b-spinner>
                    <p>Loading...</p>
                  </div>
                </template>
                <b-table
                  v-else
                  class=""
                  id="promotion-group-list-table"
                  hover
                  :fields="fields"
                  :items="listResult"
                >
                  <template v-slot:cell(c_price)="data">
                    <template>
                      <span>{{ data.item.c_price | formatNumber }} </span>
                    </template>
                  </template>
                </b-table>
                <b-row class="mt-4">
                  <b-col cols="6 ">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <p class="pr-3">แสดง</p>
                      <b-form-select
                        v-model="selectedShow"
                        style="width: 75px"
                        @change="changeShowData"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </b-form-select>
                      <p class="pl-3">จาก {{ rows }} รายการ</p>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="promotion-group-list-table"
                      align="right"
                      last-number
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col cols="12" class="mt-5">
              <h5 class="text-dark"><strong>เกณฑ์การให้ส่วนลด</strong></h5>
              <b-row>
                <b-col cols="6">
                  <div class="card no-shadow px-4 py-3">
                    <template v-if="isLoading">
                      <div class="text-center my-5 text-csq">
                        <b-spinner
                          class="align-middle"
                          variant="primary"
                        ></b-spinner>
                        <p>Loading...</p>
                      </div>
                    </template>
                    <template v-else>
                      <b-row class="mb-3">
                        <b-col cols="6">
                          <b-form-group label="จำนวนคอร์สที่ซื้อ">
                            <b-input-group append="คอร์ส">
                              <b-form-input
                                v-model="minimumCourse"
                                placeholder="ใส่ตัวเลข"
                                type="number"
                                onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
                                min="0"
                              >
                              </b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <b-form-group
                            id="input-group-1"
                            label="ส่วนลด*"
                            class="mb-0"
                          >
                            <b-form-select
                              v-model="discountType"
                              :options="optionsDiscount"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" class="d-flex align-items-end">
                          <b-input-group
                            :append="discountType === 'PERCENT' ? '%' : 'บาท'"
                          >
                            <b-form-input
                              v-model="discountValue"
                              placeholder="ใส่ตัวเลข"
                              type="number"
                              onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
                              min="1"
                              :max="maxDiscountValue"
                              @change="validateDiscountValue"
                            >
                            </b-form-input>
                          </b-input-group>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div class="card no-shadow px-4 py-3">
                    <template v-if="isLoading">
                      <div class="text-center my-5 text-csq">
                        <b-spinner
                          class="align-middle"
                          variant="primary"
                        ></b-spinner>
                        <p>Loading...</p>
                      </div>
                    </template>
                    <template v-else>
                      <b-row class="mb-3">
                        <b-col cols="6">
                          <b-form-group label="จำนวนวันเรียน">
                            <b-form-checkbox
                              switch
                              class="mb-2"
                              v-model="hasPromotionLimitedTime"
                            >
                              กำหนดช่วงเวลา
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="จำนวนวันเรียน">
                            <b-input-group append="วัน">
                              <b-form-input
                                v-model="learnDuration"
                                placeholder="ใส่ตัวเลข"
                                type="number"
                                onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
                                min="0"
                                :disabled="hasPromotionLimitedTime == false"
                              >
                              </b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <b-form-group
                            label="วันที่เริ่มโปรโมชั่น"
                            class="mb-0"
                          >
                            <b-form-input
                              v-model="coursePromotionStartDate"
                              type="date"
                              :min="dateFormat"
                              :max="dataCoursePromotionPriceEnd"
                              :disabled="hasPromotionLimitedTime == false"
                              :state="isPromotionDateValid"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                              :state="isPromotionDateValid"
                            >
                              วันที่เริ่มโปรโมชั่นต้องน้อยกว่าวันที่สิ้นสุดโปรโมชั่น
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group
                            label="วันที่สิ้นสุดโปรโมชั่น"
                            class="mb-0"
                          >
                            <b-form-input
                              v-model="coursePromotionEndDate"
                              type="date"
                              :min="coursePromotionStartDate"
                              :disabled="hasPromotionLimitedTime == false"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <br>
                          <b-form-group id="input-group-1" label="นับวันเรียน">
                            <b-form-radio-group v-model="startDateType" name="radio-options">
                              <b-form-radio value="START_LEARNING">วันที่เข้าเรียนวันแรก <span v-if="this.$store.state.Company.companyInfo.companyStartDateType == 'START_LEARNING'">(ค่าเริ่มต้น)</span></b-form-radio><br>
                              <b-form-radio value="ENROLL">วันลงทะเบียน <span v-if="this.$store.state.Company.companyInfo.companyStartDateType == 'ENROLL'">(ค่าเริ่มต้น)</span></b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" class="mt-5 d-flex justify-content-between">
              <router-link
                :to="{ name: 'purchase.course.promotion.list' }"
                class="text-csq"
              >
                <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
                ย้อนกลับ
              </router-link>

              <b-button
                type="submit"
                variant="primary"
                @click="submitPromotions()"
                :disabled="
                  !tagSelect ||
                  !minimumCourse ||
                  !discountType ||
                  !discountValue ||
                  (hasPromotionLimitedTime &&
                    (!coursePromotionStartDate ||
                      !coursePromotionEndDate ||
                      !learnDuration) &&
                    coursePromotionStartDate >= coursePromotionEndDate)
                "
                >บันทึก</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import moment from "moment";
import api from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";

export default {
  components: {
    PageHeader,
  },

  filters: {
    formatNumber: function (value) {
      if (!isNaN(value)) {
        return Numeral(value).format("0,0.00");
      } else {
        return value;
      }
    },
  },

  data() {
    return {
      tagSelect: null,
      tags: [],
      comId: this.$store.state.Company.companyInfo.id,
      startDateType: this.$store.state.Company.companyInfo.companyStartDateType,
      fields: [
        {
          key: "c_name",
          label: "คอร์สเรียน",
          thStyle: "width: 400px;border-top:0px",
        },
        { key: "tutor", label: "ผู้สอน", thStyle: "border-top:0px" },
        { key: "c_price", label: "ราคา", thStyle: "border-top:0px" },
      ],
      tagsCouser: [],
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
      minimumCourse: null,
      optionsDiscount: [
        { value: null, text: "เลือกส่วนลด" },
        { value: "FIX", text: "บาท" },
        { value: "PERCENT", text: "เปอร์เซ็นต์ (%)" },
      ],
      discountType: null,
      discountValue: null,
      learnDuration: null,
      hasPromotionLimitedTime: false,
      coursePromotionStartDate: null,
      coursePromotionEndDate: null,
      dateFormat: moment().format("YYYY-MM-DD"),
      promotionId: this.$route.params.promotionId,
    };
  },

  computed: {
    ...mapGetters("CourseManagement", ["coursePromotionsGroup", "isLoading"]),
    rows() {
      return this.tagsCouser.length;
    },
    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      var showData = this.tagsCouser.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      );
      return showData;
    },
    dataCoursePromotionPriceEnd() {
      if (
        moment(this.coursePromotionEndDate).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
      ) {
        return null;
      } else {
        return this.coursePromotionEndDate;
      }
    },
    isPromotionDateValid() {
      if (
        moment(this.coursePromotionEndDate).format("YYYY-MM-DD") <
        moment(this.coursePromotionStartDate).format("YYYY-MM-DD")
      ) {
        return false;
      } else {
        return null;
      }
    },
    maxDiscountValue() {
      if (this.discountType == "PERCENT") {
        return 100;
      }
      return null;
    },
  },
  created() {
    this.getTags();
    this.fetchPromotionList();
  },
  watch: {
    discountType(newValue) {
      if (newValue == 'PERCENT' && this.discountValue > 100)
        this.discountValue = 1
    }
  },
  methods: {
    ...mapActions("CourseManagement", [
      "editPromotionsGroup",
      "getPromotionsGroup",
      "setTabIndexPromotion",
    ]),
    async getTags() {
      await api
        .get(`/v1/tags`)
        .catch(({ response }) => {
          console.error(response);
        })
        .then(({ data }) => {
          this.tags = data.data;
        });
    },

    async getCourseInTag() {
      await api
        .get(`/v1/tags/get-company-course-tags`, {
          params: {
            tag_id: this.tagSelect,
          },
        })
        .catch(({ response }) => {
          console.error(response);
        })
        .then(({ data }) => {
          this.tagsCouser = data;
        });
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },

    async submitPromotions() {
      if (!this.hasPromotionLimitedTime) {
        this.coursePromotionStartDate = "NULL";
        this.coursePromotionEndDate = "NULL";
        this.learnDuration = 0;
      }
      let request = {
        body: {
          name: this.tags.filter((x) => x.tagId === this.tagSelect)[0].tagName,
          tagId: this.tagSelect,
          minimumCourse: this.minimumCourse,
          discountType: this.discountType,
          discountValue: this.comId == 519 && this.discountType == "FIX" ? this.discountValue * 1.07 : this.discountValue,
          startDate: this.coursePromotionStartDate,
          endDate: this.coursePromotionEndDate,
          learnDuration: this.learnDuration,
          startDateType: this.startDateType,
        },
        tcd_id: this.promotionId,
      };

      await this.editPromotionsGroup(request).then(() => {
        this.setTabIndexPromotion(1);
        this.$router.push({
          name: "purchase.course.promotion.list",
        });
      });
    },

    async fetchPromotionList() {
      await this.getPromotionsGroup(null).then(() => {
        let data;
        data = this.coursePromotionsGroup.filter(
          (x) => x.tcd_id === parseInt(this.promotionId)
        )[0];

        this.tagSelect = data.tag_id;
        this.minimumCourse = data.tcd_minimumCourse;
        this.discountType = data.tcd_discountType;
        this.discountValue =
          data.tcd_discountType === "FIX"
            ? this.comId == 519 ? parseFloat((data.tcd_discountValue / 1.07).toFixed(2)) : data.tcd_discountValue //data.tcd_discountValue / 1.07
            : data.tcd_discountPercent;
        this.learnDuration = data.tcd_learnDuration;
        this.startDateType = data.tcd_startDateType;
        this.coursePromotionStartDate = data.tcd_startDate
          ? data.tcd_startDate.slice(0, 10) || moment().format("YYYY-MM-DD")
          : null;
        this.coursePromotionEndDate = data.tcd_endDate
          ? data.tcd_endDate.slice(0, 10) || moment().format("YYYY-MM-DD")
          : null;

        if (
          this.coursePromotionStartDate &&
          this.coursePromotionEndDate &&
          this.learnDuration
        ) {
          this.hasPromotionLimitedTime = true;
        }
        this.getCourseInTag();
      });
    },
    validateDiscountValue() {
      if (this.discountType == "PERCENT" && this.discountValue > 100)
        this.discountValue = 100;
      if (this.discountValue < 1) this.discountValue = 1;
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}
.h6 {
  margin-bottom: 0 !important;
}
.map-row {
  padding-top: 2rem !important;
}
.widthPage {
  max-width: 81.25rem;
}
</style>

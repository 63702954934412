<template>
  <div class="style-background border border-dark w-100">
    <b-row align-v="center" class="h-100 p-2">
      <b-col class="col-12">
        <span class="style-text">จำนวนครั้งที่เข้าสอบ</span>
      </b-col>
      <b-col class="col-12 text-center">
        <span v-if="isLoading">Loading ...</span>
        <span v-else class="style-amount-number">{{
          displayAnswerSheetAmount
        }}</span>
      </b-col>
      <b-col class="col-12 text-right">
        <span class="style-text">ครั้ง</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import adminService from '@/services/api.js';

export default {
  data() {
    return {
      isLoading: true,
      company: null,
      quizIds: [],
      answerSheetAmount: 0,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAvailableQuizIds();
  },
  computed: {
    displayAnswerSheetAmount() {
      let amount = '';
      if (this.answerSheetAmount >= Math.pow(10, 9)) {
        amount = (this.answerSheetAmount / Math.pow(10, 9)).toFixed(2) + ' G';
      } else if (this.answerSheetAmount >= Math.pow(10, 6)) {
        amount = (this.answerSheetAmount / Math.pow(10, 6)).toFixed(2) + ' M';
      } else if (this.answerSheetAmount >= Math.pow(10, 3)) {
        amount = (this.answerSheetAmount / Math.pow(10, 3)).toFixed(2) + ' K';
      } else {
        amount = this.answerSheetAmount;
      }
      return amount;
    },
  },
  methods: {
    async fetchAvailableQuizIds() {
      this.fetchAnswerSheets();
    },
    async fetchAnswerSheets() {
      this.isLoading = true;
      const endpoint = `/v1/analytics/exams/count-answer-sheets/${this.company.id}`;
      const res = await adminService.get(endpoint);
      this.answerSheetAmount = res.data;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.style-background {
  height: 200px;
}
.style-text {
  font-size: 1.1rem;
}
.style-amount-number {
  font-weight: bold;
  font-size: 3rem;
  color: #f7c65c;
}
</style>

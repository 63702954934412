<template>
  <div>
    <layout>
      <template v-slot:header v-if="tab == false">
        <b-row>
          <b-col class="my-auto" cols="6">
            <h6 class="my-auto">รีวิว</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/courses/review`"
              target="_blank"
              ><h6>ดูรีวิวทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <b-row class="px-3 mt-3">
          <b-col style="color: #EB8123">
            <strong>
              รีวิวเฉลี่ย
              {{
                (
                  result.reduce((a, c) => (a += c.course_review_rating), 0) /
                  result.length
                ).toFixed(2)
              }}
              ดาว
            </strong>
          </b-col>
        </b-row>
        <div class="d-flex flex-wrap px-3 mb-3">
          <div
            class="review-box d-flex justify-content-center align-items-center"
            @click="filter = 'all'"
          >
            ทั้งหมด
          </div>
          <div class="review-box" @click="filter = '5'">
            <star-rating class="star-style" :rating="5"></star-rating>
            <span class="ml-2">
              {{ result.filter((i) => i.course_review_rating == 5).length }}
              รีวิว
            </span>
          </div>
          <div class="review-box" @click="filter = '4'">
            <star-rating class="star-style" :rating="4"></star-rating>
            <span class="ml-2">
              {{
                result.filter((i) => Math.floor(i.course_review_rating) == 4)
                  .length
              }}
              รีวิว
            </span>
          </div>
          <div class="review-box" @click="filter = '3'">
            <star-rating class="star-style" :rating="3"></star-rating>
            <span class="ml-2">
              {{
                result.filter((i) => Math.floor(i.course_review_rating) == 3)
                  .length
              }}
              รีวิว
            </span>
          </div>
          <div class="review-box" @click="filter = '2'">
            <star-rating class="star-style" :rating="2"></star-rating>
            <span class="ml-2">
              {{
                result.filter((i) => Math.floor(i.course_review_rating) == 2)
                  .length
              }}
              รีวิว
            </span>
          </div>
          <div class="review-box" @click="filter = '1'">
            <star-rating class="star-style" :rating="1"></star-rating>
            <span class="ml-2">
              {{
                result.filter((i) => Math.floor(i.course_review_rating) == 1)
                  .length
              }}
              รีวิว
            </span>
          </div>
          <div class="review-box" @click="filter = '0'">
            <star-rating class="star-style" :rating="0"></star-rating>
            <span class="ml-2">
              {{
                result.filter((i) => Math.floor(i.course_review_rating) == 0)
                  .length
              }}
              รีวิว
            </span>
          </div>
        </div>
        <b-row
          v-for="(item, index) in displayFilteredResults"
          :key="index"
          class="px-3"
        >
          <b-col cols="12">
            <div class="w-100" style="border: 0.25px solid #D1D2D4;"></div>
            <div class="d-flex my-3 px-3">
              <div class="text-center" style="width: 100px;">
                <img :src="item.mp_displayPath" class="user-profile-image" />
              </div>
              <div class="w-100 pr-2">
                <div class="d-flex">
                  <div class="mr-3 user-name d-flex align-items-center">
                    <strong class="my-auto">{{
                      `${item.mp_firstName} ${item.mp_lastName} ${item.course_review_rating}`
                    }}</strong>
                  </div>
                  <div class="mr-3 d-flex align-items-center">
                    <star-rating
                      :rating="item.course_review_rating"
                      class="my-auto"
                    ></star-rating>
                  </div>
                </div>
                <div class="user-course-time mt-3 mb-4">
                  {{ item.c_name }} | โพสต์เมื่อ {{ item.course_review_date }}
                </div>
                <div
                  class="user-message pr-5"
                  style="word-wrap: break-word;"
                  v-html="item.course_review_message"
                ></div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-pagination
          v-if="tab == true"
          v-model="currentPage"
          :total-rows="getPaginationTotalRows()"
          :per-page="perPage"
        ></b-pagination>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import starRating from "./CourseInfoStarRating";
import api from "@/services/api.js";

export default {
  components: {
    layout,
    starRating,
  },
  props: ["apiRoute", "perPage", "tab"],
  data() {
    return {
      company: {},
      numbers: [],
      currentPage: 1,
      result: [],
      isBusy: true,
      items: [],
      rows: 0,
      filter: "all",
    };
  },
  methods: {
    // getRows(filter) {
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // },
    getPaginationTotalRows() {
      let answer = null;
      if (this.filter === "all") {
        answer = this.result.length;
      } else {
        answer = this.result.filter((el) => {
          return (
            Math.floor(el.course_review_rating) === parseInt(this.filter, 10)
          );
        }).length;
      }
      return answer;
    },
  },
  computed: {
    displayFilteredResults() {
      let end = this.perPage * this.currentPage;
      let start = end - this.perPage;
      let answer = [];
      if (this.filter === "all") {
        answer = this.result.slice(start, end);
      } else {
        answer = this.result
          .filter((el) => {
            return (
              Math.floor(el.course_review_rating) === parseInt(this.filter, 10)
            );
          })
          .slice(start, end);
      }
      return answer;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>

<style scoped>
.review-box {
  width: 180px;
  height: 70px;
  border: 1px solid #d1d2d4;
  border-radius: 4px;
  margin: 10px 15px 0px 0px;
  padding: 5px 0px 0px 8px;
  cursor: pointer;
}
.star-style {
  margin: 5px;
}
.user-profile-image {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course-time {
  font-size: x-small;
}
.user-message {
  font-size: medium;
}
</style>

<template>
  <b-modal
    title="ยืนยันการยกเลิกการลงทะเบียน"
    :id="modalId"
    footerClass="border-top-0"
  >
    <b-list-group>
      <b-list-group-item v-for="item in items" :key="item.cerId">
        <span v-show="item.fullName">{{ item.fullName }}</span>
        <span v-show="item.firstName">{{ item.firstName }}</span>
        <span v-show="item.lastName"> {{ item.lastName }}</span>
        <small v-if="item.idCard" class="small text-muted">
          ({{ item.idCard }})
        </small>
      </b-list-group-item>
    </b-list-group>
    <template v-slot:modal-footer>
      <b-button @click="$bvModal.hide(modalId)" variant="link">ยกเลิก</b-button>
      <b-button @click="doUnenroll" variant="primary" :disabled="onLoading">
        <b-spinner v-if="onLoading" small></b-spinner> ยกเลิกการลงทะเบียน
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ConfirmUnenrollModal",
  props: {
    modalId: String,
    courseId: [Number, String],
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      onLoading: false
    };
  },
  computed: {
    cerIds() {
      return this.items.map(item => item.cerId);
    }
  },
  methods: {
    ...mapActions("CourseEnrollment", ["unenroll"]),
    async doUnenroll() {
      this.onLoading = true;

      const { data } = await this.unenroll({
        courseId: this.courseId,
        cerIds: this.cerIds
      });

      if (data.status === "success") {
        this.onLoading = false;
        this.$emit("closed", this.modalId);
        this.$bvToast.toast("ยกเลิกการลงทะเบียนเรียบร้อยแล้ว", {
          title: "สำเร็จ",
          variant: "success"
        });
      }
    }
  }
};
</script>

<template>
  <b-container fluid="xl">
    <layout>
      <template v-slot:header>ผู้เข้าเรียนพร้อมกัน - Concurrent Users</template>
      <template v-slot:body>
        <b-row class="justify-content-end mb-3 pr-0">
          <b-col cols="3" class="pr-0">
            <month-year-picker
              id="dpicker"
              class="mb-2 mr-sm-2 mb-sm-0 pr-0"
              v-model="date"
              style="width:100%"
            ></month-year-picker>
          </b-col>
        </b-row>

        <bar-chart
          :chart-data="data"
          :options="options"
          :height="300"
        ></bar-chart>
      </template>
    </layout>
  </b-container>
</template>

<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import BarChart from "@/components/charts/BarChart.js";
import MonthYearPicker from "@/components/pages/analytics/shared/MonthYearPicker.vue";
import moment from "moment";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";
import api from "@/services/api.js";
export default {
  mixins: [chartMockMixin],
  components: {
    BarChart,
    Layout,
    MonthYearPicker,
  },
  props: ["apiRoute"],
  data() {
    return {
      max: null,
      date: moment().format("YYYY/MM"),
      result: {},
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20,
                min: 0,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(
        this.apiRoute + "?date=" + (this.date._i ? this.date._i : this.date)
      );
      this.max = res.data.max;
      this.result = res.data.data;
      this.mockBarChart(res.data.data);
      this.data = {
        labels: [],
        datasets: [
          {
            // Name the series
            data: Object.values(this.result).map((el) =>
              el.count > this.max ? this.max : el.count
            ), // Specify the data values array
            // this.result).map((el) => el.count
            label: "จำนวนผู้ใช้ปกติ",
            // Add custom color border (Line)
            backgroundColor: this.backgroundColor,
          },
          {
            // Name the series
            data: Object.values(this.result).map(
              (el) => el.count > this.max && el.count - this.max
            ), // Specify the data values array
            // this.result).map((el) => el.count
            label: "จำนวนผู้ใช้เกินกำหนด",
            // Add custom color border (Line)
            backgroundColor: "#E78C06",
          },
        ],
      };
      this.result.forEach((element) => {
        this.data.labels.push(element.day);
      });
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    date() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped></style>

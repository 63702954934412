<template>
  <div>
    <b-row>
      <b-col md="6" lg="4">
        <SearchBox
          :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
          @typed="setSearchKeyword"
        />
      </b-col>
    </b-row>

    <b-table
      :fields="fields"
      :items="members.data"
      :busy="isLoading"
      :sort-by.sync="fetchParams.sort_by"
      :sort-desc.sync="sortDesc"
      no-local-sorting
      small
      sort-icon-left
      responsive
      table-class="mt-4 border-bottom"
    >
      <template v-slot:cell(fullname)="{ item }">{{
        `${item.firstName} ${item.lastName}`
      }}</template>
      <template v-slot:cell(groups)="props">
        <span
          v-for="(group, index) in props.item.groups"
          :key="index"
          class="badge badge-secondary mr-1"
          >{{ group.name }}</span
        >
      </template>
      <template v-slot:cell(selection)="{ item }">
        <ToggleSelectionButton
          item-key="members"
          :item-value="{
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
            groups: item.groups
          }"
        />
      </template>
      <div slot="table-busy" class="text-center text-danger my-2">
        <Loading />
      </div>
    </b-table>

    <Pagination
      :items="members"
      :limits="perPageOptions"
      @changePage="fetchParams.page = $event"
      @changeLimit="setLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchBox from "@/components/shared/SearchBox";
import Pagination from "@/components/shared/Pagination";
import Loading from "@/components/shared/Loading";
import ToggleSelectionButton from "./ToggleSelectionButton";
import MembersTableMixin from "@/mixins/members-table-mixin.js";

export default {
  name: "SelectMembersTable",
  components: { SearchBox, Pagination, Loading, ToggleSelectionButton },
  mixins: [MembersTableMixin],
  computed: {
    ...mapGetters("Company", ["companyInfo"]),
    ...mapGetters("MemberManagement", ["members"]),
    fields() {
      return [
        ...this.tableFields,
        { key: "groups", label: "กลุ่มผู้เรียน", sortable: true },
        {
          label: "",
          key: "selection",
          sortable: false,
          thStyle: "width: 70px"
        }
      ];
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchMembers"]),
    async fetch() {
      this.isLoading = true;
      await this.fetchMembers({
        companyId: this.companyInfo.id,
        params: this.fetchParams
      }).then(() => (this.isLoading = false));
    },
    setLimit(perPage) {
      this.fetchParams.limit = perPage;
      this.fetchParams.page = 1;
    }
  }
};
</script>

<template>
  <div>
    <PageHeader title="รายงานสรุปการขายรายหลักสูตร"></PageHeader>
    <b-container class="mt-5">
      <div class="text-font mb-4">
        <p>
          <strong>
            กรุณาระบุช่วงเวลาที่ต้องการดาวน์โหลดรายงาน
          </strong>
        </p>
        <div class="my-4">
          <p><strong>ช่วงวันที่ชำระเงิน</strong></p>
          <div style="width: 250px; margin-top: 5px;">
            <b-input-group>
              <b-form-input
                size="sm"
                type="text"
                :value="formattedDate"
                placeholder="ค้นหาช่วงวันที่ซื้อ"
                disabled
              />
              <b-input-group-prepend>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="showCalendarRange()"
                >
                  <b-icon icon="calendar-range"></b-icon>
                </b-button>
              </b-input-group-prepend>
            </b-input-group>
            <DatePicker
              id="calendar-range"
              style="
                  display: none;
                  float: right;
                  position: relative;
                  will-change: transform;
                  left: 0px;
                "
              v-model="range"
              is-range
            />
          </div>
        </div>

        <div class="my-4" style="width: 400px">
          <p><strong>หลักสูตรทั้งหมด</strong></p>
          <v-select
            v-if="courses != null"
            class="mt-2"
            :options="courses"
            label="courseName"
            v-model="courseData"
            placeholder="กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร..."
            @input="getPurchaseSummaryList()"
            :clearable="false"
          />
          <v-select v-else class="mt-2" placeholder="กำลังโหลดข้อมูล..." />
        </div>
        <strong style="color: #ffcc00">
          หมายเหตุ: ระบบทำการ generate report เป็นไฟล์ .xlsx
          และดาวน์โหลดลงบนเครื่องของคุณ
        </strong>
      </div>

      <div
        v-if="isCardShow"
        class="card card-csq no-shadow mt-4"
        style="min-height: 280px;"
      >
        <template v-if="isLoading">
          <div class="text-center mt-5">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <p class="mt-3"><strong>กรุณารอสักครู่</strong></p>
            <p>เรากำลังเตรียมรายงานสรุปการขายรายหลักสูตร</p>
          </div>
        </template>
        <div v-else-if="!isLoading && reportData.length > 0" class="card-body">
          <b-row>
            <b-col cols="12" style="text-align: right;">
              <b-button
                @click="exportReport()"
                id="export-excel"
                variant="primary"
                style="display: inline-block"
                href=""
              >
                <b-spinner
                  id="export-excel-loading"
                  class="align-middle mr-2"
                  style="display: none"
                  small
                ></b-spinner>
                <i
                  id="download-icon"
                  class="fa fa-angle-double-down"
                  aria-hidden="true"
                ></i>
                ดาวน์โหลดรายงาน
              </b-button>
              <ag-grid-vue
                style="width: 100%; height: 800px; display: none"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                @grid-ready="onGridReady"
                :rowData="reportData"
                :excelStyles="excelStyles"
              ></ag-grid-vue>
            </b-col>
          </b-row>

          <b-row
            class="py-2 mt-4 mx-1"
            style="border-bottom: 2px solid #c9c9c9;"
          >
            <b-col cols="1"><strong>ลำดับ</strong></b-col>
            <b-col cols="3"><strong>ชื่อหลักสูตร</strong></b-col>
            <b-col cols="2"><strong>รหัสหลักสูตร</strong></b-col>
            <b-col cols="2"><strong>ชื่อ-นามสกุลผู้เรียน</strong></b-col>
            <b-col cols="2"><strong>วันที่ชำระเงิน</strong></b-col>
            <b-col cols="2"><strong>ราคาหลักสูตรก่อนภาษีขาย</strong></b-col>
          </b-row>
          <b-row
            v-for="(report, reportIndex) in purchaseSummaryList"
            :key="reportIndex"
            class="py-2 mx-1"
            style="border-bottom: 1px solid #c9c9c9"
          >
            <b-col cols="1">{{
              (currentPage - 1) * perPage + reportIndex + 1
            }}</b-col>
            <b-col cols="3">{{ report.c_name }}</b-col>
            <b-col cols="2">{{ report.c_courseCode }}</b-col>
            <b-col cols="2">{{ report.mp_name }}</b-col>
            <b-col cols="2">
              {{ formatDate(report.co_purchaseDate) }}
            </b-col>
            <b-col cols="2" class="text-right">
              {{ report.price_exclude_vat }}
            </b-col>
          </b-row>

          <div class="row mt-3">
            <div class="col-lg-6">
              <form class="form-inline">
                <div class="form-group mb-2">
                  <label>แสดง</label>
                </div>
                <div class="form-group ml-2 mb-2">
                  <b-form-select
                    v-model="perPage"
                    :options="[5, 10, 15, 30, 50]"
                    size="sm"
                  ></b-form-select>
                </div>
                <div class="form-group ml-2 mb-2">
                  <label>
                    จาก
                    {{ rows }}
                    รายการ
                  </label>
                </div>
              </form>
            </div>
            <div class="col-lg-6">
              <nav class="float-right" aria-label="Page navigation example">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  last-number
                ></b-pagination>
              </nav>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="text-center mt-5">
            <p>ไม่มีข้อมูลสรุปการขายรายหลักสูตร</p>
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import api from "@/services/api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: { DatePicker, PageHeader, vSelect, "ag-grid-vue": AgGridVue },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      range: {
        start: "",
        end: "",
      },
      selectedDate: {
        start: null,
        end: null,
      },
      perPage: 10,
      currentPage: 1,
      courses: null,
      reportData: "",
      isLoading: false,
      isCardShow: false,
      courseData: "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร...",
      gridApi: null,
      columnApi: null,
      excelStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0",
          },
        },
        {
          id: "currencyFormat",
          numberFormat: {
            format: "#,##0.00 €",
          },
        },
        {
          id: "negativeInBrackets",
          numberFormat: {
            format: "$[blue] #,##0;$ [red](#,##0)",
          },
        },
        {
          id: "booleanType",
          dataType: "Boolean",
        },
        {
          id: "stringType",
          dataType: "String",
        },
        {
          id: "dateType",
          dataType: "DateTime",
        },
        {
          id: "dateISO",
          dataType: "DateTime",
          numberFormat: {
            format: "dd-mm-yyyy",
          },
        },
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "decimalFormat",
          numberFormat: {
            format: "#,##0.00",
          },
          alignment: {
            horizontal: "Right",
          },
        },
      ],
      columnDefs: [
        {
          headerName: "รายงานสรุปการขายรายหลักสูตร",
          children: [
            { field: "ลำดับ", valueGetter: hashValueGetter },
            { headerName: "ชื่อหลักสูตร", field: "c_name" },
            { headerName: "วิทยากร", field: "cow_name" },
            { headerName: "รหัสหลักสูตร", field: "c_courseCode" },
            { headerName: "ชื่อ - นามสกุลผู้เรียน", field: "mp_name" },
            {
              headerName: "รหัสผู้เข้าอบรม / เลขประจำตัวบัตรประชาชน",
              field: "mp_idCard",
              cellClass: "stringType",
            },
            {
              headerName: "วันที่ชำระเงิน",
              field: "co_purchaseDate",
              cellClass: (params) => {
                return params.value == " " ? "" : "dateISO";
              },
            },
            {
              headerName: "วันที่เริ่มของหลักสูตร",
              field: "c_startLearningDate",
              cellClass: (params) => {
                return params.value == " " ? "" : "dateISO";
              },
            },
            {
              headerName: "วันที่สิ้นสุดของหลักสูตร",
              field: "c_finishLearningDate",
              cellClass: (params) => {
                return params.value == " "
                  ? ""
                  : params.value == "สรุปยอดราคาหลักสูตร"
                  ? "stringType"
                  : "dateISO";
              },
            },
            {
              headerName: "ราคาหลักสูตรก่อนภาษีขาย",
              field: "price_exclude_vat",
              cellClass: "decimalFormat",
            },
            { headerName: "ภาษีขาย", field: "vat", cellClass: "decimalFormat" },
            {
              headerName: "ราคาหลักสูตร",
              field: "coi_price",
              cellClass: "decimalFormat",
            },
            {
              headerName: "วันที่ผู้เรียนเริ่มเรียน",
              field: "cer_startDate",
              cellClass: (params) => {
                return params.value == " " ? "" : "dateISO";
              },
            },
            {
              headerName:
                "จำนวนเวลาที่เหลือยังไม่เข้าเรียนทั้งหมดของหลักสูตร (วินาที)",
              field: "notstarted_sec",
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    await api.get(`v1/companies/${this.comId}/courses`).then(({ data }) => {
      this.courses = data.data;
    });
  },
  methods: {
    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    formatApiDate() {
      this.selectedDate.start = moment(this.range.start).format("YYYY-MM-DD");
      this.selectedDate.end = moment(this.range.end).format("YYYY-MM-DD");
    },
    async getPurchaseSummaryList() {
      this.isCardShow = true;
      this.isLoading = true;
      await api
        .get(
          `v1/tfac/purchase-summary-by-course-report?reportStartDate=${this.selectedDate.start}&reportEndDate=${this.selectedDate.end}&courseId=${this.courseData.courseId}`
        )
        .then(({ data }) => {
          this.reportData = data;
          this.isLoading = false;
        });
    },
    exportReport() {
      document.getElementById("export-excel-loading").style.display =
        "inline-block";
      document.getElementById("download-icon").style.display = "none";

      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "รายงานสรุปการขายรายหลักสูตร",
          fileName: `รายงานสรุปการขายรายหลักสูตร`,
        });
        document.getElementById("export-excel-loading").style.display = "none";
        document.getElementById("download-icon").style.display = "inline-block";
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    formatDate(date) {
      return date != " " ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        this.formatApiDate();
        return (
          moment(this.range.start).format("DD MMM YYYY") +
          " - " +
          moment(this.range.end).format("DD MMM YYYY")
        );
      } else {
        return "";
      }
    },
    rows() {
      return this.reportData.length - 1;
    },
    purchaseSummaryList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      let data = this.reportData.slice(start, end);
      return data.slice(0, -1);
    },
  },
};
let hashValueGetter = function(params) {
  return !params.node.lastChild ? params.node.rowIndex + 1 : " ";
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

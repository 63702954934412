<template>
  <div>
    <b-form id="tfac-course-quarter">
      <b-row v-if="comId == 519 || comId == 513">
        <b-col>
          <h5><strong>ข้อมูลไตรมาส</strong></h5>
        </b-col>
      </b-row>
      <b-row v-if="comId == 519 || comId == 513">
        <div class="quarter-filed ">
          <b-row>
            <b-col>
              <h6><strong>ไตรมาสที่ 1 (1 มกราคม - 31 มีนาคม)</strong></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert v-model="showWarningText1" variant="warning">
                <b-icon icon="info-circle" variant="warning"></b-icon>
                <span style="color: #ffc107 !important;">
                  การแก้ไขข้อมูล จะไม่เปลี่ยนแปลง Certificate
                  และรายงานที่เรียนจบไปก่อนหน้านี้
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  <h6>
                    รหัสหลักสูตร (CPD)
                  </h6>
                </template>
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="form.tfacQuarter.cpdCourseCodeQ1"
                    placeholder=""
                    :readonly="readonly"
                    :state="validCpdCourseCodeQ1"
                    @change="validCpdCourseCodeQ1 = null; showWarningText1 = false"
                    @blur="onblurTfacQuarterCpdCourseCode('Q1')"
                    @keyup.enter="onblurTfacQuarterCpdCourseCode('Q1')"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="validCpdCourseCodeQ1">
                  {{ cpdCourseCodeInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  วันที่อนุมัติหลักสูตร (CPD)
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.approveDateQ1"
                  type="date"
                  :readonly="readonly"
                  :state="validApproveDateQ1"
                  @change="validApproveDateQ1 = null; showWarningText1 = false"
                  @blur="onblurCourseInfoQuarterData('Q1')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q1')"
                />
                <b-form-invalid-feedback :state="validApproveDateQ1">
                  {{ approveDateInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="วันที่เปิดขายหลักสูตร">
                <b-form-input
                  required
                  v-model="form.tfacQuarter.startSellingCourseDateQ1"
                  type="date"
                  :readonly="readonly"
                  :state="validStartSellingQ1"
                  @change="validStartSellingQ1 = null; showWarningText1 = false"
                  @blur="onblurCourseInfoQuarterData('Q1')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q1')"
                />
                <b-form-invalid-feedback :state="validStartSellingQ1">
                  {{ startSellingInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="enabledInputDate">
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่เริ่มหลักสูตร
                </template>
                <b-form-input
                  required
                  type="date"
                  v-model="form.tfacQuarter.startLearningDateQ1"
                  :state="validStartLearningQ1"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q1')"
                  @change="validStartLearningQ1 = null; showWarningText1 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q1')"
                />
                <b-form-invalid-feedback :state="validStartLearningQ1">
                  {{ startLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่จบหลักสูตร
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.finishLearningDateQ1"
                  type="date"
                  :state="validFinishLearningQ1"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q1')"
                  @change="validFinishLearningQ1 = null; showWarningText1 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q1')"
                />
                <b-form-invalid-feedback :state="validFinishLearningQ1">
                  {{ finishLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="comId == 519 || comId == 513">
        <div class="quarter-filed ">
          <b-row>
            <b-col>
              <h6><strong>ไตรมาสที่ 2 (1 เมษายน - 30 มิถุนายน)</strong></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert v-model="showWarningText2" variant="warning">
                <b-icon icon="info-circle" variant="warning"></b-icon>
                <span style="color: #ffc107 !important;">
                  การแก้ไขข้อมูล จะไม่เปลี่ยนแปลง Certificate
                  และรายงานที่เรียนจบไปก่อนหน้านี้
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  <h6>
                    รหัสหลักสูตร (CPD)
                  </h6>
                </template>
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="form.tfacQuarter.cpdCourseCodeQ2"
                    placeholder=""
                    :readonly="readonly"
                    :state="validCpdCourseCodeQ2"
                    @change="validCpdCourseCodeQ2 = null; showWarningText2 = false"
                    @blur="onblurTfacQuarterCpdCourseCode('Q2')"
                    @keyup.enter="onblurTfacQuarterCpdCourseCode('Q2')"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="validCpdCourseCodeQ2">
                  {{ cpdCourseCodeInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  วันที่อนุมัติหลักสูตร (CPD)
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.approveDateQ2"
                  type="date"
                  :readonly="readonly"
                  :state="validApproveDateQ2"
                  @change="validApproveDateQ2 = null; showWarningText2 = false"
                  @blur="onblurCourseInfoQuarterData('Q2')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q2')"
                />
                <b-form-invalid-feedback :state="validApproveDateQ2">
                  {{ approveDateInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="วันที่เปิดขายหลักสูตร">
                <b-form-input
                  required
                  v-model="form.tfacQuarter.startSellingCourseDateQ2"
                  type="date"
                  :readonly="readonly"
                  :state="validStartSellingQ2"
                  @change="validStartSellingQ2 = null; showWarningText2 = false"
                  @blur="onblurCourseInfoQuarterData('Q2')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q2')"
                />
                <b-form-invalid-feedback :state="validStartSellingQ2">
                  {{ startSellingInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="enabledInputDate">
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่เริ่มหลักสูตร
                </template>
                <b-form-input
                  required
                  type="date"
                  v-model="form.tfacQuarter.startLearningDateQ2"
                  :state="validStartLearningQ2"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q2')"
                  @change="validStartLearningQ2 = null; showWarningText2 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q2')"
                />
                <b-form-invalid-feedback :state="validStartLearningQ2">
                  {{ startLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่จบหลักสูตร
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.finishLearningDateQ2"
                  type="date"
                  :state="validFinishLearningQ2"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q2')"
                  @change="validFinishLearningQ2 = null; showWarningText2 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q2')"
                />
                <b-form-invalid-feedback :state="validFinishLearningQ2">
                  {{ finishLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="comId == 519 || comId == 513">
        <div class="quarter-filed ">
          <b-row>
            <b-col>
              <h6><strong>ไตรมาสที่ 3 (1 กรกฎาคม - 30 กันยายน)</strong></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert v-model="showWarningText3" variant="warning">
                <b-icon icon="info-circle" variant="warning"></b-icon>
                <span style="color: #ffc107 !important;">
                  การแก้ไขข้อมูล จะไม่เปลี่ยนแปลง Certificate
                  และรายงานที่เรียนจบไปก่อนหน้านี้
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  <h6>
                    รหัสหลักสูตร (CPD)
                  </h6>
                </template>
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="form.tfacQuarter.cpdCourseCodeQ3"
                    placeholder=""
                    :readonly="readonly"
                    :state="validCpdCourseCodeQ3"
                    @change="validCpdCourseCodeQ3 = null; showWarningText3 = false"
                    @blur="onblurTfacQuarterCpdCourseCode('Q3')"
                    @keyup.enter="onblurTfacQuarterCpdCourseCode('Q3')"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="validCpdCourseCodeQ3">
                  {{ cpdCourseCodeInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  วันที่อนุมัติหลักสูตร (CPD)
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.approveDateQ3"
                  type="date"
                  :readonly="readonly"
                  :state="validApproveDateQ3"
                  @change="validApproveDateQ3 = null; showWarningText3 = false"
                  @blur="onblurCourseInfoQuarterData('Q3')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q3')"
                />
                <b-form-invalid-feedback :state="validApproveDateQ3">
                  {{ approveDateInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="วันที่เปิดขายหลักสูตร">
                <b-form-input
                  required
                  v-model="form.tfacQuarter.startSellingCourseDateQ3"
                  type="date"
                  :readonly="readonly"
                  :state="validStartSellingQ3"
                  @change="validStartSellingQ3 = null; showWarningText3 = false"
                  @blur="onblurCourseInfoQuarterData('Q3')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q3')"
                />
                <b-form-invalid-feedback :state="validStartSellingQ3">
                  {{ startSellingInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="enabledInputDate">
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่เริ่มหลักสูตร
                </template>
                <b-form-input
                  required
                  type="date"
                  v-model="form.tfacQuarter.startLearningDateQ3"
                  :state="validStartLearningQ3"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q3')"
                  @change="validStartLearningQ3 = null; showWarningText3 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q3')"
                />
                <b-form-invalid-feedback :state="validStartLearningQ3">
                  {{ startLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่จบหลักสูตร
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.finishLearningDateQ3"
                  type="date"
                  :state="validFinishLearningQ3"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q3')"
                  @change="validFinishLearningQ3 = null; showWarningText3 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q3')"
                />
                <b-form-invalid-feedback :state="validFinishLearningQ3">
                  {{ finishLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="comId == 519 || comId == 513">
        <div class="quarter-filed">
          <b-row>
            <b-col>
              <h6><strong>ไตรมาสที่ 4 (1 ตุลาคม - 31 ธันวาคม)</strong></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert v-model="showWarningText4" variant="warning">
                <b-icon icon="info-circle" variant="warning"></b-icon>
                <span style="color: #ffc107 !important;">
                  การแก้ไขข้อมูล จะไม่เปลี่ยนแปลง Certificate
                  และรายงานที่เรียนจบไปก่อนหน้านี้
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  <h6>
                    รหัสหลักสูตร (CPD)
                  </h6>
                </template>
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="form.tfacQuarter.cpdCourseCodeQ4"
                    placeholder=""
                    :readonly="readonly"
                    :state="validCpdCourseCodeQ4"
                    @change="validCpdCourseCodeQ4 = null; showWarningText4 = false"
                    @blur="onblurTfacQuarterCpdCourseCode('Q4')"
                    @keyup.enter="onblurTfacQuarterCpdCourseCode('Q4')"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="validCpdCourseCodeQ4">
                  {{ cpdCourseCodeInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <template v-slot:label>
                  วันที่อนุมัติหลักสูตร (CPD)
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.approveDateQ4"
                  type="date"
                  :readonly="readonly"
                  :state="validApproveDateQ4"
                  @change="validApproveDateQ4 = null; showWarningText4 = false"
                  @blur="onblurCourseInfoQuarterData('Q4')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q4')"
                />
                <b-form-invalid-feedback :state="validApproveDateQ4">
                  {{ approveDateInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="วันที่เปิดขายหลักสูตร">
                <b-form-input
                  required
                  v-model="form.tfacQuarter.startSellingCourseDateQ4"
                  type="date"
                  :readonly="readonly"
                  :state="validStartSellingQ4"
                  @change="validStartSellingQ4 = null; showWarningText4 = false"
                  @blur="onblurCourseInfoQuarterData('Q4')"
                  @keyup.enter="onblurCourseInfoQuarterData('Q4')"
                />
                <b-form-invalid-feedback :state="validStartSellingQ4">
                  {{ startSellingInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="enabledInputDate">
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่เริ่มหลักสูตร
                </template>
                <b-form-input
                  required
                  type="date"
                  v-model="form.tfacQuarter.startLearningDateQ4"
                  :state="validStartLearningQ4"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q4')"
                  @change="validStartLearningQ4 = null; showWarningText4 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q4')"
                />
                <b-form-invalid-feedback :state="validStartLearningQ4">
                  {{ startLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  วันที่จบหลักสูตร
                </template>
                <b-form-input
                  required
                  v-model="form.tfacQuarter.finishLearningDateQ4"
                  type="date"
                  :state="validFinishLearningQ4"
                  :readonly="readonly"
                  @blur="onblurCourseInfoQuarterData('Q4')"
                  @change="validFinishLearningQ4 = null; showWarningText4 = false"
                  @keyup.enter="onblurCourseInfoQuarterData('Q4')"
                />
                <b-form-invalid-feedback :state="validFinishLearningQ4">
                  {{ finishLearningInvalidFeedback }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="comId == 519">
        <b-col>
          <h6>
            <strong>ระยะเวลาเรียน</strong>
          </h6>
        </b-col>
      </b-row>
      <b-row v-if="comId == 519">
        <b-col cols="6">
          <b-form-group>
            <template v-slot:label>
              <b-form-checkbox v-model="hasLearnDuration" switch>
                จำนวนวันที่เข้าเรียน
              </b-form-checkbox>
            </template>
            <b-input-group append="วัน">
              <b-form-input
                v-model="form.learnDuration"
                placeholder=""
                :readonly="readonly"
                :state="validLearnDuration"
                :disabled="!hasLearnDuration"
                @blur="onblurCourseLearnDuration"
                @keyup.enter="onblurCourseLearnDuration"
                @keydown="onKeydownCourseLearnDuration"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import moment from "moment";

export default {
  name: "TfacPricingForm",
  filters: {
    countVat: function(value) {
      return value / 1.07;
    },

    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    },

    formatDate: function(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
  },
  props: {
    readonly: { type: Boolean, default: false },
    form: { default: null, type: Object },
  },
  data() {
    return {
      comId: localStorage.getItem("ComId"),
      minSelect: [
        { display: 0, value: 0 },
        { display: 15, value: 0.25 },
        { display: 30, value: 0.5 },
        { display: 45, value: 0.75 },
      ],
      validApproveDateQ1: null,
      validStartSellingQ1: null,
      validStartLearningQ1: null,
      validFinishLearningQ1: null,
      validCpdCourseCodeQ1: null,
      validApproveDateQ2: null,
      validStartSellingQ2: null,
      validStartLearningQ2: null,
      validFinishLearningQ2: null,
      validCpdCourseCodeQ2: null,
      validApproveDateQ3: null,
      validStartSellingQ3: null,
      validStartLearningQ3: null,
      validFinishLearningQ3: null,
      validCpdCourseCodeQ3: null,
      validApproveDateQ4: null,
      validStartSellingQ4: null,
      validStartLearningQ4: null,
      validFinishLearningQ4: null,
      validCpdCourseCodeQ4: null,
      validCpdCourseCode: null,
      approveDateInvalidFeedback: "",
      startSellingInvalidFeedback: "",
      startLearningInvalidFeedback: "",
      finishLearningInvalidFeedback: "",
      cpdCourseCodeInvalidFeedback: "",
      apiCpdCourseCodeLoading: false,
      enabledInputDate: false,
      hasLearnDuration: false,
      validLearnDuration: null,
      showWarningText1: true,
      showWarningText2: true,
      showWarningText3: true,
      showWarningText4: true,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
  },
  watch: {
    form(newValue) {
      this.form = newValue;
      this.hasLearnDuration = this.form.learnDuration ? true : false;
    },
  },
  async created() {
    this.enabledInputDate = this.comId == 513 ? true : false
  },
  methods: {
    ...mapActions("CourseManagement", [
      "updateTfacQuarter",
      "updateCourseInfo",
    ]),
    getCourseTempInfo() {
      this.$emit("getCourseTempInfo");
    },
    async onblurTfacQuarterCpdCourseCode(quarter) {
      if(!this.apiCpdCourseCodeLoading){
        if (quarter == 'Q1' || quarter == 'Q2' || quarter == 'Q3' || quarter == 'Q4') {
          this[`validCpdCourseCode${quarter}`] = null;
        }
        if (
          this.form.tfacQuarter[`cpdCourseCode${quarter}`] == this.courseInfo.tfacQuarter[`cpdCourseCode${quarter}`]
        ) {
          this[`validCpdCourseCode${quarter}`] = true;
        } else {
          try {
            this.apiCpdCourseCodeLoading = true;
            await this.updateTfacQuarter({
              courseId: this.$route.params.courseId,
              attribute: {
                [`cpdCourseCode${quarter}`]: this.form.tfacQuarter[`cpdCourseCode${quarter}`]
              },
            });
            await this.getCourseTempInfo();
            this[`validCpdCourseCode${quarter}`] = true;
            this.apiCpdCourseCodeLoading = false;
          } catch (error) {
            console.log(error);
            if (error == "course code already used") {
              this.cpdCourseCodeInvalidFeedback =
                "รหัสหลักสูตรต้องไม่ซ้ำกัน";
              this[`validCpdCourseCode${quarter}`] = false;
              this.apiCpdCourseCodeLoading = false;
            } else {
              this.cpdCourseCodeInvalidFeedback =
                "เกิดข้อผิดพลาดกกรุณาลองใหม่อีกครั้ง";
              this[`validCpdCourseCode${quarter}`] = false;
              this.apiCpdCourseCodeLoading = false;
            }
          }
        }
      }
    },
    // Assuming you have quarter as a number (1, 2, 3, or 4) and date as a string in 'YYYY-MM-DD' format
    checkDateWithinQuarter(quarter, date, approve = false) {
      const [year, month, day] = date.split('-').map(Number);
      const currentYear = new Date().getFullYear();
      const quarters = {
          1: { start: '01-01', end: '03-31' },
          2: { start: '04-01', end: '06-30' },
          3: { start: '07-01', end: '09-30' },
          4: { start: '10-01', end: '12-31' }
      };
      const [startMonth, startDay] = quarters[quarter].start.split('-').map(Number);
      const [endMonth, endDay] = quarters[quarter].end.split('-').map(Number);

      const quarterStart = new Date(`${currentYear}-${startMonth}-${startDay}`);
      const quarterEnd = new Date(`${currentYear}-${endMonth}-${endDay}`);

      let quarterPastStart = null;
      let quarterPastEnd = null;
      let quarterFutureStart = null;
      let quarterFutureEnd = null;

      const todayDate = new Date();
      if (todayDate > quarterEnd) {
        quarterPastStart = new Date(
          `${currentYear - 1}-${startMonth}-${startDay}`
        );
        quarterPastEnd = new Date(`${currentYear - 1}-${endMonth}-${endDay}`);
        quarterFutureStart = new Date(
          `${currentYear + 1}-${startMonth}-${startDay}`
        );
        quarterFutureEnd = new Date(`${currentYear + 1}-${endMonth}-${endDay}`);
      }

      const inputDate = new Date(`${year}-${month}-${day}`);
      return approve
        ? inputDate <= todayDate
        : todayDate > quarterEnd
        ? (inputDate >= quarterPastStart && inputDate <= quarterPastEnd) ||
          (inputDate >= quarterFutureStart && inputDate <= quarterFutureEnd) ||
          (inputDate >= quarterStart && inputDate <= quarterEnd)
        : inputDate >= quarterStart && inputDate <= quarterEnd;
    },

    async onblurCourseInfoQuarterData(quarter) {
      if (quarter == 'Q1' || quarter == 'Q2' || quarter == 'Q3' || quarter == 'Q4') {
        this[`validApproveDate${quarter}`] = null;
        this[`validStartSelling${quarter}`] = null;
        this[`validStartLearning${quarter}`] = null;
        this[`validFinishLearning${quarter}`] = null;
      }
      if (
        this.form.tfacQuarter[`startSellingCourseDate${quarter}`] == this.courseInfo.tfacQuarter[`startSellingCourseDate${quarter}`] &&
        this.form.tfacQuarter[`startLearningDate${quarter}`] == this.courseInfo.tfacQuarter[`startLearningDate${quarter}`] &&
        this.form.tfacQuarter[`finishLearningDate${quarter}`] == this.courseInfo.tfacQuarter[`finishLearningDate${quarter}`] &&
        this.form.tfacQuarter[`approveDate${quarter}`] == this.courseInfo.tfacQuarter[`approveDate${quarter}`]
      ) {
        this[`validApproveDate${quarter}`] = true;
        this[`validStartSelling${quarter}`] = true;
        this[`validStartLearning${quarter}`] = true;
        this[`validFinishLearning${quarter}`] = true;
      } else if (
        this.form.tfacQuarter[`startSellingCourseDate${quarter}`] &&
        this.form.tfacQuarter[`approveDate${quarter}`] &&
        this.form.tfacQuarter[`approveDate${quarter}`] >= this.form.tfacQuarter[`startSellingCourseDate${quarter}`]
      ) {
        this[`validStartSelling${quarter}`] = false;
        this.startSellingInvalidFeedback = "วันที่เปิดขายหลักสูตรต้องเป็นวันหลังจาก วันที่อนุมัติหลักสูตร";
      } else if (
        this.form.tfacQuarter[`approveDate${quarter}`] &&
        (
          (
            quarter == 'Q1' && !this.checkDateWithinQuarter(1,this.form.tfacQuarter[`approveDate${quarter}`], true)
          ) ||
          (
            quarter == 'Q2' && !this.checkDateWithinQuarter(2,this.form.tfacQuarter[`approveDate${quarter}`], true)
          ) ||
          (
            quarter == 'Q3' && !this.checkDateWithinQuarter(3,this.form.tfacQuarter[`approveDate${quarter}`], true)
          ) ||
          (
            quarter == 'Q4' && !this.checkDateWithinQuarter(4,this.form.tfacQuarter[`approveDate${quarter}`], true)
          )
        )
      ) {
        this[`validApproveDate${quarter}`] = false;
        this.approveDateInvalidFeedback = "วันที่อนุมัติต้องไม่เกินวันที่ปัจจุบัน";
      } else if (
        this.form.tfacQuarter[`startSellingCourseDate${quarter}`] &&
        (
          (
            quarter == 'Q1' && !this.checkDateWithinQuarter(1,this.form.tfacQuarter[`startSellingCourseDate${quarter}`])
          ) ||
          (
            quarter == 'Q2' && !this.checkDateWithinQuarter(2,this.form.tfacQuarter[`startSellingCourseDate${quarter}`])
          ) ||
          (
            quarter == 'Q3' && !this.checkDateWithinQuarter(3,this.form.tfacQuarter[`startSellingCourseDate${quarter}`])
          ) ||
          (
            quarter == 'Q4' && !this.checkDateWithinQuarter(4,this.form.tfacQuarter[`startSellingCourseDate${quarter}`])
          )
        )
      ) {
        this[`validStartSelling${quarter}`] = false;
        this.startSellingInvalidFeedback = quarter == 'Q4' ? "วันที่ต้องอยู่ในไตรมาสที่ 4 (1 ตุลาคม - 31 ธันวาคม)" : quarter == 'Q3' ? "วันที่ต้องอยู่ในไตรมาสที่ 3 (1 กรกฎาคม - 30 กันยายน)" : quarter == 'Q2' ? "วันที่ต้องอยู่ในไตรมาสที่ 2 (1 เมษายน - 30 มิถุนายน)" : quarter == 'Q1' ? "วันที่ต้องอยู่ในไตรมาสที่ 1 (1 มกราคม - 31 มีนาคม)" : "";
      } else if (
        this.form.tfacQuarter[`startLearningDate${quarter}`] &&
        (
          (
            quarter == 'Q1' && !this.checkDateWithinQuarter(1,this.form.tfacQuarter[`startLearningDate${quarter}`])
          ) ||
          (
            quarter == 'Q2' && !this.checkDateWithinQuarter(2,this.form.tfacQuarter[`startLearningDate${quarter}`])
          ) ||
          (
            quarter == 'Q3' && !this.checkDateWithinQuarter(3,this.form.tfacQuarter[`startLearningDate${quarter}`])
          ) ||
          (
            quarter == 'Q4' && !this.checkDateWithinQuarter(4,this.form.tfacQuarter[`startLearningDate${quarter}`])
          )
        )
      ) {
        this[`validStartLearning${quarter}`] = false;
        this.startLearningInvalidFeedback = quarter == 'Q4' ? "วันที่ต้องอยู่ในไตรมาสที่ 4 (1 ตุลาคม - 31 ธันวาคม)" : quarter == 'Q3' ? "วันที่ต้องอยู่ในไตรมาสที่ 3 (1 กรกฎาคม - 30 กันยายน)" : quarter == 'Q2' ? "วันที่ต้องอยู่ในไตรมาสที่ 2 (1 เมษายน - 30 มิถุนายน)" : quarter == 'Q1' ? "วันที่ต้องอยู่ในไตรมาสที่ 1 (1 มกราคม - 31 มีนาคม)" : "";
      } else if (
        this.form.tfacQuarter[`startSellingCourseDate${quarter}`] &&
        this.form.tfacQuarter[`startLearningDate${quarter}`] &&
        this.form.tfacQuarter[`startLearningDate${quarter}`] < this.form.tfacQuarter[`startSellingCourseDate${quarter}`]
      ) {
        this[`validStartLearning${quarter}`] = false;
        this.startLearningInvalidFeedback = "วันที่เริ่มหลักสูตรต้องหลังจากวันที่เปิดขายหลักสูตร";
      } else if (
        this.form.tfacQuarter[`startLearningDate${quarter}`] &&
        this.form.tfacQuarter[`finishLearningDate${quarter}`] &&
        this.form.tfacQuarter[`startLearningDate${quarter}`] >= this.form.tfacQuarter[`finishLearningDate${quarter}`]
      ) {
        this[`validFinishLearning${quarter}`] = false;
        this.finishLearningInvalidFeedback = "วันที่จบหลักสูตรต้องมากกว่าวันที่เริ่มหลักสูตร";
      } else {
        try {
          await this.updateTfacQuarter({
            courseId: this.$route.params.courseId,
            attribute: {
              [`startSellingCourseDate${quarter}`]: this.form.tfacQuarter[`startSellingCourseDate${quarter}`],
              [`startLearningDate${quarter}`]: this.form.tfacQuarter[`startLearningDate${quarter}`],
              [`finishLearningDate${quarter}`]: this.form.tfacQuarter[`finishLearningDate${quarter}`],
              [`approveDate${quarter}`]: this.form.tfacQuarter[`approveDate${quarter}`]
            },
          });
          await this.getCourseTempInfo();
          this[`validApproveDate${quarter}`] = true;
          this[`validStartSelling${quarter}`] = true;
          this[`validStartLearning${quarter}`] = true;
          this[`validFinishLearning${quarter}`] = true;
        } catch (error) {
          if (error == "course code already used") {
            this.cpdCourseCodeInvalidFeedback =
              "รหัสหลักสูตรต้องไม่ซ้ำกัน";
            this[`validCpdCourseCode${quarter}`] = false;
          } else {
            this.cpdCourseCodeInvalidFeedback =
              "เกิดข้อผิดพลาดกกรุณาลองใหม่อีกครั้ง";
            this[`validCpdCourseCode${quarter}`] = false;
          }
        }
      }
    },
    async onblurCourseLearnDuration(){
      if(!this.hasLearnDuration){
        this.form.learnDuration = null;
      }
      await this.updateCourseInfo({
        courseId: this.$route.params.courseId,
        attribute: {
          learnDuration: this.form.learnDuration,
          learningPeriodInDay: this.form.learnDuration,
        },
      });
    },

    onKeydownCourseLearnDuration(event) {
      if (!(event.key === "Backspace" || event.key === "Delete" || event.key === "ArrowLeft" || event.key === "ArrowRight" || event.key === "Tab" || (event.key >= "0" && event.key <= "9"))) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style>
.quarter-filed {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 15px;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
}
</style>

<template>
  <b-card :class="$options.name">
    <h6 slot="header" class="mb-0">
      <i class="fas fa-times text-danger" aria-hidden="true" ></i> ข้อที่ตอบผิดมากที่สุด
    </h6>
    <template v-if="examStatPending || examInfoPending">
      <i class="fas fa-spinner fa-pulse" aria-hidden="true" ></i> กำลังโหลด
    </template>
    <template v-else>
      <b-container :class="`${$options.name}__body`" class="mb-3" fluid>
        <b-card-text>
          <template
            v-if="examStatData.mostIncorrectlyAnsweredQuestion.subQuestionTitle"
          >
            {{ examStatData.mostIncorrectlyAnsweredQuestion.subQuestionTitle }}
          </template>
          <template v-else>
            <Markdown
              :message="
                examStatData.mostIncorrectlyAnsweredQuestion.questionTitle
              "
            />
          </template>
        </b-card-text>
      </b-container>
      <em slot="footer"
        >ตอบผิด
        {{ examStatData.mostIncorrectlyAnsweredQuestion.incorrectCount }} ครั้ง
        คิดเป็น {{ incorrectPercentage }} ของจำนวนครั้งที่ทำ</em
      >
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Markdown from "@/components/shared/Markdown.vue";

export default {
  name: "ExamMostCorrectQuestion",
  computed: {
    ...mapGetters("Company", [
      "examStatPending",
      "examStatData",
      "examInfoPending",
      "examInfoData"
    ]),
    incorrectPercentage() {
      let correctCount = this.examStatData.mostIncorrectlyAnsweredQuestion
        .correctCount;
      let incorrectCount = this.examStatData.mostIncorrectlyAnsweredQuestion
        .incorrectCount;
      let blankCount = this.examStatData.mostIncorrectlyAnsweredQuestion
        .blankCount;
      let answerCount = correctCount + incorrectCount + blankCount;
      let percentage = (incorrectCount / answerCount) * 100;
      return `${percentage.toFixed(2)} %`;
    }
  },
  components: {
    Markdown
  }
};
</script>

<style lang="scss" scoped>
.ExamMostCorrectQuestion {
  &__body {
    padding-top: 1.3em;
    height: 119px;
  }
}
.card-footer {
  background-color: #fff;
  color: #747373;
  border: 0;
  text-align: end;
}
.card {
  margin-bottom: 1em;
}
</style>

import { render, staticRenderFns } from "./CourseLectureViewPage.vue?vue&type=template&id=143600f9&scoped=true&"
import script from "./CourseLectureViewPage.vue?vue&type=script&lang=js&"
export * from "./CourseLectureViewPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143600f9",
  null
  
)

export default component.exports
<template>
  <b-card
    :class="$options.name"
    class="text-center time-take-exam"
    title="จำนวนการทำข้อสอบ"
  >
    <b-container :class="`${$options.name}__body`" fluid>
      <template v-if="examStatPending">
        <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> กำลังโหลด
      </template>
      <template v-else>
        <br />
        <b-card-text>
          <h1>{{ examStatData.answerSheetCount }}</h1>
        </b-card-text>
      </template>
    </b-container>
    <!-- <em slot="footer">อัพเดทล่าสุดวันที่ 12 ธันวา 2560 [วันที่อัพเดท]</em> -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExamTakerInfo",
  computed: {
    ...mapGetters("Company", ["examStatPending", "examStatData"])
  }
};
</script>

<style lang="scss" scoped>
.ExamTakerInfo {
  &__body {
    padding: 0;
    height: 175px;
  }
}
.time-take-exam {
  padding-top: 2em;
  padding-bottom: 0;
  h1 {
    font-size: 5em;
  }
  em {
    color: #b2b2b2;
  }
}
.card-footer {
  background-color: #fff;
  border: 0;
  text-align: end;
}
</style>

import async from "@/config/store";

import * as legacy from "./legacy/getters";
import map from "lodash/map";

export default {
  ...legacy,
  companyInfo: state => {
    return state.companyInfo;
  },
  [async.ASYNC_EXAMS_COUNT.loadingKey]: state => {
    return state[async.ASYNC_EXAMS_COUNT.loadingKey];
  },
  [async.ASYNC_EXAMS_COUNT.errorKey]: state => {
    return state[async.ASYNC_EXAMS_COUNT.errorKey];
  },
  [async.ASYNC_EXAMS_COUNT.stateKey]: state => {
    if (!state[async.ASYNC_EXAMS_COUNT.stateKey]) return 0;
    return state[async.ASYNC_EXAMS_COUNT.stateKey].count;
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.loadingKey]: state => {
    return state[async.ASYNC_ANSWERSHEETS_COUNT.loadingKey];
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.errorKey]: state => {
    return state[async.ASYNC_ANSWERSHEETS_COUNT.errorKey];
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.stateKey]: state => {
    if (!state[async.ASYNC_ANSWERSHEETS_COUNT.stateKey]) return 0;
    return state[async.ASYNC_ANSWERSHEETS_COUNT.stateKey].count;
  },
  [async.ASYNC_COMPANY_COURSES.loadingKey]: state => {
    return state[async.ASYNC_COMPANY_COURSES.loadingKey];
  },
  [async.ASYNC_COMPANY_COURSES.errorKey]: state => {
    return state[async.ASYNC_COMPANY_COURSES.errorKey];
  },
  [async.ASYNC_COMPANY_COURSES.stateKey]: state => {
    if (!state[async.ASYNC_COMPANY_COURSES.stateKey]) return null;
    return state[async.ASYNC_COMPANY_COURSES.stateKey];
  },
  [async.ASYNC_COMPANY_MODULES.loadingKey]: state => {
    return state[async.ASYNC_COMPANY_MODULES.loadingKey];
  },
  [async.ASYNC_COMPANY_MODULES.errorKey]: state => {
    return state[async.ASYNC_COMPANY_MODULES.errorKey];
  },
  [async.ASYNC_COMPANY_MODULES.stateKey]: state => {
    if (!state[async.ASYNC_COMPANY_MODULES.stateKey]) return null;
    return map(state[async.ASYNC_COMPANY_MODULES.stateKey], ech => {
      const {
        mod_id,
        c_id,
        mod_name,
        mod_order,
        mod_enableFlag,
        mod_status,
        mod_auto_naming_lecture
      } = ech;
      return {
        id: mod_id,
        cid: c_id,
        name: mod_name,
        order: mod_order,
        enableFlag: mod_enableFlag,
        status: mod_status,
        autoNamingLecture: mod_auto_naming_lecture
      };
    });
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.loadingKey]: state => {
    return state[async.ASYNC_COMPANY_INSTRUCTORS.loadingKey];
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.errorKey]: state => {
    return state[async.ASYNC_COMPANY_INSTRUCTORS.errorKey];
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.stateKey]: state => {
    if (!state[async.ASYNC_COMPANY_INSTRUCTORS.stateKey]) return null;
    return state[async.ASYNC_COMPANY_INSTRUCTORS.stateKey];
  }
};

import api from '@/services/api';
import moment from 'moment';

const mapInstructorToLive = (meeting, instructors) => {
  let teacher = instructors.filter((inst) => inst.ownerId == meeting.m_id);
  if (teacher == null) {
    console.warn(
      'Create zoom live: instructor is not matched with M ID => ',
      meeting.m_id
    );
    teacher = {
      ownerName: '....... [ ' + meeting.m_id + ' ] .......',
      ownerId: meeting.m_id,
    };
  }
  meeting.instructor = teacher[0];
};

const state = {
  planMeetings: [],
  endedMeetings: [],
  isBusy: false,
};

const getters = {
  getPlanList: (state) => {
    return state.planMeetings;
  },

  getEndedList: (state) => {
    return state.endedMeetings;
  },

  getIsBusy: (state) => {
    return state.isBusy;
  },
};

const actions = {
  init() {},
  async fetchMeetings({ state, commit, dispatch, rootGetters }) {
    commit('setIsBusy', true);
    // if (rootGetters['Company/companyInfo'] != null) {
    await api
      .get(`/v1/live/meetings`)
      .then((res) => {
        let publish = res.data.data.publishMeetings;
        let end = res.data.data.endedMeetings;

        commit('initLive', { publish, end });
      })
      .catch((err) => {
        console.error(err);
        if (err.response != undefined) {
          console.error(err.response.data.errors);
          // vm.$bvToast.toast(err.response.data.message, {
          //   title: "ไม่สามารถสร้าง Live ได้",
          //   variant: "danger",
          //   solid: true
          // });
        }
      });

    await dispatch(
      'Company/fetchCompanyInstructors',
      rootGetters['Company/companyInfo'].id,
      { root: true }
    )
      .then(() => {
        let instructors = rootGetters['Company/companyInstructorsData'].data;
        state.planMeetings.forEach((meeting) => {
          let teacher = instructors.find(
            (inst) => inst.ownerId == meeting.m_id
          );
          if (teacher == null) {
            console.warn(
              'Fetch: instructor is not matched with M ID => ',
              meeting.m_id
            );
            teacher = [
              {
                ownerName: '....... [ ' + meeting.m_id + ' ] .......',
                ownerId: meeting.m_id,
              },
            ];
          }

          let courseName = 'TBA';
          let instructor = {
            ownerId: teacher.ownerId,
            ownerName: teacher.ownerName,
          };
          commit('attachAdditionalData', { meeting, instructor, courseName });
        });

        state.endedMeetings.forEach((meeting) => {
          let teacher = instructors.find(
            (inst) => inst.ownerId == meeting.m_id
          );
          if (teacher == null) {
            console.warn(
              'Fetch: instructor is not matched with M ID => ',
              meeting.m_id
            );
            teacher = [
              {
                ownerName: '....... [ ' + meeting.m_id + ' ] .......',
                ownerId: meeting.m_id,
              },
            ];
          }

          let courseName = 'TBA';
          let instructor = {
            ownerId: teacher.ownerId,
            ownerName: teacher.ownerName,
          };
          commit('attachAdditionalData', { meeting, instructor, courseName });
        });
      })
      .catch((e) => {
        console.log('[Live] Error while fetchingInstructors', e);
      });
    // }
    commit('setIsBusy', false);
  },

  async submitZoomCreateForm(
    { commit, dispatch, rootGetters },
    { vm, isDraft }
  ) {
    commit('setIsBusy', true);

    let reqJson = {
      topic: rootGetters['LiveForm/getTopic'],
      start_datetime: `${rootGetters['LiveForm/getStartDate']} ${rootGetters['LiveForm/getStartTime']}`,
      end_datetime: `${rootGetters['LiveForm/getEndDate']} ${rootGetters['LiveForm/getEndTime']}`,
      type: 'ZOOM',
      // TODO: custom pwd
      m_id: rootGetters['LiveForm/getInstructor'],
      c_id: rootGetters['LiveForm/getCourseID'],
      description: '',
      thumbnail_path: '',
      password: '*QWERTY01',
      live_scope: rootGetters['LiveForm/getLiveScope'],
      calendar: rootGetters['LiveForm/getCalendar'],
      settings: {
        host_video: rootGetters['LiveForm/getIsInstructorCam'],
        participant_video: rootGetters['LiveForm/getIsStudentCam'],
        cloud_rec: rootGetters['LiveForm/getRecord'],
        waiting_room: '1',
      },
    };

    dispatch('LiveForm/resetModified', {}, { root: true });

    let draft = isDraft ? 1 : 0;
    await api
      .post(`/v1/live/meetings?draft=${draft}`, reqJson)
      .then((payload) => {
        var meeting = { ...payload.data.data };
        meeting.start_datetime = moment(meeting.start_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        meeting.end_datetime = moment(meeting.end_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        let instructors = rootGetters['Company/companyInstructorsData'].data;

        mapInstructorToLive(meeting, instructors);

        meeting.courseName = 'TBA';

        commit('addToPlanMeeting', { meeting });

        vm.$bvToast.toast(
          `สร้าง Live หัวข้อ "${reqJson.topic}" เรียบร้อยแล้ว`,
          {
            title: 'สร้าง Live สำเร็จ',
            variant: 'success',
            solid: true,
          }
        );
      })
      .catch((e) => {
        console.error('Error while create zoom meeting => ' + e);
        if (e.response != undefined) {
          console.error(e.response.data.errors);
          vm.$bvToast.toast(e.response.data.message, {
            title: 'ไม่สามารถสร้าง Live ได้',
            variant: 'danger',
            solid: true,
          });
        }
      });
    commit('setIsBusy', false);
  },

  async submitZoomUrlCreateForm(
    { commit, dispatch, rootGetters },
    { vm, isDraft }
  ) {
    commit('setIsBusy', true);
    
    let reqJson = {
      topic: rootGetters['LiveForm/getTopic'],
      start_datetime: `${rootGetters['LiveForm/getStartDate']} ${rootGetters['LiveForm/getStartTime']}`,
      end_datetime: `${rootGetters['LiveForm/getEndDate']} ${rootGetters['LiveForm/getEndTime']}`,
      type: 'ZOOM-URL',
      m_id: rootGetters['LiveForm/getInstructor'],
      c_id: rootGetters['LiveForm/getCourseID'],
      live_scope: rootGetters['LiveForm/getLiveScope'],
      url: rootGetters['LiveForm/getMeetingURL'],
      meeting_id: rootGetters['LiveForm/getMeetingId'],
      meeting_password: rootGetters['LiveForm/getMeetingPassword']
    };

    dispatch('LiveForm/resetModified', {}, { root: true });

    let draft = isDraft ? 1 : 0;
    await api
      .post(`/v1/live/meetings?draft=${draft}`, reqJson)
      .then((payload) => {
        console.log(payload ? "complete" : "false");
        var meeting = { ...payload.data.data };
        meeting.start_datetime = moment(meeting.start_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        meeting.end_datetime = moment(meeting.end_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        var instructors = rootGetters['Company/companyInstructorsData'].data;

        mapInstructorToLive(meeting, instructors);

        meeting.courseName = 'TBA';

        commit('addToPlanMeeting', { meeting });

        vm.$bvToast.toast(
          `เพิ่ม Live จาก Meeting URL\nหัวข้อ "${reqJson.topic}"`,
          {
            title: 'สร้าง Live สำเร็จ',
            variant: 'success',
            solid: true,
          }
        );

        commit('setIsBusy', false);
      })
      .catch((e) => {
        console.error('Error while 3rd create meeting => ' + e);
        if (e.response != undefined) {
          console.error(e.response.data.errors);

          vm.$bvToast.toast(e.response.data.message, {
            title: 'ไม่สามารถสร้าง Live ได้',
            variant: 'danger',
            solid: true,
          });
        }
        commit('setIsBusy', false);
      });
  },

  // Submit 3rd party form
  async submitOtherCreateForm(
    { commit, dispatch, rootGetters },
    { vm, isDraft }
  ) {
    commit('setIsBusy', true);

    let reqJson = {
      topic: rootGetters['LiveForm/getTopic'],
      start_datetime: `${rootGetters['LiveForm/getStartDate']} ${rootGetters['LiveForm/getStartTime']}`,
      end_datetime: `${rootGetters['LiveForm/getEndDate']} ${rootGetters['LiveForm/getEndTime']}`,
      type: 'OTHER',
      m_id: rootGetters['LiveForm/getInstructor'],
      c_id: rootGetters['LiveForm/getCourseID'],
      live_scope: rootGetters['LiveForm/getLiveScope'],
      url: rootGetters['LiveForm/getThirdURL'],
    };

    dispatch('LiveForm/resetModified', {}, { root: true });

    let draft = isDraft ? 1 : 0;
    await api
      .post(`/v1/live/meetings?draft=${draft}`, reqJson)
      .then((payload) => {
        var meeting = { ...payload.data.data };
        meeting.start_datetime = moment(meeting.start_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        meeting.end_datetime = moment(meeting.end_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        var instructors = rootGetters['Company/companyInstructorsData'].data;

        mapInstructorToLive(meeting, instructors);

        meeting.courseName = 'TBA';
        // For dubugging
        // console.log("3rd Meeting: ", meeting)

        commit('addToPlanMeeting', { meeting });

        vm.$bvToast.toast(
          `เพิ่ม Live จาก Third Party\nหัวข้อ "${reqJson.topic}"`,
          {
            title: 'สร้าง Live สำเร็จ',
            variant: 'success',
            solid: true,
          }
        );

        commit('setIsBusy', false);
      })
      .catch((e) => {
        console.error('Error while 3rd create meeting => ' + e);
        if (e.response != undefined) {
          console.error(e.response.data.errors);

          vm.$bvToast.toast(e.response.data.message, {
            title: 'ไม่สามารถสร้าง Live ได้',
            variant: 'danger',
            solid: true,
          });
        }
        commit('setIsBusy', false);
      });
  },

  async submitEditForm(
    { commit, dispatch, rootGetters },
    { vm, isDraft, live, liveID }
  ) {
    commit('setIsBusy', true);
    dispatch('LiveForm/resetModified', {}, { root: true });

    let reqJson = {
      topic: rootGetters['LiveForm/getTopic'],
      start_datetime: `${rootGetters['LiveForm/getStartDate']} ${rootGetters['LiveForm/getStartTime']}`,
      end_datetime: `${rootGetters['LiveForm/getEndDate']} ${rootGetters['LiveForm/getEndTime']}`,
      type: live.type,
      m_id: rootGetters['LiveForm/getInstructor'],
      c_id: rootGetters['LiveForm/getCourseID'],
      description: '',
      thumbnail_path: '',
      password: rootGetters['LiveForm/getMeetingPassword'], // TODO: custom pwd
      meeting_id: rootGetters['LiveForm/getMeetingId'],
      live_scope: rootGetters['LiveForm/getLiveScope'],
      live_status: live.status,
      calendar: rootGetters['LiveForm/getCalendar'],
      settings: {
        host_video: rootGetters['LiveForm/getIsInstructorCam'],
        participant_video: rootGetters['LiveForm/getIsStudentCam'],
        cloud_rec: rootGetters['LiveForm/getRecord'],
        waiting_room: '1',
      },
      
      url: live.type == 'OTHER' ? rootGetters['LiveForm/getThirdURL'] : rootGetters['LiveForm/getMeetingURL'],
    };

    let draft = isDraft ? 1 : 0;
    await api
      .put(`/v1/live/meetings/${liveID}?draft=${draft}`, reqJson)
      .then((payload) => {
        console.log('Edit payload', payload.data);
        let meeting = null;
        if (payload.data === 'OK') {
          const { ...req } = reqJson;
          meeting = {
            ...req,
            live_id: liveID,
          };
          meeting.start_datetime = moment(meeting.start_datetime).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          meeting.end_datetime = moment(meeting.end_datetime).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          meeting.live_status = isDraft ? 'DRAFT' : 'PUBLISH';

          const instructors =
            rootGetters['Company/companyInstructorsData'].data;
          mapInstructorToLive(meeting, instructors);

          meeting.courseName = 'TBA';

          commit('editPlanMeeting', meeting);
          vm.$bvToast.toast(
            `อัพเดทข้อมูลของ Live หัวข้อ "${reqJson.topic}" แล้ว`,
            {
              title: 'แก้ไข Live สำเร็จ',
              variant: 'success',
              solid: true,
            }
          );
        } else {
          // Zoom: Draft -> Publish
          meeting = {
            ...payload.data.data,
          };
          meeting.start_datetime = moment(meeting.start_datetime).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          meeting.end_datetime = moment(meeting.end_datetime).format(
            'YYYY-MM-DD HH:mm:ss'
          );

          const instructors =
            rootGetters['Company/companyInstructorsData'].data;
          mapInstructorToLive(meeting, instructors);

          meeting.courseName = 'TBA';

          commit('removeFromPlanMeetingList', liveID);
          commit('addToPlanMeeting', { meeting });
        }
      })
      .catch((e) => {
        console.error('Error while edit meeting => ' + e);

        if (e.response != undefined) {
          console.error(e.response.data.errors);
          vm.$bvToast.toast(e.response.data.message, {
            title: 'ไม่สามารถแก้ไข Live ได้',
            variant: 'danger',
            solid: true,
          });
        }
      });
    commit('setIsBusy', false);
  },

  async submitDeleteMeeting({ commit }, { vm, meeting }) {
    commit('setIsBusy', true);

    await api
      .delete(`/v1/live/meetings/${meeting.live_id}`, {
        live_status: meeting.status,
        type: meeting.type,
        soft_del: '0',
      })
      .then(() => {
        commit('removeFromPlanMeetingList', meeting.live_id);
        vm.$bvToast.toast(`ลบ Live หัวข้อ "${meeting.topic}" แล้ว`, {
          title: 'ลบ Live สำเร็จ',
          variant: 'success',
          solid: true,
        });
        commit('setIsBusy', false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          vm.$bvToast.toast(`Live: "${meeting.topic}" ได้ถูกลบไปแล้ว`, {
            title: 'Bad Delete Request',
            variant: 'warning',
            solid: true,
          });
        } else {
          console.error('Error while delete meeting => ' + e);
          if (e.response != undefined) {
            console.error(e.response.data.errors);
            vm.$bvToast.toast(e.response.data.message, {
              title: 'ไม่สามารถลบ Live ได้',
              variant: 'danger',
              solid: true,
            });
          }
        }
        commit('setIsBusy', false);
      });
  },
};

const mutations = {
  initLive(state, { publish, end }) {
    state.planMeetings = publish;
    state.endedMeetings = end;
  },

  attachAdditionalData(state, { meeting, instructor, courseName }) {
    meeting.instructor = instructor;
    meeting.courseName = courseName;
  },

  addToPlanMeeting(state, { meeting }) {
    state.planMeetings.push(meeting);
  },

  addToEndedMeeting(state, { payload }) {
    state.endedMeetings.push(payload);
  },

  editPlanMeeting(state, meeting) {
    let i = state.planMeetings.findIndex(
      (m) => m.meeting_id === meeting.meeting_id
    );
    if (i >= 0) {
      state.planMeetings[i].topic = meeting.topic;
      state.planMeetings[i].start_datetime = meeting.start_datetime;
      state.planMeetings[i].end_datetime = meeting.end_datetime;
      state.planMeetings[i].description = meeting.description;
      state.planMeetings[i].thumbnailPath = meeting.thumbnailPath;
      state.planMeetings[i].scope = meeting.live_scope;
      state.planMeetings[i].status = meeting.live_status;
      state.planMeetings[i].instructor = meeting.instructor;
      state.planMeetings[i].settings = meeting.settings;
      state.planMeetings[i].join_url = meeting.url;
      state.planMeetings[i].calendar = meeting.calendar;
      state.planMeetings[i].record = meeting.record;
    } else {
      console.error(
        'No meeting with ID: ' + meeting.meeting_id + ' in Plan meeting'
      );
    }
  },

  removeFromPlanMeetingList(state, liveID) {
    let i = state.planMeetings
      .map(function(elem) {
        return elem.live_id;
      })
      .indexOf(liveID);
    state.planMeetings.splice(i, 1);

    let j = state.endedMeetings
      .map(function(elem) {
        return elem.live_id;
      })
      .indexOf(liveID);
    state.endedMeetings.splice(j, 1);
  },

  setIsBusy(state, payload) {
    state.isBusy = payload;
  },
};

export default { namespaced: true, state, getters, actions, mutations };

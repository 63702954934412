import async from "@/config/store";

export default {
  [async.ASYNC_UPLOADED_CSV.loadingKey]: state => {
    return state[async.ASYNC_UPLOADED_CSV.loadingKey];
  },
  [async.ASYNC_UPLOADED_CSV.errorKey]: state => {
    return state[async.ASYNC_UPLOADED_CSV.errorKey];
  },
  [async.ASYNC_UPLOADED_CSV.stateKey]: state => {
    if (!state[async.ASYNC_UPLOADED_CSV.stateKey]) return null;
    return state[async.ASYNC_UPLOADED_CSV.stateKey];
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey]: state => {
    return state[async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey];
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey]: state => {
    return state[async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey];
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey]: state => {
    if (!state[async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey]) return null;
    const { lec_id, lec_name, lec_status, lec_type } = state[
      async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey
    ];
    return {
      id: lec_id,
      name: lec_name,
      status: lec_status,
      type: lec_type
    };
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey]: state => {
    return state[async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey];
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey]: state => {
    return state[async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey];
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey]: state => {
    if (!state[async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey]) return null;
    return state[async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey];
  }
};

import Vue from 'vue';
import moment from 'moment';
Vue.filter('cut', function(value, type) {
  if (value.length > 30 && type <= 10)
    return value.substr(0, 12) + '...' + value.substr(-12);
  else return value;
});

Vue.filter('thaiDate', function(value) {
  if (value) {
    moment.updateLocale('th', {});
    let year = parseInt(moment(String(value)).format('YYYY'));
    year += 543;
    return moment(String(value)).format(`D MMM ${year}`);
  }
});

Vue.filter('expireThaiDate', function(value) {
  if (value) {
    moment.updateLocale('th', {});
    let year = parseInt(moment(String(value)).format('YYYY'));
    year += 543;
    return moment(String(value)).format(`D MMM ${year}`);
  }
  return 'ไม่มีวันหมดอายุ';
});

Vue.filter('roundDecimal', function(value) {
  if (value) {
    if (
      value >= 1000 &&
      value / 1000 - Math.round(Number((value / 1000).toFixed(2))) == 0
    )
      return (value / 1000).toString() + ' K';
    else if (
      value >= 1000 &&
      value / 1000 - Number((value / 1000).toFixed(1)) == 0
    )
      return (value / 1000).toFixed(1) + ' K';
    else if (value >= 1000) return (value / 1000).toFixed(2) + ' K';
    else return value.toFixed(2);
  }
  return 'ไม่มีข้อมูล';
});

Vue.filter('thaiBaht', function(value) {
  if (value) {
    return '฿ ' + Number(value).toLocaleString();
  }
  return 'ไม่มีข้อมูล';
});

<template>
  <b-card :class="$options.name">
    <h6 slot="header" class="mb-0">
      <i class="fas fa-check text-success" aria-hidden="true"></i> ข้อที่ตอบถูกมากที่สุด
    </h6>
    <template v-if="examStatPending || examInfoPending">
      <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> กำลังโหลด
    </template>
    <template v-else>
      <b-container :class="`${$options.name}__body`" class="mb-3" fluid>
        <b-card-text class="text-left">
          <template
            v-if="examStatData.mostCorrectlyAnsweredQuestion.subQuestionTitle"
          >
            {{ examStatData.mostCorrectlyAnsweredQuestion.subQuestionTitle }}
          </template>
          <template v-else>
            <Markdown
              :message="
                examStatData.mostCorrectlyAnsweredQuestion.questionTitle
              "
            />
          </template>
        </b-card-text>
      </b-container>
      <em slot="footer"
        >ตอบถูก
        {{ examStatData.mostCorrectlyAnsweredQuestion.correctCount }} ครั้ง
        คิดเป็น {{ correctPercentage }} ของจำนวนครั้งที่ทำ</em
      >
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Markdown from "@/components/shared/Markdown.vue";

export default {
  name: "ExamMostCorrectQuestion",
  computed: {
    ...mapGetters("Company", [
      "examStatPending",
      "examStatData",
      "examInfoPending",
      "examInfoData"
    ]),
    correctPercentage() {
      let correctCount = this.examStatData.mostCorrectlyAnsweredQuestion
        .correctCount;
      let incorrectCount = this.examStatData.mostCorrectlyAnsweredQuestion
        .incorrectCount;
      let blankCount = this.examStatData.mostCorrectlyAnsweredQuestion
        .blankCount;
      let answerCount = correctCount + incorrectCount + blankCount;
      let percentage = (correctCount / answerCount) * 100;
      return `${percentage.toFixed(2)} %`;
    }
  },
  components: {
    Markdown
  }
};
</script>

<style lang="scss" scoped>
.ExamMostCorrectQuestion {
  &__body {
    padding-top: 1.5em;
    height: 120px;
  }
}
.card-footer {
  background-color: #fff;
  color: #747373;
  border: 0;
  text-align: end;
}
.card {
  margin-bottom: 1em;
}
</style>

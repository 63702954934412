<template>
  <div>
    <div class="mb-3">
      <b-row>
        <b-col cols="4">
          <b-input-group>
            <b-form-input
              v-model="q"
              placeholder="ค้นหากลุ่มส่วนลด"
              style="padding: 1rem; display: inline-block; width: 30%"
              @keyup.enter="sendKeyword"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="sendKeyword">ค้นหา</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="8" class="text-right">
          <b-button
            @click="modalPromotionShow((ccd_id = null))"
            class="create-new-link-button"
            variant="primary"
            :to="{ name: 'purchase.promotion-group.add' }"
          >
            <i class="fa fa-plus-square" aria-hidden="true"></i>
            สร้างโปรโมชั่นใหม่
          </b-button>
        </b-col>
      </b-row>
    </div>

    <template v-if="isLoading">
      <div class="text-center mt-5 text-csq">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
        <p>Loading...</p>
      </div>
    </template>
    <div style="text-align: center" v-else-if="listResult.length == 0">
      <p class="mt-5 promotion-on-info">{{ "ไม่มีโปรโมชั่นกลุ่มส่วนลด" }}</p>
    </div>
    <b-table
      v-else
      id="promotion-group-list-table"
      hover
      :fields="fields"
      :items="listResult"
    >
      <template v-slot:cell(tcd_discountType)="data">
        <template>
          <span>
            {{
              data.item.tcd_discountType === "FIX"
                ? comId == 519 ? data.item.tcd_discountValue/1.07 : data.item.tcd_discountValue
                : data.item.tcd_discountPercent | formatNumber
            }}
            {{ data.item.tcd_discountType === "FIX" ? " บาท" : " %" }}
          </span>
        </template>
      </template>

      <template v-slot:cell(tcd_startDate)="data">
        <template>
          <span>{{ data.item.tcd_startDate | thaiDateFilter }}</span></template
        >
      </template>
      <template v-slot:cell(tcd_endDate)="data">
        <template>
          <span>{{ data.item.tcd_endDate | thaiDateFilter }}</span></template
        >
      </template>
      <template v-slot:cell(option)="data">
        <div>
          <b-button
            variant="outline-primary"
            size="sm"
            class="mr-1"
            :to="{ path: 'promotion-group/' + data.item.tcd_id + '/edit' }"
          >
            แก้ไขโปรโมชั่น
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="confirmRemovePromotion(data.item.tcd_id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row class="mt-4">
      <b-col cols="6 ">
        <div class="d-flex justify-content-start align-items-center">
          <p class="pr-3">แสดง</p>
          <b-form-select
            v-model="selectedShow"
            style="width: 75px"
            @change="changeShowData"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </b-form-select>
          <p class="pl-3">จาก {{ rows }} รายการ</p>
        </div>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="promotion-group-list-table"
          align="right"
          last-number
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import Numeral from "numeral";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  mixins: [dateMixins],
  mounted() {
    this.fetchPromotionList();
  },
  filters: {
    formatNumber: function (value) {
      if (!isNaN(value)) {
        return Numeral(value).format("0,0.00");
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      q: "",
      comId: this.$store.state.Company.companyInfo.id,
      fields: [
        { key: "tcd_name", label: "กลุ่มส่วนลด", thStyle: "width: 250px" },
        { key: "tcd_minimumCourse", label: "จำนวนคอร์ส" },
        { key: "tcd_discountType", label: "ส่วนลด" },
        { key: "tcd_startDate", label: "วันที่เริ่มโปรโมชั่น" },
        { key: "tcd_endDate", label: "วันที่สิ้นสุดโปรโมชั่น" },
        { key: "option", label: "" },
      ],
      items: [],
      ccd_id: null,
      ModalShowPromotion: false,
      coursePromotionsDetails: [],
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
    };
  },

  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "coursePromotions",
      "coursePromotionsGroup",
    ]),

    rows() {
      return this.coursePromotionsGroup.length;
    },

    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      var showData = this.coursePromotionsGroup.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      );
      return showData;
    },
  },
  created() {},
  methods: {
    ...mapActions("CourseManagement", [
      "getPromotionsGroup",
      "ondeletePromotionGroupTag",
    ]),
    async fetchPromotionList() {
      await this.getPromotionsGroup(null).then({});
    },

    hideModalPromotion() {
      this.$refs["modal-promotion"].hide();
    },

    changeShowData() {
      this.perPage = this.selectedShow;
    },

    modalPromotionShow(ccd_id) {
      this.ModalShowPromotion = true;
      this.ccd_id = ccd_id;
    },

    async sendKeyword() {
      if (this.q) {
        await this.getPromotionsGroup(this.q).then({});
      } else {
        await this.getPromotionsGroup(null).then({});
      }
    },

    async confirmRemovePromotion(tcd_id) {
      this.$bvModal
        .msgBoxConfirm(`คุณต้องการลบกลุ่มส่วนลดหรือไม่ ?`, {
          title: "แจ้งเตือนการลบกลุ่มส่วนลด",
          size: "ml",
          buttonSize: "sm px-3",
          okVariant: "primary",
          okTitle: "ลบกลุ่มส่วนลด",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-primary",
          footerClass: "p-2 msgbox-footer",
          hideHeaderClose: false,
          centered: true,
          bodyClass: "msgbox-content",
          headerBgVariant: "danger",
        })
        .then((confirm) => {
          if (confirm) {
            this.ondeletePromotionGroupTag(tcd_id).then(() => {
              this.getPromotionsGroup(null);
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.promotion-on-info {
  font-size: 30px;
  font-weight: 500;
}
</style>
<template>
  <div>
    <div>
      <span>คอร์สที่มี หมวดหมู่คอร์ส นี้</span>
    </div>
    <div
      style="height : auto;border : gray solid 1px; border-radius : 5px 5px 5px 5px;"
      class="p-3 my-2"
    >
      <div v-for="t in tagged" v-bind:key="t.c_id" class="my-2">
        <span>{{ t.c_name }} </span>
      </div>
    </div>
    <span>พิมพ์ชื่อ หมวดหมู่คอร์ส ใหม่</span>
    <b-form-input v-model="newTag" style="padding:1rem" class="mt-2">
    </b-form-input>
    <div
      class="mt-2"
      v-if="
        newTag &&
          tag.filter((i) => i.tagName.toLowerCase() == newTag.toLowerCase())
            .length > 0
      "
    >
      <span style="color :red">หมวดหมู่คอร์ส นี้มีอยู่ในระบบแล้ว</span>
    </div>
    <div style="float:right">
      <b-button variant="secondary" class="mt-2 m-1 p-2" @click="closeModal()"
        >ยกเลิก</b-button
      >
      <b-button
        variant="primary"
        class="mt-2 m-1 p-2"
        @click="submit()"
        :disabled="
          newTag &&
            tag.filter((i) => i.tagName.toLowerCase() == newTag.toLowerCase())
              .length > 0
        "
        >แก้ไข หมวดหมู่คอร์ส</b-button
      >
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js';
export default {
  props: ['tag', 'id', 'course'],
  data() {
    return {
      newTag: null,
      tagged: [],
      result: {},
      apiRoute: `/v1/tags/update-tag`,
      errorText: null,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('update-tag-' + this.id);
    },
    async submit() {
      let data = {
        tag_id: this.id,
        tag_name: this.newTag,
      };
      await api
        .post(this.apiRoute, data)
        .then(() => {
          this.$bvToast.toast(`แก้ไข หมวดหมู่คอร์ส ${this.newTag} สำเร็จแล้ว`, {
            title: `สำเร็จแล้ว`,
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
          setTimeout(() => {
            this.$emit('edited');
            this.$bvModal.hide('update-tag-' + this.id);
          }, 500);
        })
        .catch(() => {
          this.$bvToast.toast(`แก้ไข หมวดหมู่คอร์ส ${this.newTag} ล้มเหลว`, {
            title: `เกิดข้อผิดพลาด`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
        });
    },
  },
  async mounted() {
    var temp = this.tag.filter((i) => i.tagId == this.id);
    this.newTag = temp[0].tagName;
    this.tagged = this.course.filter((i) => i.tag_id == this.id);
  },
};
</script>
<style scoped></style>

import CourseEnrollIndexPage from "@/components/pages/course-enrollment/CourseEnrollIndexPage";
import CourseEnrollStep1Page from "@/components/pages/course-enrollment/CourseEnrollStep1Page";
import CourseEnrollStep2Page from "@/components/pages/course-enrollment/CourseEnrollStep2Page";
import CourseEnrollStep3Page from "@/components/pages/course-enrollment/CourseEnrollStep3Page";
import EnrollmentCourseMemberPage from "@/components/pages/course-enrollment/CourseMemberPage";

export default [
  {
    path: "courses/enroll",
    name: "course.enroll.index",
    component: CourseEnrollIndexPage,
    meta: {
      authRequired: true,
      permsRequired: [
        "read-students-enrolled",
        "crud-individual-enrollment",
        "crud-group-enrollment"
      ]
    }
  },
  {
    path: "courses/enroll/step1",
    name: "course.enroll.step1",
    component: CourseEnrollStep1Page,
    meta: {
      authRequired: true,
      permsRequired: ["crud-individual-enrollment", "crud-group-enrollment"]
    }
  },
  {
    path: "courses/enroll/step2",
    name: "course.enroll.step2",
    component: CourseEnrollStep2Page,
    meta: {
      authRequired: true,
      permsRequired: ["crud-individual-enrollment", "crud-group-enrollment"]
    }
  },
  {
    path: "courses/enroll/step3",
    name: "course.enroll.step3",
    component: CourseEnrollStep3Page,
    meta: {
      authRequired: true,
      permsRequired: ["crud-individual-enrollment", "crud-group-enrollment"]
    }
  },
  {
    path: "courses/:id/students",
    name: "enrollment.members",
    component: EnrollmentCourseMemberPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-students-enrolled"]
    }
  }
];

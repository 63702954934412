<template>
  <div>
    <div>
      <label id="fieldset-1__BV_label_" for="duration" class="d-block mt-4">
        ประเภทหลักสูตรของตัวแทน/นายหน้าประกัน
        <span v-if="courseAttributeUpdated['courseTags']" class="text-success">
          <i class="fa fa-check" aria-hidden="true"></i>
        </span>
      </label>
      <div class="position-relative">
        <v-select
          v-model="insuranceCourseSelect"
          multiple
          :options="type"
          label="typeName"
          placeholder="เลือกประเภทหลักสูตร"
          @input="setSelectedTags"
          :disabled="isLoading"
        >
        </v-select>
        <template v-if="isLoading">
          <div
            class="text-cent"
             style="position: absolute; right: 25px; top: 50%; z-index: 5;transform: translate(-50%, -50%);"
          >
            <b-spinner class="align-middle" variant="primary"></b-spinner>
          </div>
        </template>
      </div>
    </div>
    <div>
      <label id="fieldset-1__BV_label_" for="duration" class="d-block mt-4">
        ระดับของหลักสูตร
        <span class="text-danger"
          ><small>*กรณีเลือกขอรับ ต้องมีประเภทหลักสูตรแค่ 1 ประเภท</small></span
        >
      </label>
      <b-form-select
        v-model="insuranceCourseLavel"
        placeholder="เลือกประเภทหลักสูตร"
        @change="changeLevelInsurance()"
        :disabled="
          insuranceCourseSelect ? insuranceCourseSelect.length === 0 : ''
        "
      >
        <option value="null" selected disabled>
          กรุณาเลือกระดับของหลักสูตร
        </option>
        <option value="ENTRY" :disabled="checkSumType()">ขอรับ</option>
        <option value="RENEW1">ต่อ 1</option>
        <option value="RENEW2">ต่อ 2</option>
        <option value="RENEW3">ต่อ 3</option>
        <option value="RENEW4">ต่อ 4</option>
      </b-form-select>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      insuranceCourseSelect: [],
      insuranceCourseId: [],
      insuranceCourseLavel: null,
      type: [
        {
          typeName: "ตัวแทนประกันชีวิต",
          insuranceCourseType: "LIFE_AGENT",
        },
        {
          typeName: "นายหน้าประกันชีวิต",
          insuranceCourseType: "LIFE_BROKER",
        },
        {
          typeName: "ตัวแทนประกันวินาศภัย",
          insuranceCourseType: "NONLIFE_AGENT",
        },
        {
          typeName: "นายหน้าประกันวินาศภัย",
          insuranceCourseType: "NONLIFE_BROKER",
        },
      ],
      selectedTags: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "courseInfo",
      "courseAttributeUpdated",
      "insuranceCourse",
    ]),
  },

  mounted() {
    this.fetchInsuranceCourse();
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateCourseInfo",
      "updateCourseTags",
      "getInsuranceCourse",
      "createInsuranceCourse",
      "delectInsuranceCourse",
      "updateInsuranceCourse",
    ]),

    setSelectedTags() {
      this.isLoading = true;
      this.syncCourseInsurance();
    },

    checkSumType() {
      if (
        this.insuranceCourseSelect === null ||
        this.insuranceCourseSelect === undefined
      ) {
        return false;
      } else if (Object.keys(this.insuranceCourseSelect).length > 1) {
        return true;
      }
    },

    fetchInsuranceCourse() {
      this.getInsuranceCourse(this.courseId).then(() => {
        this.insuranceCourseSelect = this.insuranceCourse;

        for (let i = 0; i < this.insuranceCourseSelect.length; i++) {
          this.insuranceCourseId[i] =
            this.insuranceCourseSelect[i].insuranceCourseId;
          this.insuranceCourseLavel =
            this.insuranceCourseSelect[i].insuranceCourseLevel;
          delete this.insuranceCourseSelect[i].insuranceCourseId;
          delete this.insuranceCourseSelect[i].insuranceCourseLevel;
          delete this.insuranceCourseSelect[i].insuranceCoursePillarInfo;
          delete this.insuranceCourseSelect[i].insuranceCourseTotalHours;
        }
      });
    },

    async deleteInsuranceCourse() {
      for (let i = 0; i < this.insuranceCourse.length; i++) {
        let request = {
          courseId: this.courseId,
          insuranceId: this.insuranceCourseId[i],
        };
        await this.delectInsuranceCourse(request);
      }
    },
    async syncCourseInsurance() {
      if (this.insuranceCourseId) {
        await this.deleteInsuranceCourse();
      }

      if (Object.keys(this.insuranceCourseSelect).length > 1 && this.insuranceCourseLavel === 'ENTRY') {
        this.insuranceCourseLavel = null;
      }

      for (let i = 0; i < this.insuranceCourseSelect.length; i++) {
        let request = {
          courseId: this.courseId,
          insuranceLevel: this.insuranceCourseLavel
            ? this.insuranceCourseLavel
            : null,
          insuranceType: this.insuranceCourseSelect[i].insuranceCourseType,
          insurancePillarInfo: null,
          insuranceTotalHours: null,
        };
        await this.createInsuranceCourse(request);
      }

      await this.getInsuranceCourse(this.courseId).then(() => {
        for (let i = 0; i < this.insuranceCourse.length; i++) {
          this.insuranceCourseId[i] = this.insuranceCourse[i].insuranceCourseId;
        }
        this.isLoading = false;
      });
    },

    async changeLevelInsurance() {
      for (let i = 0; i < this.insuranceCourseSelect.length; i++) {
        let request = {
          body: {
            courseId: this.courseId,
            insuranceId: this.insuranceCourseId[i],
            insuranceLevel: this.insuranceCourseLavel
              ? this.insuranceCourseLavel
              : null,
          },
          page: "info",
        };
        await this.updateInsuranceCourse(request);
      }
      await this.getInsuranceCourse(this.courseId).then(() => {
        for (let i = 0; i < this.insuranceCourse.length; i++) {
          this.insuranceCourseId[i] = this.insuranceCourse[i].insuranceCourseId;
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <page-header :title="`ประวัติการตรวจสอบใบหน้า`"></page-header>
    <b-container class="widthPage mx">
      <b-row class="mt-4">
        <b-col cols="3">
          <b-input-group>
            <b-form-input
              placeholder="ค้นหาคอร์สหรือชื่อการค้นหา"
              v-model="search"
            ></b-form-input>
            <b-input-group-prepend class="input-search">
              <b-button
                size="sm"
                variant="primary"
                @click="searchNameAndCourse()"
                >ค้นหา</b-button
              >
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-input-group>
            <b-form-input
              placeholder="วันที่ตรวจสอบ"
              disabled
              v-model="formattedDate"
            ></b-form-input>
            <b-input-group-prepend class="input-search">
              <b-button size="sm" variant="primary" @click="showCalendarRange()"
                ><b-icon icon="calendar-range"></b-icon
              ></b-button>
            </b-input-group-prepend>
          </b-input-group>
          <div>
            <DatePicker
              id="calendar-range"
              style="
                display: none;
                float: right;
                position: absolute;
                will-change: transform;
                z-index: 999;
              "
              v-model="range"
            />
          </div>
        </b-col>
        <b-col cols="12" class="mt-4">
          <layout class="user-learn-progress">
            <template v-slot:header> </template>
            <template v-slot:body>
              <b-table
                striped
                hover
                :fields="fields"
                :items="listResult"
                :busy="isBusy"
              >
                <template v-slot:cell(fash_randomPercent)="data">
                  <div>
                    {{ data.item.fash_randomPercent }}%
                  </div>
                </template>
                <template v-slot:cell(face_approvedSummary)="data">
                  <div>
                    {{ `${data.item.face_sumApproved}/${data.item.face_cerTotal}` }}
                  </div>
                </template>
                <template v-slot:cell(option)="data">
                  <div>
                    <a
                      class="btn btn-sm btn-outline-primary"
                      :href="`${$route.path}/${data.item.fash_id}/courses/${data.item.c_id}`"
                      target="_blank"
                    >
                      ดูข้อมูล
                    </a>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>

              <div v-if="!rows && !isBusy" class="text-center">
                <h6>ไม่มีข้อมูล</h6>
              </div>
            </template>
          </layout>

          <div>
            <b-row class="mt-4">
              <b-col cols="6 ">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="pr-3">แสดง</p>
                  <b-form-select
                    v-model="selectedShow"
                    style="width: 75px"
                    @change="changeShowData"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </b-form-select>
                  <p class="pl-3">จาก {{ rows }} รายการ</p>
                </div>
              </b-col>
              <b-col cols="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="promotion-list-table"
                  align="right"
                  last-number
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
export default {
  components: {
    PageHeader,
    layout,
    DatePicker,
  },

  data() {
    return {
      items: [],
      fields: [
        {
          key: "fash_name",
          label: "การค้นหา",
          thStyle: "width: 350px",
        },
        {
          key: "c_name",
          label: "คอร์สเรียน",
          thStyle: "width:350px",
        },
        {
          key: "fash_timeStamp",
          label: "วันที่ตรวจสอบ",
          thStyle: "width: 200px;",
        },
        {
          key: "admin_name",
          label: "ชื่อผู้ตรวจสอบ",
          thStyle: "width: 300px;",
        },
        {
          key: "fash_randomPercent",
          label: "จำนวนที่สุ่ม (%)",
          thStyle: "width: 200px;",
        },
        {
          key: "face_approvedSummary",
          label: "จำนวนที่ตรวจสอบแล้ว",
          thStyle: "width: 200px"
        },
        {
          key: "option",
          label: "",
          thStyle: "width: 200px;",
        },
      ],
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
      search: null,
      isBusy: false,
      range: null,
    };
  },

  mounted() {
    this.getAppproveSearchHistory();
  },

  computed: {
    formattedDate() {
      if (this.range) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        return moment(this.range).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
    rows() {
      return this.items.length;
    },

    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      var showData = this.items.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      );
      return showData;
    },
  },
  created() {},

  methods: {
    async getAppproveSearchHistory() {
      await api.get("/v1/face-approve-search-history").then(({ data }) => {
        this.items = data.data;
      });
    },

    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        this.searchNameAndCourse();
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    searchNameAndCourse() {
      this.isBusy = true;
      this.getAppproveSearchHistory().then(() => {
        this.items = this.items
          .filter((i) =>
            this.search
              ? (i.c_name !== null && i.c_name.includes(this.search)) ||
                (i.fash_name !== null && i.fash_name.includes(this.search))
              : i.c_name
          )
          .filter((i) =>
            this.range
              ? moment(i.fash_timeStamp).format("YYYY-MM-DD") ===
                moment(this.range).format("YYYY-MM-DD")
              : i.fash_timeStamp
          );
        this.isBusy = false;
      });
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
  },
};
</script>

<style scoped>
.input-search button {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>

import * as actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import Vue from "vue";
import async from "@/config/store";

const initialState = () => ({
  [async.ASYNC_UPLOADED_CSV.stateKey]: null,
  [async.ASYNC_UPLOADED_CSV.loadingKey]: true,
  [async.ASYNC_UPLOADED_CSV.errorKey]: null,
  [async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey]: null,
  [async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey]: true,
  [async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey]: null,
  [async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey]: null,
  [async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey]: true,
  [async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey]: null
});

const state = {
  ...initialState()
};

export default {
  namespaced: true,
  state,
  actions: {
    ...actions,
    init(context) {
      context.commit("init");
    }
  },
  getters,
  mutations: {
    ...mutations,
    init(state) {
      let reset = initialState();
      for (let f in state) {
        Vue.set(state, f, reset[f]);
        // if (reset.hasOwnProperty(f)) Vue.set(state, f, reset[f]);
      }
    }
  }
};

<template>
  <div>
    <b-form id="tfac-course">
      <b-row class="mt-4"
        ><b-col
          ><h5><strong>ราคาคอร์สและระยะเวลาเรียน</strong></h5></b-col
        ></b-row
      >
      <b-row
        ><b-col
          ><h6><strong>ระยะเวลาเรียน</strong></h6></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              วันที่เริ่มหลักสูตร<span class="text-danger">*</span>
            </template>
            <b-form-input
              required
              id="input-start-date"
              type="date"
              v-model="startLearningDate"
              :state="validStartLearning"
              :readonly="readonly"
              @blur="onblurCourseInfoDate"
              @change="validStartLearning = null"
              @keyup.enter="onblurCourseInfoDate"
            />
            <b-form-invalid-feedback id="input-start-date-feedback">
              {{
                !this.form.startLearningDate ||
                this.form.startLearningDate == ""
                  ? `กรุณากรอกวันที่เริ่มหลักสูตร`
                  : `วันที่เริ่มวันที่เริ่มหลักสูตร ต้องเป็นวันเดียวกันหรือหลังจาก วันที่เปิดขายหลักสูตร`
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              วันที่จบหลักสูตร<span class="text-danger">*</span>
            </template>
            <b-form-input
              required
              v-model="finishLearningDate"
              type="date"
              id="input-finish-date"
              :state="validFinishLearning"
              :readonly="readonly"
              @blur="onblurCourseInfoDate"
              @change="validFinishLearning = null"
              @keyup.enter="onblurCourseInfoDate"
            />
            <b-form-invalid-feedback id="input-finish-date-feedback">
              {{
                !this.form.finishLearningDate ||
                this.form.finishLearningDate == ""
                  ? `กรุณากรอกวันที่จบหลักสูตร`
                  : `วันที่จบหลักสูตร ต้องเป็นวันหลังจาก วันที่เริ่มหลักสูตร`
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      form: {
        approveDate: null,
        startLearningDate: null,
        finishLearningDate: null,
      },
      dateFormat: moment().format("YYYY-MM-DD"),
      validStartLearning: null,
      validFinishLearning: null,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
    approveDate: {
      get() {
        return moment(this.form.approveDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.approveDate = val;
      },
    },
    startLearningDate: {
      get() {
        return moment(this.form.startLearningDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.startLearningDate = val;
      },
    },
    finishLearningDate: {
      get() {
        return moment(this.form.finishLearningDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.finishLearningDate = val;
      },
    },
  },
  async created() {
    await this.getCourseInfo({
      courseId: this.$route.params.courseId,
      temp: true,
      excepts: "numberOfStudent,numberOfActiveStudent",
    });
    this.form = JSON.parse(JSON.stringify(this.courseInfo));
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateCourseInfo",
    ]),
    async onblurCourseInfoDate() {
      this.validStartLearning = null;
      this.validFinishLearning = null;
      if (
        this.form.startLearningDate === this.courseInfo.startLearningDate &&
        this.form.finishLearningDate === this.courseInfo.finishLearningDate
      ) {
        this.validStartLearning = true;
        this.validFinishLearning = true;
      } else if (
        !this.form.startLearningDate ||
        this.form.startLearningDate == ""
      ) {
        this.validStartLearning = false;
      } else if (
        !this.form.finishLearningDate ||
        this.form.finishLearningDate == ""
      ) {
        this.validFinishLearning = false;
      } else if (
        this.form.startLearningDate &&
        this.form.finishLearningDate &&
        this.form.startLearningDate >= this.form.finishLearningDate
      ) {
        this.validFinishLearning = false;
      } else {
        await this.updateCourseInfo({
          courseId: this.$route.params.courseId,
          attribute: {
            startLearningDate: this.form.startLearningDate,
            finishLearningDate: this.form.finishLearningDate,
          },
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.validStartLearning = true;
        this.validFinishLearning = true;
      }
    },
  },
};
</script>

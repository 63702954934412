<template>
  <div>
    <PageHeader title="รายการสั่งซื้อคอร์สเรียน" />
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <div class="inner bg-container">
          <b-card class="no-shadow" no-body>
            <b-tabs card>
              <b-tab title="รายการสั่งซื้อรายคอร์ส" active>
                <CourseOrderListByCourse />
              </b-tab>
              <b-tab title="รายการสั่งซื้อรายออเดอร์">
                <CourseOrderListByOrder />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import CourseOrderListByCourse from "./panel/CourseOrderListByCourse.vue";
import CourseOrderListByOrder from "./panel/CourseOrderListByOrder.vue";

export default {
  components: {
    PageHeader,
    CourseOrderListByCourse,
    CourseOrderListByOrder,
  },
};
</script>

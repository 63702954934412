<template>
  <div>
    <b-row class="mt-3">
      <b-col md="12">
        <search-box
          :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
          @typed="getSearchKeyword"
        ></search-box>
      </b-col>
      <b-col md="7">
        <div class="form-inline">
          <label for class="ml-2 mt-2"
            >เลือกผู้เรียนจำนวน {{ allSelectedItems.length }} คน</label
          >
        </div>
      </b-col>
    </b-row>

    <b-table
      ref="selectableTable"
      :items="items.data"
      :fields="fields"
      :current-page="items.meta.current_page"
      :busy="onLoading"
      hover
      responsive
      class="mt-3"
      @row-clicked="rowClicked"
      :tbody-tr-class="tbodyRowClass"
      primary-key="id"
    >
      <template v-slot:header(selected)="">
        <b-link
          v-if="isAllItemsSelected"
          class="text-dark"
          @click="clearSelected"
        >
          <b-icon-check2-square scale="1.2" />
        </b-link>
        <b-link v-else class="text-dark" @click="selectAllRows">
          <b-icon-square />
        </b-link>
      </template>

      <template v-slot:cell(selected)="{ item }">
        <template v-if="isSelectedItem(item)">
          <b-icon-check2-square scale="1.2" />
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <b-icon-square />
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <template v-slot:cell(fullname)="{ item: { firstName, lastName } }">
        {{ `${firstName} ${lastName}` }}
      </template>

      <div slot="table-busy">
        <b-spinner class="align-middle text-csq"></b-spinner>
      </div>
    </b-table>

    <Pagination
      v-if="items.data.length"
      :items="items"
      :limits="[10, 50, 100]"
      @changePage="getResults"
      @changeLimit="getResults(1)"
    />

    <div class="mt-3 text-right">
      <b-button variant="link" class="text-csq" @click="cancel()"
        >ยกเลิก</b-button
      >
      <b-button variant="dark" @click="confirm()">ยืนยัน</b-button>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import SearchBox from './SearchBox';
import Pagination from '@/components/shared/Pagination';
import Numeral from 'numeral';
import SelectableTableMixin from '@/mixins/selectable-table-mixin.js';

export default {
  components: { SearchBox, Pagination },
  mixins: [SelectableTableMixin],
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format('0,0');
    },
  },
  props: ['companyId', 'group'],
  data() {
    return {
      groupId: this.group.id,
      input: {
        name: null,
        description: null,
      },
      fields: [
        { key: 'selected', thStyle: { width: '20px' } },
        { label: 'ชื่อผู้เรียน', key: 'fullname' },
        { label: 'ชื่อผู้ใช้', key: 'username' },
        { label: 'อีเมล', key: 'email' },
        { label: 'รหัสพนักงาน', key: 'staffId' },
        { label: 'แผนก', key: 'department' },
      ],
      q: '',
      onLoading: true,
      actionOptions: null,
    };
  },
  created() {
    this.getResults();
  },
  methods: {
    async getResults(page = 1) {
      this.onLoading = true;
      await api
        .get(
          `/v1/companies/${this.companyId}/members?page=${page}&sort_by=fullname&limit=${this.items.meta.per_page}&q=${this.q}&excepts=profiles,groups,roles&except_group=${this.group.id}`
        )
        .then(({ data }) => {
          this.items = data;
          this.onLoading = false;
        });
    },
    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },
    confirm() {
      const memberIds = this.allSelectedItems.map((item) => item.id);
      this.$emit('selected', memberIds);
    },
    cancel() {
      this.$emit('canceled');
    },
  },
};
</script>

<style scoped>
.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(
      white,
      white 30px,
      #ccc 30px,
      #ccc 31px,
      white 31px
    );
  line-height: 31px;
  padding: 0px 0px;
}
</style>

import moment from "moment";

const state = {
  form: {
    topic: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    instructor: "", // m_id
    liveScope: "COMPANY",
    thumbnailURL: "",
    liveDetail: "",
    courseID: null,
    isInstructorCam: "1",
    isStudentCam: "1",
    calendar: "i-cal",
    record: "1",
    thirdURL: "",
    meetingId: "",
    meetingPassword: "",
    meetingURL: ""
  },
  isModified: false,
  isDraft: true
};

const getters = {
  isModified: state => {
    return state.isModified;
  },
  isEnableSaveAsDraft: state => {
    return state.isDraft;
  },
  getTopic: state => {
    return state.form.topic;
  },
  getStartDate: state => {
    return state.form.startDate;
  },
  getStartTime: state => {
    return state.form.startTime.substr(0, 5);
  },
  getEndDate: state => {
    return state.form.endDate;
  },
  getEndTime: state => {
    return state.form.endTime.substr(0, 5);
  },
  getInstructor: state => {
    return state.form.instructor;
  },
  getLiveScope: state => {
    return state.form.liveScope;
  },
  getThumbnailURL: state => {
    return state.form.thumbnailURL;
  },
  getLiveDetail: state => {
    return state.form.liveDetail;
  },
  getCourseID: state => {
    return state.form.courseID;
  },
  getIsInstructorCam: state => {
    return state.form.isInstructorCam;
  },
  getIsStudentCam: state => {
    return state.form.isStudentCam;
  },
  getCalendar: state => {
    return state.form.calendar;
  },
  getRecord: state => {
    return state.form.record;
  },
  getThirdURL: state => {
    return state.form.thirdURL;
  },
  getMeetingId: state => {
    return state.form.meetingId;
  },
  getMeetingPassword: state => {
    return state.form.meetingPassword;
  },
  getMeetingURL: state => {
    return state.form.meetingURL;
  }
};

const actions = {
  resetForm({ commit, dispatch }) {
    commit("resetForm");
    dispatch("setDefaultInstructor");
  },
  resetModified({ commit }) {
    commit("setModified", false);
  },
  setTopic({ state, commit }, topic) {
    if (!state.isModified) commit("setModified", true);
    commit("setTopic", topic);
  },
  setStartDate({ state, commit }, date) {
    if (!state.isModified) commit("setModified", true);
    commit("setStartDate", date);
  },
  setStartTime({ state, commit }, time) {
    if (!state.isModified) commit("setModified", true);
    commit("setStartTime", time);
  },
  setEndDate({ state, commit }, date) {
    if (!state.isModified) commit("setModified", true);
    commit("setEndDate", date);
  },
  setEndTime({ state, commit }, time) {
    if (!state.isModified) commit("setModified", true);
    commit("setEndTime", time);
  },
  setInstructor({ state, commit }, inst) {
    if (!state.isModified) commit("setModified", true);
    commit("setInstructor", inst);
  },
  setCourse({ state, commit }, course) {
    if (!state.isModified) commit("setModified", true);
    commit("setCourse", course);
  },
  setLiveScope({ state, commit }, scope) {
    if (!state.isModified) commit("setModified", true);
    commit("setLiveScope", scope);
  },
  setThumbnailURL({ state, commit }, url) {
    if (!state.isModified) commit("setModified", true);
    commit("setThumbnailURL", url);
  },
  setLiveDetail({ state, commit }, detail) {
    if (!state.isModified) commit("setModified", true);
    commit("setLiveDetail", detail);
  },
  setCourseID({ state, commit }, id) {
    if (!state.isModified) commit("setModified", true);
    commit("setCourseID", id);
  },
  setIsInstructorCam({ state, commit }, isEnable) {
    if (!state.isModified) commit("setModified", true);
    commit("setIsInstCam", isEnable);
  },
  setIsStudentCam({ state, commit }, isEnable) {
    if (!state.isModified) commit("setModified", true);
    commit("setIsStudCam", isEnable);
  },
  setCalendar({ state, commit }, cal) {
    if (!state.isModified) commit("setModified", true);
    commit("setCalendar", cal);
  },
  setRecord({ state, commit }, record) {
    if (!state.isModified) commit("setModified", true);
    commit("setRecord", record);
  },
  setThirdURL({ state, commit }, url) {
    if (!state.isModified) commit("setModified", true);
    commit("setThirdURL", url);
  },
  setMeetingId({ state, commit }, id) {
    if (!state.isModified) commit("setModified", true);
    commit("setMeetingId", id);
  },
  setMeetingPassword({ state, commit }, pass) {
    if (!state.isModified) commit("setModified", true);
    commit("setMeetingPassword", pass);
  },
  setMeetingURL({ state, commit }, url) {
    if (!state.isModified) commit("setModified", true);
    commit("setMeetingURL", url);
  },
  setFormDatetime({ commit }) {
    let today = moment();
    let start = moment(today).add(10, "minutes");
    let end = moment(today).add(70, "minutes");
    let startDate = moment(start).format("YYYY-MM-DD");
    let startTime = moment(start).format("HH:mm:ss");
    let endDate = moment(end).format("YYYY-MM-DD");
    let endTime = moment(end).format("HH:mm:ss");

    commit("setStartDatetime", { date: startDate, time: startTime });
    commit("setEndDatetime", { date: endDate, time: endTime });
  },
  setDefaultInstructor({ commit, rootGetters }) {
    commit(
      "setInstructor",
      rootGetters["Company/companyInstructorsData"].data[0].ownerId
    );
  },
  fillInForm({ commit }, meeting) {
    commit("setDraftForm", meeting.status == "DRAFT");

    commit("setMeetingId", meeting.meeting_id);
    commit("setTopic", meeting.topic);
    commit("setStartDate", moment(meeting.start_datetime).format("YYYY-MM-DD"));
    commit("setStartTime", moment(meeting.start_datetime).format("HH:mm:ss"));
    commit("setEndDate", moment(meeting.end_datetime).format("YYYY-MM-DD"));
    commit("setEndTime", moment(meeting.end_datetime).format("HH:mm:ss"));
    commit("setInstructor", meeting.instructor.ownerId);
    commit("setCourseID", meeting.c_id);
    commit("setLiveScope", meeting.scope);
    if (meeting.type == "ZOOM") {
      commit("setIsInstCam", meeting.settings.host_video);
      commit("setIsStudCam", meeting.settings.participant_video);
      commit("setCalendar", meeting.settings.calendar);
      commit("setRecord", meeting.settings.record);
      commit("setMeetingPassword", "*QWERTY01");
    } else if (meeting.type == "ZOOM-URL"){
      commit("setMeetingURL", meeting.join_url);
      commit("setMeetingPassword", meeting.meeting_password);
    } else if (meeting.type == "OTHER") {
      commit("setThirdURL", meeting.join_url);
    } else console.warn("FillInForm: Undefined live type");
  }
};

const mutations = {
  resetForm: state => {
    (state.form = {
      topic: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      instructor: "", // m_id
      liveScope: "COMPANY",
      thumbnailURL: "",
      liveDetail: "",
      courseID: null,
      isInstructorCam: "1",
      isStudentCam: "1",
      calendar: "i-cal",
      record: "1",
      thirdURL: "",
      meetingId: "",
      meetingPassword: "",
      meetingURL: ""
    }),
      (state.isModified = false),
      (state.isDraft = true);
  },
  setModified: (state, payload) => {
    state.isModified = payload;
  },
  setDraftForm: (state, payload) => {
    state.isDraft = payload;
  },
  setInstructor: (state, payload) => {
    state.form.instructor = payload;
  },
  setTopic: (state, payload) => {
    state.form.topic = payload;
  },
  setStartDate: (state, payload) => {
    state.form.startDate = payload;
  },
  setStartTime: (state, payload) => {
    state.form.startTime = payload;
  },
  setEndDate: (state, payload) => {
    state.form.endDate = payload;
  },
  setEndTime: (state, payload) => {
    state.form.endTime = payload;
  },
  setLiveScope: (state, payload) => {
    state.form.liveScope = payload;
  },
  setThumbnailURL: (state, payload) => {
    state.form.thumbnailURL = payload;
  },
  setLiveDetail: (state, payload) => {
    state.form.liveDetail = payload;
  },
  setCourseID: (state, payload) => {
    state.form.courseID = payload;
  },
  setIsInstCam: (state, payload) => {
    state.form.isInstructorCam = payload;
  },
  setIsStudCam: (state, payload) => {
    state.form.isStudentCam = payload;
  },
  setCalendar: (state, payload) => {
    state.form.calendar = payload;
  },
  setRecord: (state, payload) => {
    state.form.record = payload;
  },
  setThirdURL: (state, payload) => {
    state.form.thirdURL = payload;
  },
  setStartDatetime: (state, payload) => {
    state.form.startDate = payload.date;
    state.form.startTime = payload.time;
  },
  setEndDatetime: (state, payload) => {
    state.form.endDate = payload.date;
    state.form.endTime = payload.time;
  },  
  setMeetingId: (state, payload) => {
    state.form.meetingId = payload;
  },
  setMeetingPassword: (state, payload) => {
    state.form.meetingPassword = payload;
  },
  setMeetingURL: (state, payload) => {
    state.form.meetingURL = payload;
  }
};

export default { namespaced: true, state, getters, actions, mutations };

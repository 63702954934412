<template>
  <div class>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col cols="6">
            <div class="d-flex align-items-center">
              <img
                :src="diplomaLogo"
                style="width: 40px; height: 40px;"
              />
              <span class="ml-2">Certificate / ประกาศนียบัตร</span>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex align-items-center justify-content-end h-100">
              <a :href="`${$route.path}/certificate`" target="_blank">
                <h6>ดูทั้งหมด</h6>
              </a>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            striped
            hover
          >
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.courseName }}</span></template
            >
            <template v-slot:cell(courseDuration)="data">
              <span>{{ data.item.courseDuration | thaiDurationFilter }}</span>
            </template>
            <template v-slot:cell(timeSpent)="data">
              <template>
                <span>{{
                  data.item.timeSpent | thaiDurationFilter
                }}</span></template
              >
            </template>
            <template v-slot:cell(certificateIssueDate)="data"
              ><span>{{
                data.item.certificateIssueDate | thaiDateFilter
              }}</span></template
            >

            <template v-slot:cell(btn)="data">
              <!-- multiple certificate  -->
              <b-dropdown
                v-if="data.item.accounting.length > 1"
                id="dropdown-certificate"
                text="ดาวน์โหลด"
                variant="outline-primary"
                size="sm"
                class="h5 text-center"
              >
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <b-icon
                      icon="download"
                      variant="primary"
                      class="h6 text-center download-icon-style"
                    ></b-icon>
                    <span class="ml-2">ดาวน์โหลด</span>
                  </div>
                </template>

                <b-dropdown-item @click="downloadIconClicked(data.item.courseId)">
                  ดาวน์โหลดทั้งหมด
                </b-dropdown-item>

                <!-- Loop through accounting items -->
                <b-dropdown-item
                  v-for="(accountingItem, index) in data.item.accounting"
                  :key="index"
                  @click="downloadIconClicked(data.item.courseId, accountingItem.certificateIssueId)"
                >
                  {{
                    getCertificateType(accountingItem.certificateIssueType)
                  }}
                </b-dropdown-item>
              </b-dropdown>
              <!-- certificate single-->
              <b-icon
                v-else
                icon="download"
                variant="primary"
                class="h5 text-center download-icon-style"
                @click="downloadIconClicked(data.item.courseId,data.item.certificateIssueId)"
              ></b-icon>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import _ from "lodash";
import DiplomaLogo from '@/assets/images/diploma.png';
import { openURL } from "@/components/shared/NewTabLink.js";

export default {
  components: {
    layout
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      isLoading: false,
      fields: [
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "courseDuration", label: "ความยาวคอร์ส" },
        { key: "timeSpent", label: "เวลาที่ใช้" },
        { key: "certificateIssueDate", label: "วันที่ออกใบ" },
        { key: "btn", label: "ดาวน์โหลด" }
      ],
      items: [],
      company: {},
      diplomaLogo: DiplomaLogo
    };
  },
  mounted: function() {
    this.memberId = this.$route.params.mId;
    this.company = this.$store.state.Company.companyInfo;
    this.fetchMemberCertificates();
  },
  methods: {
    async fetchMemberCertificates() {
      this.isLoading = true;
      try {
        let results = await api.get(
          `/v1/members/${this.memberId}/certificates`
        );
        this.items = _.orderBy(
          results.data.data,
          ["certificateIssueDate"],
          ["desc"]
        ).slice(0, 10);
      } catch (err) {
        console.log("fetchMemberCertificates error", err);
      }
      this.isLoading = false;
    },
    async downloadIconClicked(id, certisId = "") {
      try {
        let results = await api.get(
          `/v1/members/${this.memberId}/course/${id}/certificate?certisId=${certisId}`
        );
        let link = "";
        if (results.data.length == 2) {
          for (let i = 0; i < results.data.length; i++) {
            if (this.company.whitelabelUrl)
              link = this.company.whitelabelUrl + results.data[i].link;
            else link = results.data[i].domain + results.data[i].link;
            openURL(link)
          }
        } else {
          if (this.company.whitelabelUrl)
            link = this.company.whitelabelUrl + results.data.link;
          else link = results.data.domain + results.data.link;
          openURL(link)
        }
      } catch (err) {
        console.log("downloadIconClicked error", err);
      }
    },

    getCertificateType(type) {
      switch (type) {
        case 'CPD_COMPLETED':
          return 'CPD';
        case 'CPA_COMPLETED':
          return 'CPA';
        case 'COMPLETED':
          return 'COMPLETED';
        default:
          return 'Unknown';
      }
    },
  }
};
</script>

<style scoped>
.download-icon-style:hover {
  cursor: pointer;
}
</style>

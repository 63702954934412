import Cookies from "js-cookie";
import axios from "axios";
import authConfig from "../config/auth";

const accessToken = Cookies.get(authConfig.ACCESS_TOKEN_COOKIE);
const baseURL = process.env.VUE_APP_API_BASE_URL || "http://localhost:8008/api";

const client = axios.create({
  baseURL: baseURL,
  timeout: 90000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + accessToken,
  },
});

const img = axios.create({
  baseURL: baseURL,
  timeout: 90000,
  responseType: "blob",
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + accessToken,
  },
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Make sure that error.response is not null
    if (error.response) {
      if (error.response.status === 404) {
        let url = `${window.location.origin}/${
          window.location.pathname.split("/")[1]
        }/error`;
        window.location.replace(url);
      }
      if (error.response.status === 403) {
        let url = `${window.location.origin}/${
          window.location.pathname.split("/")[1]
        }/un-authorized`;
        window.location.replace(url);
      }
    }
    return Promise.reject(error);
  }
);

export default {
  async get(endpoint, params = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.get(`${endpoint}`, params);
  },

  async getImg(endpoint, params = {}) {
    img.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await img.get(`${endpoint}`, params);
  },

  async post(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.post(`${endpoint}`, payloads);
  },

  async put(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.put(`${endpoint}`, payloads);
  },

  async patch(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.patch(`${endpoint}`, payloads);
  },

  async delete(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.delete(`${endpoint}`, {
      params: payloads,
      data: payloads,
    });
  },
};

<template>
  <b-modal
    v-model="show"
    title="ยืนยันการเผยแพร่คอร์ส"
    hide-footer
    @hide="close()"
  >
    <div class="modal-body p-0">
      <div v-if="!isSuccess && !message">
        {{ course.courseName }}
      </div>
      <div v-else class="text-center">
        <h1 class="py-4">
          <b-icon-check-circle
            v-if="isSuccess"
            scale="2.4"
            class="text-success"
          />
          <b-icon-exclamation-circle v-else scale="2" class="text-danger" />
        </h1>
        <span>{{ message }}</span>
      </div>
    </div>

    <div class="modal-footer text-center border-top-0 px-0 pt-5">
      <div>
        <b-button
          variant="outline-secondary"
          data-dismiss="modal"
          @click="close()"
        >
          ปิด
        </b-button>
        <b-button
          v-if="!isSuccess"
          variant="success"
          class="ml-1"
          :disabled="isLoading"
          @click="publish"
        >
          <b-spinner v-if="isLoading" small></b-spinner>
          เผยแพร่คอร์ส
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmPublishCourseModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isModalShow: false,
      isSuccess: false,
      isLoading: false,
      message: '',
    };
  },
  methods: {
    ...mapActions('CourseManagement', ['publishCourse', 'getCourseInfo']),
    async close() {
      this.isModalShow = false;
      this.isSuccess = false;
      this.isLoading = false;
      this.message = '';
      this.$emit('closed');
    },
    async publish() {
      this.isLoading = true;
      const { data } = await this.publishCourse(this.course.courseId);
      await this.getCourseInfo({
        courseId: this.course.courseId,
        temp: true,
        excepts: 'numberOfStudent,numberOfActiveStudent',
      });
      this.isSuccess = data.status === 'success';
      this.message = data.message;
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="mt-2">
      <b-card 
        title-tag="h5"
        class="bg-light border-0 no-shadow pt-3 my-3"
      >
        <!-- หน้าตรง -->
        <b-form-group
          class="my-1"
          label="รูปถ่ายหน้าตรง"
          label-for="face-image"
          :description="faceImageDescription"
          :state="faceImageUploadState"
          valid-feedback="อัปโหลดไฟล์สำเร็จ"
          invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
        >
          <div class="row">
            <div class="col-md-4">
              <img src="@/assets/images/front_angle.jpg" :style="imgStyle" alt="Front Image">
            </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <img v-if="previewImgFrontSrc" :src="previewImgFrontSrc" :alt="'Front Image'" :style="imgStyle">
                  <b-form-file
                    id="face-image"
                    class="ml-4"
                    v-model="faceFrontImage"
                    plain
                    @change="previewImageFront"
                  ></b-form-file>
                </div>
              </div>
          </div>
        </b-form-group>
      </b-card>
      <!-- Line -->
      <hr class="my-1">

      <div v-if="isMultiUpload">
        <b-card 
          class="bg-light border-0 no-shadow pt-3 my-3"
        >
          <!-- หน้าหันซ้าย -->
          <b-form-group
            class="my-1"
            label="รูปถ่ายหน้าหันซ้าย"
            label-for="face-image"
            :description="faceImageDescription"
            :state="faceImageUploadState"
            valid-feedback="อัปโหลดไฟล์สำเร็จ"
            invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
          >
            <div class="row">
              <div class="col-md-4">
                <img src="@/assets/images/left_angle.jpg" :style="imgStyle" alt="Left Image">
              </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <img v-if="previewImgLeftSrc" :src="previewImgLeftSrc" :alt="'Left Image'" :style="imgStyle">
                  <b-form-file
                    id="face-image"
                    class="ml-4"
                    v-model="faceLeftImage"
                    plain
                    @change="previewImageLeft"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </b-form-group>
        </b-card>

        <b-card 
          title-tag="h5"
          class="bg-light border-0 no-shadow pt-3 my-3"
        >
          <!-- หน้าหันขวา -->
          <b-form-group
            class="my-1"
            label="รูปถ่ายหน้าหันขวา"
            label-for="face-image"
            :description="faceImageDescription"
            :state="faceImageUploadState"
            valid-feedback="อัปโหลดไฟล์สำเร็จ"
            invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
          >
            <div class="row">
              <div class="col-md-4">
                <img src="@/assets/images/right_angle.jpg" :style="imgStyle" alt="Right Image">
              </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <img v-if="previewImgRightSrc" :src="previewImgRightSrc" :alt="'Right Image'" :style="imgStyle">
                  <b-form-file
                    id="face-image"
                    class="ml-4"
                    v-model="faceRightImage"
                    plain
                    @change="previewImageRight"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </b-form-group>
        </b-card>

        <b-card 
          title-tag="h5"
          class="bg-light border-0 no-shadow pt-3 my-3"
        >
          <!-- หน้าหันมุมเงย -->
          <b-form-group
            class="my-1"
            label="รูปถ่ายหน้ามุมเงย"
            label-for="face-image"
            :description="faceImageDescription"
            :state="faceImageUploadState"
            valid-feedback="อัปโหลดไฟล์สำเร็จ"
            invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
          >
            <div class="row">
              <div class="col-md-4">
                <img src="@/assets/images/up_angle.jpg" :style="imgStyle" alt="Up Image">
              </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <img v-if="previewImgUpSrc" :src="previewImgUpSrc" :alt="'Up Image'" :style="imgStyle">
                  <b-form-file
                    id="face-image"
                    class="ml-4"
                    v-model="faceUpImage"
                    plain
                    @change="previewImageUp"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </b-form-group>
        </b-card>

        <b-card 
          title-tag="h5"
          class="bg-light border-0 no-shadow pt-3 my-3"
        >
          <!-- หน้าหันมุมก้ม -->
          <b-form-group
            class="my-1"
            label="รูปถ่ายหน้ามุมก้ม"
            label-for="face-image"
            :description="faceImageDescription"
            :state="faceImageUploadState"
            valid-feedback="อัปโหลดไฟล์สำเร็จ"
            invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
          >
            <div class="row">
              <div class="col-md-4">
                <img src="@/assets/images/down_angle.jpg" :style="imgStyle" alt="Example Down Image">
              </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <img v-if="previewImgDownSrc" :src="previewImgDownSrc" :alt="'Down Image'" :style="imgStyle">
                  <b-form-file
                    id="face-image"
                    class="ml-4"
                    v-model="faceDownImage"
                    plain
                    @change="previewImageDown"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </b-form-group>
        </b-card>

      </div>

      <div>
        <b-button
          class="mt-2 float-right"
          type="submit"
          variant="secondary"
          @click="submitMultiUpload()"
          :disabled="disableSubmitMultiUpload"
          >บันทึกรูป</b-button
        >
        <b-button
          class="mt-2 float-left"
          type="submit"
          :variant="isMultiUpload ? 'light' : 'primary'"
          :style="{ color: isMultiUpload ? '#007bff' : '#fff', backgroundColor: isMultiUpload ? '#fff' : '#007bff', borderColor: isMultiUpload ? '#007bff' : '' }"
          @click="isImgMultiUpload()"
        >
          <span v-if="isMultiUpload">
            <i class="fa fa-minus-circle "></i>
          </span>
          <span v-else>
            <i class="fa fa-plus-circle "></i>
          </span>
          {{ getButtonText }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";
import axios from "axios";
export default {
  props: {
    apiMethod: {
      type: String,
      default: null
    },
  },

  mounted() {

  },
  data() {
    return {
      token: localStorage.getItem("token"),
      mId: this.$route.params.id,
      comId: localStorage.getItem("ComId"),
      verifier: JSON.parse(localStorage.getItem("user")).id,
      faceBaseUrl:
        process.env.VUE_APP_FACE_RECOGNITION_API_URL ||
        "https://sightgain-dev-26082022.coursesquare.com/",
      isMultiUpload: false,
      faceFrontImage: null,
      faceLeftImage: null,
      faceRightImage: null,
      faceUpImage: null,
      faceDownImage: null,
      previewImgFrontSrc: null,
      previewImgLeftSrc: null,
      previewImgRightSrc: null,
      previewImgUpSrc: null,
      previewImgDownSrc: null,
      faceImageDescription: null,
      faceImageUploadState: null,
      imgStyle: {
        width: 'auto', // Set the width as per your requirement
        height: '150px' // Automatically adjust the height while maintaining the aspect ratio
      }
    };
  },
  created() {},

  computed: {
    getButtonText() {
      if (this.isMultiUpload) {
        return "เปลี่ยนเป็นอัปโหลดรูปหน้าตรงเท่านั้น";
      } else {
        return "เพิ่มการอับโหลดรูปมุมอื่น"; // Change this to the desired text when ccd_id is not null
      }
    },

    disableSubmitMultiUpload() {
      if (this.isMultiUpload) {
        return (
          this.previewImgFrontSrc === null ||
          this.previewImgLeftSrc === null ||
          this.previewImgRightSrc === null ||
          this.previewImgUpSrc === null ||
          this.previewImgDownSrc === null
        );
      } else {
        return this.previewImgFrontSrc === null;
      }
    },

  },

  methods: {
    onhideModel() {
      this.$emit("hideModalFaceImgMultiUpload");
    },

    isImgMultiUpload() {
      if(!this.isMultiUpload){
        this.isMultiUpload = true;
      }else{
        this.isMultiUpload = false;
        this.previewImgLeftSrc = null;
        this.previewImgRightSrc = null;
        this.previewImgUpSrc = null;
        this.previewImgDownSrc = null;
        this.faceLeftImage = null;
        this.faceRightImage = null;
        this.faceUpImage = null;
        this.faceDownImage = null;
      }
    },
    previewImageFront(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImgFrontSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImgFrontSrc = null;
      }
    },
    previewImageLeft(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImgLeftSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImgLeftSrc = null;
      }
    },
    previewImageRight(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImgRightSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImgRightSrc = null;
      }
    },
    previewImageUp(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImgUpSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImgUpSrc = null;
      }
    },
    previewImageDown(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImgDownSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImgDownSrc = null;
      }
    },

    submitMultiUpload() {
      if (this.apiMethod == 'PUT') {
        if (!this.isMultiUpload) {
          this.updateRegisterFace();
        } else {
          this.updateRegisterMultiFace();
        }
        this.onhideModel();
      } else {
        if (!this.isMultiUpload) {
          this.insertRegisterFace();
        } else {
          this.insertRegisterMultiFace();
        }
        this.onhideModel();
      }
    },

    async updateRegisterFace() {
      const formData = new FormData();
      formData.append('data', JSON.stringify({
        m_id: this.mId,
        com_id: this.comId
      }));
      formData.append('image', this.faceFrontImage);
      await axios
        .put(`${this.faceBaseUrl}v2/register-face`, formData)
        .then(() => {
          this.editFace();
        });
    },

    async updateRegisterMultiFace() {
      const formData = new FormData();
      formData.append('data', JSON.stringify({
        m_id: this.mId,
        com_id: this.comId
      }));
      formData.append('image_front', this.faceFrontImage);
      formData.append('image_left', this.faceLeftImage);
      formData.append('image_right', this.faceRightImage);
      formData.append('image_up', this.faceUpImage);
      formData.append('image_down', this.faceDownImage);
      await axios
        .put(`${this.faceBaseUrl}register-faces`, formData)
        .then(() => {
          this.editFace();
        });
    },

    async insertRegisterFace() {
      const formData = new FormData();
      formData.append('data', JSON.stringify({
        m_id: this.mId,
        com_id: this.comId
      }));
      formData.append('image', this.faceFrontImage);
      await axios
        .post(`${this.faceBaseUrl}v2/register-face`, formData)
        .then(() => {
          this.editFace();
        });
    },

    async insertRegisterMultiFace() {
      const formData = new FormData();
      formData.append('data', JSON.stringify({
        m_id: this.mId,
        com_id: this.comId
      }));
      formData.append('image_front', this.faceFrontImage);
      formData.append('image_left', this.faceLeftImage);
      formData.append('image_right', this.faceRightImage);
      formData.append('image_up', this.faceUpImage);
      formData.append('image_down', this.faceDownImage);
      await axios
        .post(`${this.faceBaseUrl}register-faces`, formData)
        .then(() => {
          this.editFace();
        });
    },

    editFace() {
      this.updateFaceStatus();
    },

    async updateFaceStatus() {
      await api
        .put(`/v1/faceverify/${this.mId}/approveFaceVerifyStatus`, {
          verifier: this.verifier,
          status: "APPROVED",
        })
    },

  },
};
</script>

<style>
.circle-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  display: flex;
}

.minus-icon {
  color: #fff;
}

.plus-icon {
  color: #007bff;
}
</style>
<template>
  <li class="dropdown_menu" :class="mainExamListCssObj">
    <a v-b-toggle.examCollapse @click.prevent="toggleCollapse">
      <b-icon-file-text-fill></b-icon-file-text-fill>
      <span class="link-title menu_hide ml-1">ข้อสอบ</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="examCollapse" :visible="false" accordion="accordion">
      <main-exam-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainExamCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import _ from "lodash";

export default {
  components: {
    MainExamCollapseMenu,
  },
  data() {
    return {
      isActive: false,
      collapseList: [
        // {
        //   title: "ภาพรวมข้อสอบ",
        //   to: {
        //     name: "analytics-overall-exams"
        //   },
        //   iconClass: "fas fa-book"
        // },
        {
          title: "จัดการข้อสอบ",
          to: {
            name: "exam.build",
          },
          iconClass: "fas fa-book",
        },
        {
          title: "สรุปภาพรวมข้อสอบ",
          to: {
            name: "analytics.exam.overview",
          },
          iconClass: "fas fa-book",
        },
        // {
        //   title: "ข้อสอบทั้งหมด",
        //   to: {
        //     name: "analytics-exams-details"
        //   },
        //   iconClass: "fas fa-book"
        // },
        {
          title: "กระดาษคำตอบ",
          to: {
            name: "analytics-answer-sheet-list",
          },
          iconClass: "fas fa-book",
        },

        {
          title: "อัปโหลดข้อสอบ",
          to: {
            name: "company.exam-management.upload",
          },
          meta: {
            module: "exam-management",
          },
          iconClass: "fas fa-book",
        },
      ],
    };
  },
  computed: {
    mainExamListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  watch: {
    // $route() {
    //     this.checkActiveRoute()
    // },
  },
  mounted() {
    // this.checkActiveRoute()
  },
  methods: {
    toggleCollapse() {
      // this.isActive = !this.isActive
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>

<template>
  <div>
    <page-header :title="'ผู้เรียนที่เข้าเรียนมากกว่า 1 จังหวัด'"></page-header>
    <layout class="m-4">
      <template v-slot:header>
        <!-- Selection -->
        <div class="pb-2">
          <b-row align-v="center">
            <b-col cols="12" md="6" class="my-2 d-flex flex-column">
              <p class="period-date-label">เริ่มต้น</p>
              <b-input
                v-model="periodDateFrom"
                type="date"
                :max="periodDateTo"
                @change="handlePeriodDateChange"
                :disabled="isLoading"
              ></b-input>
            </b-col>
            <b-col cols="12" md="6" class="d-flex flex-column my-2">
              <p class="period-date-label">สิ้นสุด</p>
              <b-input
                v-model="periodDateTo"
                type="date"
                class=""
                :max="periodDateToMax"
                @change="handlePeriodDateChange"
                :disabled="isLoading"
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="mb-3 mt-3">
            <b-col cols="6">
              <b-form inline>
                <label class="mr-sm-2" for="recent-group">กลุ่มตำแหน่ง</label>
                <b-form-select
                  id="recent-group"
                  class="mb-2 ml-lg-2 mr-sm-2 mb-sm-0 fix-length"
                  v-model="groupSelected"
                  :options="groupOptions"
                  @change="fetchProvinceLoginStat"
                ></b-form-select>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:body>
        <!-- Table -->
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            id="province-login-table"
            table-class="tbNumber text-left"
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
          >
            <template v-slot:cell(memberName)="data">
              <span>{{ data.item.memberName | cut }}</span>
            </template>
            <template v-slot:cell(groupName)="data">
              <span>{{ data.item.groupName ? data.item.groupName : "-" }}</span>
            </template>
            <template v-slot:cell(loginAmount)="data">
              <span>{{ data.item.loginAmount }}</span>
            </template>
            <template v-slot:cell(latestLoginProvince)="data">
              <span>{{
                data.item.latestLoginProvince
                  ? data.item.latestLoginProvince
                  : "-"
              }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-modal="`modal-${data.item.memberName}`"
              >
                ดูข้อมูล
              </b-button>
              <b-modal
                size="xl"
                :id="`modal-${data.item.memberName}`"
                :title="
                  `ข้อมูลการเข้าเรียนตามพื้นที่ของ ${data.item.memberName}`
                "
              >
                <province-login-modal
                  :apiRoute="
                    `/v1/analytics/students/get-student-province-learning-stats/${data.item.memberId}?from=${periodDateFrom}&to=${periodDateTo}`
                  "
                ></province-login-modal>
              </b-modal>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="items.length == 0">
            ไม่มีข้อมูล
          </div>
        </div>
        <!-- Pagination -->
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="province-login-table"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import ProvinceLoginModal from "./panel/ProvinceLoginModal.vue";
import api from "@/services/api";
import moment from "moment";
import exp from "@/services/export.js";
export default {
  components: {
    layout,
    PageHeader,
    ProvinceLoginModal
  },
  data() {
    return {
      groupSelected: "*",
      groupOptions: [],
      isLoading: false,
      fields: [
        { key: "memberName", label: "รายชือผู้เรียน" },
        { key: "groupName", label: "กลุ่มตําแหน่ง" },
        { key: "loginAmount", label: "จำนวนจังหวัด" },
        { key: "latestLoginProvince", label: "จังหวัดที่เข้าล่าสุด" },
        { key: "btn", label: "" }
      ],
      periodDateFrom: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      periodDateTo: moment().format("YYYY-MM-DD"),
      periodDateToMax: moment().format("YYYY-MM-DD"),
      items: [],
      companyInfo: {},
      currentPage: 1,
      perPage: 20
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  mounted() {
    this.setCompanyInfo();
    this.setGroupOptions();
    this.fetchProvinceLoginStat();
  },
  methods: {
    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach(i => {
        let temp = {
          name: i.memberName,
          group: i.groupName,
          login: i.loginAmount,
          lastest: i.latestLoginProvince
        };
        value.push(temp);
      });
      let key = [
        "ชื่อผู้เรียน",
        "กลุ่มตำแหน่ง",
        "จำนวนจังหวัด",
        "จังหวัดที่เข้าล่าสุด"
      ];
      let filename = "User-Province-Login-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },
    setCompanyInfo() {
      this.companyInfo = this.$store.state.Company.companyInfo;
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(
          `/v1/companies/${this.companyInfo.id}/groups`
        );

        this.groupOptions = results.data.data.map(el => {
          return { value: el.id + "", text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setGroupOptions error", err);
      }
    },
    handlePeriodDateChange() {
      this.presetPeriod = "";
      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
      this.fetchProvinceLoginStat();
    },
    async fetchProvinceLoginStat() {
      this.isLoading = true;
      try {
        const queryString = `from=${this.periodDateFrom}&to=${this.periodDateTo}&group=${this.groupSelected}`;
        let results = await api.get(
          `/v1/analytics/students/province-learning-stats?${queryString}`
        );
        this.items = results.data;
      } catch (err) {
        console.log("fetchProvinceLoginStat error!", err);
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.period-date-label {
  font-size: 15px;
  margin-bottom: 2px;
  margin-left: 2px;
}
</style>

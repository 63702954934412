export const prefixNameList = () => {
  return [
    "นาย",
    "นางสาว",
    "นาง",
    "นาวาตรี นายแพทย์",
    "พันตำรวจโท นายแพทย์",
    "พลตำรวจเอก",
    "พลตำรวจโท",
    "พลตำรวจตรี",
    "พันตำรวจเอก(พิเศษ)",
    "พันตำรวจเอก",
    "พันตำรวจโท",
    "พันตำรวจตรี",
    "ร้อยตำรวจเอก",
    "ร้อยตำรวจโท",
    "ร้อยตำรวจตรี",
    "นายดาบตำรวจ",
    "จ่าสิบตำรวจ",
    "สิบตำรวจเอก",
    "สิบตำรวจโท",
    "สิบตำรวจตรี",
    "นักเรียนนายร้อยตำรวจ",
    "นักเรียนนายสิบตำรวจ",
    "นักสิบพลตำรวจ",
    "พลตำรวจ",
    "พลตำรวจพิเศษ",
    "พลตำรวจอาสาสมัคร",
    "พลตำรวจสำรอง",
    "พลตำรวจสำรองพิเศษ",
    "พลเอก",
    "พลโท",
    "พลตรี",
    "พันเอก",
    "พันเอก แพทย์หญิง",
    "พันโท",
    "พันตรี",
    "ร้อยเอก",
    "ร้อยโท",
    "ร้อยตรี",
    "จ่าสิบเอก",
    "จ่าสิบโท",
    "จ่าสิบตรี",
    "สิบเอก",
    "สิบโท",
    "สิบตรี",
    "พลฯทหาร",
    "เรือเอกหญิง",
    "นักเรียนนายสิบ",
    "พลเรือเอก",
    "พลเรือโท",
    "พลเรือตรี",
    "นาวาเอกหญิง",
    "นาวาเอก",
    "นาวาโท",
    "นาวาตรี",
    "เรือเอก",
    "เรือโทหญิง",
    "เรือตรีหญิง",
    "พันจ่าเอก",
    "พันจ่าโท",
    "พันจ่าตรี",
    "จ่าเอก",
    "จ่าโท",
    "จ่าตรี",
    "พลฯทหารเรือ",
    "นักเรียนนายเรือ",
    "นักเรียนจ่าทหารเรือ",
    "พลอากาศเอก",
    "พลอากาศโท",
    "พลอากาศตรี",
    "นาวาอากาศเอก",
    "นาวาอากาศโท",
    "นาวาอากาศตรี",
    "เรืออากาศเอก",
    "เรืออากาศโท",
    "เรืออากาศตรี",
    "พันจ่าอากาศเอก",
    "พันจ่าอากาศโท",
    "พันจ่าอากาศตรี",
    "จ่าอากาศเอก",
    "จ่าอากาศโท",
    "จ่าอากาศตรี",
    "พลฯทหารอากาศ",
    "นักเรียนนายเรืออากาศ",
    "นักเรียนจ่าอากาศ",
    "คุณ",
    "คุณหญิง",
    "หม่อมเจ้า",
    "หม่อมราชวงศ์",
    "หม่อมหลวง",
    "นายแพทย์",
    "แพทย์หญิง",
    "ทันตแพทย์",
    "ทันตแพทย์หญิง",
    "ดอกเตอร์",
    "ศาสตราจารย์นายแพทย์",
    "เภสัชกรชาย",
    "เภสัชกรหญิง",
    "ว่าที่ ร้อยตรี",
    "พลตรีหญิง",
    "เด็กหญิง",
    "เด็กชาย",
    "ท่านผู้หญิง",
    "จ่าตรีหญิง",
    "จ่าโทหญิง",
    "จ่าสิบตรีหญิง",
    "จ่าสิบโทหญิง",
    "จ่าสิบเอกหญิง",
    "จ่าอากาศตรีหญิง",
    "จ่าอากาศโทหญิง",
    "จ่าอากาศเอกหญิง",
    "จ่าเอกหญิง",
    "นาวาตรีหญิง",
    "นาวาโทหญิง",
    "นาวาอากาศตรีหญิง",
    "นาวาอากาศโทหญิง",
    "นาวาอากาศเอกหญิง",
    "พลตำรวจตรีหญิง",
    "พลตำรวจโทหญิง",
    "พลตำรวจหญิง",
    "พลตำรวจเอกหญิง",
    "พลโทหญิง",
    "พลเรือตรีหญิง",
    "พลเรือโทหญิง",
    "พลเรือเอกหญิง",
    "พลอากาศตรีหญิง",
    "พลอากาศโทหญิง",
    "พลอากาศเอกหญิง",
    "พลเอกหญิง",
    "พันจ่าตรีหญิง",
    "พันจ่าโทหญิง",
    "พันจ่าอากาศตรีหญิง",
    "พันจ่าอากาศโทหญิง",
    "พันจ่าอากาศเอกหญิง",
    "พันจ่าเอกหญิง",
    "พันตรีหญิง",
    "พันตำรวจตรีหญิง",
    "พันตำรวจโทหญิง",
    "พันตำรวจเอกหญิง",
    "ว่าที่ พันตำรวจตรี",
    "ว่าที่ พันตำรวจตรีหญิง",
    "ว่าที่ พันตำรวจโท",
    "ว่าที่ พันตำรวจโทหญิง",
    "ว่าที่ พันตำรวจเอก",
    "ว่าที่ พันตำรวจเอกหญิง",
    "พันโทหญิง",
    "พันเอกหญิง",
    "รองศาสตราจารย์",
    "ร้อยตรีหญิง",
    "ร้อยตำรวจตรีหญิง",
    "ร้อยตำรวจโทหญิง",
    "ร้อยตำรวจเอกหญิง",
    "ว่าที่ ร้อยตำรวจตรี",
    "ว่าที่ ร้อยตำรวจตรีหญิง",
    "ว่าที่ ร้อยตำรวจโท",
    "ว่าที่ ร้อยตำรวจโทหญิง",
    "ว่าที่ ร้อยตำรวจเอก",
    "ว่าที่ ร้อยตำรวจเอกหญิง",
    "ร้อยโทหญิง",
    "ร้อยเอกหญิง",
    "เรือตรี",
    "เรือโท",
    "เรืออากาศตรีหญิง",
    "เรืออากาศโทหญิง",
    "เรืออากาศเอกหญิง",
    "ว่าที่ ร้อยตรีหญิง",
    "ว่าที่ ร้อยโท",
    "ว่าที่ ร้อยโทหญิง",
    "ว่าที่ ร้อยเอก",
    "ว่าที่ ร้อยเอกหญิง",
    "ว่าที่ พันตรี",
    "ว่าที่ พันตรีหญิง",
    "ว่าที่ พันโท",
    "ว่าที่ พันโทหญิง",
    "ว่าที่ พันเอก",
    "ว่าที่ พันเอกหญิง",
    "ศาสตราจารย์",
    "สิบตรีหญิง",
    "สิบตำรวจตรีหญิง",
    "สิบตำรวจโทหญิง",
    "สิบตำรวจเอกหญิง",
    "สิบโทหญิง",
    "สิบเอกหญิง",
    "บริษัท",
    "ผู้ช่วยศาสตราจารย์ดอกเตอร์",
    "หม่อม",
    "ห้างหุ้นส่วนจำกัด",
    "ว่าที่ เรือตรี",
    "ศาสตราจารย์ดอกเตอร์",
    "ผู้ช่วยศาสตราจารย์พันตรีหญิง",
    "ศาสตราจารย์ คุณหญิง",
    "พันเอก(พิเศษ)",
    "ผู้ช่วยศาสตราจารย์",
    "พระยา",
    "พระ",
    "ว่าที่ เรืออากาศตรี",
    "หม่อมราชวงศ์หญิง",
    "นาวาอากาศเอก(พิเศษ)",
  ];
};

<template>
  <div>
    <b-row>
      <b-col cols="7"> </b-col>
      <b-col cols="5" class="text-center">
        <b-button @click="exportData()" class="btn btn-download" href="">
          <i class="fa fa-angle-double-down" aria-hidden="true"> </i>ดาวน์โหลดรายงาน
        </b-button>
      </b-col>
    </b-row>
    <b-table
      v-if="items.length > 0"
      id="complete-rule-table"
      table-class="tbNumber text-left my-2"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      small
      sort-icon-left
      responsive
      :busy="isLoading"
      hover
    >
      <template v-slot:cell(criteriaNumber)="data">
        <span>{{ data.item.criteriaNumber }}</span>
      </template>
      <template v-slot:cell(ruleName)="data">
        <span>{{ data.item.ruleName }}</span>
      </template>
      <template v-slot:cell(rulePass)="data">
        <span :style="getExamStatusStyle(data.item.rulePass)">{{
          data.item.rulePass ? "ผ่าน" : "ไม่ผ่าน"
        }}</span>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-end" v-if="items.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="complete-rule-table"
      ></b-pagination>
    </div>
    <div class="d-flex justify-content-center" v-if="items.length <= 0">
      <span>{{ statusMessage }}</span>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  props: {
    apiRoute: String,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        { key: "criteriaNumber", label: "ลำดับที่" },
        { key: "ruleName", label: "ชื่อแบบทดสอบ" },
        { key: "rulePass", label: "สถานะ" },
      ],
      perPage: 10,
      currentPage: 1,
      statusMessage: "Loading ...",
      companyInfo: null,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.setExamResults();
    this.setCompanyInfo();
  },
  methods: {
    setCompanyInfo() {
      this.companyInfo = { ...this.$store.state.Company.companyInfo };
    },
    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach((i) => {
        let temp = {
          name: i.ruleName,
          status: i.rulePass ? "ผ่าน" : "ไม่ผ่าน",
        };
        value.push(temp);
      });
      let key = ["ชื่อแบบทดสอบ", "สถานะ"];
      let filename = "User-Individual-Complete-Rule-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    fetchExamResults() {
      return api.get(this.apiRoute);
    },
    async setExamResults() {
      this.isLoading = true;
      try {
        let results = await this.fetchExamResults();
        this.items = results.data.map((element, index) => {
          return {
            ...element,
            criteriaNumber: index + 1,
          };
        });
        if (this.items.length == 0) {
          this.statusMessage = "ไม่มีเกณฑ์การจบ";
        }
      } catch (err) {
        console.log("setExamResults error!", err);
      }
      this.isLoading = false;
    },
    getExamStatusStyle(result) {
      return result ? { color: "green" } : { color: "red" };
    },
  },
};
</script>

<style></style>

<template>
  <PageHeader title="อัปโหลดข้อสอบ"></PageHeader>
</template>
<script>
import PageHeader from "../../layouts/PageHeader.vue";
export default {
  components:{
    PageHeader
  }
}
</script>
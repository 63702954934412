<template>
  <b-card
    v-if="!examAnswerSheetPending"
    :class="$options.name"
    title="คะแนนที่ได้"
    class="text-center stscore"
  >
    <b-card-text>
      <h1>{{ examAnswerSheetData.overallResult.score }}</h1>
    </b-card-text>
    <em slot="footer"><p>คิดเป็น {{ scorePercentage }} ของทั้งหมด</p></em>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "ExamTakerDetailsOverallScore",
  computed: {
    ...mapGetters("Company", [
      "examAnswerSheetData",
      "examAnswerSheetPending",
      "examMemberData"
    ]),
    scorePercentage() {
      let percentage =
        (this.examAnswerSheetData.overallResult.score /
          this.examAnswerSheetData.overallResult.fullScore) *
        100;
      return `${percentage.toFixed(2)} %`;
    },
    member() {
      if (this.examMemberData) return this.examMemberData;
      let { email, id, name } = this.examAnswerSheetData._guest;
      name = _.compact(_.split(name, " "));
      return {
        id,
        firstName: name[0] || "",
        lastName: name[1] || "",
        displayName: "",
        username: "",
        email,
        displayPath: ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamTakerDetailsOverallScore {
  height: 280px;
}
.stscore {
  padding-top: 2em;
  h1 {
    font-size: 6em;
  }
}
</style>

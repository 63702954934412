<template>
  <div>
    <PageHeader title="จัดการคอร์สเรียน"></PageHeader>
    <b-container>
      <b-row>
        <b-col>
          <div class="py-4">
            <div class="bg-container">
              <b-row>
                <b-col cols="12" md="4">
                  <!-- This column takes up 12 columns on small screens and 6 columns on medium and larger screens -->
                  <h6 class="mb-2 font-weight-bold">ค้นหาคอร์ส</h6>
                  <search-box
                    title="พิมพ์เพื่อค้นหาคอร์ส"
                    @typed="getSearchKeyword"
                  ></search-box>
                </b-col>
                <b-col cols="12" md="8">
                  <b-row>
                    <b-col cols="12" md="3">
                      <h6 class="mb-2 font-weight-bold">สถานะ</h6>
                      <b-form-select
                        v-model="selectedStatusOption"
                        :options="status"
                        @change="onStatusOptionChanged($event)"
                      ></b-form-select>
                    </b-col>
                    <b-col cols="12" md="3">
                      <h6 class="mb-2 font-weight-bold">จัดเรียงตาม</h6>
                      <b-form-select
                        v-model="selectedSortOption"
                        :options="sortOptions"
                        @change="onSortOptionChanged($event)"
                      ></b-form-select>
                    </b-col>
                    <b-col v-if="comId == 512" cols="12" md="3">
                      <h6 class="mb-2 font-weight-bold">รูปแบบคอร์ส</h6>
                      <b-form-select
                        v-model="selectedTypeOption"
                        :options="typeOptions"
                        @change="onSortTypeChanged($event)"
                      ></b-form-select>
                    </b-col>
                    <b-col v-if="comId == 512" cols="12" md="3" align="right">
                      <p class="mb-2">&nbsp;</p>
                      <b-dropdown
                        right
                        id="dropdown-create-course"
                        text="สร้างคอร์สเรียน"
                        variant="primary"
                      >
                        <b-dropdown-item
                          @click="startCreateCourse(courseOnline)"
                        >
                          คอร์สเรียนออนไลน์
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="startCreateCourse(courseHybrid)"
                        >
                          คอร์สเรียนแบบผสมผสาน
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                    <b-col v-else cols="12" md="4">
                      <p class="mb-2">&nbsp;</p>
                      <b-button @click="startCreateCourse(courseOnline)" variant="primary">สร้างคอร์สเรียนออนไลน์</b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <p v-if="!isLoading && fetchParams.q" class="mt-2">
                ผลการค้นหา {{ courseList.meta.total }} คอร์ส
              </p>

              <div v-if="!isLoading">
                <b-row class="mt-3">
                  <b-col
                    v-for="item in courseList.data"
                    :key="item.id"
                    xl="3"
                    lg="4"
                    md="6"
                    class="py-4"
                  >
                    <b-card
                      no-body
                      header-class="text-center py-0"
                      header-bg-variant="success"
                      footer-border-variant="white"
                      footer-bg-variant="white"
                      class="h-100"
                    >
                      <div
                        class="bg-light"
                        style="min-height: 106px"
                        @click="openCourseInfoPage(item.courseId)"
                      >
                        <b-card-img-lazy
                          :src="item.courseThumbnail"
                          alt=""
                          top
                        ></b-card-img-lazy>
                      </div>

                      <div
                        class="text-center small status-bar"
                        :class="item.courseStatus | statusColor(statusOptions)"
                        role="alert"
                      >
                        {{ item.courseStatus | statusText(statusOptions) }}
                      </div>

                      <b-card-body
                        v-bind:class="{ 'py-1': isCompany([485]) }"
                        @click="openCourseInfoPage(item.courseId)"
                      >
                        <div>
                          <span
                            class="mt-2 mr-2"
                            :style="getCourseTypeStyle(item.courseOnlineType)"
                          >
                            {{
                              item.courseOnlineType == "ONLINE"
                                ? "&nbsp;คอร์สเรียนออนไลน์&nbsp;"
                                : item.courseOnlineType == "HYBRID"
                                ? "&nbsp;การเรียนแบบผสมผสาน&nbsp;"
                                : ""
                            }}
                          </span>
                        </div>
                        <div class="course-title mt-2 text-crop-2">
                          <h6>{{ item.courseName }}</h6>
                        </div>
                        <b-card-text
                          v-if="item.courseOwner"
                          v-bind:class="{ 'mb-2': isCompany([485]) }"
                        >
                          <a class="text-csq small">
                            {{ item.courseOwner.ownerName }}
                          </a>
                        </b-card-text>
                        <b-card-text v-if="isCompany([485])">
                          <span class="text-csq">
                            <div>
                              ผู้ทำบัญชี:
                              <div>
                                <span class="text-primary">บัญชี</span>
                                {{ item.tfac.accAccHour | formatTfacHour }}
                                <span class="text-primary">จรรยาบรรณ</span>
                                {{ item.tfac.accEthicHour | formatTfacHour }}
                                <span class="text-primary">อื่นๆ</span>
                                {{ item.tfac.accOtherHour | formatTfacHour }}
                              </div>
                            </div>
                            <div>
                              ผู้สอบบัญชีรับอนุญาต:
                              <div>
                                <span class="text-primary">บัญชี</span>
                                {{ item.tfac.cpaAccHour | formatTfacHour }}
                                <span class="text-primary">จรรยาบรรณ</span>
                                {{ item.tfac.cpaEthicHour | formatTfacHour }}
                                <span class="text-primary">อื่นๆ</span>
                                {{ item.tfac.cpaOtherHour | formatTfacHour }}
                              </div>
                            </div>
                          </span>
                          <div v-if="item.membershipPrice.length > 0">
                            <div
                              v-if="
                                item.membershipPrice[0].promotionPrice !== null
                              "
                            >
                              <span class="text-primary">สมาชิก:</span>
                              <span class="text-danger">
                                {{
                                  item.membershipPrice[0].promotionPrice
                                    | formatNumber
                                    | displayFree
                                }}
                              </span>
                              <del class="text-muted small">{{
                                item.membershipPrice[0].price
                                  | formatNumber
                                  | displayFree
                              }}</del>
                            </div>
                            <span v-else>
                              <span class="text-primary">สมาชิก:</span>
                              {{
                                item.membershipPrice[0].price
                                  | formatNumber
                                  | displayFree
                              }}</span
                            >
                          </div>
                          <div v-else>
                            <span class="text-primary">สมาชิก:</span> ฟรี
                          </div>
                          <div v-if="item.promotionPrice !== null">
                            <span class="text-primary">บุคคลทั่วไป:</span>
                            <span class="text-danger">
                              {{
                                item.promotionPrice | formatNumber | displayFree
                              }}
                            </span>
                            <del class="text-muted small">{{
                              item.price | formatNumber | displayFree
                            }}</del>
                          </div>
                          <span v-else
                            ><span class="text-primary">บุคคลทั่วไป:</span>
                            {{ item.price | formatNumber | displayFree }}</span
                          >
                        </b-card-text>
                      </b-card-body>
                      <template
                        v-slot:footer
                        footer-border-variant="light"
                        footer-bg-variant="light"
                      >
                        <div @click="openCourseInfoPage(item.courseId)">
                          <div v-if="!isCompany([519])">
                            <h5
                              v-if="
                                item.seasonPrice.enable === true &&
                                  !isCompany([485])
                              "
                              class="text-right"
                            >
                              <span class="small text-danger">
                                ฿{{ displayPrice(item) | formatNumber }}
                              </span>
                            </h5>
                            <div>
                              <small class="text-muted">
                                <b-icon-clock />
                                {{ item.courseLastUpdate | formatThaiDate }}
                              </small>
                              <h5
                                v-if="!isCompany([485])"
                                class="float-right text-right"
                              >
                                <div v-if="item.seasonPrice.enable === true">
                                  <del class="small">
                                    ฿{{
                                      item.price
                                        | formatNumber
                                        | displaySeasonPrice
                                    }}
                                  </del>
                                </div>
                                <div v-else>
                                  <span class="small">
                                    ฿{{ displayPrice(item) | formatNumber }}
                                  </span>
                                </div>
                              </h5>
                            </div>
                          </div>
                          <div v-else>
                            <h5
                              class="text-right"
                              v-if="item.promotionPrice >= 0 && item.promotionPrice !== null"
                            >
                              <span class="small text-danger">
                                ฿{{
                                  item.promotionPrice == 0
                                    ? "ฟรี"
                                    : (item.promotionPrice / 1.07)
                                      | formatNumber
                                }}
                              </span>
                            </h5>
                            <div>
                              <small class="text-muted">
                                <b-icon-clock />
                                {{ item.courseLastUpdate | formatThaiDate }}
                              </small>
                              <h5 class="float-right text-right">
                                <div v-if="item.promotionPrice >= 0 && item.promotionPrice !== null">
                                  <del class="small">
                                    ฿{{ (item.price / 1.07) | formatNumber }}
                                  </del>
                                </div>
                                <div v-else>
                                  <span class="small">
                                    ฿{{
                                      (item.price == 0 || item.price == null
                                        ? "ฟรี"
                                        : item.price / 1.07) | formatNumber
                                    }}
                                  </span>
                                </div>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <b-button
                            size="sm"
                            class="ml-3"
                            style="height: 29px"
                            variant="outline-secondary"
                            @click="gotoLearningDetail(item.courseId)"
                            >ข้อมูลผู้เรียน</b-button
                          >
                          <b-dropdown
                            right
                            id="dropdown-right"
                            text="จัดการ"
                            size="sm"
                            class="card-ellipsis mr-3"
                          >
                            <b-dropdown-item
                              @click="openCourseInfoPageInNewTab(item.courseId)"
                            >
                              จัดการคอร์สเรียน
                            </b-dropdown-item>

                            <b-dropdown-item
                              v-if="
                                item.courseStatus == 'PUBLISH' ||
                                  item.courseStatus == 'DISABLED'
                              "
                              @click="confirmSuspendCourse(item)"
                            >
                              หยุดเผยแพร่
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="
                                item.courseStatus == 'SUSPEND' ||
                                  item.courseStatus == 'DRAFT' ||
                                  item.courseStatus == 'PUBLISH_EDIT' ||
                                  item.courseStatus == 'DISABLED'
                              "
                              @click="confirmPublishCourse(item)"
                            >
                              เผยแพร่คอร์ส
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="item.courseStatus != 'DISABLED'"
                              @click="confirmDisableCourse(item)"
                            >
                              ปิดการเข้าถึง
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canDeleteCourse"
                              @click="confirmRemoveCourse(item)"
                            >
                              <span class="text-danger">ลบคอร์ส</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row class="mt-3">
                  <b-col
                    v-for="skeletonCourseItem in [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10,
                      11,
                      12,
                    ]"
                    :key="skeletonCourseItem"
                    xl="3"
                    lg="4"
                    md="6"
                    class="py-4"
                  >
                    <b-card
                      no-body
                      header-class="text-center py-0"
                      header-bg-variant="success"
                      footer-border-variant="white"
                      footer-bg-variant="white"
                      class="mb-4 h-100"
                    >
                      <b-skeleton-img
                        card-img="top"
                        aspect="16:9"
                      ></b-skeleton-img>
                      <b-card-body>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </b-card-body>
                      <template
                        v-slot:footer
                        footer-border-variant="light"
                        footer-bg-variant="light"
                      >
                        <b-skeleton width="20%" class="float-left"></b-skeleton>
                        <b-skeleton
                          width="10%"
                          class="float-right"
                        ></b-skeleton>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </div>

              <Pagination
                :items="courseList"
                :limits="[24, 60, 120]"
                @changePage="setFetchParamsPage"
                @changeLimit="setFetchParamsLimit"
              />
            </div>
          </div>

          <ConfirmPublishCourseModal
            :show="isShowConfirmPublishCourseModal"
            :course="selectedCourse"
            @closed="isShowConfirmPublishCourseModal = false"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import SearchBox from "../../shared/SearchBox";
// import Loading from "../../shared/Loading";
import Pagination from "../../shared/Pagination";
import ConfirmPublishCourseModal from "./partials/ConfirmPublishCourseModal";
import CourseMixin from "../../../mixins/course-mixin";
import authMixin from "@/mixins/auth-mixin.js";
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import moment from "moment";

export default {
  components: {
    // Loading,
    SearchBox,
    PageHeader,
    Pagination,
    ConfirmPublishCourseModal,
  },
  filters: {
    formatTfacHour: function(value) {
      return (
        parseInt(value) +
        ":" +
        Numeral((value % 1) * 60).format("00") +
        " ชั่วโมง"
      );
    },
    formatNumber: function(value) {
      if (!isNaN(value)) {
        return Numeral(value).format("0,0");
      } else {
        return value;
      }
    },
    displayFree: function(value) {
      if (value == 0 || value === null) {
        return "ฟรี";
      }
      return value + " บาท";
    },
    displaySeasonPrice: function(value) {
      if (value == 0 || value === null) {
        return "ฟรี";
      }
      return value;
    },
    formatThaiDate: function(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        year = year - 2500;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
  },
  mixins: [CourseMixin, authMixin],
  props: {
    company: {
      type: Object,
      default: null,
    },
    user: { default: null, type: Object },
  },

  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      selectedStatusOption: null,
      selectedSortOption: { sort_by: "updated", sort_order: "desc" },
      sortOptions: [
        {
          value: { sort_by: "updated", sort_order: "desc" },
          text: "อัปเดตล่าสุด",
        },
        {
          value: { sort_by: "name", sort_order: "asc" },
          text: "ชื่อคอร์ส",
        },
      ],
      selectedTypeOption: { type: "ALL" },
      typeOptions: [
        {
          value: { type: "ALL" },
          text: "คอร์สทุกรูปแบบ",
        },
        {
          value: { type: "ONLINE" },
          text: "คอร์สออนไลน์",
        },
        {
          value: { type: "HYBRID" },
          text: "คอร์สเรียนแบบผสมผสาน",
        },
      ],
      fetchParams: {
        company_id: null,
        page: null,
        limit: 24,
        q: null,
        type: "ALL",
        sort_by: "updated",
        sort_order: "desc",
        excepts: "numberOfStudent,numberOfActiveStudent",
      },
      isShowConfirmPublishCourseModal: false,
      selectedCourse: {},
      courseOnline: "ONLINE",
      courseHybrid: "HYBRID",
    };
  },

  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseList",
      "courseInfo",
    ]),

    status: function() {
      // Filter only unique courseStatus
      let options = this.courseList.data
        .filter(
          (item, pos, self) =>
            !item.courseStatus.includes("PUBLISH_") &&
            self.findIndex((v) => v.courseStatus === item.courseStatus) === pos
        )
        .map((obj) => {
          // Set select options object (value, text)
          return {
            value: obj.courseStatus,
            text: this.statusOptions.find(
              (data) => data.value === obj.courseStatus
            ).text,
          };
        });

      // return with first options
      return [this.statusOptions[0], ...options];
    },

    canDeleteCourse() {
      return this.hasPermissions(["delete-course"]);
    },
  },
  async mounted() {
    this.fetchParams.company_id = this.company.id;
    await this.fetchCourseList(this.fetchParams);
  },
  methods: {
    ...mapActions("CourseManagement", [
      "fetchCourseList",
      "publishCourse",
      "createCourse",
      "suspendCourse",
      "deleteCourse",
      "disableCourse",
    ]),
    isCompany(comIdArr) {
      return comIdArr.includes(this.company.id);
    },
    displayPrice(courseInfo) {
      var price;
      if (
        courseInfo.membershipPrice.length > 0 &&
        courseInfo.membershipPrice[0].price !== null &&
        Number(courseInfo.membershipPrice[0].price) < Number(courseInfo.price)
      ) {
        price = courseInfo.membershipPrice[0].price;
      } else if (
        courseInfo.seasonPrice.enable &&
        courseInfo.seasonPrice.price !== null &&
        Number(courseInfo.seasonPrice.price) < Number(courseInfo.price)
      ) {
        price = courseInfo.seasonPrice.price;
      } else if (
        courseInfo.promotionPrice !== null &&
        Number(courseInfo.promotionPrice) < Number(courseInfo.price)
      ) {
        price = courseInfo.promotionPrice;
      } else {
        price = courseInfo.price;
      }

      if (price > 0) {
        return price;
      } else {
        return "ฟรี";
      }
    },
    getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchParams.page = 1;
      this.fetchCourseList(this.fetchParams);
    },
    openCourseInfoPage(courseId) {
      this.$router.push({
        name: "course.management.info",
        params: { courseId: courseId },
      });
    },
    openCourseInfoPageInNewTab(courseId) {
      const routeData = this.$router.resolve({
        name: "course.management.info",
        params: { courseId: courseId },
      });
      window.open(routeData.href, "_blank");
    },
    onStatusOptionChanged(event) {
      this.fetchParams.status = event;
      this.fetchCourseList(this.fetchParams);
    },
    onSortOptionChanged(event) {
      this.fetchParams.sort_by = event.sort_by;
      this.fetchParams.sort_order = event.sort_order;
      this.fetchCourseList(this.fetchParams);
    },
    onSortTypeChanged(event) {
      this.fetchParams.type = event.type;
      this.fetchCourseList(this.fetchParams);
    },
    async startCreateCourse(typeName) {
      await this.createCourse({
        comId: this.company.id,
        ownerId: this.user.id,
        courseOnlineType: typeName,
      }).then(() => {
        this.$router.push({
          name: "course.management.info",
          params: {
            company: this.company.url,
            courseId: this.courseInfo.courseId,
          },
        });
      });
    },
    confirmDisableCourse(course) {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการปิดการเข้าถึงคอร์ส ${course.courseName} ใช่หรือไม่?`,
          {
            title: "ยืนยันการปิดการเข้าถึงคอร์ส",
            size: "md",
            buttonSize: "sm",
            okVariant: "info",
            okTitle: "ปิดการเข้าถึง",
            cancelTitle: "ยกเลิก",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) this._disableCourse(course.courseId);
        });
    },
    confirmSuspendCourse(course) {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการระงับการเผยแพร่คอร์ส ${course.courseName} ใช่หรือไม่?`,
          {
            title: "ยืนยันการระงับการเผยแพร่คอร์ส",
            size: "md",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "ระงับการเผยแพร่",
            cancelTitle: "ยกเลิก",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) this._suspendCourse(course.courseId);
        });
    },
    confirmRemoveCourse(course) {
      this.$bvModal
        .msgBoxConfirm(`ต้องการลบคอร์ส ${course.courseName} ใช่หรือไม่?`, {
          title: "ยืนยันการลบคอร์ส",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "ลบคอร์ส",
          cancelTitle: "ยกเลิก",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) this._removeCourse(course.courseId);
        });
    },
    confirmPublishCourse(course) {
      this.$bvModal
        .msgBoxConfirm(`ต้องการเผยแพร่คอร์ส ${course.courseName} ใช่หรือไม่?`, {
          title: "ยืนยันการเผยแพร่คอร์ส",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          okTitle: "เผยแพร่คอร์ส",
          cancelTitle: "ยกเลิก",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) this._publishCourse(course.courseId);
        });
    },
    async _disableCourse(courseId) {
      await this.disableCourse(courseId).then(() => {
        this.fetchCourseList(this.fetchParams);
      });
    },
    async _suspendCourse(courseId) {
      await this.suspendCourse(courseId).then(() => {
        this.fetchCourseList(this.fetchParams);
      });
    },
    async _removeCourse(courseId) {
      await this.deleteCourse(courseId).then(() => {
        this.fetchCourseList(this.fetchParams);
      });
    },
    async _publishCourse(courseId) {
      await this.publishCourse(courseId).then(() => {
        this.fetchCourseList(this.fetchParams);
      });
    },
    setFetchParamsPage(page) {
      this.fetchParams.page = page;
      this.fetchCourseList(this.fetchParams);
    },
    setFetchParamsLimit(limit) {
      this.fetchParams.limit = limit;
      this.fetchParams.page = 1;
      this.fetchCourseList(this.fetchParams);
    },
    gotoLearningDetail(courseId) {
      window.open(
        `/${this.company.url}/analytics/courses/${courseId}`,
        "_blank"
      );
    },
    getCourseTypeStyle(typeName) {
      if (typeName === "ONLINE") {
        return {
          backgroundColor: "#F4A460",
          borderRadius: "2px",
          color: "white",
        };
      } else {
        return {
          backgroundColor: "#BA55D3",
          borderRadius: "2px",
          color: "white",
        };
      }
    },
  },
};
</script>

<style scoped>
.status-bar {
  color: white;
}
.card-ellipsis {
  position: absolute;
  right: 0;
  border: none;
}
.card:hover {
  box-shadow: 0 0 25px -5px #9e9c9e;
  cursor: pointer;
}
.course-title {
  font-size: 1.1em;
}
.text-crop-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
del {
  text-decoration-color: #dc3545;
  text-decoration-thickness: 0.1em;
}
</style>

<template>
  <div>
    <div class="exam-setting-practice-mode my-3">
      <h6>โหมดการทำข้อสอบ
        <b-icon
          class="icon mr-1"
          icon="exclamation-circle-fill"
          aria-hidden="true"
          id="tooltip-practice-mode"
          variant="warning"
        ></b-icon>
      </h6>
      <b-tooltip
        target="tooltip-practice-mode"
        triggers="hover"
        placement="bottom-right"
        class="warning-time"
      >
        <div class="text-left m-2">
          <strong>โหมดข้อสอบ: </strong>หลังจากที่ผู้เรียนคลิกส่งข้อสอบแล้ว ระบบจะสรุปคะแนนและเกรดของผู้เรียน <br>
          <strong>โหมดแบบฝึกหัด: </strong>ระบบจะแสดงเฉลยทีละข้อ และสรุป คะแนนทั้งหมดที่ได้หลังผู้เรียนตอบคำถามครบทุกข้อแล้ว
        </div>
      </b-tooltip>
      <div class="d-flex">
        <div
          class="practice-mode left"
          @click="
            practiceMode = false;
            updateExamOption();
          "
          :class="{ active: practiceMode === false }"
        >
          <h6 class="mb-0">ข้อสอบ</h6>
        </div>
        <div
          class="practice-mode right"
          @click="
            practiceMode = true;
            updateExamOption();
          "
          :class="{ active: practiceMode === true }"
        >
          <h6 class="mb-0">แบบฝึกหัด</h6>
        </div>
      </div>
    </div>
    <hr>

    <div class="exam-setting-answer-required my-3">
      <div>
        <h6>
          บังคับให้ตอบทุกข้อ
          <b-icon
            class="icon mr-1"
            icon="exclamation-circle-fill"
            aria-hidden="true"
            id="tooltip-required"
            variant="warning"
          ></b-icon>
        </h6>
        <b-tooltip
          target="tooltip-required"
          triggers="hover"
          placement="bottom-right"
          class="warning-time"
        >
          <div class="text-left m-2">
            ผู้เรียนจะต้องตอบคำถามให้ครบทุกข้อ <br />
            ถีงจะสามารถส่งข้อสอบได้
          </div>
        </b-tooltip>
      </div>

      <div class="d-flex">
        <div
          class="practice-mode left"
          @click="
            isAllAnswersRequired = false;
            updateExamOption();
          "
          :class="{ active: isAllAnswersRequired === false }"
        >
          <h6 class="mb-0">ปิด</h6>
        </div>
        <div
          class="practice-mode right"
          @click="
            isAllAnswersRequired = true;
            updateExamOption();
          "
          :class="{ active: isAllAnswersRequired === true }"
        >
          <h6 class="mb-0">เปิด</h6>
        </div>
      </div>
    </div>
    <hr />

    
      <div class="exam-setting-answer-required my-3">
        <div>
          <h6>
            จำนวนครั้งที่ทำแบบทดสอบ
          </h6>
        </div>
        <div style="display: flex;">
          <div class="d-flex">
            <div
              class="practice-mode left"
              @click="
              examAccessLimit = false;
              handleExamAccessLimit();
            "
              :class="{ active: examAccessLimit === false }"
            >
              <h6 class="mb-0">ไม่จำกัด</h6>
            </div>
            <div
              class="practice-mode right"
              @click="
              examAccessLimit = true;
              handleExamAccessLimit();
            "
              :class="{ active: examAccessLimit === true }"
            >
              <h6 class="mb-0">จำกัด</h6>
            </div>
          </div>


          <div v-if="examAccessLimit" class="ml-5 ">
            <input
              type="number"
              v-model="examAccessLimitCount"
              @blur="inputExamAccessLimit"
              :class="{'is-invalid': isInvalid}"
              class="exam-access-limit"
            />
            <span> ครั้ง</span>
            <div
              v-if="isInvalid"
              class="text-danger"
              style="font-size: 0.8rem; margin-top: 2px;"
            >
              จำกัดจำนวนครั้งที่ทำแบบทดสอบไม่เกิน 100 ครั้ง
            </div>
          </div>
        </div>
      </div>

      

    <hr />

    <div class="exam-setting-checkbox my-3">
      <b-form-checkbox
        id="checkbox-2"
        v-model="RandomChoice"
        @change="updateExamOption()"
      >
        สลับตัวเลือกตอนทำแบบทดสอบ
      </b-form-checkbox>
    </div>
    <hr />
    <div class="mb-4" style="display: flex;">
      <div style="margin: auto 0;">
        ตั้งค่าหน้าสรุปหลังส่งคำตอบ
      </div>
      
      <b-button style="margin-left: 20px;" v-b-modal="'example-modal'">ตัวอย่าง</b-button>
    </div>

    <div class="exam-setting-result my-3 mb-4">
      <div>
        <h6>
          แสดงภาพรวมคะแนน
          <b-icon
            class="icon mr-1"
            icon="exclamation-circle-fill"
            aria-hidden="true"
            id="tooltip-hasResultSectionTable"
            variant="warning"
          ></b-icon>
        </h6>
        <b-tooltip
          target="tooltip-hasResultSectionTable"
          triggers="hover"
          placement="bottom-right"
          class="warning-time"
        >
          <div class="text-left m-2">
            ผู้เรียนจำเป็นจะต้องตอบคำถามให้ครบทุกข้อ ถึงจะสามารถส่งข้อสอบได้
          </div>
        </b-tooltip>
      </div>

      <div class="d-flex">
        <div
          class="practice-mode left"
          @click="
            hasResultSectionTable = false;
            updateExamOption();
          "
          :class="{ active: hasResultSectionTable === false }"
        >
          <h6 class="mb-0">ปิด</h6>
        </div>
        <div
          class="practice-mode right"
          @click="
            hasResultSectionTable = true;
            updateExamOption();
          "
          :class="{ active: hasResultSectionTable === true }"
        >
          <h6 class="mb-0">เปิด</h6>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <div class="exam-setting-result my-3 mr-5">
        <div>
          <h6>
            แสดงรายละเอียดคะแนนและคำตอบ
            <b-icon
              class="icon mr-1"
              icon="exclamation-circle-fill"
              aria-hidden="true"
              id="tooltip-hasResultQuestionTable"
              variant="warning"
            ></b-icon>
          </h6>
          <b-tooltip
            target="tooltip-hasResultQuestionTable"
            triggers="hover"
            placement="bottom-right"
            class="warning-time"
          >
            <div class="text-left m-2">
              หลังส่งกระดาษคำตอบ ระบบจะแสดงตารางรายละเอียด คะแนน และผลตรวจคำตอบของผู้เรียน
            </div>
          </b-tooltip>
        </div>

        <div class="d-flex">
          <div
            class="practice-mode left"
            @click="
              hasResultQuestionTable = false;
              updateExamOption();
            "
            :class="{ active: hasResultQuestionTable === false }"
          >
            <h6 class="mb-0">ปิด</h6>
          </div>
          <div
            class="practice-mode right"
            @click="
              hasResultQuestionTable = true;
              updateExamOption();
            "
            :class="{ active: hasResultQuestionTable === true }"
          >
            <h6 class="mb-0">เปิด</h6>
          </div>
        </div>
      </div>

      <div class="exam-setting-result my-3">
        <div>
          <h6>
            แสดงเฉลยและคำอธิบาย
            <b-icon
              class="icon mr-1"
              icon="exclamation-circle-fill"
              aria-hidden="true"
              id="tooltip-hasSolutionAfterSubmission"
              variant="warning"
            ></b-icon>
          </h6>
          <b-tooltip
            target="tooltip-hasSolutionAfterSubmission"
            triggers="hover"
            placement="bottom-right"
            class="warning-time"
          >
            <div class="text-left m-2">
              หลังส่งกระดาษคำตอบ ระบบจะแสดงตารางรายละเอียดคะแนน และเฉลยข้อถูกแต่ละข้อ พร้อมคำอธิบายเฉลย(ถ้ามี)
            </div>
          </b-tooltip>
        </div>

        <div :class="['d-flex', { disabled: isDisabled }]">
          <div
            class="practice-mode left"
            @click="
              hasSolutionAfterSubmission = false;
              updateExamOption();
            "
            :class="{ active: hasSolutionAfterSubmission === false }"
          >
            <h6 class="mb-0">ปิด</h6>
          </div>
          <div
            class="practice-mode right"
            @click="
              hasSolutionAfterSubmission = true;
              updateExamOption();
            "
            :class="{ active: hasSolutionAfterSubmission === true }"
          >
            <h6 class="mb-0">เปิด</h6>
          </div>
        </div>
      </div>
    </div>

    <ExamToLecture />
    <ExamRandonQuestion />
    <LimitTime />


    <!-- Modal -->
    <b-modal id="example-modal" hide-footer title="ตัวอย่าง" size="lg" centered >
      <img :src="imageExampleModalUrl1" style="width: 100%;">
    </b-modal>
  </div>
</template>

<script>
import LimitTime from "./SettingLimitTime.vue";
import ExamToLecture from "./SettingExamToLecture.vue";
import ExamRandonQuestion from "./SettingRandomQuestion.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      RandomChoice: "",
      token: localStorage.getItem("token"),
      practiceMode: "",
      hasSolutionAfterSubmission: "",
      hasResultSectionTable: "",
      hasResultQuestionTable: "",
      isAllAnswersRequired: "",
      examAccessLimit: "",
      examId: this.$route.params.quizExamId,
      isDisabled: true,
      imageExampleModalUrl1: "https://s1.coursesquare.co/data/csexam/images/exam_management/exam_solution_sample.png",
      examAccessLimitCount: 3,
      isInvalid: false,
    };
  },

  components: {
    LimitTime,
    ExamToLecture,
    ExamRandonQuestion,
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
    request() {
      return {
        body: {
          isSkippable: !this.practiceMode,
          isRandomChoice: this.isRandomChoice,
          isAllAnswersRequired: this.isAllAnswersRequired,
          hasResultSectionTable: this.hasResultSectionTable,
          hasResultQuestionTable: this.hasResultQuestionTable,
          examAccessLimit: (this.examAccessLimit ? this.examAccessLimitCount : null),
          solution : {
            hasSolutionAfterSubmission: (this.hasResultQuestionTable && this.hasSolutionAfterSubmission),
            hasSolutionAfterEachQuestion: this.practiceMode,
          }
        },
        token: this.token,
        id: this.exam.id,
      }
    }
  },
  async mounted() {
    this.RandomChoice = this.exam._options.isRandomChoice;

    this.practiceMode = this.exam._options.solution
      ? this.exam._options.solution.hasSolutionAfterEachQuestion == true
      : false;

    this.hasSolutionAfterSubmission = this.exam._options.solution
      ? this.exam._options.solution.hasSolutionAfterSubmission == true
      : false;

    this.hasResultSectionTable =
      this.exam._options.hasResultSectionTable !== false;
    this.hasResultQuestionTable =
      this.exam._options.hasResultQuestionTable !== false;
    this.isDisabled = this.exam._options.hasResultQuestionTable == false;

    this.isAllAnswersRequired =
      this.exam._options.isAllAnswersRequired !== false;

    this.examAccessLimit = !!this.exam._options.examAccessLimit;

    this.examAccessLimitCount = !this.exam._options.examAccessLimit
      ? 3
      : this.exam._options.examAccessLimit;

    this.fetchCourseAndModuleByExamId();
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "updateOptions",
      "updateExamAccessLimit"
    ]),

    ...mapActions("CourseManagement", ["getCourseAndModuleByExamId"]),

    async updateExamOption() {
      this.isDisabled = !this.hasResultQuestionTable
      await this.updateOptions(this.request);
    },
   
    async handleExamAccessLimit() {
      await this.updateOptions(this.request);
      await this.updateExamAccessLimit(this.exam.id)
    },

    async fetchCourseAndModuleByExamId() {
      await this.getCourseAndModuleByExamId(this.examId);
    },

    inputExamAccessLimit() {
      const value = Number(this.examAccessLimitCount);
      this.isInvalid = value < 1 || value > 100;

      if(!this.isInvalid) {
        this.handleExamAccessLimit()
      }
    },
  },
};
</script>

<style lang="scss">
.exam-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.exam-setting-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: green !important;
  background-color: green !important;
}

.exam-checkbox .custom-control-input[disabled] ~ .custom-control-label::before,
.exam-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff;
}
.practice-mode {
  padding: 6px 20px;
  color: #525252;
  border: 1px solid rgba(82, 82, 82, 0.2);
  height: calc(1.5em + 0.75rem + 2px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.access-mode {
  padding: 6px 20px;
  color: #a1a1a1;
  border: 1px solid rgba(82, 82, 82, 0.1);
  height: calc(1.5em + 0.75rem + 2px);
  background-color: #f0f0f0;
  cursor: not-allowed;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.access-mode.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.access-mode.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px solid #707070;
}

.exam-access-limit {
  width: 100px;
  padding: 6px 15px;
  color: #525252;
  border: 1px solid rgba(82, 82, 82, 0.2);
  height: calc(1.5em + 0.75rem + 2px);
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.is-invalid {
  border-color: red;
}

.practice-mode.active {
  background: #007bff;
  color: #fff;
}

.practice-mode.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.practice-mode.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px solid #707070;
}

.d-flex.disabled .practice-mode {
  pointer-events: none;
  opacity: 0.6;
}
</style>

<template>
  <div class="inner">
    <h5 class="mt-3 font-weight-bold">เกรด</h5>
    <div
      v-for="_globalCriteria in exam._globalCriteria"
      :key="_globalCriteria.index"
      class="grading-bg"
      v-bind:class="{ isError: _globalCriteria.isError }"
    >
      <ul>
        <li
          id="abc"
          class="button-grading"
          :class="_globalCriteria.isCollapse ? 'not-collapsed' : 'collapsed'"
          :aria-expanded="_globalCriteria.isCollapse ? 'true' : 'false'"
          :aria-controls="_globalCriteria.grade"
        >
          <div
            class="text-left font-weight-bold ml-2"
            @click="TOGGLE_COLLAPSE(_globalCriteria)"
          >
            {{ _globalCriteria.grade }} |
            {{ _globalCriteria.lowerBoundRange }} -
            {{ _globalCriteria.upperBoundRange }} คะแนน
          </div>
          <div class="text-right">
            <b-button
              v-if="exam._globalCriteria.length > 2"
              variant="danger"
              size="sm"
              @click="deleteGrading(_globalCriteria.index)"
            >
              <b-icon icon="trash"></b-icon
            ></b-button>
            <span class="when-opened">
              <i
                class="fa fa-chevron-down"
                aria-hidden="true"
                @click="TOGGLE_COLLAPSE(_globalCriteria)"
              ></i
            ></span>
          </div>
          <b-collapse
            :id="_globalCriteria.grade"
            v-model="_globalCriteria.isCollapse"
            class="font-weight-bold"
          >
            <div class="my-3 ml-2">
              <label>ชื่อเกรด</label>
              <b-form-input
                type="text"
                class="col-4 mb-3"
                :value="gradeTitle"
                :formatter="formatter"
                v-model="_globalCriteria.grade"
                @keyup="updateGrading()"
              ></b-form-input>
              ช่วงของคะแนน
              <b-form inline class="mt-2">
                <b-form-input
                  type="text"
                  class="col-1 mr-3"
                  v-model="_globalCriteria.lowerBoundRange"
                  @keyup="updateGrading()"
                  :formatter="formatterNumber"
                ></b-form-input>
                ถึง
                <b-form-input
                  type="text"
                  class="col-1 mx-3"
                  v-model="_globalCriteria.upperBoundRange"
                  @keyup="updateGrading()"
                  :formatter="formatterNumber"
                ></b-form-input>
                คะแนน
              </b-form>
            </div>
            <div class="my-3 ml-2">
              <label>ใส่คำอธิบายสำหรับเกรดนี้</label><br />
              <div cols="12" sm="6" lg="3" v-if="_globalCriteria.message">
                <label style="color: #aba9a7; margin-top: 20px">{{
                _globalCriteria.message,
                }}</label>
                <b-button
                  @click="modalPreviewMessage(_globalCriteria)"
                  class="create-new-link-button ml-2"
                  variant="outline-primary "
                  size="sm"
                >
                  preview
                </b-button>

                <b-button
                  @click="modalMessage(_globalCriteria)"
                  class="create-new-link-button ml-2"
                  variant="primary"
                  size="sm"
                >
                  <b-icon icon="pencil-square" size="sm"></b-icon>
                  แก้ไข
                </b-button>
              </div>
              <div cols="12" sm="6" lg="3" v-else>
                <b-button
                  @click="modalMessage(_globalCriteria)"
                  class="create-new-link-button mt-2"
                  variant="primary"
                  size="sm"
                >
                  เพิ่มคำอธิบาย
                </b-button>
              </div>
            </div>
          </b-collapse>
        </li>
      </ul>
    </div>
    <div cols="12" sm="6" lg="3">
      <b-button
        @click="addNewGrading()"
        class="create-new-link-button mt-2"
        variant="primary"
        size="sm"
      >
        <b-icon size="lg" icon="plus"></b-icon>
        เพิ่มเกรด
      </b-button>
    </div>
    <b-modal
      :title="titleModal"
      size="xl"
      centered
      :hide-footer="true"
      class="mt-5"
      ref="modal-description"
      v-model="addStatusMessage"
    >
      <div>
        <ModelAddMessage
          :globalCriteria="globalCriteria"
          @hideModal="hideModal($event)"
          @onSave="onUpdateMessage($event)"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="ModalPreviewChoices"
      title="Preview"
      size="lg"
      :hide-footer="true"
      ref="modal-preview"
    >
      <div>
        <Markdown :message="previewMessage" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModelAddMessage from "../modal/ModelAddMessage.vue";
import Markdown from "@/components/shared/Markdown.vue";
export default {
  props: {
    gradeTitle: String,
    lowerRange: Number,
    upperRange: Number,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      nextGrade: null,
      addStatusMessage: false,
      ModalPreviewChoices: false,
      titleModal: "",
      globalCriteria: [],
      previewMessage: "",
    };
  },

  async mounted() {},
  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
  },
  components: {
    ModelAddMessage,
    Markdown,
  },
  methods: {
    formatter(gradeTitle) {
      return gradeTitle.toUpperCase();
    },
    formatterNumber(score) {
      if (!!score && Math.abs(score) >= 0) {
        return Math.abs(score);
      } else {
        return null;
      }
    },
    hideModal() {
      this.$refs["modal-description"].hide();
    },
    ...mapActions("ExamBuilder", [
      "updateExamInfo",
      "addglobalCriteria",
      "deleteglobalCriteria",
      "updateGlobalCriteria",
      "findExamsbyId",
    ]),
    ...mapMutations("ExamBuilder", ["TOGGLE_COLLAPSE"]),
    async addNewGrading() {
      let Grade = [];
      let ArrayGrade = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      const indexGrade = Math.max.apply(
        Math,
        this.exam._globalCriteria.map((globalCriteria) => globalCriteria.index)
      );

      for (let j = 0; j <= ArrayGrade.length; j++) {
        if (
          this.exam._globalCriteria[this.exam._globalCriteria.length - 1]
            .grade === ArrayGrade[j]
        ) {
          Grade.push(ArrayGrade[j + 1]);
        }
      }
      console.log(
        this.exam._globalCriteria[this.exam._globalCriteria.length - 1].index
      );

      let requestUpdate = {
        body: {
          lowerBoundRange:
            Math.floor(
              this.exam._globalCriteria[this.exam._globalCriteria.length - 1]
                .upperBoundRange / 2
            ) + 1,
          upperBoundRange: this.exam._globalCriteria[
            this.exam._globalCriteria.length - 1
          ].upperBoundRange,
          grade: this.exam._globalCriteria[this.exam._globalCriteria.length - 1]
            .grade,
          message: this.exam._globalCriteria[
            this.exam._globalCriteria.length - 1
          ].message,
        },
        index: this.exam._globalCriteria[this.exam._globalCriteria.length - 1]
          .index,
        id: this.exam.id,
        token: this.token,
        checkUpdateOfDelete: false,
      };
      await this.updateGlobalCriteria(requestUpdate);

      let request = {
        body: {
          lowerBoundRange: 0,
          upperBoundRange: Math.floor(
            this.exam._globalCriteria[this.exam._globalCriteria.length - 1]
              .upperBoundRange / 2
          ),
          grade: Grade,
          message: null,
          index: indexGrade + 1,
        },
        id: this.exam.id,
        token: this.token,
      };
      console.log(request);
      this.addglobalCriteria(request);

      this.fetch();
    },
    async deleteGrading(gradeIndex) {
      for (let i = 0; i < this.exam._globalCriteria.length; i++) {
        const element = this.exam._globalCriteria[i];
        if (element.index === gradeIndex) {
          if (this.exam._globalCriteria[0].index === gradeIndex) {
            let request = {
              body: {
                lowerBoundRange: this.exam._globalCriteria[i + 1]
                  .lowerBoundRange,
                upperBoundRange: 100,
                grade: this.exam._globalCriteria[i + 1].grade,
                message: this.exam._globalCriteria[i + 1].message,
              },
              index: this.exam._globalCriteria[i + 1].index,
              id: this.exam.id,
              token: this.token,
              arrayindex: i + 1,
              checkUpdateOfDelete: true,
            };
            await this.updateGlobalCriteria(request);
          } else if (this.exam._globalCriteria[i + 1] === undefined) {
            let request = {
              body: {
                lowerBoundRange: 0,
                upperBoundRange: this.exam._globalCriteria[i - 1]
                  .upperBoundRange,
                grade: this.exam._globalCriteria[i - 1].grade,
                message: this.exam._globalCriteria[i - 1].message,
              },
              index: this.exam._globalCriteria[i - 1].index,
              id: this.exam.id,
              token: this.token,
              arrayindex: i - 1,
              checkUpdateOfDelete: true,
            };
            await this.updateGlobalCriteria(request);
          } else {
            let request = {
              body: {
                lowerBoundRange: this.exam._globalCriteria[i + 1]
                  .lowerBoundRange,
                upperBoundRange:
                  this.exam._globalCriteria[i - 1].lowerBoundRange - 1,
                grade: this.exam._globalCriteria[i + 1].grade,
                message: this.exam._globalCriteria[i + 1].message,
              },
              index: this.exam._globalCriteria[i + 1].index,
              id: this.exam.id,
              token: this.token,
              arrayindex: i + 1,
              checkUpdateOfDelete: true,
            };
            await this.updateGlobalCriteria(request);
          }
        }
      }
      let requestDelete = {
        id: this.exam.id,
        token: this.token,
        index: gradeIndex,
      };
      this.deleteglobalCriteria(requestDelete);
    },

    modalPreviewMessage(_globalCriteria) {
      this.ModalPreviewChoices = true;
      this.previewMessage = _globalCriteria.message;
    },

    modalMessage(_globalCriteria) {
      if (!_globalCriteria.message) {
        this.titleModal = "เพิ่มคำอธิบาย";
      } else {
        this.titleModal = "แก้ไขคำอธิบาย";
      }
      this.addStatusMessage = true;
      this.globalCriteria = _globalCriteria;
    },
    fetch() {
      this.findExamsbyId(this.exam.id);
    },
    async onUpdateMessage(textMessage) {
      let request = {
        body: {
          lowerBoundRange: textMessage.data.lowerBoundRange,
          upperBoundRange: textMessage.data.upperBoundRange,
          grade: textMessage.data.grade,
          message: textMessage.message,
        },
        index: textMessage.data.index,
        id: this.exam.id,
        token: this.token,
        checkUpdateOfDelete: false,
      };
      await this.updateGlobalCriteria(request);
      this.fetch();
    },
    validateLowerCriteria(value, index) {
      const min = Number(this.exam._globalCriteria[index + 1].upperBoundRange);
      if (Number(value) <= min) {
        this.exam._globalCriteria[index].lowerBoundRange = min + 1;
        // event.preventDefault();
        return false;
      }
    },
    validateUpperCriteria() {},

    async updateGradeCriterias() {
      for (let i = 0; i <= this.exam._globalCriteria.length - 1; i++) {
        let request = {
          body: {
            lowerBoundRange: this.exam._globalCriteria[i].lowerBoundRange,
            upperBoundRange: this.exam._globalCriteria[i].upperBoundRange,
            grade: this.exam._globalCriteria[i].grade,
            message: this.exam._globalCriteria[i].message,
          },
          index: this.exam._globalCriteria[i].index,
          id: this.exam.id,
          token: this.token,
          arrayindex: i,
        };
        await this.updateGlobalCriteria(request);
      }
    },
    updateGrading() {
      this.exam._globalCriteria.every((item, index) => {
        //first criteria
        if (index === 0) {
          if (Number(item.upperBoundRange) === 100) {
            this.exam._globalCriteria[index].isError = false;
            return true;
          } else if (Number(item.upperBoundRange) > 100) {
            this.exam._globalCriteria[index].isError = true;
            return false;
          } else if (Number(this.exam._globalCriteria[index].lowerBoundRange)) {
            this.exam._globalCriteria[index].isError = true;
            return false;
          }
        }
        //last criteria
        else if (index === this.exam._globalCriteria.length - 1) {
          if (
            Number(item.upperBoundRange) + 1 !==
            Number(this.exam._globalCriteria[index - 1].lowerBoundRange)
          ) {
            this.exam._globalCriteria[index - 1].isError = true;
            this.exam._globalCriteria[index].isError = true;
            return false;
          }
          if (Number(this.exam._globalCriteria[index].lowerBoundRange) !== 0) {
            this.exam._globalCriteria[index].isError = true;
            return false;
          } else {
            let arraycheckgrade = [],
              booleancheckgrade = false;
            for (let i = 0; i < this.exam._globalCriteria.length; i++) {
              arraycheckgrade.push(this.exam._globalCriteria[i].grade);
            }

            for (let j = 0; j < arraycheckgrade.length; j++) {
              for (let k = arraycheckgrade.length - 1; k > j; k--) {
                if (arraycheckgrade[j] === arraycheckgrade[k]) {
                  this.exam._globalCriteria[j].isError = true;
                  this.exam._globalCriteria[k].isError = true;
                  return false;
                } else {
                  this.exam._globalCriteria[j].isError = false;
                  this.exam._globalCriteria[k].isError = false;
                  booleancheckgrade = true;
                }
              }
            }
            if (booleancheckgrade) {
              this.updateGradeCriterias();
            }
            return true;
          }
        }
        //other
        else if (
          Number(item.upperBoundRange) + 1 ===
          Number(this.exam._globalCriteria[index - 1].lowerBoundRange)
        ) {
          this.exam._globalCriteria[index].isError = false;
          return true;
        } else {
          //else condition
          this.exam._globalCriteria[index - 1].isError = true;
          this.exam._globalCriteria[index].isError = true;
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.not-collapsed .when-opened i {
  /* display: none; */
  transform: rotate(180deg);
  cursor: pointer;
}

.when-opened {
  padding: 2px 10px;
  cursor: pointer;
}

.b-collapse-grading {
  cursor: pointer;
}

.create-new-link-button {
  padding: 8px 25px;
}

.grading-bg {
  background-color: #f0f0f0;
  list-style: none;
  padding: 15px 15px;
  border-radius: 10px;
  margin: 10px 0;
  cursor: pointer;
  /* min-height: 100vh; */
}

input[type="text"] {
  background-color: #f0f0f0;
}

.button-grading {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px;
  justify-content: left;
  align-items: center;
}
.isError {
  border: 2px solid red !important;
}
</style>

<template>
  <div>
    <page-header
      :title="`ประกาศนียบัตรของ ${
        memberInfo ? memberInfo.firstName + ' ' + memberInfo.lastName : ''
      }`"
    ></page-header>
    <layout class="m-4">
      <template v-slot:header> </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.courseName }}</span>
            </template>
            <template v-slot:cell(courseDuration)="data">
              <span>{{ data.item.courseDuration | thaiDurationFilter }}</span>
            </template>
            <template v-slot:cell(timeSpent)="data">
              <template>
                <span>{{ data.item.timeSpent | thaiDurationFilter }}</span>
              </template>
            </template>
            <template v-slot:cell(certificateIssueDate)="data"
              ><span>{{
                data.item.certificateIssueDate | thaiDateFilter
              }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <!-- multiple certificate  -->
              <b-dropdown
                v-if="data.item.accounting.length > 1"
                id="dropdown-certificate"
                text="ดาวน์โหลด"
                variant="outline-primary"
                size="sm"
                class="h5 text-center"
              >
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <b-icon
                      icon="download"
                      variant="primary"
                      class="h6 text-center download-icon-style"
                    ></b-icon>
                    <span class="ml-2">ดาวน์โหลด</span>
                  </div>
                </template>

                <b-dropdown-item @click="downloadIconClicked(data.item.courseId)">
                  ดาวน์โหลดทั้งหมด
                </b-dropdown-item>

                <!-- Loop through accounting items -->
                <b-dropdown-item
                  v-for="(accountingItem, index) in data.item.accounting"
                  :key="index"
                  @click="downloadIconClicked(data.item.courseId, accountingItem.certificateIssueId)"
                >
                  {{
                    getCertificateType(accountingItem.certificateIssueType)
                  }}
                </b-dropdown-item>
              </b-dropdown>
              <!-- certificate single-->
              <b-icon
                v-else
                icon="download"
                variant="primary"
                class="h5 text-center download-icon-style"
                @click="downloadIconClicked(data.item.courseId,data.item.certificateIssueId)"
              ></b-icon>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="recent-study-table"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import api from "@/services/api.js";
import _ from "lodash";
import exp from "@/services/export.js";
import { openURL } from "@/components/shared/NewTabLink.js";
export default {
  components: {
    PageHeader,
    layout,
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      memberId: null,
      memberInfo: null,
      companyInfo: null,
      isLoading: false,
      fields: [
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "courseDuration", label: "ความยาวคอร์ส" },
        { key: "timeSpent", label: "เวลาที่ใช้" },
        { key: "certificateIssueDate", label: "วันที่ออกใบ" },
        { key: "btn", label: "ดาวน์โหลด" },
      ],
      items: [],
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.setMemberId();
    this.setMemberInfo();
    this.setCompanyInfo();
    this.fetchMemberRecentStudies();
  },
  methods: {
    async exportData() {
      let value = [];
      let set = this.items;
      var ctr = 0;
      set.forEach(async (i) => {
        let results = await api.get(
          `/v1/members/${this.memberId}/course/${i.courseId}/certificate`
        );
        let temp = {
          courseName: i.courseName,

          duration: this.thaiDurationFilter(i.courseDuration),
          timeSpent: this.thaiDurationFilter(i.timeSpent),
          date: this.thaiDateFilter(i.certificateIssueDate),
          rule: results.data && results.data.domain + results.data.link,
        };
        value.push(temp);
        ctr++;

        if (ctr == this.items.length) {
          let key = [
            "คอร์สเรียน",
            "ความยาวคอร์ส",
            "เวลาที่ใช้",
            "วันที่ออกใบ",
            "ดาวน์โหลด",
          ];
          let filename = "User-Individual-Certificate-" + this.companyInfo.url;

          const data = {
            key: key,
            filename: filename,
            data: value,
          };
          if (data.data.length > 0) await exp.exportData(data);
          else console.log("NO DATA");
        }
      });
    },
    thaiDurationFilter(value) {
      if (value) {
        let [hourString, minuteString, secondString] = value.split(":");
        let hour = parseInt(hourString, 10);
        let minute = parseInt(minuteString, 10);
        let second = parseInt(secondString, 10);
        if (hour === 0) {
          if (minute === 0) {
            if (second === 0) {
              return `-`;
            } else {
              return `${second} วินาที`;
            }
          } else {
            return `${minute} นาที`;
          }
        } else {
          return `${hour} ชั่วโมง`;
        }
      }
      return `-`;
    },
    thaiDateFilter(value) {
      if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    setMemberId() {
      this.memberId = this.$route.params.mId;
    },
    async setMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
    setCompanyInfo() {
      this.companyInfo = { ...this.$store.state.Company.companyInfo };
    },
    async fetchMemberRecentStudies() {
      this.isLoading = true;
      try {
        const results = await api.get(
          `/v1/members/${this.memberId}/certificates`
        );
        this.items = _.orderBy(
          results.data.data,
          ["certificateIssueDate"],
          ["desc"]
        );
      } catch (err) {
        console.log("fetchMemberRecentStudies error", err);
      }
      this.isLoading = false;
    },
    async downloadIconClicked(id, certisId = "") {
      try {
        let results = await api.get(
          `/v1/members/${this.memberId}/course/${id}/certificate?certisId=${certisId}`
        );
        let link = "";
        if (results.data.length == 2) {
          for (let i = 0; i < results.data.length; i++) {
            if (this.companyInfo.whitelabelUrl)
              link = this.companyInfo.whitelabelUrl + results.data[i].link;
            else link = results.data[i].domain + results.data[i].link;
            openURL(link);
          }
        } else {
          if (this.companyInfo.whitelabelUrl)
            link = this.companyInfo.whitelabelUrl + results.data.link;
          else link = results.data.domain + results.data.link;
          openURL(link);
        }
      } catch (err) {
        console.log("downloadIconClicked error", err);
      }
    },

    getCertificateType(type) {
      switch (type) {
        case 'CPD_COMPLETED':
          return 'CPD';
        case 'CPA_COMPLETED':
          return 'CPA';
        case 'COMPLETED':
          return 'COMPLETED';
        default:
          return 'Unknown';
      }
    },
  },
};
</script>

<style scoped>
.download-icon-style:hover {
  cursor: pointer;
}
</style>

<template>
  <div>
    <b-container>
      <div class="outer">
        <div class="inner bg-container">
          <div class="card card-csq no-shadow rounded-0" style="margin-bottom: 50px;">
            <div class="card-header">
              <router-link
                :to="{ name: 'members.face-verification.index' }"
                class="text-csq lead"
              >
                <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
              </router-link>
              <span class="lead"> การตรวจสอบใบหน้าผู้เรียน </span>
            </div>
            <div class="card-body mx-4">
              <b-row>
                <b-col cols="12">
                  <MemberInformation />
                </b-col>
                <b-col cols="12">
                  <MemberFaceApprove
                    :memberInfo="memberInfo"
                    :courseInfo="courseInfo"
                    :learningFaceItem="learningFaceItem"
                    @fetchLearningFaceApprove="fetchLearningFaceApprove"
                    @fetch="fetch"
                    @fetchCouseInfo="fetchCouseInfo"
                    @approveAllLecture="approveAllLecture"
                    @openModal="addModalConfirmAll = true"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <b-row>
          <b-col cols="6">
            <h4><span style="color: #28A745; font-size: 18px;">ตรวจสอบแล้ว</span> <strong>{{ approvePercent.toFixed(2) }}%</strong></h4>
          </b-col>
          <b-col cols="6" style="display: flex; justify-content: right;">
            <div v-if="isSave" class="mr-4">
              <span style="color: #28A745;">
                <i class="fa-solid fa-floppy-disk" style="color: #28a475;"></i>
                บันทึกการเปลี่ยนแปลงแล้ว
              </span>
            </div>
            <div>
              <b-button
                :disabled="
                  memberInfo.cer_approveFaceStatus === 'APPROVED' ||
                    memberInfo.cer_status != 'COMPLETED'
                "
                @click="addModalConfirmAll = true"
                class="px-2"
                size="sm"
                variant="success"
              >
                <b-icon
                  class="mr-3"
                  icon="check2-all"
                  aria-hidden="true"
                ></b-icon>
                ยืนยันผลการตรวจใบหน้าของคอร์สนี้
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-modal
          v-model="addModalConfirmAll"
          centered
          :hide-header="true"
          :hide-footer="true"
          id="modal-confirm-all"
          ref="modal-confirm-all"
        >
          <div class="my-3">
            <div class="icon text-center py-4">
              <b-icon
                icon="exclamation-triangle"
                variant="warning"
                scale="5"
              ></b-icon>
            </div>
            <div class="warning-text text-center mt-3">
              <h4>ยืนยันผลการตรวจสอบใบหน้าหรือไม่</h4>
              <p>เมื่อยืนยันผลการตรวจสอบใบหน้าของคอร์สนี้</p>
              <p>จะไม่สามารถเปลียนแปลงได้ภายหลัง</p>
            </div>
            <div class="button d-flex justify-content-center mt-3">
              <b-button class="mr-3" variant="outline-dark" @click="hideModal()"
                >ยกเลิก</b-button
              >
              <b-button variant="primary" @click="approveAllLecture()"
                >ยืนยัน</b-button
              >
            </div>
          </div>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { mapActions, mapGetters } from "vuex";
import MemberInformation from "./panel/MemberInformation.vue";
import MemberFaceApprove from "./panel/MemberFaceApprove.vue";
export default {
  components: {
    MemberInformation,
    MemberFaceApprove,
  },
  data() {
    return {
      cId: this.$route.params.cId,
      token: localStorage.getItem("token"),
      cerId: this.$route.params.cerId,
      memberId: null,
      isLoading: true,
      items: [],
      addModalConfirmAll: false,
      learningFaceItem: [],
      courseInfo: [],
      isSave: false,
      approvePercent: null,
    };
  },
  computed: { ...mapGetters("MemberManagement", ["memberInfo"]) },
  created() {
    this.fetch();
    this.fetchCouseInfo();
    this.fetchLearningFaceApprove();
  },
  watch: {
    learningFaceItem(newValue) {
      let lectureApproved = newValue.filter(
        (item) => item.lel_approveFaceStatus == "APPROVED"
      );
      let totalLecture = newValue.length;
      this.approvePercent = (lectureApproved.length / totalLecture) * 100;
    },
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchMemberInfo"]),
    async fetch() {
      await this.fetchMemberInfo(this.cerId);
    },
    async fetchLearningFaceApprove() {
      await api
        .get("/v1/learning-face-approve/" + this.cerId)
        .then(({ data }) => {
          this.learningFaceItem = data;
        });
    },
    async fetchCouseInfo() {
      await api.get("/v1/courses/" + this.cId).then(({ data }) => {
        this.courseInfo = data.data;
      });
    },
    async approveAllLecture() {
      let dataLecture = this.learningFaceItem.filter(
        (i) => i.lel_approveFaceStatus != "APPROVED"
      );

      let dataRequest = {
        approval: [],
      };
      for (let i = 0; i < dataLecture.length; i++) {
        for (let j = 0; j < dataLecture[i].lec_images.length; j++) {
          dataRequest.approval.push({
            lec_id: dataLecture[i].lec_images[j].lec_id,
            stat_id: dataLecture[i].lec_images[j].stat_id,
            face_verified: dataLecture[i].lec_images[j].face_verified,
          });
        }
      }
      await api
        .put("/v1/edit-face-approve-all/" + this.cerId, dataRequest)
        .then(() => {
          this.fetch();
          this.fetchCouseInfo();
          this.fetchLearningFaceApprove();
          this.hideModal();
          this.isSave = true;
        });
    },
    hideModal() {
      this.$refs["modal-confirm-all"].hide();
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 30px 13px 30px;
  background-color: #fff;
  border: 1px solid #c9c9c9;
}
</style>

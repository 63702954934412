<template>
  <Layout style="width: 100%">
    <div v-if="!isLoading">
      <LineChart
        :chart-data="chartData"
        :options="chartOption"
        :height="chartHeight"
      />
    </div>
    <div v-else style="text-align: center">
      <b-spinner class="align-middle mr-2"></b-spinner>
      <strong>กำลังโหลดข้อมูล...</strong>
    </div>
  </Layout>
</template>

<script>
import LineChart from "@/components/charts/LineChart.js";
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";

export default {
  components: {
    Layout,
    LineChart
  },
  props: {
    company: Object,
    begin: String,
    end: String,
    dep: String,
    group: String,
    course: Number,
    statType: String
  },
  data() {
    return {
      chartData: {},
      chartOption: null,
      chartHeight: 90,
      h: 0,
      s: 50,
      l: 50,
      branch: "",
      gId: "",
      cId: "",
      startDate: "",
      endDate: "",
      isLoading: true
    };
  },
  created() {
    this.setChartData();
    this.setChartOption();
  },
  methods: {
    async setChartData() {
      if (this.statType == "year") {
        this.startDate = this.begin.substr(0, 4);
        this.endDate = this.end.substr(0, 4);
      } else if (this.statType == "month") {
        this.startDate = this.begin.substr(0, 7);
        this.endDate = this.end.substr(0, 7);
      }
      if (this.dep != "*") {
        this.branch = this.dep;
      }
      if (this.group != "*") {
        this.gId = this.group;
      }
      if (this.course) {
        this.cId = this.course;
      }
      let route = `/v1/total-learning-stat-by-hour/${this.statType}/${this.startDate}/${this.endDate}?dep=${this.branch}&gId=${this.gId}&cId=${this.cId}`;
      let res = await api.get(route);
      this.chartData = { labels: res.data.labels, datasets: [] };
      res.data.datasets.forEach(dataset => {
        this.chartData.datasets.push({
          data: dataset.data,
          label: dataset.label,
          borderColor: `hsl(${this.h}, ${this.s}%, ${this.l}%)`,
          fill: false,
          lineTension: 0.5
        });
        this.h += 5;
        if (this.l <= 50) {
          this.l += 20;
        } else if (this.l == 50) {
          this.l += 30;
        } else if (this.l > 50) {
          this.l -= 30;
        }
      });
      this.isLoading = false;
    },
    setChartOption() {
      this.chartOption = {
        elements: {
          point: { radius: 2 },
          line: { borderWidth: 1.5 }
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        tooltips: {
          enabled: true
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              },
              scaleLabel: {
                display: true,
                labelString: "เวลา",
                fontSize: 12
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20
              },
              scaleLabel: {
                display: true,
                labelString: "ชั่วโมง",
                fontSize: 12
              }
            }
          ]
        }
      };
    }
  }
};
</script>
0<template>
  <b-row>
    <b-col cols="auto">
      <b-img-lazy
        slot="aside"
        class="rounded"
        v-bind="mainProps"
        :src="data.courseThumbnail"
        alt
      ></b-img-lazy>
    </b-col>
    <b-col>
      <h4 class="card-title">{{ data.courseName }}</h4>
      <p class="text-csq">by {{ data.courseOwner.ownerName }}</p>
      <p>{{ data.courseShortDescription }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CourseInfo",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mainProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 120,
        height: "auto"
      }
    };
  }
};
</script>

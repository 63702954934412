<template>
  <div>
    <b-row class="pl-4">
      <b-col cols="6">
        <p class="mb-0">เลือก Section</p>
        <b-form-select v-model="sectionSection" @change="changeSection()">
          <option>กรุณาเลือก Section</option>
          <option
            v-for="(section, sectionIndex) in sectionsSelection"
            :key="sectionIndex"
            :value="section.index"
            :disabled="checkSelectDisable(sectionIndex) === 1"
          >
            {{ sectionIndex + 1 }}. {{ section.title }}
          </option>
        </b-form-select>
      </b-col>
      <b-col cols="6">
        <p class="mb-0">ให้จำนวนข้อต้องการสุ่ม[ต้องน้อยการจำนวนข้อทั้งหมด]</p>
        <b-form class="d-flex align-items-center" style="flex: 1">
          <b-form-input
            v-model="questionsInput"
            class="mr-3 input-random"
            :class="{
              disble: questionsInput < 1 || questionsInput > questionsAmount,
            }"
            @keyup="updateQuestion()"
          ></b-form-input
          ><span>/ {{ questionsAmount }} ข้อ</span>
          <b-button
            class="exams-delete-button ml-3"
            variant="danger"
            size="sm"
            @click="deleteQuestion()"
          >
            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          </b-button>
        </b-form>
        <span
          v-if="questionsInput < 1 || questionsInput > questionsAmount"
          class="text-danger warn"
          >กรุณากรอกตัวเลขที่ต้องการสุ่มระหว่าง 1 - {{ questionsAmount }}</span
        >
      </b-col>
    </b-row>
    <div class="pl-4">
      <hr />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      sectionSection: this.sectionIndexOptions,
      questionsInput: "",
      examId: this.$route.params.quizExamId,
    };
  },

  props: {
    sectionIndexOptions: Number,
  },

  mounted() {
    this.questionsInput = this.questionsAmountInput;
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
    sectionsSelection: function () {
      return this.exam.sections.filter((i) => i.questions.length != 0);
    },

    questionsAmount() {
      return Object.keys(this.exam.sections[this.sectionSection].questions)
        .length;
    },

    questionsAmountInput() {
      if (
        this.exam.sections[this.sectionSection].options === null ||
        this.exam.sections[this.sectionSection].options === undefined ||
        Object.keys(this.exam.sections[this.sectionSection].options).length == 0
      ) {
        return Object.keys(this.exam.sections[this.sectionSection].questions)
          .length;
      } else {
        return this.exam.sections[this.sectionSection].options.randomQuestion
          .questionCount;
      }
    },
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "updateOptionsRandomQuestion",
      "findExamsSectionbyId",
    ]),

    checkSelectDisable(sectionIndex) {
      if (
        this.exam.sections[sectionIndex].options === null ||
        this.exam.sections[sectionIndex].options === undefined
      ) {
        return 0;
      } else {
        return Object.keys(this.exam.sections[sectionIndex].options).length;
      }
    },
    checkSelectOptions() {
      if (
        this.exam.sections[this.sectionSection].options === null ||
        this.exam.sections[this.sectionSection].options === undefined
      ) {
        return 0;
      } else {
        return Object.keys(this.exam.sections[this.sectionSection].options)
          .length;
      }
    },

    async updateQuestion() {
      if (
        this.questionsInput >= 1 &&
        this.questionsInput <= this.questionsAmount
      ) {
        this.exam.sections[
          this.sectionSection
        ].options.randomQuestion.questionCount = this.questionsInput;

        let request = {
          body: { sections: this.exam.sections },
          id: this.exam.id,
          token: this.token,
        };

        console.log(request)

        await this.updateOptionsRandomQuestion(request);
      }
    },

    async deleteQuestion() {
      this.exam.sections[this.sectionSection].options = {};

      let request = {
        body: { sections: this.exam.sections },
        id: this.exam.id,
        token: this.token,
      };

      await this.updateOptionsRandomQuestion(request);

    },

    async changeSection() {
      this.exam.sections[this.sectionIndexOptions].options = {};

      this.exam.sections[this.sectionSection].options = {
        randomQuestion: {
          questionCount: Object.keys(
            this.exam.sections[this.sectionSection].questions
          ).length,
        },
      };

      let request = {
        body: { sections: this.exam.sections },
        id: this.exam.id,
        token: this.token,
      };

      await this.updateOptionsRandomQuestion(request);
      console.log(this.exam.sections[this.sectionSection].options);
    },

    async findExams() {
      await this.findExamsSectionbyId(this.examId).then(() => {});
    },
  },
};
</script>

<style lang="css" scope>
input.disble:hover,
.disble {
  border: 1px solid red !important;
  border-color: red !important;
}
.warn {
  font-size: 12px;
}
</style>
import api from "../../../services/api";
import faceRecognitionService from "@/services/face-recognition-service";

const defaultLaravelResponseData = {
  data: [],
  links: {
    first: null,
    last: null,
    prev: null,
    next: null,
  },
  meta: {
    current_page: 0,
    from: 0,
    last_page: 0,
    path: null,
    per_page: 10,
    to: 0,
    total: 0,
  },
};

export default {
  namespaced: true,
  state: {
    members: defaultLaravelResponseData,
    groups: defaultLaravelResponseData,
    groupMembers: defaultLaravelResponseData,
    memberInfo: [],
  },
  mutations: {
    SET_MEMBERS(state, data) {
      state.members = data;
    },
    SET_GROUPS(state, data) {
      state.groups = data;
    },
    SET_GROUP_MEMBERS(state, data) {
      state.groupMembers = data;
    },
    INIT() {},

    SET_MEMBERS_INFO(state, data) {
      state.memberInfo = data;
    },
  },
  getters: {
    members(state) {
      return state.members;
    },
    groups(state) {
      return state.groups;
    },
    groupMembers(state) {
      return state.groupMembers;
    },
    memberInfo(state) {
      return state.memberInfo;
    },
  },
  actions: {
    async fetchMembers({ commit }, { companyId, params }) {
      const { data } = await api.get(`v1/companies/${companyId}/members`, {
        params,
      });
      commit("SET_MEMBERS", data);
    },
    async fetchGroups({ commit }, { companyId, params }) {
      const { data } = await api.get(
        `v1/companies/${companyId}/member-groups`,
        {
          params,
        }
      );
      commit("SET_GROUPS", data);
    },
    async fetchGroupMembers({ commit }, { groupId, params }) {
      const { data } = await api.get(`v1/member-groups/${groupId}/members`, {
        params,
      });
      commit("SET_GROUP_MEMBERS", data);
      return data;
    },
    async uploadFaceImage(context, payload) {
      return await faceRecognitionService.uploadFaceImage(
        `register-face`,
        payload
      );
    },
    async uploadFaceImageSuccess(context, payload) {
      return await api.post(
        `v1/members/${payload.memberId}/adminfaceverify/${payload.adminId}`
      );
    },

    async fetchMemberInfo({ commit }, cerId) {
      const { data }  =  await api.get(`v1/user-course-enroll/${cerId}`);
      commit("SET_MEMBERS_INFO", data);
    },
    init(context) {
      context.commit("INIT");
    },
  },
};

<template>
  <div style="margin:0 1rem 0 1rem">
    <layout>
      <template v-slot:header>
        <PageHeader title="ข้อมูลผู้เข้าเรียน"></PageHeader
      ></template>
      <template v-slot:body>
        <studentInfo
          :apiRoute="`/v1/analytics/tfac/get-student-info-overview`"
          :perPage="20"
        />
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";

import studentInfo from "./panel/TfacStudentInfoPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";

export default {
  components: {
    Layout,
    PageHeader,
    studentInfo,
  },
};
</script>

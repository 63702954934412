<template>
  <div class="style-background border w-100">
    <b-row align-v="center" class="h-100 text-white p-2">
      <b-col class="col-12">
        <span class="style-text">จำนวนผู้เข้าสอบ</span>
      </b-col>
      <b-col class="col-12 text-center">
        <span v-if="isLoading">Loading ...</span>
        <span v-else class="style-amount-number">{{ studentAmount }}</span>
      </b-col>
      <b-col class="col-12 text-right">
        <span class="style-text">คน</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import adminService from '@/services/api.js';
export default {
  data() {
    return {
      isLoading: true,
      company: null,
      studentAmount: null,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAvailableQuizIds();
  },
  computed: {
    displayAnswerSheetAmount() {
      let amount = '';
      if (this.studentAmount >= Math.pow(10, 9)) {
        amount = (this.studentAmount / Math.pow(10, 9)).toFixed(2) + ' G';
      } else if (this.studentAmount >= Math.pow(10, 6)) {
        amount = (this.studentAmount / Math.pow(10, 6)).toFixed(2) + ' M';
      } else if (this.studentAmount >= Math.pow(10, 3)) {
        amount = (this.studentAmount / Math.pow(10, 3)).toFixed(2) + ' K';
      } else {
        amount = this.studentAmount;
      }
      return amount;
    },
  },
  methods: {
    async fetchAvailableQuizIds() {
      this.fetchExaminee();
    },
    async fetchExaminee() {
      try {
        this.isLoading = true;
        const endpoint = `/v1/analytics/exams/count-examinee/${this.company.id}`;
        const result = await adminService.get(endpoint);
        this.studentAmount = result.data;
        this.isLoading = false;
      } catch (err) {
        console.log('fetchExaminee error!', err);
      }
    },
  },
};
</script>

<style scoped>
.style-background {
  background-color: #eb8123;
  height: 200px;
}
.style-text {
  font-size: 1.1rem;
}
.style-amount-number {
  font-weight: bold;
  font-size: 3rem;
}
</style>

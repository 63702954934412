import axios from "axios";

const accessToken = localStorage.getItem("token");
const apiUrl =
  process.env.VUE_APP_FACE_RECOGNITION_API_URL ||
  "https://sightgain-20200507102000.coursesquare.com/";
const client = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + accessToken,
    token:
      process.env.VUE_APP_FACE_RECOGNITION_API_TOKEN ||
      "KPYbAZVRMjyuEhvTzNrCqF2LdxmBiWtw569ep7cH8SUQ31XkaDJ4fgsnG",
    com_id: localStorage.getItem("ComId"),
  },
});

export default {
  async getFaceSummaryStatByMember() {
    const comId = localStorage.getItem("ComId");
    return await client.get(`face-summary-stat-by-member/${comId}`);
  },
  async uploadFaceImage(endpoint, payload) {
    const formData = new FormData();
    formData.append(
      "data",
      `{"m_id": ${payload.memberId}, "com_id": ${localStorage.getItem(
        "ComId"
      )}}`
    );
    formData.append(`image_front`, payload.imageFrontFile);
    formData.append(`image_left`, payload.imageLeftFile);
    formData.append(`image_right`, payload.imageRightFile);
    formData.append(`image_up`, payload.imageUpFile);
    formData.append(`image_down`, payload.imageDownFile);

    return await client({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });
  },
  async getFaceStats(params) {
    const comId = { com_id: localStorage.getItem("ComId") };
    client.defaults.headers.common["com_id"] = comId.com_id;
    return await client.get("face-stat", {
      params: {
        ...params,
        ...comId,
      },
    });
  },
  async getFaceImage(params) {
    const comId = { com_id: localStorage.getItem("ComId") };
    client.defaults.headers.common["com_id"] = comId.com_id;
    return await client.get("face-image", {
      params: {
        ...params,
        ...comId,
      },
    });
  },
  async getFaceSummaryStat(params) {
    const comId = { com_id: localStorage.getItem("ComId") };
    return await client.get("face-summary-stat", {
      params: {
        ...params,
        ...comId,
      },
    });
  },
  async getFaceImages(m_id = null) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.get("register-face", {
      params: {
        m_id,
        com_id: localStorage.getItem("ComId"),
      },
    });
  },
  async getRegisterFaceImages(m_id = null) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.get(`register-base64?member_id=${m_id}&com_id=${localStorage.getItem("ComId")}`);
  },
  async updateFaceStats(payload) {
    return await client.put("face-stat", { face_stat: payload });
  },
  async approveFaceImages(payload) {
    return await client.put("face-stat/approve", {
      human_approve: payload,
    });
  },
  async resetFaceImages(m_id = null) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.delete(`register-face/${localStorage.getItem("ComId")}/${m_id}`);
  },
};

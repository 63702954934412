<template>
  <div>
    <page-header :title="`การตรวจพบใบหน้า`"></page-header>
    <b-container class="widthPage mx">
      <b-row class="mt-4">
        <b-col cols="12">
          <StudentUserInformation />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="border-top py-3 mt-3">
          <h6>
            <strong>{{ courseInfo.courseName }}</strong>
          </h6>
        </b-col>
        <b-col cols="12">
          <layout class="user-learn-progress">
            <template v-slot:header> </template>
            <template v-slot:body>
              <b-table
                striped
                hover
                :fields="fields"
                :items="datalectures"
                :busy="isLoading"
              >
                <template v-slot:cell(lec_images)="data">
                  <div v-if="data.item.lec_images.status === 'you dont have permssion'">
                    <p>โหลดข้อมูลไม่สำเร็จ</p>
                  </div>
                  <div v-else-if="!data.item.lec_images.length">
                    <p>ไม่พบข้อมูลใบหน้า</p>
                  </div>
                  <div
                    v-else
                    v-for="(item, index) in data.item.lec_images
                      .slice()
                      .reverse()"
                    :key="index"
                    class="box-face-detection"
                  >
                    <div class="img" style="width: 100px">
                      <img
                        :src="`${baseUrl}/${item.face_path}?token=${token}`"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="d-flex">
                      <span>{{ secondsToHms(item.learning_point) }}</span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(vid_secondLength)="data">
                  <span>
                    {{
                      data.item.vid_secondLength
                        ? timeFormat(data.item.vid_secondLength)
                        : "-" | thaiDurationFilter
                    }}
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </template>
          </layout>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import DiplomaLogo from "@/assets/images/diploma.png";
import StudentUserInformation from "./StudentUserInformation.vue";
export default {
  components: {
    PageHeader,
    layout,
    StudentUserInformation,
  },

  mixins: [timeMixins],
  data() {
    return {
      token: localStorage.getItem("token"),
      datalectures: [],
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      fields: [
        {
          key: "lec_name",
          label: "เนื้อหาคอร์ส",
          thStyle: "width: 350px",
          tdClass: "align-middle",
        },
        {
          key: "vid_secondLength",
          label: "เวลาบทเรียน",
          thStyle: "width:200px",
          tdClass: "align-middle",
        },
        {
          key: "lec_images",
          label: "รูปภาพ",
          thStyle: "width: 400px;",
          tdClass: "td-img ",
        },
      ],
      diplomaLogo: DiplomaLogo,
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com/",
      //Local
      isLoading: true,
      courseInfo: [],
    };
  },
  async mounted() {
    await api
      .get("/v1/lecture-student-images/" + this.cerId)
      .then(({ data }) => {
        this.datalectures = data;
        this.isLoading = false;
      });

    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data.data;
    });

    this.dragScrolling();
  },

  methods: {
    timeFormat(seconds) {
      var date = new Date(0);
      date.setSeconds(seconds); // specify value for SECONDS here
      var timeString = date.toISOString().substring(11, 19);
      return timeString;
    },

    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      var hDisplay = h > 0 ? h + " : " : "";
      var mDisplay = m < 10 ? "0" + m + " : " : m + " : ";
      var sDisplay = s < 10 ? "0" + s : s;
      return hDisplay + mDisplay + sDisplay;
    },

    dragScrolling() {
      const slider = this.$el.querySelectorAll(".td-img");
      let isDown = false;
      let startX;
      let scrollLeft;

      for (let i = 0; i < slider.length; i++) {
        slider[i].addEventListener("mousedown", (e) => {
          isDown = true;
          slider[i].classList.add("active");
          startX = e.pageX - slider[i].offsetLeft;
          scrollLeft = slider[i].scrollLeft;
        });
        slider[i].addEventListener("mouseleave", () => {
          isDown = false;
          slider[i].classList.remove("active");
        });
        slider[i].addEventListener("mouseup", () => {
          isDown = false;
          slider[i].classList.remove("active");
        });
        slider[i].addEventListener("mousemove", (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider[i].offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          slider[i].scrollLeft = scrollLeft - walk;
        });
      }
    },
  },
};
</script>
<style>
.user-learn-progress .main {
  padding-top: 0px;
}
.td-img {
  width: 400px;
  display: flex;
  overflow: auto;
  cursor: pointer;
}
.td-img.active {
  cursor: grabbing;
}
.box-face-detection {
  padding: 0 15px;
}

/* width */
.td-img::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.td-img::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.td-img::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.td-img::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .td-align-center{
  vertical-align: bottom !important;
} */
</style>
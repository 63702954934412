import * as actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import Vue from "vue";
import async from "@/config/store";

const initialState = () => ({
  companyInfo: null,
  [async.ASYNC_PARTICIPATION.stateKey]: null,
  [async.ASYNC_PARTICIPATION.loadingKey]: true,
  [async.ASYNC_PARTICIPATION.errorKey]: null,
  [async.ASYNC_EXAM_ANALYTICS.stateKey]: [],
  [async.ASYNC_EXAM_ANALYTICS.loadingKey]: true,
  [async.ASYNC_EXAM_ANALYTICS.errorKey]: null,
  [async.ASYNC_EXAM_ANSWERSHEETS.stateKey]: [],
  [async.ASYNC_EXAM_ANSWERSHEETS.loadingKey]: true,
  [async.ASYNC_EXAM_ANSWERSHEETS.errorKey]: null,
  [async.ASYNC_EXAM_STAT.stateKey]: null,
  [async.ASYNC_EXAM_STAT.loadingKey]: true,
  [async.ASYNC_EXAM_STAT.errorKey]: null,
  [async.ASYNC_EXAM_INFO.stateKey]: null,
  [async.ASYNC_EXAM_INFO.loadingKey]: true,
  [async.ASYNC_EXAM_INFO.errorKey]: null,
  [async.ASYNC_COMPANY_EXAMS.stateKey]: [],
  [async.ASYNC_COMPANY_EXAMS.loadingKey]: true,
  [async.ASYNC_COMPANY_EXAMS.errorKey]: null,
  [async.ASYNC_EXAM_ANSWERSHEET.stateKey]: null,
  [async.ASYNC_EXAM_ANSWERSHEET.loadingKey]: true,
  [async.ASYNC_EXAM_ANSWERSHEET.errorKey]: null,
  [async.ASYNC_EXAM_MEMBER.stateKey]: null,
  [async.ASYNC_EXAM_MEMBER.loadingKey]: true,
  [async.ASYNC_EXAM_MEMBER.errorKey]: null,
  [async.ASYNC_EXAMS_COUNT.stateKey]: null,
  [async.ASYNC_EXAMS_COUNT.loadingKey]: true,
  [async.ASYNC_EXAMS_COUNT.errorKey]: null,
  [async.ASYNC_ANSWERSHEETS_COUNT.stateKey]: null,
  [async.ASYNC_ANSWERSHEETS_COUNT.loadingKey]: true,
  [async.ASYNC_ANSWERSHEETS_COUNT.errorKey]: null,
  [async.ASYNC_COMPANY_COURSES.stateKey]: null,
  [async.ASYNC_COMPANY_COURSES.loadingKey]: true,
  [async.ASYNC_COMPANY_COURSES.errorKey]: null,
  [async.ASYNC_COMPANY_MODULES.stateKey]: null,
  [async.ASYNC_COMPANY_MODULES.loadingKey]: true,
  [async.ASYNC_COMPANY_MODULES.errorKey]: null,
  [async.ASYNC_COMPANY_INSTRUCTORS.stateKey]: null,
  [async.ASYNC_COMPANY_INSTRUCTORS.loadingKey]: true,
  [async.ASYNC_COMPANY_INSTRUCTORS.errorKey]: null
});

const state = {
  ...initialState()
};

export default {
  namespaced: true,
  state,
  actions: {
    ...actions,
    init(context) {
      context.commit("init");
    }
  },
  getters,
  mutations: {
    ...mutations,
    init(state) {
      let reset = initialState();
      for (let f in state) {
        Vue.set(state, f, reset[f]);
        // if (reset.hasOwnProperty(f)) Vue.set(state, f, reset[f]);
      }
    }
  }
};

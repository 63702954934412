<template>
  <form @submit.prevent="update">
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">ผู้ทำบัญชี/ผู้สอบบัญชี</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>เลขผู้ทำบัญชี (CPD)</label>
            <FormInputText
              :name="'sm_membershipId'"
              :type="`text`"
              :value="specialMembership ? specialMembership.sm_membershipId : ''"
              :placeholder="`ผู้ทำบัญชี (CPD)`"
              :is-editable="true"
              :errorText="'กรุณากรอกเลขผู้ทำบัญชี'"
              @edited="edit"
            ></FormInputText>
          </div>
          <div class="form-group col-md-4">
            <label>ผู้สอบบัญชี (CPA)</label>
            <FormInputText
              :name="'sm_cpaNo'"
              :type="`text`"
              :value="specialMembership ? specialMembership.sm_cpaNo : ''"
              :placeholder="`ผู้สอบบัญชี (CPA)`"
              :is-editable="true"
              :errorText="'กรุณากรอกเลขผู้สอบบัญชี'"
              @edited="edit"
            ></FormInputText>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import FormInputText from "./FormInputText";

export default {
  components: { FormInputText },
  props: {
    specialMembership: { type: Object, default: null }
  },

  data() {
    return {
      memberId: this.$route.params.id,
    };
  },

  computed: {},

  mounted() {},

  methods: {
    edit(input) {
      if (this.specialMembership) {
        this.specialMembership[input.name] = input.value;
        this.update();
      }
    },

    update() {
      if (this.specialMembership) {
        this.$emit("updated", this.specialMembership);
        this.isUpdated = true;
      }
    },
  }
};
</script>

<template>
  <div>
    <PageHeader title="เพิ่มข้อมูลการเข้าชั้นเรียน" />
    <b-container>
      <b-row>
        <b-col>
          <div class="bg-container py-5">
            <h4 style="font-weight: 600;">
              {{ classList.courseName }}
            </h4>
            <br />
            <b-row>
              <b-col cols="12" md="3">
                <p class="pb-2">วันที่เข้าเรียน</p>
                <b-input-group class="mb-3">
                  <b-form-input
                    v-model="formattedAttendDate"
                    :state="hasAttendDate"
                    type="text"
                    placeholder="เลือกวันที่เข้าเรียน"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="attendDate"
                      button-only
                      right
                      locale="th"
                      @input="changeAttendDate()"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="hasAttendDate">
                  กรุณากรอกวันที่เข้าเรียน
                </b-form-invalid-feedback>
              </b-col>
              <b-col cols="12" md="9">
                <p class="pb-2">เวลา</p>
                <b-row>
                  <b-col
                    cols="12" md="9"
                    class="pr-0 d-flex justify-content-center align-items-center"
                  >
                    <b-input-group class="mb-3">
                      <b-form-input
                        class="input-min-width"
                        id="startTimet"
                        v-model="attendStartTime"
                        @input="changeStartTime()"
                        type="text"
                        placeholder="--:--:--"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-timepicker
                          v-model="attendStartTime"
                          id="startTimet"
                          @input="changeStartTime()"
                          button-only
                          right
                          label-no-time-selected="00:00:00"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-input-group-append>
                    </b-input-group>
                    <p class="px-3">ถึง</p>
                    <b-input-group class="mb-3">
                      <b-form-input
                        class="input-min-width"
                        id="endTime"
                        v-model="attendEndTime"
                        @input="changeEndTime()"
                        type="text"
                        placeholder="--:--:--"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-timepicker
                          v-model="attendEndTime"
                          id="endTime"
                          @input="changeEndTime()"
                          button-only
                          right
                          label-no-time-selected="00:00:00"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-input-group-append>
                    </b-input-group>

                    <b-form-input
                      class="mb-3 ml-3"
                      v-model="durationTime"
                      type="text"
                      placeholder="-"
                      disabled
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div v-if="isShowWarningText">
              <div
                class="duplicate-class"
                v-for="(classInfo, index) in duplicateClassInfo"
                :key="index"
              >
                <p>
                  <b-icon
                    icon="exclamation-triangle-fill"
                    variant="warning"
                  ></b-icon>
                  <span style="font-weight: 600; padding-left: 2px;">
                    เคยเพิ่มข้อมูลการเข้าชั้นเรียนของคลาสนี้แล้ว
                  </span>
                  วันที่เข้าเรียน {{ date }} เวลา {{ attendStartTime }} -
                  {{ attendEndTime }} น.
                </p>
                <p>
                  แก้ไขการเข้าชั้นเรียนของคลาสนี้ได้ที่
                  <router-link
                    :to="{
                      name: 'class.attendance.info',
                      params: { classId: classInfo.cls_id },
                    }"
                  >
                    <b>
                      {{ classList.courseName }} ({{ date }} •
                      {{ attendStartTime }} - {{ attendEndTime }} น.)
                    </b>
                  </router-link>
                </p>
              </div>
            </div>
            <br />
            <h6>
              <span style="font-weight: 600;">รายละเอียดการเข้าเรียน</span>
              ผู้เรียนในปัจจุบัน {{ items.data.length }} คน
            </h6>
            <h6
              v-if="!attendDate || !attendStartTime || !attendEndTime"
              class="no-info"
            >
              ไม่มีรายละเอียดการเข้าเรียนในขณะนี้ กรุณาเลือกคอร์สเรียน
              วันและเวลาที่ต้องการเพิ่มข้อมูลการเข้าชั้นเรียน
            </h6>

            <!-- table -->
            <div v-if="items.data.length > 0">
              <EditClassAttendance
                :classInfo="items.data"
                :courseId="courseId"
                :startTime="attendStartTime"
                :finishTime="attendEndTime"
                :durationTime="durationTime"
                :durationFormat="duration"
                :classList="findClass"
                :attendDate="attendDate"
                :oldAttendDate="oldAttendDate"
                :oldStartTime="oldStartTime"
                :oldEndTime="oldEndTime"
                :courseName="classList.courseName"
                :isDateChange="isDateChange"
                @cancelModal="cancelChangeDate"
              />
            </div>
          </div>
          <br />
          <router-link
            :to="{
              name: 'attendance.info',
              params: { courseId: courseId },
            }"
          >
            <b-icon icon="arrow-left" variant="primary"></b-icon>
            <span class="pl-2" style="color: #027BFF;">ย้อนกลับ</span>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import api from "@/services/api";
import PageHeader from "../../layouts/PageHeader";
import EditClassAttendance from "./partials/EditClassAttendance.vue";

export default {
  components: {
    PageHeader,
    EditClassAttendance,
  },
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      courseId: this.$route.params.courseId,
      date: "",
      oldAttendDate: "",
      oldStartTime: "",
      oldEndTime: "",
      attendDate: "",
      attendStartTime: "",
      attendEndTime: "",
      durationTime: "",
      duration: null,
      hasAttendDate: null,
      hasStartTime: null,
      hasEndTime: null,
      srcImage:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e",
      isShowWarningText: false,
      isDateChange: "",
      items: {
        data: [],
        meta: {
          current_page: 0,
          per_page: 15,
        },
      },
      duplicateClassInfo: [],
      count: 0,
    };
  },
  watch: {
    attendDate(newAttendDate, oldAttendDate) {
      this.oldAttendDate = oldAttendDate;
    },
    attendStartTime(newStartTime, oldStartTime) {
      if (newStartTime && this.attendEndTime < newStartTime) {
        // If new start time is greater than end time, reset end time
        this.attendEndTime = newStartTime;
      }
      this.oldStartTime = oldStartTime;
    },
    attendEndTime(newEndTime, oldEndTime) {
      if (newEndTime && this.attendStartTime > newEndTime) {
        // If new end time is less than start time, reset start time
        this.attendStartTime = newEndTime;
      }
      this.oldEndTime = oldEndTime;
    },
  },
  computed: {
    ...mapGetters("AttendanceManagement", ["findClass", "classList"]),
    formattedAttendDate() {
      if (this.attendDate) {
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Date(this.attendDate).toLocaleDateString("th", options);
      }
      return "";
    },
  },
  async mounted() {
    await this.fetchClassList(this.courseId);
  },
  methods: {
    ...mapActions("AttendanceManagement", [
      "fetchFindClass",
      "fetchClassList",
      "insertClassAttendance",
    ]),
    changeAttendDate() {
      this.count += 1;
      this.hasAttendDate = null;
      this.isDateChange = "attendDate" + this.count;
      if (!this.attendStartTime) {
        this.hasStartTime = false;
      }
      if (!this.attendEndTime) {
        this.hasEndTime = false;
      }
      if (this.attendDate && this.attendStartTime && this.attendEndTime) {
        this.calculateDuration();
        this.getMember();
      }
    },
    changeStartTime() {
      this.count += 1;
      this.hasStartTime = null;
      this.isDateChange = "startTime" + this.count;
      if (!this.attendDate) {
        this.hasAttendDate = false;
      }
      if (!this.attendEndTime) {
        this.hasEndTime = false;
      }
      if (this.attendDate && this.attendStartTime && this.attendEndTime) {
        this.calculateDuration();
        this.getMember();
      }
    },
    changeEndTime() {
      this.count += 1;
      this.hasEndTime = null;
      this.isDateChange = "endTime" + this.count;
      if (!this.attendDate) {
        this.hasAttendDate = false;
      }
      if (!this.attendStartTime) {
        this.hasStartTime = false;
      }
      if (this.attendDate && this.attendStartTime && this.attendEndTime) {
        this.calculateDuration();
        this.getMember();
      }
    },
    calculateDuration() {
      const [hh1, mm1] = this.attendStartTime.split(":").map(Number);
      const [hh2, mm2] = this.attendEndTime.split(":").map(Number);

      if (hh1 < hh2 || (hh1 === hh2 && mm1 < mm2)) {
        const convertHour1 = hh1 * 60;
        const convertHour2 = hh2 * 60;
        const minutes = convertHour2 + mm2 - (convertHour1 + mm1);

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const hoursStr = hours.toString().padStart(2, "0");
        const minutesStr = remainingMinutes.toString().padStart(2, "0");
        this.durationTime = `${hoursStr}:${minutesStr}`;
        this.duration = minutes * 60;
      } else {
        this.durationTime = "00:00";
        this.duration = 0;
      }
    },
    async getMember() {
      this.findDuplicateClass();
      await api
        .get(
          `/v1/courses/${this.courseId}/members?cer_status=active&class_date=${this.attendDate}`
        )
        .then(({ data }) => {
          this.items = data;
          this.items.data.forEach(() => {
            this.totalHours.push("00:00");
          });
        })
        .catch(() => {});
    },
    async findDuplicateClass() {
      moment.updateLocale("th", {});
      let params = {
        courseId: this.courseId,
        class_date: this.attendDate,
        class_start_time: this.attendStartTime,
        class_finish_time: this.attendEndTime,
      };
      await this.fetchFindClass(params);

      if (this.findClass.length > 0) {
        this.duplicateClassInfo = this.findClass.filter(
          (item) =>
            item.cls_date == this.attendDate &&
            item.cls_startTime == this.attendStartTime &&
            item.cls_finishTime == this.attendEndTime
        );

        if (this.duplicateClassInfo.length > 0) {
          this.isShowWarningText = true;
          this.date =
            moment(this.attendDate).format("DD MMM ") +
            (moment(this.attendDate).year() + 543);
        } else {
          this.isShowWarningText = false;
          this.date = "";
        }
      }
    },
    cancelChangeDate(propertyName) {
      if (propertyName.includes("attendDate")) {
        this.attendDate = this.oldAttendDate;
      } else if (propertyName.includes("startTime")) {
        this.attendStartTime = this.oldStartTime;
      } else if (propertyName.includes("endTime")) {
        this.attendEndTime = this.oldEndTime;
      }
    },
  },
};
</script>

<style scoped>
.no-info {
  padding: 15px;
  color: #eb8122;
  border: 1px solid #f8d7b9;
  border-radius: 4px;
  background-color: #fbe4d0;
}

.duplicate-class {
  padding: 15px;
  border: 1px solid #f8d7b9;
}

.duplicate-class p {
  color: #eb8122;
}

.input-min-width {
      min-width: 65px;
}

/* tbody td {
  vertical-align: middle;
  padding: 0.3rem 0.75rem 0.3rem !important;
} */
</style>

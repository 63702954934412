<template>
  <div>
    <ExamsDetailsHeader />
    <ExamsDetailsContent />
  </div>
</template>

<script>
import ExamsDetailsHeader from "@/components/pages/exam-analytics/exams-details/ExamsDetailsHeader.vue";
import ExamsDetailsContent from "@/components/pages/exam-analytics/exams-details/ExamsDetailsContent.vue";

export default {
  components: {
    ExamsDetailsHeader,
    ExamsDetailsContent
  }
};
</script>

<template>
  <div>
    <PageHeader
      :title="`สถานะการเข้าเรียนของ ${memberInfo.memberFullname}`"
    ></PageHeader>
    <b-container fluid class="outer">
      <h3 class="font-weight-bold text-dark">{{ courseInfo.courseName }}</h3>
      <hr />
      <h4>
        สถานะการเรียน: {{ status }}
        <b-dropdown
          id="change-status-dropdown"
          text="แก้ไขสถานะการเข้าเรียน"
          variant="outline-primary"
          class="m-md-2"
        >
          <b-dropdown-item
            v-for="option in learningStatusOptions"
            :key="option.value"
            @click="showConfirmActionModal(option.value)"
            >{{ option.text }}</b-dropdown-item
          >
        </b-dropdown>
      </h4>

      <b-media vertical-align="center" class="mt-4">
        <template v-slot:aside>
          <b-img
            blank-color="#ccc"
            width="30"
            src="/image/icons/graduation.png"
            :class="{ 'opacity-1': !canGetCertificate }"
          ></b-img>
        </template>
        <span :class="{ 'text-muted': !canGetCertificate }">{{
          canGetCertificate
            ? "สามารถรับ Certificate ได้"
            : "ยังไม่สามารถรับ Certificate ได้เพราะยังไม่ตรงกับเงื่อนไขที่กำหนดให้"
        }}</span>
      </b-media>

      <b-row class="mt-4">
        <b-col cols="6">
          <b-alert show variant="warning" class="border-warning rounded-0 py-4">
            <b-media vertical-align="top">
              <template v-slot:aside>
                <b-iconstack font-scale="3">
                  <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="warning"
                    scale="0.6"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="link45deg"
                    variant="white"
                    scale="0.5"
                  ></b-icon>
                </b-iconstack>
              </template>
              <h5>ตรวจสอบรูปการเข้าเรียน</h5>
              <p class="small mb-2">
                กดที่ลิงค์ด้านล่างเพื่อนเข้าตรวจสอบรูปการเข้าเรียนของผู้เรียน
              </p>
              <a class="small" :href="ftpUrl" target="_blank">{{ ftpUrl }}</a>
            </b-media>
          </b-alert>
        </b-col>
      </b-row>

      <b-card-group deck class="mt-2">
        <b-card class="rounded-0 no-shadow" body-class="pt-4">
          <b-media vertical-align="top">
            <template v-slot:aside>
              <b-iconstack font-scale="3">
                <b-icon
                  stacked
                  icon="list-check"
                  variant="blue"
                  scale="0.5"
                ></b-icon>
                <b-icon stacked icon="circle" variant="blue"></b-icon>
              </b-iconstack>
            </template>
            <div class="small mb-2" style="max-height: 30px;">
              เปอร์เซ็นของบทเรียนที่เรียนจบแล้ว
            </div>
            <h2 class="font-weight-light text-blue">{{ completedPercent }}%</h2>
          </b-media>
        </b-card>
        <b-card class="rounded-0 no-shadow" body-class="pt-4">
          <b-media vertical-align="top">
            <template v-slot:aside>
              <b-iconstack font-scale="3">
                <b-icon
                  stacked
                  icon="clock"
                  variant="yellow"
                  scale="0.5"
                ></b-icon>
                <b-icon stacked icon="circle" variant="yellow"></b-icon>
              </b-iconstack>
            </template>
            <div class="small mb-2">เวลาที่ใช้เรียนคิดเป็นนาที</div>
            <h2 class="font-weight-light text-yellow">{{ learnedMinutes }}</h2>
          </b-media>
        </b-card>
        <b-card class="rounded-0 no-shadow" body-class="pt-4">
          <b-media vertical-align="top">
            <template v-slot:aside>
              <b-iconstack font-scale="3">
                <b-icon
                  stacked
                  icon="emoji-neutral"
                  variant="pink"
                  scale="0.5"
                ></b-icon>
                <b-icon stacked icon="circle" variant="pink"></b-icon>
              </b-iconstack>
            </template>
            <div class="small mb-2">ตรวจจับใบหน้าตอนเข้าเรียนได้</div>
            <h2 class="font-weight-light text-pink">
              {{ faceDetectedPercent }}%
            </h2>
          </b-media>
        </b-card>
        <b-card class="rounded-0 no-shadow" body-class="pt-4">
          <b-media vertical-align="top">
            <template v-slot:aside>
              <b-iconstack font-scale="3">
                <b-icon
                  stacked
                  icon="camera-video"
                  variant="orange"
                  scale="0.5"
                ></b-icon>
                <b-icon stacked icon="circle" variant="orange"></b-icon>
              </b-iconstack>
            </template>
            <div class="small mb-2">ความยาววิดีโอ</div>
            <h4 class="font-weight-light text-orange">
              {{ courseInfo.courseDuration | duration }}
            </h4>
          </b-media>
        </b-card>
        <b-card class="rounded-0 no-shadow" body-class="pt-4">
          <b-media vertical-align="top">
            <template v-slot:aside>
              <b-iconstack font-scale="3">
                <b-icon
                  stacked
                  icon="emoji-smile"
                  variant="green"
                  scale="0.5"
                ></b-icon>
                <b-icon stacked icon="circle" variant="green"></b-icon>
              </b-iconstack>
            </template>
            <div class="small mb-2">ตรวจจับใบหน้าที่ตรงกันตอนเข้าเรียนได้</div>
            <h2 class="font-weight-light text-green">
              {{ faceVerifiedPercent }}%
            </h2>
          </b-media>
        </b-card>
      </b-card-group>

      <b-card
        class="rounded-0 no-shadow mt-4"
        header-bg-variant="white"
        header-class="border-0"
        body-class="pt-3"
      >
        <template v-slot:header>
          <h5 class="mb-0">บทเรียน ทั้งหมดในคอร์ส</h5>
        </template>
        <b-table
          ref="paginationTable"
          :fields="fields"
          :items="lectures"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          head-variant="light"
        >
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="lectures.length"
          :per-page="perPage"
          aria-controls="paginationTable"
          class="pull-right mt-3"
        />
      </b-card>
    </b-container>

    <ConfirmCompleteEnrollmentModal
      modal-id="confirmCompleteEnrollmentModal"
      :items="[{ cerId: this.cerId, fullName: this.memberInfo.memberFullname }]"
      @closed="closeConfirmActionModal"
    />
    <ConfirmUnenrollModal
      modal-id="confirmUnenrollModal"
      :course-id="this.courseInfo.courseId"
      :items="[{ cerId: this.cerId, fullName: this.memberInfo.memberFullname }]"
      @closed="closeConfirmActionModal"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import ConfirmCompleteEnrollmentModal from "@/components/pages/course-enrollment/partials/ConfirmCompleteEnrollmentModal";
import ConfirmUnenrollModal from "@/components/pages/course-enrollment/partials/ConfirmUnenrollModal";

import api from "@/services/api";
import courseEnrollmentMixin from "@/mixins/course-enrollment-mixin.js";
import faceRecognitionService from "@/services/face-recognition-service";
import { mapActions, mapGetters } from "vuex";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";

export default {
  name: "UserLearningStatusPage",
  components: {
    PageHeader,
    ConfirmCompleteEnrollmentModal,
    ConfirmUnenrollModal
  },
  props: {
    company: Object,
    user: Object
  },
  mixins: [courseEnrollmentMixin, timeMixins],
  data() {
    return {
      fields: [
        { key: "moduleName", label: "หัวข้อ" },
        { key: "lectureName", label: "บทเรียน" },
        { key: "learningStatus", label: "สถานะการเรียน" }
      ],
      sortBy: "moduleOrder",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      lectures: [],
      courseInfo: {
        courseName: null,
        courseDuration: null
      },
      memberInfo: {
        memberId: null,
        memberFullname: null
      },
      status: null,
      learnedMinutes: null,
      completedPercent: null,
      cerId: this.$route.params.cerId,
      hasPdfAction: false,
      faceDetectedPercent: 0,
      faceVerifiedPercent: 0
    };
  },
  computed: {
    ...mapGetters("CourseCompleteRule", ["courseCompleteActions"]),
    canGetCertificate() {
      return this.hasPdfAction && this.status === "เรียนเสร็จแล้ว";
    },
    ftpUrl() {
      return `${process.env.VUE_APP_FACE_RECOGNITION_FTP_URL +
        this.company.id}/${this.memberInfo.memberId}`;
    }
  },
  async mounted() {
    await this.getResults();
    await this.fetchCourseCompleteRules(
      this.courseInfo.courseId
    ).then(() => {});
    this.getFaceSummaryStat();

    let pdfActionIndex = this.courseCompleteActions.findIndex(
      item => item.action === "PDF"
    );
    this.hasPdfAction = pdfActionIndex !== -1;
  },
  methods: {
    ...mapActions("CourseCompleteRule", ["fetchCourseCompleteRules"]),
    async getResults() {
      await api
        .get(`v1/analytics/students/learning-status/${this.cerId}`)
        .then(({ data }) => {
          this.lectures = data.data;
          this.status = data.status;
          this.completedPercent = data.completedPercent;
          this.learnedMinutes = data.learnedMinutes;
          this.courseInfo = data.courseInfo;
          this.memberInfo = data.memberInfo;
        })
        .catch(err => console.log(err));
    },
    async getFaceSummaryStat() {
      const { data } = await faceRecognitionService.getFaceSummaryStat({
        cer_id: this.cerId,
        m_id: this.memberInfo.memberId
      });
      if (!data?.status) {
        const { face_detected_percent, face_verified_percent } = data;
        this.faceDetectedPercent = face_detected_percent;
        this.faceVerifiedPercent = face_verified_percent;
      }
    },
    showConfirmActionModal(action) {
      if (action == "completeEnrollment") {
        console.log("action", action);
        this.$bvModal.show("confirmCompleteEnrollmentModal");
      } else if (action == "unenroll") {
        this.$bvModal.show("confirmUnenrollModal");
      }
    },
    async closeConfirmActionModal(modalId) {
      await this.getResults();
      this.$bvModal.hide(modalId);
    }
  }
};
</script>

<style scoped>
body {
  background-color: #fcfcfc;
}
.card-deck {
  margin-right: -10px;
  margin-left: -10px;
}
.card-deck .card {
  margin-right: 10px;
  margin-left: 10px;
}
.opacity-1 {
  opacity: 0.15;
}
.text-blue {
  color: #56d9fe;
}
.text-yellow {
  color: #fabf3b;
}
.text-pink {
  color: #fe5676;
}
.text-orange {
  color: #ff7f00;
}
.text-green {
  color: #13a200;
}
</style>

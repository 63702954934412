// src/async-util.js
import _ from "lodash";
import { HttpService } from "@/helpers/http-service";

export const doAsync = async (store, { url, mutationTypes }) => {
  if (mutationTypes) {
    store.commit(mutationTypes.PENDING);
  }
  try {
    const response = await HttpService(url);
    if (mutationTypes) {
      store.commit(mutationTypes.SUCCESS, response.data);
    }
    return response.data;
  } catch (error) {
    let err = error.response.data.error;
    if (mutationTypes) {
      store.commit(mutationTypes.FAILURE, err);
      return;
    }
    throw new Error(`API call error:${err.message}`);
  }
};

export const createAsyncMutation = type => ({
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: _.camelCase(`${type}_PENDING`),
  stateKey: _.camelCase(`${type}_DATA`),
  errorKey: _.camelCase(`${type}_ERROR`)
});

<template>
  <div>
    <PageHeader title="จัดการ Live"></PageHeader>
    <div class="outer">
      <b-container fluid="md" class="list-page">
        <div class="text-right">
          <b-button variant="primary" @click="toPageCreateZoomLive">
            <b-icon-plus /> สร้าง Live ใหม่
          </b-button>
        </div>
        <b-nav tabs class="mt-4">
          <b-nav-item @click="setPlanTable" :active="activeTab == 'plan'">
            ตาราง Live ล่วงหน้า
          </b-nav-item>
          <b-nav-item @click="setEndTable" :active="activeTab == 'end'">
            Live ที่จบแล้ว
          </b-nav-item>
        </b-nav>

        <component :is="table" />
      </b-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PlanMeetTable from "./table/ListMeetingTablePlan";
import EndMeetTable from "./table/ListMeetingTableEnd";
import PageHeader from "@/components/layouts/PageHeader";
import { mapActions } from "vuex";
import api from "@/services/api.js";

export default {
  name: "LiveListPage",
  props: {
    company: {
      required: true,
    },
  },
  data() {
    return {
      table: "PlanMeetTable",
      activeTab: "plan",
    };
  },
  components: {
    PlanMeetTable,
    EndMeetTable,
    PageHeader,
  },
  async created(){
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
  mounted() {
    this.fetchMeetings();
    this.scroll();
  },
  methods: {
    ...mapActions("LiveMeeting", ["fetchMeetings"]),
    toPageCreateZoomLive() {
      this.$router.push({
        name: "live.form.create",
        params: {
          liveType: "ZOOM",
        },
      });
    },
    toPageThirdPartyLive() {
      this.$router.push({
        name: "live.form.create",
        params: {
          liveType: "OTHER",
        },
      });
    },
    setEndTable() {
      this.table = "EndMeetTable";
      this.activeTab = "end";
    },
    setPlanTable() {
      this.table = "PlanMeetTable";
      this.activeTab = "plan";
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          console.log("load more");
        }
      };
    },
  },
};
</script>

<style lang="css" scoped>
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #fff #fff #007bff;
  font-weight: bold;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #fff #fff #007bff;
}
.nav-tabs .nav-item .nav-link {
  color: #000;
}
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs .nav-link {
  border: 2px solid transparent;
}
</style>

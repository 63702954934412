export default {
  filters: {
    shortenLink(value) {
      let shortenLink = value
      if (shortenLink.length > 50) {
        const firstTenChar = value.substring(0, 30)
        const lastTenChar = value.substring(value.length - 18,value.length)
        shortenLink = `${firstTenChar}...${lastTenChar}`
      }
      return shortenLink
    }
  },
  methods: {
    authorizationRoles(roles = []) {
      const companyId = (this.$store.state.Company.companyInfo.id)
      const userRoles = (this.$store.state.Authentication.authUser.roles)
                          .filter(el => el.companyId === companyId)
                          .map(el => el.roleName)
      for (let role of roles) {
        if (userRoles.includes(role))
          return true
      }
      return false
    }
  }
}
import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    dataError: null,
    isLoading: false,
    courseOrderList: [],
    courseOrderListFlowAcc: [],
    courseOrderListByOrder: [],
    courseTransferOrderDetails: [],
    courseTags: [],
  },
  mutations: {
    INIT() {},
    SET_COURSE_ORDER_LIST(state, data) {
      state.courseOrderList = data;
    },
    SET_TAGS(state, data) {
      state.courseTags = ['หมวดหมู่คอร์สทั้งหมด', ...new Set(data.map(item => item.tag_name))];
    },
    SET_COURSE_ORDER_LIST_FLOW_ACC(state, data) {
      state.courseOrderListFlowAcc = data;
    },
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status === null ? !state.isLoading : status;
    },
    SET_COURSE_ORDER_LIST_BY_ORDER(state, data) {
      state.courseOrderListByOrder = data;
    },
    SET_COURSE_TRANSFER_ORDER_DETAIL(state, data) {
      state.courseTransferOrderDetails = data[0];
    },
  },
  getters: {
    dataError(state) {
      return state.dataError;
    },
    isLoading(state) {
      return state.isLoading;
    },
    courseOrderList(state) {
      return state.courseOrderList;
    },
    courseOrderListFlowAcc(state) {
      return state.courseOrderListFlowAcc;
    },
    courseOrderListByOrder(state) {
      return state.courseOrderListByOrder;
    },
    courseTransferOrderDetails(state) {
      return state.courseTransferOrderDetails;
    },
    courseTags(state) {
      return state.courseTags;
    }
  },
  actions: {
    async fetchCourseOrderList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`/v1/course-order-list`, {
          params,
        })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_ORDER_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error(errors);
        });
    },
    async fetchTags(context) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`/v1/tags/get-company-course-tags`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_TAGS", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error(errors);
        });
    },
    async fetchCourseOrderListFlowAcc(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`/v1/course-order-list-flow-acc`, {
          params,
        })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_ORDER_LIST_FLOW_ACC", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error(errors);
        });
    },
    async fetchCourseOrderListByOrder(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`v1/course-order-list-by-order`, { params })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_ORDER_LIST_BY_ORDER", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(errors);
        });
    },
    async getCourseTransferOrderDetails(context, orderId) {
      await api
        .get(`/v1/bank-transfer-order-details/${orderId}`)
        .then(({ data }) => {
          context.commit("SET_COURSE_TRANSFER_ORDER_DETAIL", data.data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
};

<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="สถิติถูกผิดของคำถามแต่ละข้อ"></PageHeader>
      <b-row>
        <b-col cols="12">
          <questionOverview
            :apiRoute="`exams/${examId}/get-answer-stat-by-exam-id`"
            :perPage="20"
            :bestScoreSelected="bestScoreSelected"
          ></questionOverview>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import questionOverview from "./panel/IndividualExamQuestionOverviewPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return { 
      examId: this.$route.params.examId,
      bestScoreSelected: this.$route.query.bestScore
      };
  },
  components: {
    PageHeader,
    questionOverview,
  },
};
</script>
<style scoped></style>

import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    discussions: []
  },
  mutations: {
    SET_DISCUSSIONS(state, data) {
      state.discussions = data;
    },
    INIT() {}
  },
  getters: {
    discussions(state) {
      return state.discussions;
    }
  },
  actions: {
    async fetchDiscussions({ commit }, courseId) {
      const { data } = await api.get(
        `/v1/analytics/discuss/getDiscuss?cId=` + courseId
      );
      commit("SET_DISCUSSIONS", data);
    },
    init(context) {
      context.commit("INIT");
    }
  }
};

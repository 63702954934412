<template>
  <div>
    <b-form-group
      class="font-weight-bold"
      id="input-1-group"
      label="URL Zoom Meeting"
      label-for="url-input"
    >
      <b-form-input id="url-input" type="url" v-model="meetingURL" required />
    </b-form-group>

    <b-row class="mt-0">
      <b-col>
        <b-form-group
          class="font-weight-bold"
          id="input-1-group"
          label="Meeting ID"
          label-for="mid-input"
        >
          <b-form-input
            id="mid-input"
            type="text"
            v-mask="['### #### ####']"
            v-model="meetingId"
            required
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          class="font-weight-bold"
          id="input-1-group"
          label="Passcode"
          label-for="pass-input"
        >
          <b-form-input
            id="pass-input"
            type="text"
            v-model="meetingPassword"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {mask} from 'vue-the-mask';
export default {
  name: "ZoomUrl",
  directives:{mask},
  computed: {
    meetingURL: {
      get() {
        return this.getMeetingURL();
      },
      set(newMeetingURL) {
        this.setMeetingURL(newMeetingURL);
      },
    },
    meetingId: {
      get() {
        return this.getMeetingId();
      },
      set(newMeetingId) {
        this.setMeetingId(newMeetingId.replace(/\s/g, ''));
      },
    },
    meetingPassword: {
      get() {
        return this.getMeetingPassword();
      },
      set(newMeetingPassword) {
        this.setMeetingPassword(newMeetingPassword);
      },
    },
  },
  methods: {
    ...mapActions("LiveForm", [
      "setMeetingId",
      "setMeetingPassword",
      "setMeetingURL",
      "setThirdURL"
    ]),
    ...mapGetters("LiveForm", [
      "getMeetingId",
      "getMeetingPassword",
      "getMeetingURL",
      "getThirdURL"
    ]),
  },
};
</script>

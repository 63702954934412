<template>
  <div class="d-flex flex-column my-3">
    <div>
      <mark-down :message="'<strong>' + data.questionTitle"></mark-down>
    </div>

    <div
      v-if="data.questionType == 'MULTIPLE_CHOICE'"
      class="d-flex flex-column my-3"
    >
      <span
        v-for="(item, index) in data.questionChoices"
        :key="index"
        :class="{
          correct:
            data.answerKey.choiceId.filter((i) => i.choiceId == item.choiceId)
              .length > 0,
          answer:
            data.answer.choiceId == item.choiceId
        }"
      >
        <strong>
          <mark-down :message="index + 1 + '. ' + item.choiceText"> </mark-down>
        </strong>
      </span>
    </div>
    <div
      v-if="data.questionType == 'FILL_BLANK'"
      class="d-flex flex-column my-3"
    >
      <span :class="'correct'">
        <strong>
          {{ data.answerKey.answerText }}
        </strong>
      </span>
      <span>
        <strong>
          {{ data.answer.answerText }}
        </strong>
      </span>
    </div>
  </div>
</template>

<script>
import MarkDown from "@/components/shared/Markdown.vue";

export default {
  components: {
    MarkDown,
  },
  props: ["data"],
};
</script>
<style scoped>
.correct {
  background: #bacfa1;
}
.answer {
  border: 3px solid #0066ff;
}
</style>

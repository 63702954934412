import { doAsync } from "@/helpers/store";
import async from "@/config/store";

export const uploadExamCSV = async (context, { data, accessToken }) => {
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/exam-creator-upload-csvs/csv/upload`,
      params: {
        access_token: accessToken
      },
      method: "post",
      data
    },
    mutationTypes: async.ASYNC_UPLOADED_CSV
  });
};

export const createOrAppendExam = async (
  context,
  { lecName, lecType, moduleId, courseId, examId }
) => {
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_API_BASE_URL}/v1/courses/${courseId}/lecture`,
      method: "post",
      data: {
        lecName,
        lecType,
        modId: moduleId
      }
    },
    mutationTypes: async.ASYNC_CREATE_EXAM_TEMPLATE
  });
  const template = context.getters[async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey];
  if (!template) return;
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_API_BASE_URL}/v1/lecture/content`,
      method: "post",
      data: {
        lecId: template.id,
        lecType: template.type,
        lecContent: {
          quiz_url: `${process.env.VUE_APP_EXAM_PREVIEW_BASE_URL}/${examId}`,
          quiz_examId: examId
        }
      }
    },
    mutationTypes: async.ASYNC_CREATE_OR_APPEND_EXAM
  });
};

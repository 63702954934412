<template>
  <b-card id="exam-insight-table">
    <div slot="header">
      <i class="fas fa-file-alt" aria-hidden="true"></i> ผลสอบทั้งหมด
    </div>
    <b-container fluid class="p-0" style="width: 100%">
      <b-row align-start justify-start column class="list-st">
        <!-- <b-col class="px-4">
                    <b-container fluid class="p-0">
                        <b-row align-space-between justify-start row>
                            <b-col align-self-center shrink>
                                <label class="mr-3">
                                    เลือกช่วงที่สอบ
                                </label>
                            </b-col>
                            <b-col align-self-center shrink>
                                <b-button-group v-model="presetPeriod" @change="handlePresetPeriodChange">
                                    <b-button value="thisMonth">
                                        เดือนนี้
                                    </b-button>
                                    <b-button value="30DaysAgo">
                                        30 วันที่แล้ว
                                    </b-button>
                                    <b-button value="90DaysAgo">
                                        90 วันที่แล้ว
                                    </b-button>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col> -->
        <b-col class="px-4" style="width:100%">
          <b-container fluid class="p-0" style="width: 100%">
            <b-row align-space-between justify-start row>
              <!-- <b-col align-self-center shrink>
                                <label>
                                    ช่วงวันที่สอบ
                                </label>
                            </b-col>
                            <b-col shrink>
                                <b-form-input
                                    v-model="periodDateFrom"
                                    type="date"
                                    class="mx-3"
                                    :max="periodDateTo"
                                    @change="handlePeriodDateChange"
                                />
                            </b-col>
                            <b-col align-self-center shrink>
                                <span>
                                    ถึง
                                </span>
                            </b-col>
                            <b-col shrink>
                                <b-form-input
                                    v-model="periodDateTo"
                                    type="date"
                                    class="mx-3"
                                    :max="periodDateToMax"
                                    @change="handlePeriodDateChange"
                                />
                            </b-col>
                            <b-col align-self-start shrink>
                                <b-button large @click="handleSearchClicked">
                                    ค้นหา
                                </b-button>
                            </b-col> -->
              <div class="col-md-8">
                <b-form inline>
                  <label for="inline-form-input-name">ช่วงวันที่สอบ</label>
                  <b-input
                    v-model="periodDateFrom"
                    type="date"
                    class="mx-3"
                    :max="periodDateTo"
                    @change="handlePeriodDateChange"
                  ></b-input>
                  <label>ถึง</label>
                  <b-input
                    v-model="periodDateTo"
                    type="date"
                    class="mx-3"
                    :max="periodDateToMax"
                    @change="handlePeriodDateChange"
                  ></b-input>
                </b-form>
              </div>
              <div class="col-md-4">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input
                    v-model="nameOrEmailSearchText"
                    type="search"
                    class="ml-3"
                    :class="`${$options.name}__search-box`"
                    append-icon="search"
                    placeholder="ชื่อ/อีเมล ผู้สอบ"
                    hide-details
                    outline
                  ></b-input>
                  <b-button
                    variant="outline-primary"
                    @click="handleSearchClicked"
                    >ค้นหา</b-button
                  >
                </b-input-group>
              </div>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <div style="width: 100%">
        <b-table
          :items="dataset"
          :fields="fields"
          :current-page="currentPage"
          :busy="examAnswerSheetsPending || companyAnswersheetsCountPending"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          sort-icon-left
        >
          <template v-slot:cell(overallResult.score)="props">
            {{ props.item.overallResult.score }} ({{
              props.item.overallResult.percent.toFixed(2)
            }}
            %)
          </template>
          <template v-slot:cell(info)="props">
            <b-button
              variant="primary"
              @click="toExamTakerDetails(props.item._id)"
              >ดูรายละเอียด</b-button
            >
          </template>
          <div slot="table-busy" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>กำลังโหลด...</strong>
          </div>
        </b-table>
        <b-row>
          <b-col md="2" class="my-1">
            <b-form class="mb-2" inline>
              <label class="mb-2 mr-sm-2 mb-sm-0" for="inline-form-input-name"
                >แสดง</label
              >
              <b-form-select
                v-model="perPage"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="pageOptions"
              ></b-form-select>
              จาก{{ totalItems }}
            </b-form>
          </b-col>
          <b-col md="10" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              align="right"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-card>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "ExamInsightTable",
  components: {},
  props: {
    examId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [
        { label: "ชื่อผู้สอบ", key: "examTakerName", sortable: true },
        { label: "อีเมล", key: "metadata.memberEmail" },
        { label: "วันที่สอบ", key: "markedAt", sortable: true },
        { label: "คะแนน (%)", key: "overallResult.score", sortable: true },
        { label: "รายละเอียด", key: "info" }
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      periodDateFrom: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      periodDateTo: moment().format("YYYY-MM-DD"),
      periodDateToMax: moment().format("YYYY-MM-DD"),
      nameOrEmailSearchText: "",
      totalItems: 0,
      sort: {
        by: "markedAt",
        desc: true
      }
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyInfo",
      "examAnswerSheetsData",
      "examAnswerSheetsPending",
      "companyAnswersheetsCountData",
      "companyAnswersheetsCountPending"
    ]),
    dataset() {
      return _.map(this.examAnswerSheetsData, ech => {
        ech.markedAt = moment(ech.markedAt)
          .utc()
          .format("LL");
        if (ech._guest) {
          ech.examTakerName = ech._guest.name;
          ech.email = ech._guest.email;
        }
        return ech;
      });
    }
  },
  watch: {
    sort: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true
    },
    currentPage: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true
    },
    perPage: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("Company", [
      "fetchAnswersheets",
      "fetchAnswersheetsCountByCompanyId"
    ]),
    async getDataFromApi() {
      const payload = {
        companyId: this.companyInfo.id,
        markedFrom: moment(this.periodDateFrom)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        markedTo: moment(this.periodDateTo)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        examId: this.examId,
        nameOrEmail: this.nameOrEmailSearchText
      };
      let pageRequest = {
        sortBy: this.sort.by,
        descending: this.sort.desc,
        page: this.currentPage,
        rowsPerPage: this.perPage
      };
      await this.fetchAnswersheetsCountByCompanyId(payload);
      await this.fetchAnswersheets({
        ...payload,
        pagination: pageRequest
      });
      this.totalItems = this.companyAnswersheetsCountData;
    },
    handlePeriodDateChange() {
      this.presetPeriod = "";
      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
    },
    handleSearchClicked() {
      this.currentPage = 1;
      this.getDataFromApi();
    },
    toExamTakerDetails(answersheetId) {
      this.$router.push({
        name: "analytics-exam-taker-details",
        params: {
          id: answersheetId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-st {
  padding-top: 1em;
}
</style>

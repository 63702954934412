<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="รีวิวทั้งหมด"></PageHeader>
      <b-row>
        <b-col cols="12">
          <review
            :apiRoute="`/v1/analytics/courses/getCourseOverviewReview`"
            :perPage="10"
            :tab="true"
          ></review>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import review from "./panel/CourseReviewPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {};
  },
  components: {
    PageHeader,
    review
  }
};
</script>
<style scoped></style>

<template>
  <div>
    <p>Affiliate Revenue component</p>
  </div>
</template>

<script>
import api from "@/services/api.js";
export default {
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
};
</script>

<style>
</style>
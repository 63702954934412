<template>
  <div>
    <page-header
      :title="
        `การเข้าเรียนล่าสุดของ ${
          memberInfo ? memberInfo.firstName + ' ' + memberInfo.lastName : ''
        }`
      "
    ></page-header>
    <layout class="m-4">
      <template v-slot:header> </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(lectureName)="data">
              <span>{{ data.item.lectureName }}</span>
            </template>
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.courseName }}</span>
            </template>
            <template v-slot:cell(totalLearningTime)="data">
              <span>{{
                data.item.totalLearningTime | thaiDurationFilter
              }}</span>
            </template>
            <template v-slot:cell(date)="data">
              <span>{{ data.item.date | thaiDateFilter }}</span>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="recent-study-table"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  components: {
    PageHeader,
    layout,
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      memberId: null,
      memberInfo: null,
      companyInfo: null,
      isLoading: false,
      fields: [
        { key: "lectureName", label: "บทเรียน" },
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "totalLearningTime", label: "เวลาที่ใช้เรียน" },
        { key: "date", label: "วันที่เข้าเรียน" },
      ],
      items: [],
      currentPage: 1,
      perPage: 15,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.setMemberId();
    this.setMemberInfo();
    this.setCompanyInfo();
    this.fetchMemberRecentStudies();
  },
  methods: {
    thaiDurationFilter(value) {
      if (value) {
        let [hourString, minuteString, secondString] = value.split(":");
        let hour = parseInt(hourString, 10);
        let minute = parseInt(minuteString, 10);
        let second = parseInt(secondString, 10);
        let result = "";
        if (hour > 0) {
          result += `${hour} ชั่วโมง `;
        }
        if (minute > 0) {
          result += `${minute} นาที `;
        }
        if (second > 0) {
          result += `${second} วินาที`;
        }
        return result;
      }
      return `-`;
    },
    thaiDateFilter(value) {
      if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach((i) => {
        let temp = {
          name: i.lectureName,
          courseName: i.courseName,
          totalLearningTime: this.thaiDurationFilter(i.totalLearningTime),
          date: this.thaiDateFilter(i.date),
        };
        value.push(temp);
      });
      let key = ["บทเรียน", "คอร์สเรียน", "เวลาที่ใช้เรียน", "วันที่เข้าเรียน"];
      let filename = "User-Individual-Recent-Study-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    setMemberId() {
      this.memberId = this.$route.params.mId;
    },
    async setMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
    setCompanyInfo() {
      this.companyInfo = { ...this.$store.state.Company.companyInfo };
    },
    async fetchMemberRecentStudies() {
      this.isLoading = true;
      try {
        const results = await api.get(
          `/v1/members/${this.memberId}/recent-studies`
        );
        this.items = results.data.data;
      } catch (err) {
        console.log("fetchMemberRecentStudies error", err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <PageHeader title="รายงานการรับรู้รายได้"></PageHeader>
    <b-container class="widthPage">
      <b-tabs card>
        <b-tab title="รายบุคคล" active @click="changeReport()">
          <p class="ml-3"><strong>กรุณาเลือกเดือน และ ปีที่ต้องการ:</strong></p>
          <vue-monthly-picker
            :monthLabels="monthOptions"
            v-model="dateSelected"
            dateFormat="MMM-YYYY"
            @selected="updateInputData('individual')"
            style="display: inline-block"
          >
          </vue-monthly-picker>
          <b-button
            @click="exportReport('individual')"
            id="export-excel"
            variant="primary"
            disabled
            href=""
            ><i class="fa fa-angle-double-down" aria-hidden="true"></i>
            ดาวน์โหลดรายงาน
          </b-button>
          <b-spinner
            id="export-excel-loading"
            class="align-middle ml-3"
            style="display: none"
          ></b-spinner>
          <ag-grid-vue
            style="width: 100%; height: 800px; display: none"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            @grid-ready="onGridReady"
            :rowData="rowData"
            :excelStyles="excelStyles"
          ></ag-grid-vue>
        </b-tab>
        <b-tab title="รายหลักสูตร" @click="changeReport()">
          <p class="ml-3"><strong>กรุณาเลือกเดือน และ ปีที่ต้องการ:</strong></p>
          <vue-monthly-picker
            :monthLabels="monthOptions"
            v-model="dateSelected"
            dateFormat="MMM-YYYY"
            @selected="updateInputData('course')"
            style="display: inline-block"
          >
          </vue-monthly-picker>
          <b-button
            @click="exportReport('course')"
            id="export-course-excel"
            variant="primary"
            disabled
            href=""
            ><i class="fa fa-angle-double-down" aria-hidden="true"></i>
            ดาวน์โหลดรายงาน
          </b-button>
          <b-spinner
            id="export-course-excel-loading"
            class="align-middle ml-3"
            style="display: none"
          ></b-spinner>
          <ag-grid-vue
            style="width: 100%; height: 800px; display: none"
            class="ag-theme-alpine"
            :columnDefs="courseColumnDefs"
            @grid-ready="onGridReady"
            :rowData="rowData"
            :excelStyles="excelStyles"
          ></ag-grid-vue>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import api from "@/services/api.js";
import VueMonthlyPicker from "vue-monthly-picker";

export default {
  components: {
    PageHeader,
    VueMonthlyPicker,
    "ag-grid-vue": AgGridVue
  },
  data() {
    return {
      monthOptions: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      dateSelected: null,
      monthSelected: null,
      yearSelected: null,
      orderStartDate: null,
      reportStartDate: null,
      monthType: null,
      excelStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0"
          }
        },
        {
          id: "currencyFormat",
          numberFormat: {
            format: "#,##0.00 €"
          }
        },
        {
          id: "negativeInBrackets",
          numberFormat: {
            format: "$[blue] #,##0;$ [red](#,##0)"
          }
        },
        {
          id: "booleanType",
          dataType: "Boolean"
        },
        {
          id: "stringType",
          dataType: "String"
        },
        {
          id: "dateType",
          dataType: "DateTime"
        },
        {
          id: "dateISO",
          dataType: "DateTime",
          numberFormat: {
            format: "dd-mm-yyyy"
          }
        },
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "decimalFormat",
          numberFormat: {
            format: "#,##0.00"
          },
          alignment: {
            horizontal: "Right"
          }
        }
      ],
      courseColumnDefs: [
        {
          headerName: "รายงานการรับรู้รายได้เดือน",
          children: [
            { field: "ลำดับ", valueGetter: hashValueGetter },
            {
              headerName: "",
              children: [
                {
                  headerName: "ข้อมูลหลักสูตร",
                  children: [
                    { field: "รหัสหลักสูตร", minWidth: 25 },
                    { field: "ชื่อหลักสูตร", minWidth: 25 },
                    {
                      field: "วันที่เริ่ม",
                      cellClass: params => {
                        return params.value == " " ? "" : "dateISO";
                      },
                      minWidth: 25
                    },
                    {
                      field: "วันที่สิ้นสุด",
                      cellClass: params => {
                        return params.value == " " ? "" : "dateISO";
                      },
                      minWidth: 25
                    },
                    { field: "ความยาวหลักสูตร (วัน)", minWidth: 25 },
                    {
                      field: "จำนวนเงิน ก่อนภาษีขาย",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    },
                    {
                      field: "ภาษีขาย (VAT)",
                      cellClass: "decimalFormat"
                    },
                    {
                      field: "จำนวนเงินรวม",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            },
            { field: "จำนวนผู้เรียนในหลักสูตร" },
            {
              headerName: "ข้อมูลการเข้าอบรม",
              children: [
                {
                  headerName: "ยอดสะสมยกมา",
                  children: [
                    { headerName: "วัน", field: "วัน (สะสม)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (สะสม)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                },
                {
                  headerName: "งวดปัจจุบัน",
                  children: [
                    {
                      headerName: "วัน",
                      field: "วัน (ปัจจุบัน)",
                      minWidth: 25
                    },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                },
                {
                  headerName: "ยอดสะสมทั้งหมด",
                  children: [
                    { headerName: "วัน", field: "วัน (ทั้งหมด)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (ทั้งหมด)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            },
            {
              headerName: "รายได้รับล่วงหน้า",
              children: [
                {
                  headerName:
                    "ที่เหลือจากงวดปัจจุบัน ถึง วันที่สิ้นสุดหลักสูตร",
                  children: [
                    { headerName: "วัน", field: "วัน (คงเหลือ)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (คงเหลือ)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      columnDefs: [
        {
          headerName: "รายงานการรับรู้รายได้เดือน",
          children: [
            { field: "ลำดับ", valueGetter: hashValueGetter },
            {
              field: "วันที่ชำระเงิน",
              cellClass: params => {
                return params.value == " " ? "" : "dateISO";
              }
            },
            { field: "เวลาชำระเงิน", minWidth: 25 },
            { field: "เลขที่คำสั่งซื้อ", minWidth: 25 },
            { field: "เลขที่ใบเสร็จ", minWidth: 25 },
            {
              field: "วันที่ใบเสร็จ",
              cellClass: params => {
                return params.value == " " ? "" : "dateISO";
              },
              minWidth: 25
            },
            {
              field: "รหัสผู้อบรม (ID)",
              minWidth: 25,
              cellClass: "stringType"
            },
            { field: "ชื่อ-นามสกุล ผู้อบรม", minWidth: 25 },
            {
              headerName: "",
              children: [
                {
                  headerName: "ข้อมูลหลักสูตร",
                  children: [
                    { field: "รหัสหลักสูตร", minWidth: 25 },
                    { field: "ชื่อหลักสูตร", minWidth: 25 },
                    {
                      field: "วันที่เริ่ม",
                      cellClass: params => {
                        return params.value == " " ? "" : "dateISO";
                      },
                      minWidth: 25
                    },
                    {
                      field: "วันที่สิ้นสุด",
                      cellClass: params => {
                        return params.value == " " ? "" : "dateISO";
                      },
                      minWidth: 25
                    },
                    { field: "ความยาวหลักสูตร (วัน)", minWidth: 25 },
                    {
                      field: "จำนวนเงิน ก่อนภาษีขาย",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    },
                    {
                      field: "ภาษีขาย (VAT)",
                      cellClass: "decimalFormat"
                    },
                    {
                      field: "จำนวนเงินรวม",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            },
            {
              headerName: "ข้อมูลการเข้าอบรม",
              children: [
                {
                  headerName: "สิทธิ์ที่ได้",
                  children: [
                    { field: "วัน", minWidth: 25 },
                    {
                      field: "จำนวนเงิน/วัน",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                },
                {
                  headerName: "ยอดสะสมยกมา",
                  children: [
                    { headerName: "วัน", field: "วัน (สะสม)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (สะสม)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                },
                {
                  headerName: "งวดปัจจุบัน",
                  children: [
                    {
                      headerName: "วัน",
                      field: "วัน (ปัจจุบัน)",
                      minWidth: 25
                    },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                },
                {
                  headerName: "ยอดสะสมทั้งหมด",
                  children: [
                    { headerName: "วัน", field: "วัน (ทั้งหมด)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (ทั้งหมด)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            },
            {
              headerName: "คงเหลือ",
              children: [
                {
                  headerName:
                    "ที่เหลือจากงวดปัจจุบัน ถึง วันที่สิ้นสุดหลักสูตร",
                  children: [
                    { headerName: "วัน", field: "วัน (คงเหลือ)", minWidth: 25 },
                    {
                      headerName: "จำนวนเงิน",
                      field: "จำนวนเงิน (คงเหลือ)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 100,
        flex: 1
      },
      rowData: null
    };
  },
  methods: {
    changeReport() {
      this.dateSelected = null;
      document.getElementById("export-excel").disabled = true;
      document.getElementById("export-course-excel").disabled = true;
    },
    updateInputData(reportType) {
      let colDef = null;
      if (reportType == "individual") {
        colDef = this.columnDefs;
      } else if (reportType == "course") {
        colDef = this.courseColumnDefs;
      }
      let date = this.dateSelected ? this.dateSelected._i.split("/") : null;
      if (date) {
        this.monthSelected = String(date[1]).padStart(2, "0");
        this.yearSelected = date[0];
        let monthName = this.monthOptions[this.monthSelected - 1];
        this.monthType = this.monthSelected % 3 == 0 ? "LAST" : "NORMAL";
        if (this.monthType == "LAST") {
          if (
            (reportType == "individual" && colDef[0].children.length == 11) ||
            (reportType == "course" && colDef[0].children.length == 5)
          ) {
            let lastTrimonthHeader = {
              headerName: "",
              children: [
                {
                  headerName: "ข้อมูลเดือนสุดท้ายของไตรมาส",
                  children: [
                    {
                      field: "รายได้ในเดือน (บาท)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    },
                    {
                      field: "รายได้สะสม (บาท)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    },
                    {
                      field: "รายได้รับล่วงหน้า (บาท)",
                      cellClass: "decimalFormat",
                      minWidth: 25
                    }
                  ]
                }
              ]
            };
            colDef[0].children.push(lastTrimonthHeader);
          }
        } else {
          if (
            (reportType == "individual" && colDef[0].children.length > 11) ||
            (reportType == "course" && colDef[0].children.length > 5)
          ) {
            colDef[0].children.pop();
          }
        }
        colDef[0].headerName =
          "รายงานการรับรู้รายได้เดือน" + monthName + " " + this.yearSelected;
        this.gridApi.setColumnDefs(colDef);
        this.reportStartDate = `${this.yearSelected}-${this.monthSelected}-01`;
        this.orderStartDate = this.setOrderStartDate();
        if (reportType == "individual") {
          document.getElementById("export-excel").disabled = false;
          document.getElementById("export-excel").classList.remove("disabled");
        } else if (reportType == "course") {
          document.getElementById("export-course-excel").disabled = false;
          document
            .getElementById("export-course-excel")
            .classList.remove("disabled");
        }
      } else {
        if (reportType == "individual") {
          document.getElementById("export-excel").disabled = true;
        } else if (reportType == "course") {
          document.getElementById("export-course-excel").disabled = true;
        }
      }
    },
    setOrderStartDate() {
      let orderMonth = this.monthSelected;
      if (this.monthSelected % 3 == 2) {
        orderMonth = this.monthSelected - 1;
      } else if (this.monthSelected % 3 == 0) {
        orderMonth = this.monthSelected - 2;
      }
      return `${this.yearSelected}-${String(orderMonth).padStart(2, "0")}-01`;
    },
    async exportReport(reportType) {
      let spinner = null;
      let exportBtn = null;
      if (reportType == "individual") {
        spinner = "export-excel-loading";
        exportBtn = "export-excel";
      } else if (reportType == "course") {
        spinner = "export-course-excel-loading";
        exportBtn = "export-course-excel";
      }
      document.getElementById(spinner).style.display = "inline-block";
      document.getElementById(exportBtn).disabled = true;
      await api
        .get(
          `/v1/tfac/revenue-report?reportStartDate=${this.reportStartDate}&monthType=${this.monthType}&reportType=${reportType}`
        )
        .then(res => {
          this.rowData = res.data;
        });
      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "รายงานการรับรู้รายได้",
          fileName: `revenue_${reportType}_report_${this.monthSelected}_${this.yearSelected}`
        });
        document.getElementById(spinner).style.display = "none";
        document.getElementById(exportBtn).disabled = false;
        document.getElementById(exportBtn).classList.remove("disabled");
        if (!this.dateSelected) {
          document.getElementById(exportBtn).disabled = true;
        }
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  }
};
let hashValueGetter = function(params) {
  return !params.node.lastChild ? params.node.rowIndex + 1 : "รวม";
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

<template>
  <b-form-group
    label-for="detail"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
  >
    <template v-slot:label>
      <span class="mb-2">{{ label }}</span>

      <b-button
        v-if="showSaveButton"
        @click="editCourseInfo()"
        size="sm"
        variant="primary"
        class="mx-2"
      >
        บันทึก
      </b-button>
      <span v-if="isUpdated" class="small mx-2">
        <b-icon-check scale="2" class="text-success"></b-icon-check>
        บันทึกแล้ว
      </span>
    </template>

    <quill-editor
      ref="myQuillEditor"
      v-model="tempValue"
      :options="editorOption"
      @change="onEditorChange"
    />

    <template v-slot:description>
      <div v-if="false" class="d-flex justify-content-between mt-0">
        <span>{{ description }}</span>
        <span :class="{ 'text-danger': wordsCount >= 1000 }">
          {{ wordsCount }}/1,000 ตัวอักษร
        </span>
      </div>
    </template>
  </b-form-group>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { quillEditor } from 'vue-quill-editor';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TextEditor',
  filters: {
    wordsCount: function(value) {
      if (value) {
        const cleanCode = value
          .replace(/<(?:.|\n)*?>/gm, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace('&nbsp;', '');

        return cleanCode.trim().length;
      }

      return 0;
    },
  },
  components: {
    quillEditor,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: { type: String, default: '' },
    attribute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: true,
        },
        placeholder: 'รายละเอียดคอร์ส',
      },
      validFeedback: '',
      invalidFeedback: '',
      temp: '',
      content: '',
      showSaveButton: false,
      isUpdated: false,
    };
  },
  computed: {
    ...mapGetters('CourseManagement', ['isLoading', 'courseAttributeUpdated']),
    tempValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.temp = val;
      },
    },
    wordsCount() {
      return this.temp.length;
    },
  },
  methods: {
    ...mapActions('CourseManagement', ['updateCourseInfo', 'getCourseInfo']),
    editCourseInfo() {
      this.updateCourseInfo({
        courseId: this.$route.params.courseId,
        attribute: { [this.attribute]: this.content },
      }).then(() => {
        this.showSaveButton = false;
        this.isUpdated = true;
        this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
      });
    },
    onEditorChange({ html }) {
      this.content = html;
      this.showSaveButton = true;
      this.isUpdated = false;
    },
  },
};
</script>

<style lang="css">
.ql-container {
  min-height: 300px;
}
</style>

<template>
  <div>
    <page-header title="สรุปภาพรวมข้อสอบ"></page-header>
    <b-container>
      <b-row>
        <b-col>
          <div>
            <b-row class="py-3">
              <b-col class="col-4 col-md-4 col-lg-3">
                <b-row>
                  <b-col class="col-12">
                    <student-amount-panel></student-amount-panel>
                  </b-col>
                  <b-col class="col-12 mt-2">
                    <exam-amount-panel></exam-amount-panel>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mt-4 mt-lg-0 col-9">
                <most-exam-panel :mostExams="mostExams"></most-exam-panel>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <recent-exam-panel
                  :recentExams="recentExams"
                ></recent-exam-panel>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import StudentAmountPanel from './panel/StudentAmount.vue';
import ExamAmountPanel from './panel/ExamAmount.vue';
import MostExamPanel from './panel/MostExam.vue';
import RecentExamPanel from './panel/RecentExam.vue';
import adminService from '@/services/api.js';

export default {
  components: {
    PageHeader,
    StudentAmountPanel,
    ExamAmountPanel,
    MostExamPanel,
    RecentExamPanel,
  },
  data() {
    return {
      company: null,
      recentExams: null,
      mostExams: null,
      amount: null,
      quizIds: null,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAvailableQuizIds();
  },
  methods: {
    async fetchRecentExams() {
      try {
        const endpoint = `/v1/analytics/exams/recent-answer-sheets/${this.company.id}?q=1&limit=10`;
        const result = await adminService.get(endpoint);
        this.recentExams = result.data;
      } catch (err) {
        console.log('fetchRecentExams error!', err);
      }
    },
    async fetchMostExams() {
      try {
        const endpoint = `/v1/analytics/exams/most-exams/${this.company.id}?q=1&limit=10`;
        const result = await adminService.get(endpoint);
        this.mostExams = result.data;
      } catch (err) {
        console.log('fetchMostExams error!', err);
      }
    },
    async fetchAvailableQuizIds() {
      this.fetchMostExams();
      this.fetchRecentExams();
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <b-row>
      <!-- Member information -->
      <b-col cols="6">
        <div class="d-flex">
          <div class="img">
            <b-img
              class="profile-image-style"
              :src="
                memberInfo
                  ? memberInfo.image_path
                    ? `${baseUrl}/${memberInfo.image_path.slice(
                        1,
                        -1
                      )}?token=${token}`
                    : baseUrlImage
                  : ''
              "
              rounded="circle"
            ></b-img>
          </div>
          <div class="box-name ml-5">
            <h6>ชื่อผู้เรียน</h6>
            <p>{{ memberInfo ? memberInfo.mp_name : "-" }}</p>
          </div>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="box-name">
          <h6>สถานะเรียนจบ</h6>
          <p
            :class="[
              memberInfo
                ? memberInfo.cer_status === 'COMPLETED'
                  ? 'text-success'
                  : memberInfo.cer_status === 'NOT_STARTED'
                  ? 'text-danger'
                  : 'text-warning'
                : '',
            ]"
          >
            {{
              memberInfo
                ? memberInfo.cer_status == "COMPLETED"
                  ? "เรียนจบแล้ว"
                  : memberInfo.cer_status == "IN_PROGRESS"
                  ? "กำลังเรียน"
                  : memberInfo.cer_status == "NOT_STARTED"
                  ? "ยังไม่เริ่มเรียน"
                  : "-"
                : "-"
            }}
          </p>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="box-name">
          <h6>วันที่เรียนจบ</h6>

          <p>
            {{
              memberInfo
                ? memberInfo.cer_endDate
                  ? memberInfo.cer_endDate
                  : "ยังเรียนไม่จบ"
                : "" | thaiDateFilter
            }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import api from "@/services/api.js";

export default {
  mixins: [dateMixins],
  components: {},
  data() {
    return {
      token: localStorage.getItem("token"),
      baseUrlImage: "https://s1.coursesquare.co/data/images/display.jpg",
      memberId: this.$route.params.mId,
      memberInfo: null,
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com/",
    };
  },
  computed: {},
  async created() {
    this.memberId = this.$route.params.mId;
    this.fetchMemberInfo();
  },
  methods: {
    async fetchMemberInfo() {
      try {
        const endpoint = `/v1/user-course-enroll/${this.cerId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
  },
};
</script>

<style scoped>
.profile-image-style {
  width: 6rem;
  height: 6rem;
}

.test-red-border {
  border: 2px solid red;
  padding: 5px;
}
</style>

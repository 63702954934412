<template>
  <Layout>
    <div v-if="!isLoading">
      <bar-chart :chart-data="chartData" :options="chartOption" :height="300">
      </bar-chart>
    </div>
    <div v-else style="text-align: center">
      <b-spinner class="align-middle mr-2"></b-spinner>
      <strong>กำลังโหลดข้อมูล...</strong>
    </div>
  </Layout>
</template>

<script>
import BarChart from "@/components/charts/BarChart.js";
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";
export default {
  components: {
    BarChart,
    Layout
  },
  props: {
    company: Object,
    begin: String,
    end: String,
    dep: String,
    group: String,
    course: Number,
    statType: String
  },
  data() {
    return {
      chartData: {},
      chartOption: {},
      checkData: false,
      backgroundColor: "orange",
      branch: "",
      gId: "",
      cId: "",
      startDate: "",
      endDate: "",
      isLoading: true
    };
  },
  created() {
    this.setChartOption();
    this.updateChart();
  },
  methods: {
    setChartOption() {
      this.chartOption = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20,
                min: 0
              },
              scaleLabel: {
                display: true,
                labelString: "ชั่วโมง",
                fontSize: 12
              }
            }
          ]
        }
      };
    },
    async updateChart() {
      if (this.statType == "year") {
        this.startDate = this.begin.substr(0, 4);
        this.endDate = this.end.substr(0, 4);
      } else if (this.statType == "month") {
        this.startDate = this.begin.substr(0, 7);
        this.endDate = this.end.substr(0, 7);
      } else if (this.statType == "day") {
        this.startDate = this.begin;
        this.endDate = this.end;
      }
      if (this.dep != "*") {
        this.branch = this.dep;
      }
      if (this.group != "*") {
        this.gId = this.group;
      }
      if (this.course) {
        this.cId = this.course;
      }
      let route = `/v1/total-learning-stat-by-period/${this.statType}/${this.startDate}/${this.endDate}?dep=${this.branch}&gId=${this.gId}&cId=${this.cId}`;
      const res = await api.get(route);
      this.chartData = {
        labels: res.data.labels,
        datasets: [
          { data: res.data.datasets, backgroundColor: this.backgroundColor }
        ]
      };
      this.isLoading = false;
    }
  }
};
</script>

<template>
  <div>
    <PageHeader title="กระดาษคำตอบ" icon="book"></PageHeader>
    <b-container fluid="xl" class="py-5">
      <div class="inner bg-container">
        <div class="row">
          <div class="col">
            <b-card class="card-layout">
              <div slot="header">
                <i class="fas fa-clipboard-list" aria-hidden="true" />
                คำตอบทั้งหมด
              </div>
              <AnswerSheetListTable />
            </b-card>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../../layouts/PageHeader.vue";
import AnswerSheetListTable from "@/components/pages/exam-analytics/answer-sheet-list/answer-sheet-list-table/AnswerSheetListTable.vue";
import api from "@/services/api.js";
export default {
  components: {
    PageHeader,
    AnswerSheetListTable,
  },
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
};
</script>
<style scoped>
.card-layout {
  box-shadow: 2px 2px 9px rgb(0 0 0 / 16%);
  border-radius: 6px;
  /* padding: 20px 35px; */
  border: 0;
}
.outer {
  margin-left: 190px;
}
</style>

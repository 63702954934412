<template>
  <b-modal v-model="show" centered hide-header hide-footer no-close-on-backdrop>
    <div class="modal-body pa-4">
      <div class="text-center">
        <h3 class="pt-4">
          <b-icon-check-circle scale="2.4" class="text-success" />
        </h3>
        <br />
        <h3 style="font-weight: 600;">{{ title }}</h3>
      </div>
    </div>

    <div class="modal-footer text-center border-top-0">
      <div>
        <b-button variant="primary" @click="$emit('success')">
          เสร็จสิ้น
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmAddClassAttendanceModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.modal-footer {
  justify-content: center !important;
}
</style>

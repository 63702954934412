<template>
  <div class=" post-template mt-2">
    <div class="sender-address-wrapper">
      <b-row>
        <b-col>
          <b-img
          :src="this.company.logo"
          style="max-height: 48px;"></b-img>
          <div class="sender-address mt-1">
            <h6>{{ this.companyInfo.com_name }}</h6>
            <h6>{{ this.companyInfo.com_address }}</h6>
          <hr><hr>
          <h6>เบอร์โทร: {{ this.companyInfo.com_contactPhone }}</h6>
          <hr>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="receiver-address-wrapper">
      <div class="receiver-address">
        <b-row>
          <b-col>
            <h6>กรุณาส่ง</h6><hr>
            <h4>ชื่อ: {{ this.$route.query.name != 'null' ? this.$route.query.name : "" }} </h4><hr>
            <h4>ที่อยู่: {{ this.$route.query.address != 'null' ? this.$route.query.address : "" }} </h4><hr>
            <h4>เบอร์โทร: {{ this.$route.query.phone != 'null' ? this.$route.query.phone : "" }} </h4><hr>
            <h6>หมายเหตุ: {{ this.$route.query.cName != 'null' ? this.$route.query.cName : "" }}</h6><hr>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";

export default {
  components: {

  },

  data() {
    return {
      company: {},
      companyInfo: {},
    };
  },
  async mounted() {
    await this.setCompany();
    await this.setCompanyInfo();
    window.focus();
    window.print();
  },
  methods: {
    setCompany() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    async setCompanyInfo() {
      await api.get(`v1/companyInfo/${this.$route.query.comId}`)
      .then(({data}) => { this.companyInfo = data })
    },
  }
};
</script>
<style>
.post-template {
  width:842px;
  height:560px;
  position:relative;
}
.sender-address-wrapper {
  position:absolute;
  top:1px;
  left:1px;
}
.sender-address {
  width:350px;
  padding:10px 10px 10px 0px;
}
.receiver-address-wrapper {
  position:absolute;
  bottom:1px;
  right:1px;
}
.receiver-address {
  width:450px;
}
</style>

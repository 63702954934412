<template>
  <div>
    <Editor
      :initialValue="editorText"
      ref="toastuiEditor"
      :options="defaultOptions"
      :initialEditType="EditType"
      previewStyle="vertical"
      height="500px"
      @blur="onUpdate()"
    />
    <div v-if="showButton != false" class="editor-button mt-3 ">
      <b-button @click="onhideModel()" class="mr-1" variant="outline-primary"
        >ยกเลิก</b-button
      >
      <b-button @click="onSave()" variant="primary" class="mx-2"
        >บันทึก
      </b-button>
    </div>
  </div>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor.css";
import { mapActions, mapGetters } from "vuex";
import { Editor } from "@toast-ui/vue-editor";

export default {
  namespaced: true,
  components: {
    Editor,
  },
  props: [
    "editorText",
    "EditType",
    "showButton",
  ],

  data() {
    return {
      token: localStorage.getItem("token"),
      defaultOptions: {
        language: "th-TH",
        useCommandShortcut: false,
        usageStatistics: true,
        hideModeSwitch: true,
        toolbarItems: [
          ["heading", "bold", "italic"],
          ["ul", "ol", "indent", "outdent"],
          ["table", "link", "image"],
          ["scrollSync"],
        ],
      },
    };
  },
  mounted() {
  },
  watch: {
    editorText(newValue) {
      this.$refs.toastuiEditor.invoke("setMarkdown", newValue);
    },
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
  },
  methods: {
    ...mapActions("ExamBuilder", ["findExamsbyId"]),
    fetch() {
      this.findExamsbyId(this.exam.id);
    },
    onhideModel() {
      this.$emit("hideModal");
    },
    onSave() {
      this.onhideModel();
      this.$emit("onSave", this.$refs.toastuiEditor.invoke("getMarkdown"));
    },
    onUpdate() {
      this.$emit("updateDesc", this.$refs.toastuiEditor.invoke("getMarkdown"));
    },
  },
};
</script>

<style lang="css" scope>
.editor-button {
  display: flex;
  justify-content: flex-end;
}
</style>

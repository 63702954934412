import { render, staticRenderFns } from "./CoursePromotionGroupTagEdit.vue?vue&type=template&id=077cf80e&scoped=true&"
import script from "./CoursePromotionGroupTagEdit.vue?vue&type=script&lang=js&"
export * from "./CoursePromotionGroupTagEdit.vue?vue&type=script&lang=js&"
import style0 from "./CoursePromotionGroupTagEdit.vue?vue&type=style&index=0&id=077cf80e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077cf80e",
  null
  
)

export default component.exports
<template>
  <div class>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col cols="6">
            <h6>การเข้าเรียนล่าสุด</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a :href="`${$route.path}/recent-study`" target="_blank">
              <h6>ดูทั้งหมด</h6>
            </a>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(lectureName)="data">
              <span>{{ data.item.lectureName | cut }}</span>
            </template>
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.courseName | cut }}</span>
            </template>
            <template v-slot:cell(totalLearningTime)="data">
              <span>{{
                data.item.totalLearningTime | thaiDurationFilter
              }}</span>
            </template>
            <template v-slot:cell(date)="data">
              <span>{{ data.item.date | thaiDateFilter }}</span>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";

export default {
  components: {
    layout,
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      isLoading: false,
      fields: [
        { key: "lectureName", label: "บทเรียน" },
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "totalLearningTime", label: "เวลาที่ใช้เรียน" },
        { key: "date", label: "วันที่เข้าเรียน" },
      ],
      items: [],
    };
  },
  created() {
    this.memberId = this.$route.params.mId;
    this.fetchMemberRecentStudies();
  },
  methods: {
    async fetchMemberRecentStudies() {
      this.isLoading = true;
      try {
        const results = await api.get(
          `/v1/members/${this.memberId}/recent-studies`
        );
        this.items = results.data.data.slice(0, 10);
      } catch (err) {
        console.log("fetchMemberRecentStudies error", err);
      }
      this.isLoading = false;
    },
  },
};
</script>


<style scoped>
@media (min-width: 992px) {
  .table-responsive {
    height: 240px !important;
  }
}

.table-responsive::-webkit-scrollbar {
  width: 5px;
}
</style>

<template>
  <div>
    <div class="choice-header">
      <div class="button-choice">
        <span class="font-weight-bolder mr-2">ตัวเลือก</span>
        <span
          v-if="choices.length < 2"
          class="text-danger"
        >คำถามจำเป็นที่จะต้องมีอย่างน้อย 2 ตัวเลือก</span>
        <p
          v-if="!hasAnswers[question.id] || selectedChoices.length == 0"
          class="text-danger"
        >
          ยังไม่กำหนดคำตอบของคำถาม
        </p>
      </div>
      <div class="example-switch">
        <b-form-checkbox
          v-model="isShowedExample"
          name="check-button"
          switch
        >
          <span class="small">แสดงตัวอย่าง</span>
        </b-form-checkbox>
      </div>
    </div>

    <draggable
      :list="choices"
      @end="reOrderChoices()"
      handle=".handle"
      animation="200"
      :disabled="disableDrag"
    >
      <div
        v-for="(choice, choiceIndex) in choices"
        :key="choiceIndex"
        class="choice-list"
        :class="{ disabled: disableDrag }"
      >
        <div class="choice-text">
          <div class="choice-text-info">
            <img
              src="/images/icons/icon_movable.png"
              alt="movable"
              height="20"
              class="align-middle icon-move-sections"
              :class="{ 'handle pointer': !disableDrag }"
            />
            <span class="font-weight-bolder mr-2">{{ choiceIndex + 1 }}</span>

            <!-- CHOICE ANSWER -->
            <b-form-checkbox
              :checked="isSelected(choice.id)"
              @change="toggleSelected(choice.id)"
              :disabled="disableDrag"
            ></b-form-checkbox>
          </div>

          <!-- CHOICE TITLE -->
          <b-form-input
            class="mr-2"
            v-model="choice.text"
            trim
            :disabled="disableDrag"
            :state="titleChoicesState(choice.text)"
            @change="handleChoiceUpdate(choice.id, choice.text, choiceIndex)
            "
          ></b-form-input>
          <div class="choice-button-group">
            <!-- BUTTON OPEN CHOICE TITLE EDITOR -->
            <b-button
              :disabled="disableDrag"
              size="xs"
              variant="primary"
              class="choice-button mr-1"
              @click="setChoiceTextInModal(choiceIndex, choice.text, choice.id)"
            >
              <b-icon
                class="choice-button-icon"
                icon="pencil-square"
              ></b-icon>
            </b-button>

            <!-- BUTTON DELETE CHOICE -->
            <b-button-close
              :disabled="disableDrag"
              class="choice-button mr-1"
              @click="handleChoiceDelete(choice.id, choiceIndex)"
            >
              <b-icon
                class="h3 mb-0 font-weight-bold"
                icon="x"
              ></b-icon>
            </b-button-close>
          </div>
        </div>
        <!-- ANSWER EXAMPLE -->
        <div
          v-if="isShowedExample == true"
          class="example-container mt-2"
        >
          <span class="font-weight-bolder small">ตัวอย่าง</span>
          <div class="example-text">
            <Markdown
              class="h6"
              :message="choice.text"
            />
            <b-button-close
              v-if="choice.text.search('data:image') !== -1"
              class="close-image-btn"
            >
              <b-icon
                class="h6"
                icon="x-square-fill"
                @click="deleteImage(  )"
              ></b-icon>
            </b-button-close>
          </div>
        </div>
      </div>
    </draggable>
    <!-- CREATE CHOICE -->
    <a
      v-if="choices.length < 6"
      :class="{'text-primary add-choice': true, 'disabled-link': disableCreateChoice}"
      @click="handleChoiceCreation(question._choices)"
    >
      <b-icon
        class="choice-button-icon"
        icon="plus"
      ></b-icon>
      เพิ่มตัวเลือก</a>

    <b-modal
      v-model="ModalShowChoice"
      title="ตัวเลือก"
      size="xl"
      :hide-footer="true"
      ref="modal-editor"
    >
      <ExamEditor
        :editorText="tempChoiceText"
        :EditType="markdown"
        @hideModal="hideModal($event)"
        @onSave="handleChoiceUpdate(tempChoiceId, $event, tempChoiceIndex)"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "csq-vue-multi-draggable";
import ExamEditor from "../modal/ModalExamEditor.vue";
import Markdown from "@/components/shared/Markdown.vue";

export default {
  props: {
    question: Object,
    choices: Array,
    isSubQuestion: {
      type: Boolean,
      default: false,
    },
    mainQuestionId: {
      type: String,
      default: "",
    },
  },
  components: {
    draggable,
    ExamEditor,
    Markdown,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      ModalShowChoice: false,
      disableDrag: false,
      disableCreateChoice: false,
      isShowedExample: false, 
      
      markdown: "markdown",
      
      selectedChoices: [],

      tempChoiceText: "",
      tempChoiceIndex: "",
      tempChoiceId: "",
      answer: []
    };
  },
  async created() {
    this.disableDrag = true;
    await this.getAnswerChecked();
    this.disableDrag = false;
  },
  computed: {
    ...mapGetters("ExamBuilder", [
      //entire exam
      "exam",

      // temp answer from endpoint get answer
      "allAnswer",

      // temp choice from creating choice
      "tempChoice",

      // objecct of hasAnswer
      "hasAnswers",
    ]),
  },
  methods: {
    ...mapActions("ExamBuilder", [
      //question
      "updateQuestionInfo",
      "updateSubQuestionInfo",

      // Choice
      "createChoiceForMultipleChoicesQuestion",
      "createChoiceForSubQuestion",
      "updateChoiceForMultipleChoicesQuestion",
      "updateChoiceForSubQuestion",
      "deleteChoiceForMultipleChoicesQuestion",
      "deleteChoiceForSubQuestion",

      // Answer
      "fetchQuestionAnswer",
      "createQuestionAnswer",
      "createSubQuestionAnswer",
      "updateQuestionAnswer",
      "updateSubQuestionAnswer",
      "deleteQuestionAnswer",
      "deleteSubQuestionAnswer",

      // Set question HasAnswer True/False
      "setQuestionAnswered",
      "setQuestionUnanswered",

      //set solution
      "setNoSolution"
    ]),
    // get answer
    async getAnswerChecked() {
      try {
        // Check if this question has an answer
        if (!this.hasAnswers[this.question.id]) {
          this.setNoSolution(this.question.id)
          return;
        }

        // Fetch the answer based on whether it's a sub-question or not
        await this.setQuestionAnswer();

      } catch (error) {
        console.log(error);
      }
    },

    async setQuestionAnswer() {
      this.answer = this.allAnswer.find(item => item.questionId === this.question.id)
      this.selectedChoices = [...this.answer.choiceId];
    },

    // create choice
    async handleChoiceCreation(questionChoices) {
      this.disableCreateChoice = true
      try {
        const newChoiceIndex = this.calculateNewChoiceIndex(questionChoices);
        const request = this.createChoiceRequest("คำตอบ", newChoiceIndex);

        if (this.isSubQuestion) {
          await this.createChoiceForSubQuestion(request);
        } else {
          await this.createChoiceForMultipleChoicesQuestion(request);
        }

        this.choices.push(this.tempChoice);
        this.disableCreateChoice = false
      } catch (error) {
        this.disableCreateChoice = false
        console.error('Error creating choice:', error);
      }
    },
    // update choice
    async handleChoiceUpdate(choiceId, choiceText, choiceIndex) {
      try {
        const request = this.createChoiceRequest(choiceText, choiceIndex, choiceId);

        if (this.isSubQuestion) {
          await this.updateChoiceForSubQuestion(request);
        } else {
          await this.updateChoiceForMultipleChoicesQuestion(request);
        }

        this.choices[choiceIndex].text = choiceText;
      } catch (error) {
        console.error('Error update choice:', error);
      }
    },
    // delete choice
    async handleChoiceDelete(choiceId, choiceIndex) {
      const request = this.createChoiceRequest(null, null, choiceId);

      if (this.isSubQuestion) {
        await this.deleteChoiceForSubQuestion(request);
      } else {
        await this.deleteChoiceForMultipleChoicesQuestion(request);
      }
      this.deleteSelectedChoice(choiceId);
      this.choices.splice(choiceIndex, 1);
    },

    async handleSelectedAnswer() {
      this.disableDrag = true;

      if (this.isSubQuestion) {
        await this.handleSelectedAnswerSubQuestion();
      } else {
        await this.handleSelectedAnswerQuestion();
      }

      this.disableDrag = false;
    },

    async handleSelectedAnswerQuestion() {
      const requestAnswer = this.createAnswerRequest();
      const requestInfo = this.createQuestionInfoRequest();

      if (!this.hasAnswers[this.question.id] && this.selectedChoices.length > 0) {
        //create Answer
        await this.createQuestionAnswer(requestAnswer);
        this.updateQuestionInfo(requestInfo);
        this.setQuestionAnswered(this.question.id);
        
      } else if (this.hasAnswers[this.question.id] && this.selectedChoices.length === 0) {
        //delete Answer
        requestInfo.body.hasAnswer = false;
        await this.deleteQuestionAnswer(requestAnswer);
        this.updateQuestionInfo(requestInfo);
        this.setQuestionUnanswered(this.question.id);

      } else if (this.hasAnswers[this.question.id]){
        //update Answer
        await this.updateQuestionAnswer(requestAnswer);
        this.updateQuestionInfo(requestInfo);
      }
    },

    async handleSelectedAnswerSubQuestion() {
      const requestAnswer = this.createAnswerRequest();
      const requestInfo = this.createQuestionInfoRequest();

      if (!this.hasAnswers[this.question.id]) {
        //create Answer
        await this.createSubQuestionAnswer(requestAnswer);
        this.updateSubQuestionInfo(requestInfo);
        this.setQuestionAnswered(this.question.id);

      } else if (this.hasAnswers[this.question.id] && this.selectedChoices.length === 0) {
        //delete Answer
        requestInfo.body.hasAnswer = false;
        await this.deleteSubQuestionAnswer(requestAnswer);
        this.updateSubQuestionInfo(requestInfo);
        this.setQuestionUnanswered(this.question.id);
        
      } else {
        //update Answer
        await this.updateSubQuestionAnswer(requestAnswer);
        this.updateSubQuestionInfo(requestInfo);
      }
    },
    // delete answer
    async deleteSelectedChoice(id) {
      this.selectedChoices = this.selectedChoices.filter(
        choiceId => choiceId !== id
      );

      await this.handleSelectedAnswer();
    },
    // update or create answer
    async toggleSelected(id) {
      if (this.selectedChoices.includes(id)) {
        this.selectedChoices = this.selectedChoices.filter(
          choiceId => choiceId !== id
        );
      } else {
        this.selectedChoices = this.isSubQuestion
          ? [id]
          : [...this.selectedChoices, id];
      }

      await this.handleSelectedAnswer();
    },

    titleChoicesState(choiceText) {
      if (choiceText == "" || choiceText == null) return false;
      return null;
    },
 
    calculateNewChoiceIndex(questionChoices) {
      if (questionChoices.length === 0) {
        return 0;
      }
      return Math.max.apply(Math, questionChoices.map(obj => obj.index)) + 1;
    },
    // reorder choice
    async reOrderChoices() {
      this.disableDrag = true;

      const promises = [];

      for (let i = 0; i < this.choices.length; i++) {
        promises.push(this.handleChoiceUpdate(this.choices[i].id, this.choices[i].text, i))
      }
      
      await Promise.all(promises);
      this.disableDrag = false;
    },

    setChoiceTextInModal(choiceIndex, choiceText, choiceId) {
      this.tempChoiceId = choiceId
      this.tempChoiceIndex = choiceIndex
      this.tempChoiceText = choiceText
      this.ModalShowChoice = true;
    },

    // create choice request  
    createChoiceRequest(choiceText = null, choiceIndex = null, choiceId = null) {
      const baseRequest = {
        body: {},
        token: this.token,
        questionId: this.isSubQuestion ? this.mainQuestionId : this.question.id,
        subQuestionId: this.isSubQuestion ? this.question.id : null,
      };

      if (choiceId !== null) baseRequest.choiceId = choiceId;
      if (choiceText !== null) baseRequest.body.text = choiceText;
      if (choiceIndex !== null) baseRequest.body.index = choiceIndex;

      return baseRequest;
    },
    // create question info request  
    createQuestionInfoRequest() {
      const requestInfo = {
        body: {
          hasAnswer: true,
          options: {
            allowMultipleAnswers: false,
            strictMarking: false
          }
        },
        token: this.token,
        questionId: this.isSubQuestion ? this.mainQuestionId : this.question.id,
        subQuestionId: this.isSubQuestion ? this.question.id : null,
      };

      if (this.selectedChoices.length > 1) {
        requestInfo.body.options.allowMultipleAnswers = true;
        requestInfo.body.options.strictMarking = true;
      }

      return requestInfo;
    },
    // create answer request  
    createAnswerRequest() {
      const requestAnswer = {
        body: {
          choiceId: this.selectedChoices
        },
        token: this.token,
        questionId: this.isSubQuestion ? this.mainQuestionId : this.question.id,
        subQuestionId: this.isSubQuestion ? this.question.id : null,
      };

      return requestAnswer;
    },

    isSelected(id) {
      return this.selectedChoices.includes(id);
    },

    hideModal() {
      this.$refs["modal-editor"].hide();
    },
  },
};
</script>

<style lang="css" scope>
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.handle {
  cursor: move;
}
.choice-header {
  display: flex;
  justify-content: space-between;
}
.show-choice-text {
  max-width: 50vw;
}
.choice-button-group {
  display: flex;
  justify-content: center;
}

.choice-button-group .btn {
  padding: 1px 6px !important;
}

.choice-button .choice-button-icon {
  width: 16px;
  display: flex;
  justify-content: center;
}

.add-choice {
  cursor: pointer;
}

.button-choice {
  display: flex;
  justify-content: space-between;
}

.example-container {
  margin-left: 4% !important;
}

.example-container img {
  height: 75px;
}

.example-text {
  display: flex;
  justify-content: baseline;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.6;
}
</style>

<template>
  <div>
    <PageHeader title="ภาพรวมคอร์สเรียน"></PageHeader>
    <b-card-img-lazy
      :src="courseInfo.data.courseThumbnail"
      alt=""
      top
      style="width: 150px; margin: 10px 10px 10px 20px"
    ></b-card-img-lazy>
    <h5
      class="nav_top_align text-dark font-weight-bold"
      style="display: inline"
    >
      {{ courseInfo.data.courseName }}
    </h5>
    <b-row class="m-4">
      <b-col sm="12">
        <layout>
          <template v-slot:body>
            <b-row class="my-2">
              <b-col sm="6">
                <b-form inline>
                  <label class="mr-2" for="year-pick">ข้อมูลของปี: </label>
                  <b-form-select
                    id="year-pick"
                    v-model="year"
                    :options="options"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  ></b-form-select>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <enrollStat
                  :apiRoute="`/v1/analytics/courses/getIndividualCourseEnrollStat?cId=${cId}&year=${year}`"
                ></enrollStat>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <studentStat
                  :apiRoute="`/v1/analytics/courses/getIndividualCourseLearnStudentStat?cId=${cId}&year=${year}`"
                ></studentStat>
              </b-col>
            </b-row>
            <b-row class="my-4">
              <b-col cols="3">
                <averageInfo
                  :apiRoute="`/v1/analytics/courses/getIndividualCourseAverageStat?cId=${cId}&year=${year}`"
                ></averageInfo>
              </b-col>
              <b-col cols="9">
                <studentPeriod
                  :apiRoute="`/v1/analytics/courses/getIndividualCourseLearningPeriodStat?cId=${cId}&year=${year}`"
                ></studentPeriod>
              </b-col>
            </b-row>
            <b-row v-if="companyId !== 513">
              <b-col cols="12">
                <courseRevenue
                  :apiRoute="`/v1/analytics/courses/getIndividualCourseYearRevenue?cId=${cId}&year=${year}`"
                ></courseRevenue>
              </b-col>
            </b-row>
          </template>
        </layout>
      </b-col>
    </b-row>
    <b-row class="m-4">
      <b-col cols="6" class="mb-4">
        <studentLearning
          :apiRoute="`/v1/analytics/courses/getCourseStudentLearning?cId=${cId}`"
          :perPage="10"
        ></studentLearning>
      </b-col>
      <b-col cols="6" class="mb-4">
        <lectureRanking
          :apiRoute="`/v1/analytics/courses/getCourseLectureRanking?cId=${cId}&template=panel`"
          :perPage="10"
        ></lectureRanking>
      </b-col>

      <b-col cols="12">
        <studentRanking
          :apiRoute="`/v1/analytics/courses/getCourseStudentRanking?cId=${cId}&template=panel`"
          :perPage="10"
        ></studentRanking>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import enrollStat from "./panel/IndividualCourseEnrollPanel";
import studentStat from "./panel/IndividualCourseLearnStudentPanel";
import averageInfo from "./panel/IndividualCourseAverageInfoPanel";
import studentPeriod from "./panel/IndividualCourseStudentLearnPeriodPanel";
import courseRevenue from "./panel/IndividualCourseYearRevenuePanel";
import studentRanking from "./panel/IndividualCourseStudentRankingPanel";
import studentLearning from "./panel/IndividualCourseStudentLearningPanel";
import lectureRanking from "./panel/IndividualCourseLectureRankingPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
import moment from "moment";
export default {
  components: {
    Layout,
    PageHeader,
    enrollStat,
    studentStat,
    averageInfo,
    studentPeriod,
    courseRevenue,
    studentRanking,
    lectureRanking,
    studentLearning,
  },
  data() {
    return {
      courseInfo: [],
      companyId: this.$store.state.Company.companyInfo.id,
      cId: this.$route.params.cId,
      year: moment().format("YYYY"),
      options: [],
    };
  },

  async mounted() {
    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data;
    });
    let thisyear = moment().format("YYYY");
    for (let i = Number(thisyear); i >= 2014; i--)
      this.options.push({ value: i.toString(), text: i.toString() });
  },
};
</script>

<style scoped>
.card-body {
  padding: 1rem !important;
}
</style>

<template>
  <div>
    <div
      class="question-block d-flex justify-content-center align-items-center"
      :class="getBlockBackgroundColor()"
      @click="blockClicked()"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answer: Object,
    answerIndex: Number
  },
  methods: {
    getBlockBackgroundColor() {
      if (this.answer) {
        let type = this.answer.result;
        if (type.correct > 0) return "correct";
        if (type.blank > 0) return "blank";
        if (type.incorrect > 0) return "incorrect";
      }
    },
    blockClicked() {
      this.$emit("blockClicked", this.answerIndex);
    }
  }
};
</script>

<style scoped>
.question-block {
  padding: 3px;
  width: 40px;
  height: 40px;
}
.question-block:hover {
  cursor: pointer;
  opacity: 0.7;
}
.correct {
  background-color: #e3f4d4;
  color: #6daa7d;
  font-weight: 700;
  
}
.incorrect {
  background-color: #ffd0ca;
  color: #c53f3c;
  font-weight: 700;
}
.blank {
  background-color: #cccccc;
  font-weight: 700;
}
</style>

import TfacReportPage from "@/components/pages/report/tfac/TfacReportPage.vue";
import PurchaseSummaryByCourseReportPage from "@/components/pages/report/tfac/PurchaseSummaryByCourseReportPage.vue";
import EnrollSummaryByCourseReportPage from "@/components/pages/report/tfac/EnrollSummaryByCourseReportPage.vue";
import TfacIndividualStudentFile from "@/components/pages/report/tfac/TfacIndividualStudentFile.vue";
import TfacIndividualStudentFileInfo from "@/components/pages/report/tfac/TfacIndividualStudentFileInfo.vue";
import TfacCompletedEnrollByDateReport from "@/components/pages/report/tfac/TfacCompletedEnrollByDateReport.vue"

export default [
    {
        path: "report/tfac/report",
        name: "report.tfac.report",
        component: TfacReportPage,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"],
        },
    },
    {
        path: "report/tfac/purchase-summary-by-course-report",
        name: "report.tfac.purchasesummarybycoursereport",
        component: PurchaseSummaryByCourseReportPage,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"],
        },
    },
    {
        path: "report/tfac/enroll-summary-by-course-report",
        name: "report.tfac.enrollsummarybycoursereport",
        component: EnrollSummaryByCourseReportPage,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"],
        },
    },
    {
        path: "report/tfac/individual-student-report",
        name: "report.tfac.individualstudentfile",
        component: TfacIndividualStudentFile,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"],
        },
    },
    {
        path: "report/tfac/individual-student-report-info",
        name: "report.tfac.individualstudentfileinfo",
        component: TfacIndividualStudentFileInfo,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"],
        },
    },
    {
        path: "report/tfac/completed-enroll-by-date-report",
        name: "report.tfac.completedEnrollByDateReport",
        component: TfacCompletedEnrollByDateReport,
        meta: {
            authRequired: true,
            permsRequired: ["read-revenue-analytic"]
        }
    }
];
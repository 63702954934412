<script type="text/babel">
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    dataset: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        animation: {
          animateScale: true
        }
      }
    };
  },
  computed: {
    datacollection() {
      const showdata = this.dataset.map(x => {
        if (x != 0) {
          return x;
        }
      });
      return {
        labels: ["ข้อถูก", "ข้อผิด", "ข้อที่ไม่ได้ทำ"],
        datasets: [
          {
            labels: "%",
            data: showdata,
            backgroundColor: ["#71ADFF", "#FF366C", "#FFB538"]
          }
        ]
      };
    }
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  }
};
</script>

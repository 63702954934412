<template>
  <div class="p-4">
    <div>
      <span
        >ชื่อผู้สมัคร: {{ data.mp_firstName + ' ' + data.mp_lastName }}</span
      >
      <span style="float:right;"
        >อีเมล: {{ data.m_email ? data.m_email : '-' }} เบอร์โทร :
        {{ data.mp_mobilePhone ? data.mp_mobilePhone : '-' }}</span
      >
    </div>
    <hr />
    <div>
      รูปบัตรประชาชน
    </div>
    <div v-if="data.verify_status === 'FORCE_APPROVED'">
      <b-spinner small v-if="cardIdBusy" class="mb-1"></b-spinner>
      <img v-else
        class="img-border"
        :src="`${baseUrl}/${card}?token=${token}`"
      />
    </div>
    <div v-else>
      <img
        class="img-border"
        :src="
          storage +
            '/faces/' +
            data.m_id +
            '/' +
            data.com_id +
            '/face-idcard.jpg'
        "
      />
    </div>
    <hr />
    <div>
      รูปผู้สมัคร
      <div v-if="data.verify_status === 'FORCE_APPROVED'">
        <img
          class="img-border"
          :src="`${baseUrl}/${faceFrontImage}?token=${token}`"
        />

        <img
          class="img-border"
          :src="`${baseUrl}/${faceLeftImage}?token=${token}`"
        />

        <img
          class="img-border"
          :src="`${baseUrl}/${faceRightImage}?token=${token}`"
        />

        <img
          class="img-border"
          :src="`${baseUrl}/${faceUpImage}?token=${token}`"
        />

        <img
          class="img-border"
          :src="`${baseUrl}/${faceDownImage}?token=${token}`"
        />
      </div>
      <div v-else>
        <img
          class="img-border"
          :src="
            storage +
              '/faces/' +
              data.m_id +
              '/' +
              data.com_id +
              '/face-front.jpg'
          "
        />

        <img
          class="img-border"
          :src="
            storage +
              '/faces/' +
              data.m_id +
              '/' +
              data.com_id +
              '/face-right.jpg'
          "
        />

        <img
          class="img-border"
          :src="
            storage +
              '/faces/' +
              data.m_id +
              '/' +
              data.com_id +
              '/face-left.jpg'
          "
        />

        <img
          class="img-border"
          :src="
            storage + '/faces/' + data.m_id + '/' + data.com_id + '/face-up.jpg'
          "
        />

        <img
          class="img-border"
          :src="
            storage +
              '/faces/' +
              data.m_id +
              '/' +
              data.com_id +
              '/face-down.jpg'
          "
        />
      </div>
    </div>
    <hr />
    <div>
      อนุมัติรูปภาพ
      <div class="pt-3 d-flex">
        <b-button
          :variant="selected == 'APPROVED' ? 'primary' : 'light'"
          class="mr-3"
          @click="selected = 'APPROVED'"
        >
          อนุมัติ
        </b-button>
        <b-button
          :variant="selected == 'REJECTED' ? 'primary' : 'light'"
          @click="selected = 'REJECTED'"
        >
          ไม่อนุมัติ
        </b-button>
        <b-input
          v-model="note"
          placeholder="ระบุเหตุผล"
          class="ml-3"
          style="padding:1rem;width:800px;"
          v-if="selected == 'REJECTED'"
        >
        </b-input>
      </div>
      <div>
        <b-button
          variant="primary"
          @click="sendVerifyResult()"
          style="float:right;"
          :disabled="selected == null || busy"
        >
          <b-spinner small v-if="busy" class="mb-1"></b-spinner>
          {{ !busy ? 'ยืนยัน' : 'รอสักครู่' }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js';
import axios from "axios";

export default {
  props: ['data'],

  data() {
    return {
      token: localStorage.getItem("token"),
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com",
      faceBaseUrl:
        process.env.VUE_APP_FACE_RECOGNITION_API_URL ||
        "https://sightgain-dev-26082022.coursesquare.com/",
      busy: false,
      user: JSON.parse(localStorage.getItem('user')),
      result: [],
      selected: null,
      items: [],
      note: null,
      storage: process.env.VUE_APP_API_BASE_STORAGE_URL,
      card: null,
      cardIdBusy: true,
      faceFrontImage: null,
      faceLeftImage: null,
      faceRightImage: null,
      faceUpImage: null,
      faceDownImage: null,
    };
  },

  mounted() {
    if (this.data.verify_status === "FORCE_APPROVED") {
      this.getId();
      this.getRegisterFace64();
    }
  },

  methods: {
    async sendVerifyResult() {
      this.busy = true;
      let data = {
        id: this.data.id,
        m_id: this.data.m_id,
        com_id: this.data.com_id,
        status: this.selected,
        note: this.note,
        verifier: JSON.parse(localStorage.getItem('user')).id,
      };
      if (this.data.verify_status === "FORCE_APPROVED") {
        await api
          .put(`/v1/faceverify/face-approve-status`, data)
          .then((response) => {
            this.result = response.data;

            this.$bvToast.toast(`ยืนยันรูปผู้สมัครเรียบร้อยแล้ว`, {
              title: `สำเร็จแล้ว`,
              variant: 'success',
              solid: true,
            });
            this.$emit('posted');
            this.busy = false;
            this.$bvModal.hide('modal-' + this.data.id);
          })
          .catch((err) => {
            this.$bvToast.toast(`ไม่สามารถยืนยันใบหน้าได้`, {
              title: `เกิดข้อผิดพลาด`,
              variant: 'danger',
              solid: true,
              noAutoHide: true,
            });
            this.busy = false;
            console.log(err);
          });
      } else {
        await api
          .post(`/v1/faceverify/updateFaceVerifyStatus`, data)
          .then((response) => {
            this.result = response.data;

            this.$bvToast.toast(`ยืนยันรูปผู้สมัครเรียบร้อยแล้ว`, {
              title: `สำเร็จแล้ว`,
              variant: 'success',
              solid: true,
            });
            this.$emit('posted');
            this.busy = false;
            this.$bvModal.hide('modal-' + this.data.id);
          })
          .catch((err) => {
            this.$bvToast.toast(`ไม่สามารถยืนยันใบหน้าได้`, {
              title: `เกิดข้อผิดพลาด`,
              variant: 'danger',
              solid: true,
              noAutoHide: true,
            });
            this.busy = false;
            console.log(err);
          });
      }
    },
    async getId() {
      await api.get(`v1/members/${this.data.m_id}`).then(({ data }) => {
        const profile = data.data.profiles.find(profile => profile.company.id == this.data.com_id);
        this.card = profile ? profile.idPath : null;
        this.cardIdBusy = profile ? false : true;
      });
    },

    async getRegisterFace64() {
      await axios
        .get(
          `${this.faceBaseUrl}v2/register-face?m_id=${this.data.m_id}&com_id=${this.data.com_id}`
        )
        .then((data) => {
          this.faceFrontImage = data.data.face_path[0] ? data.data.face_path[0] : null;
          this.faceLeftImage = data.data.face_path[1] ? data.data.face_path[1] : null;
          this.faceRightImage = data.data.face_path[2] ? data.data.face_path[2] : null;
          this.faceUpImage = data.data.face_path[3] ? data.data.face_path[3] : null;
          this.faceDownImage = data.data.face_path[4] ? data.data.face_path[4] : null;
        });
    },
  },
};
</script>
<style scoped>
.img-border {
  height: 150px;
  padding: 10px 0px 0px 10px;
}
</style>

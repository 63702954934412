<template>
  <b-overlay :show="onLoading" variant="white">
    <b-card no-body class="mb-2 no-shadow">
      <b-card-header
        header-tag="header"
        class="bg-white border-bottom-0 pl-2"
        role="tab"
      >
        <b-media no-body>
          <b-media-aside vertical-align="center">
            <img
              src="/images/icons/icon_movable.png"
              alt="movable"
              height="20"
              class="align-middle pointer"
            />
          </b-media-aside>
          <b-media-body>
            <b-row no-gutters align-v="center">
              <b-col cols="auto" class="py-0 px-2">
                {{ item.type }}
              </b-col>
              <b-col>
                <div class="border-left py-0 px-2">
                  <div v-if="!onEditing">
                    <a href="#" @click="onEditing = true">
                      <u>{{ item.materialName }}</u>
                    </a>
                  </div>
                  <div v-else>
                    <b-input-group>
                      <b-form-input
                        v-model="materialName"
                        type="text"
                        size="sm"
                        class="border-right-0"
                        autofocus
                        @keyup.enter="update"
                      />
                      <template v-slot:append>
                        <b-input-group-text class="bg-white">
                          <span
                            v-if="isLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </div>
                </div>
              </b-col>

              <b-col cols="auto">
                <b-button size="sm" variant="outline-danger" @click="remove">
                  <i class="fa fa-trash" aria-hidden="true"></i> ลบ
                </b-button>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-header>
      <b-collapse
        :id="`collapse-${item.materialId}`"
        :visible="true"
        role="tabpanel"
      >
      </b-collapse>
    </b-card>
    <template v-slot:overlay>
      <Loading />
    </template>
  </b-overlay>
</template>

<script>
import Loading from '../../shared/Loading';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MaterialItem',
  components: { Loading },
  props: {
    company: { default: null, type: Object },
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      materialName: this.item.materialName,
      courseId: this.$route.params.courseId,
      onLoading: false,
      onEditing: false,
      autoNaming: false,
      allLectureName: null,
      dragging: false,
      enabled: true,
    };
  },
  computed: {
    ...mapGetters('CourseManagement', ['isLoading']),
  },
  methods: {
    ...mapActions('CourseManagement', [
      'fetchCourseMaterialList',
      'createCourseMaterial',
      'updateCourseMaterial',
      'deleteCourseMaterial',
      'getCourseInfo',
    ]),
    async fetch() {
      await this.fetchCourseMaterialList({
        courseId: this.courseId,
      }).then(() => {
        this.onLoading = false;
      });
    },
    async update() {
      this.onEditing = true;
      this.onLoading = true;
      await this.updateCourseMaterial({
        courseId: this.courseId,
        materialId: this.item.materialId,
        materialName: this.materialName,
        materialDescription: '',
      }).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
        this.onEditing = false;
        this.fetch();
      });
    },
    async remove() {
      this.$bvModal
        .msgBoxConfirm(
          `คุณต้องการลบเอกสาร ${this.item.materialName} ใช่หรือไม่?`,
          {
            title: 'แจ้งเตือนการลบเอกสาร',
            buttonSize: 'sm',
            okTitle: 'ลบเอกสาร',
            okVariant: 'primary',
            cancelTitle: 'ยกเลิก',
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            headerCloseVariant: 'white',
            headerCloseContent: '&times;',
            headerBgVariant: 'danger',
            headerClass: 'py-2 px-3 border-0',
            footerClass: 'p-3 border-0',
            centered: true,
          }
        )
        .then(async (confirm) => {
          if (confirm) {
            this.onLoading = true;

            await this.deleteCourseMaterial({
              courseId: this.courseId,
              materialId: this.item.materialId,
            }).then(() => {
              this.getCourseInfo({
                courseId: this.courseId,
                temp: true,
                excepts: 'numberOfStudent,numberOfActiveStudent',
              });
              this.fetch();
            });
            this.onLoading = false;
          }
        });
    },
  },
};
</script>

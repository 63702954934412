<template>
  <div>
    <b-modal
      v-model="show"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="modal-body pa-4">
        <div class="text-center">
          <h3 class="pt-4">
            <b-icon-exclamation-triangle scale="2.4" class="text-warning" />
          </h3>
          <br />
          <h3 class="mb-3" style="font-weight: 600;">{{ title }}</h3>
          <h6 v-html="detail"></h6>
        </div>
      </div>

      <div class="modal-footer text-center border-top-0">
        <b-button
          class="mr-2"
          variant="outline-secondary"
          @click="$emit('canceled')"
        >
          ยกเลิก
        </b-button>
        <b-button :variant="color" @click="$emit('action')">
          {{ actionName }}
        </b-button>
      </div>
    </b-modal>
    <ConfirmSuccessModal
      :title="successTitle"
      :show="showConfirmModal"
      @success="success()"
    />
  </div>
</template>

<script>
import ConfirmSuccessModal from "./ConfirmSuccessModal.vue";
export default {
  name: "WarningActionAttendanceModal",
  components: { ConfirmSuccessModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showConfirmModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    detail: {
      type: String,
    },
    actionName: {
      type: String,
      default: "ยืนยัน",
    },
    successTitle: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
    courseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    success() {
      this.$emit("onSuccess");
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
  }
}
.modal-footer {
  justify-content: baseline !important;
  align-items: center !important;
}
</style>

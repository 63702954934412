<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col cols='12' class="mt-4">
          <template slot:label>
            <h6>กรุณาเลือกคอร์สที่ต้องการต่ออายุ</h6>
          </template>
            <b-form-select
                v-model="selectedCourseId"
                :options="courseOptions"
                class="mb-5"
                size="sm"
              ></b-form-select>
      </b-col>
      <b-col cols='6' class='mt-2'>
        <img 
          v-if='selectedCourseId' 
          :src='getImageURL' 
          alt='Course Thumbnail'
          :style="{width: '60%',float:'right',border: '5px solid black'}"
        />
      </b-col>
      <b-col cols='6' class='mb-3'>
        <div v-if='selectedCourseId'>
            <h6 class='mt-2'>{{ courseOptions.find(el => el.courseId == selectedCourseId).courseName }}</h6>
            <b-row>
              <b-col cols="4" class="mt-2">
                <span class="text-weight">สถานะการเรียน</span>
              </b-col>
              <b-col cols="6" class="mt-2">
                <span v-if='enrollStatus == "ยังไม่ได้เริ่มเรียน"' class="btn btn-secondary btn-xs">{{ enrollStatus }}</span>
                <span v-else-if='enrollStatus == "กำลังเรียนอยู่"' class="btn btn-primary btn-xs">{{ enrollStatus }}</span>
                <span v-else-if='enrollStatus == "เรียนจบแล้ว"' class="btn btn-success btn-xs">{{ enrollStatus }}</span>
              </b-col>
              <b-col cols="4" class="mt-2">
                <span class="text-weight">เริ่มเรียนได้ </span>
              </b-col>
              <b-col cols="6" class="mt-2">
                <span class="text-weight">{{ enrollStartDate }}</span>
              </b-col>
              <b-col cols="4" class="mt-2">
                <span class="text-weight">เรียนได้ถึง </span>
              </b-col>
              <b-col cols="6" class="mt-2">
                <span class="text-weight">{{ enrollEndDate }}</span><br>
              </b-col>
              <b-col cols="4" v-if='courseEnrollExpire != null'>
                <span class="text-weight"></span>
              </b-col>
              <b-col cols="6" v-if='courseEnrollExpire != null'>
                <span v-if='courseEnrollExpire == "หมดอายุ"' class="btn btn-danger btn-xs">{{ courseEnrollExpire }}</span>
                <span v-else-if='courseEnrollExpire == "ชั่วโมงเรียนหมด"' class="btn btn-warning btn-xs">{{ courseEnrollExpire }}</span>
              </b-col>
              <b-col cols="4" class="mt-2">
                <span class="text-weight">เข้าเรียนไปแล้ว </span>
              </b-col>
              <b-col cols="6" class="mt-2">
                <span class="text-weight">{{ enrollLearningTime }}</span>
              </b-col>
              <b-col cols="4" class="mt-2">
                <span class="text-weight">จากเวลาทั้งหมด </span>
              </b-col>
              <b-col cols="6" class="mt-2">
                <span class="text-weight">{{ enrollTimeLimit }}</span>
              </b-col>
            </b-row>
        </div>
       </b-col>
      <b-col cols='6' class='mt-3'>
        <div v-if='selectedCourseId'>
          <h6>เพิ่มชั่วโมงเรียน</h6>
          <b-input-group size="sm">
            <template #append>
              <b-input-group-text class="text-weight">ชั่วโมง</b-input-group-text>
            </template>
          <b-form-input
            v-model="timeLimit"
            size="sm"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">

          </b-form-input>
          </b-input-group>
        </div>
      </b-col>
      <b-col cols='6' class='mt-3'>
        <div v-if='selectedCourseId'>
          <h6>ต่อวันที่หมดอายุ</h6>
          <b-input-group >
            <b-form-input v-if="formatDatepicker > formatEndDate || enrollEndDate == 'ไม่มีวันหมดอายุ'"
              size="sm"
              type="text"
              :value="formatDate"
              readonly
            />
            <b-form-input v-if="formatDatepicker <= formatEndDate && enrollEndDate != 'ไม่มีวันหมดอายุ' "
              class="is-invalid"
              size="sm"
              type="text"
              :value="formatDate"
              readonly
            />
          <b-input-group-prepend>
            <b-button
              size="sm"
              variant="primary"
              @click="showCalendar()"
            ><b-icon icon="calendar-range"></b-icon></b-button>
          </b-input-group-prepend>
          </b-input-group>
          <DatePicker
            id="calendar"
            style="
            display: none;
            float: right;
            position: absolute;
            will-change: transform;
            right: 0px;
             "
            v-model="date"
                    />
          <div v-if="formatDatepicker <= formatEndDate && enrollEndDate != 'ไม่มีวันหมดอายุ'" class='mt-1 text-danger' :style="{fontSize: '0.8rem'}">
            <span>วันที่หมดอายุใหม่ต้องมากกว่าวันที่หมดอายุเดิม</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if='selectedCourseId' class="editor-button mt-5">
      <b-button
        @click="onSave()"
        variant="primary"
        class="mx-2"
        :disabled=" formatDatepicker <= formatEndDate || isLoading"
        >
        <b-spinner v-if="isLoading" small></b-spinner>
        บันทึก
      </b-button>
    </div>
  </b-container>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
export default {
  components: { DatePicker },
  data() {
    return {
      selectedCourseId: null,
      resultData: null,
      date: "",
      timeLimit: 10,
      isLoading: false,
      hideModal: null,
    };
  },

  props: {
    mId: Number,
    courseOptions: Array,
  },

  mounted() {
  },
  computed: {
    getImageURL() {
      let imgURL = `https://s1.coursesquare.co/data/courses/default/course_default.jpg`
      if (this.selectedCourseId) {
        let courseSelected = this.courseOptions.find(el => el.courseId == this.selectedCourseId)
        imgURL = courseSelected.courseThumbnailPath
      }
      return imgURL
    },
    formatEndDate() {
       if(this.selectedCourseId) {
        let enrollEndDate = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollEndDate
          return moment(enrollEndDate).format('YYYY-MM-DD')
        }else{
          return null
        }
    },
    formatDatepicker() {
       if(this.selectedCourseId) {
          return moment(this.date).format('YYYY-MM-DD')
        }else{
          return null
        }
    },
    formatDate() {
      let extendEnroll = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollEndDate
      if(this.date) {
        let rangeCalendar = document.getElementById("calendar");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendar();
        }
        return moment(this.date).format("DD MMMM YYYY")
      }else if(extendEnroll) { 
        return moment(extendEnroll).add(1, 'M').format("DD MMMM YYYY")
      }else{
        return moment().format("DD MMMM YYYY")
      }
    },
    updateDate() {
      let extendEnroll = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollEndDate
      if(this.date) {
        return moment(this.date).format("YYYY-MM-DD")
      }else if(extendEnroll) { 
        return moment(extendEnroll).add(1, 'M').format("YYYY-MM-DD")
      }else{
        return moment().format("YYYY-MM-DD")
      }
    },
    enrollStatus() {
     if(this.selectedCourseId) {
      let enrollStatus = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollstatus
      if(enrollStatus == "NOT_STARTED") {
        return 'ยังไม่ได้เริ่มเรียน'
      }
      else if(enrollStatus == "IN_PROGRESS") {
        return "กำลังเรียนอยู่"
      }
      else if(enrollStatus == "COMPLETED") {
        return 'เรียนจบแล้ว'
      }else{
        return "-"
      }
     }else{
      return null
     }
    },
    enrollStartDate() {
      if(this.selectedCourseId) {
        let enrollStartDate = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollStartDate
        if(enrollStartDate != null){
          return moment(enrollStartDate).format('D MMM YYYY')
        }else{
          return "-"
        }
      }else{
        return null
      }
    },
    enrollEndDate() {
      if(this.selectedCourseId) {
        let enrollEndDate = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollEndDate
        if(enrollEndDate != null){
          return moment(enrollEndDate).format('D MMM YYYY')
        }else{
          return "ไม่มีวันหมดอายุ"
        }
      }else{
        return null
      }
    },
    enrollLearningTime() {
      if(this.selectedCourseId) {
        let enrollLearningTime = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollLearningTime
        if(enrollLearningTime != null){
          let hour = Math.floor(enrollLearningTime / 3600);
          let minute = Math.floor((enrollLearningTime % 3600) / 60);
          if(hour == 0){
            return minute + " นาที"
          }
          return hour + " ชั่วโมง " + minute + " นาที" 
        }else{
          return "ยังไม่ได้เริ่มเรียน"
        }
      }else{
        return null
      }
    },
    enrollTimeLimit() {
      if(this.selectedCourseId) {
        let enrollTimeLimit = this.courseOptions.find(el => el.courseId == this.selectedCourseId).enrollTimeLimit
        if(enrollTimeLimit != null){
          let hour = Math.floor(enrollTimeLimit / 3600);
          let minute = Math.floor((enrollTimeLimit % 3600) / 60);
          if(hour == 0){
            return minute + " นาที"
          }
          return hour + " ชั่วโมง " + minute + " นาที" 
        }else{
          return "ไม่จำกัดเวลาเรียน"
        }
      }else{
        return null
      }
    },
    courseEnrollExpire() {
      if(this.selectedCourseId) {
        let courseEnroll = this.courseOptions.find(el => el.courseId == this.selectedCourseId);
        let currentDate = moment().format('YYYY-MM-DD');
        if(courseEnroll.enrollEndDate <= currentDate && courseEnroll.enrollEndDate != null){
          return "หมดอายุ"
        }else if(courseEnroll.enrollTimeLimit <= courseEnroll.enrollLearningTime && courseEnroll.enrollTimeLimit != null && courseEnroll.enrollLearningTime != null){
          return "ชั่วโมงเรียนหมด"
        }else{
          return null
        }
      }else{
        return null
      }
    },
  },

  methods: {
    onhideModel() {
      this.$emit("hideModalExtendEnrollModal");
    },
    showCalendar() {
      let rangeCalendar = document.getElementById("calendar");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    async onSave() {
      // this.isLoading = true;
      let courseEnroll = this.courseOptions.find(el => el.courseId == this.selectedCourseId)
      let time = parseInt(this.timeLimit * 3600)
      const h = this.$createElement
      const message = h('div',[h('i',[
        h('b-icon-check-circle',{class:['check-alert']})
          ]),h('div',['บันทึกการต่ออายุคอร์สเรียน'])
        ])
      this.isLoading = true;
      api
        .put(`/v1/members/${this.mId}/extend-enroll`, {
          cerId :courseEnroll.enrollId,
          courseId: courseEnroll.courseId,
          timeLimit: time,
          date: this.updateDate,
        })
        .then(() => {
          this.isLoading = false,
          this.$bvModal
            .msgBoxOk([message], {
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "เสร็จสิ้น",
              bodyClass: "text-alert",
              footerClass: "p-2 border-top-0 text-footer",
              centered: true
          })
          .then(value => {
            value ? this.onhideModel() : null
          });
        });
        // if(this.hideModal != null){
        //   this.onhideModel()
        // }
    },
  },
};
</script>

<style>
#modal-enroll .input-group > #modal-enroll .form-control:not(:last-child),
#modal-enroll .input-group > #modal-enroll .custom-select:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#modal-enroll .modal-header{
  border-bottom: 0px;
}
.check-alert{
  color: #28a745 !important;
  transform: scale(2.4);
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.text-footer{
  margin: 0 auto;
  /* min-height: 5rem; */
  margin-bottom: 1rem;
}
.text-alert{
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-family: "Prompt", sans-serif;
  text-align: center;
}
.text-weight{
  font-weight: bold; 
  font-family: "Prompt", sans-serif;
  font-size: smaller;
}
.btn-xs
{
    padding: 1px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}
</style>

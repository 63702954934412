<template>
  <b-modal :id="modalId" title="ยืนยันการผ่านหลักสูตร" hide-footer>
    <div class="modal-body p-0">
      <div v-if="!isSuccess">
        <b-list-group-item v-for="item in items" :key="item.cerId">
          <span v-show="item.fullName">{{ item.fullName }}</span>
          <span v-show="item.firstName">{{ item.firstName }}</span>
          <span v-show="item.lastName"> {{ item.lastName }}</span>
          <small v-if="item.idCard" class="small text-muted">
            ({{ item.idCard }})
          </small>
        </b-list-group-item>
      </div>
      <div v-else class="text-center">
        <h1 class="display-1 text-center">
          <i class="fa fa-check text-success" aria-hidden="true"></i>
        </h1>
        <span>ทำรายการสำเร็จ</span>
      </div>
    </div>

    <div class="modal-footer border-top-0 px-0 pb-0">
      <div v-if="isSuccess">
        <b-button variant="link" data-dismiss="modal" @click="closeModal">
          ปิด
        </b-button>
      </div>
      <div v-else>
        <b-button
          variant="link"
          data-dismiss="modal"
          @click="$bvModal.hide('confirmCompleteEnrollmentModal')"
        >
          ยกเลิก
        </b-button>
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="doCompleteEnrollment()"
        >
          <b-spinner v-if="isLoading" small></b-spinner>
          ผ่านหลักสูตร
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ConfirmCompleteEnrollmentModal",
  props: {
    modalId: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      isSuccess: false,
      isLoading: false
    };
  },
  computed: {
    cerIds() {
      return this.items.map(item => item.cerId);
    }
  },
  methods: {
    ...mapActions("CourseEnrollment", ["completeEnrollment"]),
    async doCompleteEnrollment() {
      this.isLoading = true;
      this.isSuccess = false;

      const { status } = await this.completeEnrollment(this.cerIds);
      if (status === 200) {
        this.isLoading = false;
        this.isSuccess = true;
      }
    },
    closeModal() {
      this.isLoading = false;
      this.isSuccess = false;
      this.$emit("closed", this.modalId);
    }
  }
};
</script>

export default {
  filters: {
    thaiDateFilter(value) {
      if (value && !Date.parse(value)) {
        return value;
      } else if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    thaiDateTimeFilter(value) {
      if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        const time = value.split(" ")[1].split(":");
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear} เวลา ${time[0]}:${time[1]} น.`;
      }
      return "-";
    },
    thaiDateFilterTimestamp(value) {
      if (value) {
        const yearMonthDate = value.split("T")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    thaiDateFilterTimestampUTC(value) {
      if (value) {
        const date = new Date(value);
        const options = {
          timeZone: 'Asia/Bangkok',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          fractionalSecondDigits: 3,
          hour12: false,
          formatMatcher: 'basic'
        };
        const formatter = new Intl.DateTimeFormat('en-GB', options);
        const newDate = formatter.format(date)
        console.log(newDate)

        const yearMonthDate = newDate.split(",")[0].split("/");
        const thaiYear = parseInt(yearMonthDate[2], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค."
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[0]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    }
  },
  methods: {
    thaiDateFilter(value) {
      if (value && !Date.parse(value)) {
        return value;
      } else if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
  },
};

<template>
  <div class="section">
    <b-row class="mt-3">
      <b-col cols="12">
        <b-form-checkbox v-model="couponStatus" switch>
          เปิดใช้งานคูปอง
        </b-form-checkbox>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="12">
        <strong>ชื่อคูปอง</strong>
        <b-form-input
          v-model="pcCode"
          class="mt-2"
          placeholder="ชื่อคูปอง (ใช้ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น)"
          :state="couponNameState"
          aria-describedby="input-coupon-name"
          :disabled="isCouponUsed"
          trim
          @input="validateCouponCode"
          @change="isCouponCodeChange = true"
        ></b-form-input>
        <b-form-invalid-feedback id="input-coupon-name">
          {{ couponValidateText }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="12">
        <strong>ประเภทส่วนลด</strong>
      </b-col>
      <b-col cols="6">
        <b-form-select
          v-model="pcType"
          class="mt-2"
          :options="couponType"
          @change="validateCouponDiscount"
          :disabled="isCouponUsed"
        ></b-form-select>
      </b-col>
      <b-col cols="6">
        <b-input-group class="pt-2" :append="couponTypeText">
          <b-form-input
            v-model="couponDiscount"
            placeholder="0"
            type="number"
            min="0"
            :max="maxDiscountPercent"
            :state="couponDiscountState"
            aria-describedby="input-coupon-discount"
            :disabled="isCouponUsed"
            @change="
              couponDiscountState =
                pcType == 'PERCENT'
                  ? couponDiscount > 0 && couponDiscount < 100
                    ? true
                    : false
                  : pcType == 'FIX'
                  ? couponDiscount > 0
                    ? true
                    : false
                  : null
            "
          />
        </b-input-group>
        <div
          v-if="couponDiscountState === false && pcType == 'FIX'"
          class="text-validate"
        >
          กรุณากรอกจำนวนส่วนลดมากกว่า 0
        </div>
        <div
          v-if="couponDiscountState === false && pcType == 'PERCENT'"
          class="text-validate"
        >
          กรุณากรอกจำนวนส่วนลดมากกว่า 0 แต่ไม่เกิน 99
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="4">
        <strong>วันที่เริ่มคูปอง</strong>
        <b-input-group class="mt-2 mb-1">
          <b-form-input
            id="startDate"
            v-model="formattedStartDate"
            type="text"
            placeholder="ระบุวันที่เริ่ม"
            autocomplete="off"
            :disabled="isCouponUsed"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="pcStartDate"
              button-only
              right
              locale="th"
              aria-controls="startDate"
              :state="startDateState"
              :disabled="isCouponUsed"
              @input="validateDate"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        <div v-if="startDateState === false" class="text-validate">
          วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด
        </div>
      </b-col>
      <b-col cols="4">
        <strong>วันที่สิ้นสุดคูปอง</strong>
        <b-input-group class="mt-2 mb-1">
          <b-form-input
            id="endDate"
            v-model="formattedEndDate"
            type="text"
            placeholder="ระบุวันที่สิ้นสุด"
            autocomplete="off"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="pcExpireDate"
              button-only
              right
              locale="th"
              aria-controls="endDate"
              :state="expireDateState"
              @input="validateDate"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        <div v-if="expireDateState == false" class="text-validate">
          {{ expireDateValidateText }}
        </div>
      </b-col>
      <b-col cols="3">
        <strong>จำนวนครั้งใช้คูปอง</strong>
        <b-input-group class="pt-2 mb-1" append="ครั้ง">
          <b-form-input
            v-model="pcAmount"
            placeholder="0"
            type="number"
            min="0"
            :state="couponAmountState"
            @change="validateCouponAmount"
          />
        </b-input-group>
        <div v-if="couponAmountState === false" class="text-validate">
          {{ amountValidateText }}
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="12">
        <strong>ตั้งค่าการใช้งาน</strong>
      </b-col>
      <b-col>
        <b-button-group class="mt-2">
          <b-button
            v-for="(label, index) in couponTags"
            :key="index"
            class="coupon-tag"
            variant="outline-primary"
            :class="{ active: buttonActiveIndex === index }"
            :disabled="isCouponUsed"
            @click="selectButtonType(index)"
          >
            {{ label }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <br />
    <b-row v-if="buttonActiveIndex != 2">
      <b-col cols="12">
        <strong>{{ tagTitle }}</strong>
        <div style="margin-top: 15px; margin-bottom: 5px;">
          <v-select
            v-if="courseList == null"
            placeholder="กำลังโหลดข้อมูล..."
          />
          <v-select
            v-else
            :options="courseList"
            :label="tagLabel"
            v-model="courseSelect"
            placeholder="เลือกคอร์ส"
            :clearable="false"
            :disabled="isCouponUsed"
            @input="getCourseIdOrTagId($event)"
          />
        </div>
        <div v-if="tagState === false" class="text-validate">
          กรุณาเลือก{{ tagTitle }}
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="12" class="text-right">
        <b-button @click="submitForm()" variant="primary">บันทึก</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["courses", "tags", "couponId", "actionType", "isCouponUsed"],
  components: { vSelect },
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      couponStatus: true,
      couponType: [
        { value: "PERCENT", text: "เปอร์เซ็นต์ (%)" },
        { value: "FIX", text: "บาท" },
      ],
      couponDiscount: null,
      buttonActiveIndex: 0,
      couponTags: ["คอร์ส", "หมวดหมู่", "คอร์สทั้งหมด"],
      tagTitle: "คอร์สที่ใช้กับคูปอง",
      courseList: null,
      courseSelect: "พิมพ์ชื่อคอร์สที่ต้องการค้นหา",
      tagLabel: "courseName",
      courseId: null,
      tagId: null,
      pcCode: null,
      pcType: "PERCENT",
      pcStartDate: null,
      pcExpireDate: null,
      pcAmount: null,
      couponNameState: null,
      couponDiscountState: null,
      startDateState: null,
      expireDateState: null,
      couponAmountState: null,
      tagState: null,
      errorMessage: "",
      isCouponRepeat: false,
      isCouponCodeChange: false,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoadingSpecificCoupon",
      "specificCoupon",
    ]),
    couponTypeText() {
      if (this.pcType == "FIX") {
        return "฿";
      }
      return "%";
    },
    couponValidateText() {
      if (this.isCouponRepeat) {
        return "ชื่อคูปองนี้ถูกใช้ไปแล้ว";
      }
      return "กรุณากรอกชื่อคูปอง (ใช้ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น)";
    },
    maxDiscountPercent() {
      if (this.pcType == "PERCENT") {
        return 99;
      }
      return null;
    },
    formattedStartDate() {
      if (this.pcStartDate) {
        const yearMonthDate = this.pcStartDate.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        return yearMonthDate[2] + "/" + yearMonthDate[1] + "/" + thaiYear;
      }
      return "";
    },
    formattedEndDate() {
      if (this.pcExpireDate) {
        const yearMonthDate = this.pcExpireDate.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        return yearMonthDate[2] + "/" + yearMonthDate[1] + "/" + thaiYear;
      }
      return "";
    },
    expireDateValidateText() {
      if (
        this.isCouponUsed &&
        this.pcExpireDate < this.specificCoupon.pc_expireDate
      ) {
        return "ไม่สามารถแก้ไขวันที่ให้น้อยกว่าวันที่เดิม";
      } else if (this.pcExpireDate == this.pcStartDate) {
        return "วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น";
      }
      return "";
    },
    amountValidateText() {
      if (this.isCouponUsed && this.pcAmount < this.specificCoupon.pc_amount) {
        return "ไม่สามารถแก้ไขจำนวนคูปองให้น้อยกว่าจำนวนคูปองเดิม";
      } else if (this.pcAmount < 1) {
        return "กรุณากรอกจำนวนครั้ง";
      }
      return "";
    },
  },
  mounted() {
    if (this.actionType == "edit" && this.couponId) {
      this.formatSpecificCoupon();
    }
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getSpecificCoupon",
      "insertNewCoupon",
      "updateCoupon",
    ]),
    async formatSpecificCoupon() {
      await this.getSpecificCoupon(this.couponId);
      this.pcCode = this.specificCoupon.pc_code;
      this.pcType = this.specificCoupon.pc_type;
      this.pcStartDate = this.specificCoupon.pc_startDate;
      this.pcExpireDate = this.specificCoupon.pc_expireDate;
      this.pcAmount = this.specificCoupon.pc_amount;
      this.courseId = this.specificCoupon.c_id
        ? this.specificCoupon.c_id
        : null;
      this.tagId = this.specificCoupon.tag_id
        ? this.specificCoupon.tag_id
        : null;
      this.courseSelect = this.specificCoupon.c_id
        ? this.specificCoupon.c_name
        : this.specificCoupon.tag_id
        ? this.specificCoupon.tag_name
        : "";
      this.couponStatus =
        this.specificCoupon.pc_status == "ACTIVE" ? true : false;
      this.couponDiscount =
        this.specificCoupon.pc_type == "FIX"
          ? this.specificCoupon.pc_discountValue
          : this.specificCoupon.pc_discountPercent;
      this.buttonActiveIndex = this.specificCoupon.c_id
        ? 0
        : this.specificCoupon.tag_id
        ? 1
        : 2;
    },
    getCourseIdOrTagId(item) {
      if (this.buttonActiveIndex == 0) {
        this.courseId = item.courseId;
        this.tagId = null;
      } else if (this.buttonActiveIndex == 1) {
        this.tagId = item.tagId;
        this.courseId = null;
      }
      this.tagState = true;
    },
    async submitForm() {
      this.validateCouponCode();
      this.validateDate();
      this.validateCouponAmount();
      this.couponDiscountState =
        this.pcType == "PERCENT"
          ? this.couponDiscount > 0 && this.couponDiscount < 100
            ? true
            : false
          : this.pcType == "FIX"
          ? this.couponDiscount > 0
            ? true
            : false
          : null;
      this.tagState =
        this.buttonActiveIndex == 2
          ? true
          : this.tagId || this.courseId
          ? true
          : false;

      if (this.validateForm()) {
        let couponInfo = {
          comId: this.comId,
          pcType: this.pcType,
          pcStartDate: this.pcStartDate,
          pcExpireDate: this.pcExpireDate,
          pcAmount: this.pcAmount,
          pcStatus: this.couponStatus ? "ACTIVE" : "INACTIVE",
        };

        if (this.buttonActiveIndex == 0) {
          couponInfo.cId = this.courseId;
          couponInfo.tagId = null;
        } else if (this.buttonActiveIndex == 1) {
          couponInfo.cId = null;
          couponInfo.tagId = this.tagId;
        } else {
          couponInfo.cId = null;
          couponInfo.tagId = null;
        }

        if (
          this.isCouponCodeChange &&
          this.pcCode != this.specificCoupon.pc_code
        ) {
          couponInfo.pcCode = this.pcCode;
        }

        if (this.pcType == "FIX") {
          couponInfo.pcDiscountValue = this.couponDiscount;
          couponInfo.pcDiscountPercent = null;
        } else if (this.pcType == "PERCENT") {
          couponInfo.pcDiscountPercent = this.couponDiscount;
          couponInfo.pcDiscountValue = null;
        }

        if (this.actionType == "create") {
          await this.insertNewCoupon(couponInfo)
            .then(() => {
              this.$emit("hideCouponModal", "สร้าง");
            })
            .catch(({ response }) => {
              if (response.status == 422) {
                this.isCouponRepeat = true;
                this.errorMessage = "ชื่อคูปองนี้ถูกใช้ไปแล้ว";
                this.couponNameState = false;
              }
            });
        } else if (this.actionType == "edit") {
          let params = {
            pcId: this.couponId,
            body: couponInfo,
          };
          await this.updateCoupon(params)
            .then(() => {
              this.$emit("hideCouponModal", "แก้ไข");
            })
            .catch(({ response }) => {
              if (response.status == 422) {
                this.isCouponRepeat = true;
                this.errorMessage = "ชื่อคูปองนี้ถูกใช้ไปแล้ว";
                this.couponNameState = false;
              }
            });
        }
      }
    },
    selectButtonType(index) {
      this.buttonActiveIndex = index;
      this.tagState = index == 0 || index == 1 ? false : true;
      if (index == 0) {
        this.tagTitle = "คอร์สที่ใช้กับคูปอง";
        this.courseList = this.courses;
        this.courseSelect = "พิมพ์ชื่อคอร์สที่ต้องการค้นหา";
        this.tagLabel = "courseName";
      } else if (index == 1) {
        this.tagTitle = "หมวดหมู่คอร์สที่ใช้กับคูปอง";
        this.courseList = this.tags;
        this.courseSelect = "พิมพ์ชื่อหมวดหมู่คอร์สที่ต้องการค้นหา";
        this.tagLabel = "tagName";
      }
    },
    validateForm() {
      if (
        this.couponNameState &&
        this.couponDiscountState &&
        this.startDateState &&
        this.expireDateState &&
        this.couponAmountState &&
        this.tagState
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateCouponCode() {
      const regex = /^[A-Za-z0-9]*$/;
      this.pcCode = this.pcCode.toUpperCase();
      this.isCouponRepeat = false;
      this.couponNameState =
        this.pcCode && regex.test(this.pcCode) ? true : false;
    },
    validateDate() {
      this.startDateState = this.pcStartDate
        ? this.pcStartDate == this.pcExpireDate
          ? false
          : true
        : false;
      if (this.pcExpireDate) {
        if (
          (this.isCouponUsed &&
            this.pcExpireDate < this.specificCoupon.pc_expireDate) ||
          this.pcExpireDate == this.pcStartDate
        ) {
          this.expireDateState = false;
        } else {
          this.expireDateState = true;
        }
      } else {
        this.expireDateState = false;
      }
    },
    validateCouponDiscount(select) {
      if (select == "FIX") {
        this.couponDiscountState = this.couponDiscount > 0 ? true : false;
      } else if (select == "PERCENT") {
        this.couponDiscountState =
          this.couponDiscount > 0 && this.couponDiscount < 100 ? true : false;
      }
    },
    validateCouponAmount() {
      if (
        (this.isCouponUsed && this.pcAmount < this.specificCoupon.pc_amount) ||
        this.pcAmount < 1
      ) {
        this.couponAmountState = false;
      } else {
        this.couponAmountState = true;
      }
    },
  },
  watch: {
    courses(courses) {
      if (this.buttonActiveIndex == 0) {
        this.courseList = courses;
      }
    },
    tags(tags) {
      if (this.buttonActiveIndex == 1) {
        this.courseList = tags;
      }
    },
    pcStartDate(startDate) {
      if (startDate && this.pcExpireDate < startDate && !this.isCouponUsed) {
        const start = new Date(startDate);
        start.setDate(start.getDate() + 1);
        this.pcExpireDate = moment(start).format("YYYY-MM-DD");
      }
    },
    pcExpireDate(expireDate) {
      if (expireDate && this.pcStartDate > expireDate && !this.isCouponUsed) {
        const expire = new Date(expireDate);
        expire.setDate(expire.getDate() - 1);
        this.pcStartDate = moment(expire).format("YYYY-MM-DD");
      }
    },
  },
};
</script>

<style scoped>
.section {
  font-family: "Prompt", sans-serif !important;
}
.coupon-tag.active {
  background-color: #027bff;
  color: #fff;
}
.text-validate {
  color: #dc3545;
  font-size: 0.875rem;
}
</style>

import async from "@/config/store";
import Vue from "vue";

export default {
  setCompanyInfo: (state, companyInfo) => {
    state.companyInfo = companyInfo;
  },
  [async.ASYNC_PARTICIPATION.SUCCESS]: (state, participation) => {
    state[async.ASYNC_PARTICIPATION.loadingKey] = false;
    state[async.ASYNC_PARTICIPATION.errorKey] = null;
    Vue.set(state, async.ASYNC_PARTICIPATION.stateKey, participation);
  },
  [async.ASYNC_PARTICIPATION.FAILURE]: (state, err) => {
    state[async.ASYNC_PARTICIPATION.loadingKey] = false;
    state[async.ASYNC_PARTICIPATION.stateKey] = null;
    Vue.set(state, async.ASYNC_PARTICIPATION.errorKey, err);
  },
  [async.ASYNC_PARTICIPATION.PENDING]: state => {
    state[async.ASYNC_PARTICIPATION.errorKey] = null;
    state[async.ASYNC_PARTICIPATION.stateKey] = null;
    Vue.set(state, async.ASYNC_PARTICIPATION.loadingKey, true);
  },
  [async.ASYNC_EXAM_ANALYTICS.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_ANALYTICS.loadingKey] = false;
    state[async.ASYNC_EXAM_ANALYTICS.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANALYTICS.stateKey, data);
  },
  [async.ASYNC_EXAM_ANALYTICS.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_ANALYTICS.loadingKey] = false;
    state[async.ASYNC_EXAM_ANALYTICS.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANALYTICS.errorKey, err);
  },
  [async.ASYNC_EXAM_ANALYTICS.PENDING]: state => {
    state[async.ASYNC_EXAM_ANALYTICS.errorKey] = null;
    state[async.ASYNC_EXAM_ANALYTICS.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANALYTICS.loadingKey, true);
  },
  [async.ASYNC_EXAM_ANSWERSHEETS.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_ANSWERSHEETS.loadingKey] = false;
    state[async.ASYNC_EXAM_ANSWERSHEETS.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEETS.stateKey, data);
  },
  [async.ASYNC_EXAM_ANSWERSHEETS.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_ANSWERSHEETS.loadingKey] = false;
    state[async.ASYNC_EXAM_ANSWERSHEETS.stateKey] = [];
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEETS.errorKey, err);
  },
  [async.ASYNC_EXAM_ANSWERSHEETS.PENDING]: state => {
    state[async.ASYNC_EXAM_ANSWERSHEETS.errorKey] = null;
    state[async.ASYNC_EXAM_ANSWERSHEETS.stateKey] = [];
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEETS.loadingKey, true);
  },
  [async.ASYNC_EXAM_STAT.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_STAT.loadingKey] = false;
    state[async.ASYNC_EXAM_STAT.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_STAT.stateKey, data);
  },
  [async.ASYNC_EXAM_STAT.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_STAT.loadingKey] = false;
    state[async.ASYNC_EXAM_STAT.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_STAT.errorKey, err);
  },
  [async.ASYNC_EXAM_STAT.PENDING]: state => {
    state[async.ASYNC_EXAM_STAT.errorKey] = null;
    state[async.ASYNC_EXAM_STAT.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_STAT.loadingKey, true);
  },
  [async.ASYNC_EXAM_INFO.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_INFO.loadingKey] = false;
    state[async.ASYNC_EXAM_INFO.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_INFO.stateKey, data);
  },
  [async.ASYNC_EXAM_INFO.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_INFO.loadingKey] = false;
    state[async.ASYNC_EXAM_INFO.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_INFO.errorKey, err);
  },
  [async.ASYNC_EXAM_INFO.PENDING]: state => {
    state[async.ASYNC_EXAM_INFO.errorKey] = null;
    state[async.ASYNC_EXAM_INFO.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_INFO.loadingKey, true);
  },
  [async.ASYNC_COMPANY_EXAMS.SUCCESS]: (state, data) => {
    state[async.ASYNC_COMPANY_EXAMS.loadingKey] = false;
    state[async.ASYNC_COMPANY_EXAMS.errorKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_EXAMS.stateKey, data);
  },
  [async.ASYNC_COMPANY_EXAMS.FAILURE]: (state, err) => {
    state[async.ASYNC_COMPANY_EXAMS.loadingKey] = false;
    state[async.ASYNC_COMPANY_EXAMS.stateKey] = [];
    Vue.set(state, async.ASYNC_COMPANY_EXAMS.errorKey, err);
  },
  [async.ASYNC_COMPANY_EXAMS.PENDING]: state => {
    state[async.ASYNC_COMPANY_EXAMS.errorKey] = null;
    state[async.ASYNC_COMPANY_EXAMS.stateKey] = [];
    Vue.set(state, async.ASYNC_COMPANY_EXAMS.loadingKey, true);
  },
  [async.ASYNC_EXAM_ANSWERSHEET.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_ANSWERSHEET.loadingKey] = false;
    state[async.ASYNC_EXAM_ANSWERSHEET.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEET.stateKey, data);
  },
  [async.ASYNC_EXAM_ANSWERSHEET.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_ANSWERSHEET.loadingKey] = false;
    state[async.ASYNC_EXAM_ANSWERSHEET.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEET.errorKey, err);
  },
  [async.ASYNC_EXAM_ANSWERSHEET.PENDING]: state => {
    state[async.ASYNC_EXAM_ANSWERSHEET.errorKey] = null;
    state[async.ASYNC_EXAM_ANSWERSHEETS.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_ANSWERSHEET.loadingKey, true);
  },
  [async.ASYNC_EXAM_MEMBER.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAM_MEMBER.loadingKey] = false;
    state[async.ASYNC_EXAM_MEMBER.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAM_MEMBER.stateKey, data);
  },
  [async.ASYNC_EXAM_MEMBER.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAM_MEMBER.loadingKey] = false;
    state[async.ASYNC_EXAM_MEMBER.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_MEMBER.errorKey, err);
  },
  [async.ASYNC_EXAM_MEMBER.PENDING]: state => {
    state[async.ASYNC_EXAM_MEMBER.errorKey] = null;
    state[async.ASYNC_EXAM_MEMBER.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAM_MEMBER.loadingKey, true);
  },
  [async.ASYNC_EXAMS_COUNT.SUCCESS]: (state, data) => {
    state[async.ASYNC_EXAMS_COUNT.loadingKey] = false;
    state[async.ASYNC_EXAMS_COUNT.errorKey] = null;
    Vue.set(state, async.ASYNC_EXAMS_COUNT.stateKey, data);
  },
  [async.ASYNC_EXAMS_COUNT.FAILURE]: (state, err) => {
    state[async.ASYNC_EXAMS_COUNT.loadingKey] = false;
    state[async.ASYNC_EXAMS_COUNT.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAMS_COUNT.errorKey, err);
  },
  [async.ASYNC_EXAMS_COUNT.PENDING]: state => {
    state[async.ASYNC_EXAMS_COUNT.errorKey] = null;
    state[async.ASYNC_EXAMS_COUNT.stateKey] = null;
    Vue.set(state, async.ASYNC_EXAMS_COUNT.loadingKey, true);
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.SUCCESS]: (state, data) => {
    state[async.ASYNC_ANSWERSHEETS_COUNT.loadingKey] = false;
    state[async.ASYNC_ANSWERSHEETS_COUNT.errorKey] = null;
    Vue.set(state, async.ASYNC_ANSWERSHEETS_COUNT.stateKey, data);
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.FAILURE]: (state, err) => {
    state[async.ASYNC_ANSWERSHEETS_COUNT.loadingKey] = false;
    state[async.ASYNC_ANSWERSHEETS_COUNT.stateKey] = null;
    Vue.set(state, async.ASYNC_ANSWERSHEETS_COUNT.errorKey, err);
  },
  [async.ASYNC_ANSWERSHEETS_COUNT.PENDING]: state => {
    state[async.ASYNC_ANSWERSHEETS_COUNT.errorKey] = null;
    state[async.ASYNC_ANSWERSHEETS_COUNT.stateKey] = null;
    Vue.set(state, async.ASYNC_ANSWERSHEETS_COUNT.loadingKey, true);
  },
  [async.ASYNC_COMPANY_COURSES.SUCCESS]: (state, data) => {
    state[async.ASYNC_COMPANY_COURSES.loadingKey] = false;
    state[async.ASYNC_COMPANY_COURSES.errorKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_COURSES.stateKey, data);
  },
  [async.ASYNC_COMPANY_COURSES.FAILURE]: (state, err) => {
    state[async.ASYNC_COMPANY_COURSES.loadingKey] = false;
    state[async.ASYNC_COMPANY_COURSES.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_COURSES.errorKey, err);
  },
  [async.ASYNC_COMPANY_COURSES.PENDING]: state => {
    state[async.ASYNC_COMPANY_COURSES.errorKey] = null;
    state[async.ASYNC_COMPANY_COURSES.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_COURSES.loadingKey, true);
  },
  [async.ASYNC_COMPANY_MODULES.SUCCESS]: (state, data) => {
    state[async.ASYNC_COMPANY_MODULES.loadingKey] = false;
    state[async.ASYNC_COMPANY_MODULES.errorKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_MODULES.stateKey, data);
  },
  [async.ASYNC_COMPANY_MODULES.FAILURE]: (state, err) => {
    state[async.ASYNC_COMPANY_MODULES.loadingKey] = false;
    state[async.ASYNC_COMPANY_MODULES.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_MODULES.errorKey, err);
  },
  [async.ASYNC_COMPANY_MODULES.PENDING]: state => {
    state[async.ASYNC_COMPANY_MODULES.errorKey] = null;
    state[async.ASYNC_COMPANY_MODULES.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_MODULES.loadingKey, true);
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.SUCCESS]: (state, data) => {
    state[async.ASYNC_COMPANY_INSTRUCTORS.loadingKey] = false;
    state[async.ASYNC_COMPANY_INSTRUCTORS.errorKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_INSTRUCTORS.stateKey, data);
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.FAILURE]: (state, err) => {
    state[async.ASYNC_COMPANY_INSTRUCTORS.loadingKey] = false;
    state[async.ASYNC_COMPANY_INSTRUCTORS.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_INSTRUCTORS.errorKey, err);
  },
  [async.ASYNC_COMPANY_INSTRUCTORS.PENDING]: state => {
    state[async.ASYNC_COMPANY_INSTRUCTORS.errorKey] = null;
    state[async.ASYNC_COMPANY_INSTRUCTORS.stateKey] = null;
    Vue.set(state, async.ASYNC_COMPANY_INSTRUCTORS.loadingKey, true);
  }
};

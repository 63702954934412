<template>
  <layout>
    <template v-slot:header v-if="perPage <= 10">
      <b-row>
        <b-col cols="6">
          <h6>คูปอง</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <a :href="`/${company.url}/analytics/revenue/coupon`" target="_blank"
            ><h6>ดูทั้งหมด</h6></a
          >
        </b-col>
      </b-row>
    </template>
    <template v-slot:body>
      <div class>
        <div v-if="perPage > 10" class="mb-3">
          <b-form-input
            v-model="search"
            placeholder="ค้นหาคูปอง"
            style="padding: 1rem; display: inline-block; width: 20%"
          >
          </b-form-input>
        </div>
        <b-table
          striped
          id="revenue-coupon-table"
          :per-page="perPage"
          :current-page="currentPage"
          table-class="tbNumber text-left "
          :items="
            search == null
              ? result
              : result.filter((i) => i.pc_code.includes(search))
          "
          :fields="fields"
          small
          sort-icon-left
          responsive
          :busy="isBusy"
          hover
        >
          <template v-slot:cell(name)="data"
            ><span>{{ data.item.pc_code }}</span></template
          >

          <template v-slot:cell(course)="data">
            <span>{{ data.item.c_name ? data.item.c_name : 'ทุกคอร์ส' }}</span>
          </template>

          <template v-slot:cell(discount)="data">
            <span style="float: right">{{
              data.item.pc_price >= 1
                ? '฿ ' + Number(data.item.pc_price).toLocaleString()
                : 100 - Number(data.item.pc_price) * 100 + '%'
            }}</span>
          </template>

          <template v-slot:cell(amount)="data">
            <span style="float: right">{{
              data.item.count ? data.item.count : '0'
            }}</span>
          </template>

          <template v-slot:cell(revenue)="data">
            <span style="float: right">{{ data.item.sum | thaiBaht }}</span>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <div style="text-align: center" v-if="result.length == 0">
          {{ 'ไม่มีข้อมูล' }}
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="
            search == null
              ? rows
              : result.filter((i) => i.pc_code.includes(search)).length
          "
          :per-page="perPage"
          aria-controls="revenue-coupon-table"
          v-if="perPage > 10"
        ></b-pagination>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import api from '@/services/api.js';
export default {
  components: {
    layout,
  },
  props: ['apiRoute', 'perPage'],
  data() {
    return {
      result: [],
      company: {},
      currentPage: 1,
      rows: 0,
      isBusy: true,
      search: null,
      fields: [
        { key: 'name', label: 'ชื่อคูปอง' },
        {
          key: 'course',
          label: 'ใช้กับคอร์ส',
        },
        {
          key: 'discount',
          label: 'ส่วนลด',
        },
        {
          key: 'amount',
          label: 'จำนวนที่ใช้',
          class: 'text-right',
        },
        {
          key: 'revenue',
          label: 'ยอดขายจากคูปอง',
          class: 'text-right',
        },
      ],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped></style>

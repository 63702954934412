<template>
  <page-header :title="'ข้อสอบ / ผู้สอบ'" :icon="'book'"></page-header>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  components: {
    PageHeader
  }
};
</script>

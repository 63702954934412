<template>
  <div>
    <b-modal v-model="isOverlayVisible" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered>
      <div class="text-center">
        <div class="p-5 rounded d-flex align-items-center justify-content-center">
          <div class="align-items-center">
            <b-spinner variant="primary" label="Spinning" style="width: 3rem; height: 3rem;"></b-spinner>
            <div class="ml-3">
              <h2 class="mt-2">กรุณารอสักครู่</h2>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <PageHeader title="จัดการเนื้อหาคอร์สเรียน"></PageHeader>
    <div class="outer">
      <div class="inner bg-container pb-5">
        <CourseNavSteps />
        <CourseNavToolbar
          :sidebar-opened="sidebarOpened"
          :course="courseInfo"
          :company="company"
        />
        <b-overlay :show="false" variant="white">
          <Loading
            v-if="isLoading"
            class="mt-2 fixed-top"
            style="z-index:999999"
          />
          <b-card no-body class="mt-5 no-shadow border-0 ">
            <b-card-body class="pt-4">
              <div>
                <b-button
                  variant="success"
                  class="mb-2"
                  @click="browseFile('file')"
                >
                  <b-icon icon="plus"></b-icon> เพิ่มเอกสาร
                </b-button>
                <span v-if="courseMaterialList.length" class="ml-2">
                  มีทั้งหมด {{ courseMaterialList.length }} ไฟล์
                </span>
                <span v-else class="ml-2">
                  ไม่มีเอกสารประกอบการเรียน
                </span>
              </div>
              <draggable
                tag="div"
                :list="courseMaterialList"
                :disabled="!enabled"
                class="mt-2"
                ghost-class="ghost"
                v-bind="dragOptions"
                @start="drag = true"
                @end="checkMove()"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <MaterialItem
                    v-for="(item, index) in courseMaterialList"
                    :key="item.materialId"
                    :item="item"
                    :index="index"
                  />
                </transition-group>
              </draggable>
            </b-card-body>
          </b-card>

          <b-form-file
            :id="`file`"
            :ref="`file`"
            v-model="file"
            :name="`file`"
            :state="Boolean(file)"
            accept="*"
            drop-placeholder="Drop file here..."
            browse-text=" "
            class="d-none"
            @input="addMaterial"
          />
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import CourseNavSteps from "./partials/CourseNavSteps";
import CourseNavToolbar from "./partials/CourseNavToolbar";
import MaterialItem from "./MaterialItem";
import Loading from "../../shared/Loading";
import draggable from "csq-vue-multi-draggable";

import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";

export default {
  name: "CourseMaterialPage",
  components: {
    PageHeader,
    CourseNavSteps,
    CourseNavToolbar,
    MaterialItem,
    Loading,
    draggable
  },
  props: {
    user: { default: null, type: Object },
    company: { default: null, type: Object },
    sidebarOpened: Boolean
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      enabled: true,
      dragging: false,
      drag: false,
      isOverlayVisible: false,
      list: [],
      activeNames: [1],
      collapseComponentData: {
        on: {
          input: this.inputChanged
        },
        props: {
          value: this.activeNames
        }
      },
      text: ``,
      file: null
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseMaterialList",
      "courseInfo"
    ]),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    this.fetchCourseMaterialList({
      courseId: this.courseId
    });
  },
  watch: {
    courseMaterialList() {}
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "fetchCourseMaterialList",
      "createCourseMaterial",
      "updateCourseInfo",
      "orderCourseMaterial"
    ]),

    browseFile(elementId) {
      var elem = document.getElementById(elementId);
      if (elem && document.createEvent) {
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("click", true, false);
        elem.dispatchEvent(evt);
      }
    },

    async addMaterial() {
      await this.createCourseMaterial({
        file: this.file,
        courseId: this.courseId,
        courseMaterialOrder: this.courseMaterialList.length ? this.courseMaterialList.length : 0
      }).then(() => {
        this.fetchCourseMaterialList({
          courseId: this.courseId
        });

        this.updateCourseInfo({
          courseId: this.courseId
        });
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
      });
    },

    inputChanged(val) {
      this.activeNames = val;
    },

    async checkMove() {
      this.isOverlayVisible = true;
      this.drag = false;
      var data = [];
      var count = 0;
      this.courseMaterialList.forEach(i => {
        var model = {
          c_id: this.courseId,
          cm_topic: null,
          cm_order: count,
          cm_downloadCounter: 0,
          lm_id: i.materialId,
          cm_status: i.materialStatus
        };
        data.push(model);
        count++;
      });

      await this.orderCourseMaterial({
        file: data,
        courseId: this.courseId
      }).then(() => {
        this.fetchCourseMaterialList({
          courseId: this.courseId
        }).then(() => {
          this.isOverlayVisible = false;
        })
        this.updateCourseInfo({
          courseId: this.courseId
        });
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
      });
    },

    toggleCollapse(collapseId) {
      this.$root.$emit("bv::toggle::collapse", collapseId);
    }
  }
};
</script>

<style lang="css">
.cursor-pointer {
  cursor: pointer;
}
</style>

<template>
  <div v-if="!isConfirmed" class="container text-center">
    <div class="row center-row my-5">
      <div class="col">
        <!--<b-icon
          class="h2"
          icon="check-circle"
          style="color: green"
        />-->
        <b-icon
          icon="exclamation-triangle"
          variant="warning"
          scale="5"
        ></b-icon>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label class="bold-text larger-text">ลบใบอนุญาต</label>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label>ต้องการลบ "{{typeName}}" ของ</label>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label><span style="font-weight: bold">{{firstName}} {{lastName}}</span> หรือไม่?</label>
      </div>
    </div>
    <div class="row center-row mt-3">
      <div class="col">
        <b-button variant="outline-secondary" @click="cancel()" class="mr-2">ยกเลิก</b-button>
        <b-button variant="danger" @click="confirm()">ลบ</b-button>
      </div>
    </div>
  </div>
  <div v-else class="container text-center">
    <div class="row center-row my-5">
      <div class="col">
        <b-icon
          icon="check-circle"
          style="color: green"
          scale="5"
        />
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label class="bold-text larger-text">ลบใบอนุญาตเรียบร้อยแล้ว</label>
      </div>
    </div>
    <div class="row center-row mt-3">
      <div class="col">
        <b-button variant="primary" @click="success()">เสร็จสิ้น</b-button>
      </div>
    </div>
  </div>
</template>

<style>
.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.larger-text {
  font-size: 24px; /* Adjust the font size as needed */
}

.warning-icon {
  font-size: 24px;
  color: #f39c12; /* Warning icon color */
}

.bold-text {
  font-weight: bold;
}

/* Add more styling as needed */
</style>

<script>
export default {
  name: "ConfirmModal",
  props: {
    firstName: { type: String, default: null },
    lastName: { type: String, default: null },
    typeName: { type: String, default: null },
    isConfirmed: { type: Boolean, default: false },
  },
  methods: {
    async confirm() {
      await this.$emit("deleteInsuranceMemberData");
    },
    cancel() {
      this.$emit("modalHideConfirmDeleteInsuranceForm");
    },
    success() {
      this.$emit("modalHideConfirmDeleteInsuranceForm");
      this.$emit("getIns");
    },
  }
};
</script>


import ExamListpage from "@/components/pages/exam-builder/ExamListpage.vue";
import ExamInfoPage from "@/components/pages/exam-builder/ExamInfoPage.vue";

export default [
  {
    path: "exam/build",
    name: "exam.build",
    component: ExamListpage,
  },
  {
    path: "exam/build/:quizExamId",
    name: "exam.info",
    component: ExamInfoPage,
  },
];

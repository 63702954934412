<template>
  <div>
    <PageHeader title="ภาพรวมประกาศนียบัตร" style="margin-left: -10%"></PageHeader>

    <b-container class="widthPage mt-5 mb-5">
      <b-row>
        <b-col sm="12">
          <h5>คอร์สทั้งหมด</h5>

          <div class>
            <div class="mb-3">
              <b-row>
                <b-col cols="10">
                  <b-form-input
                    v-model="search"
                    placeholder="ค้นหาคอร์สเรียน"
                    style="padding: 1rem; display: inline-block; width: 20%"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </div>
          </div>

          <b-table
            id="certificate-overview-table"
            :per-page="perPage"
            :current-page="currentPage"
            class="text-left table"
            :items="
              search == null
                ? result.data
                : result.data.filter((i) => 
                  i.courseName.toLowerCase().replace(/\s+/g, '').includes(search.toLowerCase().replace(/\s+/g, '')))
            "
            :fields="fields"
            small
            sort-icon-left
            responsive
            hover
            :busy="isBusy"
          >
            <template v-slot:cell(courseName)="data"
              ><b-img
                :src="data.item.coursethumbnailPath"
                height="50"
                width="80"
                alt
              />
              <span class="ml-3">{{
                data.item.courseName | cut(10)
              }}</span></template
            >
            <template v-slot:cell(owner)="data">
              <span>{{
                (data.item.instructorFirstName +
                  " " +
                  data.item.instructorLastName)
                  | cut(10)
              }}</span>
            </template>

            <template v-slot:cell(courseDuration)="data">
              <span>{{
                data.item.courseDuration
                  ? data.item.courseDuration + " ชั่วโมง"
                  : "00:00:00 ชั่วโมง"
              }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <router-link
                :to="`/${company.url}/analytics/certificate/${data.item.courseId}/overview`"
                ><b-button size="sm" variant="outline-primary">
                  <span>ดูข้อมูล</span>
                </b-button></router-link
              >
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-primary m-5">
                <b-spinner class="align-middle"></b-spinner>
                <br />
                <p class="mt-2">Loading...</p>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="getLengthofItem() < 1">
            {{ "ไม่มีข้อมูล" }}
          </div>
          <b-pagination
            v-if="getLengthofItem() > 10"
            v-model="currentPage"
            :total-rows="getLengthofItem()"
            :per-page="perPage"
            aria-controls="certificate-overview-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import AnalyticsCard from '@/components/shared/AnalyticsCard.vue'
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
export default {
  components: {
    // AnalyticsCard,
    PageHeader,
  },

  data() {
    return {
      search: null,
      fields: [
        { key: "courseName", label: "คอร์สเรียน ", thClass: "hello" },
        { key: "owner", label: "ชื่อผู้สอน", thClass: "hello" },
        {
          key: "courseDuration",
          label: "ความยาวคอร์ส",
          thClass: "hello",
        },
        {
          key: "totalStudent",
          label: "ผู้เรียนทั้งหมด",
          thClass: "hello",
        },
        {
          key: "completedCertificate",
          label: "ผู้เรียนที่ได้ใบประกาศ",
          thClass: "hello",
        },
        {
          key: "btn",

          label: "",
          thClass: "hello",
        },
      ],
      isBusy: true,
      rows: 0,
      result: [],
      company: null,
      imgURL: "https://s1-dev.coursesquare.com/",
      currentPage: 1,
      perPage: 10,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
  },

  computed: {
    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }
      var showData = this.result.data.slice(
        firstIndex,
        firstIndex + this.perPage
      );
      return showData;
    },
  },

  async mounted() {
    this.isBusy = true;
    let res = await api.get(
      "/v1/companies/" + this.company.id + "/course-certificate-overview"
    );
    this.result = res.data;
    this.rows = this.result.data.length;
    this.currentPage = 1;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },

  methods: {
    getLengthofItem() {
      if (this.search) {
        var itemLengt = this.result.data.filter((i) =>
          i.courseName.toLowerCase().replace(/\s+/g, '').includes(this.search.toLowerCase().replace(/\s+/g, ''))
        );
        return itemLengt.length;
      } else if (this.result.data != undefined) {
        return Object.keys(this.result.data).length;
      }
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}
.h6 {
  margin-bottom: 0 !important;
}
.map-row {
  padding-top: 2rem !important;
}
.widthPage {
  max-width: 81.25rem;
}
</style>

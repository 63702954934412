<template>
  <div>
    <PageHeader title="จัดการคอร์สเรียน"></PageHeader>
    <div class="outer">
      <div class="inner bg-container">
        <CourseNavSteps />
        <b-overlay :show="false" variant="white">
          <b-card no-body class="mt-5 no-shadow border-0">
            <b-card-body class="pt-4 pb-5">
              <b-card-title>{{ courseInfo.courseName }}</b-card-title>
              <label for="duration" class="d-block">
                วิดีโอแนะนำคอร์ส
              </label>
              <b-row>
                <b-col lg="6" xl="4">
                  <b-overlay :show="!courseInfo.courseVideoIntro">
                    <b-aspect aspect="16:9" class="bg-light">
                      <youtube
                        v-if="youtubeVideoId"
                        ref="youtube"
                        :video-id="youtubeVideoId"
                        :resize="true"
                      ></youtube>

                      <video
                        width="100%"
                        height="257px"
                        v-else-if="videoFile"
                        id="video-previewFile"
                        controls
                      />
                      <div v-if="hasHLSVideoPath">
                        <HLSVideoPlayer :url="courseInfo.courseVideoIntro"/>
                      </div>

                    </b-aspect>
                    <template v-slot:overlay>
                      <div class="text-center text-muted">
                        <b-icon icon="camera-video" font-scale="3"></b-icon>
                        <p>ไม่มีวิดีโอแนะนำ</p>
                      </div>
                    </template>
                  </b-overlay>

                  <b-form-file
                    id="file"
                    ref="file"
                    v-model="videoFile"
                    :state="Boolean(videoFile)"
                    accept=".mp4"
                    class="d-none"
                    :placeholder="
                      courseAttributeUpdated['videoFile']
                        ? 'Uploaded'
                        : 'Choose a file or drop it here...'
                    "
                    drop-placeholder="Drop file here..."
                    browse-text=" "
                    @input="_uploadVideoIntroFile"
                  />
                </b-col>
                <b-col lg="6" xl="4">
                  <div class="border-left-dashed px-4 h-100">
                    <b-dropdown
                      variant="outline-primary"
                      text="แก้ไขวิดีโอแนะนำคอร์ส"
                      class="mr-2 mt-5"
                    >
                      <b-dropdown-item
                        v-b-modal="'uploadYoutubeVideoIntroModal'"
                      >
                        เลือกวีดีโอจาก Youtube
                      </b-dropdown-item>
                      <b-dropdown-item @click="browseFile('file')">
                        อัปโหลดวิดีโอจากเครื่อง
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-b-modal="'deleteIntroVideoModal'"
                        v-if="courseInfo.courseVideoIntro"
                      >
                        <span style="color : red">
                          ลบวิดีโอ
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <div
                      v-if="uploadPercentage > 0 && uploadPercentage < 100"
                      class="mt-3"
                    >
                      <div class="d-flex justify-content-between small">
                        <span>กำลังอัปโหลด</span>
                        <span>{{ uploadPercentage }} % </span>
                      </div>
                      <b-progress :max="100" variant="warning">
                        <b-progress-bar
                          :value="uploadPercentage"
                          :label="`${uploadPercentage.toFixed(0)}%`"
                        ></b-progress-bar>
                      </b-progress>
                    </div>

                    <div>
                      <label
                        v-if="checktype"
                        for="error"
                        class="text-danger ml-2"
                        >กรุณาแนบไฟล์ mp4 เท่านั้น</label
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-5">
                <b-col class="pr-5">
                  <h5>หัวข้อและบทเรียน</h5>
                  <search-box
                    v-show="false"
                    title="พิมพ์เพื่อค้นหาบทเรียน"
                    style="width: 350px;"
                    @typed="getSearchKeyword"
                  />
                  <Loading
                    v-if="isLoading"
                    class="mt-2 fixed-top"
                    style="z-index:999999"
                  />
                  <draggable
                    tag="div"
                    :list="courseModuleList"
                    :disabled="!enabledDraggable"
                    class="mt-3"
                    ghost-class="ghost"
                    v-bind="dragOptions"
                    :move="checkMove"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <ModuleItem
                      v-for="(item, index) in courseModuleList"
                      :key="item.moduleId"
                      :item="item"
                      :index="index"
                      :company="company"
                      @on-editing="toggleDraggable"
                    />
                  </draggable>

                  <b-button
                    variant="outline-secondary"
                    class="mt-2"
                    @click="addModule()"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i> เพิ่มหัวข้อใหม่
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <template v-slot:overlay>
            <Loading />
          </template>
        </b-overlay>

        <DeleteIntroModal
          v-on:submit-successful="refreshPage"
          :id="`deleteIntroVideoModal`"
          attribute="courseVideoIntro"
        />

        <YoutubeUploadModal
          :id="`uploadYoutubeVideoIntroModal`"
          @youtube-url="submitYoutubeLink"
        />

        <CourseNavToolbar
          :sidebar-opened="sidebarOpened"
          :course="courseInfo"
          :company="company"
        />

        <b-modal
          v-model="isOverlayVisible"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          centered
        >
          <div class="text-center">
            <div
              class="p-5 rounded d-flex align-items-center justify-content-center"
            >
              <div class="align-items-center">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  style="width: 3rem; height: 3rem;"
                ></b-spinner>
                <div class="ml-3">
                  <h2 class="mt-2">กรุณารอสักครู่</h2>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "../../layouts/PageHeader";
import CourseNavSteps from "./partials/CourseNavSteps";
import CourseNavToolbar from "./partials/CourseNavToolbar";
import YoutubeUploadModal from "./YoutubeUploadModal";
import DeleteIntroModal from "./DeleteIntroModal";
import ModuleItem from "./ModuleItem";
import SearchBox from "../../shared/SearchBox";
import Loading from "../../shared/Loading";
import draggable from "csq-vue-multi-draggable";
import VueYoutube from "vue-youtube";
import api from "@/services/api.js";

import { mapActions, mapGetters } from "vuex";
import HLSVideoPlayer from "./HLSVideoPlayer.vue";

Vue.use(VueYoutube);

export default {
  name: "CourseContentPage",

  components: {
    PageHeader,
    SearchBox,
    Loading,
    CourseNavSteps,
    CourseNavToolbar,
    YoutubeUploadModal,
    ModuleItem,
    draggable,
    DeleteIntroModal,
    HLSVideoPlayer
  },

  props: {
    company: { default: null, type: Object },
    sidebarOpened: Boolean
  },

  data() {
    return {
      courseId: this.$route.params.courseId,
      enabledDraggable: true,
      modules: this.courseModuleList,
      dragging: false,
      youtubeLink: "",
      videoFile: null,
      dragMovedContext: {
        fromIndex: null,
        fromElement: null,
        toIndex: null,
        toElement: null
      },
      checktype: null,
      isOverlayVisible: false,
    };
  },

  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "dataError",
      "courseAttributeUpdated",
      "courseModuleList",
      "courseInfo",
      "uploadPercentage"
    ]),

    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    dragOptions() {
      return {
        animation: 100,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    youtubeVideoId() {
      if (this.courseInfo.courseVideoIntro) {
        return this.$youtube.getIdFromUrl(this.courseInfo.courseVideoIntro);
      }

      return null;
    },

    hasHLSVideoPath(){
      if(this.courseInfo.courseVideoIntro && this.$youtube.getIdFromUrl(this.courseInfo.courseVideoIntro) === null){
        return true;
      }
      return false;
    }
  },

  watch: {
    dragging: function(newValue) {
      if (newValue === false) {
        this.reorder();
      }
    }
  },

  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    this._getCourseInfo();
    this.fetchCourseModulesList({
      courseId: this.courseId
    });
    this.modules = this.courseModuleList;
  },

  methods: {
    ...mapActions("CourseManagement", [
      "updateCourseYoutubeIntro",
      "uploadVideoIntroFile",
      "fetchCourseModulesList",
      "createModule",
      "reorderModule",
      "getCourseInfo",
      "updateCourseInfo"
    ]),

    refreshPage() {
      location.reload();
    },

    previewVideoFile() {
      let video = document.getElementById("video-previewFile");
      let reader = new FileReader();
      reader.readAsDataURL(this.videoFile);
      reader.addEventListener("load", function() {
        video.src = reader.result;
      });
    },

    _getCourseInfo() {
      this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent"
      });
    },

    addModule: function() {
      this.createModule(this.courseId).then(() => {
        this._getCourseInfo();
        this.fetchCourseModulesList({
          courseId: this.courseId
        });
      });
    },

    checkMove: function(e) {
      this.dragMovedContext = {
        fromIndex: e.draggedContext.index,
        fromElement: e.draggedContext.element,
        toIndex: e.relatedContext.index,
        toElement: e.relatedContext.element
      };
    },

    reorder: function() {
      if (this.dragMovedContext.toIndex !== null) {
        this.isOverlayVisible = true;
        this.reorderModule({
          courseId: this.courseId,
          moduleOrder: [
            {
              moduleId: this.dragMovedContext.fromElement.moduleId,
              order: this.dragMovedContext.toIndex
            },
            {
              moduleId: this.dragMovedContext.toElement.moduleId,
              order: this.dragMovedContext.fromIndex
            }
          ]
        }).then(() => {
          this._getCourseInfo();
          this.fetchCourseModulesList({
            courseId: this.courseId,
          }).then(() => {
            this.isOverlayVisible = false;
          });
        });
      }
    },

    async submitYoutubeLink(url) {
      await this.updateCourseYoutubeIntro({
        cId: this.courseId,
        comId: this.company.id,
        youtubeLink: url
      }).then(() => {
        this.updateCourseInfo({
          courseId: this.$route.params.courseId,
          attribute: { ["courseName"]: this.courseInfo.courseName }
        });
        this._getCourseInfo();
      });
    },

    async _uploadVideoIntroFile() {
      const ext = this.videoFile.type.split("/").pop();
      const allowedExtensions = ["mp4"];

      if (!allowedExtensions.includes(ext)) {
        this.checktype = true;
      } else {
        this.checktype = false;
        await this.uploadVideoIntroFile({
          courseId: this.courseId,
          companyId: this.company.id,
          videoFile: this.videoFile
        }).then(() => {
          if (this.dataError == 500) {
            this.checktype = true;
          } else {
            this.updateCourseInfo({
              courseId: this.courseId,
              attribute: { ["courseName"]: this.courseInfo.courseName }
            });
            this._getCourseInfo();
            this.previewVideoFile();
          }
        });
      }
    },
    getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchCourseList(this.fetchParams);
    },

    browseFile(elementId) {
      var elem = document.getElementById(elementId);
      if (elem && document.createEvent) {
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("click", true, false);
        elem.dispatchEvent(evt);
      }
    },

    toggleDraggable(onEditing) {
      this.enabledDraggable = !onEditing;
    }
  }
};
</script>

<style lang="scss" scoped>
.border-left-dashed {
  border-left: 2px dashed #dee2e6 !important;
}

.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0;
  background: #fff;
}

.icon-success {
  position: absolute;
  margin: 5px 10px;
  z-index: 9999;
  right: 20px;
}

.uploaded {
  background-image: url("/image/icon_check.png");
}

.drop-zone {
  height: 90px;
  background-image: none;
  background-position: none;
  background-repeat: none;
  background-size: none;
}
</style>

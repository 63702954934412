<template>
  <div>
    <span>พิมพ์ชื่อ หมวดหมู่คอร์ส ใหม่</span>
    <b-form-input v-model="newTag" style="padding:1rem" class="mt-2">
    </b-form-input>
    <div
      class="mt-2"
      v-if="
        newTag &&
          tag.filter((i) => i.tagName.toLowerCase() == newTag.toLowerCase())
            .length > 0
      "
    >
      <span style="color :red">หมวดหมู่คอร์ส นี้มีอยู่ในระบบแล้ว</span>
    </div>
    <div style="float:right">
      <b-button variant="secondary" class="mt-2 m-1 p-2" @click="closeModal()"
        >ยกเลิก</b-button
      >
      <b-button
        variant="primary"
        class="mt-2 m-1 p-2"
        @click="submit()"
        :disabled="
          newTag &&
            tag.filter((i) => i.tagName.toLowerCase() == newTag.toLowerCase())
              .length > 0
        "
        >เพิ่ม หมวดหมู่คอร์ส</b-button
      >
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js';
export default {
  props: ['tag'],
  data() {
    return {
      newTag: null,
      result: {},
      apiRoute: `/v1/tags/create-tag`,
      errorText: null,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('create-tag');
    },
    async submit() {
      let data = {
        tag_name: this.newTag,
      };
      await api
        .post(this.apiRoute, data)
        .then(() => {
          this.$bvToast.toast(`สร้าง หมวดหมู่คอร์ส ${this.newTag} สำเร็จแล้ว`, {
            title: `สำเร็จแล้ว`,
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
          setTimeout(() => {
            this.$emit('created');
            this.$bvModal.hide('create-tag');
          }, 500);
        })
        .catch(() => {
          this.$bvToast.toast(`มี หมวดหมู่คอร์ส ${this.newTag} อยู่แล้ว`, {
            title: `เกิดข้อผิดพลาด`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
        });
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <b-button
      @click="modalShow = !modalShow"
      variant="outline-primary"
      size="sm"
    >
      ตรวจสอบใบหน้าผู้เรียน
    </b-button>
    <b-modal
      header-bg-variant="light"
      footer-bg-variant="light"
      :ref="modalRef"
      hide-header-close
      size="xl"
      footer-class="border-top-0 px-3"
      modal-class="face-modal"
    >
      <template #modal-header>
        <h4 style="margin-left: auto; margin-right: auto; margin-bottom:0;"><strong>การตรวจสอบใบหน้า</strong></h4>
      </template>
      <template #default>
        <div class="ml-5 mr-5">
          <div>
            <b-media class="mb-2">
              <h5><strong>ใบหน้าการลงทะเบียน</strong></h5>
              <template v-slot:aside> </template>
              <p style="display: none">{{ getFrontFaceData(member.id) }}</p>
              <div style="display: flex">
                <b-img-lazy
                  class="mb-2"
                  style="width: 190px;"
                  alt="Image is not found."
                  :src="getFrontFace()"
                  onerror="this.src='/images/profile_alt.jpg'"
                />
                <div style="display: block; width: 30%;" class="ml-3">
                  <span><strong>ชื่อผู้เรียน</strong></span>
                  <br/>
                  <span class="mt-0">
                    {{ `${member.firstName} ${member.lastName}` }}
                  </span>
                </div>
                <div style="display: flex; width: 70%; margin-left: 7%">
                  <div class="ml-4" style="width: 50%">
                    <span><strong>จำนวนใบหน้าที่ตรงกัน</strong></span>
                    <h2><strong>{{ validFaces.length }}</strong></h2>
                  </div>
                  <div class="ml-4" style="width: 50%">
                    <span><strong>จำนวนใบหน้าที่ไม่ตรงกัน</strong></span>
                    <h2><strong>{{ invalidFaces.length }}</strong></h2>
                  </div>
                </div>
              </div>
            </b-media>
          </div>
          <div
            name="underline"
            style="width: 100%; height: 2px; background-color: lightgrey"
          ></div>
          <b-overlay :show="onLoading" rounded="sm">
            <div class="mt-2 bg-light" style="height: 330px">
              <b-row class="no-gutters mt-3">
                <b-col
                  class="bg-white"
                  style="border-style: solid; border-color: lightgrey; height: 330px"
                >
                  <div
                    class="text-center p-1"
                    style="background-color: #4873fe"
                  >
                    <h5 class="my-2" style="color:white">จำนวนใบหน้าที่ตรงกัน</h5>
                  </div>
                  <div
                    style="height: 70%"
                    class="drop-area overflow-auto m-3 ml-2"
                    :class="{ 'drop-target': dropTarget['validFacesArea'] }"
                  >
                    <draggable
                      :list="validFaces"
                      multi-drag
                      animation="150"
                      group="faces"
                      class="row no-gutters pb-5"
                      selected-class="sortable-selected"
                      ghost-class="ghost"
                      @start="showDropTarget('invalidFacesArea')"
                      @end="hideDropTarget('invalidFacesArea')"
                    >
                      <div
                        class="col-sm-3 col-md-6 col-lg-4 p-1"
                        v-for="item in validFaces"
                        :key="item.face_path"
                      >
                        <b-img-lazy
                          v-bind="mainProps"
                          :src="getImage(item.stat_id)"
                          alt=""
                        ></b-img-lazy>
                      </div>
                    </draggable>
                  </div>
                </b-col>
                <b-col
                  class="bg-white ml-2"
                  style="height: 330px; border-style: solid; border-color: lightgrey;"
                >
                  <div class="text-center p-1" style="background-color: #4873fe">
                    <h5 class="my-2" style="color:white">จำนวนใบหน้าที่ไม่ตรงกัน</h5>
                  </div>
                  <div
                    class="drop-area overflow-auto m-3"
                    :class="{ 'drop-target': dropTarget['invalidFacesArea'] }"
                    style="height: 70%"
                  >
                    <draggable
                      :list="invalidFaces"
                      multi-drag
                      animation="150"
                      group="faces"
                      class="row no-gutters pb-5"
                      selected-class="sortable-selected"
                      ghost-class="ghost"
                      @start="showDropTarget('validFacesArea')"
                      @end="hideDropTarget('validFacesArea')"
                    >
                      <div
                        class="col-sm-6 col-md-6 col-lg-4 p-1"
                        v-for="item in invalidFaces"
                        :key="item.name"
                      >
                        <b-img-lazy
                          v-bind="mainProps"
                          :src="getImage(item.stat_id)"
                          alt=""
                        ></b-img-lazy>
                      </div>
                    </draggable>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="md" variant="outline-danger border-0" @click="cancel()">
          ยกเลิก
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          @click="updateFaceStats()"
          :disabled="onLoading"
        >
          บันทึก
        </b-button>
        <b-button
          size="md"
          variant="primary"
          @click="updateFaceStats(1)"
          :disabled="onLoading"
        >
          บันทึกและอนุมัติใบหน้า
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import faceRecognitionService from "@/services/face-recognition-service";
import draggable from "csq-vue-multi-draggable";

export default {
  name: "ShowMemberFaceModel",
  components: {
    draggable,
  },
  props: {
    member: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      items: [],
      modalShow: false,
      onLoading: true,
      storage: process.env.VUE_APP_API_BASE_STORAGE_URL,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: "100%",
        style: "cursor: pointer",
        class: "bg-secondary",
      },
      modalRef: `member-faces-${this.member.id}-modal`,
      validFaces: [],
      invalidFaces: [],
      images: [],
      dropTarget: {
        validFacesArea: false,
        invalidFacesArea: false,
      },
      frontFace: "",
    };
  },
  watch: {
    async modalShow(isShow) {
      if (isShow) {
        await this.fetch();
        this.$refs[this.modalRef].show();
        this.fetchImages();
      } else {
        this.$refs[this.modalRef].hide();
      }
    },
  },
  methods: {
    showDropTarget(area) {
      this.dropTarget[area] = true;
    },
    hideDropTarget(area) {
      this.dropTarget[area] = false;
    },
    async fetch() {
      this.onLoading = true;
      await faceRecognitionService
        .getFaceStats({ m_id: this.member.id })
        .then(({ data }) => {
          this.validFaces = data.filter(
            (item) => item.face_verified === 1 && item.face_human_approve === 0
          );
          this.invalidFaces = data.filter(
            (item) => item.face_verified === 0 && item.face_human_approve === 0
          );
          this.items = data;
          this.onLoading = false;
        });
    },
    fetchImages() {
      [...this.validFaces, ...this.invalidFaces].forEach(async (image) => {
        await this.getImageBinary(image.stat_id);
      });
    },
    async getImageBinary(statId) {
      const { data } = await faceRecognitionService.getFaceImage({
        stat_id: statId,
      });
      if (data?.binary) {
        return this.images.push({
          statId,
          image: `data:image/jpeg;base64, ${data.binary}`,
        });
      }
      return this.images.push(null);
    },
    getImage(statId) {
      const image = this.images.find((image) => image.statId == statId);
      return image ? image.image : "";
    },
    async getFrontFaceData(mId) {
      const { data } = await faceRecognitionService.getRegisterFaceImages(mId);
      if (data.message == "Data not found") {
        this.frontFace = "";
      } else {
        this.frontFace = data.img_binary_0;
      }
    },
    getFrontFace() {
      if (this.frontFace == "") {
        return "";
      } else {
        return "data:image/jpeg;base64, " + this.frontFace;
      }
    },
    async updateFaceStats(approve = 0) {
      this.onLoading = true;

      const validFaces = this.validFaces.map((item) => {
        return {
          face_stat_id: item.stat_id,
          face_verified: 1,
          face_human_approve: approve,
        };
      });

      const invalidFaces = this.invalidFaces.map((item) => {
        return {
          face_stat_id: item.stat_id,
          face_verified: 0,
          face_human_approve: approve,
        };
      });

      await faceRecognitionService
        .updateFaceStats([...validFaces, ...invalidFaces])
        .then(() => {
          this.modalShow = false;
          this.$emit("updated");
          if (approve)
            this.$emit("approved", {
              validFaces: this.validFaces,
              invalidFaces: this.invalidFaces,
            });
        });

      this.onLoading = false;
    },
  },
};
</script>

<style lang="css" scoped>
.drop-area {
  height: 500px;
}
.ghost {
  opacity: 0.5;
}
.drop-target {
  border: 2px dashed #eb8123;
  background: #fef9e7;
}

.sortable-selected img {
  border: 3px solid #eb8123;
}
</style>

<style>
@media (min-width: 1000px){
  .face-modal .modal-dialog {
    max-width: 1000px !important;
  }
}

</style>

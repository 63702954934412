import CourseListPage from "../components/pages/course-management/CourseListPage";
import CourseInfoPage from "../components/pages/course-management/CourseInfoPage";
import CourseContentPage from "../components/pages/course-management/CourseContentPage";
import CourseMaterialPage from "../components/pages/course-management/CourseMaterialPage";
import CourseRulePage from "../components/pages/course-management/CourseRulePage";
import EvaluationReport from "../components/pages/course-management/EvaluationReportPage";

import CourseGroupListPage from "../components/pages/course-group-management/CourseGroupListPage";
import CourseGroupEditPage from "../components/pages/course-group-management/CourseGroupEditPage";

import ExamManagement from "../components/pages/exam-management/ExamManagement";
import ExamManagementUpload from "../components/pages/exam-management/exam-management-actions/ExamManagementUpload.vue";
import ExamManagementConfirmation from "../components/pages/exam-management/exam-management-actions/ExamManagementConfirmation.vue";
import ExamManagementSuccess from "../components/pages/exam-management/exam-management-actions/ExamManagementSuccess.vue";
import store from "../store/index";

export default [
  /**
   * Index page
   */
  {
    path: "",
    name: "index",
    component: CourseListPage
  },

  /**
   * Course management
   */
  {
    path: "courses",
    name: "course.management.index",
    component: CourseListPage
  },
  {
    path: "courses/:courseId/info",
    name: "course.management.info",
    component: CourseInfoPage,
    meta: {
      authRequired: true,
      permsRequired: ["update-course"]
    }
  },
  {
    path: "courses/:courseId/contents",
    name: "course.management.contents",
    component: CourseContentPage,
    meta: {
      authRequired: true,
      permsRequired: ["update-course"]
    }
  },
  {
    path: "courses/:courseId/materials",
    name: "course.management.materials",
    component: CourseMaterialPage,
    meta: {
      authRequired: true,
      permsRequired: ["update-course"]
    }
  },
  {
    path: "courses/:courseId/rules",
    name: "course.management.rules",
    component: CourseRulePage,
    meta: {
      authRequired: true,
      permsRequired: ["update-course"]
    }
  },

  {
    path: "courses/:courseId/evaluation",
    name: "course.management.evaluation",
    component: EvaluationReport,
    meta: {
      authRequired: true,
      permsRequired: ["update-course"]
    }
  },

  /**
   * Course group management
   */
  {
    path: "courses-group",
    name: "course-group.management.index",
    component: CourseGroupListPage
  },
  {
    path: "courses-group/:id",
    name: "course-group.management.edit",
    component: CourseGroupEditPage
  },

  /**
   * Exam management
   */
  {
    path: "exam-management",
    name: "company.exam-management",
    component: ExamManagement,
    props: true,
    meta: {
      authRequired: true,
      module: "exam-management",
      permsRequired: [
        "read-exam-analytic",
        "create-exam",
        "update-exam",
        "delete-exam"
      ]
    },
    redirect: {
      name: "company.exam-management.upload"
    },
    children: [
      {
        path: "upload",
        name: "company.exam-management.upload",
        meta: {
          module: "exam-management"
        },
        component: ExamManagementUpload,
        props: true
      },
      {
        path: "confirmation",
        name: "company.exam-management.confirmation",
        component: ExamManagementConfirmation,
        meta: {
          module: "exam-management"
        },
        props: true,
        beforeEnter(to, _, next) {
          if (!store.getters["ExamManagement/companyUploadedCsvData"]) {
            next({
              name: "company.exam-management",
              params: to.params
            });
            return;
          }
          next();
        }
      },
      {
        path: "success",
        name: "company.exam-management.success",
        component: ExamManagementSuccess,
        meta: {
          module: "exam-management"
        },
        props: true,
        beforeEnter(to, _, next) {
          if (!store.getters["ExamManagement/companyUploadedCsvData"]) {
            next({
              name: "company.exam-management",
              params: to.params
            });
            return;
          }
          next();
        }
      }
    ]
  }
];

<template>
  <div>
    <b-link @click="modalShow = !modalShow">
      <b-icon-box-arrow-up-right /> ดูรายชื่อคอร์ส
    </b-link>
    <b-modal
      :ref="modalRef"
      hide-header
      hide-header-close
      ok-title="ปิด"
      :ok-only="true"
      size="lg"
      body-class="p-4"
      footer-class="border-top-0 px-4"
      ok-variant="primary btn-sm"
    >
      <h5 class="mb-3">
        {{ `รายชื่อของคอร์สในกลุ่มคอร์ส: ${courseGroup.courseGroupName}` }}
      </h5>
      <b-table :fields="fields" :items="courseGroupCourses" :busy="isLoading">
        <template slot="table-busy" class="text-center text-danger my-2">
          <Loading />
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/shared/Loading";

export default {
  name: "ShowCoursesInCourseGroupsTable",
  components: {
    Loading
  },
  props: {
    courseGroup: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fields: [
        {
          label: "ชื่อคอร์ส",
          key: "courseName"
        }
      ],
      isLoading: false,
      modalShow: false
    };
  },
  computed: {
    ...mapGetters("CourseGroupManagement", ["courseGroupCourses"]),
    modalRef() {
      return `course-group-${this.courseGroup.courseGroupId}-modal`;
    }
  },
  watch: {
    modalShow(isShow) {
      if (isShow) {
        this.fetch();
        this.$refs[this.modalRef].show();
      }
    }
  },
  methods: {
    ...mapActions("CourseGroupManagement", ["getCourseGroupInfo"]),
    async fetch() {
      this.isLoading = true;
      await this.getCourseGroupInfo({
        courseGroupId: this.courseGroup.courseGroupId
      }).then(() => (this.isLoading = false));
    }
  }
};
</script>

<template>
  <div class="wrap">
    <div class="jumbotron-background" style="z-index: -1">
      <img src="/images/newyork_bg.png" style="width: 100%" />
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
    <footer class="footer mt-auto text-center">
      <span class="text-light small">
        © Course Square 2020 All right reserved. Designed by Beyond Zigma
      </span>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  props: {
    version: { default: null, type: String }
  }
};
</script>

<style type="css" scoped>
.wrap {
  justify-content: center;
  align-items: center;
}

.footer {
  position: absolute;
  bottom: 15px;
}

.bg-frosted-glass {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.25rem;
}
</style>

<style lang="css">
html,
#app,
body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
}

.form-signin {
  width: 100%;
  /* max-width: 420px; */
  padding: 15px;
  /* margin: auto; */
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.jumbotron {
  position: relative;
  overflow: hidden;
}

.jumbotron .container {
  position: relative;
  z-index: 2;

  background: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.jumbotron-background {
  object-fit: cover;
  font-family: "object-fit: cover;";
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  /* height: 100%; */
  opacity: 0.7;
}

img.blur {
  -webkit-filter: blur(25px);
  filter: blur(25px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}
</style>

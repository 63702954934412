/**
 * getter for checking if user is authenticated.
 * @param {Object} state - state gained from vuex
 */
export const isAuthenticated = state => {
  return state.isAuthenticated;
};

/**
 * getter for getting user data uncapsulated from token.
 * @param {Object} state - gained from vuex
 */
export const authUser = state => {
  return state.authUser;
};

/**
 * getter for getting auth token string.
 * @param {Object} state - gained from vuex
 */
export const authToken = state => {
  return state.authToken;
};

/**
 * getter for getting access token string.
 * @param {Object} state - gained from vuex
 */
export const accessToken = state => {
  return state.accessToken;
};

<template>
  <div>
    <!-- Page header -->
    <page-header title='Affiliate Links'></page-header>
    <div class='affiliate-content'>
      <!-- Company selection -->
      <b-row v-if='authorizationRoles(["org/admin"])'>
        <b-col cols='12' class='pb-3'>
          <company-selection
            :options='options'
            @selected='onCompanySelectedHandler'
          />
        </b-col>
      </b-row>
      <!-- Company link -->
      <b-row>
        <b-col cols='12' class='pb-4'>
          <company-link 
            :companyLink='companyLink'
            @newLinkCreated='fetchAffiliateLinks'
          />
        </b-col>
      </b-row>
      <!-- Afiiliate table -->
      <b-row>
        <b-col cols='12'>
          <affiliate-link-table 
            :affiliateLinks='affiliateLinks'
            :publisherId='companySelected'
            @linkUpdated='fetchAffiliateLinks'
            @linkDeleted='fetchAffiliateLinks'
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import CompanySelection from "./components/CompanySelection.vue";
import CompanyLink from "./components/CompanyLink.vue";
import AffiliateLinkTable from './components/AffiliateLinkTable.vue';
import service from '@/services/api.js';
import ToastMixin from '@/mixins/toast-mixin.js'
import AffiliateMixin from '@/components/pages/affiliate/link/mixins/affiliate-link.js'

export default {
  mixins: [ToastMixin, AffiliateMixin],
  components: {
    PageHeader,
    CompanySelection,
    CompanyLink,
    AffiliateLinkTable
  },
  data() {
    return {
      companySelected: '*',
      options: [],
      companyLink: null,
      affiliateLinks: []
    }
  },
  created() {
    this.fetchOptions()
    this.setCompanyLink()
    this.fetchAffiliateLinks()
  },
  methods: {
    async fetchOptions() {
      try {
        let res = await service.get(`/v1/affiliate/publishers`)
        this.options = []
        this.options = res.data.data.map(publisher => {
          return {
            ...publisher,
            text: `${publisher.publisherName}`,
            value: `${publisher.publisherId}`
          }
        })
        this.options.unshift({
          text: 'ทั้งหมด',
          value: '*'
        })
      } catch (err) {
        console.log('fetchOptions error!')
        console.log(err.response)
      }
    },
    async setCompanyLink() {
      try {
        let res = await service.get(`/v1/affiliate/company-link`)
        let filteredResults = res.data.data.filter(el => !el.linkUrl.includes('salespage'))
        if (filteredResults.length <= 0) {
          this.companyLink = '-'
        } else {
          this.companyLink = filteredResults[0].linkUrl
        }
      } catch (err) {
        console.log('setCompanyLink error!')
        console.log(err.response)
      }
    },
    async fetchAffiliateLinks() {
      try {
        let res = await service.get('/v1/affiliate/links')
        this.affiliateLinks = []
        this.affiliateLinks = [...res.data.data]
      } catch (err) {
        console.log('fetchAffiliateLinks error!')
        console.log(err.response)
        this.displayToast({
          variant: 'danger',
          title: 'Fail',
          content: 'Retrieve affiliate links from database failed'
        })
      }
    },
    onCompanySelectedHandler(e) {
      this.companySelected = e;
    }
  }
}
</script>

<style scoped>
.affiliate-content {
  padding: 1rem 3rem;
}
</style>
export default {
  filters: {
    statusColor: function(value, statusOptions) {
      let status = statusOptions.find(data => data.value === value);
      return status.color;
    },

    statusText: function(value, statusOptions) {
      let status = statusOptions.find(data => data.value === value);
      return status.text;
    },
  },

  data() {
    return {
      statusOptions: [
        { value: null, text: "สถานะทั้งหมด" },
        { value: "PUBLISH", text: "กำลังเผยแพร่", color: "bg-success" },
        { value: "SUSPEND", text: "พักการเผยแพร่", color: "bg-danger" },
        {
          value: "PUBLISH_EDIT",
          text: "เผยแพร่และมีการแก้ไข",
          color: "bg-primary"
        },
        {
          value: "REQUEST",
          text: "เผยแพร่และขออนุมัติคอร์สใหม่",
          color: "bg-primary"
        },
        {
          value: "PUBLISH_REQUEST",
          text: "เผยแพร่และขออนุมัติคอร์สใหม่",
          color: "bg-primary"
        },
        { value: "COMINGSOON", text: "เผยแพร่เร็ว ๆ นี้", color: "bg-info" },
        { value: "DRAFT", text: "ร่าง", color: "bg-warning" },
        { value: "OBSOLETE", text: "เลิกใช้แล้ว", color: "bg-warning" },
        { value: "DISABLED", text: "ปิดการเข้าถึง", color: "bg-secondary"}
      ]
    };
  },

  computed: {
    coursePreviewName() {
      {
        return this.courseInfo.courseSlug;
      }
    },
    coursePreviewUrl() {
      {
        return this.company.whitelabelUrl + "/course/courseInfo";
      }
    }
  },
  methods: {},
};

<!-- HTML Part -->
<template>
  <div>
    <!-- Heading -->
    <h5 class="card-title">{{ title }}</h5>
    <br />
    <!-- 1st Line -->

    <div style="width: 100%; display: flex">
      <div style="width: 40%; margin-right: 20px">
        <label :style="labelStyle" >เลขที่ใบอนุญาต</label>
        <br />
        <label>{{ no }}</label>
      </div>
      <div style="width: 40%">
        <label :style="labelStyle" >วันใบอนุญาตหมดอายุ</label>
        <br />
        <label>{{ exp }}</label>
      </div>
      <div style="width: 20%">
        <div v-if="type" :style="renewLevelStyle">
          ขอต่ออายุใบอนุญาต <br>
          <span v-if="lLevel">{{ renewLifeLevelText }}</span>
          <span v-else-if="nlLevel">{{ renewNonlifeLevelText }}</span>
        </div>
      </div>
    </div>
    <br />
    <!-- 2nd Line -->
    <div style="width: 100%; display: flex">
      <div style="width: 40%; margin-right: 20px">
        <label :style="labelStyle" >ประเภท</label>
        <br />
        <label>{{ typeName() }}</label>
      </div>
      <div style="width: 40%">
        <label :style="labelStyle" >จำนวนชั่วโมงอบรม</label>
        <br />
        <label>{{ trHrs }}</label>
      </div>
    </div>
    <br />
    <!-- 3rd Line -->
    <b-form-checkbox v-if="type" v-model="isChecked" :name="'ReducedHours'" disabled >
      <a :href="linkHref" target="_blank" data-toggle="modal">คุณมีสิทธิ์ขอลดระยะเวลาการอบรม ตามประกาศ สำนักงาน คปภ. เรื่องกำหนดหลักสูตรและวิธีการ การอบรมความรู้เกี่ยวกับ<span class="type">{{ spanText }}</span></a>
    </b-form-checkbox>
    <br />
    <b-button
      variant="primary"
      style="margin-right: 10px;"
      @click="modalShowEditInsuranceForm()"
      >{{editButtonText}}</b-button
    >
    <b-button
      v-if="type"
      variant="outline-danger"
      @click="modalShowConfirmDeleteInsuranceForm()"
      ><i class="fa-solid fa-trash-can"></i>ลบใบอนุญาต</b-button
    >

    <b-modal
      centered
      v-model="modalEditInsuranceForm"
      title="แก้ไขข้อมูลใบอนุญาต"
      size="lg"
      :hide-footer="true"
      ref="modal-edit-insurance-form"
    >
      <div>
        <EditInsuranceForm
          :title="title"
          :id="id"
          :type="type"
          :no="no"
          :exp="exp"
          :trHrs="trHrs"
          :card="card"
          :opt="opt"
          :nlReducedHours="nlReducedHours"
          :nlLevel="nlLevel"
          @getIns="getIns()"
          @modalHideEditInsuranceForm="modalHideEditInsuranceForm()"
        />
      </div>
    </b-modal>

    <b-modal
      centered
      v-model="modalConfirmDeleteInsuranceForm"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
      ref="modal-delete-insurance-form"
    >
      <div>
        <ConfirmDeleteInsuranceForm
          :firstName="firstName"
          :lastName="lastName"
          :typeName="title"
          :isConfirmed="isConfirmed"
          @getIns="getIns()"
          @modalHideConfirmDeleteInsuranceForm="modalHideConfirmDeleteInsuranceForm()"
          @deleteInsuranceMemberData="deleteInsuranceMemberData()"
        />
      </div>
    </b-modal>
  </div>
</template>

<!-- Javascript Part -->
<script>
//Import
//--Vue Component
import "vue-select/dist/vue-select.css";
import EditInsuranceForm from "./EditInsuranceForm.vue";
import ConfirmDeleteInsuranceForm from "./ConfirmDeleteInsuranceForm.vue";
import api from "@/services/api.js";
//Export
export default {
  //--Vue Component
  components: { EditInsuranceForm, ConfirmDeleteInsuranceForm },
  //--Class Property
  props: {
    title: { type: String, default: null },
    id: { type: Number, default: null },
    type: { type: String, default: null },
    no: { type: String, default: null },
    exp: { type: String, default: null },
    trHrs: { type: String, default: null },
    card: { type: String, default: null },
    opt: { type: Array, default: null },
    lReducedHours: { type: Number, default: null },
    nlReducedHours: { type: Number, default: null },
    lLevel: { type: String, default: null },
    nlLevel: { type: String, default: null},
    firstName: { type: String, default: null },
    lastName: { type: String, default: null },
  },
  data() {
    return {
      //Global Variables
      mId: this.$route.params.id,
      comId: localStorage.getItem("ComId"),
      token: localStorage.getItem("token"),
      baseUrl:
        process.env.VUE_APP_FILE_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220815.coursesquare.com",
      //Local
      minDate: this.no
        ? new Date().toISOString().split('T')[0]
        : null,
      formType: this.type,
      config: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token")
      },
      formData: new FormData(),
      renewLevelStyle: {
        color: '#183F80',
        backgroundColor: '#D1E4FD',
        margin: '10px',
        padding: '10px',
        'font-size': '14px',
      },
      renewLifeLevelText: '',
      renewNonlifeLevelText: '',
      spanText: '',
      linkHref: '',
      isChecked: false,
      modalEditInsuranceForm: false,
      modalConfirmDeleteInsuranceForm: false,
      isConfirmed: false,
      editButtonText: this.type ? "แก้ไขข้อมูลใบอนุญาต" : "เพิ่มข้อมูลใบอนุญาต",
      labelStyle: {
        fontWeight: 'bold',
      }
    };
  },
  mounted() {
    if (this.type) {
      this.formData.append("insuranceType", this.type);
    }
    if (this.type == "LIFE_BROKER" || this.type == "LIFE_AGENT") {
      if (this.type == "LIFE_BROKER") {
        this.renewLifeLevelText = "นายหน้าประกันชีวิต ครั้งที่ ";
      } else {
        this.renewLifeLevelText = "ตัวแทนประกันชีวิต ครั้งที่ ";
      }
      this.isChecked = this.lReducedHours ? true : false;
      this.spanText = "การประกันชีวิต";
      this.linkHref = "https://www.oic.or.th/sites/default/files/content/91695/prakaas-hlaksuutrprakanchiiwit-rabfangkhwaamehn.pdf";
    } else if (this.type == "NONLIFE_BROKER" || this.type == "NONLIFE_AGENT") {
      if (this.type == "NONLIFE_BROKER") {
        this.renewNonlifeLevelText = "นายหน้าประกันวินาศภัย ครั้งที่ ";
      } else {
        this.renewNonlifeLevelText = "ตัวแทนประกันวินาศภัย ครั้งที่ ";
      }
      this.isChecked = this.nlReducedHours ? true : false;
      this.spanText = "ประกันวินาศภัย";
      this.linkHref = "https://www.oic.or.th/sites/default/files/content/91695/prakaas-hlaksuutrprakanwinaasphay-rabfangkhwaamkhidehn.pdf";
    }
    if (this.lLevel) {
      this.renewLifeLevelText += this.lLevel == "RENEW1" 
        ? "1" 
        : this.lLevel == "RENEW2"
        ? "2"
        : this.lLevel == "RENEW3"
        ? "3"
        : this.lLevel == "RENEW4"
        ? "4"
        : this.renewLifeLevelText = null;
    } else if (this.nlLevel) {
      this.renewNonlifeLevelText += this.nlLevel == "RENEW1" 
        ? "1" 
        : this.nlLevel == "RENEW2"
        ? "2"
        : this.nlLevel == "RENEW3"
        ? "3"
        : this.nlLevel == "RENEW4"
        ? "4"
        : this.renewNonlifeLevelText = null;
    } else {
      this.renewLifeLevelText = null;
      this.renewNonlifeLevelText = null;
    }
  },

  methods: {
    edit(input, name = null) {
      let inputName = null;
      let inputVal = null;
      if (name == "Type") {
        inputName = name;
        inputVal = input;
      } else if (input.name == "Card") {
        inputName = input.name;
        inputVal = input.value.insuranceCardPath;
      } else {
        inputName = input.name;
        inputVal = input.value;
      }
      this.$emit("edit", {
        name: inputName,
        value: inputVal
      });
    },

    async getIns() {
      await this.$emit("getIns");
    },
    typeName(){
      const selected = this.opt.find(opt => opt.value === this.type);
      return selected ? selected.label : '';
    },
    modalShowEditInsuranceForm() {
      this.modalEditInsuranceForm = true;
    },
    modalHideEditInsuranceForm() {
      this.modalEditInsuranceForm = false;
    },
    modalShowConfirmDeleteInsuranceForm() {
      this.modalConfirmDeleteInsuranceForm = true;
    },
    modalHideConfirmDeleteInsuranceForm() {
      this.modalConfirmDeleteInsuranceForm = false;
    },
    async deleteInsuranceMemberData() {
      let insuranceData = {};
      insuranceData = {
        id: this.id,
        type: this.type
      }
      await api
        .post(`/v1/insurance/${this.mId}/delete`, {
          ins: insuranceData
        })
        .then(() => {
          this.isConfirmed = true;
        });
    }
  }
};
</script>
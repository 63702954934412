// import Vuex from "vuex";
// import Vue from "vue";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    exams: [],
    exam: {},
    allAnswer: [],
    answer: [],
    test_state: Number,
    tempSection: {},
    tempQuestion: {},
    tempChoice: {},
    collapsedQuestions: {},
    hasAnswers: {},
    solutions: {},
    sectionCount: 0,
    questionCount: 0
  },
  actions,
  getters,
  mutations,
};

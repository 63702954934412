<template>
  <div style="margin:0 1rem 0 1rem">
    <layout>
      <template v-slot:header>
        <PageHeader title="คูปอง"></PageHeader
      ></template>
      <template v-slot:body>
        <coupon
          :apiRoute="`/v1/analytics/revenues/get-revenue-by-coupon`"
          :perPage="20"
        />
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/pages/analytics/shared/Layout.vue';

import coupon from './panel/CouponPanel';
import PageHeader from '@/components/layouts/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,
    coupon,
  },
};
</script>

<template>
  <div>
    <video ref="videoPlayer" class="video-js" :style="{ width: width, height: height }"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";

export default {
  name: "HLSVideoPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '257px'
    }
  },
  mounted() {
    const video = this.$refs.videoPlayer;
    const options = {
      html5: {
        hls: {
        },
      },
      controls: this.controls,
      autoplay: this.autoplay,
    };
    this.player = videojs(video, options);
    this.player.src({ src: this.url, type: "application/x-mpegURL" });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<template>
  <div>
    <PageHeader title="เพิ่มผู้เรียน" icon="fa fa-user-plus" />

    <b-container fluid="xl" class="pt-5">
      <div class="inner bg-container">
        <div class="card card-csq no-shadow">
          <div class="card-header bg-light">
            <ul class="nav nav-tabs card-header-tabs float-left">
              <li class="nav-item">
                <a class="nav-link active" href="#">รายบุคคล</a>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'group.index',
                    params: { company: company.url },
                  }"
                  class="nav-link text-csq"
                  >กลุ่ม</router-link
                >
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-lg-12 m-t-25">
                <b-form>
                  <b-alert v-model="showAlert" variant="danger" dismissible>
                    {{ errorMessage }}
                  </b-alert>
                  <div class="card border-0 no-shadow bg-light mb-3">
                    <div class="card-header pt-3 border-0 bg-transparent">
                      <h5 class="card-title">ข้อมูลผู้เรียน</h5>
                    </div>
                    <div class="card-body pb-0">
                      <div class="form-row">
                        <b-form-group class="col-md-4">
                          <label>ชื่อ (จำเป็น)</label>
                          <b-form-input
                            v-model="form.firstName"
                            :state="
                              !form.firstName ? null : validation.firstName
                            "
                            type="text"
                            placeholder="ชื่อ"
                            :class="{ 'is-invalid': hasError('firstName') }"
                            trim
                            :title="company.id === 513 ? 'กรุณากรอกชื่อเป็นภาษาไทย' : null"
                          ></b-form-input>

                          <b-form-invalid-feedback
                            >กรุณากรอกชื่อ</b-form-invalid-feedback
                          >
                          <b-form-valid-feedback></b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group class="col-md-4">
                          <label>นามสกุล (จำเป็น)</label>
                          <b-form-input
                            v-model="form.lastName"
                            :state="!form.lastName ? null : validation.lastName"
                            type="text"
                            placeholder="นามสกุล"
                            :class="{ 'is-invalid': hasError('lastName') }"
                            trim
                            :title="company.id === 513 ? 'กรุณากรอกนามสกุลเป็นภาษาไทย' : null"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            >กรุณากรอกนามสกุล</b-form-invalid-feedback
                          >
                          <b-form-valid-feedback></b-form-valid-feedback>
                        </b-form-group>
                      </div>
                      <div class="form-row">
                        <b-form-group class="col-md-4">
                          <label>รหัสบัตรประชาชน</label>
                          <b-form-input
                            v-model="form.idCard"
                            :state="!form.idCard ? null : validation.idCard"
                            type="text"
                            placeholder="เลข 13 หลัก"
                            :class="{ 'is-invalid': hasError('idCard') }"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            กรุณากรอกรหัสบัตรประชาชนให้ถูกต้อง
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group class="col-md-4">
                          <label>เบอร์โทรศัพท์</label>
                          <b-form-input
                            v-model="form.phoneNumber"
                            :state="
                              !form.phoneNumber ? null : validation.phoneNumber
                            "
                            type="text"
                            placeholder="0999999999"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            กรุณากรอกเบอร์โทรให้ถูกต้อง - The phone number must
                            be 10 digits.
                          </b-form-invalid-feedback>
                          <b-form-valid-feedback></b-form-valid-feedback>
                        </b-form-group>
                      </div>
                      <b-row>
                        <b-col md="8">
                          <b-form-group
                            class="mb-3"
                            label="เกี่ยวกับผู้เรียน"
                            label-for="textarea-formatter"
                          >
                            <b-form-textarea
                              id="textarea-formatter"
                              v-model="form.about"
                              placeholder="เกี่ยวกับผู้เรียน"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div v-if="!isTutorType()" class="card border-0 bg-light mb-3 no-shadow">
                    <div class="card-header pt-3 border-0 bg-transparent">
                      <h5 class="card-title">ข้อมูลพนักงาน</h5>
                    </div>
                    <div class="card-body pb-0">
                      <div class="form-row">
                        <b-form-group class="col-md-4">
                          <label>รหัสพนักงาน</label>
                          <b-form-input
                            v-model="form.staffId"
                            type="text"
                            placeholder="รหัสพนักงาน"
                            trim
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-4">
                          <label>แผนก</label>
                          <b-form-input
                            v-model="form.department"
                            type="text"
                            placeholder="ชื่อแผนก"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>

                  <div class="card border-0 bg-light mb-3 no-shadow">
                    <div class="card-header pt-3 border-0 bg-transparent">
                      <h5 class="card-title">ข้อมูลผู้ใช้งาน</h5>
                    </div>
                    <div class="card-body pb-0">
                      <div class="form-row">
                        <b-col md="4">
                          <label>อีเมล</label>
                          <b-form-input
                            v-model="form.email"
                            :state="!form.email ? null : validation.email"
                            :class="{ 'is-invalid': hasError('email') }"
                            type="email"
                            placeholder="example@email.com"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            >กรุณากรอกอีเมลให้ถูกต้อง หรือ
                            มีอีเมลนี้ในระบบแล้ว</b-form-invalid-feedback
                          >
                          <b-form-valid-feedback></b-form-valid-feedback>
                        </b-col>
                        <b-form-group class="col-md-4">
                          <label> ชื่อผู้ใช้งาน </label>
                          <b-form-input
                            v-model="form.username"
                            :state="!form.username ? null : validation.username"
                            :class="{ 'is-invalid': hasError('username') }"
                            type="text"
                            placeholder="username"
                            trim
                            @change="checkUsername"
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            ชื่อผู้ใช้งานไม่ถูกต้อง หรือ
                            มีผู้ใช้งานนี้ในระบบอยู่แล้ว</b-form-invalid-feedback
                          >
                          <b-form-valid-feedback></b-form-valid-feedback>
                        </b-form-group>
                      </div>

                      <change-password-form
                        :companyId="company.id"
                        :errors="errors"
                        @passwordConfirmed="setPassword"
                      ></change-password-form>
                    </div>
                  </div>

                  <div class="m-t-35 text-right">
                    <router-link
                      :to="{ name: 'member.index' }"
                      class="btn btn-link text-csq"
                      >ยกเลิก</router-link
                    >
                    <button
                      class="btn btn-dark"
                      type="button"
                      @click="handleSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.inner -->
    </b-container>
  </div>
</template>

<script>
import api from "../../../services/api.js";
import ChangePasswordForm from "./shared/ChangePasswordForm.vue";
import PageHeader from "./../../layouts/PageHeader";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    ChangePasswordForm,
    PageHeader
  },
  props: { company: { type: Object, default: null } },
  mixins: [authMixin],
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: null,
        phoneNumber: null,
        dateOfBirth: "",
        idCard: "",
        department: "",
        about: "",
        username: "",
        password: "",
        passwordConfirm: "",
        staffId: ""
      },
      submitted: false,
      showAlert: false,
      errors: [],
      errorMessage: ""
    };
  },

  computed: {
    validation() {
      // this.errors = [];
      // const form = this.form;

      return {
        // firstName:
        //   this.form.firstName.length > 0 && this.form.firstName.length < 100,
        // lastName:
        //   this.form.lastName.length > 0 && this.form.lastName.length < 100,
        // email: this.validEmail(this.form.email),
        // phoneNumber: this.validPhoneNumber(this.form.phoneNumber),
        // idCard: this.validIdCard(this.form.idCard),
        // username: this.validUsername(this.form.username),
      };
    }
  },

  methods: {
    addMember(form) {
      api
        .post(`/v1/members`, {
          company_id: this.company.id,
          first_name: form.firstName,
          last_name: form.lastName,
          email: form.email,
          phone_number: form.phoneNumber,
          id_card: form.idCard,
          department: form.department,
          about: form.about,
          staff_id: form.staffId,
          username: form.username,
          password: form.password
        })
        .then(() => {
          this.$bvToast.toast("เพิ่มข้อมูลเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true
          });
          this.$router.push({ name: "member.index" });
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;

          if (response.data.errors?.username?.[0]) {
            this.errors.push("username");
          }

          if (response.data.errors?.email?.[0]) {
            this.errors.push("email");
          }
        });
    },

    async checkUsername(username) {
      var myIndex = (this.errors).indexOf("username");
      if (myIndex !== -1) {
        (this.errors).splice(myIndex, 1);
      }
      if (username) {
        let { data } = await api.get(`/v1/check-username/${username}`);
        if (!data) {
        this.errors.push("username");
        }
      }
    },

    setPassword(data) {
      this.form.password = data.password;
    },

    async handleSubmit() {
      this.submitted = true;

      if (await this.validateForm()) {
        this.addMember(this.form);
      } else {
        console.log(this.errors);
      }
    },

    validateForm: async function() {
      this.errors = [];

      const form = this.form;

      if (!form.firstName) this.errors.push("firstName");
      if (!form.lastName) this.errors.push("lastName");
      if (!form.idCard && ( this.company.id == 485 || this.company.id == 513 || this.company.id == 519 ) ) this.errors.push("idCard");
      if (this.company.id == 513) {
        const thaiCharactersRegex = /^[ก-๙]+$/;
        if (!thaiCharactersRegex.test(this.form.firstName)){
          this.errors.push("firstName");
        }
        if (!thaiCharactersRegex.test(this.form.lastName)){
          this.errors.push("lastName");
        }
      }
      if (!form.email && !form.username){
          this.errors.push("email");
          this.errors.push("username");
      }
      if (form.username){
        let { data } = await api.get(`/v1/check-username/${form.username}`);
        if (!data) {
          this.errors.push("username");
        }
      }
      if (form.email && !this.validEmail(form.email)) this.errors.push("email");
      if (form.phoneNumber && !this.validPhoneNumber(form.phoneNumber))
        this.errors.push("phoneNumber");

      if (form.idCard && !this.validIdCard(form.idCard))
        this.errors.push("idCard");

      if (!this.validUsername(form.username)) this.errors.push("username");

      return !this.errors.length;
    },

    validEmail: function(email) {
      console.log(email);
      // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // return re.test(email);
      return true;
    },

    validPhoneNumber: function(phoneNumber) {
      if (phoneNumber) {
        // let re = /^[\+]?\d{2,}?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im;
        const count = (phoneNumber.match(/\d/g) || []).length;
        console.log(count);
        // return re.test(phoneNumber) && (count === 9 || count === 10);
        return true;
      }

      return true;
    },

    validIdCard: function(input) {
      return /^\d+$/.test(input) && input.length == 13;
    },

    validUsername: input =>
      input.length === 0 || /^[0-9a-zA-Z_.-]+/.test(input),

    validPassword: input => input.length >= 6,

    hasError(field) {
      const errors = this.errors;
      return errors.includes(field);
    }
  }
};
</script>

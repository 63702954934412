<template>
  <div class>
    <b-row>
      <b-col cols="8">
        <b-form-input
          v-model="search"
          placeholder="ค้นหาผู้เรียน"
          style="padding: 1rem; display: inline-block; width: 50%"
        >
        </b-form-input>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button @click="exportData()" class="btn btn-download" href=""
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
        >
      </b-col>
    </b-row>
    <b-table
      id="course-view-table"
      :per-page="perPage"
      :current-page="currentPage"
      striped
      table-class="tbNumber text-left "
      :items="
        search == null
          ? result
          : result.filter((i) =>
              (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
            )
      "
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      small
      sort-icon-left
      responsive
      :busy="isBusy"
      hover
    >
      <template v-slot:cell(studentName)="data">
        <span>{{
          data.item.mp_firstName + ' ' + data.item.mp_lastName
        }}</span></template
      >
      <template v-slot:cell(learningTime)="data">
        <span>{{ data.item.learning_time }} ชั่วโมง</span>
      </template>
      <template v-slot:cell(view)="data">
        <span>{{ data.item.lel_viewCounter }}</span>
      </template>
      <template v-slot:cell(date)="data">
        <span>{{ data.item.latest_date }}</span>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="
        search == null
          ? result.length
          : result.filter((i) =>
              (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
            ).length
      "
      :per-page="perPage"
      aria-controls="course-view-table"
    ></b-pagination>
  </div>
</template>

<script>
// import layout from '@/components/pages/analytics/shared/Layout'
import api from '@/services/api.js';
import exp from '@/services/export.js';
export default {
  components: {
    // layout,
  },
  props: ['apiRoute'],
  data() {
    return {
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      result: [],
      search: null,
      sortBy: 'viewCounter',
      sortDesc: true,
      isBusy: true,
      fields: [
        { key: 'studentName', label: 'ชื่อผู้เรียน' },
        {
          key: 'learningTime',
          label: 'เวลาที่ใช้เรียนรวม',
        },
        {
          key: 'view',
          label: 'จำนวนครั้งที่เข้าดู',
        },
        {
          key: 'date',
          label: 'วันที่เข้าดูล่าสุด',
        },
      ],
      items: [],
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) =>
              (i.mp_firstName + ' ' + i.mp_lastName).includes(this.search)
            );
      set.forEach((i) => {
        let temp = {
          name: i.mp_firstName + ' ' + i.mp_lastName,
          learning_time: i.learning_time + ' ชั่วโมง',
          view: i.lel_viewCounter,
          latest_date: i.latest_date,
        };
        value.push(temp);
      });
      let key = [
        'ชื่อผู้เรียน',
        'เวลาที่ใช้เรียนรวม',
        'จำนวนครั้งที่เข้าดู',
        'วันที่เข้าดูล่าสุด',
      ];
      let filename = 'Lecture-View-Detail' + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.rows = this.result.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div>
    <header class="head">
      <div class="main-bar">
        <div class="row">
          <div class="col-lg-6 col-sm-4">
            <h4 class="nav_top_align text-dark font-weight-bold">
              <b-icon-people />
              {{ group.name }}
            </h4>
          </div>
        </div>
      </div>
    </header>
    <div class="outer">
      <div class="inner bg-container">
        <div class="card card-csq no-shadow rounded-0">
          <div class="card-body">
            <b-row>
              <b-col md="12">
                <b-row class="my-3">
                  <b-col sm="12">
                    <b-form-group
                      label="ชื่อกลุ่ม :"
                      label-cols-sm="3"
                      label-cols-lg="2"
                      label-for="group-name"
                    >
                      <b-form-input
                        id="group-name"
                        v-model="inputs.name"
                        placeholder="พิมพ์ชื่อกลุ่ม"
                        rows="3"
                        max-rows="3"
                        class="rounded-0 border-top-0 border-left-0 border-right-0 border-bottom px-1"
                        autofocus
                        @keyup.enter="updateGroup"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="12">
                    <b-form-group
                      label="คำอธิบายกลุ่ม :"
                      label-cols-sm="3"
                      label-cols-lg="2"
                      label-for="group-description"
                    >
                      <b-form-textarea
                        id="group-description"
                        v-model="inputs.description"
                        placeholder="พิมพ์คำอธิบายกลุ่ม"
                        size="md"
                        class="rounded-0 border-0  notes"
                        @keyup.enter="updateGroup"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <div class="mt-3 text-right">
              <b-button
                v-b-modal.add-members
                size="sm"
                variant="outline-success"
              >
                <i class="fa fa-plus"  aria-hidden="true"></i> เพิ่มผู้เรียน
              </b-button>
            </div>

            <b-row class="mt-3">
              <b-col md="7">
                <b-form inline>
                  <label for="inline-form-input-name" class="mr-2"
                    >เลือกผู้เรียนจำนวน {{ allSelectedItems.length }} คน</label
                  >
                  <b-button
                    v-if="allSelectedItems.length > 0"
                    variant="danger"
                    size="sm"
                    @click="confirmRemoveMembers()"
                    >ลบผู้เรียน</b-button
                  >
                </b-form>
              </b-col>
              <b-col md="5">
                <search-box
                  :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
                  @typed="getSearchKeyword"
                ></search-box>
              </b-col>
            </b-row>

            <b-table
              ref="selectableTable"
              :items="items.data"
              :fields="fields"
              :current-page="items.meta.current_page"
              :busy="onLoading"
              hover
              responsive
              class="mt-3"
              @row-clicked="rowClicked"
              :tbody-tr-class="tbodyRowClass"
              primary-key="id"
            >
              <template v-slot:header(selected)="">
                <b-link
                  v-if="isAllItemsSelected"
                  class="text-dark"
                  @click="clearSelected"
                >
                  <b-icon-check2-square scale="1.2" />
                </b-link>
                <b-link v-else class="text-dark" @click="selectAllRows">
                  <b-icon-square />
                </b-link>
              </template>

              <template v-slot:cell(selected)="{ item }">
                <template v-if="isSelectedItem(item)">
                  <b-icon-check2-square scale="1.2" />
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <b-icon-square />
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template
                v-slot:cell(fullname)="{ item: { firstName, lastName } }"
              >
                {{ `${firstName} ${lastName}` }}
              </template>

              <div slot="table-busy">
                <b-spinner class="align-middle text-csq"></b-spinner>
              </div>
            </b-table>

            <Pagination
              v-if="items.data.length"
              :items="items"
              :limits="[10, 50, 100]"
              @changePage="getResults"
              @changeLimit="getResults(1)"
            />
          </div>
          <div class="card-footer mb-2 border-top-0 bg-white">
            <b-button :to="{ name: 'group.index' }" variant="outline-secondary"
              >« ย้อนกลับ</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-modal
        id="add-members"
        ref="modal"
        size="xl"
        :title="`เพิ่มผู้เรียนในกลุ่ม: ${inputs.name}`"
        hide-footer
        hide-header-close
      >
        <members-selector
          :group="group"
          :company-id="company.id"
          @selected="addMembers"
          @canceled="hideModal"
        ></members-selector>
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import SearchBox from '@/components/shared/SearchBox';
import Pagination from '@/components/shared/Pagination';
import MembersSelector from '@/components/shared/MembersSelector';
import Numeral from 'numeral';
import SelectableTableMixin from '@/mixins/selectable-table-mixin.js';

export default {
  components: {
    SearchBox,
    MembersSelector,
    Pagination,
  },
  mixins: [SelectableTableMixin],
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format('0,0');
    },
  },
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      groupId: this.$route.params.id,
      group: {},
      inputs: {
        name: null,
        description: null,
      },
      fields: [
        { key: 'selected', thStyle: { width: '20px' } },
        { label: 'ชื่อผู้เรียน', key: 'fullname' },
        { label: 'ชื่อผู้ใช้', key: 'username' },
        { label: 'อีเมล', key: 'email' },
        { label: 'รหัสพนักงาน', key: 'staffId' },
        { label: 'แผนก', key: 'department' },
      ],
      q: '',
      onLoading: true,
    };
  },
  created() {
    this.getGroupInfo();
    this.getResults();
  },
  methods: {
    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },
    getGroupInfo() {
      api.get(`/v1/member-groups/${this.groupId}`).then(({ data }) => {
        this.group = data.data;
        this.inputs = data.data;
        this.onLoading = false;
      });
    },
    getResults(page = 1) {
      this.onLoading = true;
      api
        .get(
          `/v1/member-groups/${this.groupId}/members?page=${page}&limit=${this.items.meta.per_page}&q=${this.q}&sort_by=fullname&excepts=profiles,groups,roles`
        )
        .then(({ data }) => {
          this.items = data;
          this.onLoading = false;
        });
    },
    updateGroup() {
      api
        .put(`/v1/member-groups/${this.groupId}`, {
          name: this.inputs.name,
          description: this.inputs.description,
        })
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลเรียบร้อยแล้ว', {
            title: 'สำเร็จ',
            variant: 'success',
            solid: true,
          });
          this.isEditing = false;
        });
    },
    addMembers(selectedItems) {
      this.syncMembers(selectedItems);
    },
    confirmRemoveMembers() {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการลบผู้เรียนจำนวน ${this.allSelectedItems.length} คน ออกจากกลุ่ม ${this.group.name} ใช่หรือไม่?`,
          {
            buttonSize: 'sm',
            okTitle: 'ลบผู้เรียน',
            okVariant: 'danger',
            cancelTitle: 'ยกเลิก',
            cancelVariant: 'link',
            title: 'ยืนยันการลบผู้เรียน',
            hideHeaderClose: true,
            bodyClass: 'p-4',
            footerClass: 'p-3 border-0',
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.removeMembers();
          }
        });
    },
    removeMembers() {
      api
        .patch(`/v1/member-groups/${this.groupId}/members`, {
          members: this.allSelectedItemIds,
        })
        .catch(() => {})
        .then(() => {
          this.$bvToast.toast('ลบผู้เรียนออกจากกลุ่มแล้ว', {
            title: 'สำเร็จ',
            variant: 'warning',
            solid: true,
          });

          this.getResults();
          this.allSelectedItems = [];
        });
    },
    syncMembers(selectedItems) {
      api
        .post(`/v1/member-groups/${this.groupId}/members`, {
          members: selectedItems,
        })
        .catch(() => {})
        .then(() => {
          this.$bvToast.toast('เพิ่มผู้เรียนเข้ากลุ่มเรียบร้อยแล้ว', {
            title: 'สำเร็จ',
            variant: 'success',
            solid: true,
          });
          this.getResults();
          this.hideModal();
        });
    },
    hideModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped>
.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 0px, transparent 0px),
    linear-gradient(to left, white 0px, transparent 0px),
    repeating-linear-gradient(
      white,
      white 30px,
      #ced4da 30px,
      #ced4da 31px,
      white 31px
    );
  line-height: 31px;
  padding: 0px 0px;
}
</style>

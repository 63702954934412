<template>
  <div>
    <div class="mb-3">
      <b-row>
        <b-col cols="12" v-if="items.length == 0 && showData == 0">
          <b-alert variant="warning" style="font-size: 0.9rem" show>
            <strong>ไม่มีรายการสั่งซื้อในขณะนี้</strong> รายการสั่งทั้งหมด 0
            รายการ
          </b-alert>
        </b-col>
        <b-col cols="7" class="mb-5" v-if="items.length > 0 || showData == 1">
          <span
            style="font-size:14px;
                 margin-right: 10px;"
          >
            <strong>รายการสั่งซื้อทั้งหมด {{ items.length }} รายการ</strong>
          </span>
          <b-button
            class="inputDate"
            size="sm"
            variant="success"
            style="font-size:12px;"
          >
            ชำระเงินแล้ว {{ getPurchaseSuccess(items) }} รายการ
          </b-button>
        </b-col>
        <b-col
          cols="2"
          style="left: 100px; padding: 5px;"
          v-if="items.length > 0 || showData == 1"
        >
          <strong style="font-size:14px;">แสดงผลตาม</strong>
        </b-col>
        <b-col cols="3" v-if="items.length > 0 || showData == 1">
          <b-dropdown right size="sm" variant="ligth" no-caret>
            <template #button-content>
              <b-input-group>
                <b-form-input
                  size="sm"
                  class="input-dropdown"
                  :value="paidName"
                >
                </b-form-input>
                <b-input-group-append>
                  <i class="icon-dropdown" aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-funnel"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
                      />
                    </svg>
                  </i>
                </b-input-group-append>
              </b-input-group>
            </template>
            <b-dropdown-item @click="getPaidStatus('all')">
              รายการทั้งหมด
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('success')">
              รายการที่ชำระเงินแล้ว
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('failed')">
              รายการที่ยังไม่ชำระเงิน
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('cancel')">
              รายการที่ยกเลิก
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col class="search">
          <b-input-group>
            <b-form-input
              size="sm"
              type="text"
              :value="showRange"
              placeholder="วันที่เริ่มสั่งซื้อ - วันที่สิ้นสุดสั่งซื้อ"
              disabled
            />
            <b-input-group-prepend>
              <b-button
                size="sm"
                variant="primary"
                @click="showCalendarRange()"
              >
                <b-icon icon="calendar-range"></b-icon>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
          <DatePicker
            id="calendar-range"
            style="
              display: none;
              float: right;
              position: absolute;
              left: 15px;
            "
          >
            <template #default>
              <div
                style="padding: 10px 0 5px;
                          background: white;
                          width: 674px;
                          border-style: solid solid none solid;
                          border-color: whitesmoke;
                          border-width: 1px;
                        "
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon
                      icon="calendar"
                      class="input-group-text icon"
                    ></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    class="inputDate"
                    size="sm"
                    style="margin-right: 1rem;"
                    :value="formatStartDate"
                  >
                  </b-form-input>
                  <b-input-group-prepend>
                    <b-icon
                      icon="calendar"
                      class="input-group-text icon"
                    ></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    class="inputDate"
                    size="sm"
                    style="margin-right: 1rem;"
                    :value="formatEndDate"
                  >
                  </b-form-input>
                  <b-button
                    style="margin-right: 0.5rem;"
                    size="sm"
                    variant="primary"
                    @click="applyCalendarRange()"
                  >
                    Apply
                  </b-button>
                  <b-button
                    style="margin-right: 1rem;"
                    size="sm"
                    variant="light"
                    @click="showCalendarRange()"
                  >
                    Cancel
                  </b-button>
                </b-input-group>
              </div>
              <div
                style="background: white; 
                          padding-left: 10px;
                          padding-bottom: 10px;
                          border-style: none solid solid solid;
                          border-color: whitesmoke;
                          border-width: 1px;
                          position: relative;
                          z-index: 9;"
              >
                <DatePicker
                  v-model="range"
                  :columns="$screens({ default: 1, sm: 2 })"
                  is-range
                />
              </div>
            </template>
          </DatePicker>
        </b-col>
        <b-col class="search" v-if="items.length > 0 || showData == 1">
          <search-box title="ค้นหาคอร์ส" @typed="getSearchKeyword"></search-box>
        </b-col>
        <b-col style="max-width: 20%;" v-if="( items.length > 0 || showData == 1 ) && comId == 513">
          <b-form-select
            style="font-size: 14px;"
            v-model="selectedTags"
            :options="courseTags"
            @change="getTagSelect($event)"
          ></b-form-select>
        </b-col>
        <b-col style="max-width: 40%;" v-if="items.length > 0 || showData == 1">
          <b-button
            @click="exportExcel()"
            size="sm"
            variant="info"
            style="float: right;"
          >
            <b-icon icon="chevron-double-down" />
            ดาวน์โหลดรายงาน
            <b-spinner
              id="export-excel-loading"
              style="display: none"
              small
            ></b-spinner>
          </b-button>
          <ag-grid-vue
            style="width: 100%; height: 100%; display: none"
            class="ag-theme-alpine"
            :columnDefs="
              isTutorrusCompany() == true
                ? columnTutorrusDefs
                : isReportFlowAccData
                ? columnFlowAccDefs
                : columnDefs
            "
            @grid-ready="onGridReady"
            :excelStyles="
              comId == 513 && isReportFlowAccData
                ? excelFlowAccStyles
                : excelDefStyles
            "
            :rowData="isReportFlowAccData ? reportFlowAccData : reportData"
          ></ag-grid-vue>
          <b-button
            v-if="comId == 513"
            @click="exportExcelFlowAcc()"
            size="sm"
            variant="outline-info"
            style="float: right; margin-right: 4px;"
          >
            <b-icon icon="chevron-double-down" />
            ดาวน์โหลด FLOWACC
            <b-icon icon="graph-up" />
            <b-spinner
              id="export-excel-flow-acc-loading"
              style="display: none"
              small
            ></b-spinner>
          </b-button>
        </b-col>
        <b-col cols="12" class="mt-5" v-if="items.length == 0">
          <Loading v-if="isLoading && showData == 0" />
        </b-col>
        <b-col cols="12" class="mt-5" v-if="items.length > 0 || showData == 1">
          <b-table
            id="course-order-list"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            style="font-size: 14px;
                 border:hidden;"
            :busy="isLoading"
            hover
            striped
          >
            <template slot="table-busy" class="text-center text-danger my-2">
              <Loading />
            </template>
            <template v-slot:cell(datetime)="data">
              {{ formatDate(data.item.purchase_date) }}<br />
              {{ formatTime(data.item.purchase_date) }}
            </template>
            <template v-slot:cell(course)="data">
              {{ data.item.c_name }}
            </template>
            <template v-slot:cell(courseTag)="data">
              <div v-for="(tag, index) in parsedTags(data.item.tags)" class="courseTag" :key="index">
                {{ tag }}
              </div>
            </template>
            <template v-slot:cell(fullname)="data">
              {{ data.item.mp_firstName }} {{ data.item.mp_lastName }}
            </template>
            <template v-slot:cell(email)="data">
              {{ data.item.m_email }}
            </template>
            <template v-slot:cell(price)="data">
              {{
                comId == 519
                  ? formatPrice(Number(data.item.coi_purchasePrice) / 1.07)
                  : formatPrice(data.item.coi_purchasePrice)
              }}
              บาท
            </template>
            <template v-slot:cell(status)="data">
              <span v-if="data.item.co_cancelFlag == 1" class="text-danger">
                <strong>ยกเลิก</strong>
              </span>
              <span
                v-else-if="data.item.co_paidFlag == 0"
                class="text-secondary"
              >
                <strong>ยังไม่ชำระเงิน</strong>
              </span>
              <span v-else-if="data.item.co_paidFlag == 1">
                <strong class="text-success">ชำระเงินแล้ว</strong>
                <br />
                <strong v-if="data.item.paymentMethod == 'CREDITCARD'">
                  เครดิต
                </strong>
                <strong v-else-if="data.item.paymentMethod == 'CASH'">
                  เงินสด
                </strong>
                <strong v-else>เงินโอน</strong>
              </span>
            </template>
            <template v-slot:cell(action)="data">
              <a
                :href="
                  `/${company.url}/purchase/post?comId=${data.item.com_id}&name=${data.item.learner_name}&address=${data.item.mp_address}&phone=${data.item.mp_mobilePhone}&cName=${data.item.c_name}`
                "
                target="_blank"
                class="btn btn-outline-secondary btn-sm"
                style="font-size: 11px"
              >
                พิมพ์ที่อยู่
              </a>
              <br />
              <b-button
                class="mt-1"
                size="sm"
                variant="outline-primary"
                @click="
                  modalTracking = true;
                  setOrder(data);
                "
                style="font-size: 11px"
              >
                ข้อมูลการจัดส่ง
              </b-button>
            </template>
          </b-table>
          <div
            style="text-align: center"
            v-if="showData == 1 && isLoading == false"
          >
            {{ "ไม่มีข้อมูล" }}
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-3" v-if="items.length > 0 || showData == 1">
      <b-col cols="6">
        <form class="form-inline">
          <div class="form-group mb-2">
            <label>แสดง</label>
          </div>
          <div class="form-group ml-2 mb-2">
            <b-form-select
              v-model="selectedShow"
              size="sm"
              @change="changeShowData"
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </b-form-select>
          </div>
          <div class="form-group ml-2 mb-2">
            <label>
              จาก
              {{ items.length }}
              รายการ
            </label>
          </div>
        </form>
      </b-col>
      <b-col cols="6" v-if="items.length > 0 || showData == 1">
        <nav class="float-right">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="items.length"
            aria-controls="course-order-list"
            last-number
          >
          </b-pagination>
        </nav>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalTracking"
      size="sm"
      :hide-footer="true"
      ref="modal-tracking"
      centered
    >
      <template #modal-title>
        <h6>กรอกข้อมูลการจัดส่งหนังสือ</h6>
      </template>
      <template #default>
        <div class="mt-3 text-font">
          <div>
            <span style="font-size: 14px;">ตัวเลือกการจัดส่ง</span>
          </div>
          <div class="mt-2">
            <b-form-select
              v-model="selected"
              size="sm"
              :options="options"
            ></b-form-select>
          </div>
          <div class="mt-4">
            <span style="font-size: 14px;">หมายเลขพัสดุ (Tracking Number)</span>
          </div>
          <div class="mt-2">
            <b-form-input size="sm" v-model="trackingNumber"> </b-form-input>
          </div>
          <div class="mt-5">
            <b-button
              @click="onSave()"
              size="sm"
              variant="primary"
              style="float: right;"
              :disabled="
                (trackingNumber === null && selected === null) ||
                  (trackingNumber === null && selected !== null) ||
                  (trackingNumber !== null && selected === null)
              "
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              บันทึก
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import Numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import Loading from "@/components/shared/Loading";
import SearchBox from "../../../shared/SearchBox";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CourseOrderList",
  components: {
    DatePicker,
    "ag-grid-vue": AgGridVue,
    Loading,
    SearchBox,
  },
  data() {
    return {
      company: {},
      comId: this.$store.state.Company.companyInfo.id,
      courseName: "",
      paidName: "รายการทั้งหมด",
      paidStatus: "",
      isLoading: false,
      isLoadingButton: false,
      selectedShow: 10,
      perPage: 10,
      currentPage: 1,
      items: [],
      itemsFlowAcc: [],
      trackingNumber: null,
      selected: null,
      orderId: null,
      courseId: null,
      gridApi: null,
      columnApi: null,
      reportData: null,
      reportFlowAccData: null,
      isReportFlowAccData: false,
      modalTracking: false,
      showData: 0,
      selectedTags: "หมวดหมู่คอร์สทั้งหมด",
      courseTag: "",
      range: {
        start: null,
        end: null,
      },
      excelFlowAccStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0",
          },
        },
        {
          id: "currencyFormat",
          numberFormat: {
            format: "#,##0.00 €",
          },
        },
        {
          id: "negativeInBrackets",
          numberFormat: {
            format: "$[blue] #,##0;$ [red](#,##0)",
          },
        },
        {
          id: "booleanType",
          dataType: "Boolean",
        },
        {
          id: "stringType",
          dataType: "String",
        },
        {
          id: "dateType",
          dataType: "DateTime",
        },
        {
          id: "dateISO",
          dataType: "DateTime",
          numberFormat: {
            format: "dd-mm-yyyy",
          },
        },
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "left",
          },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "left",
          },
        },
        {
          id: "decimalFormat",
          numberFormat: {
            format: "#,##0.00",
          },
          alignment: {
            horizontal: "Right",
          },
        },
      ],
      excelDefStyles: [
        {
          id: "stringType",
          dataType: "String",
        },
      ],
      fields: [
        {
          label: "วันที่",
          key: "datetime",
          sortable: true,
        },
        {
          label: "ชื่อคอร์ส",
          key: "course",
        },
        this.$store.state.Company.companyInfo.id == 513 ? 
          { label: 'หมวดหมู่คอร์ส', key: 'courseTag'  } : null,
        {
          label: "ชื่อผู้เรียน",
          key: "fullname",
        },
        {
          label: "อีเมล",
          key: "email",
        },
        {
          label: "ราคา",
          key: "price",
        },
        {
          label: "สถานะ",
          key: "status",
        },
        {
          label: "การจัดส่งหนังสือ",
          key: "action",
        },
      ],
      options: [
        { value: null, text: "กรุณาเลือก" },
        { value: "THAILANDPOST", text: "ไปรษณีย์ไทย (Thailand Post)" },
        { value: "KERRY", text: "Kerry Express" },
        { value: "NINJAVAN", text: "Ninja Van" },
        { value: "DHL", text: "DHL" },
      ],
      columnDefs: [
        { headerName: "วันที่", field: "purchase_date" },
        { headerName: "ชื่อคอร์ส", field: "c_name" },
        { headerName: "ชื่อผู้เรียน", field: "learner_name" },
        { headerName: "อีเมล", field: "m_email" },
        {
          headerName: "ราคา",
          field: "coi_purchasePrice",
          cellClass: "stringType",
        },
        {
          headerName: "เบอร์โทร",
          field: "mp_mobilePhone",
          cellClass: "stringType",
        },
        { headerName: "ที่อยู่", field: "mp_address" },
        { headerName: "ช่องทาง", field: "co_paymentMethod" },
        {
          field: "สถานะ",
          valueGetter: (params) => {
            const cencelFlag = params.data.co_cancelFlag;
            const paidFlag = params.data.co_paidFlag;
            if (cencelFlag == 1) {
              return "ยกเลิก";
            } else if (paidFlag == 1) {
              return "ชำระเงินแล้ว";
            } else {
              return "ยังไม่ชำระเงิน";
            }
          },
        },
        { headerName: "ตัวเลือกการจัดส่ง", field: "co_shippingService" },
      ],
      columnTutorrusDefs: [
        { headerName: "วันที่", field: "purchase_date" },
        { headerName: "ชื่อ-สกุล", field: "learner_name" },
        { headerName: "ชื่อเล่น", field: "mp_displayName" },
        { headerName: "เพศ", field: "mp_gender" },
        {
          headerName: "เบอร์โทร",
          field: "mp_mobilePhone",
          cellClass: "stringType",
        },
        { headerName: "อีเมล", field: "m_email" },
        { headerName: "วันเกิด", field: "mp_dob" },
        { headerName: "ที่อยู่", field: "mp_address" },
        { headerName: "รหัสคอร์ส", field: "c_courseCode" },
        { headerName: "คอร์ส", field: "c_name" },
        {
          headerName: "ราคา",
          field: "coi_purchasePrice",
          cellClass: "stringType",
        },
        {
          field: "สถานะ",
          valueGetter: (params) => {
            const cencelFlag = params.data.co_cancelFlag;
            const paidFlag = params.data.co_paidFlag;
            if (cencelFlag == 1) {
              return "ยกเลิก";
            } else if (paidFlag == 1) {
              return "ชำระเงินแล้ว";
            } else {
              return "ยังไม่ชำระเงิน";
            }
          },
        },
        {
          headerName: "วิธีการชำระเงิน",
          valueGetter: (params) => {
            const paymentMethod = params.data.co_paymentMethod;
            if (paymentMethod == "CASH") {
              return "เงินสด";
            } else if (
              paymentMethod == "TRANSFER" ||
              paymentMethod == "CASH_TRANSFER"
            ) {
              return "เงินโอน";
            } else if (
              paymentMethod == "COUNTERSERVICE" ||
              paymentMethod == "COUNTER_SERVICE"
            ) {
              return "เคาน์เตอร์เซอร์วิส";
            } else if (paymentMethod == "CREDITCARD") {
              return "เครดิต";
            } else if (
              paymentMethod == "PROMOCODE" ||
              paymentMethod == "REDEEM"
            ) {
              return "รหัสคูปอง";
            } else {
              return paymentMethod;
            }
          },
        },
        { headerName: "โอนเข้าธนาคาร", field: "cpa_BTBankName" },
      ],
      columnFlowAccDefs: [
        { headerName: "วันที่เอกสาร", field: "co_purchaseDate" },
        {
          headerName: "ราคาต่อหน่วย",
          field: "coi_price",
          cellClass: "stringType",
        },
        { headerName: "จำนวน", field: "coi_purchaseAmount" },
        {
          headerName: "หน่วย",
          valueGetter: function() {
            return "item(s)";
          },
        },
        {
          headerName: "ส่วนลด(บาท)",
          field: "discount",
          cellClass: "stringType",
        },
        {
          headerName: "รายละเอียด",
          valueGetter: (params) => {
            const courseCode = params.data.c_courseCode;
            const courseName = params.data.c_name;
            if (courseCode == null) {
              return courseName;
            } else {
              return courseCode + " " + courseName;
            }
          },
        },
        { headerName: "ชื่อลูกค้า", field: "billingName" },
        {
          headerName: "เลขผู้เสียภาษี",
          field: "mba_idNo",
          cellClass: "stringType",
        },
        {
          headerName: "ที่อยู่",
          valueGetter: (params) => {
            const officeName = params.data.mba_officeName
              ? "บริษัท " + params.data.mba_officeName + " "
              : "";
            const number = params.data.mba_number
              ? "เลขที่ " + params.data.mba_number + " "
              : "";
            const buildingName = params.data.mba_buildingName
              ? "อาคาร " + params.data.mba_buildingName + " "
              : "";
            const floor = params.data.mba_floor
              ? "ชั้นที่ " + params.data.mba_floor + " "
              : "";
            const village = params.data.mba_village
              ? "หมู่บ้าน " + params.data.mba_village + " "
              : "";
            const moo = params.data.mba_moo
              ? "หมู่ที่ " + params.data.mba_moo + " "
              : "";
            const soi = params.data.mba_soi
              ? "ซอย " + params.data.mba_soi + " "
              : "";
            const street = params.data.mba_street
              ? "ถนน " + params.data.mba_street + " "
              : "";
            const subDistrict =
              params.data.mba_province == "กรุงเทพมหานคร" &&
              params.data.mba_subDistrict
                ? "แขวง " + params.data.mba_subDistrict + " "
                : params.data.mba_subDistrict
                ? "ตำบล " + params.data.mba_subDistrict + " "
                : "";
            const district = 
              params.data.mba_province == "กรุงเทพมหานคร" &&
              params.data.mba_district
                ? "เขต " + params.data.mba_district + " "
                : params.data.mba_district
                ? "อำเภอ " + params.data.mba_district + " "
                : "";
            const province = params.data.mba_province
              ? "จังหวัด " + params.data.mba_province + " "
              : "";
            const postcode = params.data.mba_postcode
              ? params.data.mba_postcode + " "
              : "";
            return (
              officeName +
              number +
              buildingName +
              floor +
              village +
              moo +
              soi +
              street +
              subDistrict +
              district +
              province +
              postcode
            );
          },
          width: 444,
          cellClass: "stringType",
        },
        {
          headerName: "สาขา",
          valueGetter: (params) => {
            if (params.data.mba_branch == "UNDEFINED") {
              return "ไม่ระบุ";
            } else if (params.data.mba_branch == "MAIN_OFFICE") {
              return "สำนักงานใหญ่";
            } else if (params.data.mba_branch == "BRANCH_OFFICE") {
              return params.data.mba_branchId;
            }
          },
        },
        {
          headerName: "พนักงานขาย",
          valueGetter: function() {
            return "99";
          },
        },
        { headerName: "อ้างอิงจากเอกสาร", field: "co_receiptNumber" },
        { headerName: "หมายเหตุ", field: "m_email" },
        { headerName: "โน้ตภายในบริษัท", field: "co_orderNumber" },
      ],
      fetchParams: {
        selectedMonth: "",
        startDate: "",
        endDate: "",
        courseName: "",
        paid: "",
        tag: ""
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    await this.fetchPurchase();
    await this.fetchTags();
    this.setCompanyInfo();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("CourseOrder", [
      "courseOrderList",
      "courseOrderListFlowAcc",
      "courseTags"
    ]),
    formatStartDate() {
      if (this.range.start != null) {
        return moment(this.range.start).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
    formatEndDate() {
      if (this.range.end != null) {
        return moment(this.range.end).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
    showRange() {
      if (this.range.start != null && this.range.end != null) {
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("CourseOrder", [
      "fetchCourseOrderList",
      "fetchCourseOrderListFlowAcc",
      "fetchTags"
    ]),

    isTutorrusCompany() {
      if (localStorage.getItem("ComId") == 408) {
        return true;
      } else {
        return false;
      }
    },
    async fetchPurchase() {
      this.fetchParams.selectedMonth = moment().format("YYYY-MM");
      this.fetchParams.startDate =
        this.range.start == null
          ? ""
          : moment(this.range.start).format("YYYY-MM-DD");
      this.fetchParams.endDate =
        this.range.end == null
          ? ""
          : moment(this.range.end).format("YYYY-MM-DD");
      this.fetchParams.courseName = this.courseName;
      this.fetchParams.paid = this.paidStatus;
      this.fetchParams.tag = this.courseTag;
      await this.fetchCourseOrderList(this.fetchParams);
      this.items = this.courseOrderList;
    },
    async fetchPurchaseFlowAcc() {
      this.fetchParams.selectedMonth = moment().format("YYYY-MM");
      this.fetchParams.startDate =
        this.range.start == null
          ? ""
          : moment(this.range.start).format("YYYY-MM-DD");
      this.fetchParams.endDate =
        this.range.end == null
          ? ""
          : moment(this.range.end).format("YYYY-MM-DD");
      this.fetchParams.courseName = this.courseName;
      this.fetchParams.paid = this.paidStatus;
      this.fetchParams.tag = this.courseTag;
      await this.fetchCourseOrderListFlowAcc(this.fetchParams);
      this.itemsFlowAcc = this.courseOrderListFlowAcc;
    },
    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    setOrder(data) {
      this.orderId = data.item.co_orderId;
      this.courseId = data.item.c_id;
      this.selected = data.item.co_shippingService;
      this.trackingNumber = data.item.co_trackingId;
    },
    setPaidName(status) {
      if (status == "all") {
        this.paidName = "รายการทั้งหมด";
      } else if (status == "success") {
        this.paidName = "รายการที่ชำระเงินแล้ว";
      } else if (status == "failed") {
        this.paidName = "รายการที่ยังไม่ชำระเงิน";
      } else if (status == "cancel") {
        this.paidName = "รายการที่ยกเลิก";
      }
    },
    formatDate(datetime) {
      return moment(datetime).format("YYYY-MM-DD");
    },
    formatTime(datetime) {
      return moment(datetime).format("HH:mm:ss");
    },
    formatPrice(price) {
      return Numeral(price).format("0,0.00");
    },
    getPurchaseSuccess(item) {
      let paymentSuccess = 0;
      for (let i = 0; i < item.length; i++) {
        if (item[i].co_paidFlag == 1 && item[i].co_cancelFlag != 1) {
          paymentSuccess = paymentSuccess + 1;
        }
      }
      return paymentSuccess;
    },
    async getSearchKeyword(value) {
      this.isLoading = true;
      this.courseName = value;
      await this.fetchPurchase();
      if (this.items.length > 0) {
        this.showData = 0;
      } else {
        this.showData = 1;
      }
      this.isLoading = false;
    },
    async getTagSelect(value) {
      this.isLoading = true;
      let courseTagValue = (value === "หมวดหมู่คอร์สทั้งหมด") ? null : value;
      this.courseTag = courseTagValue;
      await this.fetchPurchase();
      if (this.items.length > 0) {
        this.showData = 0;
      } else {
        this.showData = 1;
      }
      this.isLoading = false;
    },
    async getPaidStatus(status) {
      this.isLoading = true;
      this.paidStatus = status;
      await this.fetchPurchase();
      if (this.items.length > 0) {
        this.showData = 0;
        this.setPaidName(this.paidStatus);
      } else {
        this.showData = 1;
        this.setPaidName(this.paidStatus);
      }
      this.isLoading = false;
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
    async showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        this.isLoading = true;
        rangeCalendar.style.display = "none";
        this.range.start = null;
        this.range.end = null;
        this.showData = 1;
        await this.fetchPurchase();
        if (this.items.length > 0) {
          this.showData = 0;
        }
        this.isLoading = false;
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    async applyCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (this.range.start != null && this.range.end != null) {
        this.isLoading = true;
        rangeCalendar.style.display = "none";
        this.showData = 1;
        await this.fetchPurchase();
        if (this.items.length > 0) {
          this.showData = 0;
        }
        this.isLoading = false;
      } else {
        rangeCalendar.style.display = "none";
      }
    },
    async onSave() {
      if (this.trackingNumber != null && this.selected != null) {
        this.isLoading = true;
        let updateTracking = await api.put(`/v1/update-tracking-detail`, {
          orderId: this.orderId,
          shippingService: this.selected,
          trackingID: this.trackingNumber,
          courseId: this.courseId,
        });
        if (updateTracking) {
          this.orderId = null;
          this.selected = null;
          this.trackingNumber = null;
          this.courseId = null;
          await this.fetchPurchase();
          this.$bvToast.toast(`อัพเดทข้อมูลสำเร็จ`, {
            title: `สำเร็จ`,
            variant: "success",
            solid: true,
          });
          this.hideModalTracking();
        } else {
          this.$bvToast.toast(`อัพเดทข้อมูลไม่สำเร็จ`, {
            title: `ข้อมูลผิดพลาด`,
            variant: "danger",
            solid: true,
          });
        }
        this.isLoading = false;
      }
    },
    async exportExcel() {
      document.getElementById("export-excel-loading").style.display =
        "inline-block";
      await this.fetchPurchase();
      this.reportData = this.items;
      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "course-order-list",
          fileName: `CourseOrderList`,
        });
        document.getElementById("export-excel-loading").style.display = "none";
      }, 1000);
    },
    async exportExcelFlowAcc() {
      this.isReportFlowAccData = true;
      const currentDate = new Date()
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "");
      const fileName = `FLOWACC_${currentDate}`;
      document.getElementById("export-excel-flow-acc-loading").style.display =
        "inline-block";
      await this.fetchPurchaseFlowAcc();

      this.reportFlowAccData = this.itemsFlowAcc;
            setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "course-order-list-flow-acc",
          fileName: fileName,
        });
        document.getElementById("export-excel-flow-acc-loading").style.display =
          "none";
        this.isReportFlowAccData = false;
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    hideModalTracking() {
      this.$refs["modal-tracking"].hide();
    },
    parsedTags(courseTags) {
      return courseTags.replace(/{|}/g, '').split(',').map(tag => tag.trim());
    }
  },
};
</script>

<style>
.input-group-text.icon {
  width: 40px;
  height: auto;
  border-right: none !important;
  background-color: #ffffff !important;
  margin-left: 10px;
}
.inputDate {
  border-left: none !important;
  position: relative !important;
}
.text-font {
  font-family: "Prompt", sans-serif;
}
.icon-dropdown {
  width: 40px;
  border: 1px solid #ced4da !important;
  border-left: none !important;
  padding-top: 4px;
}
.input-dropdown {
  border-right: none !important;
  position: relative;
}
.courseTag {
  display: inline-block;
  background-color: #FFA84D;
  color: white;
  padding: 2px 4px;
  margin: 3px;
  border-radius: 4px;
  text-align: center;
}
.search{
  max-width: 22% !important;
  padding-left: 15px !important;
  padding-right: 0px !important;
}
</style>

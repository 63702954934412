<template>
  <div>
    <PageHeader title="จัดการหมวดหมู่คอร์ส"></PageHeader>
    <b-row class="justify-content-center m-5">
      <b-col cols="12"
        ><TagList
          :apiRoute="`/v1/tags/get-available-tags`"
          :apiRoute2="`/v1/tags/get-company-course-tags`"
        ></TagList
      ></b-col>
    </b-row>
  </div>
</template>
<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import TagList from './panel/TagListPanel';
export default {
  components: {
    PageHeader,
    TagList,
  },
  async mounted() {},
};
</script>

<style scoped></style>

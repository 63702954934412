<template>
  <b-container fluid="xl" class="pt-5">
    <div>
      <b-form @submit.stop.prevent>
        <b-form-group label="ชื่อกลุ่มคอร์ส" label-for="title">
          <b-form-input
            id="title"
            v-model="courseGroupName"
            required
            placeholder="ชื่อกลุ่มคอร์สใหม่"
            aria-describedby="input-courseGroupName-live-feedback"
            :state="validated.courseGroupName"
            @keyup.enter="update('courseGroupName')"
          />
          <b-form-invalid-feedback id="input-courseGroupName-live-feedback">
            กรุณาใส่ชื่อกลุ่มคอร์ส 3 ตัวอักษรขึ้นไป
            และไม่ซ้ำกับชื่อกลุ่มคอร์สอื่น
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="คำอธิบายสั้น ๆ" label-for="description">
          <b-form-input
            id="description"
            v-model="courseGroupShortDescription"
            required
            placeholder="คำอธิบายกลุ่ม"
            aria-describedby="input-courseGroupShortDescription-live-feedback"
            :state="validated.courseGroupShortDescription"
            @keyup.enter="update('courseGroupShortDescription')"
          />
          <b-form-invalid-feedback
            id="input-courseGroupShortDescription-live-feedback"
          >
            ไม่เกิน 250 ตัวอักษร
            <span class="float-right">
              {{
                form.courseGroupShortDescription
                  ? form.courseGroupShortDescription.length
                  : 0
              }}
              ตัวอักษร
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CourseGroupInfoForm",
  data() {
    return {
      form: {
        courseGroupName: "",
        courseGroupShortDescription: "",
      },
      validated: {
        courseGroupName: null,
        courseGroupShortDescription: null,
      },
      isUpdated: null,
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters("CourseGroupManagement", [
      "courseGroupInfo",
      "courseGroupCourses",
    ]),
    courseGroupName: {
      get() {
        return this.courseGroupInfo.courseGroupName;
      },
      set(value) {
        if (value && value.length >= 3) {
          this.form.courseGroupName = value;
          this.validated.courseGroupName = null;
        } else {
          this.validated.courseGroupName = false;
        }
      },
    },
    courseGroupShortDescription: {
      get() {
        return this.courseGroupInfo.courseGroupShortDescription;
      },
      set(value) {
        if (value && value.length <= 250) {
          this.form.courseGroupShortDescription = value;
          this.validated.courseGroupShortDescription = null;
        } else {
          this.validated.courseGroupShortDescription = false;
        }
      },
    },
  },
  methods: {
    ...mapActions("CourseGroupManagement", [
      "getCourseGroupInfo",
      "updateCourseGroupInfo",
    ]),
    async update(attr) {
      if (this.validated[attr] !== false) {
        await this.updateCourseGroupInfo({
          courseGroupId: this.courseGroupInfo.courseGroupId,
          courseGroupName:
            this.form.courseGroupName || this.courseGroupInfo.courseGroupName,
          courseGroupShortDescription:
            this.form.courseGroupShortDescription ||
            this.courseGroupInfo.courseGroupShortDescription,
        })
          .then(() => {
            this.validated[attr] = true;
          })
          .catch(() => {
            this.errorMessage = "ข้อมูลไม่ถูกต้อง";
            this.validated[attr] = false;
          });
      }
    },
  },
};
</script>

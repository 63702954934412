<template>
  <div>
    <h4 class="mt-5">
      ราคาคอร์สและระยะเวลาเรียน
      <b-button variant="primary" size="sm" @click="toggleCoursePriceModal()">
        จัดการ
      </b-button>
    </h4>
    <PricingForm :readonly="true" />
    <b-modal
      id="coursePriceModal"
      v-model="showCoursePriceModal"
      size="lg"
      centered
      title="ราคาคอร์สและระยะเวลาเรียน"
      hide-footer
    >
      <PricingForm @toggle-modal="toggleCoursePriceModal()" />
    </b-modal>
  </div>
</template>

<script>
import PricingForm from "../forms/PricingForm.vue";
import { mapActions } from "vuex";

export default {
  components: { PricingForm },
  data() {
    return {
      showCoursePriceModal: false,
      courseId: this.$route.params.courseId,
    };
  },
  methods: {
    ...mapActions("CourseManagement", ["getCourseInfo"]),
    toggleCoursePriceModal() {
      this.showCoursePriceModal = !this.showCoursePriceModal;
      this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });
    },
  },
};
</script>

<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="อันดับนักเรียน"></PageHeader>
      <b-row>
        <b-col cols="12">
          <studentRanking
            :apiRoute="
              `/v1/analytics/courses/getCourseStudentRanking?cId=${cId}&template=A`
            "
            :perPage="20"
          ></studentRanking>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import studentRanking from "./panel/IndividualCourseStudentRankingPanel";
import moment from "moment";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      cId: this.$route.params.cId
    };
  },
  components: {
    PageHeader,
    studentRanking
  }
};
</script>
<style scoped></style>

<template>
  <div class="outer">
    <div class="inner bg-container">
      <b-container fluid>
        <b-row class="top-content" justify-center mb-5>
          <b-col>
            <ExamMeta />
          </b-col>
          <b-col>
            <ExamParticipation />
          </b-col>
        </b-row>
        <b-row class="bottom-content">
          <b-col>
            <ExamAnswerSheets />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ExamParticipation from "@/components/pages/exam-analytics/exams-overall/exam-participation/ExamParticipation.vue";
import ExamMeta from "@/components/pages/exam-analytics/exams-overall/exam-meta/ExamMeta";
import ExamAnswerSheets from "@/components/pages/exam-analytics/exams-overall/exam-answersheets/ExamAnswerSheets";
export default {
  components: {
    ExamParticipation,
    ExamMeta,
    ExamAnswerSheets
  }
};
</script>

<template>
  <div>
    <div v-if="perPage > 10" class="mb-3">
      <b-form-input
        v-model="search"
        placeholder="ค้นหาคอร์สเรียน"
        style="padding: 1rem; display: inline-block; width: 20%"
      >
      </b-form-input>
    </div>
    <b-table
      striped
      id="revenue-course-table"
      :per-page="perPage"
      :current-page="currentPage"
      table-class="tbNumber text-left "
      :items="
        search == null
          ? result
          : result.filter((i) => i.c_name.includes(search))
      "
      :fields="fields"
      small
      sort-icon-left
      responsive
      :busy="isBusy"
      hover
    >
      <template v-slot:cell(name)="data"
        ><span>{{ data.item.c_name }}</span></template
      >
      <template v-slot:cell(amount)="data"
        ><span>{{ data.item.count }}</span></template
      >
      <template v-slot:cell(price)="data"
        ><span>{{ data.item.pricePerHour | thaiBaht }}</span></template
      >
      <template v-slot:cell(revenue)="data"
        ><span>{{ data.item.sum | thaiBaht }}</span></template
      >
    </b-table>
    <div style="text-align: center" v-if="result.length == 0">
      {{ 'ไม่มีข้อมูล' }}
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="
        search == null
          ? rows
          : result.filter((i) => i.c_name.includes(search)).length
      "
      :per-page="perPage"
      aria-controls="revenue-course-table"
      v-if="perPage > 10"
    ></b-pagination>
  </div>
</template>

<script>
import api from '@/services/api.js';
export default {
  components: {},
  props: ['apiRoute', 'perPage'],
  data() {
    return {
      result: [],
      currentPage: 1,
      rows: 0,
      isBusy: true,
      search: null,
      fields: [
        { key: 'name', label: 'คอร์สเรียน' },
        {
          key: 'amount',
          label: 'จำนวนคนซื้อคอร์ส',
          class: 'text-right',
        },
        {
          key: 'price',
          label: 'ราคาคอร์สเฉลี่ยต่อชั่วโมง',
          class: 'text-right',
        },
        {
          key: 'revenue',
          label: 'รายได้จากคอร์ส',
          class: 'text-right',
        },
      ],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>
<style scoped></style>

<template>
  <div class>
    <PageHeader title="สรุปภาพรวมคำถาม"></PageHeader>
    <b-container fluid="xl" class="pt-5">
      <layout>
        <template v-slot:header>
          <b-row>
            <b-col cols="12"> </b-col>
            <b-col cols="12">
              <h6>รายชื่อคอร์สทั้งหมดในระบบ</h6>
            </b-col>
          </b-row>
        </template>
        <template v-slot:body>
          <div class style="padding:1rem;">
            <div>
              <b-form-input
                v-model="search"
                placeholder="พิมพ์เพื่อค้นหาคอร์สเรียน"
                style="padding:1rem;display:inline-block;width:20%"
              ></b-form-input>
            </div>
            <b-table
              striped
              style="padding-top:2rem"
              id="discuss-table"
              :per-page="perPage"
              :current-page="currentPage"
              table-class="tbNumber text-left "
              :items="
                search == null
                  ? result
                  : result.filter((i) => i.c_name.includes(search))
              "
              :fields="fields"
              small
              sort-icon-left
              responsive
              :busy="isBusy"
              hover
            >
              <template v-slot:cell(cName)="data">
                <span>{{ data.item.c_name }}</span>
              </template>
              <template v-slot:cell(notAnswered)="data"
                ><span>{{ data.item.ans }}</span></template
              >
              <template v-slot:cell(totalQuestion)="data"
                ><span>{{ data.item.total }}</span></template
              >
              <template v-slot:cell(btn)="data">
                <a
                  v-if="data.item.total > 0"
                  :href="`/${company.url}/analytics/discuss/${data.item.c_id}`"
                  target="_blank"
                  ><b-button size="sm" variant="outline-primary">
                    <span>ดูคำถามทั้งหมด</span>
                  </b-button>
                </a>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="
                search == null
                  ? result.length
                  : result.filter((i) => i.c_name.includes(search)).length
              "
              :per-page="perPage"
              aria-controls="discuss-table"
            ></b-pagination>
          </div>
        </template>
      </layout>
    </b-container>
  </div>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
export default {
  components: {
    layout,
    PageHeader,
  },
  data() {
    return {
      search: "",
      perPage: 15,
      currentPage: 1,
      result: [],
      isBusy: true,
      fields: [
        { key: "cName", label: "ชื่อคอร์สเรียน" },
        {
          key: "notAnswered",
          label: "จำนวนคำถามที่ยังไม่ได้ตอบ",
          class: "text-right",
        },
        {
          key: "totalQuestion",
          label: "จำนวนคำถามทั้งหมด",
          class: "text-right",
        },
        {
          key: "btn",

          label: "",
        },
      ],
      items: [],
      company: {},
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    let res = await api.get(`/v1/analytics/discuss/getDiscussOverview`);
    this.result = res.data.sort((a, b) => b.ans - a.ans || b.total - a.total);
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped></style>

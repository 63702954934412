<template>
  <layout>
    <template v-slot:header>
      <b-row>
        <b-col cols="12" class="text-right">
          <div>
            <b-button
              @click="exportReport()"
              id="export-excel"
              class="btn btn-download"
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
            <b-spinner
              id="export-excel-loading"
              class="align-middle ml-3"
              style="display: none"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-table
        striped
        hover
        :fields="fields"
        :items="individualStudentInfoReport"
        class="mt-3"
      >
        <template #cell(lec_name)="data" v-if="comId != 519">
          {{
            data.index + 1 == individualStudentInfoReport.length
              ? "รวม"
              : data.item.lec_name
          }}
        </template>
        <template #cell(ผลทดสอบ)="data" v-if="comId == 519">
          <span>
            {{ data.item.lel_examResultScore ? parseInt(data.item.lel_examResultScore) + "/" + parseInt(data.item.lel_examResultFullScore) : "-" }}
          </span>
        </template>

        <template #cell(status)="data">
          <span
            :class="{
              'text-green': data.item.status === 'ผ่าน',
              'text-red': data.item.status === 'ไม่ผ่าน',
            }"
          >
            {{ data.item.status }}
          </span>
        </template>
      </b-table>
      <b-table
        striped
        hover
        v-if="comId == 519"
        :fields="summaryFields"
        :items="individualStudentInfoReportSummary"
        class="mt-3"
        style="width: 100%"
      >
        <template #cell(summary)="data">
          <span>
            {{ data.item.summary }}
          </span>
        </template>
        <template #cell(cer_startDate)="data">
          <span>
            {{ data.item.cer_startDate }}
          </span>
        </template>
        <template #cell(cer_completeDate)="data">
          <span>
            {{ data.item.cer_completeDate ? data.item.cer_completeDate.split(' ')[0] : "-" }}
          </span>
        </template>
        <template #cell(c_duration)="data">
          <span>
            {{ data.item.c_duration ? formatDuration(data.item.c_duration) : "-" }}
          </span>
        </template>
        <template #cell(max_examResultPercent)="data">
          <span>
            {{ data.item.max_examResultPercent ? data.item.max_examResultPercent + "%" : "-" }}
          </span>
        </template>
        <template #cell(cer_status)="data">
          <span
            :class="{
              'text-green': data.item.cer_status === 'COMPLETED',
              'text-red': data.item.cer_status != 'COMPLETED',
            }"
          >
            {{ data.item.cer_status === 'COMPLETED' ? "ผ่าน" : "ไม่ผ่าน" }}
          </span>
        </template>
      </b-table>
      <div style="width: 100%">
        <ag-grid-vue
          style="width: 98%; height: 500px; display: none"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady($event, 1)"
          :rowData="individualStudentInfo"
          :excelStyles="excelStyles"
        ></ag-grid-vue>
        <ag-grid-vue
          style="width: 98%; height: 500px; display: none"
          class="ag-theme-alpine"
          :columnDefs="columnDefs2"
          @grid-ready="onGridReady($event, 2)"
          :rowData="individualStudentInfoReport"
          :excelStyles="excelStyles"
        ></ag-grid-vue>
      </div>
    </template>
  </layout>
</template>


<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import { exportMultipleSheetsAsExcel } from "ag-grid-enterprise";
/* import api from "@/services/api.js"; */

export default {
  components: {
    layout,
    "ag-grid-vue": AgGridVue
  },

  mounted() {
    this.getIndividualStudentInfoReport();
    if (this.comId == 519) {
      this.getIndividualStudentInfoReportSummary();
    }
    this.individualStudentInfo.push(this.individualStudentInfoData);
  },

  props: {
    individualStudentInfoData: { default: null, type: Object }
  },

  data() {
    return {
      individualStudentInfo: [],
      fields: [
        { key: "lec_name", label: "หัวข้อบทเรียน" },
        {
          key: "วันที่เริ่มเรียน",
          label: "วันที่เริ่มเรียน"
        },
        {
          key: "วันที่เรียนจบ",
          label: "วันที่เรียนจบ"
        },
        {
          key: "จำนวนชั่วโมง",
          label: "จำนวนชั่วโมง"
        },
        {
          key: "จำนวนนาที",
          label: "จำนวนนาที"
        },
        {
          key: "วันที่สอบผ่าน",
          label: "วันที่สอบผ่าน"
        },
        {
          key: "ผลทดสอบ",
          label: "ผลทดสอบ"
        },
        {
          key: "status",
          label: "สถานะ"
        }
      ],
      summaryFields: [
        {
          key: "summary",
          label: "สรุปผลการอบรม",
          tdClass: "td-summary"
        },
        {
          key: "cer_startDate",
          label: "วันที่เริ่มเรียน",
          tdClass: "td-startDate"
        },
        {
          key: "cer_completeDate",
          label: "วันที่เรียนจบ",
          tdClass: "td-completeDate"
        },
        {
          key: "date_diff",
          label: "จำนวนวัน",
          tdClass: "td-date-diff"
        },
        {
          key: "c_duration",
          label: "ระยะเวลา"
        },
        {
          key: "max_examResultPercent",
          label: "ผลทดสอบ",
          tdClass: "td-exam"
        },
        {
          key: "cer_status",
          label: "สถานะ",
          tdClass: "td-status"
        }
      ],

      gridApi: null,
      gridColumnApi: null,
      gridApi2: null,
      gridColumnApi2: null,
      cerId: this.$route.query.cerId,
      comId: localStorage.getItem("ComId"),
      individualStudentInfoReport: [],
      individualStudentInfoReportSummary: [],
      excelStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0"
          }
        },
        {
          id: "currencyFormat",
          numberFormat: {
            format: "#,##0.00 €"
          }
        },
        {
          id: "negativeInBrackets",
          numberFormat: {
            format: "$[blue] #,##0;$ [red](#,##0)"
          }
        },
        {
          id: "booleanType",
          dataType: "Boolean"
        },
        {
          id: "stringType",
          dataType: "String"
        },
        {
          id: "dateType",
          dataType: "DateTime"
        },
        {
          id: "dateISO",
          dataType: "DateTime",
          numberFormat: {
            format: "dd-mm-yyyy"
          }
        },
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center"
          }
        },
        {
          id: "decimalFormat",
          numberFormat: {
            format: "#,##0.00"
          },
          alignment: {
            horizontal: "Right"
          }
        }
      ],
      columnDefs: [
        {
          headerName: "ข้อมูลผู้เรียนรายบุคคล",
          children: [
            { headerName: "ชื่อ-สกุล", field: "mp_name" },
            {
              headerName: "เลขผู้สอบบัญชี",
              field: "เลขทะเบียนผู้สอบบัญชี",
              cellClass: "stringType"
            },
            {
              headerName: "เลขผู้ทำบัญชี",
              field: "ผู้ทำบัญชี",
              cellClass: "stringType"
            },
            { headerName: "รหัสหลักสูตร", field: "c_courseCode" },
            { headerName: "ชื่อหลักสูตร", field: "c_name" }
          ]
        }
      ],
      columnDefs2: [
        {
          headerName: "ข้อมูลการเข้าเรียน",
          children: [
            { field: "ลำดับ", valueGetter: hashValueGetter, maxWidth: 90 },
            { headerName: "หัวข้อบทเรียน", field: "lec_name", maxWidth: 200 },
            {
              headerName: "วันที่เริ่มเรียน",
              field: "วันที่เริ่มเรียน",
              maxWidth: 130
            },
            {
              headerName: "วันที่เรียนจบ",
              field: "วันที่เรียนจบ",
              maxWidth: 130
            },
            {
              headerName: "จำนวนชั่วโมง",
              field: "จำนวนชั่วโมง",
              maxWidth: 135
            },
            { headerName: "จำนวนนาที", field: "จำนวนนาที", maxWidth: 120 },
            {
              headerName: "วันที่สอบผ่าน",
              field: "วันที่สอบผ่าน",
              maxWidth: 130
            },
            { headerName: "ผลทดสอบ", field: "ผลคะแนนทดสอบ", maxWidth: 120 },
            {
              headerName: "สถานะ",
              field: "status",
              maxWidth: 120,
              cellStyle: params => {
                if (params.value === "ไม่ผ่าน") {
                  return { color: "red" };
                } else if (params.value === "ผ่าน") {
                  return { color: "green" };
                }
                return { color: "black" };
              }
            }
          ]
        }
      ]
    };
  },

  methods: {
    async getIndividualStudentInfoReport() {
      await api
        .get(`v1/tfac/get-individual-student-info-report?cer_id=${this.cerId}`)
        .then(({ data }) => {
          this.individualStudentInfoReport = data;
          if (this.comId == 519) {
            this.individualStudentInfoReport.pop();
          }
        });
    },
    async getIndividualStudentInfoReportSummary() {
      await api
        .get(`v1/tfac/get-individual-student-info-report-summary?cer_id=${this.cerId}`)
        .then(({ data }) => {
          this.individualStudentInfoReportSummary = data;
        });
    },
    async onGridReady(params, page) {
      if (page === 1) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
      }
      if (page === 2) {
        this.gridApi2 = params.api;
        this.gridColumnApi2 = params.columnApi;
      }
    },
    async exportReport() {
      let spinner = "export-excel-loading";
      let exportBtn = "export-excel";
      document.getElementById(spinner).style.display = "inline-block";
      document.getElementById(exportBtn).disabled = true;
      setTimeout(() => {
        const spreadsheets = [];
        spreadsheets.push(
          this.gridApi.getSheetDataForExcel({
            sheetName: "ข้อมูลผู้เรียนรายบุคคล"
          }),
          this.gridApi2.getSheetDataForExcel({
            sheetName: "ข้อมูลการเข้าเรียน"
          })
        );
        exportMultipleSheetsAsExcel({
          data: spreadsheets,
          fileName: "รายงานข้อมูลผู้เรียนรายบุคคล.xlsx"
        });
        document.getElementById(spinner).style.display = "none";
        document.getElementById(exportBtn).disabled = false;
        document.getElementById(exportBtn).classList.remove("disabled");
      }, 1000);
    },
    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      return formattedDuration;
    }
  }
};
let hashValueGetter = function(params) {
  return !params.node.lastChild ? params.node.rowIndex + 1 : "รวม";
};
</script>


<style>
.text-red {
  color: red;
}

.text-green {
  color: green;
}

.td-summary {
  width: 27.78%;
}
.td-startDate {
  width: 13.05%;
}
.td-completeDate {
  width: 13.92%;
}
.td-date-diff {
  width: 11.77%;
}
.td-exam {
  width: 11.72%;
}
.td-status {
  width: 8.16%;
}
</style>

<template>
  <div style="margin:0 1rem 0 1rem">
    <layout>
      <template v-slot:header>
        <PageHeader title="สรุปรายได้"></PageHeader
      ></template>
      <template v-slot:body>
        <revenueSummary :perPage="20" />
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/pages/analytics/shared/Layout.vue';

import revenueSummary from './panel/RevenueSummaryPanel';
import PageHeader from '@/components/layouts/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,

    revenueSummary,
  },
};
</script>

<template>
  <div class="box-information">
    <h5>ใบหน้าผู้เรียน</h5>
    <b-row class="mt-3">
      <!-- Member information -->
      <b-col cols="6">
        <div class="d-flex">
          <div class="img mr-4">
            <b-img
              :src="
                memberInfo
                  ? memberInfo.image_path
                    ? `${baseUrl}/${memberInfo.image_path.slice(
                        1,
                        -1
                      )}?token=${token}`
                    : baseUrlImage
                  : ''
              "
              class="profile-image-style"
              rounded="circle"
            ></b-img>
          </div>
          <div
            class="box-content d-flex flex-column justify-content-between ml-5"
          >
            <div class="box-name">
              <h6>ชื่อผู้เรียน</h6>
              <p>{{ memberInfo ? memberInfo.mp_name : "-" }}</p>
            </div>
            <div class="box-cer-status">
              <h6>สถานะเรียนจบ</h6>
              <p>
                {{
                  memberInfo
                    ? memberInfo.cer_status == "COMPLETED"
                      ? "เรียนจบแล้ว"
                      : memberInfo.cer_status == "IN_PROGRESS"
                      ? "กำลังเรียน"
                      : memberInfo.cer_status == "NOT_STARTED"
                      ? "ยังไม่เริ่มเรียน"
                      : "-"
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="d-flex flex-column justify-content-between">
        <div class="box-name">
          <h6>สถานะการยืนยันผลการตรวจสอบใบหน้า</h6>
          <p
            :class="[
              memberInfo
                ? memberInfo.cer_approveFaceStatus == 'NOT_APPROVED'
                  ? 'text-danger'
                  : memberInfo.cer_approveFaceStatus == 'APPROVING'
                  ? 'text-warning'
                  : memberInfo.cer_approveFaceStatus == 'APPROVED'
                  ? 'text-success'
                  : '-'
                : '-',
            ]"
          >
            {{
              memberInfo
                ? memberInfo.cer_approveFaceStatus == "NOT_APPROVED"
                  ? "ยังไม่ยืนยัน"
                  : memberInfo.cer_approveFaceStatus == "APPROVING"
                  ? "กำลังยืนยัน"
                  : memberInfo.cer_approveFaceStatus == "APPROVED"
                  ? "ยืนยันแล้ว"
                  : "-"
                : "-"
            }}
          </p>
        </div>
        <div class="box-cer-status">
          <h6>ผลยืนยันความถูกต้อง</h6>
          <p>
            {{
              memberInfo
                ? memberInfo.cer_approveFacePercent
                  ? memberInfo.cer_approveFacePercent.toFixed(2) + "%"
                  : "-"
                : "-"
            }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      token: localStorage.getItem("token"),
      cerId: this.$route.params.cerId,
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com/",
      baseUrlImage: "https://s1.coursesquare.co/data/images/display.jpg",
    };
  },
  computed: { ...mapGetters("MemberManagement", ["memberInfo"]) },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchMemberInfo"]),
    async fetch() {
      await this.fetchMemberInfo(this.cerId);
    },
  },
};
</script>

<style scoped>
.profile-image-style {
  width: 10rem;
  height: 10rem;
}

.test-red-border {
  border: 2px solid red;
  padding: 5px;
}

.box-information h6 {
  font-weight: 600;
}
p {
  font-family: "Prompt", sans-serif;
}
</style>

<template>
  <b-card :class="$options.name">
    <header slot="header" :class="[`${$options.name}__head`]">
      <div class="main-bar">
        <div class="row">
          <div class="col-lg-5">
            <h5 class="nav_top_align">
              <i class="fas fa-file-alt"  aria-hidden="true" /> อัปโหลดสำเร็จ
            </h5>
          </div>
        </div>
      </div>
    </header>
    <b-container fluid :class="[`${$options.name}__content`]" class="px-3">
      <b-row>
        <b-col class="px-4">
          <b-container fluid class="py-2">
            <b-row>
              <b-col class="text-left px-0">
                <h5>ข้อสอบ {{ examName }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-table
                stacked
                :items="examInfo"
                :fields="fields"
                :class="[`${$options.name}__content__table`]"
              ></b-table>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row>
      <!--   <b-col>
          <b-button @click="toExamManagement">จัดการคอร์ส</b-button>
        </b-col> -->
        <b-col>
          <b-button @click="toExamManagement">จัดการข้อสอบ</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>


<script>
import { mapGetters } from "vuex";
import reduce from "lodash/reduce";

export default {
  name: "ExamManagementSuccess",
  data() {
    return {
      fields: [
        {
          key: "examName",
          label: "ชื่อข้อสอบ",
          sortable: false,
        },
        {
          key: "examCategory",
          label: "หมวดข้อสอบ",
          sortable: false,
        },
        {
          key: "examQuestionsCount",
          label: "จำนวนคำถาม",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyCoursesData",
      "companyCoursesPending",
      "companyModulesData",
      "companyModulesPending",
      "companyInfo",
    ]),
    ...mapGetters("ExamManagement", ["companyUploadedCsvData"]),
    examName() {
      return this.companyUploadedCsvData.exam.title;
    },
    examCategory() {
      return this.companyUploadedCsvData.exam.category;
    },
    examInfo() {
      return [
        {
          examName: this.examName,
          examCategory: this.examCategory,
          examQuestionsCount: reduce(
            this.companyUploadedCsvData.exam.sections,
            (count, ech) => {
              return count + ech.questions.length;
            },
            0
          ),
        },
      ];
    },
  },
  methods: {
    toExamManagement() {
      this.$router.push({
        name: "exam.build",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_variables.scss";
.ExamManagementSuccess {
  &__content {
    padding: 1rem 0;
  }
  .card-header {
    padding: 0 auto;
  }
}
</style>

<style lang="scss">
.ExamManagementSuccess {
  &__content {
    padding: 1rem 0;
    &__table {
      tbody {
        tr {
          td {
            &::before {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <layout>
      <template v-slot:header>
        <h6>จำนวนชั่วโมงเรียนรวมในแต่ละช่วงเวลา</h6>
      </template>
      <template v-slot:body>
        <!-- <Area-chart :chart-data="data" :options="options"></Area-chart> -->
        <line-chart
          :chartData="data"
          :chartOption="options"
          :chartHeight="150"
        ></line-chart>
      </template>
    </layout>
  </div>
</template>

<script>
// import AreaChart from '@/components/pages/analytics/shared/AreaChartjs'
import LineChart from "@/components/pages/analytics/shared/LineChart";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
export default {
  components: {
    LineChart,
    // AreaChart,
    layout,
  },
  props: ["apiRoute"],
  data() {
    return {
      result: {},
      data: {},
      options: {
        elements: {
          point: { radius: 0 },
          line: { borderWidth: 0.8 },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
        }, // Add to prevent default behaviour of full-width/height
      },
    };
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.data = {
      labels: Object.keys(this.result.MON),
      datasets: [
        {
          // Name the series
          data: Object.values(this.result.MON).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันจันทร์",
          // Add custom color border (Line)
          borderColor: "#F6C72C",
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.TUE).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันอังคาร",
          // Add custom color border (Line)
          borderColor: "#E76275", // Add custom color background (Points and Fill)
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.WED).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันพุธ",
          // Add custom color border (Line)
          borderColor: "#ACBD24", // Add custom color background (Points and Fill)
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.THU).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันพฤหัส",
          // Add custom color border (Line)
          borderColor: "#F19D28", // Add custom color background (Points and Fill)
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.FRI).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันศุกร์",
          // Add custom color border (Line)
          borderColor: "#3E9FD1",
          // Add custom color background (Points and Fill)
          // Specify bar border width
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.SAT).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันเสาร์",
          // Add custom color border (Line)
          borderColor: "#9A64D4",
          // Add custom color background (Points and Fill)
          // Specify bar border width
          borderWidth: 0,
        },
        {
          // Name the series
          data: Object.values(this.result.SUN).map((el) => el), // Specify the data values array
          fill: false,
          label: "วันอาทิตย์",
          // Add custom color border (Line)
          borderColor: "#D53835",
          // Add custom color background (Points and Fill)
          // Specify bar border width
          borderWidth: 0,
        },
      ],
    };
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },
};
</script>

<template>
  <div>
    <div class="mt-2">
      <b-row>
        <b-col cols="12">
          <b-form-group id="input-group-1" label="ชื่อโปรโมชั่น">
            <b-form-input
              v-model="promotionName"
              type="text"
              placeholder="ชื่อโปรโมชั่น"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3"><span>ประเภทเงื่อนไขส่วนลด*</span></b-col>
        <b-col cols="3"></b-col>
        <b-col cols="3"><span>ส่วนลด*</span></b-col>
        <b-col cols="3"></b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="3">
          <b-form-group id="input-group-1" class="mb-0">
            <b-form-select
              v-model="conditionType"
              :options="optionsCondition"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-input-group
            :append="conditionType === 'COURSE_NUMBER' ? 'คอร์ส' : 'บาท'"
          >
            <b-form-input
              v-model="conditionMinimum"
              placeholder="ใส่ตัวเลข"
              type="number"
              onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
              min="0"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-form-group id="input-group-1" class="mb-0">
            <b-form-select
              v-model="discountType"
              :options="optionsDiscount"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-input-group :append="discountType === 'PERCENT' ? '%' : 'บาท'">
            <b-form-input
              v-model="discountValue"
              placeholder="ใส่ตัวเลข"
              type="number"
              onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
              @change="validateDiscountValue"
              min="1"
              :max="maxDiscountValue"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12 mb-3">
          <b-form-checkbox
            switch
            class="mb-2"
            v-model="hasPromotionLimitedTime"
          >
            กำหนดช่วงเวลา
          </b-form-checkbox>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col cols="4"><span>วันที่เริ่มโปรโมชั่น</span></b-col>
            <b-col cols="4"><span>วันที่สิ้นสุดโปรโมชั่น</span></b-col>
            <b-col cols="4"><span>จำนวนวันเรียน</span></b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <b-form-input
              v-model="coursePriceStartDate"
              type="date"
              :min="dateFormat"
              :max="dataCoursePriceEnd"
              :disabled="hasPromotionLimitedTime == false"
              :state="isPromotionDateValid"
            >
            </b-form-input>
            <b-form-invalid-feedback :state="isPromotionDateValid">
              วันที่เริ่มโปรโมชั่นต้องน้อยกว่าวันที่สิ้นสุดโปรโมชั่น
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <b-form-input
              v-model="coursePriceEndDate"
              type="date"
              :min="coursePriceStartDate"
              :disabled="hasPromotionLimitedTime == false"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <b-input-group append="วัน">
              <b-form-input
                v-model="learnDuration"
                placeholder="ใส่ตัวเลข"
                type="number"
                onkeypress="return (event.charCode >= 48 || event.charCode == 46)"
                min="0"
                :disabled="hasPromotionLimitedTime == false"
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group id="input-group-1" label="นับวันเรียน">
            <b-form-radio-group v-model="startDateType" name="radio-options">
              <b-form-radio value="START_LEARNING">วันที่เข้าเรียนวันแรก <span v-if="this.$store.state.Company.companyInfo.companyStartDateType == 'START_LEARNING'">(ค่าเริ่มต้น)</span></b-form-radio><br>
              <b-form-radio value="ENROLL">วันลงทะเบียน <span v-if="this.$store.state.Company.companyInfo.companyStartDateType == 'ENROLL'">(ค่าเริ่มต้น)</span></b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          class="mt-4"
          type="submit"
          variant="primary"
          @click="submitPromotions()"
          :disabled="
            !promotionName ||
              !conditionType ||
              !discountType ||
              !conditionMinimum ||
              !discountValue ||
              (hasPromotionLimitedTime &&
                (!coursePriceStartDate ||
                  !coursePriceEndDate ||
                  !learnDuration) &&
                coursePriceStartDate >= coursePriceEndDate)
          "
          >บันทึก</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    ccd_id: {
      type: Number,
      default: null,
    },
  },

  mounted() {
    this.setDataPromotionDetails();
  },
  data() {
    return {
      promotionName: null,
      conditionType: null,
      discountType: null,
      conditionMinimum: null,
      discountValue: null,
      startDateType: this.$store.state.Company.companyInfo.companyStartDateType,
      comId: this.$store.state.Company.companyInfo.id,
      optionsCondition: [
        { value: null, text: "เลือกเงื่อนไขส่วนลด" },
        { value: "COURSE_NUMBER", text: "คอร์สที่ซื้อขั้นต่ำ" },
        { value: "TOTAL_PRICE", text: "ยอดซื้อขั้นต่ำ" },
      ],
      optionsDiscount: [
        { value: null, text: "เลือกส่วนลด" },
        { value: "FIX", text: "บาท" },
        { value: "PERCENT", text: "เปอร์เซ็นต์ (%)" },
      ],
      hasPromotionLimitedTime: false,
      coursePriceStartDate: null,
      coursePriceEndDate: null,
      dateFormat: moment().format("YYYY-MM-DD"),
      learnDuration: null,
      isDate: false,
    };
  },
  created() {},
  watch: {
    discountType(newValue) {
      if (newValue == 'PERCENT' && this.discountValue > 100)
        this.discountValue = 1
    }
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "coursePromotions"]),
    dataCoursePriceEnd() {
      if (
        moment(this.coursePriceEndDate).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
      ) {
        return null;
      } else {
        return this.coursePriceEndDate;
      }
    },
    isPromotionDateValid() {
      if (
        moment(this.coursePriceEndDate).format("YYYY-MM-DD") <
        moment(this.coursePriceStartDate).format("YYYY-MM-DD")
      ) {
        return false;
      } else {
        return null;
      }
    },
    maxDiscountValue() {
      if (this.discountType == "PERCENT") {
        return 100;
      }
      return null;
    },
  },

  methods: {
    onhideModel() {
      this.$emit("hideModalPromotion");
    },

    ...mapActions("CourseManagement", [
      "getPromotionList",
      "createPromotions",
      "updatePromotions",
    ]),
    async submitPromotions() {
      if (!this.hasPromotionLimitedTime && this.ccd_id) {
        this.coursePriceStartDate = "NULL";
        this.coursePriceEndDate = "NULL";
        this.learnDuration = 0;
      }
      let request = {
        body: {
          promotionName: this.promotionName,
          promotionType: this.conditionType,
          minimumValue: this.comId == 519 && this.conditionType == 'TOTAL_PRICE' ? this.conditionMinimum * 1.07 : this.conditionMinimum,
          discountType: this.discountType,
          discountValue: this.comId == 519 && this.discountType == 'FIX' ? this.discountValue * 1.07 : this.discountValue,
          startDate: this.coursePriceStartDate,
          endDate: this.coursePriceEndDate,
          learnDuration: this.learnDuration,
          startDateType: this.startDateType,
        },
        ccd_id: this.ccd_id,
      };
      if (!this.ccd_id) {
        await this.createPromotions(request).then(() => {
          this.getPromotionList(null);
        });
      } else {
        await this.updatePromotions(request).then(() => {
          this.getPromotionList(null);
        });
      }

      this.onhideModel();
    },

    setDataPromotionDetails() {
      let data;
      if (this.ccd_id) {
        data = this.coursePromotions.filter((x) => x.ccd_id === this.ccd_id)[0];
        this.promotionName = data.ccd_name;
        this.conditionType = data.ccd_conditionType;
        this.discountType = data.ccd_discountType;
        this.conditionMinimum = data.ccd_minimumCourse
          ? data.ccd_minimumCourse
          : ( this.comId == 519 && this.conditionType == 'TOTAL_PRICE' ? (data.ccd_minimumPrice / 1.07).toFixed(2) : data.ccd_minimumPrice );
        this.discountValue = data.ccd_discountValue
          ? this.comId == 519 && this.discountType == 'FIX' ? (data.ccd_discountValue / 1.07).toFixed(2) : data.ccd_discountValue
          : data.ccd_discountPercent;
        this.coursePriceStartDate = data.ccd_startDate
          ? data.ccd_startDate.slice(0, 10) || moment().format("YYYY-MM-DD")
          : null;
        this.coursePriceEndDate = data.ccd_endDate
          ? data.ccd_endDate.slice(0, 10) || moment().format("YYYY-MM-DD")
          : null;
        this.learnDuration = data.ccd_learnDuration;
        this.startDateType = data.ccd_startDateType;

        if (this.coursePriceStartDate && this.coursePriceEndDate) {
          this.hasPromotionLimitedTime = true;
        }
      }
    },
    validateDiscountValue() {
      if (this.discountType == "PERCENT" && this.discountValue > 100)
        this.discountValue = 100;
      if (this.discountValue < 1) this.discountValue = 1;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Prompt", sans-serif;
}
</style>

<template>
  <b-container fluid class="p-0" style="width: 100%">
    <b-row align-start justify-start column class="list-table">
      <div class="col-md-8">
        <!-- <b-form inline>
                    <label for="inline-form-input-name">เลือกช่วงที่อัพเดทล่าสุด</label>
                    <b-input 
                        v-model="periodDateFrom"
                        type="date"
                        class="mx-3"
                        :max="periodDateToMax"
                        @change="handlePeriodDateChange"
                    ></b-input>
                    <label for="inline-form-input-name">ถึง</label>
                    <b-input 
                        v-model="periodDateTo"
                        type="date"
                        class="mx-3"
                        :max="periodDateToMax"
                        @change="handlePeriodDateChange"
                    ></b-input>
                </b-form> -->
      </div>
      <div class="col-md-4">
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-input
            v-model="examTitleSearchText"
            type="search"
            class="ml-3 search-box"
            append-icon="search"
            placeholder="ชื่อข้อสอบ"
            hide-details
            outline
          ></b-input>
          <b-button variant="outline-primary" @click="handleSearchClicked"
            >ค้นหา</b-button
          >
        </b-input-group>
      </div>
    </b-row>

    <div>
      <b-table
        :items="dataset"
        :fields="fields"
        :current-page="currentPage"
        :busy="companyExamsPending || companyExamsCountPending"
      >
        <template v-slot:cell(avgScore)="props">
          {{ props.item.avgScore.toFixed(2) }}
        </template>
        <template v-slot:cell(info)="props">
          <b-button variant="primary" @click="toExamInsight(props.item._id)"
            >ดูรายละเอียด</b-button
          >
        </template>
        <div slot="table-busy" class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>กำลังโหลด...</strong>
        </div>
      </b-table>
      <b-row>
        <b-col md="2" class="my-1">
          <b-form class="mb-2" inline>
            <label class="mb-2 mr-sm-2 mb-sm-0" for="inline-form-input-name"
              >แสดง</label
            >
            <b-form-select
              v-model="perPage"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="pageOptions"
            ></b-form-select>
            จาก{{ this.totalItems }}
          </b-form>
        </b-col>
        <b-col md="10" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="right"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      fields: [
        { label: "ชื่อข้อสอบ", key: "exam.title" },
        { label: "จำนวนผู้เข้าสอบ", key: "particaipatorCount" },
        { label: "อัพเดทล่าสุด", key: "updatedAt" },
        { label: "คะแนนเฉลี่ย", key: "avgScore" },
        { label: "รายละเอียด", key: "info", sortable: false }
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      periodDateFrom: moment().format("2017-01-01"),
      periodDateTo: moment().format("YYYY-MM-DD"),
      periodDateToMax: moment().format("YYYY-MM-DD"),
      examTitleSearchText: "",
      totalItems: 0
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyInfo",
      "companyExamsData",
      "companyExamsPending",
      "companyExamsCountData",
      "companyExamsCountPending"
    ]),
    dataset() {
      return _.map(this.companyExamsData, ech => {
        return {
          ...ech,
          updatedAt: moment(ech.exam.updatedAt).format("LL")
        };
      });
    }
  },
  watch: {
    currentPage: {
      async handler() {
        await this.getDataFromApi();
        this.totalItems = this.companyExamsCountData;
      },
      deep: true
    },
    perPage: {
      async handler() {
        await this.getDataFromApi();
        this.totalItems = this.companyExamsCountData;
      },
      deep: true
    }
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("Company", [
      "fetchAllExamsDetails",
      "fetchExamsCountByCompanyId"
    ]),
    async getDataFromApi() {
      const payload = {
        companyId: this.companyInfo.id,
        examTitle: this.examTitleSearchText,
        examUpdatedAtFrom: moment(this.periodDateFrom)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        examUpdatedAtTo: moment(this.periodDateTo)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      };
      let pageRequest = {
        page: this.currentPage,
        rowsPerPage: this.perPage
      };
      await this.fetchExamsCountByCompanyId(payload);
      await this.fetchAllExamsDetails({
        ...payload,
        pagination: pageRequest
      });
      this.totalItems = this.companyExamsCountData;
    },
    handlePeriodDateChange() {
      this.presetPeriod = "";
      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
    },
    // handlePresetPeriodChange(value) {
    //     switch (value) {
    //         case 'thisMonth': {
    //             this.periodDateFrom = moment()
    //                 .startOf('month')
    //                 .format('YYYY-MM-DD')
    //             this.periodDateTo = moment()
    //                 .endOf('month')
    //                 .format('YYYY-MM-DD')
    //             break
    //         }
    //         case '30DaysAgo': {
    //             this.periodDateFrom = moment()
    //                 .subtract(30, 'days')
    //                 .format('YYYY-MM-DD')
    //             this.periodDateTo = moment().format('YYYY-MM-DD')
    //             break
    //         }
    //         case '90DaysAgo': {
    //             this.periodDateFrom = moment()
    //                 .subtract(90, 'days')
    //                 .format('YYYY-MM-DD')
    //             this.periodDateTo = moment().format('YYYY-MM-DD')
    //             break
    //         }
    //     }
    // },
    handleSearchClicked() {
      this.currentPage = 1;
      this.getDataFromApi();
    },
    toExamInsight(examId) {
      this.$router.push({
        name: "analytics-exam-insight",
        params: {
          examId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.list-table {
  padding-top: 1.2em;
}
.search-box {
  margin-right: 0.5em;
}
</style>

<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col cols="9">
        <b-form-group>
          <template slot:label>
            <h6>URL เนื้อหาบทเรียน</h6>
          </template>

          <b-input-group size="md" class="mb-5">
            <b-form-input v-model="webEmbedUrl"> </b-form-input>
          </b-input-group>

          <template slot:label>
            <h6>เวลาเรียนจบโดยประมาณ</h6>
          </template>

          <div class="d-flex">
            <vue-timepicker
              manual-input
              class="mr-3"
              v-model="estimatedTime"
              format="HH:mm:ss"
            ></vue-timepicker>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6"> </b-col>
    </b-row>
    <div class="editor-button mt-5">
      <b-button class="mr-1" variant="outline-primary" @click="onhideModel()"
        >ยกเลิก</b-button
      >
      <b-button
        @click="onSave()"
        variant="primary"
        class="mx-2"
        :disabled="!webEmbedUrl || estimatedTime === null"
        >บันทึก
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";
import TimePicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
export default {
  components: {
    'vue-timepicker': TimePicker
  },
  data() {
    return {
      webEmbedUrl: "",
      selectedhour: null,
      selectedminute: null,
      selectedsecond: null,
      resultData: null,
      estimatedTime: null,
    };
  },

  props: {
    lecId: Number,
    courseId: Number,
  },

  mounted() {
    this.fetchmbedWebLecture();
  },
  computed: {
    ...mapGetters("ExamBuilder", ["exams"]),
  },

  methods: {
    ...mapActions("CourseManagement", [
      "createWebEmbedtoLecture",
      "getCourseInfo",
      "fetchCourseModulesList",
      "updateLecture",
    ]),

    onhideModel() {
      this.$emit("hideModalWebEmbedModal");
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
    async fetch() {
      this.isLoading = true;
      await this.fetchExams(this.comId).then(() => {});
      this.isLoading = false;
    },

    async onSave() {
      let time = null;
      if (typeof this.estimatedTime == "object") {
        time = `${this.estimatedTime.HH}:${this.estimatedTime.mm}:${this.estimatedTime.ss}`;
        time = time.split(":").map(Number);
      } else {
        time = this.estimatedTime.split(":").map(Number); // split it at the colons
      }

      var seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];
      this.onhideModel();

      let request = {
        body: {
          lecId: this.lecId,
          courseId: this.courseId,
          embedUrl: this.webEmbedUrl,
          estimatedTime: seconds,
          mId: this.$store.state.Authentication.authUser.id,
        },
      };

      await this.createWebEmbedtoLecture(request);
      let requestLecName = {
        courseId: this.courseId,
        lectureId: this.lecId,
        lectureName: this.lectureName,
        lectureType: "EMBED_WEB",
      };
      await this.updateLecture(requestLecName).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.fetchCourseModulesList({
          courseId: this.courseId,
        });
      });
      this.onhideModel();
    },

    async fetchmbedWebLecture() {
      await api.get(`/v1/embed-web-lecture/${this.lecId}`).then((res) => {
        this.resultData = res.data[0];
      });

      if (this.resultData) {
        this.webEmbedUrl = this.resultData.ew_url;
        this.estimatedTime = new Date(this.resultData.ew_estimatedTime * 1000)
          .toISOString()
          .slice(11, 19);
      }
    },
  },
};
</script>

<style>
#modal-webembed .input-group > #modal-webembed .form-control:not(:last-child),
#modal-webembed .input-group > #modal-webembed .custom-select:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#modal-webembed .modal-header {
  border-bottom: 0px;
}
</style>

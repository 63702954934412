<template>
  <b-row no-gutters>
    <b-col class="text-right">
      <b-icon
        icon="pencil-square"
        scale="1.2"
        variant="success"
        class="mt-2 mr-1"
      />
    </b-col>
    <b-col cols="auto">
      <b-dropdown
        size="sm"
        variant="outline-primary"
        :text="flagLabel"
        class="mr-1"
      >
        <b-dropdown-item :disabled="!flag" @click="update()">
          แบบทดสอบทั่วไป
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="hasQuizLecturePretestFlag"
          @click="update('PRETEST')"
        >
          แบบทดสอบก่อนเรียน
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="hasQuizLecturePosttestFlag"
          @click="update('POSTTEST')"
        >
          แบบทดสอบหลังเรียน
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'QuizLectureOptions',
  props: {
    lectureId: {
      type: Number,
      default: null,
    },
    flag: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('CourseManagement', [
      'hasQuizLecturePretestFlag',
      'hasQuizLecturePosttestFlag',
    ]),
    flagLabel() {
      const flagLabels = {
        PRETEST: 'แบบทดสอบก่อนเรียน',
        POSTTEST: 'แบบทดสอบหลังเรียน',
        null: 'แบบทดสอบทั่วไป',
      };
      return flagLabels[this.flag];
    },
  },
  methods: {
    ...mapActions('CourseManagement', [
      'updateQuizLectureFlag',
      'getCourseInfo',
    ]),
    update(flag = null) {
      this.updateQuizLectureFlag({
        lectureId: this.lectureId,
        flag,
      }).then(() => {
        this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
        this.$emit('updated');
      });
    },
  },
};
</script>

<template>
  <div class="mb-5">
    <PageHeader title="ลงทะเบียนเรียน"></PageHeader>

    <b-container fluid class="outer">
      <b-card class="no-shadow mb-4">
        <h5 class="my-3 text-body">
          คอร์สเรียน
          <small class="text-secondary">
            (ทั้งหมด {{ selectedItems["courses"].length }} คอร์ส,
            {{ selectedItems["courseGroups"].length }} กลุ่มคอร์ส)
          </small>
        </h5>
        <b-table :fields="fields" :items="items" small></b-table>
      </b-card>
      <b-card class="no-shadow mb-4">
        <h5 class="my-3 text-body">
          ผู้เรียน
          <small class="text-secondary"
            >(ทั้งหมด {{ members.length }} คน)</small
          >
        </h5>
        <b-table
          id="members-table"
          :fields="membersTableFields"
          :items="members"
          :current-page="currentPage"
          :per-page="perPage"
          :busy="isLoading"
          sort-icon-left
          small
        >
          <template v-slot:cell(groups)="props">
            <span
              v-for="({ name }, index) in props.item.groups"
              :key="index"
              class="badge badge-secondary mr-1"
              >{{ name }}</span
            >
          </template>
          <div slot="table-busy" class="text-center text-danger my-2">
            <Loading />
          </div>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="members.length"
          :per-page="perPage"
          limit="7"
          aria-controls="members-table"
          prev-text="ก่อนหน้า"
          next-text="ถัดไป"
          :first-number="true"
          :last-number="true"
          :hide-goto-end-buttons="true"
          size="sm"
          align="end"
        ></b-pagination>
      </b-card>
    </b-container>

    <ConfirmEnrollModal modal-id="confirmEnrollModal" />

    <CourseEnrollNavbar
      :sidebar-opened="sidebarOpened"
      @startEnroll="startEnroll"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PageHeader from "@/components/layouts/PageHeader";
import CourseEnrollNavbar from "./partials/CourseEnrollNavbar";
import Loading from "@/components/shared/Loading";
import ConfirmEnrollModal from "./partials/ConfirmEnrollModal";

export default {
  name: "CourseEnrollStep3Page",
  components: { PageHeader, CourseEnrollNavbar, ConfirmEnrollModal, Loading },
  props: {
    sidebarOpened: Boolean
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "ชื่อคอร์สเรียน/กลุ่มคอร์สเรียน"
        },
        {
          key: "type",
          label: "ประเภท"
        }
      ],
      membersTableFields: [
        { key: "name", label: "ผู้เรียน", sortable: true },
        { key: "email", label: "อีเมล", sortable: true },
        { key: "groups", label: "กลุ่มผู้เรียน", sortable: true }
      ],
      allGroupMembers: [],
      perPage: 10,
      currentPage: 1,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("CourseEnrollment", ["selectedItems"]),
    items() {
      return [
        ...this.selectedItems["courses"],
        ...this.selectedItems["courseGroups"]
      ];
    },
    members() {
      const members = [
        ...this.selectedItems["members"],
        ...this.allGroupMembers
      ];
      return members.filter(
        (item, pos, self) => self.findIndex(v => v.id === item.id) === pos
      );
    }
  },
  mounted() {
    this.selectedItems["memberGroups"].forEach(group => {
      this.getGroupMembers(group);
    });
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchGroupMembers"]),
    ...mapActions("CourseEnrollment", [
      "setSelectedItems",
      "validateEnrollment"
    ]),

    async getGroupMembers({ id, name }) {
      const { data } = await this.fetchGroupMembers({
        groupId: id,
        params: {
          excepts: "roles,groups,profiles"
        }
      });

      let members = data.map(({ id, firstName, lastName, username }) => ({
        id,
        groups: [{ name }],
        name: firstName ? `${firstName} ${lastName}` : username
      }));

      this.allGroupMembers = [...this.allGroupMembers, ...members];
    },

    showConfirmEnrollModal() {
      this.$bvModal.show("confirmEnrollModal");
    },

    async startEnroll() {
      const memberIds = this.members.map(item => item.id);
      const courseIds = this.selectedItems["courses"].map(item => item.id);
      const courseGroupIds = this.selectedItems["courseGroups"].map(
        item => item.id
      );

      this.setSelectedItems({
        memberIds,
        courseIds,
        courseGroupIds
      });

      const isValidated = await this.validateEnrollment();
      if (isValidated) {
        this.showConfirmEnrollModal();
      } else {
        const backToUrl = localStorage.getItem("backToUrl");
        if (backToUrl) {
          this.$router.push(backToUrl);
        } else {
          this.$router.push({
            name: "course.enroll.step1"
          });
        }
      }
    }
  }
};
</script>

<template>
  <b-modal
    :id="id"
    ref="modal"
    size="lg"
    centered
    hide-header-close
    hide-footer
    header-border-variant="white"
  >
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title">อัปโหลดจาก Youtube</h5>
      <b-icon-x
        shift-v="30"
        shift-h="10"
        style="width: 30px; height: 30px;"
        @click="close()"
      ></b-icon-x>
    </template>
    <div class="mb-4">
      <b-row class="justify-content-md-center">
        <b-col cols="9" class="text-center">
          <b-form-group>
            <template slot:label>
              <h6>วางลิงค์ลงที่ช่องเพื่ออัปโหลด</h6>
            </template>

            <b-input-group size="md">
              <b-form-input
                v-model="youtubeUrl"
                @keyup="getYoutubeVideoInfo"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  size="sm"
                  text="Button"
                  variant="primary"
                  :disabled="!youtubeUrl"
                  @click="submit"
                >
                  อัปโหลด
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <template slot:description>
              <span class="small text-muted">
                ใช้ได้กับลิงค์ Youtube
                <i class="fa fa-youtube-play text-danger" aria-hidden="true"></i> เท่านั้น
              </span>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="youtubeVideoId" class="justify-content-md-center">
        <b-col cols="5">
          <youtube
            ref="youtube"
            :video-id="youtubeVideoId"
            :resize="true"
          ></youtube>
        </b-col>
        <b-col cols="4">
          <div v-if="youtubeVideoInfo">
            ระยะเวลา:
            <br />
            {{ youtubeVideoInfo.duration | duration }}
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";

Vue.use(VueYoutube);

export default {
  name: "YoutubeUploadModal",
  mixins: [timeMixins],
  props: {
    id: {
      type: String,
      default: "upload-youttube-modal"
    }
  },
  data() {
    return {
      youtubeUrl: "",
      youtubeVideoInfo: {
        duration: 0
      }
    };
  },

  computed: {
    youtubePlayer: {
      get() {
        if (this.youtubeUrl) {
          return this.$refs.youtube.player;
        }

        return null;
      }
    },

    youtubeVideoId() {
      if (this.youtubeUrl) {
        return this.$youtube.getIdFromUrl(this.youtubeUrl);
      }

      return null;
    }
  },
  methods: {
    async getYoutubeVideoInfo() {
      const duration = await this.$refs.youtube.player.getDuration();
      this.youtubeVideoInfo.duration = duration;
    },

    submit() {
      this.$emit(
        "youtube-url",
        `https://www.youtube.com/watch?v=${this.youtubeVideoId}`
      );
      this.$refs["modal"].hide();
    }
  }
};
</script>

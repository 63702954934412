<template>
  <div>
    <PageHeader title="จัดการผู้ใช้งาน"></PageHeader>
    <b-container fluid="xl">
      <div class="py-5">
        <div class="inner bg-container">
          <div style="display: flex">
            <h6 style="margin-right: 150px; display: inline">
              <strong class="pre-formatted">เพิ่มหน้าที่ให้กับผู้ใช้งาน </strong>
            </h6>
            <div style="display: inline-block">
              <div class="mb-4" style="width: 400px">
                <h6>
                  <strong>ชื่อผู้ใช้งานในระบบ</strong>
                </h6>
                <v-select
                  v-if="newUser != null"
                  :options="newUserData"
                  label="name"
                  v-model="memberData"
                  :filter-by="myFilter"
                  @search="newUserSearch($event)"
                  placeholder="กรุณาพิมพ์เพื่อค้นหาผู้ใช้งาน..."
                />
                <v-select v-else placeholder="กำลังโหลดข้อมูล..." />
              </div>
              <div>
                <h6><strong>เลือกหน้าที่</strong></h6>
                <v-select
                  v-if="roleList != null"
                  v-model="roleSelected"
                  :options="roleList"
                  label="name"
                  multiple
                  placeholder="-- เลือกหน้าที่ให้ผู้ใช้งาน --"
                ></v-select>
                <v-select v-else placeholder="กำลังโหลดข้อมูล..."></v-select
                ><br />
                <b-button
                  variant="primary"
                  v-b-modal.modal-1
                  :disabled="
                    memberData == null ||
                    roleSelected.length == 0 ||
                    memberData == 'กรุณาค้นหาชื่อผู้ใช้'
                  "
                  @click="showUserModalData()"
                  >บันทึก</b-button
                >
                <b-modal
                  id="modal-1"
                  title="เพิ่มหน้าที่"
                  no-close-on-backdrop
                  no-close-on-esc
                >
                  ต้องการเพิ่มหน้าที่ <strong>{{ roleName }}</strong> ให้กับ <br />{{
                    nameModal
                  }}
                  ({{ emailModal }})<br />
                  ใช่ หรือ ไม่?
                  <template #modal-footer>
                    <b-button variant="primary" @click="selectUser($bvModal)"
                      >ใช่</b-button
                    >
                    <b-spinner
                      variant="success"
                      label="Spinning"
                      v-show="isLoading"
                    ></b-spinner>
                  </template>
                </b-modal>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <h6 class="mb-3"><strong>รายชื่อผู้ใช้งาน</strong></h6>
            <h6 style="float: left" class="mt-2">
              มีทั้งหมด {{ totalRows }} คน
            </h6>

            <b-input-group style="width: 250px; float: right" class="mb-3">
              <b-input-group-prepend>
                <span class="input-group-text"
                  ><b-icon-search></b-icon-search
                ></span>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="พิมพ์ชื่อเพื่อค้นหา"
              ></b-form-input>
            </b-input-group>

            <b-modal
              ref="modal-2"
              id="modal-2"
              title="ลบหน้าที่"
              no-close-on-backdrop
              no-close-on-esc
              hide-header-close
            >
              ต้องการลบหน้าที่ <strong>{{ roleNameRemoveModal }}</strong> ของ <br />{{
                nameModal
              }}
              ({{ emailModal }})<br />
              ใช่ หรือ ไม่?
              <p style="color: red">{{ removeModalText }}</p>
              <template #modal-footer>
                <b-button @click="$bvModal.hide('modal-2')">ยกเลิก</b-button>
                <div v-if="confirmRemove == true">
                  <b-button variant="primary" @click="removeModal()"
                    >ใช่</b-button
                  >
                </div>
                <b-spinner
                  variant="success"
                  label="Spinning"
                  v-show="isLoading"
                ></b-spinner>
              </template>
            </b-modal>

            <b-modal
              id="modal-3"
              ref="modal-3"
              title="เพิ่มหน้าที่"
              no-close-on-backdrop
              no-close-on-esc
              hide-header-close
            >
              ต้องการเพิ่มหน้าที่
              <v-select
                v-model="roleAdd"
                :options="roleOption"
                multiple
                label="name"
              />
              ให้กับ <br />{{ nameModal }} ({{ emailModal }})<br />
              ใช่ หรือ ไม่?
              <template #modal-footer>
                <b-button @click="$bvModal.hide('modal-3')">ยกเลิก</b-button>
                <div v-if="roleAdd.length > 0">
                  <b-button variant="primary" @click="addRole($bvModal)"
                    >ใช่</b-button
                  >
                </div>
                <b-spinner
                  variant="success"
                  label="Spinning"
                  v-show="isLoading"
                ></b-spinner>
              </template>
            </b-modal>

            <b-table
              id="hasrole-table"
              :filter="filter"
              @filtered="onFiltered"
              :per-page="perPage"
              :current-page="currentPage"
              v-if="hasRole != null"
              striped
              hover
              fixed
              :items="hasRole.data"
              :fields="fields"
              table-class="pre-formatted"
            >
              <template v-slot:cell(roles)="items">
                <div
                  style="padding: 0.2em; display: inline-block; width: 215px"
                >
                  <div
                    v-for="(role, index) in items.item.role"
                    :key="role"
                    class="mr-1 mt-1 mb-1 ml-1 roletag"
                    style="
                      display: inline-block;
                      height: 25px;
                      border-radius: 5px;
                    "
                  >
                    <p class="ml-2 mr-2">
                      {{ role.name }}
                      <span
                        style="cursor: pointer; font-weight: bold"
                        class="ml-1"
                        :id="role.role + '' + items.item.memberId"
                        @click="
                          openRemoveModal(
                            items.item.memberId,
                            role.role,
                            index,
                            role.name
                          )
                        "
                        >x</span
                      >
                    </p>
                  </div>
                </div>
                <b-button
                  class="mr-2"
                  variant="success"
                  style="
                    font-size: 36px;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    line-height: 0;
                    float: right;
                  "
                  @click="openAddRoleModal(items.item.memberId)"
                  >+</b-button
                >
              </template>
            </b-table>
            <b-table v-else :fields="fields"></b-table>
            <div style="float: left">
              <span class="mr-2">จำนวนแถวต่อหน้า </span>
              <b-form-select
                v-model="perPage"
                :options="pageOptions"
                style="width: 100px"
              ></b-form-select>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="hasrole-table"
              style="float: right"
            ></b-pagination>
          </div>
        </div>
        <!-- /.inner -->
      </div>
    </b-container>
  </div>
</template>

<script>
//Import
import PageHeader from "../../layouts/PageHeader";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import api from "@/services/api.js";

export default {
  components: { PageHeader, vSelect },

  filters: {},
  mixins: [],

  props: [],

  data() {
    return {
      totalRows: 0,
      filter: null,
      pageOptions: [10, 20, 30],
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "name", label: "ชื่อ-นามสกุล" },
        { key: "department", label: "แผนก" },
        { key: "email", label: "อีเมล" },
        { key: "username", label: "ชื่อผู้ใช้งาน" },
        { key: "roles", label: "หน้าที่" },
      ],
      isLoading: false,
      roleSelected: [],
      comId: this.$store.state.Company.companyInfo.id,
      roleName: "",
      memberData: null,
      comType: null,
      newUser: null,
      addroleUser: null,
      roleList: null,
      hasRole: null,
      roleOption: null,
      roleAdd: [],
      addMemberId: null,
      removeModalText: null,
      roleNameRemoveModal: null,
      nameModal: null,
      emailModal: null,
      confirmRemove: true,
      count: 0,
      tempSearch: 0,
      newUserData: "กรุณาค้นหาชื่อผู้ใช้",
      myFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        if (search != this.tempSearch) {
          this.count = 0;
          this.tempSearch = search;
        }
        if (
          this.count <= 4 &&
          search.length > 1 &&
          option.autocomplete.toLowerCase().indexOf(temp) > -1 == true
        ) {
          this.count += 1;
          return option.autocomplete.toLowerCase().indexOf(temp) > -1;
        }
      },
    };
  },

  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },

  async mounted() {
    this.comType = await api.get(`v1/companyType/${this.comId}`);
    this.comType = this.comType.data["com_type"];
    if (this.comType == "TRAINER" || this.comType == "WHITELABEL_TUTOR") {
      this.roleList = [
        { name: "ผู้ดูแลระบบ", role: 9 },
        { name: "ผู้สอน", role: 10 },
      ];
    } else {
      this.roleList = [
        { name: "ผู้ดูแลระบบ", role: 3 },
        { name: "ผู้สอน", role: 4 },
      ];
    }
    this.hasRole = await api.get(
      `/v1/companies/${this.comId}/member-has-role/${this.comType}`
    );
    for (var i = 0; i < this.hasRole.data.length; i++) {
      const tempData = [];
      const arr = this.hasRole.data[i].role.split(",");
      for (var j = 0; j < arr.length; j++) {
        if (arr[j] == 3 || arr[j] == 9) {
          tempData.push({ name: "ผู้ดูแลระบบ", role: parseInt(arr[j], 10) });
        } else if (arr[j] == 4 || arr[j] == 10) {
          tempData.push({ name: "ผู้สอน", role: parseInt(arr[j], 10) });
        }
      }
      this.hasRole.data[i].role = tempData;
    }

    this.totalRows = this.hasRole.data.length;
    this.newUser = await api.get(
      `/v1/companies/${this.comId}/member-without-role/${this.comType}`
    );

    for (var aa = 0; aa < this.hasRole.data.length; aa++) {
      if (this.hasRole.data[aa].email != null) {
        this.hasRole.data[aa].email = this.truncate(
          this.hasRole.data[aa].email
        );
      }

      if (this.hasRole.data[aa].username != null) {
        this.hasRole.data[aa].username = this.truncate(
          this.hasRole.data[aa].username
        );
      }
    }
  },

  methods: {
    truncate: function (data) {
      const reqdString = data.split("@");
      return reqdString.join("\n@");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    newUserSearch(event) {
      if (event.length == 0) {
        this.newUserData = "กรุณาค้นหาชื่อผู้ใช้";
      } else if (event.length == 1) {
        this.newUserData = this.newUser.data;
      }
    },
    async selectUser(modal) {
      this.isLoading = true;
      await api
        .post(`/v1/companies/${this.comId}/create-role`, {
          roles: this.roleSelected,
          mId: this.memberData.memberId,
        })
        .then(() => {
          modal.hide("modal-1");
          this.isLoading = false;
          location.reload();
        });
    },
    openRemoveModal(memberId, role, roleIndex, roleName) {
      var memberData = this.hasRole.data.filter(function (data) {
        if (data.memberId == memberId) {
          return data;
        }
      });
      this.nameModal = memberData[0].name;
      if (memberData[0].email == null) {
        this.emailModal = "ไม่มีอีเมล";
      } else {
        this.emailModal = memberData[0].email;
      }
      this.isLoading = false;
      this.removeModalText = null;
      this.confirmRemove = true;
      this.removeModalText = "";
      this.$refs["modal-2"].show();
      this.roleNameRemoveModal = roleName;
      var userId = this.$store.state.Authentication.authUser.id;
      if (userId == memberId) {
        this.confirmRemove = false;
        this.removeModalText = "ไม่อนุญาตให้ลบหน้าที่ของตนเองออกได้";
      }
      this.memberIdRemoveModal = memberId;
      this.roleNumberRemoveModal = role;
      this.roleIndexRemoveModal = roleIndex;
    },
    async removeModal() {
      this.isLoading = true;
      var memberId = this.memberIdRemoveModal;
      var roleIndex = this.roleIndexRemoveModal;
      await api
        .post(`v1/companies/${this.comId}/remove-role`, {
          memberId: this.memberIdRemoveModal,
          roleNumber: this.roleNumberRemoveModal,
        })
        .then(({ data }) => {
          this.isLoading = false;
          if (data == "this instructor has course.") {
            this.confirmRemove = false;
            this.removeModalText =
              "ไม่อนุญาตให้ลบหน้าที่ของผู้สอนที่มีคอร์สเรียนอยู่ได้";
          } else {
            this.hasRole.data.filter(function (data) {
              if (data.memberId == memberId) {
                data.role.splice(roleIndex, 1);
              }
            });
            this.$refs["modal-2"].hide();
            location.reload();
          }
        });
    },
    openAddRoleModal(memberId) {
      this.isLoading = false;
      this.$refs["modal-3"].show();
      var memberData = this.hasRole.data.filter(function (data) {
        if (data.memberId == memberId) {
          return data;
        }
      });
      var roles = memberData[0].role;
      var roleList = this.roleList;
      var roleOption = roleList.filter(function (objFromA) {
        return !roles.find(function (objFromB) {
          return objFromA.role === objFromB.role;
        });
      });
      this.roleOption = roleOption;
      if (memberData[0].email == null) {
        this.emailModal = "ไม่มีอีเมล";
      } else {
        this.emailModal = memberData[0].email;
      }
      this.nameModal = memberData[0].name;
      this.addMemberId = memberId;
    },
    async addRole(modal) {
      this.isLoading = true;
      await api
        .post(`/v1/companies/${this.comId}/create-role`, {
          roles: this.roleAdd,
          mId: this.addMemberId,
        })
        .then(() => {
          modal.hide("modal-3");
          this.isLoading = false;
          location.reload();
        });
    },
    showUserModalData() {
      this.isLoading = false;
      this.nameModal = this.memberData.name;
      if (this.memberData.email == null) {
        this.emailModal = "ไม่มีอีเมล";
      } else {
        this.emailModal = this.memberData.email;
      }
      this.roleName = "";
      for (var i = 0; i < this.roleSelected.length; i++) {
        this.roleName += this.roleSelected[i].name;
        if (i != this.roleSelected.length - 1) {
          this.roleName += ", ";
        }
      }
    },
  },
};
</script>

<style scoped>
.roletag {
  background-color: lightgrey;
}
.roletag span {
  color: grey;
}
.roletag:hover {
  background-color: #6fb8ef;
  color: white;
}
.roletag:hover span {
  color: white;
}

.table > tbody > tr > td {
  white-space: pre;
}
.pre-formatted {
  white-space: pre;
}
</style>

<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="ประกาศนียบัตร"></PageHeader>
      <b-row>
        <b-col cols="12">
          <certInfo
            :apiRoute="
              `/v1/analytics/courses/getCourseLearnerCertificate?cId=${cId}&template=A`
            "
            :perPage="20"
          ></certInfo>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import certInfo from "./panel/LearnerIndividualCourseCertificatePanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {
      cId: this.$route.params.cId,
    };
  },
  components: {
    PageHeader,
    certInfo,
  },
};
</script>
<style scoped></style>

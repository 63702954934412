export default {
  checkCourseInfoRequire(courseInfo, comId) {
    let pass =
      courseInfo.courseName != null &&
      courseInfo.courseName.length > 0 &&
      courseInfo.courseOwner != null &&
      courseInfo.courseOwner.ownerId != null &&
      courseInfo.courseShortDescription != null &&
      courseInfo.courseSlug != null;
    if (comId == 485) {
      pass =
        pass &&
        courseInfo.approveDate != null &&
        courseInfo.startLearningDate != null &&
        courseInfo.finishLearningDate != null &&
        courseInfo.price != null &&
        courseInfo.membershipPrice[0] != null &&
        courseInfo.membershipPrice[0].price != null;
    }
    if (comId == 519) {
      pass =
        pass &&
        courseInfo.price != null
    }
    if (comId == 513) {
      pass =
        pass &&
        (courseInfo.applicantLimit == null || courseInfo.applicantLimit > 0)
    }
    if (comId == 500) {
      pass =
        courseInfo.startLearningDate != null &&
        courseInfo.finishLearningDate != null;
    }
    return pass;
  },
  isCurrentPage(name) {
    return this.$route.name == "course.management." + name;
  },
};

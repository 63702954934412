<template>
  <div class="outer">
    <div class="inner bg-container">
      <b-container fluid>
        <b-row mb-6 align-content-space-between>
          <b-col xs5 md="8">
            <ExamInfo />
          </b-col>
          <b-col xs6 offset-xs1 md="4">
            <ExamTakerCount />
          </b-col>
        </b-row>

        <b-row mb-5 align-content-space-between>
          <b-col md="4">
            <ExamHighestScore />
          </b-col>
          <b-col md="4">
            <ExamAvgScore />
          </b-col>
          <b-col md="4">
            <ExamLowestScore />
          </b-col>
          <b-col md="6">
            <ExamPassCount />
          </b-col>

          <b-col md="6">
            <ExamFailCount />
          </b-col>
          <b-col md="12">
            <ExamScoreSummary />
          </b-col>
          <!-- <b-col md="6"> -->
          <!-- <ExamScoreSummary /> -->
          <!-- </b-col> -->
          <b-col md="6">
            <ExamMostCorrectQuestion />
          </b-col>
          <b-col md="6">
            <ExamMostIncorrectQuestion />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <ExamInsightTable :exam-id="examId" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ExamInfo from "@/components/pages/exam-analytics/exam-insight/ExamInfo.vue";
import ExamTakerCount from "@/components/pages/exam-analytics/exam-insight/ExamTakerCount.vue";
import ExamHighestScore from "@/components/pages/exam-analytics/exam-insight/ExamHighestScore.vue";
import ExamLowestScore from "@/components/pages/exam-analytics/exam-insight/ExamLowestScore.vue";
import ExamAvgScore from "@/components/pages/exam-analytics/exam-insight/ExamAvgScore.vue";
import ExamPassCount from "@/components/pages/exam-analytics/exam-insight/ExamPassCount.vue";
import ExamFailCount from "@/components/pages/exam-analytics/exam-insight/ExamFailCount.vue";
import ExamMostCorrectQuestion from "@/components/pages/exam-analytics/exam-insight/ExamMostCorrectQuestion.vue";
import ExamMostIncorrectQuestion from "@/components/pages/exam-analytics/exam-insight/ExamMostIncorrectQuestion.vue";
import ExamScoreSummary from "@/components/pages/exam-analytics/exam-insight/ExamScoreSummary.vue";
import ExamInsightTable from "@/components/pages/exam-analytics/exam-insight/exam-insight-table/ExamInsightTable.vue";

export default {
  components: {
    ExamInfo,
    ExamTakerCount,
    ExamHighestScore,
    ExamLowestScore,
    ExamAvgScore,
    ExamPassCount,
    ExamFailCount,
    ExamMostCorrectQuestion,
    ExamMostIncorrectQuestion,
    ExamScoreSummary,
    ExamInsightTable
  },
  props: {
    examId: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
  <div>
    <PageHeader  title="สรุปการเข้าเรียนเฉพาะผู้เรียน"></PageHeader>
    <div class="m-lg-4">
      <!-- Member info / Finish course / Enroll course / Available lecture / Progress percentage -->
      <b-row class="m-4">
        <b-col>
          <information-panel></information-panel>
        </b-col>
      </b-row>
      <!-- Recent study table / Student progress of each course -->
      <b-row class="m-4" align-v="stretch">
        <b-col lg="8">
          <recent-study-panel></recent-study-panel>
        </b-col>
        <b-col lg="4" class="mt-3 mt-lg-0">
          <layout class="h-100">
            <template v-slot:header>
              <h6>ความก้าวหน้ารวมของทุกหลักสูตรคิดเป็นเปอร์เซ็นต์</h6>
            </template>
            <template v-slot:body>
              <course-process-panel :memberId="memberId"></course-process-panel>
            </template>
          </layout>
        </b-col>
        <b-col cols="12 mt-3">
          <learn-progress-panel></learn-progress-panel>
        </b-col>
      </b-row>
      <!-- Certificate / Badge list -->
      <b-row class="m-4">
        <b-col cols="12" class="mt-3">
          <certificate-panel></certificate-panel>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout.vue';
import PageHeader from "@/components/layouts/PageHeader.vue";
import InformationPanel from "@/components/pages/analytics/user/learning-stats/panel/Information.vue";
import RecentStudyPanel from "@/components/pages/analytics/user/learning-stats/panel/RecentStudy.vue";
import LearnProgressPanel from "@/components/pages/analytics/user/learning-stats/panel/LearnProgress.vue";
import CertificatePanel from "@/components/pages/analytics/user/learning-stats/panel/Certificate.vue";
import CourseProcessPanel from "@/components/pages/analytics/user/learning-stats/panel/CourseProcessPanel.vue";
// import BadgeListPanel from "@/components/pages/analytics/user/learning-stats/panel/BadgeList.vue";

export default {
  components: {
    layout,
    PageHeader,
    InformationPanel,
    RecentStudyPanel,
    LearnProgressPanel,
    CourseProcessPanel,
    CertificatePanel,

    // BadgeListPanel,
  },
  props: {
    company: Object,
    user: Object,
  },
  data() {
    return {
      memberId: this.$route.params.mId,
    };
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="row mt-3">
    <div class="col-lg-6">
      <form class="form-inline">
        <div class="form-group mb-2">
          <label>แสดง</label>
        </div>
        <div class="form-group ml-2 mb-2">
          <b-form-select
            v-model="items.meta.per_page"
            :options="limits"
            size="sm"
            @change="changeLimit"
          ></b-form-select>
        </div>
        <div class="form-group ml-2 mb-2">
          <label>
            จาก
            {{ this.items.meta.total | formatNumber }}
            รายการ
          </label>
        </div>
      </form>
    </div>
    <div class="col-lg-6">
      <nav class="float-right" aria-label="Page navigation example">
        <LaravelVuePagination
          :data="items"
          :limit="3"
          class="pagination pagination-csq pagination-sm rounded-0"
          @pagination-change-page="changePage"
        >
          <span slot="prev-nav">ก่อนหน้า</span>
          <span slot="next-nav">ถัดไป</span>
        </LaravelVuePagination>
      </nav>
    </div>
  </div>
</template>

<script>
import LaravelVuePagination from "laravel-vue-pagination";
import Numeral from "numeral";

export default {
  name: "Pagination",
  components: { LaravelVuePagination },
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    }
  },
  props: {
    items: {
      type: Object
    },
    limits: {
      type: Array,
      default: () => {
        return [15, 30, 50];
      }
    }
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    changeLimit(limit) {
      this.$emit("changeLimit", limit);
    }
  }
};
</script>

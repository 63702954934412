// import Cookies from "js-cookie";
import axios from "axios";
// import authConfig from "../config/auth";

// const accessToken = Cookies.get(authConfig.ACCESS_TOKEN_COOKIE);
const baseURL =
  process.env.VUE_APP_EXAM_API_BASE_URL || "http://localhost:9797/api";

const client = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    Authorization: `${localStorage.getItem("token")}`
  }
});

// client.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 404) {
//       let url = `${window.location.origin}/${
//         window.location.pathname.split("/")[1]
//       }/error`;
//       window.location.replace(url);
//     }
//     return Promise.reject(error.message);
//   }
// );

export default {
  async get(endpoint, params = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.get(`${endpoint}`, params);
  },

  async post(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.post(`${endpoint}`, payloads);
  },

  async put(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.put(`${endpoint}`, payloads);
  },

  async patch(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.patch(`${endpoint}`, payloads);
  },

  async delete(endpoint, payloads = {}) {
    client.defaults.headers.common["ComId"] = localStorage.getItem("ComId");
    return await client.delete(`${endpoint}`, { data: payloads });
  }
};

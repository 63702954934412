<template>
  <div class>
    <layout>
      <template v-slot:header v-if="tab == false">
        <b-row>
          <b-col class="my-auto" cols="6">
            <h6 class="my-auto">กระดานถามตอบ</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/courses/discuss`"
              target="_blank"
              ><h6>ดูคำถามที่ยังไม่ตอบทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div :style="style">
          <b-row>
            <b-col>
              <div class="my-2 d-flex">
                <div class="mr-3">คำถามที่ยังไม่ได้ตอบ</div>
                <div
                  class="mr-3 question-amount-style d-flex justify-content-center align-items-center"
                >
                  <span>{{ result && result.length }}</span>
                </div>
                <div>คำถาม</div>
              </div>
            </b-col>
          </b-row>
          <b-row v-for="index in numbers" :key="index" class="px-3">
            <b-col cols="12">
              <div class="d-flex my-3 px-3">
                <div class="text-center" style="width: 100px;">
                  <img
                    :src="result.length && result[index].mp_displayPath"
                    class="user-profile-image"
                  />
                </div>
                <div class="w-100 pr-2">
                  <div class="d-flex">
                    <div class="mr-3 user-name d-flex align-items-center">
                      <strong class="my-auto">{{
                        result.length &&
                          result[index].mp_firstName +
                            " " +
                            result[index].mp_lastName
                      }}</strong>
                    </div>
                    <div class="mr-3 d-flex align-items-center">
                      <span class="my-auto" style="color: #EB8123;"
                        >0 <strong>ความคิดเห็น</strong></span
                      >
                    </div>
                    <div>
                      <b-button
                        variant="primary"
                        size="sm"
                        v-b-modal="
                          result.length && `modal-${result[index].dis_id}`
                        "
                      >
                        <div
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-icon
                            class="mr-2"
                            icon="chat-square"
                            style="color: white;"
                          ></b-icon>
                          <span>ตอบคำถามนี้</span>
                          <b-modal
                            size="xl"
                            :id="
                              result.length && `modal-${result[index].dis_id}`
                            "
                            :title="`ตอบคำถาม`"
                            :hide-footer="true"
                          >
                            <answerModal
                              @posted="fetch()"
                              :pic="
                                result.length && result[index].mp_displayPath
                              "
                              :message="
                                result.length && result[index].dis_message
                              "
                              :id="result.length && result[index].dis_id"
                              :cId="result.length && result[index].c_id"
                              :mId="result.length && result[index].m_id"
                              :name="
                                result.length &&
                                  result[index].mp_firstName +
                                    ' ' +
                                    result[index].mp_lastName
                              "
                            ></answerModal>
                          </b-modal>
                        </div>
                      </b-button>
                    </div>
                  </div>
                  <div class="user-course mt-3 mb-4">
                    {{ result.length && result[index].c_name }}
                    | โพสต์เมื่อ
                    {{ result.length && result[index].dis_date }}
                  </div>
                  <div
                    class="user-message"
                    style="word-break: break-all;"
                    v-html="result.length && result[index].dis_message"
                  ></div>
                </div>
              </div>
              <div
                v-if="index < perPage"
                class="w-100"
                style="border: 0.25px solid #D1D2D4;"
              ></div>
            </b-col>
          </b-row>
          <b-pagination
            v-if="tab == true"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import answerModal from "../modal/CourseDiscussAnswerModal";
import api from "@/services/api.js";
export default {
  components: {
    layout,
    answerModal,
  },
  props: ["apiRoute", "perPage", "tab"],
  data() {
    return {
      numbers: [],
      currentPage: 1,
      company: {},
      style: "",
      result: [],
      isBusy: true,
      items: [],
      rows: 0,
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.rows = this.result.length;
      this.style = this.tab == true ? null : "height: 500px; overflow : auto";
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.company = this.$store.state.Company.companyInfo;
      let numbers = this.numbers;

      for (let i = 1; i <= 1000; i++) {
        numbers.push(i);
      }
      let end = this.perPage * this.currentPage;
      let start = end - this.perPage;
      numbers = [];
      for (let i = start; i <= end - 1; i++) {
        if (i >= this.result.length) break;
        numbers.push(i);
      }
      this.numbers = numbers;
    },
  },
  async mounted() {
    await this.fetch();
  },
};
</script>

<style scoped>
.question-amount-style {
  background-color: #eb8123;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  color: white;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
}
</style>

<template>
  <div id="Example">
    <div>
      <b-button @click="downloadPdf" variant='primary' :disabled="localBtnDisabled"><i class="fa fa-angle-double-down" aria-hidden="true"></i> ดาวน์โหลดรายงาน pdf </b-button>
    </div>
    <canvas id="chartCanvas" width="360" style="display: none"></canvas>
  </div>
</template>

<script>
import api from "@/services/api";
import attendanceReportMixin from "@/mixins/attendace-report-mixin.js";
import attendanceAllCoursesReportMixin from "@/mixins/attendance-all-courses-report-mixin.js";
import generateAttendanceReportPdfMixin from "@/mixins/generate-attendance-report-pdf-mixin.js"; // Import Chart.js

export default {
  mixins: [attendanceReportMixin, attendanceAllCoursesReportMixin, generateAttendanceReportPdfMixin],
  name: "example",
  components: {},
  props: {
    mId: { type: Number, default: null },
    cerId: { default: 'all' },
    start: { type: String, default: null },
    end: { type: String, default: null },
    btnDisabled: { type: Boolean, default: true },
  },
  data() {
    const currentDate = new Date();
    return {
      data: {},
      courseOnlineType: "",
      localBtnDisabled: this.btnDisabled,
      chartWidth: 360,
      chartHeight: 200,
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      thaiMonths: [
          "มกราคม", "กุมภาพันธ์", "มีนาคม",
          "เมษายน", "พฤษภาคม", "มิถุนายน",
          "กรกฎาคม", "สิงหาคม", "กันยายน",
          "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ],
      pdfOption: {
        orientation: "p",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true,
      },
      pdfConfig: {
        typo: {
          header: 16,
          large: 18,
          normal: 11,
          small: 14,
          chartHeader: 18,
        },
        margin: {
          t: 40,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5,
        },
        endNormal: 14,
        endRow: 18,
        endLegend: 32,
      },

      //pdf all course
      pdfAllCourseOption: {
        orientation: "l",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true,
      },
      pdfAllCourseConfig: {
        typo: {
          header: 14,
          large: 16,
          normal: 9,
          small: 7,
          chartHeader: 18,
        },
        margin: {
          t: 72,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5,
        },
        endNormal: 11,
        endRow: 18,
        endTable: 16,
        endRowHeader: 27,
        endLegend: 32,
      },
      allCourseChartWidth: 470,
      allCourseChartHeight: 300,
    };
  },
  watch: {
    mId(newMember) {
      this.mId = newMember;
    },
    cerId(newCourseEnroll) {
      this.cerId = newCourseEnroll;
    },
    start(startMonth) {
      this.start = startMonth;
    },
    end(endMonth) {
      this.end = endMonth;
    },
    btnDisabled(newbtnDisabled) {
      this.localBtnDisabled = newbtnDisabled;
    },
  },
  methods: {
    async getMember() {
      await api
        .get(`/v1/members/${this.mId}/attendance-report/${this.cerId}?start_month=${this.start}&end_month=${this.end}`)
        .then(({ data }) => {
          if(this.cerId !== 'all'){
            this.data = data.data;
            this.courseOnlineType = (data.data.courseOnlineType === "ONLINE") ? "คอร์สเรียนแบบออนไลน์" : "คอร์สเรียนแบบผสมผสาน";
          } else {
            this.data = data;
          }
        })
        .catch(() => {});
    },
    async downloadPdf(){
      await api
        .get(`/v1/members/${this.mId}/is-enroll-available/${this.cerId}?start_month=${this.start}&end_month=${this.end}`) 
        .then(({ data }) => {
          if(data) {
            if (this.cerId === 'all'){
              this.generateAllCoursePdf();
            } else {
              this.generatePdf();
            }
          } else {
            this.$emit('no-data');
          }
        })
    },
  },
};
</script>

<style>
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("พิมพ์ชื่อ หมวดหมู่คอร์ส ใหม่")]),_c('b-form-input',{staticClass:"mt-2",staticStyle:{"padding":"1rem"},model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}}),(
      _vm.newTag &&
        _vm.tag.filter((i) => i.tagName.toLowerCase() == _vm.newTag.toLowerCase())
          .length > 0
    )?_c('div',{staticClass:"mt-2"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("หมวดหมู่คอร์ส นี้มีอยู่ในระบบแล้ว")])]):_vm._e(),_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"mt-2 m-1 p-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("ยกเลิก")]),_c('b-button',{staticClass:"mt-2 m-1 p-2",attrs:{"variant":"primary","disabled":_vm.newTag &&
          _vm.tag.filter((i) => i.tagName.toLowerCase() == _vm.newTag.toLowerCase())
            .length > 0},on:{"click":function($event){return _vm.submit()}}},[_vm._v("เพิ่ม หมวดหมู่คอร์ส")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row>
      <b-col cols="12">
        <page-header title="ข้อสอบที่มีคนทำมากที่สุด"></page-header>
        <b-button
          @click="exportData()"
          style="float: right"
          class="mt-3 mr-3 btn btn-download"
          href=""
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
        >
      </b-col>
    </b-row>

    <b-row class="p-3">
      <b-col cols="2">
        <div></div>
        <b-form-input
          v-model="search"
          placeholder="ชื่อข้อสอบ"
          style="padding:1rem;display:inline-block;width:100%"
        >
        </b-form-input>
      </b-col>

      <b-col cols="2">
        <div></div>
        <b-button variant="info" @click="fetchAvailableQuizIds()"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col-12">
        <b-table
          id="my-table"
          table-class="tbNumber text-left"
          style="overflow-x: auto"
          class="text-truncate border m-3"
          responsive="true"
          :items="items"
          :fields="fields"
          :busy="isLoading || examAmount == null"
          small
          sort-icon-left
          hover
          striped
        >
          <template v-slot:cell(exam)="data">
            <span>{{ data.item.examTitle | cut }}</span>
          </template>
          <template v-slot:cell(totalPass)="data">
            <span>{{ data.item.totalPassDistinctStudent.length }}</span>
          </template>
          <template v-slot:cell(totalStudent)="data">
            <span>{{ data.item.totalStudentDistinctStudent.length }}</span>
          </template>
          <template v-slot:cell(avgScore)="data">
            <span>{{ data.item.avgScore.toFixed(2) }}</span>
          </template>
          <template v-slot:cell(button)="data">
            <b-button variant="outline-primary" size="sm">
              <a
                :href="`/${company.url}/analytics/exam/${data.item._id}`"
                target="_blank"
                >ดูข้อมูล</a
              >
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-12">
        <div class="d-flex justify-content-end align-items-center m-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="examAmount"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import totalTimeUsedMixin from '@/components/pages/analytics/exam/mixins/totalTimeUsed.js';
import dateMixin from '@/components/pages/analytics/shared/mixins/date.js';
import adminService from '@/services/api.js';
import exp from '@/services/export.js';
export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  components: {
    PageHeader,
  },
  data() {
    return {
      company: null,
      examAmount: null,
      quizIds: [],
      items: [],
      search: null,
      fields: [
        { key: 'exam', label: 'ข้อสอบ' },
        { key: 'totalPass', label: 'จำนวนผู้สอบผ่าน' },
        { key: 'totalStudent', label: 'จำนวนผู้เข้าสอบ' },
        { key: 'avgScore', label: 'คะแนนเฉลี่ย' },
        { key: 'button', label: '' },
      ],
      currentPage: 1,
      perPage: 15,
      isLoading: false,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAvailableQuizIds();
  },
  watch: {
    currentPage() {
      this.fetchMostExams();
    },
  },
  methods: {
    async fetchAvailableQuizIds() {
      this.fetchMostExams();
      this.fetchExams();
    },
    async fetchMostExams() {
      this.isLoading = true;
      try {
        const endpoint = `/v1/analytics/exams/most-exams/${
          this.company.id
        }?limit=${this.perPage}&q=${this.currentPage}&exam_search=${
          this.search ? this.search : '*'
        }`;
        const result = await adminService.get(endpoint);
        this.items = result.data;
      } catch (err) {
        console.log('fetchMostExams error!', err);
      }
      this.isLoading = false;
    },

    async fetchExams() {
      this.isLoading = true;
      const endpoint = `/v1/analytics/exams/count-exams/${
        this.company.id
      }?exam_search=${this.search ? this.search : '*'}`;
      const res = await adminService.get(endpoint);
      this.examAmount = res.data;
      this.isLoading = false;
    },

    getTotalTimeUsed(time) {
      if (time) {
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        var timeString = date.toISOString().substr(11, 8);
        const hour = parseInt(timeString.split(':')[0]);
        const minute = parseInt(timeString.split(':')[1]);
        const second = parseInt(timeString.split(':')[2]);
        return `${hour > 0 ? hour + ' ชั่วโมง' : ''} ${
          minute > 0 ? minute + ' นาที' : ''
        } ${second > 0 ? second + ' วินาที' : ''}`;
      } else return '-';
    },

    thaiDateFilterTimestamp(value) {
      if (value) {
        const yearMonthDate = value.split('T')[0].split('-');
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          'ม.ค.',
          'ก.พ.',
          'มี.ค.',
          'เม.ย.',
          'พ.ค.',
          'มิ.ย.',
          'ก.ค.',
          'ส.ค.',
          'ก.ย.',
          'ต.ค.',
          'พ.ย.',
          'ธ.ค.',
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return '-';
    },
    async exportData() {
      let value = [];
      const endpoint = `/v1/analytics/exams/most-exams/${
        this.company.id
      }?limit=${this.examAmount}&q=${1}&exam_search=${
        this.search ? this.search : '*'
      }`;
      const result = await adminService.get(endpoint);
      let set = result.data;
      set.forEach((i) => {
        let temp = {
          title: i.examTitle ? i.examTitle : '-',
          pass: i.totalPassDistinctStudent
            ? i.totalPassDistinctStudent.length
            : 0,
          total: i.totalStudentDistinctStudent
            ? i.totalStudentDistinctStudent.length
            : 0,
          score: i.avgScore ? i.avgScore.toFixed(2) : 0,
        };
        value.push(temp);
      });
      let key = ['ข้อสอบ', 'จำนวนผู้สอบผ่าน', 'จำนวนผู้เข้าสอบ', 'คะแนนเฉลี่ย'];
      let filename = 'Exam-Overview-Most-Exams-' + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
  },
};
</script>

<style></style>

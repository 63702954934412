import axios from "axios";
import Cookies from "js-cookie";
import authConfig from "../config/auth";
import store from "../store/index";

const accessToken = Cookies.get(authConfig.ACCESS_TOKEN_COOKIE);
const apiUrl = process.env.VUE_APP_VIDEO_API_URL;
const client = axios.create({
  baseURL: apiUrl,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + accessToken
  }
});

export default {
  async get(endpoint, payloads) {
    return await client.get(`${endpoint}`, {
      params: payloads.params,
      headers: {
        body: payloads.data
      }
    });
  },

  async post(endpoint, payloads = {}) {
    return await client.post(`${endpoint}`, payloads);
  },

  async uploadVideoFile(endpoint, data) {
    const formData = new FormData();
    formData.append("comId", data.companyId);
    formData.append("cId", data.courseId);
    formData.append("modId", data.moduleId);
    formData.append("lecId", data.lectureId);
    formData.append("video", data.videoFile);
    formData.append("youtubeLink", data.youtubeUrl);
    formData.append("accessToken", localStorage.getItem("token"));

    return await axios({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: "Bearer " + accessToken
      },
      onUploadProgress: function(progressEvent) {
        const uploadPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        store.dispatch("CourseManagement/setUploadVideoLecturePercentage", {
          lectureId: data.lectureId,
          percent: uploadPercentage
        });
      }.bind(this)
    });
  },

  async uploadSlideFile(endpoint, data) {
    const formData = new FormData();
    formData.append("comId", data.companyId);
    formData.append("cId", data.courseId);
    formData.append("lecId", data.lectureId);
    formData.append("material", data.slideFile);
    formData.append("accessToken", localStorage.getItem("token"));

    return await axios({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: "Bearer " + accessToken
      }
    });
  },

  async uploadVideoIntroFile(endpoint, data) {
    const formData = new FormData();
    formData.append("cId", data.courseId);
    formData.append("comId", data.companyId);
    formData.append("video", data.videoFile);
    formData.append("accessToken", localStorage.getItem("token"));

    return await axios({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: "Bearer " + accessToken
      },
      onUploadProgress: function(progressEvent) {
        const uploadPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        store.dispatch(
          "CourseManagement/setUploadPercentage",
          uploadPercentage
        );
      }.bind(this)
    });
  },

  async uploadCourseThumbnail(endpoint, data) {
    const formData = new FormData();
    formData.append("cId", data.courseId);
    formData.append("comId", data.companyId);
    formData.append("image", data.imageFile);
    formData.append("accessToken", localStorage.getItem("token"));

    return await axios({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: "Bearer " + accessToken
      }
    });
  },

  async uploadCourseMaterial(endpoint, data) {
    const formData = new FormData();
    formData.append("material", data.file);
    formData.append("name", data.file.name);
    formData.append("description", "No description");
    formData.append("status", "PUBLISH");
    formData.append("cId", data.courseId);
    formData.append("courseMaterialOrder", data.courseMaterialOrder);

    return await axios({
      method: "post",
      url: apiUrl + endpoint,
      data: formData,
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: "Bearer " + accessToken
      }
    });
  }
};

<template>
  <b-modal
    :id="modalId"
    title="ยืนยันการลงทะเบียน"
    size="lg"
    header-class="border-bottom-0"
    footer-class="border-top-0"
  >
    <b-card-group>
      <b-card class="text-center py-5 no-shadow">
        <label class="mb-3">จำนวนผู้เรียน</label>
        <h2 class="font-weight-bold">
          {{ validatedEnrollmentData.membersCount }} คน
        </h2>
      </b-card>
      <b-card class="text-center py-5 no-shadow">
        <label class="mb-3">จำนวนคอร์สเรียน</label>
        <h2 class="font-weight-bold">
          {{ validatedEnrollmentData.coursesCount }} คอร์ส
        </h2>
      </b-card>
      <b-card class="text-center py-5 no-shadow">
        <label class="mb-3">จำนวนการลงทะเบียนซ้ำ</label>
        <h2 class="font-weight-bold">
          {{
            validatedEnrollmentData.existingEnrollments.length > 1000
              ? '1000+'
              : validatedEnrollmentData.existingEnrollments.length
          }}
          รายการ
        </h2>
      </b-card>
    </b-card-group>

    <div v-if="items.length">
      <h5 class="mt-5">รายการผู้เรียนที่ลงทะเบียนซ้ำ</h5>
      <b-table
        id="existing-enrollment-table"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        class="border-left border-right border-bottom"
      ></b-table>

      <b-pagination
        v-model="currentPage"
        aria-controls="existing-enrollment-table"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="end"
      ></b-pagination>
    </div>

    <template v-slot:modal-footer>
      <b-button @click="closeModal">ยกเลิก</b-button>
      <b-button variant="success" :disabled="onLoading" @click="startEnroll">
        <b-spinner v-if="onLoading" small></b-spinner> ยืนยันการลงทะเบียน
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ConfirmEnrollModal',
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
  },
  data() {
    return {
      onLoading: false,
      fields: [
        { key: 'fullName', label: 'ผู้เรียน', sortable: true },
        { key: 'courseName', label: 'คอร์ส', sortable: true },
        { key: 'enrolledDate', label: 'วันที่ลงทะเบียน' },
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters('CourseEnrollment', ['validatedEnrollmentData']),
    items() {
      return this.validatedEnrollmentData.existingEnrollments;
    },
    rows() {
      return this.items.length > 1000 ? 1000 : this.items.length;
    },
  },
  methods: {
    ...mapActions('CourseEnrollment', ['enrolls']),
    async startEnroll() {
      this.onLoading = true;
      await this.enrolls();
      this.onLoading = false;

      const backToUrl = localStorage.getItem('backToUrl');
      if (backToUrl) {
        this.$router.push(backToUrl);
      } else {
        this.$router.push({
          name: localStorage.getItem('backToUrl') ?? 'course.enroll.index',
        });
      }
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

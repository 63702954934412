<template>
  <div class="mt-5">
    <b-card class="no-shadow rounded-0" header-bg-variant="white">
      <template v-slot:header>
        <h5 class="mb-0">ใบหน้าของผู้เรียนทั้งหมดในระบบ</h5>
      </template>
      <div class="pt-4">
        <b-row align-h="between">
          <b-col md="6" lg="4">
            <SearchBox
              :title="`พิมพ์เพื่อค้นหาผู้เรียน`"
              @typed="getSearchKeyword"
            />
          </b-col>
          <p class="pull-right mr-3">
            <span class="mr-2">ความถูกต้องของใบหน้าผู้เรียน</span>
            <select
              id
              v-model="percentInput"
              class="form-control form-control-sm border-0 bg-light"
              style="width: 85px; display: inline"
              name
              @change="facePercentFilter(percentInput)"
            >
              <option value="ALL">ทั้งหมด</option>
              <option value="90">90%</option>
              <option value="80">80%</option>
              <option value="50">50%</option>
            </select>
          </p>
        </b-row>
        <b-row class="mt-3">
          <b-col md="12">
            <b-form inline>
              <label for="inline-form-input-name" class="mr-2 text-muted">
                เลือก {{ selectedItems.length }} รายการ
              </label>
              <b-button
                v-if="this.selectedItems.length != this.rowPerPage"
                variant="outline-success"
                size="sm"
                class="ml-1"
                @click="selectAllRows"
              >
                เลือกทั้งหมด
              </b-button>
              <b-button
                v-if="this.selectedItems.length == this.rowPerPage"
                variant="outline-danger"
                size="sm"
                class="ml-1"
                @click="clearSelected"
              >
                ยกเลิกทั้งหมด
              </b-button>

              <b-button
                :disabled="selectedItems.length === 0"
                variant="outline-primary"
                size="sm"
                class="ml-1 bg-csq"
                @click="confirmApprovement"
              >
                อนุมัติการเข้าเรียน
              </b-button>
            </b-form>
          </b-col>
        </b-row>
        <b-table
          ref="selectableTable"
          :items="specificMembers"
          :fields="fields"
          :busy="onLoading"
          :per-page="perPage"
          :current-page="currentPage"
          selectable
          select-mode="multi"
          selected-variant="warning"
          striped
          responsive
          class="mt-3"
          @row-selected="onRowSelected"
        >
          <template v-slot:header(±)="">
            <b-link
              v-if="selectedItems.length < items.length"
              class="text-dark"
              @click="selectAllRows"
            >
              <b-icon-square />
            </b-link>
            <b-link v-else class="text-dark" @click="clearSelected">
              <b-icon-check2-square scale="1.2" />
            </b-link>
          </template>

          <template v-slot:cell(±)="props">
            <template v-if="props.rowSelected">
              <b-icon-check2-square scale="1.2" />
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <b-icon-square />
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template v-slot:cell(fullname)="{ item: { firstName, lastName } }">
            <span>{{ `${firstName} ${lastName}` }}</span>
          </template>

          <template v-slot:cell(validFaces)="{ item: { id } }">
            {{ getVerifiedFacesCount(id) }} รูป
          </template>

          <template v-slot:cell(invalidFaces)="{ item: { id } }">
            {{ getUnverifiedFacesCount(id) }} รูป
          </template>

          <template v-slot:cell(verifyMemberFaces)="{ item }">
            <ShowMemberFaceModel
              :member="item"
              @updated="getResults()"
              @approved="sendApprovedFacesAttrs"
            />
          </template>

          <div slot="table-busy">
            <b-spinner class="align-middle text-csq"></b-spinner>
          </div>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="specificMembers.length"
          :per-page="perPage"
          aria-controls="selectableTable"
          class="pull-right mt-3"
          @change="countRowPerPage()"
        ></b-pagination>
        <p class="pull-left mt-3">
          <span class="mr-2">จำนวนแถวต่อหน้า</span>
          <select
            id
            v-model="perPage"
            class="form-control form-control-sm border-0 bg-light"
            style="width: 55px; display: inline"
            name
            @change="countRowPerPage()"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import api from "@/services/api";
import faceRecognitionService from "@/services/face-recognition-service";
import SearchBox from "@/components/shared/SearchBox.vue";
import ShowMemberFaceModel from "../modal/ShowMemberFaceModel.vue";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    SearchBox,
    ShowMemberFaceModel,
  },

  mixins: [AuthMixin],
  props: { company: Object },
  data() {
    return {
      courseId: this.$route.params.id,
      q: "",
      items: [],
      perPage: 10,
      currentPage: 1,
      selectedItems: [],
      fields: [
        { key: "±", thStyle: { width: "20px" } },
        { label: "ชื่อผู้เรียน", key: "fullname" },
        { label: "ตรงกับใบหน้าผู้เรียน", key: "validFaces" },
        { label: "ไม่ตรงกับใบหน้าผู้เรียน", key: "invalidFaces" },
        {
          label: "ตรวจสอบใบหน้าผู้เรียน",
          key: "verifyMemberFaces",
        },
      ],
      onLoading: false,
      memberIds: [],
      members: [],
      specificMembers: [],
      faceApprovePercent: [],
      tempSpecificMembers: [],
      approvedFacesAttrs: [],
      percentInput: "ALL",
      percentFilterCount: 0,
      rowPerPage: "",
    };
  },

  computed: {
    selectAll: {
      get: function () {
        return this.items
          ? this.selectedItems.length == this.items.length
          : false;
      },
      set: function (value) {
        var selectedItems = [];
        if (value) {
          this.items.forEach(function (item) {
            selectedItems.push(item);
          });
        }
        this.selectedItems = selectedItems;
      },
    },
  },

  created() {
    this.getResults();
  },

  methods: {
    onRowSelected(items) {
      this.selectedItems = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    countRowPerPage() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      this.rowPerPage = this.specificMembers.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      ).length;
    },
    async getResults() {
      this.onLoading = true;
      await faceRecognitionService
        .getFaceSummaryStatByMember()
        .then(({ data }) => {
          this.onLoading = false;

          this.items = data;
          this.members = data.filter((item, pos, self) => {
            return self.findIndex((v) => v.m_id === item.m_id) === pos;
          });

          this.memberIds = this.members.map((obj) => {
            return obj.m_id;
          });
        })
        .catch((err) => console.log(err));
      await this.getSpecificMembers();
      this.countRowPerPage();
      this.onLoading = false;
    },
    async getSpecificMembers() {
      console.log(this.company.id);
      await api
        .post(
          `/v1/companies/${this.company.id}/specific-members?excepts=roles,profiles&q=${this.q}`,
          {
            memberIds: this.memberIds,
          }
        )
        .then(({ data }) => {
          this.specificMembers = data.data;
        });
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    async updateLearningLectureFaceDetection() {
      await api.post(`/v1/faces/face-approval`, {
        approvedFacesAttrs: this.approvedFacesAttrs,
      });
    },
    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },
    confirmApprovement() {
      this.$bvModal
        .msgBoxConfirm("ยืนยันใบหน้าของผู้เรียน", {
          title: "ต้องการยืนยันใบหน้าของผู้เรียนใช่หรือไม่",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "ยืนยัน",
          cancelTitle: "ยกเลิก",
          cancelVariant: "link",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) {
            this.approveFaceImages();
          }
        });
    },
    async approveFaceImages() {
      const members = this.selectedItems.map((item) => {
        return {
          m_id: item.id,
          com_id: this.company.id,
        };
      });

      await this.getMemberFaceStats(members);

      await faceRecognitionService
        .approveFaceImages(members)
        .then(async () => {
          this.$bvToast.toast("ยืนยันใบหน้าของผู้เรียนเรียบร้อยแล้ว", {
            title: "สำเร็จ",
            variant: "success",
            solid: true,
          });

          await this.updateLearningLectureFaceDetection(this.selectedItems);
          await this.getResults();
        })
        .catch((err) => console.log(err));
    },
    async getMemberFaceStats(members) {
      const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
      await this.asyncForEach(members, async ({ m_id }) => {
        await waitFor(50);
        const { data } = await faceRecognitionService.getFaceStats({
          m_id,
          face_human_approve: 0,
        });

        const attrs = data.map(({ cer_id, lec_id, learning_point }) => {
          return {
            cerId: cer_id,
            lecId: lec_id,
            learningPoint: learning_point,
            faceVerified: 1,
          };
        });

        this.approvedFacesAttrs = [...this.approvedFacesAttrs, ...attrs];
      });
    },
    async sendApprovedFacesAttrs({ validFaces, invalidFaces }) {
      const validFacesAttrs = validFaces.map(
        ({ cer_id, lec_id, learning_point }) => {
          return {
            cerId: cer_id,
            lecId: lec_id,
            learningPoint: learning_point,
            faceVerified: 1,
          };
        }
      );
      const invalidFacesAttrs = invalidFaces.map(
        ({ cer_id, lec_id, learning_point }) => {
          return {
            cerId: cer_id,
            lecId: lec_id,
            learningPoint: learning_point,
            faceVerified: 0,
          };
        }
      );

      this.approvedFacesAttrs = [
        ...this.approvedFacesAttrs,
        ...validFacesAttrs,
        ...invalidFacesAttrs,
      ];
      await this.updateLearningLectureFaceDetection(this.selectedItems);
    },
    getMemberInfo(memberId) {
      return this.specificMembers.find((member) => member.id === memberId);
    },
    getVerifiedFacesCount(memberId) {
      if (this.items) {
        const item = this.items.find((item) => item.m_id === memberId);
        if (item?.face_verified_count) return item.face_verified_count;
      }

      return 0;
    },
    getUnverifiedFacesCount(memberId) {
      if (this.items) {
        const item = this.items.find((item) => item.m_id === memberId);
        if (item?.total_image_count && item?.face_verified_count)
          return item.total_image_count - item.face_verified_count;
        else if (item?.total_image_count) return item.total_image_count;
      }

      return 0;
    },
    getTotalFacesCount(memberId) {
      if (this.items) {
        const item = this.items.find((item) => item.m_id === memberId);
        return item.total_image_count;
      }
      return 0;
    },
    facePercentFilter(percentInput) {
      if (this.percentFilterCount == 0) {
        if (this.specificMembers.length <= 0) {
          return 0;
        }
        this.tempSpecificMembers = this.specificMembers;
        this.percentFilterCount = 1;
      }
      this.faceApprovePercent = [];
      if (this.percentInput == "ALL") {
        this.specificMembers = this.tempSpecificMembers;
        this.countRowPerPage();
        return 0;
      }
      for (var i = 0; i < this.tempSpecificMembers.length; i++) {
        const mId = this.tempSpecificMembers[i].id;
        if (
          this.getVerifiedFacesCount(mId) != 0 &&
          this.getTotalFacesCount(mId) != 0
        ) {
          const percentCheck =
            this.getVerifiedFacesCount(mId) / this.getTotalFacesCount(mId);
          if (percentCheck * 100 >= percentInput) {
            this.faceApprovePercent.push(this.tempSpecificMembers[i]);
          }
        }
      }
      this.specificMembers = this.faceApprovePercent;
      this.countRowPerPage();
    },
  },
};
</script>

<style>
</style>
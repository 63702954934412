<template>
  <div>
    <div v-if="!globalCriteria.message">
      <Editor
        initialValue=""
        ref="toastuiEditor"
        :options="defaultOptions"
        previewStyle="vertical"
        height="500px"
      />
    </div>
    <div v-else>
      <Editor
        :initialValue="globalCriteria.message"
        ref="toastuiEditor"
        :options="defaultOptions"
        previewStyle="vertical"
        height="500px"
      />
    </div>
    <div class="editor-button mt-3 ">
      <b-button @click="onhideModel()" class="mr-1" variant="outline-primary"
        >ยกเลิก</b-button
      >
      <b-button @click="onSave()" variant="primary" class="mx-2"
        >บันทึก
      </b-button>
    </div>
  </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import { mapActions, mapGetters } from 'vuex';
import { Editor } from '@toast-ui/vue-editor';

export default {
  namespaced: true,
  components: {
    Editor,
  },
  props: ['examId', 'globalCriteria'],

  data() {
    return {
      token: localStorage.getItem('token'),
      contentMARKDOWN: '',
      defaultOptions: {
        minHeight: '800px',
        language: 'th-TH',
        useCommandShortcut: false,
        usageStatistics: true,
        hideModeSwitch: true,
        toolbarItems: [
          ['heading', 'bold', 'italic'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'link'],
          ['scrollSync'],
        ],
      },
    };
  },
  computed: {
    ...mapGetters('ExamBuilder', ['exam']),
  },
  methods: {
    ...mapActions('ExamBuilder', ['updateGlobalCriteria']),
    onhideModel() {
      this.$emit('hideModal');
    },
    onSave() {
      this.onhideModel();
      this.$emit('onSave', {
        message: this.$refs.toastuiEditor.invoke('getMarkdown'),
        data: this.globalCriteria,
      });
    },
  },
};
</script>

<style lang="css" scope>
.editor-button {
  display: flex;
  justify-content: flex-end;
}
</style>

<template>
  <b-row :class="{ 'text-muted': !useExamResult }" class="my-1 ">
    <b-col sm="7" class="py-1 pl-5">
      <b-form-checkbox
        v-model="useExamResult"
        :value="true"
        :unchecked-value="false"
      >
        {{ item.lectureName }}
      </b-form-checkbox>
    </b-col>
    <b-col v-if="useExamResult" sm="2">
      <b-form-input
        v-model="examResultValue"
        type="text"
        size="sm"
        min="0"
        max="100"
        class="text-uppercase"
        :state="validateExamResultValue"
        @change="update"
      />
      <b-form-invalid-feedback id="input-examResultValue-live-feedback">
        <span v-if="examResultType === 'examResultPercent'"
          >กรุณาระบุ 0-100</span
        >
        <span v-else-if="examResultType === 'examResultGrade'"
          >กรุณาระบุ A-F</span
        >
      </b-form-invalid-feedback>
    </b-col>
    <b-col v-if="useExamResult" sm="3">
      <span>% ของคะแนนข้อสอบ</span>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ExamRuleItem',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      examRuleOptions: [
        { text: '% ของคะแนนข้อสอบ', value: 'examResultPercent' },
        { text: 'เกรดของข้อสอบ', value: 'examResultGrade' },
      ],
      isUseExamRule: false,
      tempExamResultType: 'examResultPercent',
      tempExamResultValue: 60,
    };
  },
  created() {
    if (this.item.examRule) {
      this.isUseExamRule = true;
      if (this.item.examRule.examResultGrade) {
      //   this.tempExamResultType = 'examResultGrade';
        this.tempExamResultValue = this.item.examRule['examResultGrade'];
      } else if (this.item.examRule.examResultPercent) {
      //   this.tempExamResultType = 'examResultPercent';
        this.tempExamResultValue = this.item.examRule['examResultPercent'];
      }
    }
  },
  computed: {
    examResultType: {
      get() {
        return this.tempExamResultType;
      },
      set(value) {
        this.tempExamResultType = value;
        this.tempExamResultValue =
          this.tempExamResultType === 'examResultPercent' ? 60 : 'C';
      },
    },

    examResultValue: {
      get() {
        return this.tempExamResultValue;
      },
      set(value) {
        this.tempExamResultValue =
          this.tempExamResultType === 'examResultGrade'
            ? value.toUpperCase()
            : value;
      },
    },

    validateExamResultValue() {
      if (!this.tempExamResultValue) return null;

      if (this.tempExamResultType === 'examResultPercent')
        return this.tempExamResultValue >= 0 && this.tempExamResultValue <= 100
          ? null
          : false;
      else if (this.tempExamResultType === 'examResultGrade') {
        const re = new RegExp('[A-F]$');
        return re.test(this.tempExamResultValue) ? null : false;
      }

      return null;
    },

    useExamResult: {
      get() {
        return this.isUseExamRule;
      },
      set(value) {
        this.isUseExamRule = value;

        this.update();
      },
    },
  },
  methods: {
    ...mapActions('CourseCompleteRule', ['updateCourseExamRules']),
    ...mapActions('CourseManagement', ['getCourseInfo']),
    async update() {
      if (this.examResultValue && this.validateExamResultValue === null) {
        await this.updateCourseExamRules({
          examId: this.item.quizExamId,
          [this.tempExamResultType]: this.tempExamResultValue,
          courseId: this.courseId,
          isUseExamRule: this.isUseExamRule,
          examRule: {
            examId: this.item.quizExamId,
            [this.tempExamResultType]: this.tempExamResultValue,
          },
        });
        await await this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>สรุปจำนวนคอร์สแยกตามผู้สอน</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/courses/instructors`"
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <b-form-input
                  v-model="search"
                  placeholder="ค้นหาผู้สอน"
                  style="padding: 1rem; display: inline-block; width: 20%"
                >
                </b-form-input>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="course-instructor-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="
              search == null
                ? result
                : result.filter((i) =>
                    (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
                  )
            "
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(instructorName)="data">
              <span>{{
                (data.item.mp_firstName + " " + data.item.mp_lastName)
                  | cut(perPage)
              }}</span>
            </template>
            <template v-slot:cell(courseCount)="data"
              ><span>{{ data.item.count }}</span></template
            >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="result.length == 0">
            {{ "ไม่มีข้อมูล" }}
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) =>
                    (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
                  ).length
            "
            :per-page="perPage"
            aria-controls="course-instructor-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  components: {
    layout,
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      currentPage: 1,
      search: null,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "instructorName", label: "ผู้สอน" },
        { key: "courseCount", label: "จำนวนคอร์สที่สอน" },
      ],
      items: [],
      company: {},
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) =>
              (i.mp_firstName + " " + i.mp_lastName).includes(this.search)
            );
      set.forEach((i) => {
        let temp = {
          instructor: i.mp_firstName + " " + i.mp_lastName,
          count: i.count,
        };
        value.push(temp);
      });
      let key = ["ผู้สอน", "จำนวนคอร์สที่สอน"];
      let filename = "Course-Overview-Instructors-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped></style>

import authService from "@/services/auth";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Authentication", ["authUser"]),
    isTutor() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("tutor/") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    IsTutorAdmin() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("tutor/admin") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    isTutorInstructor() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("tutor/instructor") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    isOrgAdmin() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("org/admin") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    isInstructor() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("/instructor") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    isOrgInstructor() {
      const roles = this.authUser.roles;
      const company = this.$store.state.Company.companyInfo.id;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].roleName.search("org/instructor") != "-1" &&
          roles[i].companyId == company
        ) {
          return true;
        }
      }
    },
    canManageMember() {
      return this.hasPermissions(["crud-member"]);
    },
    canEnrollMember() {
      return this.hasPermissions([
        "crud-individual-enrollment",
        "crud-group-enrollment",
      ]);
    },
    canSpecificUserAccess() {
      if (this.m_Id == 283814 && this.comId == 408) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      tempHasPermissions: null,
      comId: JSON.parse(localStorage.getItem("ComId")),
      m_Id: JSON.parse(localStorage.getItem("user")).id,
    };
  },
  methods: {
    hasRoles(roles) {
      return roles.find((roleName) => {
        return this.authUser.roles.find((role) => {
          return (
            role.roleName == roleName && role.companyId === this.company.id
          );
        });
      });
    },
    hasPermissions(permissions) {
      authService.hasPermissions(permissions).then((result) => {
        this.tempHasPermissions = result;
      });

      return this.tempHasPermissions;
    },
    isAccountingType() {
      if (this.comId == 485 || this.comId == 513 || this.comId == 519) {
        return true;
      }
    },
    isInsuranceType() {
      if (
        this.comId == 470 ||
        this.comId == 500 ||
        this.comId == 502 ||
        this.comId == 503 ||
        this.comId == 506
      ) {
        return true;
      }
    },
    isTutorType() {
      let companyType = this.$store.state.Company.companyInfo.companyType;
      let exceptComIds = [513, 519];
      if (
        (companyType == "WHITELABEL_TUTOR" || companyType == "TRAINER") &&
        !exceptComIds.includes(this.comId)
      ) {
        return true;
      }
    },
    isAllowedToSpecificCompany() {
      if (this.comId == 512 || this.comId == 524) {
        return true;
      }
    }
  },
};

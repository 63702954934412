<template>
  <div>
    <b-jumbotron
      lead="You have no permission to view this page."
      bg-variant="white"
      class="text-center"
    >
      <template v-slot:header>
        403
      </template>
      <b-button variant="outline-dark" @click="refresh">
        <b-icon icon="box-arrow-in-left"></b-icon>
        กลับสู่หน้าหลัก
      </b-button>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  methods: {
    refresh() {
      let homepage = window.location.origin + '/' + this.$route.params.company;
      window.location.replace(homepage);
    },
  },
};
</script>

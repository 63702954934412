var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 pt-4 border-top"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.courseInfo.courseName))])]),_c('b-button',{staticClass:"px-2",attrs:{"disabled":_vm.memberInfo.cer_approveFaceStatus === 'APPROVED' ||
              _vm.memberInfo.cer_status != 'COMPLETED',"size":"sm","variant":"success"},on:{"click":function($event){return _vm.$emit('openModal')}}},[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"check2-all","aria-hidden":"true"}}),_vm._v(" ยืนยันผลการตรวจใบหน้าของคอร์สนี้ ")],1)],1),_c('div',{staticClass:"btn"})]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h6',[_c('strong',[_vm._v("เนื้อหาคอร์ส")])]),_c('b-button',{staticClass:"px-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.toggleCollapse()}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")])],1),_c('div',{staticClass:"box-lecture mt-3"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},_vm._l((_vm.learningFaceItem),function(learningFaceItem,learningFaceItemIndex){return _c('div',{key:learningFaceItemIndex,staticClass:"box-lecture-collapse"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + learningFaceItemIndex),expression:"'collapse-' + learningFaceItemIndex"}],staticClass:"box-lecture-collaspe-title",class:[_vm.collapseValue ? 'not-collapsed' : 'collapsed']},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('h6',{staticClass:"mb-0 text-lecture"},[_vm._v(" "+_vm._s(learningFaceItem.lec_name)+" ")])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('h6',{class:[
                        learningFaceItem.lel_approveFaceStatus === 'APPROVED'
                          ? 'text-success'
                          : 'text-danger',
                        'mb-0 mr-3',
                      ]},[_vm._v(" "+_vm._s(learningFaceItem.lel_approveFaceStatus === "APPROVED" ? "ยืนยันแล้ว" : "ยังไม่ได้ยืนยัน")+" ")]),_c('b-icon',{staticClass:"down",attrs:{"icon":"chevron-down","aria-hidden":"true"}}),_c('b-icon',{staticClass:"up",attrs:{"icon":"chevron-up","aria-hidden":"true"}})],1)])],1)],1),_c('b-collapse',{attrs:{"id":'collapse-' + learningFaceItemIndex,"visible":_vm.collapseValue},model:{value:(_vm.collapseStates[learningFaceItemIndex]),callback:function ($$v) {_vm.$set(_vm.collapseStates, learningFaceItemIndex, $$v)},expression:"collapseStates[learningFaceItemIndex]"}},[_c('div',{staticClass:"collapse-details"},[(!learningFaceItem.lec_images.length)?_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('h4',[_vm._v("ยังไม่มีข้อมูลใบหน้า")])])],1):_c('div',[_c('b-row',{staticClass:"my-3"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"px-2",attrs:{"size":"sm","disabled":learningFaceItem.lel_approveFaceStatus ===
                            'APPROVED' || _vm.memberInfo.cer_status != 'COMPLETED'},on:{"click":function($event){return _vm.approveLecture(learningFaceItem.lec_id)}}},[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"check2","aria-hidden":"true"}}),_vm._v(" ยืนยันผลการตรวจใบหน้าของบทเรียนนี้ ")],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('h6',[_c('strong',[_vm._v("รูปภาพ")])])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('h6',[_c('strong',[_vm._v("เวลาที่ถ่ายรูป(นาที)")])])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('h6',[_c('strong',[_vm._v("ระบบตรวจสอบใบหน้า")])])])],1),_c('div',{staticClass:"box-face-images"},_vm._l((learningFaceItem.lec_images
                        .slice()
                        .reverse()),function(faceIamgesItem,faceIamgesIndex){return _c('b-row',{key:faceIamgesIndex,staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":`${_vm.baseUrl}/${faceIamgesItem.face_path}?token=${_vm.token}`}})])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"time"},[_c('h6',[_vm._v(" "+_vm._s(_vm.secondsToHms(faceIamgesItem.learning_point))+" ")])])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.memberInfo.cer_status != 'COMPLETED')?_c('div',[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(
                                'ไม่สามารถแก้ไขได้ เนื่องจากคอร์สนี้ยังเรียนไม่จบ'
                              ),expression:"\n                                'ไม่สามารถแก้ไขได้ เนื่องจากคอร์สนี้ยังเรียนไม่จบ'\n                              ",modifiers:{"hover":true,"bottom":true}}],staticClass:"mr-3",attrs:{"icon":"exclamation-triangle-fill","variant":"warning"}})],1):_vm._e(),_c('select',{class:[
                              faceIamgesItem.face_verified == 1
                                ? 'text-success'
                                : 'text-danger',
                              'form-select border-0 bg-light',
                            ],attrs:{"disabled":learningFaceItem.lel_approveFaceStatus ===
                                'APPROVED' ||
                                _vm.memberInfo.cer_status != 'COMPLETED'},on:{"change":function($event){return _vm.editApproveLecture($event, faceIamgesItem)}}},[_c('option',{staticClass:"text-success",attrs:{"value":"1"},domProps:{"selected":faceIamgesItem.face_verified == 1}},[_vm._v(" ตรวจพบ ")]),_c('option',{staticClass:"text-danger",attrs:{"value":"0"},domProps:{"selected":faceIamgesItem.face_verified == 0}},[_vm._v(" ตรวจไม่พบ ")])])])])],1)}),1)],1)],1)])],1)}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  JWT_TOKEN_COOKIE: "token",
  ACCESS_TOKEN_COOKIE: "accessToken",
  AUTH_SERVICE_API_URL:
    process.env.VUE_APP_AUTH_SERVICE_API_URL ||
    "https://cs-api-gateway-20190919.coursesquare.com",
  AUTH_SERVICE_TOKEN_KEY:
    process.env.VUE_APP_AUTH_SERVICE_TOKEN_KEY || "auth-key",
  AUTH_SERVICE_TOKEN_VALUE:
    process.env.VUE_APP_AUTH_SERVICE_TOKEN_VALUE ||
    "wpaGWB0uzXIpzBL3cZ3czUAR8OKr4GGK"
};

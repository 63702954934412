<template>
  <div>
    <PageHeader title="สรุปการเข้าชั้นเรียน"></PageHeader>
    <b-container>
      <b-row>
        <b-col>
          <div class="bg-container py-5">
            <b-row>
              <b-col cols="auto">
                <b-img-lazy
                  slot="aside"
                  class="rounded"
                  v-bind="mainProps"
                  :src="classList.courseThumbnail"
                  alt
                ></b-img-lazy>
              </b-col>
              <b-col>
                <h4 class="card-title" style="font-weight: 600;">
                  {{ classList.courseName }}
                </h4>
                <h6>
                  {{ hasCouseOwnerName(classList.courseOwner.ownerName) }}
                </h6>
                <h6>
                  จำนวนผู้เรียนทั้งหมด {{ classList.numberOfActiveStudent }} คน
                </h6>
              </b-col>
            </b-row>

            <div class="attend">
              <div>
                <div>
                  <h6 class="mb-0">
                    <span style="font-weight: 600;">ข้อมูลการเข้าเรียน</span>
                    รวมทั้งหมด {{ classList.numberOfClass }} ครั้ง
                  </h6>
                </div>
              </div>
              <div class="attend-info my-4">
                <b-button
                  @click="exportReport()"
                  class="btn-main mr-2"
                  variant="primary"
                  href=""
                >
                  <b-icon
                    icon="chevron-double-down"
                    style="height: 14px;"
                  ></b-icon>
                  ดาวน์โหลดรายงาน xlsx
                </b-button>
                <ag-grid-vue
                  style="width: 100%; height: 800px; display: none"
                  class="ag-theme-alpine"
                  :columnDefs="columnInfo"
                  :rowData="rowDataInfo"
                  :excelStyles="excelStyles"
                  @grid-ready="onGridReady"
                ></ag-grid-vue>
                <router-link
                  :to="{
                    name: 'attendance.add',
                    params: { courseId: courseId },
                  }"
                >
                  <b-button variant="primary" class="btn-main">
                    <b-icon icon="plus" style="height: 22px;"></b-icon>
                    <span>เพิ่มข้อมูลใหม่</span>
                  </b-button>
                </router-link>
              </div>
            </div>

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="align-middle" rowspan="2">
                    วันที่เข้าเรียน
                  </th>
                  <th class="align-middle" rowspan="2">เวลา</th>
                  <th colspan="4">สถานะการเข้าเรียน</th>
                  <th class="align-middle" rowspan="2">
                    รวมทั้งหมด
                  </th>
                  <th class="align-middle" rowspan="2">
                    เพิ่มเติม
                  </th>
                </tr>
                <tr>
                  <th class="align-middle" style="width: 100px;">เข้าเรียน</th>
                  <th class="align-middle" style="width: 100px;">
                    มาสาย/กลับก่อน
                  </th>
                  <th class="align-middle" style="width: 100px;">ขาดเรียน</th>
                  <th class="align-middle" style="width: 100px;">ลา</th>
                </tr>
              </thead>
              <tbody v-if="!isLoading" id="table-body">
                <tr
                  v-for="(item, index) in classList.classAttendanceStat"
                  :key="index"
                >
                  <td class="text-center align-middle">
                    {{ formatDate(item.cls_date) }}
                  </td>
                  <td class="text-center align-middle">
                    {{
                      formatTime(item.cls_startTime) +
                        " - " +
                        formatTime(item.cls_finishTime)
                    }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.present_count }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.late_count }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.absent_count }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.leave_count }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.total_attend_count }}
                    <b-icon
                      id="tooltip-icon"
                      v-if="item.null_count > 0"
                      icon="exclamation-triangle-fill"
                      variant="warning"
                    >
                    </b-icon>
                    <b-tooltip target="tooltip-icon" placement="bottom">
                      กรอกรายละเอียดการเข้าเรียนของผู้เรียนไม่ครบ
                    </b-tooltip>
                  </td>
                  <td class="text-center align-middle">
                    <router-link
                      :to="{
                        name: 'class.attendance.info',
                        params: { classId: item.cls_id },
                      }"
                    >
                      <b-button variant="outline-primary">รายละเอียด</b-button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td class="text-center" colspan="8">
                  <b-spinner></b-spinner>
                </td>
              </tbody>
              <tbody
                v-if="!isLoading && classList.classAttendanceStat.length < 1"
              >
                <td class="text-center" colspan="8">
                  ไม่มีรายละเอียดข้อมูลการเข้าเรียนในขณะนี้
                </td>
              </tbody>
            </table>
          </div>
          <br />
          <router-link
            :to="{
              name: 'attendance.overview',
            }"
          >
            <b-icon icon="arrow-left" variant="primary"></b-icon>
            <span class="pl-2" style="color: #027BFF;">ย้อนกลับ</span>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "../../layouts/PageHeader";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: { PageHeader, "ag-grid-vue": AgGridVue },
  props: {
    company: {
      type: Object,
      default: null,
    },
    user: { default: null, type: Object },
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      mainProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 120,
        height: "auto",
        isShow: true,
      },
      columnInfo: [],
      rowDataInfo: [],
      excelStyles: [
        {
          id: "header",
          font: { fontName: "Cordia New", size: 14 },
          alignment: {
            horizontal: "left",
          },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 14 },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "left",
          font: { fontName: "Cordia New", size: 14 },
          alignment: {
            horizontal: "left",
          },
        },
        {
          id: "border",
          borders: {
            borderBottom: {
              color: "#000000",
              lineStyle: "Continuous",
              weight: 1,
            },
            borderLeft: {
              color: "#000000",
              lineStyle: "Continuous",
              weight: 1,
            },
            borderRight: {
              color: "#000000",
              lineStyle: "Continuous",
              weight: 1,
            },
            borderTop: {
              color: "#000000",
              lineStyle: "Continuous",
              weight: 1,
            },
          },
        },
        { id: "mergedCell", alignment: { horizontal: "Center", vertical: "Middle" } }
      ],
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters("AttendanceManagement", [
      "isLoading",
      "classList",
      "courseReport",
    ]),
  },
  created() {},
  async mounted() {
    await this.fetchClassList(this.courseId);
  },
  methods: {
    ...mapActions("AttendanceManagement", [
      "fetchClassList",
      "fetchCourseReport",
    ]),
    hasCouseOwnerName(name) {
      if (name.trim() === "") {
        return "-";
      } else {
        return name;
      }
    },
    formatDate(date) {
      moment.updateLocale("th", {});
      return moment(date).format("DD MMM ") + (moment(date).year() + 543);
    },
    formatTime(time) {
      const [hours, minutes] = time.split(":");
      return `${hours}:${minutes}`;
    },
    secondsToHHMM(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);

      var HH = hours < 10 ? "0" + hours : hours;
      var mm = minutes < 10 ? "0" + minutes : minutes;

      return HH + ":" + mm;
    },
    async exportReport() {
      await this.fetchCourseReport(this.courseId);
      
      const uniqueColumn = [...new Set(this.courseReport.column.map(item => item.time))];
      const dynamicHeader = uniqueColumn.map(date => ({
        headerName: "",
        field: date,
        cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
      }));

      let dynamicColumn = {};
      uniqueColumn.forEach(date => {
        dynamicColumn[date] = date;
      });

      let dynamicEmptyColumn = {};
      uniqueColumn.forEach(date => {
        dynamicEmptyColumn[date] = '';
      });

      let classStatus = this.courseReport.classStatus;

      for (let key in classStatus) {
        if (classStatus[key]) {
          let attendanceRecord = classStatus[key];
          for (let timestamp in attendanceRecord) {
            if (attendanceRecord[timestamp]) {
              attendanceRecord[timestamp] = this.translateAttendanceStatus(attendanceRecord[timestamp]);
            }
          }
        }
      }


      this.columnInfo = [
        {
          headerName: "ชื่อคอร์ส :",
          field: "name",
          rowSpan: (params) =>
            params.data.name === "ชื่อผู้เรียน" ? 2 : 1,

          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== '' &&
                params.value !== "ชื่อคอร์ส :" &&
                params.value !== "ชื่อผู้สอน :" &&
                params.value !== "จำนวนผู้เรียนทั้งหมด :" &&
                params.value !== "จำนวนชั้นเรียนรวม :";
            },
            left: (params) => {
              return params.value == "ชื่อคอร์ส :" ||
                params.value == "ชื่อผู้สอน :" ||
                params.value == "จำนวนผู้เรียนทั้งหมด :" ||
                params.value == "จำนวนชั้นเรียนรวม :";
            },
          },
        },
        {
          headerName: `${this.classList.courseName}`,
          field: "email",
          rowSpan: (params) =>
            params.data.email === "อีเมล" ? 2 : 1,
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== '' &&
                params.value !== `${this.classList.courseName}` &&
                params.value !== `${this.classList.courseOwner.ownerName}` &&
                params.value !== `${this.classList.numberOfActiveStudent} คน` &&
                params.value !== `${this.classList.numberOfClass} ครั้ง`;
            },
            left: (params) => {
              return params.value == `${this.classList.courseName}` ||
                params.value == `${this.classList.courseOwner.ownerName}` ||
                params.value == `${this.classList.numberOfActiveStudent} คน` ||
                params.value == `${this.classList.numberOfClass} ครั้ง`;
            },
          },
        },
        {
          headerName: "",
          field: "totalTime",
          colSpan: (params) =>
            params.data.totalTime === "การเข้าชั้นเรียน" ? (uniqueColumn.length + 1) : 1,
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
        },
        ...dynamicHeader,
        {
          headerName: "",
          field: "learningTime",
          colSpan: (params) =>
            params.data.learningTime === "สรุปการเข้าเรียนรวม" ? 4 : 1,
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
        },
        {
          headerName: "",
          field: "videoTime",
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
        },
        {
          headerName: "",
          field: "totalLearningTime",
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
        },
        {
          headerName: "",
          field: "percentTime",
          cellClassRules: {
            border: (params) => {
              return params.value.trim() !== ''
            },
          },
        },
      ];


      let classInfo = [
        {
          name: "ชื่อผู้สอน :",
          email: `${this.classList.courseOwner.ownerName}`,
          totalTime: "",
          ...dynamicEmptyColumn,
          learningTime: "",
          videoTime: "",
          totalLearningTime: "",
          percentTime: "",
        },
        {
          name: "จำนวนผู้เรียนทั้งหมด :",
          email: `${this.classList.numberOfActiveStudent} คน`,
          totalTime: "",
          ...dynamicEmptyColumn,
          learningTime: "",
          videoTime: "",
          totalLearningTime: "",
          percentTime: "",
        },
        {
          name: "จำนวนชั้นเรียนรวม :",
          email: `${this.classList.numberOfClass} ครั้ง`,
          totalTime: "",
          ...dynamicEmptyColumn,
          learningTime: "",
          videoTime: "",
          totalLearningTime: "",
          percentTime: "",
        },
        {  },
        {
          name: "",
          email: "",
          totalTime: "การเข้าชั้นเรียน",
          learningTime: "สรุปการเข้าเรียนรวม",
        },
        {
          name: "ชื่อผู้เรียน",
          email: "อีเมล",
          totalTime: "รวมชั่วโมง",
          ...dynamicColumn,
          learningTime : "เรียนสด",
          videoTime: "เรียนวิดีโอ",
          totalLearningTime: "รวม ชม. ที่เรียน",
          percentTime: "%การเข้าเรียน",
        },
      ];

      let data = this.courseReport.classDetail.map((item) => {
        return {
          name: item.name,
          email: item.email,
          totalTime: this.secondsToHHMM(item.totalClassTime),
          learningTime: this.secondsToHHMM(item.totalOnsiteTimeSpent) + " ชม.",
          ...classStatus[item.cer_id],
          videoTime: this.secondsToHHMM(item.totalOnlineTimeSpent) + " ชม.",
          totalLearningTime: this.secondsToHHMM(item.totalOnsiteTimeSpent + item.totalOnlineTimeSpent) + " ชม.",
          percentTime: (((item.totalOnsiteTimeSpent + item.totalOnlineTimeSpent) / item.totalClassTime) * 100).toFixed(2) + "%"
        };
      });
      this.rowDataInfo = [...classInfo, ...data];

      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "รายงานการเข้าชั้นเรียน",
          fileName: `รายงานการเข้าชั้นเรียน`,
        });
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    translateAttendanceStatus(status) {
      switch (status) {
          case "PRESENT":
              return "เข้าเรียน";
          case "ABSENT":
              return "ไม่เข้าเรียน";
          case "LATE":
              return "มาสาย";
          case "LEAVE":
              return "ลา";
          default:
              return " - ";
      }
    },
  },
};
</script>

<style scoped>
.btn-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attend {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attend-info {
  display: flex;
  justify-content: baseline;
}

.table th {
  text-align: center !important;
}

.cell-span {
  background-color: #00e5ff;
}
</style>

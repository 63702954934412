<template>
  <div>
    <PageHeader :title="title">></PageHeader>

    <!-- LearningStatSummary -->
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <div class="inner bg-container">
          <b-card no-body class="no-shadow">
            <b-tabs  v-model="tabIndex"  card>
              <b-tab title="โปรโมชั่น" @click="title = 'โปรโมชั่น';tabIndexPromotion(0)">
                <PromotionStat />
              </b-tab>
              <b-tab title="กลุ่มส่วนลด" @click="title = 'กลุ่มส่วนลด';tabIndexPromotion(1)">
                <PromotionGroupStat />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import PromotionStat from "./panel/PromotionStat.vue";
import PromotionGroupStat from "./panel/PromotionGroupStat.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PageHeader,
    PromotionStat,
    PromotionGroupStat,
  },

  data() {
    return {
      title: "โปรโมชั่น",
    };
  },

  computed: {
    ...mapGetters("CourseManagement", ["tabIndex"]),
  },
  created() {},

  methods: {
     ...mapActions("CourseManagement", [
      "setTabIndexPromotion",
    ]),


    tabIndexPromotion(tabIndex){
      this.setTabIndexPromotion(tabIndex);
    }



  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}
.h6 {
  margin-bottom: 0 !important;
}
.map-row {
  padding-top: 2rem !important;
}
.widthPage {
  max-width: 81.25rem;
}
</style>

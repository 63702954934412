<template>
  <div>
    <b-row>
      <b-col class="col-12 mb-2 mt-4">
        <div class="d-flex align-items-center justify-content-between">
          <div>ผลการทำข้อสอบ</div>
          <div class="font-small">
            <a
              :href="`/${company.url}/analytics/exams/member/${memberId}/exam/${examId}/exam-result`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </div>
        </div>
      </b-col>
      <b-col class="col-12 mt-1">
        <b-table
          table-class="tbNumber text-left"
          style="overflow-x: auto"
          :items="items"
          :fields="fields"
          class="text-truncate border"
          small
          sort-icon-left
          responsive="true"
          :busy="isLoading"
          hover
          striped
        >
          <template v-slot:cell(no)="data">
            <span>{{ data.item.no }}</span>
          </template>
          <template v-slot:cell(examDate)="data">
            <span>{{ data.item.createdAt | thaiDateFilterTimestampUTC }}</span>
          </template>
          <template v-slot:cell(totalTimeUsed)="data">
            <span>{{ data.item.totalTimeUsed != null
                ? getTotalTimeUsed(data.item.totalTimeUsed.toFixed(2))
                : getTotalTimeUsed(
                    calculateTimeUsed(data.item.sections).toFixed(2)
                  ) }}</span>
          </template>
          <template v-slot:cell(score)="data">
            <span>{{ data.item.overallResult.score }}</span>
          </template>
          <template v-slot:cell(percent)="data">
            <span>{{ formatNumber((data.item.overallResult.correct * 100) / data.item.overallResult.fullScore ) }} %</span>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import totalTimeUsedMixin from "@/components/pages/analytics/exam/mixins/totalTimeUsed.js";
import dateMixin from "@/components/pages/analytics/shared/mixins/date.js";

export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  props: {
    company: Object,
    examId: String,
    memberId: String,
    totalAnswerSheets: Array,
  },
  data() {
    return {
      fields: [
        { key: "no", label: "ลำดับ" },
        { key: "examDate", label: "วันที่ทำข้อสอบ" },
        { key: "totalTimeUsed", label: "เวลาที่ใช้ทำข้อสอบ" },
        { key: "score", label: "คะแนนที่ได้" },
        { key: "percent", label: "คิดเป็น %" },
      ],
      items: [],
      isLoading: false,
    };
  },
  watch: {
    totalAnswerSheets() {
      this.updateTable();
    },
  },
  methods: {
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    updateTable() {
      this.isLoading = true;
      this.items = this.totalAnswerSheets.slice(0, 10);
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].no = i + 1;
      }
      this.isLoading = false;
    },
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },
  },
};
</script>

<style scoped>
.font-small {
  font-size: 0.8rem;
}
</style>

<template>
  <b-card-group>
    <b-card>
      <div slot="header">
        <i class="fas fa-chart-bar" aria-hidden="true" /> ผลคะแนนล่าสุด
      </div>
      <div>
        <div class="card_block_top_align">
          <div>
            <b-row align-h="end">
              <b-col cols="4">
                <form class="search-box-form" @submit.prevent="filterTable">
                  <div :class="`${$options.name}__body__search-box`">
                    <b-form-input
                      v-model="search"
                      append-icon="search"
                      hide-details
                      class="text-field-form"
                      align="right"
                      placeholder="ชื่อข้อสอบ"
                    />
                    <b-button variant="outline-primary" @click="filterTable">
                      ค้นหา
                    </b-button>
                  </div>
                </form>
              </b-col>
            </b-row>

            <b-table
              :items="dataset"
              :fields="fields"
              :current-page="currentPage"
              :busy="examAnswerSheetsPending || companyAnswersheetsCountPending"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              sort-icon-left
            >
              <template v-slot:cell(info)="props">
                <b-button
                  variant="primary"
                  @click="toExamTakerDetails(props.item._id)"
                >
                  ดูรายละเอียด
                </b-button>
              </template>
              <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>กำลังโหลด...</strong>
              </div>
            </b-table>
            <b-row>
              <b-col md="2" class="my-1">
                <b-form class="mb-2" inline>
                  <label
                    class="mb-2 mr-sm-2 mb-sm-0"
                    for="inline-form-input-name"
                    >แสดง</label
                  >
                  <b-form-select
                    v-model="perPage"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    :options="pageOptions"
                  ></b-form-select>
                  จาก{{ totalItems }}
                </b-form>
              </b-col>
              <b-col md="10" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  align="right"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { map, isEqual } from "lodash";
export default {
  name: "ExamOverallExamAnswerSheets",
  data() {
    return {
      totalItems: 0,
      search: "",
      fields: [
        { label: "ชื่อผู้สอบ", key: "metadata.memberName" },
        { label: "ชื่อข้อสอบ", key: "exam.title", sortable: true },
        { label: "วันที่สอบ", key: "markedAt", sortable: true },
        { label: "คะแนน", key: "overallResult.score", sortable: true },
        { label: "รายละเอียด", key: "info" }
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sort: {
        by: "markedAt",
        desc: true
      }
    };
  },
  computed: {
    ...mapGetters("Company", [
      "companyInfo",
      "examAnswerSheetsData",
      "examAnswerSheetsPending",
      "companyAnswersheetsCountData",
      "companyAnswersheetsCountPending"
    ]),
    dataset() {
      return map(this.examAnswerSheetsData, ech => {
        let date = moment(ech.markedAt);
        let markedAt = date.format("LL");
        let name = ech.metadata.memberName;
        return {
          ...ech,
          markedAt,
          examTakerName: name
        };
      });
    }
  },
  watch: {
    sort: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true
    },
    currentPage: {
      handler(oldVal, newVal) {
        if (isEqual(oldVal, newVal)) return;
        this.getDataFromApi();
      },
      deep: true
    },
    perPage: {
      handler(oldVal, newVal) {
        if (isEqual(oldVal, newVal)) return;
        this.getDataFromApi();
      },
      deep: true
    }
  },
  mounted() {
    moment.locale("th");
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("Company", [
      "fetchAnswersheets",
      "fetchAnswersheetsCountByCompanyId"
    ]),
    async getDataFromApi() {
      this.loading = true;
      let pageRequest = {
        page: this.currentPage,
        rowsPerPage: this.perPage,
        sortBy: this.sort.by,
        descending: this.sort.desc
      };
      await this.fetchAnswersheets({
        companyId: this.companyInfo.id,
        pagination: pageRequest
      });
      await this.fetchAnswersheetsCountByCompanyId({
        companyId: this.companyInfo.id
      });
      this.loading = false;
      this.totalItems = this.companyAnswersheetsCountData;
    },
    filterTable() {
      let pageRequest = { page: this.currentPage, rowsPerPage: this.perPage };
      this.fetchAnswersheets({
        companyId: this.companyInfo.id,
        pagination: pageRequest,
        ...(this.search.length > 0 && { examTitle: this.search })
      });
    },
    toExamTakerDetails(answersheetId) {
      this.$router.push({
        name: "analytics-exam-taker-details",
        params: {
          id: answersheetId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamOverallExamAnswerSheets {
  &__body {
    &__search-box {
      display: flex;
      align-self: flex-end;
      width: 70%;
    }
    &__search-field {
      flex: 0 1 20%;
      margin: 0;
      padding: 0;
    }
  }
}
.search-box-form {
  display: flex;
  flex-direction: row-reverse;
}
.text-field-form {
  margin-right: 0.5em;
}
</style>

<template>
  <div id="ceritgicate-overview">
    <PageHeader
      title="ภาพรวมประกาศนียบัตร"
      style="margin-left: -10%"
    ></PageHeader>

    <template v-if="isBusy">
      <div class="text-center text-primary m-5">
        <b-spinner class="align-middle"></b-spinner>
        <br />
        <p class="mt-2">Loading...</p>
      </div>
    </template>
    <b-container v-else class="widthPage mt-5 mb-5">
      <div class="d-flex align-items-end py-3">
        <h5 class="pr-2">
          <router-link :to="`/${company.url}/analytics/certificate/overview`"
            >คอร์สทั้งหมด
          </router-link>
        </h5>
        /
        <h6 class="pl-2">{{ courseInfo.courseName }}</h6>
      </div>

      <div>
        <b-card-img-lazy
          v-if="courseInfo.courseThumbnail"
          :src="courseInfo.courseThumbnail"
          alt=""
          top
          style="width: 150px; margin: 10px 10px 10px 20px"
        ></b-card-img-lazy>
        <h5
          class="nav_top_align text-dark font-weight-bold"
          style="display: inline"
        >
          {{ courseInfo.courseName }}
        </h5>
      </div>

      <div class>
        <div class="mb-3">
          <b-row>
            <b-col cols="10" style="display: inline-block">
              <b-form-input
                v-model="search"
                placeholder="ค้นหาผู้เรียน"
                style="padding: 1rem; display: inline-block; width: 20%"
              >
              </b-form-input>
              <div
                style="display: inline-block; width: 30%; position: relative"
              >
                <b-input-group>
                  <b-form-input
                    class="ml-3"
                    style="display: inline-block; width: 20%"
                    placeholder="ค้นหาวันที่ออกใบประกาศ"
                    :value="showRange"
                    disabled
                  />
                  <b-form-group-prepend>
                    <b-button variant="primary" @click="showCalendarRange()">
                      <b-icon icon="calendar-range"></b-icon>
                    </b-button>
                  </b-form-group-prepend>
                </b-input-group>
                <DatePicker
                  ref="calendar"
                  id="calendar-range"
                  style="
                    display: none;
                    float: right;
                    position: absolute;
                    will-change: transform;
                    left: 0px;
                    z-index: 100;
                  "
                >
                  <template #default>
                    <div
                      style="
                        padding: 10px 0 5px;
                        background: white;
                        width: 674px;
                        border-style: solid solid none solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="formatStartDate"
                        >
                        </b-form-input>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="formatEndDate"
                        >
                        </b-form-input>
                        <b-button
                          style="margin-right: 0.5rem"
                          size="sm"
                          variant="primary"
                          @click="applyCalendarRange()"
                        >
                          Apply
                        </b-button>
                        <b-button
                          style="margin-right: 1rem"
                          size="sm"
                          variant="light"
                          @click="showCalendarRange()"
                        >
                          Cancel
                        </b-button>
                      </b-input-group>
                    </div>
                    <div
                      style="
                        background: white;
                        padding-left: 10px;
                        padding-bottom: 10px;
                        border-style: none solid solid solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <DatePicker
                        v-model="range"
                        :columns="$screens({ default: 1, sm: 2 })"
                        is-range
                      />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div>
        <b-table
          id="certificate-overview-table"
          :per-page="perPage"
          :current-page="currentPage"
          class="text-left table mt-1 table-certificate"
          :items="
            search == null
              ? result.data
              : result.data.filter((i) =>
                  (i.instructorfirstName + i.instructorlastName).toLowerCase().replace(/ /g, '').includes(search.toLowerCase().replace(/ /g, ''))
                )
          "
          :fields="fields"
          small
          responsive
          hover
          :busy="isLoading"
        >
          <template slot="table-busy" class="text-center text-danger my-2">
            <Loading />
          </template>
          <template v-slot:cell(owner)="data">
            <span>{{
              (data.item.instructorfirstName +
                " " +
                data.item.instructorlastName)
                | cut(10)
            }}</span>
          </template>
          <template v-slot:cell(registerday)="data">
            <template>
              <span>{{
                data.item.enrollstartDate | thaiDateFilter
              }}</span></template
            >
          </template>
          <template v-slot:cell(expirationDate)="data">
            <template>
              <span>{{
                data.item.certificateIssueExpireDate | thaiDateFilter
              }}</span></template
            >
          </template>

          <template v-slot:cell(timeSpent)="data">
            <template>
              <span>{{
                data.item.learningTime
                  ? data.item.learningTime + " ชั่วโมง"
                  : "00:00:00 ชั่วโมง"
              }}</span></template
            >
          </template>

          <template v-slot:cell(certificateIssueDate)="data">
            <template>
              <span>{{
                data.item.certificateIssueDate | thaiDateTimeFilter
              }}</span></template
            >
          </template>

          <template v-slot:cell(btn)="data">
            <!-- multiple insurance certificate  -->
            <b-dropdown
              v-if="data.item.insurance.length == 2"
              id="dropdown-certificate"
              text="ดาวน์โหลด"
              variant="outline-primary"
              size="sm"
              class="h5 text-center"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <b-icon
                    icon="download"
                    variant="primary"
                    class="h6 text-center download-icon-style"
                  ></b-icon>
                  <span class="ml-2">ดาวน์โหลด</span>
                </div>
              </template>
              <b-dropdown-item @click="downloadIconClicked(data.item.memberId)">
                ดาวน์โหลดทั้งหมด
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  downloadIconClicked(
                    data.item.memberId,
                    data.item.insurance[0].certificateIssueId
                  )
                "
              >
                {{
                  data.item.insurance[0].insuranceType == "LIFE_AGENT" ||
                  data.item.insurance[0].insuranceType == "LIFE_BROKER"
                    ? "นายหน้าประกันชัวิต"
                    : "นายหน้าประกันวินาศภัย"
                }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  downloadIconClicked(
                    data.item.memberId,
                    data.item.insurance[1].certificateIssueId
                  )
                "
              >
                {{
                  data.item.insurance[1].insuranceType == "LIFE_AGENT" ||
                  data.item.insurance[1].insuranceType == "LIFE_BROKER"
                    ? "นายหน้าประกันชัวิต"
                    : "นายหน้าประกันวินาศภัย"
                }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- multiple certificate  -->
            <b-dropdown
              v-if="data.item.accounting.length > 1"
              id="dropdown-certificate"
              text="ดาวน์โหลด"
              variant="outline-primary"
              size="sm"
              class="h5 text-center"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <b-icon
                    icon="download"
                    variant="primary"
                    class="h6 text-center download-icon-style"
                  ></b-icon>
                  <span class="ml-2">ดาวน์โหลด</span>
                </div>
              </template>

              <b-dropdown-item @click="downloadIconClicked(data.item.memberId)">
                ดาวน์โหลดทั้งหมด
              </b-dropdown-item>

              <!-- Loop through accounting items -->
              <b-dropdown-item
                v-for="(accountingItem, index) in data.item.accounting"
                :key="index"
                @click="downloadIconClicked(data.item.memberId, accountingItem.certificateIssueId)"
              >
                {{
                  getCertificateType(accountingItem.certificateIssueType)
                }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- certificate default or single insurance certificate life or nonlife -->
            <b-button
              v-else
              size="sm"
              variant="outline-primary"
              class="h5 text-center"
              @click="
                downloadIconClicked(
                  data.item.memberId,
                  data.item.certificateIssueId
                )
              "
            >
              <b-icon
                icon="download"
                variant="primary"
                class="h6 text-center download-icon-style"
              />
              <span class="ml-2">ดาวน์โหลด</span>
            </b-button>
          </template>
        </b-table>
        <div style="text-align: center" v-if="getLengthofItem() < 1">
          {{ "ไม่มีข้อมูล" }}
        </div>
        <b-pagination
          v-if="getLengthofItem() > 10"
          v-model="currentPage"
          :total-rows="getLengthofItem()"
          :per-page="perPage"
          aria-controls="certificate-overview-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import Loading from "@/components/shared/Loading";
import { openURL } from "@/components/shared/NewTabLink.js";
export default {
  components: {
    PageHeader,
    DatePicker,
    Loading,
  },

  mixins: [dateMixins, timeMixins],
  data() {
    return {
      search: null,
      cId: this.$route.params.cId,
      courseInfo: [],
      company: null,
      fields: [
        {
          key: "owner",
          label: "ผู้เรียน",
          thClass: "hello",
          thStyle: "width:250px",
        },
        {
          key: "registerday",
          label: "วันที่ลงทะเบียน",
          thClass: "hello",
          thStyle: "width:200px",
        },
        {
          key: "expirationDate",
          label: "วันที่หมดอายุ",
          thClass: "hello",
          thStyle: "width:200px",
        },
        {
          key: "timeSpent",
          label: "เวลาที่ใช้เรียน",
          thClass: "hello",
          thStyle:"width:200px"
        },
        {
          key: "certificateIssueDate",
          label: "วันที่ออกใบประกาศ",
          thStyle: { backgroundPosition: "right calc(12.3rem / 2) center" },
          sortable: true,
        },
        {
          key: "btn",
          label: "ดาวน์โหลด",
          thClass: "hello",
        },
      ],
      rows: 0,
      isLoading: false,
      isBusy: true,
      result: [],
      currentPage: 1,
      perPage: 10,
      range: {
        start: null,
        end: null,
      },
    };
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
      }
    }
  },
  computed: {
    formatStartDate() {
      if (this.range.start != null) {
        return moment(this.range.start).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },

    formatEndDate() {
      if (this.range.end != null) {
        return moment(this.range.end).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },

    showRange() {
      if (this.range.start != null && this.range.end != null) {
        return (
          moment(this.range.start).add(543, "year").format("DD MMM YYYY") +
          " - " +
          moment(this.range.end).add(543, "year").format("DD MMM YYYY")
        );
      } else {
        return null;
      }
    },
  },

  created() {
    this.company = this.$store.state.Company.companyInfo;
  },

  async mounted() {
    moment.locale("th");
    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data;
    });

    this.isBusy = true;
    let res = await api.get(
      "/v1/courses/" +
        this.courseInfo.data.courseId +
        "/course-certificate-details"
    );
    this.result = res.data;
    this.rows = this.result.data.length;
    this.currentPage = 1;
    this.courseInfo = this.courseInfo.data;
    this.isBusy = false;
  },

  methods: {
    getLengthofItem() {
      if (this.search) {
        var itemLengt = this.result.data.filter((i) =>
          i.instructorfirstName.includes(this.search)
        );
        return itemLengt.length;
      } else if (this.result.data != undefined) {
        return Object.keys(this.result.data).length;
      }
    },

    async downloadIconClicked(id, certisId = "") {
      try {
        let results = await api.get(
          `/v1/members/${id}/course/${this.cId}/certificate?certisId=${certisId}`
        );
        let link = "";
        if (results.data.length > 1) {
          for (let i = 0; i < results.data.length; i++) {
            if (this.company.whitelabelUrl)
              link = this.company.whitelabelUrl + results.data[i].link;
            else link = results.data[i].domain + results.data[i].link;
            openURL(link)
          }
        } else {
          if (this.company.whitelabelUrl)
            link = this.company.whitelabelUrl + results.data.link;
          else link = results.data.domain + results.data.link;
          openURL(link)
        }
      } catch (err) {
        console.log("downloadIconClicked error", err);
      }
    },

    async showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
        if (this.range.start != null && this.range.end != null) {
          this.isLoading = true;
          await api.get("/v1/courses/" + this.cId).then(({ data }) => {
            this.courseInfo = data;
          });
          this.range.start = null;
          this.range.end = null;
          let res = await api.get(
            "/v1/courses/" +
              this.courseInfo.data.courseId +
              "/course-certificate-details?startDate=&endDate="
          );
          this.result = res.data;
          this.rows = this.result.data.length;
          this.currentPage = 1;
          this.courseInfo = this.courseInfo.data;
          this.isLoading = false;
        }
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },

    async applyCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (this.range.start != null && this.range.end != null) {
        rangeCalendar.style.display = "none";
        this.isLoading = true;
        await api.get("/v1/courses/" + this.cId).then(({ data }) => {
          this.courseInfo = data;
        });
        let res = await api.get(
          "/v1/courses/" +
            this.courseInfo.data.courseId +
            "/course-certificate-details?startDate=" +
            moment(this.range.start).format("YYYY-MM-DD") +
            " 00:00:00" +
            "&endDate=" +
            moment(this.range.end).format("YYYY-MM-DD") +
            " 23:59:59"
        );
        this.result = res.data;
        this.rows = this.result.data.length;
        this.currentPage = 1;
        this.courseInfo = this.courseInfo.data;
        this.isLoading = false;
      } else {
        rangeCalendar.style.display = "none";
      }
    },

    getCertificateType(type) {
      switch (type) {
        case 'CPD_COMPLETED':
          return 'CPD';
        case 'CPA_COMPLETED':
          return 'CPA';
        case 'COMPLETED':
          return 'COMPLETED';
        default:
          return 'Unknown';
      }
    },
  },
};
</script>

<style>
#ceritgicate-overview h5,
#ceritgicate-overview h6 {
  margin: 0px;
}
#ceritgicate-overview .card-body {
  padding: 1rem !important;
}

#ceritgicate-overview .h6 {
  margin-bottom: 0 !important;
}
#ceritgicate-overview .map-row {
  padding-top: 2rem !important;
}
#ceritgicate-overview .widthPage {
  max-width: 81.25rem;
}
#ceritgicate-overview .download-icon-style {
  cursor: pointer;
}
#ceritgicate-overview .input-group-text.icon {
  width: 40px;
  height: auto;
  border-right: none;
  background-color: #ffffff;
  margin-left: 10px;
}
#ceritgicate-overview .inputDate {
  border-left: none;
  position: relative;
}

#dropdown-certificate .dropdown-toggle::after {
  display: none;
}
#dropdown-certificate:hover .download-icon-style {
  filter: brightness(0) invert(1);
}
#dropdown-certificate .btn-outline-primary {
  border: 1px solid #007bff !important;
}
</style>

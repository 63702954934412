
import CoursePromotionList from '@/components/pages/purchase/CoursePromotionList.vue'
import CourseOrderList from '@/components/pages/purchase/CourseOrderList.vue'
import CourseTransferMoneyList from '@/components/pages/purchase/CourseTransferMoneyList.vue'
import RegisterCash from '@/components/pages/purchase/RegisterCash.vue'
import PromotionGroupTagAdd from '@/components/pages/purchase/panel/CoursePromotionGroupTagAdd.vue'
import PromotionGroupTagEdit from '@/components/pages/purchase/panel/CoursePromotionGroupTagEdit.vue'
import CourseCouponList from '@/components/pages/purchase/CourseCouponList.vue'
import Post from '@/components/pages/purchase/Post.vue'

export default [

  {
    path: 'purchase/course-promotion-list',
    name: 'purchase.course.promotion.list',
    component: CoursePromotionList
  },
  {
    path: 'purchase/course-order-list',
    name: 'purchase.course.order.list',
    component: CourseOrderList
  },
  {
    path: "purchase/course-coupon-list",
    name: "purchase.course.coupon.list",
    component: CourseCouponList
  },
  {
    path: 'purchase/register-cash',
    name: 'purchase.register.cash',
    component: RegisterCash
  },
  {
    path: 'purchase/course-transfer-money-list',
    name: 'purchase.course-transfer-money-list',
    component: CourseTransferMoneyList
  },
  {
    path: 'purchase/post',
    name: 'purchase.post',
    component: Post,
    meta: {
      template: "A",
    }
  },

  {
    path: "purchase/promotion-group/add",
    name: "purchase.promotion-group.add",
    component: PromotionGroupTagAdd,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "purchase/promotion-group/:promotionId/edit",
    name: "purchase.promotion-group.edit",
    component: PromotionGroupTagEdit,
    meta: {
      authRequired: true,
    },
  },
  
]
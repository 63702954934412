<template>
  <div>
    <b-row>
      <b-col md="6" lg="4">
        <SearchBox
          :title="`พิมพ์ชื่อคอร์สเรียนที่ต้องการค้นหา`"
          @typed="getSearchKeyword"
        />
      </b-col>
    </b-row>

    <b-table
      :fields="fields"
      :items="courseList.data"
      :busy="isLoading"
      :sort-by.sync="sort.by"
      :sort-desc.sync="sort.desc"
      :per-page="perPage"
      sort-icon-left
      responsive
      table-class="mt-4 border-bottom"
    >
      <template v-slot:cell(image)="props">
        <b-img-lazy
          slot="aside"
          v-bind="imageProps"
          :src="props.item.courseThumbnail"
          class="course-image"
          alt
        ></b-img-lazy>
      </template>
      <template v-slot:cell(name)="props">{{ props.item.courseName }}</template>
      <template v-slot:cell(selection)="{ item }">
        <ToggleSelectionButton
          item-key="courses"
          :item-value="{
            id: item.courseId,
            name: item.courseName,
            type: 'คอร์สเรียน'
          }"
        />
      </template>
      <div slot="table-busy" class="text-center text-danger my-2">
        <Loading />
      </div>
    </b-table>

    <Pagination
      :items="courseList"
      :limits="limitOptions"
      @changePage="setFetchParamsPage"
      @changeLimit="setFetchParamsLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CourseEnrollmentMixin from "@/mixins/course-enrollment-mixin";
import SearchBox from "@/components/shared/SearchBox";
import Pagination from "@/components/shared/Pagination";
import Loading from "@/components/shared/Loading";
import ToggleSelectionButton from "./ToggleSelectionButton";

export default {
  name: "SelectCoursesTable",
  components: { SearchBox, Pagination, Loading, ToggleSelectionButton },
  mixins: [CourseEnrollmentMixin],
  computed: {
    ...mapGetters("Company", ["companyInfo"]),
    ...mapGetters("CourseManagement", ["courseList", "isLoading"]),
    fields() {
      return [
        ...this.tableFields,
        {
          label: "",
          key: "selection",
          sortable: false,
          thStyle: "width: 70px",
          perPage: 10,
          currentPage: 1,
        }
      ];
    }
  },
  async mounted() {
    this.fetchParams.company_id = this.companyInfo.id;
    await this.fetchCourseListEnrollment(this.fetchParams);
  },
  methods: {
    ...mapActions("CourseManagement", ["fetchCourseListEnrollment"]),
    
    async getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchParams.page = this.currentPage = 1;
      this.fetchCourseListEnrollment(this.fetchParams);
    },

    async setFetchParamsPage(page) {
      this.fetchParams.page = page;
      await this.fetchCourseListEnrollment(this.fetchParams);
    },

    async setFetchParamsLimit(limit) {
      this.fetchParams.limit = limit;
      await this.fetchCourseListEnrollment(this.fetchParams);
    }
  }
};
</script>

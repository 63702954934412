import Wrapper from "@/components/pages/Wrapper.vue";
import ExamsOverall from "@/components/pages/exam-analytics/exams-overall/ExamsOverall.vue";
import AnswerSheetList from "@/components/pages/exam-analytics/answer-sheet-list/AnswerSheetList.vue";
import ExamInsight from "@/components/pages/exam-analytics/exam-insight/ExamInsight.vue";
import ExamsDetails from "@/components/pages/exam-analytics/exams-details/ExamsDetails.vue";
import ExamTakerDetails from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerDetails.vue";

export default [
  {
    path: "/:company/exam-analytics",
    component: Wrapper,
    meta: {
      authRequired: true
    },
    children: [
      {
        name: "analytics-overall-exams",
        path: "overall-exam",
        component: ExamsOverall
      },
      {
        name: "analytics-answer-sheet-list",
        path: "answer-sheets",
        component: AnswerSheetList
      },
      {
        name: "analytics-exam-insight",
        path: "exam-insight/:examId",
        component: ExamInsight,
        props: true
      },
      {
        name: "analytics-exams-details",
        path: "exams-details",
        component: ExamsDetails
      },
      {
        name: "analytics-exam-taker-details",
        path: "exam-taker-details/:id",
        props: true,
        component: ExamTakerDetails
      }
    ]
  }
];

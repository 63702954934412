<template>
  <form @submit.prevent="update">
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">ข้อมูลผู้เรียน</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>คำนำหน้า</label>
            <v-select
              :options="prefixList"
              v-model="prefixName"
              @input="edit({ name: 'prefixName', value: prefixName })"
              :clearable="false"
            />
          </div>
          <div class="form-group col-md-4">
            <label>ชื่อ</label>
            <FormInputText
              :name="'firstName'"
              :type="`text`"
              :value="member.firstName"
              :placeholder="`ชื่อ`"
              :errorText="'กรุณากรอกชื่อ'"
              :is-editable="true"
              @edited="edit"
            ></FormInputText>
          </div>
          <div class="form-group col-md-4">
            <label>นามสกุล</label>
            <FormInputText
              :name="'lastName'"
              :type="`text`"
              :value="member.lastName"
              :placeholder="`นามสกุล`"
              :is-editable="true"
              :errorText="'กรุณากรอกนามสกุล'"
              @edited="edit"
            ></FormInputText>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>เลขประจำตัวประชาชน</label>
            <FormInputText
              :name="'idCard'"
              :type="`text`"
              :value="member.idCard"
              :placeholder="`เลขประจำตัวประชาชน`"
              :errorText="'กรุณากรอกเลขประจำตัวประชาชน'"              
              :errors="errors.idCard != null ? 'กรุณากรอกเลขประจำตัวประชาชน 13 หลัก' : ''"
              :is-editable="true"
              @edited="edit"
            ></FormInputText>
          </div>
          <div class="form-group col-md-4">
            <label>วันหมดอายุบัตรประชาชน</label>
            <FormInputText
              :name="'idCardExpireDate'"
              :type="`date`"
              :value="member.idCardExpireDate"
              :placeholder="`dd/mm/yyyy`"
              :is-editable="true"
              :errorText="'กรุณากรอกวันหมดอายุบัตรประชาชน'"
              @edited="edit"
            ></FormInputText>
            <b-form-checkbox
              name="isNotExpired"
              v-model="isNotExpired"
              @change="checkedNotExpired"
            >
              บัตรประชาชนตลอดชีพ
            </b-form-checkbox>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>วันเดือนปีเกิด</label>
            <FormInputText
              :name="'dateOfBirth'"
              :type="`date`"
              :value="member.dateOfBirth"
              :placeholder="`dd/mm/yyyy`"
              :is-editable="true"
              :errorText="'กรุณากรอกวันเดือนปีเกิด'"
              @edited="edit"
            ></FormInputText>
          </div>
          <div class="form-group col-md-4">
            <label>เบอร์โทรศัพท์</label>
            <FormInputText
              :name="'phoneNumber'"
              :type="`text`"
              :value="member.phoneNumber"
              :placeholder="`0999999999`"
              :is-editable="true"
              :errorText="'กรุณากรอกเบอร์โทรศัพท์'"
              :errors="errors.phoneNumber != null ? 'กรุณากรอกเบอร์โทรศัพท์' : ''"
              @edited="edit"
            ></FormInputText>
          </div>
        </div>
        <b-row>
          <b-col>
            <TextareaForm
              name="about"
              :value="member.about"
              :is-editable="true"
              label="เกี่ยวกับผู้เรียน"
              class="mb-3"
              placeholder="เกี่ยวกับผู้เรียน"
              @edited="edit"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="!isTutorType()" class="card border-0 bg-light mb-3 no-shadow">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">ข้อมูลพนักงาน</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>รหัสพนักงาน</label>
            <FormInputText
              :name="'staffId'"
              :type="`text`"
              :value="member.staffId"
              :placeholder="`รหัสพนักงาน`"
              :is-editable="true"
              @edited="edit"
            ></FormInputText>
          </div>

          <div class="form-group col-md-8">
            <label>แผนก</label>
            <FormInputText
              :name="'department'"
              :type="`text`"
              :value="member.department"
              :placeholder="`แผนก`"
              :is-editable="true"
              @edited="edit"
            ></FormInputText>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 bg-light mb-3 no-shadow">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">ข้อมูลที่อยู่</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <div class="form-group col-md-12">
            <TextareaForm
              name="address"
              :value="member.address"
              :is-editable="true"
              class="mb-3"
              label="ที่อยู่ (บ้านเลขที่ หมู่บ้าน ซอย ถนน)"
              placeholder="ที่อยู่"
              @edited="edit"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 bg-light mb-3 no-shadow">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title">ข้อมูลผู้ใช้งาน</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>อีเมล</label>
            <FormInputText
              :name="'email'"
              :type="`email`"
              :value="member.email"
              :placeholder="`อีเมล`"
              :is-editable="true"
              :errorText="'กรุณากรอกอีเมล'"
              :errors="emailErrorMessage"
              @edited="edit"
            ></FormInputText>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label>ชื่อผู้ใช้งาน</label>
            <FormInputText
              :name="'username'"
              :type="`text`"
              :value="member.username"
              :placeholder="`ชื่อผู้ใช้`"
              :is-editable="true"
              :errorText="'กรุณากรอกชื่อผู้ใช้งาน'"
              :errors="usernameErrorMessage"
              @edited="edit"
            ></FormInputText>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 bg-light mb-3 no-shadow">
      <div class="card-header border-0 bg-transparent">
        <h5 v-b-toggle.collapse-change-password class="card-title">
          เปลี่ยนรหัสผ่าน
          <b-icon-chevron-compact-down class="float-right" />
        </h5>
      </div>
      <b-collapse id="collapse-change-password">
        <div class="card-body pb-0">
          <change-password-form
            :errors="errors"
            @passwordConfirmed="changePassword"
          ></change-password-form>
        </div>
      </b-collapse>
    </div>
    <!-- อัปโหลดรูปบัตรประชาชน -->
    <b-card
      v-if="false"
      title="อัปโหลดรูปบัตรประชาชน"
      title-tag="h5"
      class="bg-light border-0 no-shadow pt-3"
    >
      <b-form-group
        class="mb-0"
        label="อัปโหลดรูปหน้าตรงไม่ใส่เครื่องประดับใบหน้า(ยกเว้นแว่นสายตา) เพื่อยืนยันตัวตน"
        label-for="face-image"
        :description="faceImageDescription"
        :state="faceImageUploadState"
        valid-feedback="อัปโหลดไฟล์สำเร็จ"
        invalid-feedback="อัปโหลดไฟล์ไม่สำเร็จ"
      >
        <b-form-file
          id="face-image"
          v-model="faceImage"
          plain
          @input="doUpload"
        ></b-form-file>
      </b-form-group>
      <div v-if="faceImages.length > 0">
        <b-card-group columns class="mt-3">
          <b-card
            v-for="faceImage in faceImages"
            :key="faceImage"
            img-top
            :img-src="
              `https://facefacto-20200804223500.coursesquare.com/register${faceImage}`
            "
            no-body
          >
          </b-card>
        </b-card-group>
        <b-button
          variant="outline-danger"
          size="sm"
          @click="confirmResetFaceImages"
        >
          ลบรูปทั้งหมด
        </b-button>
      </div>
    </b-card>
  </form>
</template>

<script>
import FormInputText from "./FormInputText";
import ChangePasswordForm from "./ChangePasswordForm";
import TextareaForm from "./TextareaForm";
import { mapActions } from "vuex";
import faceRecognitionService from "@/services/face-recognition-service";
import authMixin from "@/mixins/auth-mixin.js";
import moment from "moment";
import vSelect from "vue-select";
import { prefixNameList } from "@/helpers/prefix-name";

export default {
  components: { FormInputText, ChangePasswordForm, TextareaForm, vSelect },
  props: {
    member: { type: Object, default: null },
    errors: { type: Object, default: null },
  },
  mixins: [authMixin],
  data() {
    return {
      memberId: this.$route.params.id,
      temp: [],
      faceImage: null,
      faceImageDescription: null,
      faceImageUploadState: null,
      faceImages: [],
      userFaceUploadingStatus: false,
      faceFrontImage: null,
      faceLeftImage: null,
      faceRightImage: null,
      faceUpImage: null,
      faceDownImage: null,
      isNotExpired: false,
      prefixName: "",
      prefixList: [],
    };
  },

  computed: {
    getUserFaceUploadingStatus() {
      if (this.userFaceUploadingStatus) {
        return true;
      } else {
        if (
          this.faceFrontImage &&
          this.faceLeftImage &&
          this.faceRightImage &&
          this.faceUpImage &&
          this.faceDownImage
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    emailErrorMessage() {
      if (this.errors.email != null) {
        if (this.errors.email[0].includes('booked')) {
          return 'อีเมล นี้ถูกจองไว้แล้ว กรุณาติดต่อผู้ดูแลระบบเพื่อสอบถามข้อมูลเพิ่มเติม';
        }
        if (this.errors.email[0].includes('registered')) {
          return 'อีเมลนี้ถูกใช้ไปแล้ว กรุณาใช้อีเมลอื่น';
        }
        if (this.errors.email[0].includes('required')) {
          return 'กรุณากรอกอีเมล';
        }
        return 'กรุณากรอกรูปแบบอีเมลให้ถูกต้อง ตัวอย่าง example@mail.com';
      }
      return '';
    },
    usernameErrorMessage() {
      if (this.errors.username != null) {
        if (this.errors.username[0].includes('booked')) {
          return 'ชื่อผู้ใช้งาน นี้ถูกจองไว้แล้ว กรุณาติดต่อผู้ดูแลระบบเพื่อสอบถามข้อมูลเพิ่มเติม';
        }
        if (this.errors.username[0].includes('registered')) {
          return 'ชื่อผู้ใช้งานนี้ถูกใช้ไปแล้ว กรุณาใช้อีเมลอื่น';
        }
        return 'กรุณากรอกชื่อผู้ใช้งาน';
      }
      return '';
    },
  },
  watch: {
    "member.idCardExpireDate"(newVal) {
      if (newVal) {
        this.isNotExpired = newVal === "9999-12-31";
      }
    },
    "member.prefixName"(newVal) {
      newVal ? (this.prefixName = newVal) : (this.prefixName = "-");
    },
  },
  mounted() {
    this.temp = this.member;
    this.getFaceImages();
    this.prefixList = prefixNameList();
  },

  methods: {
    ...mapActions("MemberManagement", [
      "uploadFaceImage",
      "uploadFaceImageSuccess",
    ]),
    checkedNotExpired() {
      if (this.isNotExpired) {
        this.member["idCardExpireDate"] = "9999-12-31";
      } else {
        this.member["idCardExpireDate"] = moment().format("YYYY-MM-DD");
      }
      this.patch({ name: 'idCardExpireDate', value: this.member["idCardExpireDate"] });
    },
    edit(input) {
      this.member[input.name] = input.value;
      this.errors[input.name] = null;
      this.patch(input);
    },

    changePassword(data) {
      this.$emit("passwordChanged", data);
    },

    patch(input) {
      this.$emit("patched", input);
      this.isUpdated = true;
    },

    async doUpload() {
      if (!this.faceImage) return false;

      this.faceImageDescription = "กำลังอัปโหลด..";
      this.faceImageUploadState = null;
      const response = await this.uploadFaceImage({
        memberId: this.memberId,
        imageFile: this.faceImage,
      });

      if (response.status === 200) {
        this.faceImage = null;
        this.faceImageDescription = null;
        this.faceImageUploadState = true;
        this.getFaceImages();
      }
    },

    getFaceImages() {
      faceRecognitionService
        .getRegisterFaceImages(this.memberId)
        .then(({ data }) => {
          if (data.message === "Data not found") return;
          else {
            this.faceImages = [];
            for (let key in data) {
              this.faceImages.push(`data:image/jpeg;base64, ${data[key]}`);
            }
          }
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
  },
};
</script>

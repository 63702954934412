<template>
  <div v-if="!isConfirmed" class="container text-center">
    <div class="row center-row my-5">
      <div class="col">
        <b-icon
          icon="exclamation-triangle"
          variant="warning"
          scale="5"
        ></b-icon>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label class="bold-text larger-text">ยืนยันการเปลี่ยนรูปแบบของคอร์สเรียน</label>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label>ต้องการเปลี่ยนคอร์ส "{{courseName}}" เป็น <span style="color:white; background-color:#BA55D3;">การเรียนแบบผสมผสาน</span> หรือไม่</label>
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label>(ไม่สามารถเปลี่ยนกลับเป็นคอร์สออนไลน์ได้)</label>
      </div>
    </div>
    <div class="row center-row mt-3">
      <div class="col">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="isButtonDisabled" class="mr-2">ยกเลิก</b-button>
        <b-button variant="primary" @click="confirm()" :disabled="isButtonDisabled">ยืนยัน</b-button>
      </div>
    </div>
  </div>
  <div v-else class="container text-center">
    <div class="row center-row my-5">
      <div class="col">
        <b-icon
          icon="check-circle"
          style="color: green"
          scale="5"
        />
      </div>
    </div>
    <div class="row center-row">
      <div class="col">
        <label class="bold-text larger-text">เปลี่ยนรูปแบบคอร์สเรียนสำเร็จ</label>
      </div>
    </div>
    <div class="row center-row mt-3">
      <div class="col">
        <b-button variant="primary" @click="success()">เสร็จสิ้น</b-button>
      </div>
    </div>
  </div>
</template>

<style>
.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.larger-text {
  font-size: 24px; /* Adjust the font size as needed */
}

.warning-icon {
  font-size: 24px;
  color: #f39c12; /* Warning icon color */
}

.bold-text {
  font-weight: bold;
}

/* Add more styling as needed */
</style>

<script>
export default {
  name: "ConfirmModal",
  props: {
    courseName: { type: String, default: null },
    typeName: { type: String, default: "HYBRID" },
    isConfirmed: { type: Boolean, default: false },
  },
  data() {
    return {
      isButtonDisabled: false, // Initialize the variable to false to enable the button initially
    };
  },
  methods: {
    async confirm() {
      this.isButtonDisabled = true;
      await this.$emit("edit", this.typeName);
    },
    cancel() {
      this.$emit("modalHideEditCourseType");
    },
    success() {
      this.$emit("modalHideEditCourseType");
    },
  }
};
</script>


import jsPDF from "jspdf";
import { font, fontBold } from "@/assets/fonts/Sarabun-normal.js";

export default {
  methods: {
    async generateAllCoursePdf(){
      try {
        this.localBtnDisabled = true;
        await this.getMember();
        let pdf = new jsPDF(this.pdfAllCourseOption);
        await this.generateChart();
  
        const pdf_width = pdf.internal.pageSize.width;
        const pdf_height = pdf.internal.pageSize.height;
  
        const margin_l = this.pdfAllCourseConfig.margin.l;
        const margin_t = this.pdfAllCourseConfig.margin.t;
        
        const cellWidth = 90;
        const cellHeight = 18;
  
        const addNewPageIfNeeded = (incrementValue) => {
          if ((pdf_position_y + incrementValue) >= pdf_height - margin_t) {
            pdf.addPage();
            pdf_position_y = margin_t; // Reset the position to the top of the new page
          } else {
            pdf_position_y += incrementValue; // Add the input value
          }
        };
  
        let pdf_position_y = 0;
  
        pdf.addFileToVFS("Sarabun-Regular.ttf", fontBold);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "bold");
        pdf.addFileToVFS("Sarabun-Regular.ttf", font);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "normal");
  
        pdf.setFont("Sarabun", "bold");
  
        pdf.setFontSize(this.pdfAllCourseConfig.typo.header);
        pdf_position_y += margin_t;
        pdf.text(
          "รายงานการเข้าเรียน",
          pdf_width / 2,
          pdf_position_y,
          null,
          null,
          "center"
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endLegend);
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(
          pdf,
          "ชื่อผู้เรียน : " + this.data.firstName + " " + this.data.lastName,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);
  
        pdf.text(
          "อีเมล : " + this.data.email,
          margin_l,
          pdf_position_y,
          null,
          null,
          "left"
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);
  
        this.thaitext(
          pdf,
          "ข้อมูลวันที่ : " + this.formatThaiMonthRange(this.data.startDate, this.data.endDate),
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);
  
        this.thaitext(
          pdf,
          "จำนวนคอร์สทั้งหมดที่สามารถเรียนได้: " + this.data.total_course + " คอร์ส",
          margin_l,
          pdf_position_y,
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endTable);
  
        // table course
        // Draw the cell borders
        pdf.rect(margin_l, pdf_position_y, cellWidth + 215, cellHeight + 9);
        pdf.rect(margin_l + cellWidth + 215, pdf_position_y, cellWidth - 5 , cellHeight + 9);
        pdf.rect(margin_l + cellWidth*2 + 215 - 5, pdf_position_y, cellWidth - 5 , cellHeight + 9);
        pdf.rect(margin_l + cellWidth*3 + 215 - 10, pdf_position_y, cellWidth - 15 , cellHeight + 9);
  
        
        //header table
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        //column 1
        this.thaitext(pdf, "คอร์ส", margin_l + 5, pdf_position_y + 16);
  
        //column 2
        this.thaitext(pdf, "ชั่วโมงที่เข้าชั้นเรียน", margin_l + cellWidth + 220, pdf_position_y + 12);
  
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.small);
        this.thaitext(pdf, "(เวลาเรียน/ชม.ทั้งหมด)", margin_l + cellWidth + 220, pdf_position_y + 21);
  
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        //column 3
        this.thaitext(pdf, "จำนวนชั่วโมงที่เข้าเรียน", margin_l + cellWidth*2 + 220 - 5, pdf_position_y + 12);
        this.thaitext(pdf, "ออนไลน์", margin_l + cellWidth*2 + 220 - 5, pdf_position_y + 21);
  
        //column 4
        this.thaitext(pdf, "จำนวนข้อสอบที่ทำ", margin_l + cellWidth*3 + 220 - 10, pdf_position_y + 16);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRowHeader);
  
  
        let sumTotalTime = 0;
        let sumLearnedTime = 0;
        let sumOnlineTime = 0;
        let sumExam = 0;
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.data.course.forEach((row) => {
          // Draw the cell borders
          pdf.rect(margin_l, pdf_position_y, cellWidth + 215, cellHeight);
          pdf.rect(margin_l + cellWidth + 215, pdf_position_y, cellWidth - 5 , cellHeight);
          pdf.rect(margin_l + cellWidth*2 + 215 - 5, pdf_position_y, cellWidth - 5 , cellHeight);
          pdf.rect(margin_l + cellWidth*3 + 215 - 10, pdf_position_y, cellWidth - 15 , cellHeight);
  
          this.thaitext(pdf, row.c_name, margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, (row.total_time == null) ? "-" : this.secondsToHHMM(row.learned_time) + "/" + this.secondsToHHMM(row.total_time) + " ชั่วโมง", margin_l + cellWidth + 220, pdf_position_y + 12);
          this.thaitext(pdf, (row.online_time == null) ? "-" : this.secondsToHHMM(row.online_time) + " ชั่วโมง", margin_l + cellWidth*2 + 220 - 5, pdf_position_y + 12);
          this.thaitext(pdf, (row.exam == null) ? "-" : row.exam + " ชุด" , margin_l + cellWidth*3 + 220 - 10, pdf_position_y + 12);
          
  
          sumTotalTime += row.total_time;
          sumLearnedTime += row.learned_time;
          sumOnlineTime += row.online_time;
          sumExam += row.exam;
          
          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        });
  
        // sum course result
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        pdf.rect(margin_l, pdf_position_y, cellWidth + 215, cellHeight);
        pdf.rect(margin_l + cellWidth + 215, pdf_position_y, cellWidth - 5 , cellHeight);
        pdf.rect(margin_l + cellWidth*2 + 215 - 5, pdf_position_y, cellWidth - 5 , cellHeight);
        pdf.rect(margin_l + cellWidth*3 + 215 - 10, pdf_position_y, cellWidth - 15 , cellHeight);
  
        this.thaitext(pdf, "รวมทั้งหมด", margin_l + 5, pdf_position_y + 12);
        this.thaitext(pdf, this.secondsToHHMM(sumLearnedTime) + "/" + this.secondsToHHMM(sumTotalTime) + " ชั่วโมง", margin_l + cellWidth + 220, pdf_position_y + 12);
        this.thaitext(pdf, this.secondsToHHMM(sumOnlineTime) + " ชั่วโมง", margin_l + cellWidth*2 + 220 - 5, pdf_position_y + 12);
        this.thaitext(pdf, sumExam + " ชุด" , margin_l + cellWidth*3 + 220 - 10, pdf_position_y + 12);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endTable);
  
        // summarize table
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(pdf, 'สรุปการเข้าชั้นเรียน', margin_l, pdf_position_y);
  
        pdf.rect(margin_l, pdf_position_y + 3, cellWidth + 180, cellHeight);
        pdf.rect(margin_l + cellWidth + 180, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 2 + 180 - 20, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 3 + 180 - 40, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 4 + 180 - 60, pdf_position_y + 3, cellWidth - 20, cellHeight);
  
        //header table
        this.thaitext(pdf, "คอร์ส", margin_l + 5, pdf_position_y + 15);
        this.thaitext(pdf, "เข้าเรียน", margin_l + cellWidth + 203, pdf_position_y + 15);
        this.thaitext(pdf, "มาสาย", margin_l + cellWidth * 2 + 206 - 20, pdf_position_y + 15);
        this.thaitext(pdf, "ขาดเรียน", margin_l + cellWidth * 3 + 203 - 40, pdf_position_y + 15);
        this.thaitext(pdf, "ลา", margin_l + cellWidth * 4 + 210 - 60, pdf_position_y + 15);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
  
        let sumPresentCount = 0;
        let sumAbsentCount = 0;
        let sumLateCount = 0;
        let sumLeaveCount = 0;
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.data.summarize.forEach((row) => {
          // Draw the cell borders
          pdf.rect(margin_l, pdf_position_y + 3, cellWidth + 180, cellHeight);
          pdf.rect(margin_l + cellWidth + 180, pdf_position_y + 3, cellWidth - 20, cellHeight);
          pdf.rect(margin_l + cellWidth * 2 + 180 - 20, pdf_position_y + 3, cellWidth - 20, cellHeight);
          pdf.rect(margin_l + cellWidth * 3 + 180 - 40, pdf_position_y + 3, cellWidth - 20, cellHeight);
          pdf.rect(margin_l + cellWidth * 4 + 180 - 60, pdf_position_y + 3, cellWidth - 20, cellHeight);
  
          this.thaitext(pdf, row.c_name, margin_l + 5, pdf_position_y + 15);
          pdf.text((row.Present_Count == 0) ? "-" : row.Present_Count + "", margin_l + cellWidth + 215, pdf_position_y + 15, null, null, "center");
          pdf.text((row.Late_Count == 0) ? "-" : row.Late_Count + "", margin_l + cellWidth * 2 + 215 - 20, pdf_position_y + 15, null, null, "center");
          pdf.text((row.Absent_Count == 0) ? "-" : row.Absent_Count + "", margin_l + cellWidth * 3 + 215 - 40, pdf_position_y + 15, null, null, "center");
          pdf.text((row.Leave_Count == 0) ? "-" : row.Leave_Count + "" + "", margin_l + cellWidth * 4 + 215 - 60, pdf_position_y + 15, null, null, "center");
  
          
  
          sumPresentCount += row.Present_Count;
          sumAbsentCount += row.Absent_Count;
          sumLateCount += row.Late_Count;
          sumLeaveCount += row.Leave_Count;
          
          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        });
  
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        pdf.rect(margin_l, pdf_position_y + 3, cellWidth + 180, cellHeight);
        pdf.rect(margin_l + cellWidth + 180, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 2 + 180 - 20, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 3 + 180 - 40, pdf_position_y + 3, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth * 4 + 180 - 60, pdf_position_y + 3, cellWidth - 20, cellHeight);
  
        this.thaitext(pdf, "รวมทั้งหมด", margin_l + 5, pdf_position_y + 15);
        pdf.text((sumPresentCount == 0) ? "-" : sumPresentCount + "", margin_l + cellWidth + 215, pdf_position_y + 15, null, null, "center");
        pdf.text((sumLateCount == 0) ? "-" : sumLateCount + "", margin_l + cellWidth * 2 + 215 - 20, pdf_position_y + 15, null, null, "center");
        pdf.text((sumAbsentCount == 0) ? "-" : sumAbsentCount + "", margin_l + cellWidth * 3 + 215 - 40, pdf_position_y + 15, null, null, "center");
        pdf.text((sumLeaveCount == 0) ? "-" : sumLeaveCount + "", margin_l + cellWidth * 4 + 215 - 60, pdf_position_y + 15, null, null, "center");
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endTable);
  
        // classDetails table
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(pdf, 'รายละเอียดการเข้าชั้นเรียน', margin_l, pdf_position_y);
        addNewPageIfNeeded(3);
  
        pdf.rect(margin_l, pdf_position_y, cellWidth, cellHeight + 9);
        pdf.rect(margin_l + cellWidth, pdf_position_y, cellWidth + 230, cellHeight + 9);
        pdf.rect(margin_l + cellWidth * 2 + 230, pdf_position_y, cellWidth - 20, cellHeight + 9);
        pdf.rect(margin_l + cellWidth * 3 + 230 - 20, pdf_position_y, cellWidth - 20, cellHeight + 9);
  
        //header table
        this.thaitext(pdf, "วันที่", margin_l + 39, pdf_position_y + 16);
        this.thaitext(pdf, "คอร์ส", margin_l + cellWidth + 5, pdf_position_y + 16);
        this.thaitext(pdf, "สถานะ", margin_l + cellWidth * 2 + 255, pdf_position_y + 16);
        this.thaitext(pdf, "เวลาเข้าเรียน", margin_l + cellWidth * 3 + 247 - 20, pdf_position_y + 12);
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.small);
        this.thaitext(pdf, "(ชั่วโมง:นาที)", margin_l + cellWidth * 3 + 250 - 20, pdf_position_y + 21);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRowHeader);
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.data.classDetails.forEach((row) => {
          pdf.rect(margin_l, pdf_position_y, cellWidth, cellHeight + 9);
          pdf.rect(margin_l + cellWidth, pdf_position_y, cellWidth + 230, cellHeight + 9);
          pdf.rect(margin_l + cellWidth * 2 + 230, pdf_position_y, cellWidth - 20, cellHeight + 9);
          pdf.rect(margin_l + cellWidth * 3 + 230 - 20, pdf_position_y, cellWidth - 20, cellHeight + 9);
  
          this.thaitext(pdf, this.formatDateToThaiShortYear2Digits(row.cls_date), margin_l + 30, pdf_position_y + 12); 
          this.thaitext(pdf, row.cls_startTime + "-" + row.cls_finishTime, margin_l + 27.5, pdf_position_y + 21);
          this.thaitext(pdf, row.c_name, margin_l + cellWidth + 5, pdf_position_y + 16);
          this.classStatus(pdf, row.clsa_status, margin_l + cellWidth * 2 + 255, pdf_position_y + 16);
          let timeString = this.secondsToHHMM(row.clsa_duration);
          let parts = timeString.split(':');

          let HH = parts[0];
          let MM = parts[1];
          pdf.text(HH, margin_l + cellWidth * 3 + 257 - 13, pdf_position_y + 16, null, null, "right");
          pdf.text(":", margin_l + cellWidth * 3 + 257 - 12, pdf_position_y + 15.5, null, null, "center");
          pdf.text(MM, margin_l + cellWidth * 3 + 257 - 11, pdf_position_y + 16, null, null, "left");
         
          addNewPageIfNeeded(this.pdfAllCourseConfig.endRowHeader);
        });
        addNewPageIfNeeded(this.pdfAllCourseConfig.endTable);
  
        // onlineClassDetails table
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(pdf, 'สรุปการเข้าเรียนออนไลน์', margin_l, pdf_position_y);
        addNewPageIfNeeded(3);
  
        pdf.rect(margin_l, pdf_position_y, cellWidth, cellHeight + 9);
        pdf.rect(margin_l + cellWidth, pdf_position_y, cellWidth + 105, cellHeight + 9);
        pdf.rect(margin_l + cellWidth * 2 + 105, pdf_position_y, cellWidth + 105, cellHeight + 9);
        pdf.rect(margin_l + cellWidth * 3 + 210, pdf_position_y, cellWidth - 20, cellHeight + 9);
  
        //header table
        this.thaitext(pdf, "วันที่", margin_l + 39, pdf_position_y + 16);
        this.thaitext(pdf, "คอร์ส", margin_l + cellWidth + 5, pdf_position_y + 16);
        this.thaitext(pdf, "ชื่อบทเรียน", margin_l + cellWidth * 2 + 105 + 5, pdf_position_y + 16);
        this.thaitext(pdf, "เวลาเข้าเรียน", margin_l + cellWidth * 3 + 210 + 5, pdf_position_y + 12);
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.small);
        this.thaitext(pdf, "(ชั่วโมง:นาที)", margin_l + cellWidth * 3 + 210 + 5, pdf_position_y + 21);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRowHeader);
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.data.onlineClassDetails.forEach((row) => {
          pdf.rect(margin_l, pdf_position_y, cellWidth, cellHeight);
          pdf.rect(margin_l + cellWidth, pdf_position_y, cellWidth + 105, cellHeight);
          pdf.rect(margin_l + cellWidth * 2 + 105, pdf_position_y, cellWidth + 105, cellHeight);
          pdf.rect(margin_l + cellWidth * 3 + 210, pdf_position_y, cellWidth - 20, cellHeight);
  
          this.thaitext(pdf, this.formatDateToThaiShortYear2Digits(row.lels_date), margin_l + 30, pdf_position_y + 12); 
          this.thaitext(pdf, row.c_name, margin_l + cellWidth + 5, pdf_position_y + 12);
          this.thaitext(pdf, row.lec_name, margin_l + cellWidth * 2 + 105 + 5, pdf_position_y + 12);
          this.thaitext(pdf, this.secondsToHHMM(row.learning_time), margin_l + cellWidth * 3 + 210 + 5, pdf_position_y + 12);
         
          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        });
        addNewPageIfNeeded(this.pdfAllCourseConfig.endTable);
  
        // ExamResult table
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(pdf, 'รายงานผลคะแนนสอบ', margin_l, pdf_position_y);
        addNewPageIfNeeded(3);
  
        pdf.rect(margin_l, pdf_position_y, cellWidth - 20, cellHeight);
        pdf.rect(margin_l + cellWidth - 20, pdf_position_y, cellWidth + 105, cellHeight);
        pdf.rect(margin_l + cellWidth * 2 + 105 - 20, pdf_position_y, cellWidth + 105, cellHeight);
        pdf.rect(margin_l + cellWidth * 3 + 210 - 20, pdf_position_y, cellWidth, cellHeight);
  
        this.thaitext(pdf, "ครั้งที่", margin_l + 28, pdf_position_y + 12);
        this.thaitext(pdf, "คอร์ส", margin_l + cellWidth - 20 + 5, pdf_position_y + 12);
        this.thaitext(pdf, "ชื่อข้อสอบ", margin_l + cellWidth * 2 + 105 - 20 + 5, pdf_position_y + 12);
        this.thaitext(pdf, "ผลคะแนน", margin_l + cellWidth * 3 + 210 - 20 + 30, pdf_position_y + 12);
        addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
  
  
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        let i = 1;
        let average_percent = 0;
        this.data.ExamResult.forEach((row) => {
          pdf.rect(margin_l, pdf_position_y, cellWidth - 20, cellHeight);
          pdf.rect(margin_l + cellWidth - 20, pdf_position_y, cellWidth + 105, cellHeight);
          pdf.rect(margin_l + cellWidth * 2 + 105 - 20, pdf_position_y, cellWidth + 105, cellHeight);
          pdf.rect(margin_l + cellWidth * 3 + 210 - 20, pdf_position_y, cellWidth, cellHeight);
  
          pdf.text(i + "", margin_l + 35, pdf_position_y + 12, null, null, "center");
          this.thaitext(pdf, row.c_name, margin_l + cellWidth - 20 + 5, pdf_position_y + 12);
          this.thaitext(pdf, row.lec_name, margin_l + cellWidth * 2 + 105 - 20 + 5, pdf_position_y + 12);
          pdf.text(row.lel_examResultPercent + "%", margin_l + cellWidth * 3 + 210 - 20 + 45, pdf_position_y + 12, null, null, "center");
  
          i += 1;
          average_percent += row.lel_examResultPercent
          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
        });
  
        average_percent = (average_percent / i).toFixed(2);
  
        pdf.rect(margin_l, pdf_position_y, cellWidth + 370, cellHeight);
        pdf.rect(margin_l + cellWidth + 370, pdf_position_y, cellWidth, cellHeight);
        this.thaitext(pdf, "คะแนนเฉลี่ย", margin_l + cellWidth + 330, pdf_position_y + 12);
        pdf.text(average_percent + "%", margin_l + cellWidth * 3 + 210 - 20 + 45, pdf_position_y + 12, null, null, "center");
  
        // chart
        addNewPageIfNeeded(pdf_height);
        pdf.setFont("Sarabun", "bold");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(pdf, 'กราฟแสดงผลคะแนนสอบ', margin_l, pdf_position_y);
        addNewPageIfNeeded(3);
        addNewPageIfNeeded(this.pdfAllCourseConfig.margin.tChart);
  
  
        await this.generateChart();
        setTimeout(() => {
          const canvas = document.getElementById("chartCanvas");
          const imageData = canvas.toDataURL("image/jpeg", 1.0);
          pdf.addImage(imageData, "JPEG", margin_l + 60, pdf_position_y, this.chartWidth, this.chartHeight);
        }, 0);

        setTimeout(() => {
          pdf.save("รายงานการเข้าเรียน.pdf");
        }, 0);
        this.localBtnDisabled = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
}
<template>
  <router-link :to="path">
    <b-icon-chevron-right /> <span class="link-title">{{ collapseName }} </span>
  </router-link>
</template>

<script>
export default {
  props: {
    collapseName: {
      type: String,
      required: true
    },
    path: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss" scoped>
.fa-book:before {
  padding-right: 0.5em;
}
</style>

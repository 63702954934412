<template>
  <div>
    <b-input-group :size="size">
      <b-form-input
        v-model="q"
        type="search"
        class="form-control border-1 bg-light"
        autofocus
        :placeholder="title"
        :state="isValidated"
        @keyup.enter="sendKeyword"
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="primary" @click="sendKeyword" :disabled="disabled"
          >ค้นหา</b-button
        >
      </b-input-group-append>
    </b-input-group>
    <p v-if="isValidated" class="text-danger small">
      String is empty or contains only spaces.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'พิมพ์เพื่อค้นหา',
    },
    size: {
      type: String,
      default: 'sm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      q: '',
      isValidated: null,
    };
  },
  methods: {
    sendKeyword() {
      if (this.q && this.isNullOrEmpty(this.q)) {
        this.isValidated = false;
      } else {
        this.isValidated = null;
        this.$emit('typed', this.q.trim());
      }
    },
    isNullOrEmpty(str) {
      return !str || !str.trim();
    },
  },
};
</script>

<style scoped>
.form-control {
  height: calc(1.5em + 0.75rem + 2px) !important;
}
</style>

import { render, staticRenderFns } from "./StorageLimitPanel.vue?vue&type=template&id=513a9d67&scoped=true&"
import script from "./StorageLimitPanel.vue?vue&type=script&lang=js&"
export * from "./StorageLimitPanel.vue?vue&type=script&lang=js&"
import style0 from "./StorageLimitPanel.vue?vue&type=style&index=0&id=513a9d67&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513a9d67",
  null
  
)

export default component.exports
<template>
  <div>
    <PageHeader title="ลงทะเบียนจ่ายเงินสด" />
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <b-row>
          <b-col cols="6">
            <img :src="imagePreview" class="img-fluid" />
          </b-col>
          <b-col cols="6">
            <div class="my-4">
              <h6><strong>ชื่อคอร์สเรียน</strong></h6>
              <b-form-select
                v-model="selected"
                @change="dataCourseInfo()"
                style="position: relative"
                :disabled="isLoading"
              >
                <option value="null">เลือกคอร์ส</option>
                <option
                  v-for="(courseListItem, courseListIndex) in courseFilteredList"
                  :key="courseListIndex"
                  :value="courseListIndex"
                >
                  {{ courseListItem.courseName }}
                </option></b-form-select
              >
              <template v-if="isLoading">
                <div
                  class="text-center my-4"
                  style="position: absolute; top: 28px; left: 50%"
                >
                  <b-spinner class="align-middle" variant="primary"></b-spinner>
                </div>
              </template>
            </div>
            <div class="my-4">
              <h6>
                <strong>ราคา : {{ coursePrice }}</strong>
              </h6>
            </div>
            <div class="my-4">
              <h6>
                <strong>ผู้สอน : {{ courseOwner }}</strong>
              </h6>
            </div>
            <div class="my-4">
              <h6>
                <strong>ระยะเวลาเรียน : {{ learnDuration }}</strong>
              </h6>
            </div>
            <div class="my-4">
              <h6>
                <strong>ชั่วโมงเรียน : {{ learnTimeLimit }}</strong>
              </h6>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12">
            <h4>
              <strong>กรอกข้อมูลของผู้เรียนเพื่อใช้ในการลงทะเบียน</strong>
            </h4>
          </b-col>
          <b-col cols="4" class="mt-3">
            <div>
              <h6><strong>ชื่อผู้เรียน</strong></h6>
              <b-form-input
                v-model="mFirstName"
                placeholder="ระบุชื่อผู้เรียน"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="4" class="mt-3">
            <div>
              <h6><strong>นามสกุล</strong></h6>
              <b-form-input
                v-model="mLastName"
                placeholder="ระบุนามสกุลผู้เรียน"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4" class="mt-3">
            <div>
              <h6><strong>อีเมล</strong></h6>
              <b-form-input
                v-model="mEamil"
                placeholder="อีเมลที่จะใช้ในการเข้าสู่ระแบบ"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="4" class="mt-3">
            <div>
              <h6><strong>เบอร์โทรศัพท์</strong></h6>
              <b-form-input
                v-model="mPhone"
                placeholder="08xxxxxxxx"
                trim
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="12" class="mt-3">
            <b-button
              variant="primary"
              @click="submitRegisterCash()"
              :disabled="
                (selected == null) || !mFirstName || !mLastName || !mEamil || !mPhone || isRegisterLoading
              "
              >ลงทะเบียน <b-spinner v-if="isRegisterLoading" small></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import api from "@/services/api.js";
export default {
  name: "RegisterCash",
  components: {
    PageHeader,
  },
  props: {
    company: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      imagePreview: "/images/placeholder-image.png",
      selected: null,
      fetchParams: {
        company_id: this.company.id,
      },
      mFirstName: null,
      mLastName: null,
      mEamil: null,
      mPhone: null,
      courseOwner: null,
      coursePrice: null,
      learnDuration: null,
      learnTimeLimit: null,
      courseFilteredList: [],
      isRegisterLoading: false
    };
  },

  async mounted() {
    this.fetchParams.company_id = this.company.id;
    await this.fetchCourseList(this.fetchParams);
    this.courseFilteredList = this.courseList.data.filter(item => item.courseStatus != "SUSPEND" && item.courseStatus != "DELETED" && item.courseStatus != "DISABLED" && item.courseStatus != "DRAFT");
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseList"]),
  },
  methods: {
    ...mapActions("CourseManagement", ["fetchCourseList"]),

    editPrice(value) {
      var newValue = value.replace(/[^0-9]|e/g, "");
      if (isNaN(parseInt(newValue))) {
        return newValue;
      } else {
        return Numeral(parseInt(newValue)).format("0,0");
      }
    },
    dataCourseInfo() {
      if (this.courseFilteredList[this.selected].seasonPrice.price !== null && this.courseFilteredList[this.selected].seasonPrice.enable == true) {
        this.coursePrice = this.courseFilteredList[this.selected].seasonPrice.price === "0" ? "ฟรี" : Numeral(parseInt(this.courseFilteredList[this.selected].seasonPrice.price)).format("0.00");
      } else if (this.courseFilteredList[this.selected].promotionPrice !== null) {
        this.coursePrice = this.courseFilteredList[this.selected].promotionPrice === "0.00" ? "ฟรี" : this.courseFilteredList[this.selected].promotionPrice;
      } else {
        this.coursePrice = this.courseFilteredList[this.selected].price === "0.00" || this.courseFilteredList[this.selected].price == null ? "ฟรี" : this.courseFilteredList[this.selected].price;
      }

      this.courseOwner =
        this.courseFilteredList[this.selected].courseOwner.ownerName;
      this.imagePreview = this.courseFilteredList[this.selected].courseThumbnail;
      this.learnDuration = this.courseFilteredList[this.selected].learnDuration
        ? this.courseFilteredList[this.selected].learnDuration + " วัน"
        : "ไม่จำกัดวัน";
      this.learnTimeLimit = this.courseFilteredList[this.selected].learnTimeLimit
        ? this.courseFilteredList[this.selected].learnTimeLimit + " ชั่วโมง"
        : "ไม่จำกัดชั่วโมง";
    },

    async submitRegisterCash() {
      this.isRegisterLoading = true
      var userId = this.$store.state.Authentication.authUser.id;
      let request = {
        adminId: userId,
        courseId: this.courseFilteredList[this.selected].courseId,
        price: this.coursePrice == "ฟรี" ? 0 : this.coursePrice,
        firstName: this.mFirstName,
        lastName: this.mLastName,
        email: this.mEamil,
        mobilePhone: this.mPhone,
      };
      await api
        .post(`/v1/confirm-order-cash`, request)
        .then(() => {
          this.$bvToast.toast("บันทึกข้อมูลเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });
          this.selected = null;
          this.mFirstName = null;
          this.mLastName = null;
          this.mEamil = null;
          this.mPhone = null;
          this.coursePrice = null;
          this.courseOwner = null;
          this.imagePreview = "/images/placeholder-image.png";
          this.learnDuration = null;
          this.learnTimeLimit = null;
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message, {
            variant: "danger",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });
          return false;
        });
      this.isRegisterLoading = false
    },
  },
};
</script>

<style>
</style>

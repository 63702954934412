<template>
  <header
    class="bottom-toolbar border-top fixed-bottom"
    :class="{ 'sidebar-left-hidden': !sidebarOpened }"
  >
    <div class="main-bar p-3 ml-5">
      <b-row>
        <b-col>
          <b-button
            v-if="backToUrl && currentLinkIndex < 2"
            :to="backToUrl"
            variant="link"
          >
            <b-icon-chevron-left /> ย้อนกลับ
          </b-button>
          <div v-else>
            <b-button class="ml-3"
              v-if="currentLinkIndex === 0"
              variant="link"
              :to="{ name: 'course.enroll.index' }"
            >
              <b-icon-chevron-left /> กลับไปหน้าลงทะเบียนเรียน
            </b-button>

            <b-button
              v-if="currentLinkIndex === 1"
              :to="{ name: links[0].name }"
              variant="link"
            >
              <b-icon-chevron-left /> เลือกคอร์สเรียน
            </b-button>
          </div>

          <b-button
            v-if="currentLinkIndex === 2"
            variant="link"
            :to="{ name: links[1].name }"
          >
            <b-icon-chevron-left /> เลือกผู้เรียน
          </b-button>

          <b-button
            v-if="currentLinkIndex === 3"
            variant="link"
            :to="{ name: links[2].name }"
          >
            <b-icon-chevron-left /> ตรวจสอบการลงทะเบียนซ้ำ
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="currentLinkIndex === 0"
            :disabled="
              selectedItems['courses'].length === 0 &&
                selectedItems['courseGroups'].length === 0
            "
            :to="{ name: links[1].name }"
            variant="primary"
          >
            เลือกผู้เรียน <b-icon-chevron-right />
          </b-button>

          <b-button
            v-if="currentLinkIndex === 1"
            :disabled="
              (selectedItems['members'].length === 0 &&
                selectedItems['memberGroups'].length === 0) ||
                (selectedItems['courses'].length === 0 &&
                  selectedItems['courseGroups'].length === 0)
            "
            :to="{ name: links[2].name }"
            variant="primary"
          >
            รีวิวการลงทะเบียน <b-icon-chevron-right />
          </b-button>

          <b-button
            v-if="currentLinkIndex === 2"
            :disabled="
              (selectedItems['members'].length === 0 &&
                selectedItems['memberGroups'].length === 0) ||
                (selectedItems['courses'].length === 0 &&
                  selectedItems['courseGroups'].length === 0)
            "
            variant="success"
            @click="$emit('startEnroll')"
          >
            ลงทะเบียนเรียน
          </b-button>

          <b-button
            v-if="currentLinkIndex === 3"
            :disabled="
              (selectedItems['members'].length === 0 &&
                selectedItems['memberGroups'].length === 0) ||
                (selectedItems['courses'].length === 0 &&
                  selectedItems['courseGroups'].length === 0)
            "
            variant="success"
            @click="$emit('startEnroll')"
          >
            ลงทะเบียนเรียน
          </b-button>
        </b-col>
      </b-row>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CourseEnrollNavbar",
  props: {
    sidebarOpened: Boolean
  },
  data() {
    return {
      links: [
        {
          name: "course.enroll.step1"
        },
        {
          name: "course.enroll.step2"
        },
        {
          name: "course.enroll.step3"
        },
        {
          name: "course.enroll.step4"
        }
      ],
      currentLinkIndex: 0
    };
  },
  computed: {
    ...mapGetters("CourseEnrollment", ["selectedItems"]),
    backToUrl() {
      return localStorage.getItem("backToUrl");
    }
  },
  created() {
    this.currentLinkIndex = this.getCurrentLinkIndex();
  },
  methods: {
    getCurrentLinkIndex() {
      return this.links.findIndex(
        data => data.name === this.$router.currentRoute.name
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  padding-left: 250px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  z-index: 80;
}
.progress-bar {
  background-color: #f99f4e;
}
.sidebar-left-hidden {
  padding-left: 0px !important;
}

.router-link-exact-active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>

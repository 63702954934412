export default {
  filters: {
    thaiDurationFilter(value) {
      if (value) {
        let [hourString, minuteString, secondString] = value.split(":");
        let hour = parseInt(hourString, 10);
        let minute = parseInt(minuteString, 10);
        let second = parseInt(secondString, 10);
        let result = "";
        if (hour > 0) {
          result += `${hour} ชั่วโมง `;
        }
        if (minute > 0) {
          result += `${minute} นาที `;
        }
        if (second > 0) {
          result += `${second} วินาที`;
        }
        return result;
      }
      return `-`;
    },
    duration: function(value) {
      if (value) {
        let seconds = parseInt(value, 10);

        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        let hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        hours = String(hours).padStart(2, "0");
        minutes = String(minutes).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");

        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "-";
      }
    },
  },
  methods: {
    duration: function(value) {
      if (value) {
        let seconds = parseInt(value, 10);

        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        let hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        hours = String(hours).padStart(2, "0");
        minutes = String(minutes).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");

        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "-";
      }
    },
  },
};

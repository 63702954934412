import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    learningHistoryList: {},
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status == null ? !state.isLoading : status;
    },
    SET_LEARNING_HISTORY_LIST(state, data) {
      state.learningHistoryList = data;
    },
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    learningHistoryList(state) {
      return state.learningHistoryList;
    },
  },
  actions: {
    async getLearningHistoryList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/analytics/students/learning-history/${params}`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_LEARNING_HISTORY_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex: ", errors);
        });
    },
  },
};

import async from "@/config/store";
import Vue from "vue";

export default {
  [async.ASYNC_UPLOADED_CSV.SUCCESS]: (state, payload) => {
    state[async.ASYNC_UPLOADED_CSV.loadingKey] = false;
    state[async.ASYNC_UPLOADED_CSV.errorKey] = null;
    Vue.set(state, async.ASYNC_UPLOADED_CSV.stateKey, payload);
  },
  [async.ASYNC_UPLOADED_CSV.FAILURE]: (state, err) => {
    state[async.ASYNC_UPLOADED_CSV.loadingKey] = false;
    state[async.ASYNC_UPLOADED_CSV.stateKey] = null;
    Vue.set(state, async.ASYNC_UPLOADED_CSV.errorKey, err);
  },
  [async.ASYNC_UPLOADED_CSV.PENDING]: state => {
    state[async.ASYNC_UPLOADED_CSV.errorKey] = null;
    state[async.ASYNC_UPLOADED_CSV.stateKey] = null;
    Vue.set(state, async.ASYNC_UPLOADED_CSV.loadingKey, true);
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.SUCCESS]: (state, payload) => {
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey] = false;
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey] = null;
    Vue.set(state, async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey, payload);
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.FAILURE]: (state, err) => {
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey] = false;
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey] = null;
    Vue.set(state, async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey, err);
  },
  [async.ASYNC_CREATE_EXAM_TEMPLATE.PENDING]: state => {
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.errorKey] = null;
    state[async.ASYNC_CREATE_EXAM_TEMPLATE.stateKey] = null;
    Vue.set(state, async.ASYNC_CREATE_EXAM_TEMPLATE.loadingKey, true);
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.SUCCESS]: (state, payload) => {
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey] = false;
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey] = null;
    Vue.set(state, async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey, payload);
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.FAILURE]: (state, err) => {
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey] = false;
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey] = null;
    Vue.set(state, async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey, err);
  },
  [async.ASYNC_CREATE_OR_APPEND_EXAM.PENDING]: state => {
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.errorKey] = null;
    state[async.ASYNC_CREATE_OR_APPEND_EXAM.stateKey] = null;
    Vue.set(state, async.ASYNC_CREATE_OR_APPEND_EXAM.loadingKey, true);
  }
};

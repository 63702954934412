export default {
    isMenuShow(menu){
        const comid = this.$store.state.Company.companyInfo.id;
        if(!MainMenu[menu]){
            return true;
        }else if(MainMenu[menu]['show'].includes(comid)){
            return true;
        }else if(MainMenu[menu]['notShow'].includes(comid)){
            return false;
        }else{
            return MainMenu[menu]['showDefault'];
        }
    },
}

const MainMenu = {
    course:{
        showDefault: true,
        show:[],
        notShow:[],
    },
    "course-Manage": {
        showDefault: true,
        show:[],
        notShow:[],
    },
    member:{
        showDefault: true,
        show:[],
        notShow:[],
    },
    "member-faceRegistration":{
        showDefault: true,
        show:[],
        notShow:[],
    },
    "member-faceRecognition":{
        showDefault: true,
        show:[],
        notShow:[],
    },
    mainExam:{
        showDefault: true,
        show:[],
        notShow:[],
    },
    analytics:{
        showDefault: true,
        show:[],
        notShow:[],
    },
    "analytics-exportDataAnalytic":{
        showDefault: false,
        show:[313,437,498,499],
        notShow:[],
    },
    discuss:{
        showDefault: true,
        show:[],
        notShow:[],
    },
    live:{
        showDefault: true,
        show:[],
        notShow:[485],
    },
    affiliate:{
        showDefault: true,
        show:[],
        notShow:[485],
    },
    etc:{
        showDefault: true,
        show:[],
        notShow:[485],
    }
};
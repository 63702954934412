<template>
  <div>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>ประกาศนียบัตร</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${companyInfo.url}/analytics/courses/${cId}/certificate`"
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-row
            style="margin: 0.5rem 0 0.5rem 0"
            class="justify-content-start"
          >
            <b-col cols="3" v-if="perPage > 10">
              <b-form-input
                v-model="search"
                placeholder="ค้นหารายชื่อ"
                class="mr-3"
                style="padding: 1rem; display: inline-block"
              >
              </b-form-input>
            </b-col>
            <b-col cols="1">
              <strong><p>ข้อมูลของวันที่</p></strong>
            </b-col>
            <b-col cols="3">
              <b-form-datepicker
                v-model="startDate"
                class="mb-2"
                locale="th"
                style="margin: 0 1rem 0 1rem"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1" class="text-center">
              <strong><p>ถึง</p></strong>
            </b-col>
            <b-col cols="3">
              <b-form-datepicker
                v-model="endDate"
                locale="th"
                class="mb-2"
                style="margin: 0 1rem 0 1rem"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="1" class="text-right">
              <b-button
                v-if="perPage > 10"
                @click="exportData()"
                class="btn btn-download"
                href=""
                ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
              >
            </b-col>
          </b-row>
          <b-table
            striped
            id="course-certificate-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="
              search == null
                ? result
                : result.filter((i) =>
                    (i.m_firstName + ' ' + i.m_lastName).includes(search)
                  )
            "
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(fullName)="data"
              ><span>{{
                data.item.m_firstName + " " + data.item.m_lastName
              }}</span></template
            >
            <template v-slot:cell(issueDate)="data">
              <span style="float: right">{{
                data.item.certis_issueDate | expireThaiDate
              }}</span>
            </template>

            <template v-slot:cell(expireDate)="data">
              <span style="float: right">{{
                data.item.expireDate | expireThaiDate
              }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <b-icon
                icon="download"
                variant="primary"
                class="h5 text-center download-icon-style"
                @click="downloadIconClicked(data.item.m_id)"
              ></b-icon>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) =>
                    (i.m_firstName + ' ' + i.m_lastName).includes(search)
                  ).length
            "
            :per-page="perPage"
            aria-controls="course-student-ranking-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
import moment from "moment";
export default {
  components: {
    layout,
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      search: null,
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "fullName", label: "ผู้เรียน" },
        {
          key: "issueDate",
          label: "วันที่ออก",
          class: "text-right",
        },
        {
          key: "expireDate",
          label: "วันที่หมดอายุ",
          class: "text-right",
        },
        { key: "btn", label: "ดาวน์โหลด", class: "text-right" },
      ],
      items: [],
    };
  },
  watch: {
    startDate() {
      this.fetch();
    },
    endDate() {
      this.fetch();
    },
  },
  methods: {
    async exportData() {
      let value = Array();
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) =>
              (i.m_firstName + " " + i.m_lastName).includes(this.search)
            );
      var ctr = 0;
      set.forEach(async (i) => {
        let results = await api.get(
          `/v1/members/${i.m_id}/course/${this.cId}/certificate`
        );
        let temp = {
          name: i.m_firstName + " " + i.m_lastName,
          issue: this.formatDate(i.certis_issueDate),
          expire: i.expireDate
            ? this.formatDate(i.expireDate)
            : "ไม่มีวันหมดอายุ",
          download: results.data && results.data.domain + results.data.link,
        };
        value.push(temp);
        ctr++;
        if (
          ctr ==
          (this.search == null
            ? this.result.length
            : this.result.filter((i) =>
                (i.m_firstName + " " + i.m_lastName).includes(this.search)
              ).length)
        ) {
          let key = ["ผู้เรียน", "วันที่ออก", "วันที่หมดอายุ", "ดาวน์โหลด"];
          let filename =
            "Individual-Course-Learner-Certificate" + this.companyInfo.url;

          const data = {
            key: key,
            filename: filename,
            data: value,
          };
          if (data.data.length > 0) await exp.exportData(data);
          else console.log("NO DATA");
        }
      });
    },
    formatDate: function (value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(
        this.apiRoute + `&startDate=${this.startDate}&endDate=${this.endDate}`
      );
      this.result = res.data;
      this.rows = this.result.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },

    async downloadIconClicked(id) {
      try {
        let results = await api.get(
          `/v1/members/${id}/course/${this.cId}/certificate`
        );
        let urlDownloadLink = results.data[0].domain + results.data[0].link;
        window.open(urlDownloadLink, "_blank");
      } catch (err) {
        console.log("downloadIconClicked error", err);
      }
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
.download-icon-style:hover {
  cursor: pointer;
}
</style>

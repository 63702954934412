import ErrorPage from '../components/pages/ErrorPage.vue';
import UnAuthorizedPage from '../components/pages/UnAuthorizedPage.vue';

export default [
  {
    path: '/:company/error',
    name: 'error',
    component: ErrorPage,
    props: { template: 'A' },
  },

  {
    path: '/:company/un-authorized',
    name: 'un-authorized',
    component: UnAuthorizedPage,
    props: { template: 'A' },
  },
];

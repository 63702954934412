export default {
  data() {
    return {
      memberId: JSON.parse(localStorage.getItem("user")).id,
    };
  },
  computed: {
    isHideTransferMoney() {
      const id = [77352, 122380, 84123, 85923, 276559];
      for (var i = 0; i < id.length; i++) {
        if (this.memberId == id[i]) {
          return true;
        }
      }
      return false;
    },
    isHideCourseManagement() {
      const id = [122372, 88749, 122380, 84123, 85923, 93996, 182132];
      for (var i = 0; i < id.length; i++) {
        if (this.memberId == id[i]) {
          return true;
        }
      }
      return false;
    },
    isHideCoursePriceManagement() {
      const id = [276559];
      for (var i = 0; i < id.length; i++) {
        if (this.memberId == id[i]) {
          return true;
        }
      }
      return false;
    },
  },
};

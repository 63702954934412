<template>
  <div>
    <page-header :title="`ประวัติการตรวจสอบใบหน้า`"></page-header>
    <b-container class="widthPage mx">
      <b-row class="mt-4">
        <b-col cols="12" class="mt-4">
          <b-breadcrumb>
            <b-breadcrumb-item>
              <router-link
                :to="{
                  name: 'members.face-approve-search-history',
                }"
                >ประวัติการตรวจสอบใบหน้า
              </router-link></b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{
              courseInfo.courseName
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>

        <b-col cols="12" class="mt-4">
          <div>
            <h5>
              <strong>{{ courseInfo.courseName }}</strong>
            </h5>
          </div>
        </b-col>

        <b-col cols="12" class="mt-4">
          <layout class="user-learn-progress">
            <template v-slot:header> </template>
            <template v-slot:body>
              <b-row class="mb-3">
                <b-col cols="3">
                  <div>
                    <b-input-group>
                      <b-form-input
                        placeholder="พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา"
                        v-model="search"
                      ></b-form-input>
                      <b-input-group-prepend class="input-search">
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="searchName()"
                          >ค้นหา</b-button
                        >
                      </b-input-group-prepend>
                    </b-input-group>
                  </div></b-col
                >
              </b-row>

              <b-table
                striped
                hover
                :fields="fields"
                :items="listResult"
                :busy="isBusy"
              >
                <template v-slot:cell(cer_completeDate)="data">
                  <template>
                    <span
                      >{{
                        data.item.cer_completeDate
                          ? data.item.cer_completeDate
                          : "ยังเรียนไม่จบ"
                      }}
                    </span></template
                  >
                </template>
                <template v-slot:cell(cer_status)="data">
                  <template>
                    <span
                      >{{
                        data.item.cer_status === "NOT_STARTED"
                          ? "ยังไม่เริ่มเรียน"
                          : data.item.cer_status === "IN_PROGRESS"
                          ? "กำลังเรียน"
                          : data.item.cer_status === "COMPLETED"
                          ? "เรียนจบแล้ว"
                          : "-"
                      }}
                    </span></template
                  >
                </template>
                <template v-slot:cell(cer_approveFaceStatus)="data">
                  <template>
                    <span
                      :class="[
                        data.item.cer_approveFaceStatus === 'NOT_APPROVED'
                          ? 'text-danger'
                          : data.item.cer_approveFaceStatus === 'APPROVING'
                          ? 'text-warning'
                          : data.item.cer_approveFaceStatus === 'APPROVED'
                          ? 'text-success'
                          : '',
                      ]"
                      >{{
                        data.item.cer_approveFaceStatus === "NOT_APPROVED"
                          ? "ยังไม่ได้ยืนยัน"
                          : data.item.cer_approveFaceStatus === "APPROVING"
                          ? "กำลังยืนยัน"
                          : data.item.cer_approveFaceStatus === "APPROVED"
                          ? "ยืนยันแล้ว"
                          : "-"
                      }}
                    </span></template
                  >
                </template>
                <template v-slot:cell(option)="data">
                  <div>
                    <router-link
                      class="text-light btn btn-primary btn-sm"
                      :to="{
                        name: 'members.face-verification.face-approve.courses',
                        params: { cerId: data.item.cer_id, cId: cId },
                      }"
                      target="_blank"
                    >
                      ตรวจสอบ
                    </router-link>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>

              <div v-if="!rows" class="text-center">
                <h6>ไม่มีข้อมูล</h6>
              </div>
            </template>
          </layout>

          <div>
            <b-row class="mt-4">
              <b-col cols="6 ">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="pr-3">แสดง</p>
                  <b-form-select
                    v-model="selectedShow"
                    style="width: 75px"
                    @change="changeShowData"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </b-form-select>
                  <p class="pl-3">จาก {{ rows }} รายการ</p>
                </div>
              </b-col>
              <b-col cols="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="promotion-list-table"
                  align="right"
                  last-number
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
export default {
  components: {
    PageHeader,
    layout,
  },

  data() {
    return {
      courseInfo: [],
      items: [],
      fields: [
        { key: "mp_name", label: "ชื่อผู้เรียน", thStyle: "width: 260px" },
        {
          key: "cer_completeDate",
          label: "วันที่เรียนจบ",
          thStyle: "width:160px",
        },
        { key: "cer_status", label: "สถานะการเรียน", thStyle: "width:150px" },
        {
          key: "cer_approveFaceStatus",
          label: "สถานะการยืนยันผลการตรวจสอบใบหน้า",
          thStyle: "width:180px",
        },
        { key: "option", label: "ตรวจสอบใบหน้าผู้เรียน" },
      ],
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
      search: null,
      isBusy: false,
      cId: this.$route.params.cId,
      fashId: this.$route.params.fashId,
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.items.length;
    },
    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      var showData = this.items.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.perPage)
      );
      return showData;
    },
  },
  created() {
    this.fetchCouseInfo();
    this.getFaceApproveEnroll();
  },

  methods: {
    async fetchCouseInfo() {
      await api.get("/v1/courses/" + this.cId).then(({ data }) => {
        this.courseInfo = data.data;
      });
    },

    async getFaceApproveEnroll() {
      this.isBusy = true;
      await api
        .get("/v1/face-approve-enroll/" + this.fashId)
        .then(({ data }) => {
          this.items = data.data;
           this.isBusy = false;
        });
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },

    searchName() {
      this.getFaceApproveEnroll().then(() => {
        this.items = this.items.filter((i) =>
          this.search
            ? i.mp_name !== null && i.mp_name.includes(this.search)
            : i.mp_name
        );
       
      });
    },
  },
};
</script>

<style scoped>
.input-search button {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>

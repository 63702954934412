<template>
  <b-card-group>
    <b-card class="bt-space">
      <div slot="header">
        <i class="fa fa-users" aria-hidden="true" /> จำนวนผู้สอบต่อปี
      </div>
      <div class="card_block_top_align ">
        <template v-if="participationPending">
          กำลังโหลด
        </template>
        <template v-else>
          <ExamParticipationChart :height="chartHeight" />
        </template>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ExamParticipationChart from "@/components/pages/exam-analytics/exams-overall/charts/ExamParticipationChart.vue";
export default {
  name: "ExamOverallExamParticipation",
  components: {
    ExamParticipationChart
  },
  data() {
    return {
      chartHeight: 250
    };
  },
  computed: {
    ...mapGetters("Company", ["participationPending", "companyInfo"])
  },
  created() {
    this.fetchCompanyParticipation(this.companyInfo.id);
  },
  methods: {
    ...mapActions("Company", ["fetchCompanyParticipation"])
  }
};
</script>

<style lang="scss" scoped>
.card_block_top_align {
  padding-top: 5%;
  padding-bottom: 0.5%;
}
.bt-space {
  margin-bottom: 1em;
}
</style>

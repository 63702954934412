var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column my-3"},[(_vm.mainQuestionTitle)?_c('div',[_c('mark-down',{attrs:{"message":_vm.mainQuestionTitle + '<br><br><strong>' + _vm.result.questionTitle + '</strong>'}})],1):_c('div',[_c('mark-down',{attrs:{"message":_vm.result.questionTitle}})],1),(_vm.result.questionType == 'MULTIPLE_CHOICE')?_c('div',{staticClass:"d-flex flex-column my-3"},_vm._l((_vm.result.questionChoices),function(item,index){return _c('span',{key:index,class:_vm.result.answerKey.choiceId.filter(
          (i) => i.choiceId == item.choiceId
        ).length > 0 && 'correct'},[_c('strong',[_c('mark-down',{attrs:{"message":(index + 1) + 
            '. ' +
            item.choiceText + 
            ' (' + 
            ((_vm.result.stat[item.choiceId] / _vm.result.total) * 100).toFixed(2) + 
            '%)'}})],1)])}),0):_vm._e(),(_vm.result.questionType == 'FILL_BLANK')?_c('div',{staticClass:"d-flex flex-column my-3"},_vm._l((_vm.result.stat),function(item,index){return _c('span',{key:index,class:_vm.result.answerKey.answerText == index && 'correct'},[(index != 'null')?_c('strong',[_vm._v(" "+_vm._s(index)+" ("+_vm._s(((_vm.result.stat[index] / _vm.result.total) * 100).toFixed(2))+"%)")]):_vm._e()])}),0):_vm._e(),_c('div',[_c('span',{staticClass:"float-right"},[_c('strong',[_vm._v(" ไม่ได้ตอบ ("+_vm._s(((_vm.result.blank / _vm.result.total) * 100).toFixed(2))+"%)")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="">
    <ExamExamUpload />
  </div>
</template>

<script>
import ExamExamUpload from "./ModalExamUpload.vue";
export default {
  name: "ExamManagementUpload",
  components: { ExamExamUpload },
};
</script>

<style>
</style>

<template>
  <div style="margin:0 1rem 0 1rem">
    <layout>
      <template v-slot:header>
        <PageHeader title="ข้อมูลการชำระเงินของผู้เรียน"></PageHeader
      ></template>
      <template v-slot:body>
        <paymentSummary
          :apiRoute="`/v1/analytics/tfac/get-payment-summary`"
          :perPage="20"
        />
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/pages/analytics/shared/Layout.vue';

import paymentSummary from './panel/PaymentSummaryPanel';
import PageHeader from '@/components/layouts/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,
    paymentSummary,
  },
};
</script>

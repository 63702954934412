<template>
  <div>
    <page-header
      :title="`ความก้าวหน้าหลักสูตร ${courseInfo.courseName}`"
    ></page-header>
    <b-container class="widthPage mx">
      <div class="mb-3">
        <b-card-img-lazy
          v-if="courseInfo.courseThumbnail"
          :src="courseInfo.courseThumbnail"
          alt=""
          top
          style="width: 150px; margin: 10px 10px 10px 20px"
        ></b-card-img-lazy>
        <h5
          class="nav_top_align text-dark font-weight-bold"
          style="display: inline"
        >
          {{ courseInfo.courseName }}
        </h5>
      </div>

      <b-row>
        <b-col cols="12">
          <StudentCourseCompleteRule />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="8">
          <StudentLearningLectures />
        </b-col>
         <b-col cols="4">
          <StudentLearningLecturesQuiz />
        </b-col>
      </b-row>

       <b-row class="mt-3">
        <b-col cols="12">
          <StudentLearningFaceDetection v-if="comId != 513 && comId != 512 && comId != 515"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import StudentCourseCompleteRule from "./panel/StudentCourseCompleteRule.vue";
import StudentLearningLectures from "./panel/StudentLearningLectures.vue";
import StudentLearningLecturesQuiz from "./panel/StudentLearningLecturesQuiz.vue";
import StudentLearningFaceDetection from "./panel/StudentLearningFaceDetection.vue";

import api from "@/services/api.js";

export default {
  components: {
    PageHeader,
    StudentCourseCompleteRule,
    StudentLearningLectures,
    StudentLearningLecturesQuiz,
    StudentLearningFaceDetection,
  },
  data() {
    return {
      courseInfo: [],
      cId: this.$route.params.cId,
      comId: this.$store.state.Company.companyInfo.id,
    };
  },
  async mounted() {
    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data.data;
    });
  },
};
</script>
<style>
.user-learn-progress .main {
  padding-top: 0px;
}
</style>
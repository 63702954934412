<template>
  <div>
    <header class="head" style="margin-left: -70px">
      <div class="main-bar">
        <b-container class="pl-5">
          <b-row>
            <b-col >
              <h5 class="nav_top_align text-dark font-weight-bold title-head " >
                <i v-show="icon" :class="`fa fa-${icon}`" class="ml-1" aria-hidden="true"></i>
                {{ title }}
              </h5>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    icon: { type: String, default: null },
    title: { type: String, default: null },
  },
};
</script>

<style scoped>

@media (max-width: 992px) {
  .title-head {
      padding-left: 5%;
  }
}
</style>
<template>
  <li class="dropdown_menu" :class="mainExamListCssObj">
    <a v-b-toggle.membersCollapse @click.prevent="toggleCollapse">
      <b-icon-people-fill class="mr-1"></b-icon-people-fill>
      <span class="link-title menu_hide">ผู้เรียน</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="membersCollapse" :visible="false" accordion="accordion">
      <main-exam-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainExamCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import authService from "@/services/auth";
import _ from "lodash";
import AuthMixin from "@/mixins/auth-mixin.js";
import CompanySideBars from "../CompanySidebarSwitch";

export default {
  components: {
    MainExamCollapseMenu,
  },
  mixins: [AuthMixin],
  data() {
    return {
      isActive: false,
      collapseList: [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
      ],
      comId: localStorage.getItem("ComId"),
      memberId: JSON.parse(localStorage.getItem("user")).id,
    };
  },
  computed: {
    mainExamListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  mounted() {
    if (this.isOrgInstructor) {
      this.collapseList = [
        {
          title: "ลงทะเบียนเรียน",
          to: { name: "course.enroll.index" },
        },
      ];
    }
    if (this.isOrgAdmin) {
      this.collapseList = [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
        {
          title: "จัดการกลุ่มผู้เรียน",
          to: {
            name: "group.index",
          },
        },
        {
          title: "จัดการผู้ใช้งาน",
          to: {
            name: "members.role",
          },
        },
        {
          title: "ลงทะเบียนเรียน",
          to: {
            name: "course.enroll.index",
          },
        },
      ];
    }
    if (this.isTutor == true && !this.IsTutorAdmin) {
      this.collapseList = [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
        {
          title: "ลงทะเบียนเรียน",
          to: {
            name: "course.enroll.index",
          },
        },
      ];
    } else if (this.IsTutorAdmin) {
      this.collapseList = [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
        {
          title: "จัดการกลุ่มผู้เรียน",
          to: {
            name: "group.index",
          },
        },
        {
          title: "ลงทะเบียนเรียน",
          to: {
            name: "course.enroll.index",
          },
        },
        {
          title: "จัดการผู้ใช้งาน",
          to: {
            name: "members.role",
          },
        },
      ];
    }

    if (this.canSpecificUserAccess) {
      this.collapseList = [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
      ]
    }
    this.checkCanReadFaceRegistration();
    this.checkCanReadFaceRecognition();
  },
  methods: {
    ...CompanySideBars,
    toggleCollapse() {},
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
    checkCanReadFaceRegistration() {
      if (this.isMenuShow("member-faceRegistration")) {
        authService
          .hasPermissions(["approve-face-self-registration"])
          .then((result) => {
            if (
              result &&
              (this.isOrgAdmin || this.IsTutorAdmin) &&
              (this.isInsuranceType() || this.isAccountingType())
            ) {
              this.collapseList.push({
                title: "คำขอลงทะเบียนใบหน้า",
                to: {
                  name: "face.verify.overview",
                },
              });
              this.collapseList.push({
                title: "ตรวจสอบใบหน้าลงทะเบียน",
                to: {
                  name: "face.verify.overview2",
                },
              });
            }
          });
      }
    },
    checkCanReadFaceRecognition() {
      if (this.isMenuShow("member-faceRecognition")) {
        authService
          .hasPermissions(["approve-face-recognition"])
          .then((result) => {
            if (
              result &&
              (this.isOrgAdmin || this.IsTutorAdmin) &&
              (this.isInsuranceType() || this.isAccountingType()) &&
              this.comId != 519
            )
              this.collapseList.push({
                title: "การตรวจสอบใบหน้า",
                to: {
                  name: "members.face-verification.index",
                },
              });
          });
      }
    },
  },
};
</script>

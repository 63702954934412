import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    courseAttentionList: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        path: null,
        per_page: 10,
        to: 0,
        total: 0,
      },
    },
    classList: {},
    findClass: [],
    classInfo: [],
    courseReport: [],
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status === null ? !state.isLoading : status;
    },
    SET_ATTENTION_COURSE_LIST(state, data) {
      state.courseAttentionList = data;
    },
    SET_CLASS_LIST(state, data) {
      state.classList = data;
    },
    SET_COURSE_REPORT(state, data) {
      state.courseReport = data;
    },
    SET_FIND_CLASS(state, data) {
      state.findClass = data;
    },
    SET_CLASS_INFO(state, data) {
      state.classInfo = data;
    },
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    courseAttentionList(state) {
      return state.courseAttentionList;
    },
    classList(state) {
      return state.classList;
    },
    findClass(state) {
      return state.findClass;
    },
    classInfo(state) {
      return state.classInfo;
    },
    courseReport(state) {
      return state.courseReport;
    }
  },
  actions: {
    async fetchCourseAttentionList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/companies/courses/attendance`, {
          params,
        })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_ATTENTION_COURSE_LIST", data);
          console.log(data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async fetchClassList(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses/${courseId}/attendance`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_CLASS_LIST", data.data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async fetchCourseReport(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses/${courseId}/attendance/details`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_REPORT", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async fetchFindClass(context, params) {
      return await api
        .get(`/v1/courses/${params.courseId}/attendance/find-class`, { params })
        .then(({ data }) => {
          context.commit("SET_FIND_CLASS", data);
        })
        .catch(({ errors }) => {
          console.log("vuex:", errors);
        });
    },
    async insertClassAttendance(context, params) {
      await api.post(`/v1/companies/courses/attendance`, params);
    },
    async fetchClassInfo(context, classId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/class/${classId}/attendance`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_CLASS_INFO", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async deleteClassAttendance(context, classId) {
      await api.delete(`/v1/class/${classId}`);
    },
    async updateClassAttendanceInfo(context, params) {
      await api.put(`/v1/class/${params.classId}/attendance`, params.body);
    },
  },
};

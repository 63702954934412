export default {
  data() {
    return {
      backgroundColor: "",
      count: 0,
      mockPieChart: false,
      mockPieStyle: {
        borderRadius: "50%",
        backgroundColor: "#f4f4f4",
        width: "250px",
        height: "250px",
        marginLeft: "auto",
        marginRight: "auto",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
      },
      mockPieText: {
        textAlign: "center",
        fontSize: "36px",
      }
    };
  },
  methods: {
    mockBarChart(chartData) {
      for (var i = 0; i < chartData.length; i++) {
        this.count += chartData[i];
      }
      if (this.count == 0 || chartData.length == 0) {
        this.backgroundColor = "#F4F4F4";
        for (i = 0; i < 6; i++) {
          chartData[i] = 10000 * (i * 2 + 2);
        }
        for (i = 0; i < 6; i++) {
          chartData[i + 6] = 10000 * (12 - i * 2);
        }
      } else {
        this.backgroundColor = "#E78C06";
      }
      this.count = 0;
    },
    checkmockBarChart(chartData) {
      for (var i = 0; i < chartData.length; i++) {
        this.count += chartData[i];
      }
      if (this.count == 0 || chartData.length == 0) {
        this.backgroundColor = "#F4F4F4";
        this.label = true;
      } else {
        this.backgroundColor = "#E78C06";
        this.label = false;
      }
      this.count = 0;
    },
    mockBarChartWeek(chartData) {
      for (var i = 0; i < Object.values(chartData).length; i++) {
        this.count += Object.values(chartData)[i];
      }
      if (this.count === 0 || Object.values(chartData).length === 0) {
        this.backgroundColor = "#F4F4F4";
        this.label = true;
      } else {
        this.backgroundColor = "#E78C06";
        this.label = false;
      }
      this.count = 0;
    },
    checkMockPieChart(chartData) {
      if (chartData == 0) {
        this.mockPieChart = true;
      } else {
        this.mockPieChart = false;
      }
    }
  }
};

<template>
  <div>
    <div id="wrap" v-if="template === 'A'">
      <div id="top">
        <!-- .navbar -->
        <navbar-a
          :profile="currentProfile"
          :sidebar-opened="false"
          @sidebarOpened="false"
        ></navbar-a>
        <!-- /.navbar -->
      </div>
      <div id="content" class="bg-container">
        <router-view
          :company="currentProfile.company"
          :user="user"
        ></router-view>
      </div>
    </div>
    <div id="wrap" v-else>
      <content-modal></content-modal>
      <div class="wrapper">
        <sidebar
          :profile="currentProfile"
          :company="currentProfile.company"
          :sidebar-opened="sidebarOpened"
          @sidebarOpened="toggleSidebar"
        ></sidebar>

        <div
          id="content"
          class="bg-container"
          @click="openClosebar"
          :class="{
            togtoright: !sidebarOpened
          }"
        >
          <router-view
            :company="currentProfile.company"
            :user="user"
            :sidebar-opened="sidebarOpened"
            :class="{
              togtoright: sidebarOpened
            }"
          ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarA from "@/components/layouts/NavBarA.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import { mapActions, mapGetters } from "vuex";
import ContentModal from "@/components/shared/ContentModal.vue";

export default {
  name: "App",
  components: { NavbarA, Sidebar, ContentModal },
  data() {
    return {
      sidebarOpened: true
    };
  },
  computed: {
    ...mapGetters("Authentication", ["authUser"]),
    ...mapGetters("Company", ["companyInfo"]),
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    currentCompany() {
      return this.getCurrentCompany(this.user.companies);
    },
    currentProfile() {
      return this.getCurrentProfile(this.user.profiles);
    },
    template() {
      const routeObj = this.$route;

      if (routeObj.matched?.[0]?.props?.default?.template) {
        return routeObj.matched[0].props.default.template;
      } else if (routeObj?.meta?.template) {
        return routeObj.meta.template;
      }

      return null;
    }
  },
  created() {
    // Check which template should be used
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "login" });
      // window.location.href = "/login";
    } else {
      localStorage.setItem("ComId", this.currentProfile.company.id);
      this.setCompanyInfo(this.currentProfile.company);
    }
  },
  mounted() {
    if((Date.now() - localStorage.getItem("loginTime")) > (8 * 60 * 60 * 1000)){
        this.$store
          .dispatch("Authentication/logout")
          .then(() => {
            window.location.href = "/login";
          })
          .catch(err => console.log(err));
    }
    setInterval(() => {
      // Check if token is expired, redirect user to login again
      if((Date.now() - localStorage.getItem("loginTime")) > (8 * 60 * 60 * 1000)){
        this.$store
          .dispatch("Authentication/logout")
          .then(() => {
            window.location.href = "/login";
          })
          .catch(err => console.log(err));
      }
    }, 30000);
  },
  methods: {
    ...mapActions("Authentication", ["setUser"]),
    ...mapActions("Company", ["setCompanyInfo"]),
    getCurrentCompany(companies) {
      const requestCompany = this.$route.params.company;
      if (requestCompany) {
        let currentCompany = companies.find(
          data => data.url === requestCompany
        );
        if (currentCompany) {
          return currentCompany;
        }
      }

      return companies[0];
    },
    getCurrentProfile(profiles) {
      const requestCompany = this.$route.params.company;
      if (requestCompany) {
        let currentCompany = profiles.find(
          data => data.company.url === requestCompany
        );
        if (currentCompany) return currentCompany;
      } else if (localStorage.getItem("ComId")) {
        const currentComId = localStorage.getItem("ComId");
        let currentCompany = profiles.find(
          data => data.company.id == currentComId
        );
        if (currentCompany) return currentCompany;
      }

      return profiles[0];
    },
    toggleSidebar($value) {
      this.sidebarOpened = $value;
    },
    openClosebar() {
     this.sidebarOpened = false
    },
  }
};
</script>

<style scoped>
#content {
  height: calc(100vh - 52px);
  overflow: auto;

}

@media (min-width: 992px) {
  #content {
      padding-left: 5%;
  }
}
</style>

<style lang="css">
.table th {
  border-top: 0px;
}
.togtoright {
  padding-left: 15%;
}
</style>

<template>
  <b-card :class="$options.name" title="คะแนนต่ำสุด" class="text-center">
    <template v-if="examStatPending || examInfoPending">
      <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> กำลังโหลด
    </template>
    <template v-else>
      <b-container :class="`${$options.name}__body`" class="mb-3" fluid>
        <b-card-text>
          <h1>{{ examStatData.minScore }}</h1>
        </b-card-text>
      </b-container>
      <em slot="footer">คิดเป็น {{ scorePercentage }} จากคะแนนเต็ม </em>
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExamLowestScore",
  computed: {
    ...mapGetters("Company", [
      "examStatPending",
      "examStatData",
      "examInfoPending",
      "examInfoData"
    ]),
    scorePercentage() {
      let percentage =
        (this.examStatData.minScore / this.examInfoData.totalScore) * 100;
      return `${percentage.toFixed(2)} %`;
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamLowestScore {
  padding-top: 1em;
  &__body {
    padding-top: 3.5em;
  }
  h1 {
    font-size: 3em;
  }
}
.card-title {
  color: #ffb12e;
}
.card-footer {
  background-color: #fff;
  color: #707070;
  border: 0;
  font-style: inherit;
}
.card {
  margin-bottom: 1em;
}
</style>

<template>
  <div>
    <PageHeader title="ภาพรวมรายได้" style="margin-left: -10%"></PageHeader>
    <b-container class="widthPage pt-5">
      <!-- <layout> -->
      <!-- <template v-slot:header>
        <PageHeader title="สรุปภาพรวมรายได้"></PageHeader
      ></template> -->
      <!-- <template v-slot:body> -->
      <b-row class="mb-3">
        <b-col cols="12">
          <revenueYear
            :apiRoute="`/v1/analytics/revenues/get-yearly-revenue`"
          ></revenueYear>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="2">
          <b-row>
            <b-col cols="12" class="mb-3">
              <avgPrice
                :apiRoute="
                  `/v1/analytics/revenues/get-average-course-price-per-hour`
                "
              ></avgPrice>
            </b-col>
            <b-col cols="12">
              <avgLearner
                :apiRoute="
                  `/v1/analytics/revenues/get-average-revenue-per-learner`
                "
              ></avgLearner>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="10">
          <revenueSummary :perPage="10" />
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col cols="4">
          <payMethod
            :apiRoute="`/v1/analytics/revenues/get-order-payment-method`"
          ></payMethod>
        </b-col>
        <b-col cols="3">
          <topSpender
            :apiRoute="`/v1/analytics/revenues/get-top-spender`"
          ></topSpender>
        </b-col>
        <b-col cols="5">
          <coupon
            :apiRoute="`/v1/analytics/revenues/get-revenue-by-coupon`"
            :perPage="10"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="12">
          <paymentSummary
            :apiRoute="`/v1/analytics/tfac/get-payment-summary`"
            :perPage="10"
          ></paymentSummary>
        </b-col>
      </b-row>
      <!-- </template> -->
      <!-- </layout> -->
    </b-container>
  </div>
</template>

<script>
// import Layout from "@/components/pages/analytics/shared/Layout.vue";
import revenueYear from './panel/RevenueYearGraphPanel';
import avgPrice from './panel/AverageCoursePricePerHourPanel';
import avgLearner from './panel/AverageRevenuePerLearnerPanel';
import payMethod from './panel/PaymentMethodPanel';
import topSpender from './panel/TopSpenderPanel';
import revenueSummary from './panel/RevenueSummaryPanel';
import coupon from './panel/CouponPanel';
// import paymentSummary from "./panel/PaymentSummaryPanel";
import PageHeader from '@/components/layouts/PageHeader.vue';

export default {
  components: {
    // Layout,
    PageHeader,
    revenueYear,
    avgPrice,
    avgLearner,
    payMethod,
    topSpender,
    revenueSummary,
    coupon,
  },
};
</script>
<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

<template>
  <div>
    <ExamInsightHeader />
    <ExamInsightContent :exam-id="examId" />
  </div>
</template>

<script>
import ExamInsightHeader from "@/components/pages/exam-analytics/exam-insight/ExamInsightHeader.vue";
import ExamInsightContent from "@/components/pages/exam-analytics/exam-insight/ExamInsightContent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ExamInsightHeader,
    ExamInsightContent
  },
  props: {
    examId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("Company", ["companyInfo"])
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions("Company", ["fetchExamStat", "fetchExamById"]),
    async fetch() {
      await this.fetchExamStat({
        companyId: this.companyInfo.id,
        examId: this.examId
      });
      await this.fetchExamById(this.examId);
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-information"},[_c('h5',[_vm._v("ใบหน้าผู้เรียน")]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"img mr-4"},[_c('b-img',{staticClass:"profile-image-style",attrs:{"src":_vm.memberInfo
                ? _vm.memberInfo.image_path
                  ? `${_vm.baseUrl}/${_vm.memberInfo.image_path.slice(
                      1,
                      -1
                    )}?token=${_vm.token}`
                  : _vm.baseUrlImage
                : '',"rounded":"circle"}})],1),_c('div',{staticClass:"box-content d-flex flex-column justify-content-between ml-5"},[_c('div',{staticClass:"box-name"},[_c('h6',[_vm._v("ชื่อผู้เรียน")]),_c('p',[_vm._v(_vm._s(_vm.memberInfo ? _vm.memberInfo.mp_name : "-"))])]),_c('div',{staticClass:"box-cer-status"},[_c('h6',[_vm._v("สถานะเรียนจบ")]),_c('p',[_vm._v(" "+_vm._s(_vm.memberInfo ? _vm.memberInfo.cer_status == "COMPLETED" ? "เรียนจบแล้ว" : _vm.memberInfo.cer_status == "IN_PROGRESS" ? "กำลังเรียน" : _vm.memberInfo.cer_status == "NOT_STARTED" ? "ยังไม่เริ่มเรียน" : "-" : "-")+" ")])])])])]),_c('b-col',{staticClass:"d-flex flex-column justify-content-between",attrs:{"cols":"6"}},[_c('div',{staticClass:"box-name"},[_c('h6',[_vm._v("สถานะการยืนยันผลการตรวจสอบใบหน้า")]),_c('p',{class:[
            _vm.memberInfo
              ? _vm.memberInfo.cer_approveFaceStatus == 'NOT_APPROVED'
                ? 'text-danger'
                : _vm.memberInfo.cer_approveFaceStatus == 'APPROVING'
                ? 'text-warning'
                : _vm.memberInfo.cer_approveFaceStatus == 'APPROVED'
                ? 'text-success'
                : '-'
              : '-',
          ]},[_vm._v(" "+_vm._s(_vm.memberInfo ? _vm.memberInfo.cer_approveFaceStatus == "NOT_APPROVED" ? "ยังไม่ยืนยัน" : _vm.memberInfo.cer_approveFaceStatus == "APPROVING" ? "กำลังยืนยัน" : _vm.memberInfo.cer_approveFaceStatus == "APPROVED" ? "ยืนยันแล้ว" : "-" : "-")+" ")])]),_c('div',{staticClass:"box-cer-status"},[_c('h6',[_vm._v("ผลยืนยันความถูกต้อง")]),_c('p',[_vm._v(" "+_vm._s(_vm.memberInfo ? _vm.memberInfo.cer_approveFacePercent ? _vm.memberInfo.cer_approveFacePercent.toFixed(2) + "%" : "-" : "-")+" ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
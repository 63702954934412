<template>
  <div>
    <b-media class="px-3">
      <template v-slot:aside>
        <b-img
          blank-color="#ccc"
          alt="placeholder"
          :src="comment.mp_displayPath"
          class="user-profile-image"
        ></b-img>
      </template>

      <h6 class="mt-3">
        {{ comment.mp_firstName + ' ' + comment.mp_lastName }}
      </h6>
      <p
        style="word-break: break-all;"
        v-html="comment.dis_message"
        v-if="!isEditing"
      ></p>
      <div v-if="isEditing">
        <comment-edit-form
          :id="'edit_' + comment.dis_id"
          :parentId="comment.dis_id"
          :reply="comment.dis_message"
          @commentedited="edited()"
        ></comment-edit-form>
      </div>

      <div class="float-right">
        <div
          class="expand-collapse-button pr-2 text-primary"
          style="display: inline-block"
          v-if="comment.m_id == mId && !isEditing"
          @click="isEditing = true"
        >
          แก้ไข
        </div>

        <div
          class="expand-collapse-button pr-2 text-primary"
          style="display: inline-block"
          v-if="comment.m_id == mId && isEditing"
          @click="isEditing = false"
        >
          ยกเลิกแก้ไข
        </div>
        <div
          class="expand-collapse-button pr-2 text-primary"
          style="display: inline-block"
          v-b-toggle="'collapse-' + comment.dis_id"
        >
          <b-icon-reply-fill /> <span>ตอบกลับ</span>
        </div>
        <div
          class="expand-collapse-button text-primary"
          style="display: inline-block"
          v-b-toggle="'collapse-' + comment.dis_id"
        >
          <span @click="toggleLevel">
            {{ getReply(comment.dis_id).length }} การตอบกลับ
          </span>
        </div>
      </div>
      <div class="w-100 mt-5" style="border-bottom: 0.1rem solid #ddd;"></div>
      <b-collapse
        :id="'collapse-' + comment.dis_id"
        :accordion="'accordion-' + level"
        class="mt-2 pt-5"
      >
        <comment-lists
          :collection="collection"
          :comments="getReply(comment.dis_id)"
          :level="level"
        ></comment-lists>
        <comment-form
          :id="'reply_' + comment.dis_id"
          :parentId="comment.dis_id"
          @commentposted="$emit('posted')"
        ></comment-form>
      </b-collapse>
    </b-media>
  </div>
</template>
<script>
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import CommentEditForm from './CommentEditForm';
export default {
  components: {
    'comment-lists': CommentList,
    'comment-form': CommentForm,
    'comment-edit-form': CommentEditForm,
  },
  props: ['collection', 'comment', 'level'],
  data() {
    return {
      isEditing: false,
      editorConfig: {
        btns: [
          ['formatting'],
          ['justifyLeft', 'justifyCenter', 'justifyRight'],
          ['unorderedList', 'orderedList'],
          ['strong', 'em', 'link'],
        ],
        autogrow: true,
        removeformatPasted: true,
      },
      topic: 0,
      reply: '',
      replied: 0,
      notReplied: 0,
      cId: this.$route.params.cId,
      isBusy: true,
      items: [],
      isOpened: false,
      user: JSON.parse(localStorage.getItem('user')),
      mId: JSON.parse(localStorage.getItem('user')).id,
      filterOptions: [
        { value: null, text: 'คำถามทั้งหมด' },
        { value: true, text: 'คำถามที่ยังไม่ตอบ' },
        { value: false, text: 'คำถามที่ตอบแล้ว' },
      ],
      filter: null,
      orderOptions: [
        { value: 'answerAsc', text: 'คำตอบน้อยไปมาก' },
        { value: 'answerDesc', text: 'คำตอบมากไปน้อย' },
        { value: 'dateDesc', text: 'วันที่โพสต์ล่าสุด' },
      ],
      orderBy: 'dateDesc',
      busy: false,
      response: {},
    };
  },
  methods: {
    toggleLevel() {
      this.isOpened = !this.isOpened;
      if (this.comment.dis_parent_id) {
        const elemId = '#reply_' + this.comment.dis_parent_id;
        console.log('elemId', elemId);
        console.log('isOpened', this.isOpened);
        if (this.isOpened)
          document.querySelector(elemId).style.display = 'none';
        else document.querySelector(elemId).style.display = 'block';
      }
    },
    edited() {
      this.isEditing = false;
      this.$emit('edited');
    },
    getReply(disId) {
      return this.collection.filter((i) => i.dis_parent_id == disId);
    },
  },
};
</script>
<style scoped>
.text-head {
  color: #f0a502;
  text-align: center;
  font-weight: bold;
  font-family: 'Sarabun', sans-serif;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
  width: 600px;
}
.expand-collapse-button:hover {
  cursor: pointer;
}
</style>

<template>
  <b-card
    :img-src="courseInfo.courseThumbnail"
    img-alt
    img-top
    style="max-width: 20rem;"
    no-body
    footer-border-variant="white"
    footer-bg-variant="white"
    class="mb-4 text-dark"
  >
    <b-card-body>
      <div class="my-2">
        <b-badge
          v-for="(tag, index) in courseInfo.courseTags"
          :key="index"
          variant="secondary"
          class="mr-1"
          >{{ tag.tagName }}</b-badge
        >
      </div>
      <div class="course-title mt-2 text-crop-2">
        {{ courseInfo.courseName }}
      </div>
      <b-card-text>
        <a class="text-csq small">{{ courseInfo.courseOwner.ownerName }}</a>
      </b-card-text>
    </b-card-body>
    <template v-slot:footer>
      <b-card-text>
        <span class="text-csq">
          <div>
            ผู้ทำบัญชี: <div><span class="text-primary">บัญชี</span>
            {{ courseInfo.tfac.accAccHour | formatTfacHour }}
            <span class="text-primary">จรรยาบรรณ</span>
            {{ courseInfo.tfac.accEthicHour | formatTfacHour}}
            <span class="text-primary">อื่นๆ</span>
            {{ courseInfo.tfac.accOtherHour | formatTfacHour}}</div>
          </div>
          <div>
            ผู้สอบบัญชีรับอนุญาต: <div><span class="text-primary">บัญชี</span>
            {{ courseInfo.tfac.cpaAccHour | formatTfacHour}}
            <span class="text-primary">จรรยาบรรณ</span>
            {{ courseInfo.tfac.cpaEthicHour | formatTfacHour}}
            <span class="text-primary">อื่นๆ</span>
            {{ courseInfo.tfac.cpaOtherHour | formatTfacHour}}</div>
          </div>
        </span>
        <div class="text-csq">
          ราคา
        </div>
        <div class="text-csq">
          <div v-if="courseInfo.membershipPrice[0].promotionPrice!==null">
            <span class="text-primary">สมาชิก:</span>
            <span class="text-danger"> {{ courseInfo.membershipPrice[0].promotionPrice|displayFree}}</span>
            <del class="text-muted small"> {{ courseInfo.membershipPrice[0].price|displayFree}}</del>
          </div>
          <div v-else> <span class="text-primary">สมาชิก:</span> {{courseInfo.membershipPrice[0].price|displayFree}}</div>
          <div v-if="courseInfo.promotionPrice!==null">
            <span class="text-primary">บุคคลทั่วไป:</span>
            <span class="text-danger"> {{courseInfo.promotionPrice|displayFree}}</span>
            <del class="text-muted small"> {{courseInfo.price|displayFree}}</del>
          </div>
          <div v-else><span class="text-primary"> บุคคลทั่วไป:</span> {{ courseInfo.price|displayFree}}</div>
        </div>
      </b-card-text>
    </template>
  </b-card>
</template>

<script>
import Numeral from "numeral";
export default {
  name: "TfacInfoCard",
  props: {
    courseInfo: {
      type: Object,
      default: null,
    },
  },
  filters:{
    displayFree: function(value) {
      if (value==0 || value==null) {
        return 'ฟรี';
      }
      return Numeral(value).format("0,0") + ' บาท';
    },
    formatTfacHour: function(value){
      return parseInt(value)+':'+Numeral((value%1)*60).format("00")+' ชั่วโมง';
    },
  },
  methods: {
  },
};
</script>

/**
 * mutate user state.
 * @param {Object} state
 * @param {Object} user - data to be written into state.
 */
export const setUser = (state, user) => {
  state.authUser = user;
};

/**
 * mutate is authenticated.
 * @param {Object} state
 * @param {boolean} isAuthenticated - boolean to be written into state.
 */
export const setIsAuthenticated = (state, isAuthenticated) => {
  state.isAuthenticated = isAuthenticated;
};

/**
 * mutate auth token.
 * @param {Object} state
 * @param {string} token - token string to be written into state.
 */
export const setAuthToken = (state, token) => {
  state.authToken = token;
};

/**
 * mutate an access token.
 * @param {Object} state
 * @param {string} token - token string to be written into state.
 */
export const setAccessToken = (state, token) => {
  state.accessToken = token;
};

import { doAsync } from "@/helpers/store";
import async from "@/config/store";

/**
 * setCompanyInfo is for setting company info sending from laravel.
 * @param {Object} companyInfo - represent company basic info (id, name, type, addr) sending from laravel.
 */
export const setCompanyInfo = (context, companyInfo) => {
  context.commit("setCompanyInfo", companyInfo);
};

export const fetchCompanyParticipation = async (context, companyId) => {
  await doAsync(context, {
    url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/companies/${companyId}/exam-analytics/participation-by-month`,
    mutationTypes: async.ASYNC_PARTICIPATION
  });
};

export const fetchExamAnalytics = async (context, companyId) => {
  await doAsync(context, {
    url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/companies/${companyId}/exam-analytics`,
    mutationTypes: async.ASYNC_EXAM_ANALYTICS
  });
};

export const fetchAnswersheets = async (
  context,
  {
    companyId,
    pagination,
    markedFrom,
    markedTo,
    examTitle,
    examId,
    nameOrEmail
  }
) => {
  const { sortBy, descending, page, rowsPerPage } = pagination;
  const sortingDirection = descending ? "DESC" : "ASC";
  const offset = (page - 1) * rowsPerPage;
  const whereFilter = {
    ...((!!markedFrom ||
      !!markedTo ||
      !!examTitle ||
      !!examId ||
      !!nameOrEmail) && {
      where: {
        ...(!!markedFrom && { answerSheetMarkedAtFrom: markedFrom }),
        ...(!!markedTo && { answerSheetMarkedAtTo: markedTo }),
        ...(!!examTitle && { examTitle }),
        ...(!!examId && { examId }),
        ...(!!nameOrEmail && { answerSheetMemberQuery: nameOrEmail })
      }
    })
  };
  const filter = {
    ...whereFilter,
    skip: offset,
    limit: rowsPerPage
  };
  if (sortBy) {
    filter.order = `${sortBy} ${sortingDirection}`;
  }
  await doAsync(context, {
    url: `${
      process.env.VUE_APP_EXAM_API_BASE_URL
    }/companies/${companyId}/exam-analytics/answer-sheets?filter=${JSON.stringify(
      filter
    )}`,
    mutationTypes: async.ASYNC_EXAM_ANSWERSHEETS
  });
};

export const fetchExamStat = async (context, { companyId, examId }) => {
  await doAsync(context, {
    url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/companies/${companyId}/exam-stats/${examId}`,
    mutationTypes: async.ASYNC_EXAM_STAT
  });
};

export const fetchExamById = async (context, examId) => {
  await doAsync(context, {
    url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${examId}`,
    mutationTypes: async.ASYNC_EXAM_INFO
  });
};

export const fetchAllExamsByCompanyId = async (
  context,
  {
    companyId,
    pagination,
    examTitle,
    examUpdatedAtFrom,
    examUpdatedAtTo,
    nameOrEmail
  }
) => {
  const { page, rowsPerPage } = pagination;
  const offset = (page - 1) * rowsPerPage;
  const whereFilter = {
    ...((!!examTitle ||
      !!examUpdatedAtFrom ||
      !!examUpdatedAtTo ||
      !!nameOrEmail) && {
      where: {
        ...(!!examTitle && { examTitle }),
        ...(!!examUpdatedAtFrom && { examUpdatedAtFrom }),
        ...(!!examUpdatedAtTo && { examUpdatedAtTo }),
        ...(!!nameOrEmail && { answerSheetMemberQuery: nameOrEmail })
      }
    })
  };
  const filter = {
    ...whereFilter,
    skip: offset,
    limit: rowsPerPage
  };
  await doAsync(context, {
    url: `${
      process.env.VUE_APP_EXAM_API_BASE_URL
    }/companies/${companyId}/exams?filter=${JSON.stringify(filter)}`,
    mutationTypes: async.ASYNC_COMPANY_EXAMS
  });
};

export const fetchExamsCountByCompanyId = async (
  context,
  { companyId, examTitle, examUpdatedAtFrom, examUpdatedAtTo, nameOrEmail }
) => {
  const whereFilter = {
    ...((!!examTitle ||
      !!examUpdatedAtFrom ||
      !!examUpdatedAtTo ||
      !!nameOrEmail) && {
      where: {
        ...(!!examTitle && { examTitle }),
        ...(!!examUpdatedAtFrom && { examUpdatedAtFrom }),
        ...(!!examUpdatedAtTo && { examUpdatedAtTo }),
        ...(!!nameOrEmail && { answerSheetMemberQuery: nameOrEmail })
      }
    })
  };
  const filter = {
    ...whereFilter
  };
  await doAsync(context, {
    url: `${
      process.env.VUE_APP_EXAM_API_BASE_URL
    }/companies/${companyId}/exam-count?filter=${JSON.stringify(filter)}`,
    mutationTypes: async.ASYNC_EXAMS_COUNT
  });
};

export const fetchAnswersheetsCountByCompanyId = async (
  context,
  { companyId, examTitle, markedTo, markedFrom, examId, nameOrEmail }
) => {
  const whereFilter = {
    ...((!!markedFrom ||
      !!markedTo ||
      !!examTitle ||
      !!examId ||
      !!nameOrEmail) && {
      where: {
        ...(!!markedFrom && { answerSheetMarkedAtFrom: markedFrom }),
        ...(!!markedTo && { answerSheetMarkedAtTo: markedTo }),
        ...(!!examTitle && { examTitle }),
        ...(!!examId && { examId }),
        ...(!!nameOrEmail && { answerSheetMemberQuery: nameOrEmail })
      }
    })
  };
  const filter = {
    ...whereFilter
  };
  await doAsync(context, {
    url: `${
      process.env.VUE_APP_EXAM_API_BASE_URL
    }/companies/${companyId}/exam-analytics/answer-sheet-count?filter=${JSON.stringify(
      filter
    )}`,
    mutationTypes: async.ASYNC_ANSWERSHEETS_COUNT
  });
};

export const fetchAllExamsDetails = async (
  context,
  {
    companyId,
    pagination,
    examTitle,
    examUpdatedAtFrom,
    examUpdatedAtTo,
    nameOrEmail
  }
) => {
  await context.dispatch("fetchAllExamsByCompanyId", {
    companyId,
    pagination,
    examTitle,
    examUpdatedAtFrom,
    examUpdatedAtTo,
    nameOrEmail
  });
};

export const fetchAnswerSheetById = async (
  context,
  { companyId, answersheetId, authToken }
) => {
  await doAsync(context, {
    url: `${process.env.VUE_APP_EXAM_API_BASE_URL}/companies/${companyId}/answer-sheets/${answersheetId}?access_token=${authToken}`,
    mutationTypes: async.ASYNC_EXAM_ANSWERSHEET
  });
};

export const fetchCompanyCourses = async (context, companyId) => {
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_API_BASE_URL}/v1/companies/${companyId}/courses`,
      method: "get"
    },
    mutationTypes: async.ASYNC_COMPANY_COURSES
  });
};

export const fetchCompanyModules = async (context, courseId) => {
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_API_BASE_URL}/v1/courses/${courseId}/modules`,
      method: "get"
    },
    mutationTypes: async.ASYNC_COMPANY_MODULES
  });
};

export const fetchCompanyInstructors = async (context, companyId) => {
  await doAsync(context, {
    url: {
      url: `${process.env.VUE_APP_API_BASE_URL}/v1/companies/${companyId}/instructors`,
      method: "get"
    },
    mutationTypes: async.ASYNC_COMPANY_INSTRUCTORS
  });
};

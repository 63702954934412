var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"title":"ลงทะเบียนเรียน"}}),_c('b-container',{attrs:{"fluid":"xl"}},[_c('div',{staticClass:"py-5"},[(_vm.alert)?_c('b-alert',{attrs:{"show":_vm.dismissCountDown,"dismissible":"","variant":_vm.alert.type},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(_vm._s(_vm.alert.message))]):_vm._e(),_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('SearchBox',{attrs:{"title":`พิมพ์ชื่อคอร์สเรียนที่ต้องการค้นหา`},on:{"typed":_vm.getSearchKeyword}})],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"6","lg":"4"}},[(
              _vm.hasRoles([
                'csq/superadmin',
                'csq/moderator',
                'org/admin',
                'org/hr',
                'org/group_admin',
                'org/instructor',
                'tutor/admin'
              ]) && 
              !_vm.isAccountingType() && 
              (!_vm.isTutorType() || _vm.isAllowedToSpecificCompany())
            )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addEnrollment}},[_c('b-icon-plus'),_vm._v("เพิ่มการลงทะเบียนคอร์สเรียน ")],1):_vm._e()],1)],1),_c('b-table',{attrs:{"fields":_vm.tableFields,"items":_vm.courseList.data,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"id":"course-list","busy":_vm.isLoading,"per-page":_vm.perPage,"responsive":"","sort-icon-left":"","table-class":"mt-4 border-bottom"},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"cell(image)",fn:function(props){return [_c('b-img-lazy',_vm._b({staticClass:"course-image",attrs:{"slot":"aside","src":props.item.courseThumbnail,"alt":""},slot:"aside"},'b-img-lazy',_vm.imageProps,false))]}},{key:"cell(courseName)",fn:function(props){return [(!_vm.isTutorType() || _vm.comId == 512)?_c('router-link',{attrs:{"to":{
              name: 'enrollment.members',
              params: { id: props.item.courseId },
            }}},[_vm._v(_vm._s(props.item.courseName))]):_c('span',[_vm._v(_vm._s(props.item.courseName))])]}}])},[_c('div',{staticClass:"text-center text-danger my-2",attrs:{"slot":"table-busy"},slot:"table-busy"},[_c('Loading')],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-lg-6"},[_c('form',{staticClass:"form-inline"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',[_vm._v("แสดง")])]),_c('div',{staticClass:"form-group ml-2 mb-2"},[_c('b-form-select',{attrs:{"options":[10, 15, 30, 50],"size":"sm"},on:{"input":_vm.setFetchParamsPerPage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',{staticClass:"form-group ml-2 mb-2"},[_c('label',[_vm._v(" จาก "+_vm._s(_vm._f("formatNumber")(_vm.courseList.meta.total))+" รายการ ")])])])]),_c('div',{staticClass:"col-lg-6"},[_c('nav',{staticClass:"float-right",attrs:{"aria-label":"Page navigation example"}},[_c('b-pagination',{attrs:{"total-rows":_vm.courseList.meta.total,"per-page":_vm.perPage,"aria-controls":"course-list"},on:{"change":_vm.setFetchParamsPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
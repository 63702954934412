<template>
  <b-modal
    :name="name"
    :width="width"
    :max-width="maxWidth"
    height="auto"
    :click-to-close="clickToClose"
    style="border-radius: 10px;"
    :class="$options.name"
    @before-open="beforeOpen"
    @closed="custom.callback"
  >
    <div class="content py-2" :class="[`${$options.name}__content`]">
      <b-container>
        <b-row align-h="center">
          <b-col align-h="left">
            <div class="modal-header">
              <slot name="modal-header">
                {{ header }}
              </slot>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col>
            <div
              class="modal-body"
              :class="[`${$options.name}__content__body`]"
            >
              <slot name="modal-body">
                {{ message }}
              </slot>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row align-h="center">
          <b-col :class="[`${$options.name}__content__btn__wrapper`]">
            <b-btn
              class="submit-btn"
              :class="[`${$options.name}__content__btn__elem`]"
              @click="close"
              >ตกลง</b-btn
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Contentmodal",
  props: {
    name: {
      type: String,
      default: "content-modal"
    },
    width: {
      type: String,
      default: () => "280"
    },
    clickToClose: {
      type: Boolean,
      default: () => true
    },
    maxWidth: {
      type: Number,
      default: () => 450
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      message: "",
      header: "",
      custom: {
        callback: () => {}
      }
    };
  },
  methods: {
    close() {
      this.$modal.hide("content-modal");
      this.custom.callback();
    },
    beforeOpen(event) {
      if (!event.params) return;
      if (event.params.header) {
        this.header = event.params.header;
      }
      if (event.params.message) {
        this.message = event.params.message;
      }
      if (event.params.callback) {
        this.custom.callback = event.params.callback;
      }
    },
    created() {
      this.custom.callback = this.callback;
    }
  }
};
</script>

<style scoped lang="scss">
.Contentmodal {
  &__content {
    &__btn {
      &__wrapper {
        text-align: center;
      }
    }
  }
}
</style>

<template>
  <li class="dropdown_menu" :class="mainTagListCssObj">
    <a v-b-toggle.attendanceCollapse @click.prevent="toggleCollapse">
      <img
        src="@/assets/images/icon/icon_attendance.svg"
        alt="attendance"
        height="22px"
      />
      <span class="link-title menu_hide">การเข้าชั้นเรียน</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="attendanceCollapse" :visible="false" accordion="accordion">
      <MainCollapseMenu :collapse-list="collapseList" :is-active="!isActive" />
    </b-collapse>
  </li>
</template>

<script>
import _ from "lodash";
import MainCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";

export default {
  components: { MainCollapseMenu },
  data() {
    return {
      isActive: false,
      collapseList: [
        {
          title: "สรุปการเข้าชั้นเรียน",
          to: { name: "attendance.overview" },
        },
        {
          title: "รายงานการเข้าเรียนรายบุคคล",
          to: { name: "members.attendance.report" },
        },
      ],
    };
  },
  computed: {
    mainTagListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  methods: {
    toggleCollapse() {},
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>

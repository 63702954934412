<template>
  <div>
    <label class="d-block"
      >ระดับความยากของคอร์ส
      <span v-if="courseAttributeUpdated[attribute]" class="text-success">
        <i class="fa fa-check"  aria-hidden="true"></i>
      </span>
    </label>
    <div style="display:inline-block">
      <div class="d-flex justify-content-between text-muted small">
        <span class="mt-2 mr-2">ง่ายที่สุด</span>
        <b-button
          v-for="(level, index) in DifficultLevels"
          :key="index"
          variant="outline-primary"
          squared
          :class="[
            {
              'bg-primary': courseInfo.courseDifficultLevel === level,
            },
            'mr-1',
          ]"
          @click="editCourseInfo(level)"
        >
          {{ level }}
        </b-button>
        <span class="ml-1 mt-2">ยากที่สุด</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DifficultLevelForm',
  props: {
    attribute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      DifficultLevels: [1, 2, 3],
    };
  },
  computed: {
    ...mapGetters('CourseManagement', [
      'isLoading',
      'courseInfo',
      'courseAttributeUpdated',
    ]),
  },
  methods: {
    ...mapActions('CourseManagement', [
      'updateCourseInfo',
      'setCourseInfoAttribute',
      'getCourseInfo',
    ]),
    editCourseInfo(value) {
      this.updateCourseInfo({
        courseId: this.$route.params.courseId,
        attribute: { [this.attribute]: value },
      }).then(() => {
        this.setCourseInfoAttribute({
          [this.attribute]: value,
        });
        this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
      });
    },
  },
};
</script>

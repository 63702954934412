import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    courseCompleteRule: {
      lecturePercentCount: 0,
      timeSpentSecond: 0,
      timeSpentType: '',
      lecturePercentWithApprovedFaceVerification: 0,
      evaluation: 0
    },
    courseCompleteActions: [],
    courseLectures: [],
    courseExamRules: []
  },
  mutations: {
    INIT() { },
    SET_COURSE_COMPLETE_RULES(state, data) {
      let rules = JSON.parse(data.ccr_rule);
      let actions = JSON.parse(data.ccr_action);

      let lecturePercentCount = 0;
      let lecturePercentWithApprovedFaceVerification = 0;
      let timeSpentSecond = 0;
      let timeSpentType = data.ccr_timespentType;
      let evaluation = 0;

      if (rules.$AND) {
        lecturePercentCount =
          rules.$AND.find(item => {
            return "lecturePercentCount" in item;
          })?.lecturePercentCount || 0;

        lecturePercentWithApprovedFaceVerification =
          rules.$AND.find(item => {
            return "lecturePercentWithApprovedFaceVerification" in item;
          })?.lecturePercentWithApprovedFaceVerification || 0;

        timeSpentSecond =
          rules.$AND.find(item => {
            return "timeSpentSecond" in item;
          })?.timeSpentSecond || 0;

        evaluation =
          rules.$AND.find(item => {
            return "evaluation" in item;
          })?.evaluation || 0;

      } else {
        lecturePercentCount = rules.lecturePercentCount;
        timeSpentSecond = rules.timeSpentSecond;
        lecturePercentWithApprovedFaceVerification =
          rules.lecturePercentWithApprovedFaceVerification || 0;
        evaluation =
          rules.evaluation || 0;
      }

      state.courseCompleteRule = {
        lecturePercentCount,
        timeSpentSecond,
        timeSpentType,
        lecturePercentWithApprovedFaceVerification,
        evaluation
      };

      if (actions) {
        state.courseCompleteActions = actions.actions.map(item => {
          return item;
        });
      }
    },
    SET_COURSE_EXAM_RULES(state, data) {
      state.courseLectures = data.examRules;
      state.courseExamRules = data.examRules
        .filter(item => item.examRule != null)
        .map(item => {
          return item.examRule;
        });
    }
  },
  actions: {
    init(context) {
      context.commit("INIT");
    },

    async fetchCourseCompleteRules(context, courseId) {
      return await api
        .get(`/v1/courses/${courseId}/course-complete-rules`)
        .then(({ data }) => {
          context.commit("SET_COURSE_COMPLETE_RULES", data);
        })
        .catch(errors => {
          console.error("fetchCourseCompleteRules", errors);
        });
    },

    async fetchCourseExamRules(context, courseId) {
      return await api
        .get(`/v1/courses/${courseId}/course-exam-rules`)
        .then(({ data }) => {
          context.commit("SET_COURSE_EXAM_RULES", data);
        })
        .catch(({ errors }) => {
          console.error("fetchCourseExamRules", errors);
        });
    },

    async updateCourseCompleteRules(context, payload) {
      const courseCompleteRule = context.state.courseCompleteRule;
      let courseExamRules = context.state.courseExamRules;
      if (payload.isUseExamRules === false) {
        courseExamRules = [];
      }
      if (courseCompleteRule.evaluation == 1) {
        return await api.put(`/v1/courses/${payload.courseId}/rules`, {
          lecturePercentCount: courseCompleteRule.lecturePercentCount,
          timeSpentType: courseCompleteRule.timeSpentType,
          timeSpentSecond: courseCompleteRule.timeSpentSecond,
          lecturePercentWithApprovedFaceVerification:
            courseCompleteRule.lecturePercentWithApprovedFaceVerification,
          evaluation:
            courseCompleteRule.evaluation,
          examRule: courseExamRules
        });
      }
      else {
        return await api.put(`/v1/courses/${payload.courseId}/rules`, {
          lecturePercentCount: courseCompleteRule.lecturePercentCount,
          timeSpentType: courseCompleteRule.timeSpentType,
          timeSpentSecond: courseCompleteRule.timeSpentSecond,
          lecturePercentWithApprovedFaceVerification:
          courseCompleteRule.lecturePercentWithApprovedFaceVerification,
          examRule: courseExamRules
        });
      }
    },

    async updateCourseCompleteActions(context, payload) {
      const courseCompleteActions = context.state.courseCompleteActions;

      const index = courseCompleteActions.findIndex(
        item => item.action === "PDF"
      );

      if (payload.hasPdfAction) {
        if (index === -1) courseCompleteActions.push({ action: "PDF" });
      } else courseCompleteActions.splice(index, 1);

      return await api.put(`/v1/courses/${payload.courseId}/actions`, {
        cActions: courseCompleteActions
      });
    },

    async updateCourseExamRules(context, payload) {
      const courseCompleteRule = context.state.courseCompleteRule;
      let courseExamRules = context.state.courseExamRules;

      if (payload.examRule) {
        const index = courseExamRules.findIndex(
          item => item.examId === payload.examRule.examId
        );

        if (index === -1) {
          courseExamRules.push(payload.examRule);
        } else {
          if (payload.isUseExamRule) courseExamRules[index] = payload.examRule;
          else courseExamRules.splice(index, 1);
        }
      }

      return await api.put(`/v1/courses/${payload.courseId}/rules`, {
        lecturePercentCount: courseCompleteRule.lecturePercentCount,
        timeSpentSecond: courseCompleteRule.timeSpentSecond,
        examRule: courseExamRules,
        examRuleCriteriaChange: { examId: payload.examId, type: payload.isUseExamRule, examResultPercent: payload.examResultPercent },
      });
    }
  },
  getters: {
    courseCompleteRule(state) {
      return state.courseCompleteRule;
    },
    courseCompleteActions(state) {
      return state.courseCompleteActions;
    },
    courseLectures(state) {
      return state.courseLectures;
    },
    courseExamRules(state) {
      return state.courseExamRules;
    }
  }
};

<template>
  <div>
    <b-row>
      <b-col md="6" lg="4">
        <SearchBox
          :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
          @typed="setSearchKeyword"
        />
      </b-col>
    </b-row>

    <b-table
      :fields="fields"
      :items="groups.data"
      :busy="isLoading"
      :sort-by.sync="fetchParams.sort_by"
      :sort-desc.sync="sortDesc"
      no-local-sorting
      small
      sort-icon-left
      responsive
      table-class="mt-4 border-bottom"
    >
      <template v-slot:cell(courses)="{ item }">
        <ShowMembersInMemberGroupsTable :group="item" />
      </template>
      <template v-slot:cell(selection)="{ item }">
        <ToggleSelectionButton
          item-key="memberGroups"
          :item-value="{
            id: item.id,
            name: item.name
          }"
        />
      </template>
      <div slot="table-busy" class="text-center text-danger my-2">
        <Loading />
      </div>
    </b-table>

    <Pagination
      :items="groups"
      :limits="[10, 30, 50]"
      @changePage="setCurrentPage"
      @changeLimit="setPerPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchBox from "@/components/shared/SearchBox";
import Pagination from "@/components/shared/Pagination";
import Loading from "@/components/shared/Loading";
import ToggleSelectionButton from "./ToggleSelectionButton";
import ShowMembersInMemberGroupsTable from "./ShowMembersInMemberGroupsTable";
import Numeral from "numeral";

export default {
  name: "SelectMemberGroupsTable",
  components: {
    SearchBox,
    Pagination,
    Loading,
    ToggleSelectionButton,
    ShowMembersInMemberGroupsTable
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          label: "ชื่อกลุ่มผู้เรียน",
          key: "name",
          sortable: true
        },
        {
          key: "membersCount",
          label: "จำนวนผู้เรียนในกลุ่ม",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Numeral(value).format("0,0");
          }
        },
        {
          label: "ดูรายชื่อผู้เรียนในกลุ่ม",
          key: "courses",
          sortable: false,
          class: "text-center"
        },
        {
          label: "",
          key: "selection",
          sortable: false,
          thStyle: "width: 70px"
        }
      ],
      fetchParams: {
        page: null,
        limit: 10,
        q: this.q,
        sort_by: "fullname",
        sort_order: "asc"
      }
    };
  },
  computed: {
    ...mapGetters("Company", ["companyInfo"]),
    ...mapGetters("MemberManagement", ["groups"]),
    sortDesc: {
      get() {
        return false;
      },
      set(isDesc) {
        this.fetchParams.sort_order = isDesc ? "desc" : "asc";
      }
    }
  },
  watch: {
    fetchParams: {
      handler() {
        this.fetch();
      },
      deep: true
    }
  },
  created() {
    this.fetch();
  },

  methods: {
    ...mapActions("MemberManagement", ["fetchGroups"]),
    async fetch() {
      this.isLoading = true;
      await this.fetchGroups({
        companyId: this.companyInfo.id,
        params: this.fetchParams
      }).then(() => (this.isLoading = false));
    },
    setCurrentPage(page) {
      this.fetchParams.page = page;
    },
    setPerPage(limit) {
      this.fetchParams.page = 1;
      this.fetchParams.limit = limit;
    },
    setSearchKeyword(q) {
      this.fetchParams.page = 1;
      this.fetchParams.q = q;
    }
  }
};
</script>

import axios from "axios";
import { setupRequestInterceptor } from "@/helpers/http-service/setupRequestInterceptor";

export let HttpService = axios.create({
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
    ComId: localStorage.getItem("ComId")
  }
});

export const initHttpService = (store, router) => {
  /* istanbul ignore next */
  HttpService = axios.create();

  HttpService.store = store;
  HttpService.router = router;
  HttpService.isReAuthenticating = false;
  HttpService.pendingRequests = [];

  setupRequestInterceptor();
};

export const replaceOldToken = (request, accessToken) => {
  request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

<!-- HTML Part -->
<template>
  <div>
    <!-- Heading -->
    <h5 class="card-title">{{ title }}</h5>
    <br />
    <!-- 1st Line -->

    <div style="width: 100%; display: flex">
      <div style="width: 40%; margin-right: 20px">
        <label :style="labelStyle" >เลขที่ใบอนุญาต</label>
        <input
            ref="refNoInput"
            v-model="inputNo"
            type="text"
            maxlength="10"
            class="form-control"
            :class="{ 'is-invalid': errorsNo }"
        />
        <b-form-invalid-feedback>
          <p v-if="errorsNo">{{ errorsNo }}</p>
        </b-form-invalid-feedback>
      </div>
      <div style="width: 40%">
        <label :style="labelStyle" >วันใบอนุญาตหมดอายุ</label>
        <input
            ref="refExpInput"
            v-model="inputExp"
            type="date"
            :min="minDate"
            class="form-control"
            :class="{ 'is-invalid': errorsExp }"
        />
        <b-form-invalid-feedback>
          <p v-if="errorsExp">{{ errorsExp }}</p>
        </b-form-invalid-feedback>
      </div>
    </div>
    <br />
    <!-- 2nd Line -->
    <div style="width: 100%; display: flex">
      <div style="width: 40%; margin-right: 20px">
        <label :style="labelStyle" >ประเภท</label>
        <v-select
          v-model="formType"
          :reduce="(option) => option.value"
          :options="opt"
          :clearable="false"
          label="label"
          placeholder="กรุณาเลือกประเภท"
          :class="{ 'is-invalid': errorsType }"
        ></v-select>
        <b-form-invalid-feedback>
          <p v-if="errorsType">{{ errorsType }}</p>
        </b-form-invalid-feedback>
      </div>
      <div style="width: 40%">
        <label :style="labelStyle" >จำนวนชั่วโมงอบรม</label>
        <input
            ref="refTrHrsInput"
            v-model="inputTrHrs"
            type="number"
            class="form-control"
            :class="{ 'is-invalid': errorsTrHrs }"
            @keypress="onKeyPress"
        />
        <b-form-invalid-feedback>
          <p v-if="errorsTrHrs">{{ errorsTrHrs }}</p>
        </b-form-invalid-feedback>
      </div>
    </div>
    <br />
    <!-- 3rd Line -->
    <b-form-checkbox v-model="isChecked" :name="'ReducedHours'">
      <a :href="linkHref" target="_blank" data-toggle="modal">คุณมีสิทธิ์ขอลดระยะเวลาการอบรม ตามประกาศ สำนักงาน คปภ. เรื่องกำหนดหลักสูตรและวิธีการ การอบรมความรู้เกี่ยวกับ<span class="type">{{ spanText }}</span></a>
    </b-form-checkbox>
    <br />
    <b-button
      variant="dark"
      class="float-right"
      style="margin-right: 10px;"
      :disabled='disableUpdateButton'
      @click="update()"
      >บันทึกข้อมูล</b-button
    >
  </div>
</template>

<!-- Javascript Part -->
<script>
//Import
//--Vue Component
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import api from "@/services/api.js";
import axios from "axios";
//Export
export default {
  //--Vue Component
  components: { vSelect },
  //--Class Property
  props: {
    title: { type: String, default: null },
    id: { type: Number, default: null },
    type: { type: String, default: null },
    no: { type: String, default: null },
    exp: { type: String, default: null },
    trHrs: { type: String, default: null },
    card: { type: String, default: null },
    opt: { type: Array, default: null },
    lReducedHours: { type: Number, default: null },
    nlReducedHours: { type: Number, default: null },
    lLevel: { type: String, default: null },
    nlLevel: { type: String, default: null},
    errors: { type: String, default: "" },
    method: { type: String, default: "POST" }
  },
  data() {
    return {
      //Global Variables
      mId: this.$route.params.id,
      comId: localStorage.getItem("ComId"),
      token: localStorage.getItem("token"),
      baseUrl:
        process.env.VUE_APP_FILE_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220815.coursesquare.com",
      //Local
      minDate: this.no
        ? new Date().toISOString().split('T')[0]
        : null,
      formType: this.type,
      config: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token")
      },
      formData: new FormData(),
      renewLevelStyle: {
        color: '#183F80',
        backgroundColor: '#D1E4FD',
        margin: '10px',
        padding: '10px',
        'font-size': '14px',
      },
      renewLifeLevelText: '',
      renewNonlifeLevelText: '',
      spanText: '',
      linkHref: '',
      isChecked: false,
      inputNo: this.no,
      errorsNo: "",
      inputExp: this.exp,
      errorsExp: "",
      inputTrHrs: this.trHrs,
      errorsTrHrs: "",
      errorsCard: "",
      errorsType: "",
      insuranceCard: null,
      isChangeInsuranceCard: false,
      insuranceCardPath: null,
      previewInsuranceCardSrc: null,
      disableUpdateButton: false,
      labelStyle: {
        fontWeight: 'bold',
      }
    };
  },
  mounted() {
    if (this.type) {
      this.formData.append("insuranceType", this.type);
    }
    if (this.type == "LIFE_BROKER" || this.type == "LIFE_AGENT") {
      this.isChecked = this.lReducedHours ? true : false;
      this.spanText = "การประกันชีวิต";
      this.linkHref = "https://www.oic.or.th/sites/default/files/content/91695/prakaas-hlaksuutrprakanchiiwit-rabfangkhwaamehn.pdf";
    } else if (this.type == "NONLIFE_BROKER" || this.type == "NONLIFE_AGENT") {
      this.isChecked = this.nlReducedHours ? true : false;
      this.spanText = "ประกันวินาศภัย";
      this.linkHref = "https://www.oic.or.th/sites/default/files/content/91695/prakaas-hlaksuutrprakanwinaasphay-rabfangkhwaamkhidehn.pdf";
    }
  },

  methods: {
    modalHideEditInsuranceForm() {
      this.$emit("modalHideEditInsuranceForm");
    },
    onKeyPress(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },
    getIns() {
      this.$emit("getIns");
    },
    async update() {
      let insuranceData = {};
      if (!this.inputNo) {
        this.errorsNo = "กรุณากรอกเลขที่ใบอนุญาต";
        return;
      } else {
        this.errorsNo = null;
      }
      if (!this.inputExp) {
        this.errorsExp = "กรุณาเลือกวันใบอนุญาตหมดอายุ";
        return;
      } else {
        this.errorsExp = null;
      }
      if (!this.inputTrHrs || this.inputTrHrs <= 0 || this.inputTrHrs > 200) {
        this.errorsTrHrs = "กรุณากรอกจำนวนชั่วโมงอบรมในช่วง 0 - 200 ชม";
        return;
      } else {
        this.errorsTrHrs = null;
      }
      if (!this.formType) {
        this.errorsType = "กรุณาเลือกประเภทใบอนุญาตบัตรประกันวินาศภัย";
        return;
      } else {
        this.errorsType = null;
      }
      if (!this.errors) {
        if(this.isChangeInsuranceCard){
          await this.updateInsuranceCard();
        }
        if (this.formType == "LIFE_BROKER" || this.formType == "LIFE_AGENT") {
          insuranceData = {
            id: this.id,
            lCard: this.isChangeInsuranceCard ? this.insuranceCardPath : this.card,
            lExp: this.inputExp,
            nlNo: null,
            lNo: this.inputNo,
            lTrHrs: this.inputTrHrs,
            lType: this.formType,
            lReducedHours: this.isChecked
          }
        } else if (this.formType == "NONLIFE_BROKER" || this.formType == "NONLIFE_AGENT") {
          insuranceData = {
            id: this.id,
            lNo: null,
            nlCard: this.isChangeInsuranceCard ? this.insuranceCardPath : this.card,
            nlExp: this.inputExp,
            nlNo: this.inputNo,
            nlTrHrs: this.inputTrHrs,
            nlType: this.formType,
            nlReducedHours: this.isChecked
          }
        }
        this.disableUpdateButton = true;
        await api
          .post(`/v1/insurance/${this.mId}/update`, {
            ins: insuranceData
          })
          .then(() => {
            this.$emit("getIns");
            this.$emit("modalHideEditInsuranceForm");
            this.disableUpdateButton = false;
          })
          .catch(error => {
            this.disableUpdateButton = false;
            console.log("[Error] Insurace Update - " + error);
          });
      }
    },
    previewInsuranceCard(event) {
      this.isChangeInsuranceCard = true;
      const file = event.target.files[0];
      let extension = file.type.split("/")[1];
      if (!(extension == "jpeg" || extension == "jpg" || extension == "png")) {
        this.errors = "อัปโหลดรูปภาพไม่สำเร็จ โปรดเลือกเฉพาะไฟล์ .png .jpg .jpeg เท่านั้น";
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewInsuranceCardSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.errorsCard = null;
      } else {
        this.previewInsuranceCardSrc = null;
      }
    },
    async updateInsuranceCard() {
      const formCardData = new FormData();
      formCardData.append('insuranceType', this.type);
      formCardData.append('insuranceCard', this.insuranceCard);
      await axios({
        url: this.baseUrl + `/companies/${this.comId}/members/${this.mId}/images/insurance-card/`,
        data: formCardData,
        method: this.method,
        headers: this.config
      }).then(result => {
          let response = result.data;
          this.insuranceCardPath = response.insuranceCardPath;
        })
        .catch(error => {
          this.formData.delete('insuranceCard');
          console.log("[Error] ImgUpload - " + error);
        });
    },
  }
};
</script>
<template>
  <b-row>
    <b-col lg="3 mt-3 mt-lg-0">
      <div class="box border">
        <div class="text-box">
          <p style="color: black"><strong>คะแนนเต็ม</strong></p>
          <h1>{{ result2 && result2.fullScore }}</h1>
        </div>
      </div>
    </b-col>
     <b-col lg="3 mt-3 mt-lg-0 order-3 order-lg-2">
      <div class="box border">
        <div class="nm-text-box">
          <p style="color: black"><strong>คะแนนสูงสุด</strong></p>
          <h1>{{ result.length && result[0].max }}</h1>
          <p style="color: black">
            คิดเป็น
            {{
              result.length &&
              ((result[0].max / result[0].fullScore) * 100).toFixed(2)
            }}%
          </p>
        </div>
      </div>
    </b-col>
    <b-col lg="3 mt-3 mt-lg-0 order-2 order-lg-3">
      <div class="box border">
        <div class="text-box">
          <p style="color: black"><strong>คะแนนเฉลี่ย</strong></p>
          <h1>{{ result.length && result[0].average.toFixed(2) }}</h1>
        </div>
      </div>
    </b-col>
    <b-col lg="3 mt-3 mt-lg-0 order-4">
      <div class="box border">
        <div class="nm-text-box">
          <p style="color: black"><strong>คะแนนต่ำสุด</strong></p>
          <h1>{{ result.length && result[0].min }}</h1>
          <p style="color: black">
            คิดเป็น
            {{
              result.length &&
              ((result[0].min / result[0].fullScore) * 100).toFixed(2)
            }}%
          </p>
        </div>
      </div>
    </b-col>
   
  </b-row>
</template>

<script>
import api from "@/services/exam-api.js";

export default {
  props: ["apiRoute", "apiRoute2"],
  data() {
    return {
      result: {},
      result2: {},
    };
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  methods: {
    async fetch() {
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      let res2 = await api.get(this.apiRoute2);
      this.result2 = res2.data;
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_dashboard.scss";
.box {
  display: flex;
  color: rgb(235, 129, 35);
  padding: 1rem;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 9px rgb(0 0 0 / 20%);
  border-radius: 6px;
}
.text-box {
  margin: auto;
}
.text-box > h1 {
  font-size: 6rem;
  text-align: center;
}

.text-box > p {
  font-size: 1.5rem;
  text-align: center;
}
.nm-text-box {
  margin: auto;
}
.nm-text-box > h1 {
  font-size: 4rem;
  text-align: center;
}

.nm-text-box > p {
  font-size: 1.5rem;
  text-align: center;
}
</style>

<template>
  <div>
    <hr />
    <div class="exam-to-thelesson">
      <b-form-checkbox v-model="isCheckBoxCheck" id="checkbox-4" class="mb-3"
        >ผูกข้อสอบกับบทเรียน****
      </b-form-checkbox>

      <b-row
        class="pl-4"
        v-if="!moudleAndCouseAndLectures || isShowSelect === true"
      >
        <b-col cols="4">
          <p class="mb-0">เลือกคอร์ส</p>
          <b-form-select v-model="selected" @change="listModule">
            <option value="null">กรุณาเลือกคอร์ส</option>
            <option
              v-for="courseItem in courseList.data"
              :key="courseItem.courseId"
              :value="courseItem.courseId"
            >
              {{ courseItem.courseName }}
            </option>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <p class="mb-0">
            เลือกหัวข้อ
            <!-- {{ courseModuleList }} -->
          </p>
          <b-form-select v-model="moduleSelected" @change="listLectures">
            <option value="null">กรุณาเลือกหัวข้อ</option>
            <option
              v-for="moduleItem in courseModuleList"
              :key="moduleItem.moduleId"
              :value="moduleItem.moduleId"
            >
              {{ moduleItem.moduleName }}
            </option>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <p class="mb-0">เลือกบทเรียน</p>
          <b-form-select v-model="lectureSelected" @change="lectureChange">
            <option value="null">กรุณาเลือกบทเรียน</option>
            <option
              v-for="lectureItem in lecturesList"
              :key="lectureItem.lecId"
              :value="lectureItem.lecId"
            >
              {{ lectureItem.lecName }}
            </option>

            <option
              v-if="selected != null && moduleSelected != null"
              class="add-lecture"
              value="addlectureName"
            >
              เพิ่มบทเรียน
            </option>
          </b-form-select>
          <div
            class="btn-confrin d-flex justify-content-end"
            v-if="
              selected != null &&
              moduleSelected != null &&
              lectureSelected != null &&
              lectureSelected != 'addlectureName'
            "
          >
            <b-button
              v-if="this.isShowButtomSubmit === false"
              class="mt-2 text-right"
              variant="success"
              size="md"
              @click="submitLecture()"
              >ยืนยัน</b-button
            >
          </div>
        </b-col>
      </b-row>

      <div class="pl-4" v-else>
        <h6>
          ข้อสอบนี้อยู่ในคอร์ส
          <span class="text-danger">
            {{ moudleAndCouseAndLectures.courseName }}
          </span>
          หัวข้อ
          <span class="text-danger">
            {{ moudleAndCouseAndLectures.moduleName }}
          </span>
          บทเรียน
          <span class="text-danger">
            {{ moudleAndCouseAndLectures.lectureName }}
          </span>
          ต้องการยกเลิก
          <a class="delete-lecture" @click="cancelQuizLecture()"> คลิก</a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      selected: null,
      moduleSelected: null,
      lectureSelected: null,

      fetchParams: {
        company_id: null,
      },
      examUrl: "",
      examId: this.$route.params.quizExamId,
      isCheckUpdate: false,
      isShowSelect: false,
      isShowButtomSubmit: false,
      isCheckBoxCheck: false,
    };
  },

  async mounted() {
    this.fetchParams.company_id = this.comId;
    await this.fetchCourseList(this.fetchParams);

    this.examUrl =
      process.env.VUE_APP_EXAM_PREVIEW_BASE_URL ||
      "https://exam-frontend-dev-26082018.coursesquare.co/embed/exam/";

    if (Object.keys(this.examModuleAndCourseAndLectures).length === 0) {
      this.isCheckBoxCheck = false;
    } else {
      this.isCheckBoxCheck = true;
    }
  },

  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
    ...mapGetters("CourseManagement", [
      "courseList",
      "courseModuleList",
      "examModuleAndCourseAndLectures",
      "quizLectureList",
    ]),

    moudleAndCouseAndLectures() {
      if (this.examModuleAndCourseAndLectures === undefined) {
        return false;
      } else {
        return this.examModuleAndCourseAndLectures[0];
      }
    },

    lecturesList() {
      return this.quizLectureList.filter(
        (i) => i.examId === null && i.moduleId === this.moduleSelected
      );
    },
  },

  methods: {
    ...mapActions("CourseManagement", [
      "fetchCourseList",
      "fetchCourseModulesList",
      "createExamtoLecture",
      "getLecturesQuiz",
      "deleteQuizLecture",
      "getLectureByCourseId",
      "createLectureQuiz",
    ]),
    ...mapActions("ExamBuilder", ["countSectionAndQuestion", "updateExamAccessLimit"]),

    async listModule() {
      this.moduleSelected = null;
      this.lectureSelected = null;
      await this.fetchCourseModulesList({
        courseId: this.selected,
      }).then(() => {});
      this.isShowButtomSubmit = false;
    },
    async listLectures() {
      await this.getLectureByCourseId({
        courseId: this.selected,
      }).then(() => {});
      this.lectureSelected = null;
    },

    async lectureChange() {
      if (this.lectureSelected === "addlectureName") {
        let request = {
          courseId: this.selected,
          body: {
            lecName: this.exam.title,
            lecType: "QUIZ",
            modId: this.moduleSelected,
          },
        };

        await this.createLectureQuiz(request);

        for (let i = 0; i < this.quizLectureList.length; i++) {
          if (i === this.quizLectureList.length - 1) {
            this.lectureSelected = this.quizLectureList[i].lecId;
          }
        }
      }
    },

    async submitLecture() {
      let request = {
        body: {
          lecContent: {
            quiz_url: this.examUrl + this.examId,
            quiz_examId: this.examId,
          },
          lecId: this.lectureSelected,
          lecType: "QUIZ",
        },
      };

      if (this.isCheckUpdate === false) {
        this.isShowButtomSubmit = true;
        this.isCheckBoxCheck = true;
        await this.createExamtoLecture(request);
        await this.countSectionAndQuestion(this.examId)
        await this.updateExamAccessLimit(this.examId);
        
        this.isCheckUpdate = true;
      } else {
        this.isShowButtomSubmit = true;
        this.isCheckBoxCheck = true;
        let deleteRequest = {
          body: {
            lectureId: this.lectureSelected,
            courseId: this.selected,
          },
        };
        await this.deleteQuizLecture(deleteRequest);
        await this.createExamtoLecture(request);
        await this.countSectionAndQuestion(this.examId)
        await this.updateExamAccessLimit(this.examId);

        this.isCheckUpdate = true;
      }
    },
    async cancelQuizLecture() {
      this.isCheckBoxCheck = false;
      let request = {
        body: {
          lectureId: this.moudleAndCouseAndLectures.lectureId,
          courseId: this.moudleAndCouseAndLectures.courseId,
        },
      };
      await this.deleteQuizLecture(request);
      this.isShowSelect = true;
    },
  },
};
</script>

<style lang="css" scope>
.exam-to-thelesson
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: green !important;
  background-color: green !important;
}

.delete-lecture {
  cursor: pointer;
  padding: 2px 5px;
  border: 1px solid #007bff;
  border-radius: 4px;
}

.delete-lecture:hover {
  text-decoration: none;
}
</style>


<template>
  <div>
    <line-chart
      :chart-data="chartData"
      :options="chartOption"
      :height="chartHeight"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart.js';
import datatest from './LineChartSampleData.js';

export default {
  components: {
    LineChart,
  },
  props: ['mstyle', 'chartData', 'chartOption', 'chartHeight'],
  data() {
    return {
      dataCollection: null,
      option: null,
    };
  },
  watch: {
    chartData() {
      this.dataCollection = this.chartData;
    },
    chartOption() {
      this.option = this.chartOption;
    },
  },
  mounted() {
    // this.setExampleData()
    // this.setExampleOption()
  },
  methods: {
    setExampleData() {
      this.dataCollection = {
        labels: datatest.time_24h,
        datasets: [
          {
            data: datatest.dataRandom1,
            label: 'วันจันทร์',
            borderColor: '#F6C72C',
            fill: false,
            lineTension: 0,
          },
          {
            data: datatest.dataRandom2,
            label: 'วันอังคาร',
            borderColor: '#E76275',
            fill: false,
            lineTension: 0.4,
          },
          {
            data: datatest.dataRandom3,
            label: 'วันพุธ',
            borderColor: '#ACBD24',
            fill: false,
            lineTension: 0,
          },
          {
            data: datatest.dataRandom4,
            label: 'วันพฤหัส',
            borderColor: '#FF980B',
            fill: false,
            lineTension: 0,
          },
          {
            data: datatest.dataRandom5,
            label: 'วันศุกร์',
            borderColor: '#3E9FD1',
            fill: false,
            lineTension: 0.4,
          },
          {
            data: datatest.dataRandom6,
            label: 'วันเสาร์',
            borderColor: '#9A64D4',
            fill: false,
            lineTension: 0.4,
          },
          {
            data: datatest.dataRandom7,
            label: 'วันอาทิตย์',
            borderColor: '#D53835',
            fill: false,
            lineTension: 0.4,
          },
        ],
      };
    },
    setExampleOption() {
      this.option = {
        elements: {
          point: { radius: 0 },
          line: { borderWidth: 0.8 },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style scoped>
.small {
  max-width: 600px;
  max-height: 200px;
  margin: auto;
}
</style>

<template>
  <layout>
    <template v-slot:header v-if="perPage <= 10">
      <b-row>
        <b-col cols="6">
          <h6>สรุปรายได้</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <a :href="`/${company.url}/analytics/revenue/summary`" target="_blank"
            ><h6>ดูทั้งหมด</h6></a
          >
        </b-col>
      </b-row>
    </template>
    <template v-slot:body>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="รายได้ตามคอร์ส" active
            ><p></p>
            <revenueCourse
              :apiRoute="`/v1/analytics/revenues/get-revenue-by-course`"
              :perPage="perPage"
            />
          </b-tab>
          <b-tab title="รายได้ตามแท็ก">
            <revenueTag
              :apiRoute="`/v1/analytics/revenues/get-revenue-by-tag`"
              :perPage="perPage"
          /></b-tab>
          <b-tab title="รายได้ตามผู้สอน"
            ><revenueInstructor
              :apiRoute="`/v1/analytics/revenues/get-revenue-by-instructor`"
              :perPage="perPage"
          /></b-tab>
        </b-tabs>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import revenueCourse from './RevenueByCoursePanel';
import revenueTag from './RevenueByTagPanel';
import revenueInstructor from './RevenueByInstructorPanel';

export default {
  props: ['perPage'],
  components: {
    layout,
    revenueCourse,
    revenueTag,
    revenueInstructor,
  },

  data() {
    return {
      company: {},
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped></style>

<template>
  <div class="d-flex my-3">
    <div class="text-center " style="width: 100px;">
      <img :src="pic" class="user-profile-image" />
    </div>
    <div class="w-100 px-3">
      <div class="d-flex">
        <div class="mr-3 user-name d-flex align-items-center">
          <strong class="my-auto">{{ name }}</strong>
        </div>
      </div>
      <div
        class="user-message"
        style="word-break: break-all;"
        v-html="message"
      ></div>
      <div class="w-100" style="border: 0.25px solid #D1D2D4;"></div>
      <div class="d-flex">
        <img :src="user.avatarUrl" class="user-profile-image" />
        <div class="mr-3 user-name d-flex align-items-center">
          <strong class="my-auto">{{ user.profiles[0].fullName }}</strong>
        </div>
      </div>
      <div class="type-box">
        <quill-editor
          ref="myQuillEditor"
          v-model="tempValue"
          :options="editorOption"
        />
      </div>
      <div style="display:inline-block; float:right;" class="mt-3">
        <b-button
          size="sm"
          variant="outline-primary"
          class="mx-1"
          @click="closeModal()"
        >
          <span>ยกเลิก</span>
        </b-button>
        <b-button size="sm" variant="primary" @click="answerDiscussion()">
          <span>ตอบคำถาม</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  props: ["pic", "message", "name", "id", "cId", "mId"],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: true,
        },
        placeholder: "พิมพ์คำตอบ..",
      },
      user: JSON.parse(localStorage.getItem("user")),
      result: [],

      items: [],
    };
  },
  computed: {
    tempValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.temp = val;
      },
    },
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    closeModal() {
      this.$bvModal.hide("modal-" + this.id);
    },
    async answerDiscussion() {
      let res = await api.post(`/v1/analytics/discuss/answerDiscussion`, {
        message: this.temp,
        courseId: this.cId,
        parentId: this.id,
        memberId: JSON.parse(localStorage.getItem("user")).id,
      });
      this.result = res.data;
      if (this.result) {
        this.$bvToast.toast(`ตอบคำถามเรียบร้อยแล้ว`, {
          title: `สำเร็จแล้ว`,
          variant: "success",
          solid: true,
        });
        this.$emit("posted");
        this.$bvModal.hide("modal-" + this.id);
      }
    },
  },
};
</script>
<style scoped>
.question-amount-style {
  background-color: #eb8123;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  color: white;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin: 16px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
}
.type-box {
  word-break: break-all;
}
</style>

<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="4">
        <b-form-select
          v-model="selectedmodule"
          aria-describedby="input-live-feedback"
          :state="modelState"
          @change="changeModule"
        >
          <option value="null" selected disabled>
            กรุณาใส่อย่างน้อย 1 หัวข้อ
          </option>
          <option
            v-for="(module, moduleIndex) in courseModuleList"
            :key="moduleIndex"
            :value="module.moduleName"
            :disabled="checkDisable(moduleIndex)"
          >
            {{ module.moduleName }}
          </option>
        </b-form-select>
        <b-form-invalid-feedback id="input-live-feedback">
          กรุณาเลือกชื่อหัวข้อ
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="3">
        <b-form-timepicker
          v-model="time"
          class="form-control"
          label-no-time-selected="00:00:00"
          :state="timeState"
          :hour12="false"
          show-seconds
          aria-describedby="input-live-feedback"
          @input="changeTimeInsurance()"
        ></b-form-timepicker>
        <b-form-invalid-feedback id="input-live-feedback">
          กรุณาใส่เวลา
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="3">
        <b-form-select
          v-model="selectedPillar"
          :state="pillarState"
          aria-describedby="input-live-feedback"
          @change="changePillerInsurance()"
        >
          <option value="null" selected disabled>
            ความรู้ด้าน P1 ,P2 หรือ P3
          </option>
          <option value="1">Pillar 1 Technical & Business Knowledge</option>
          <option value="2">Pillar 2 Skills Development</option>
          <option value="3">Pillar 3 Regulation & Ethics</option>
        </b-form-select>
        <b-form-invalid-feedback id="input-live-feedback">
          กรุณาใส่หมวดความรู้
        </b-form-invalid-feedback>
      </b-col>
      <b-col clos="3" v-if="hasPillarInfo()">
        <b-button variant="outline-danger" @click="addModalDeletePillar = true"
          >ลบ</b-button
        >
      </b-col>
    </b-row>

    <div>
      <b-modal
        v-model="addModalDeletePillar"
        title="แจ้งเตือนการลบ"
        size="lg"
        :hide-footer="true"
        ref="modal-delete"
        centered
        id="modal-delete"
      >
        <div class="text-center pt-4">
          <b-icon
            icon="exclamation-triangle-fill"
            scale="5"
            variant="warning"
          ></b-icon>
        </div>

        <div class="content-text text-center mt-5">
          <h4>
            คุณต้องการลบข้อมูล Pillar หัวข้อ {{ selectedmodule }} ใช่หรือไม่
          </h4>
        </div>
        <div class="btn-model-delete mt-5 text-center">
          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('modal-delete')"
            >ยกเลิก</b-button
          >
          <b-button
            variant="danger"
            class="ml-1"
            style="width: 71px"
            @click="deletePillar"
            >ลบ</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    pillarindex: Number,
    insuranceCoursePillarInfo: Array,
  },

  data() {
    return {
      addModalDeletePillar: false,
      time: "",
      selectedmodule: "",
      selectedPillar: "",
      courseId: this.$route.params.courseId,
      modelState: null,
      timeState: null,
      pillarState: null,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoadingInsurance",
      "insuranceCourse",
      "courseModuleList",
    ]),

    insuranceModule() {
      if (
        this.insuranceCoursePillarInfo[this.pillarindex].subject === null ||
        this.insuranceCoursePillarInfo[this.pillarindex].subject === undefined
      ) {
        return null;
      } else {
        return this.insuranceCoursePillarInfo[this.pillarindex].subject;
      }
    },
    insurancePiller: {
      get() {
        if (
          this.insuranceCoursePillarInfo[this.pillarindex].pillar === null ||
          this.insuranceCoursePillarInfo[this.pillarindex].pillar === undefined
        ) {
          return null;
        } else {
          return this.insuranceCoursePillarInfo[this.pillarindex].pillar;
        }
      },
      set(newValue) {
        return (this.insuranceCoursePillarInfo[this.pillarindex].pillar =
          newValue);
      },
    },
    insuranceTime() {
      if (
        this.insuranceCoursePillarInfo[this.pillarindex].hours === null ||
        this.insuranceCoursePillarInfo[this.pillarindex].hours === undefined
      ) {
        return null;
      } else {
        return new Date(
          this.insuranceCoursePillarInfo[this.pillarindex].hours * 1000
        )
          .toISOString()
          .slice(11, 16);
      }
    },
  },

  async created() {
    this.fetchCourseModulesList({
      courseId: this.courseId,
    });
  },
  mounted() {
    this.fetchInsuranceCourse();
    this.selectedmodule = this.insuranceModule;
    this.selectedPillar = this.insurancePiller;
    this.time = this.insuranceTime;
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "getInsuranceCourse",
      "updateInsuranceCourse",
      "fetchCourseModulesList",
    ]),

    hasPillarInfo() {
      if (
        this.insuranceCoursePillarInfo === null ||
        this.insuranceCoursePillarInfo === undefined
      ) {
        return false;
      } else if (Object.keys(this.insuranceCoursePillarInfo).length > 1) {
        return true;
      }
    },

    fetchInsuranceCourse() {
      this.getInsuranceCourse(this.courseId).then(() => {});
    },

    changeModule() {
      this.modelState = null;
      if (!this.time) {
        this.timeState = false;
      }
      if (!this.selectedPillar) {
        this.pillarState = false;
      }

      if (this.selectedmodule && this.time && this.selectedPillar) {
        this.updateInurance();
      }
    },

    changeTimeInsurance() {
      this.timeState = null;
      if (!this.selectedmodule) {
        this.modelState = false;
      }
      if (!this.selectedPillar) {
        this.pillarState = false;
      }

      if (this.selectedmodule && this.time && this.selectedPillar) {
        this.updateInurance();
      }
    },

    changePillerInsurance() {
      this.pillarState = null;
      if (!this.selectedmodule) {
        this.modelState = false;
      }
      if (!this.time) {
        this.timeState = false;
      }

      if (this.selectedmodule && this.time && this.selectedPillar) {
        this.updateInurance();
      }
    },

    checkDisable(moduleIndex) {
      var moduleCheck = this.insuranceCoursePillarInfo.map((x) => x.subject);
      var found = moduleCheck.find(
        (element) => element === this.courseModuleList[moduleIndex].moduleName
      );
      return found;
    },

    async updateInurance() {
      var times = this.time.split(":");
      var hr = +times[0] * 60 * 60 + +times[1] * 60;
      var sumhr = 0;

      this.insuranceCoursePillarInfo[this.pillarindex].subject =
        this.selectedmodule;
      this.insuranceCoursePillarInfo[this.pillarindex].pillar =
        this.selectedPillar;
      this.insuranceCoursePillarInfo[this.pillarindex].hours = hr;

      for (var i = 0; i < this.insuranceCoursePillarInfo.length; i++) {
        sumhr += this.insuranceCoursePillarInfo[i].hours;
      }

      for (let j = 0; j < this.insuranceCourse.length; j++) {
        let request = {
          body: {
            courseId: this.courseId,
            insuranceId: this.insuranceCourse[j].insuranceCourseId,
            insurancePillarInfo: this.insuranceCoursePillarInfo,
            insuranceTotalHours: sumhr,
          },
          page: "rules",
        };
        await this.updateInsuranceCourse(request);
      }
      await this.getInsuranceCourse(this.courseId).then(() => {});
    },

    async deletePillar() {
      this.insuranceCoursePillarInfo.splice(this.pillarindex, 1);

      var sumhr = 0;
      for (var i = 0; i < this.insuranceCoursePillarInfo.length; i++) {
        sumhr += this.insuranceCoursePillarInfo[i].hours;
      }

      for (let j = 0; j < this.insuranceCourse.length; j++) {
        let request = {
          body: {
            courseId: this.courseId,
            insuranceId: this.insuranceCourse[j].insuranceCourseId,
            insurancePillarInfo: this.insuranceCoursePillarInfo,
            insuranceTotalHours: sumhr,
          },
          page: "rules",
        };
        await this.updateInsuranceCourse(request);
      }
      this.$bvModal.hide("modal-delete");
      await this.getInsuranceCourse(this.courseId).then(() => {});
    },
  },
};
</script>

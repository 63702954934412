<template>
  <div>
    <b-form id="tfac-course">
      <b-row
        ><b-col
          ><h5><strong>การเก็บชั่วโมงในการเรียน</strong></h5></b-col
        ></b-row
      >
      <b-row
        ><b-col
          ><h6><strong>สำหรับผู้ทำบัญชี</strong></h6></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <b-form-group label="บัญชี">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <b-form-input
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                    type="text"
                    v-model="form.tfac.accAccHour"
                    placeholder="-"
                    :readonly="readonly"
                    :state="validAccAccHour"
                    :formatter="editAccAccHour"
                  /> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.accAccMin"
                    :options="minSelect"
                    :state="validAccAccHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>

            <b-form-invalid-feedback :state="validAccAccHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="จรรยาบรรณ">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <!-- tfc_accEthicHour -->
                  <b-form-input
                    v-model="form.tfac.accEthicHour"
                    type="text"
                    placeholder="-"
                    :state="validAccEthicHour"
                    :readonly="readonly"
                    :formatter="editAccEthicHour"
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                  ></b-form-input> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.accEthicMin"
                    :options="minSelect"
                    :state="validAccEthicHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>
            <b-form-invalid-feedback :state="validAccEthicHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="อื่นๆ">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <b-form-input
                    v-model="form.tfac.accOtherHour"
                    type="text"
                    placeholder="-"
                    :state="validAccOtherHour"
                    :readonly="readonly"
                    :formatter="editAccOtherHour"
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                  ></b-form-input> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.accOtherMin"
                    :options="minSelect"
                    :state="validAccOtherHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>
            <b-form-invalid-feedback :state="validAccOtherHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        ><b-col
          ><h6><strong>สำหรับผู้สอบบัญชีรับอนุญาต</strong></h6></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <b-form-group label="บัญชี">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <b-form-input
                    v-model="form.tfac.cpaAccHour"
                    type="text"
                    placeholder="-"
                    :readonly="readonly"
                    :state="validCpaAccHour"
                    :formatter="editCpaAccHour"
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                  ></b-form-input> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.cpaAccMin"
                    :options="minSelect"
                    :state="validCpaAccHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>
            <b-form-invalid-feedback :state="validCpaAccHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="จรรยาบรรณ">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <!-- tfc_cpaEthicHour -->
                  <b-form-input
                    type="text"
                    v-model="form.tfac.cpaEthicHour"
                    placeholder="-"
                    :readonly="readonly"
                    :state="validCpaEthicHour"
                    :formatter="editCpaEthicHour"
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                  ></b-form-input> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.cpaEthicMin"
                    :options="minSelect"
                    :state="validCpaEthicHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>
            <b-form-invalid-feedback :state="validCpaEthicHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="อื่นๆ">
            <b-row>
              <b-col
                ><b-input-group append="ชั่วโมง">
                  <b-form-input
                    type="text"
                    v-model="form.tfac.cpaOtherHour"
                    placeholder="-"
                    :readonly="readonly"
                    :state="validCpaOtherHour"
                    :formatter="editCpaOtherHour"
                    @blur="onblurTfacHour"
                    @keyup.enter="onblurTfacHour"
                  ></b-form-input> </b-input-group
              ></b-col>
              <b-col
                ><b-input-group append="นาที">
                  <b-form-select
                    v-model="tfac.cpaOtherMin"
                    :options="minSelect"
                    :state="validCpaOtherHour"
                    @change="onblurTfacHour"
                    value-field="value"
                    text-field="display"
                  /> </b-input-group
              ></b-col>
            </b-row>
            <b-form-invalid-feedback :state="validCpaOtherHour">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        ><b-col
          ><h5><strong>รหัสหลักสูตร</strong></h5></b-col
        ></b-row
      >
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <template v-slot:label>
              <h6>
                <strong>รหัสหลักสูตร (CPD)</strong>
              </h6>
            </template>
            <b-input-group>
              <b-form-input
                type="text"
                v-model="form.tfac.cpdCourseCode"
                placeholder=""
                :readonly="readonly"
                :state="validCpdCourseCode"
                :formatter="editCpdCourseCode"
                @blur="onblurTfacCpdCourseCode"
                @keyup.enter="onblurTfacCpdCourseCode"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validCpdCourseCode">
              {{cpdCourseCodeInvalidFeedback}}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4"
        ><b-col
          ><h5><strong>ราคาคอร์สและระยะเวลาเรียน</strong></h5></b-col
        ></b-row
      >
      <b-row
        ><b-col
          ><h6><strong>ระยะเวลาเรียน</strong></h6></b-col
        ></b-row
      >
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <template v-slot:label>
              วันที่อนุมัติหลักสูตร (CPD)<span class="text-danger">*</span>
            </template>
            <b-form-input
              required
              v-model="approveDate"
              type="date"
              :readonly="readonly"
              :state="validApproveDate"
              @change="validApproveDate = null"
              @blur="onblurCourseApproveDate"
              @keyup.enter="onblurCourseApproveDate"
            />
            <b-form-invalid-feedback id="input-approve-date-feedback">
              {{
                !this.form.approveDate || this.form.approveDate == ""
                  ? `กรุณากรอกวันที่อนุมัติหลักสูตร (CPD)`
                  : `วันที่เริ่มอนุมัติหลักสูตร (CPD) ต้องไม่เกินวันที่เปิดขายหลักสูตร`
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols='6'>
          <b-form-group label="วันที่เปิดขายหลักสูตร">
            <b-form-input
              required
              v-model="startSellingCourseDate"
              type="date"
              :readonly="readonly"
              :state="validStartSelling"
              @change="validStartSelling = null"
              @blur="onblurCourseInfoDate"
              @keyup.enter="onblurCourseInfoDate"
            />
            <b-form-invalid-feedback id="input-selling-date-feedback">
              วันที่เปิดขายหลักสูตร ต้องเป็นวันหลังจาก วันที่อนุมัติหลักสูตร
              (CPD)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              วันที่เริ่มหลักสูตร<span class="text-danger">*</span>
            </template>
            <b-form-input
              required
              id="input-start-date"
              type="date"
              v-model="startLearningDate"
              :state="validStartLearning"
              :readonly="readonly"
              @blur="onblurCourseInfoDate"
              @change="validStartLearning = null"
              @keyup.enter="onblurCourseInfoDate"
            />
            <b-form-invalid-feedback id="input-start-date-feedback">
              {{
                !this.form.startLearningDate ||
                this.form.startLearningDate == ""
                  ? `กรุณากรอกวันที่เริ่มหลักสูตร`
                  : `วันที่เริ่มวันที่เริ่มหลักสูตร ต้องเป็นวันเดียวกันหรือหลังจาก วันที่เปิดขายหลักสูตร`
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              วันที่จบหลักสูตร<span class="text-danger">*</span>
            </template>
            <b-form-input
              required
              v-model="finishLearningDate"
              type="date"
              id="input-finish-date"
              :state="validFinishLearning"
              :readonly="readonly"
              @blur="onblurCourseInfoDate"
              @change="validFinishLearning = null"
              @keyup.enter="onblurCourseInfoDate"
            />
            <b-form-invalid-feedback id="input-finish-date-feedback">
              {{
                !this.form.finishLearningDate ||
                this.form.finishLearningDate == ""
                  ? `กรุณากรอกวันที่จบหลักสูตร`
                  : `วันที่จบหลักสูตร ต้องเป็นวันหลังจาก วันที่เริ่มหลักสูตร`
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        ><b-col
          ><h6>
            <strong>ราคาคอร์ส</strong
            ><span class="text-secondary small"> ระบุราคาเป็นจำนวนเต็ม</span>
          </h6></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              ราคาสำหรับบุคคลทั่วไป<span class="text-danger">*</span>
            </template>
            <b-input-group append="บาท">
              <b-form-input
                required
                type="text"
                v-model="price"
                placeholder=""
                :readonly="readonly"
                :state="validPrice"
                :formatter="editPrice"
                @blur="onblurCourseInfoPrice"
                @keyup.enter="onblurCourseInfoPrice"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validPrice">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template v-slot:label>
              ราคาสำหรับสมาชิก<span class="text-danger">*</span>
            </template>
            <b-input-group append="บาท">
              <b-form-input
                required
                type="text"
                v-model="memberShipPrice"
                placeholder=""
                :readonly="readonly"
                :state="validMembershipPrice"
                :formatter="editMembershipPrice"
                @blur="onblurCoursePriceMembership"
                @keyup.enter="onblurCoursePriceMembership"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validMembershipPrice">
              กรุณากรอกราคาที่ถูกต้อง (0 หรือมากกว่า, ไม่มีเครื่องหมาย)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Numeral from "numeral";
import moment from "moment";

export default {
  name: "TfacPricingForm",
  filters: {
    countVat: function(value) {
      return value / 1.07;
    },

    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    },

    formatDate: function(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
      }
  },
  props: {
    readonly: { type: Boolean, default: false }
  },
  data() {
    return {
      minSelect: [
        { display: 0, value: 0 },
        { display: 15, value: 0.25 },
        { display: 30, value: 0.5 },
        { display: 45, value: 0.75 }
      ],
      tfac: {
        accAccMin: 0,
        accEthicMin: 0,
        accOtherMin: 0,
        cpaAccMin: 0,
        cpaEthicMin: 0,
        cpaOtherMin: 0,
        cpdCourseCode: null
      },
      form: {
        tfac: {
          accAccHour: 0,
          accEthicHour: 0,
          accOtherHour: 0,
          cpaAccHour: 0,
          cpaEthicHour: 0,
          cpaOtherHour: 0,
          cpdCourseCode: null
        },
        price: 0,
        membershipPrice: [
          {
            level: 1,
            price: 0
          }
        ],
        approveDate: null,
        startSellingCourseDate: null,
        startLearningDate: null,
        finishLearningDate: null
      },
      dateFormat: moment().format("YYYY-MM-DD"),
      validApproveDate: null,
      validStartSelling: null,
      validStartLearning: null,
      validFinishLearning: null,
      validCpdCourseCode:null,
      validAccAccHour:null,
      validAccEthicHour: null,
      validAccOtherHour:null,
      validCpaAccHour:null,
      validCpaEthicHour: null,
      validCpaOtherHour:null,
      validPrice:null,
      validMembershipPrice:null,

      cpdCourseCodeInvalidFeedback: "",
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
    approveDate: {
      get() {
        return moment(this.form.approveDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.approveDate = val;
      }
    },
    startSellingCourseDate: {
      get() {
        return moment(this.form.startSellingCourseDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.startSellingCourseDate = val;
      }
    },
    startLearningDate: {
      get() {
        return moment(this.form.startLearningDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.startLearningDate = val;
      }
    },
    finishLearningDate: {
      get() {
        return moment(this.form.finishLearningDate).format("YYYY-MM-DD");
      },
      set(val) {
        this.form.finishLearningDate = val;
      }
    },
    price: {
      get() {
        return Numeral(this.form.price).format("0,0");
      },
      set(val) {
        this.form.price = val.replace(/[^0-9]|e/g, "");
      }
    },
    memberShipPrice: {
      get() {
        return Numeral(this.form.membershipPrice[0].price).format("0,0");
      },
      set(val) {
        this.form.membershipPrice[0].price = val.replace(/[^0-9]|e/g,'');
      },
    },
  },
  async created() {
    await this.getCourseInfo({
      courseId: this.$route.params.courseId,
      temp: true,
      excepts: "numberOfStudent,numberOfActiveStudent"
    });
    this.form = JSON.parse(JSON.stringify(this.courseInfo));
    this.form.price = isNaN(parseInt(this.form.price))?null:parseInt(this.form.price||0);
    if(this.form.membershipPrice.length===0){
      this.form.membershipPrice.push(
        {
        price: null,
        level: 1,
        name: null,
        promotionPrice: null,
        learnDuration: null,
        learnTimeLimit: null,
        }
      );
    }else{
      this.form.membershipPrice[0].price = isNaN(parseInt(this.form.membershipPrice[0].price))?null:parseInt(this.form.membershipPrice[0].price||0);
    }
    this.tfac.accAccMin = this.form.tfac.accAccHour % 1;
    this.tfac.accEthicMin = this.form.tfac.accEthicHour % 1;
    this.tfac.accOtherMin = this.form.tfac.accOtherHour % 1;
    this.tfac.cpaAccMin = this.form.tfac.cpaAccHour % 1;
    this.tfac.cpaEthicMin = this.form.tfac.cpaEthicHour % 1;
    this.tfac.cpaOtherMin = this.form.tfac.cpaOtherHour % 1;
    this.form.tfac.accAccHour = parseInt(this.form.tfac.accAccHour);
    this.form.tfac.accEthicHour = parseInt(this.form.tfac.accEthicHour);
    this.form.tfac.accOtherHour = parseInt(this.form.tfac.accOtherHour);
    this.form.tfac.cpaAccHour = parseInt(this.form.tfac.cpaAccHour);
    this.form.tfac.cpaEthicHour = parseInt(this.form.tfac.cpaEthicHour);
    this.form.tfac.cpaOtherHour = parseInt(this.form.tfac.cpaOtherHour);
  },
  methods: {
    ...mapActions("CourseManagement", [
      "updateCoursePrice",
      "getCourseInfo",
      "updateCourseInfo",
      "updateTfacCourse",
      "updateCoursePriceMemberships"
    ]),
    editAccAccHour(value) {
      this.validAccAccHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editAccEthicHour(value) {
      this.validAccEthicHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editAccOtherHour(value) {
      this.validAccOtherHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editCpaAccHour(value) {
      this.validCpaAccHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editCpaEthicHour(value) {
      this.validCpaEthicHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editCpaOtherHour(value) {
      this.validCpaOtherHour = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      return isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);
    },
    editCpdCourseCode(value) {
      this.validCpdCourseCode = null;
      return value;
    },
    editPrice(value) {
      this.validPrice = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      if (isNaN(parseInt(newValue))) {
        return newValue;
      } else {
        return Numeral(parseInt(newValue)).format("0,0");
      }
    },
    editMembershipPrice(value) {
      this.validMembershipPrice = null;
      var newValue = value.replace(/[^0-9]|e/g, "");
      if (isNaN(parseInt(newValue))) {
        return newValue;
      } else {
        return Numeral(parseInt(newValue)).format("0,0");
      }
    },
    async onblurTfacHour() {
      var canUpdate = true;
      if (
        this.form.tfac.accAccHour < 0 ||
        this.form.tfac.accEthicHour < 0 ||
        this.form.tfac.accOtherHour < 0 ||
        this.form.tfac.cpaAccHour < 0 ||
        this.form.tfac.cpaEthicHour < 0 ||
        this.form.tfac.cpaOtherHour < 0
      ) {
        canUpdate = false;
        console.log("hour is negative");
        this.validAccAccHour =
          this.form.tfac.accAccHour < 0 ? false : this.validAccAccHour;
        this.validAccEthicHour =
          this.form.tfac.accAccHour < 0 ? false : this.validAccEthicHour;
        this.validAccOtherHour =
          this.form.tfac.accOtherHour < 0 ? false : this.validAccOtherHour;
        this.validCpaAccHour =
          this.form.tfac.cpaAccHour < 0 ? false : this.validCpaAccHour;
        this.validCpaEthicHour =
          this.form.tfac.cpaEthicHour < 0 ? false : this.validCpaEthicHour;
        this.validCpaOtherHour =
          this.form.tfac.cpaOtherHour < 0 ? false : this.validCpaOtherHour;
      }
      const accAccHour =
        parseInt(this.form.tfac.accAccHour || 0) + this.tfac.accAccMin;
      const accEthicHour =
        parseInt(this.form.tfac.accEthicHour || 0) + this.tfac.accEthicMin;
      const accOtherHour =
        parseInt(this.form.tfac.accOtherHour || 0) + this.tfac.accOtherMin;
      const cpaAccHour =
        parseInt(this.form.tfac.cpaAccHour || 0) + this.tfac.cpaAccMin;
      const cpaEthicHour =
        parseInt(this.form.tfac.cpaEthicHour || 0) + this.tfac.cpaEthicMin;
      const cpaOtherHour =
        parseInt(this.form.tfac.cpaOtherHour || 0) + this.tfac.cpaOtherMin;
      if (
        accAccHour == this.courseInfo.tfac.accAccHour &&
        accEthicHour == this.courseInfo.tfac.accEthicHour &&
        accOtherHour == this.courseInfo.tfac.accOtherHour &&
        cpaAccHour == this.courseInfo.tfac.cpaAccHour &&
        cpaEthicHour == this.courseInfo.tfac.cpaEthicHour &&
        cpaOtherHour == this.courseInfo.tfac.cpaOtherHour
      ) {
        canUpdate = false;
        this.validAccAccHour = true;
        this.validAccEthicHour = true;
        this.validAccOtherHour = true;
        this.validCpaAccHour = true;
        this.validCpaEthicHour = true;
        this.validCpaOtherHour = true;
      }
      if (canUpdate) {
        await this.updateTfacCourse({
          courseId: this.$route.params.courseId,
          payloads: {
            accAccHour: accAccHour || 0,
            accEthicHour: accEthicHour || 0,
            accOtherHour: accOtherHour || 0,
            cpaAccHour: cpaAccHour || 0,
            cpaEthicHour: cpaEthicHour || 0,
            cpaOtherHour: cpaOtherHour || 0
          }
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
        this.validAccAccHour = true;
        this.validAccEthicHour = true;
        this.validAccOtherHour = true;
        this.validCpaAccHour = true;
        this.validCpaEthicHour = true;
        this.validCpaOtherHour = true;
      }
    },
    async onblurTfacCpdCourseCode(){
      if(this.form.tfac.cpdCourseCode==this.courseInfo.tfac.cpdCourseCode){
        this.validCpdCourseCode = true
      }
      else{
        try{
          await this.updateTfacCourse({
            courseId: this.$route.params.courseId,
            payloads:{
              cpdCourseCode: this.form.tfac.cpdCourseCode || null,
            }
          })
          await this.getCourseInfo({
            courseId: this.$route.params.courseId,
            temp: true,
            excepts: 'numberOfStudent,numberOfActiveStudent',
          });
          this.validCpdCourseCode = true;
        }catch(error){
          console.log(error);
          if(error =='course code already used'){
            this.cpdCourseCodeInvalidFeedback = 'รหัสหลักสูตร (CPD) นี้ถูกใช้ไปแล้ว';
            this.validCpdCourseCode = false;
          }else{
            this.cpdCourseCodeInvalidFeedback = 'เกิดข้อผิดพลาดกกรุณาลองใหม่อีกครั้ง';
            this.validCpdCourseCode = false;
          }
        }
      }
    },
    async onblurCourseInfoDate() {
      this.validStartSelling = null;
      this.validStartLearning = null;
      this.validFinishLearning = null;
      if (
        this.form.startSellingCourseDate ===
          this.courseInfo.startSellingCourseDate &&
        this.form.startLearningDate === this.courseInfo.startLearningDate &&
        this.form.finishLearningDate === this.courseInfo.finishLearningDate
      ) {
        this.validStartSelling = true;
        this.validStartLearning = true;
        this.validFinishLearning = true;
      } else if (
        this.form.startSellingCourseDate &&
        this.form.approveDate &&
        this.form.approveDate >= this.form.startSellingCourseDate
      ) {
        this.validStartSelling = false;
      } else if (
        !this.form.startLearningDate ||
        this.form.startLearningDate == ""
      ) {
        this.validStartLearning = false;
      } else if (
        !this.form.finishLearningDate ||
        this.form.finishLearningDate == ""
      ) {
        this.validFinishLearning = false;
      } else if (
        this.form.startSellingCourseDate &&
        this.form.startLearningDate &&
        this.form.startSellingCourseDate > this.form.startLearningDate
      ) {
        this.validStartLearning = false;
      } else if (
        this.form.startLearningDate &&
        this.form.finishLearningDate &&
        this.form.startLearningDate >= this.form.finishLearningDate
      ) {
        this.validFinishLearning = false;
      } else {
        await this.updateCourseInfo({
          courseId: this.$route.params.courseId,
          attribute: {
            startSellingCourseDate: this.form.startSellingCourseDate,
            startLearningDate: this.form.startLearningDate,
            finishLearningDate: this.form.finishLearningDate
          }
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
        this.validStartSelling = true;
        this.validStartLearning = true;
        this.validFinishLearning = true;
      }
    },
    async onblurCourseApproveDate() {
      if (!this.form.approveDate) {
        this.validApproveDate = false;
      } else if (
        this.form.startSellingCourseDate &&
        this.form.approveDate &&
        this.form.startSellingCourseDate < this.form.approveDate
      ) {
        this.validApproveDate = false;
      } else if (this.form.approveDate === this.courseInfo.approveDate) {
        this.validApproveDate = true;
      } else {
        await this.updateCourseInfo({
          courseId: this.$route.params.courseId,
          attribute: {
            approveDate: this.form.approveDate
          }
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
        this.validApproveDate = true;
      }
    },
    async onblurCourseInfoPrice() {
      if (isNaN(parseFloat(this.form.price)) || this.form.price < 0) {
        this.validPrice = false;
      } else if (Number(this.form.price).toFixed(2) === this.courseInfo.price) {
        this.validPrice = true;
      } else if (parseFloat(this.form.price) !== parseInt(this.form.price)) {
        this.validPrice = false;
      } else {
        await this.updateCourseInfo({
          courseId: this.$route.params.courseId,
          attribute: {
            price: this.form.price
          }
        });
        await this.updateCoursePrice({
          courseId: this.$route.params.courseId,
          payloads: {
            courseLearnDuration: null,
            courseLearnTimeLimit: null,
            exCoursePrice: this.form.price || 0,
            curCoursePrice: this.form.price || 0,
            coursePriceStartDate: null,
            coursePriceEndDate: null
          }
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
        this.validPrice = true;
      }
    },
    async onblurCoursePriceMembership() {
      if (
        isNaN(parseFloat(this.form.membershipPrice[0].price)) ||
        this.form.membershipPrice[0].price < 0
      ) {
        this.validMembershipPrice = false;
      } else if (
        this.courseInfo.membershipPrice.length > 0 &&
        Number(this.form.membershipPrice[0].price).toFixed(2) ==
          this.courseInfo.membershipPrice[0].price
      ) {
        this.validMembershipPrice = true;
      } else {
        await this.updateCoursePriceMemberships({
          courseId: this.$route.params.courseId,
          membershipPrices: [
            {
              price: this.form.membershipPrice[0].price,
              level: 1,
              name: null,
              promotionPrice: null,
              learnDuration: null,
              learnTimeLimit: null
            }
          ]
        });
        await this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent"
        });
        this.validMembershipPrice = true;
      }
    }
  }
};
</script>

<template>
  <div>
    <PageHeader
      v-if="comId == 437"
      title="ดาวน์โหลดรายงานการเข้าเรียน N Health"
    ></PageHeader>
    <PageHeader
      v-if="comId == 498"
      title="ดาวน์โหลดรายงานการเข้าเรียน HRH Medical"
    ></PageHeader>
    <PageHeader
      v-if="comId == 499"
      title="ดาวน์โหลดรายงานการเข้าเรียน คณะแพทย์ศาสตร์ มหาวิทยาลัยขอนแก่น"
    ></PageHeader>
    <b-container class="widthPage pt-5">
      <div v-if="comId == 437">
        <layout>
          <div class="inner bg-container">
            <div id="choose-group">
              <h6 class="d-block mt-2">เลือกกลุ่มเรียน</h6>
              <b-select
                style="width: 250px"
                v-if="groupData.length != 0"
                v-model="gId"
              >
                <option
                  v-for="data in groupData.data"
                  :key="data.id"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </b-select>
            </div>
            <div style="display: flex; width: 100%">
              <div id="choose-cer-start-date" class="mr-4" style="width: 300px">
                <h6 class="mt-5 w-100">เลือกวันที่เริ่มลงทะเบียนคอร์ส</h6>
                <b-form-datepicker v-model="cerStartDate" class="w-100" />
              </div>
              <div id="choose-cer-end-date" style="width: 300px">
                <h6 class="mt-5">เลือกวันสิ้นสุดคอร์ส</h6>
                <b-form-datepicker
                  v-model="cerEndDate"
                  :min="cerStartDate"
                  class="w-100"
                />
              </div>
            </div>
            <div id="choose-pre-course">
              <h6 class="d-block mt-5">เลือกคอร์สสำหรับ Pre-Test</h6>
              <v-select
                style="width: 70%"
                v-if="courseData.length != 0"
                v-model="pSelect"
                :options="courseData.data"
                label="courseName"
                @input="getCourseQuiz('pretest')"
              ></v-select>
            </div>
            <div v-if="pSelect.courseId">
              <div v-if="pQuizData.length > 0">
                <div id="choose-pre-exam">
                  <h6 class="d-block mt-3">เลือกข้อสอบ Pre-Test</h6>
                  <b-select
                    style="width: 70%"
                    v-if="pQuizData.length != 0"
                    v-model="preTest"
                  >
                    <option
                      v-for="data in pQuizData"
                      :key="data.examId"
                      :value="data.examId"
                    >
                      {{ data.lecName }}
                    </option>
                  </b-select>
                </div>
              </div>
              <div v-else-if="pQuizData == -1" class="mt-3">
                กำลังโหลดข้อมูล...
              </div>
              <div v-else class="mt-3">บทเรียนนี้ไม่มีข้อสอบ</div>
            </div>
            <div id="choose-post-course">
              <h6 class="d-block mt-5">เลือกคอร์สสำหรับ Post-Test</h6>
              <v-select
                style="width: 70%"
                v-if="courseData.length != 0"
                v-model="poSelect"
                :options="courseData.data"
                label="courseName"
                @input="getCourseQuiz('posttest')"
              ></v-select>
            </div>
            <div v-if="poSelect.courseId">
              <div v-if="poQuizData.length > 0">
                <div id="choose-post-exam">
                  <h6 class="d-block mt-3">เลือกข้อสอบ Post-Test</h6>
                  <b-select
                    style="width: 70%"
                    v-if="poQuizData.length != 0"
                    v-model="postTest"
                  >
                    <option
                      v-for="data in poQuizData"
                      :key="data.examId"
                      :value="data.examId"
                    >
                      {{ data.lecName }}
                    </option>
                  </b-select>
                </div>
              </div>
              <div v-else-if="poQuizData == -1" class="mt-3">
                กำลังโหลดข้อมูล...
              </div>
              <div v-else class="mt-3">บทเรียนนี้ไม่มีข้อสอบ</div>
            </div>
            <div class="mt-5">
              <b-button
                @click="exportData('bki')"
                :disabled="
                  gId == '' ||
                    cerStartDate == '' ||
                    cerEndDate == '' ||
                    preTest == '' ||
                    postTest == ''
                "
              >
                ดาวน์โหลดรายงาน
              </b-button>
              <b-spinner
                ref="spinner"
                variant="primary"
                class="ml-3"
                style="display: none"
              ></b-spinner>
            </div>
          </div>
        </layout>
      </div>
    </b-container>
    <div v-if="comId == 313">
      <div class="outer">
        <div class="inner bg-container">
          <div id="choose-course">
            <h6 class="d-block mt-5">เลือกคอร์สเรียน</h6>
            <v-select
              style="width: 70%"
              v-if="courseData.length != 0"
              v-model="courseId"
              :options="courseData.data"
              label="courseName"
            ></v-select>
          </div>
          <div style="display: flex; width: 100%">
            <div id="choose-date" class="mr-4" style="width: 650px">
              <h6 class="mt-5 w-100">เลือกช่วงเวลาที่ผู้เรียนเรียนจบคอร์ส</h6>
              <div style="display: flex">
                <b-form-datepicker v-model="startDate" class="w-100 mr-3" />
                <h6 class="mt-2">ถึง</h6>
                <b-form-datepicker
                  v-model="stopDate"
                  :min="startDate"
                  class="w-100 ml-3"
                />
              </div>
            </div>
          </div>
          <div class="mt-5">
            <b-button
              @click="exportData('nhealth')"
              :disabled="
                courseId.courseId == '' || startDate == '' || stopDate == ''
              "
            >
              ดาวน์โหลดรายงาน
            </b-button>
            <b-spinner
              ref="spinner"
              class="ml-3"
              variant="primary"
              style="display: none"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <div v-if="comId == 498">
      <div class="outer">
        <div class="inner bg-container">
          <div id="choose-course">
            <h6 class="d-block mt-5">เลือกคอร์สเรียน</h6>
            <v-select
              style="width: 70%"
              v-if="courseData.length != 0"
              v-model="courseId"
              :options="courseData.data"
              label="courseName"
            ></v-select>
          </div>
          <div style="display: flex; width: 100%">
            <div id="choose-date" class="mr-4" style="width: 650px">
              <h6 class="mt-5 w-100">เลือกช่วงเวลาที่ผู้เรียนเรียนจบคอร์ส</h6>
              <div style="display: flex">
                <b-form-datepicker v-model="startDate" class="w-100 mr-3" />
                <h6 class="mt-2">ถึง</h6>
                <b-form-datepicker
                  v-model="stopDate"
                  :min="startDate"
                  class="w-100 ml-3"
                />
              </div>
            </div>
          </div>
          <div class="mt-5">
            <b-button
              @click="exportData('hrh')"
              :disabled="
                courseId.courseId == '' || startDate == '' || stopDate == ''
              "
            >
              ดาวน์โหลดรายงาน
            </b-button>
            <b-spinner
              ref="spinner"
              class="ml-3"
              variant="primary"
              style="display: none"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <div v-if="comId == 499">
      <div class="outer">
        <div class="inner bg-container">
          <div id="choose-course">
            <h6 class="d-block mt-5">เลือกคอร์สเรียน</h6>
            <v-select
              style="width: 70%"
              v-if="courseData.length != 0"
              v-model="courseId"
              :options="courseData.data"
              label="courseName"
            ></v-select>
          </div>
          <div style="display: flex; width: 100%">
            <div id="choose-date" class="mr-4" style="width: 650px">
              <h6 class="mt-5 w-100">เลือกช่วงเวลาที่ผู้เรียนเรียนจบคอร์ส</h6>
              <div style="display: flex">
                <b-form-datepicker v-model="startDate" class="w-100 mr-3" />
                <h6 class="mt-2">ถึง</h6>
                <b-form-datepicker
                  v-model="stopDate"
                  :min="startDate"
                  class="w-100 ml-3"
                />
              </div>
            </div>
          </div>
          <div class="mt-5">
            <b-button
              @click="exportData('mdkku')"
              :disabled="
                courseId.courseId == '' || startDate == '' || stopDate == ''
              "
            >
              ดาวน์โหลดรายงาน
            </b-button>
            <b-spinner
              ref="spinner"
              class="ml-3"
              variant="primary"
              style="display: none"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import PageHeader from "../../layouts/PageHeader";
import api from "@/services/api.js";
import Layout from "@/components/pages/analytics/shared/Layout.vue";
export default {
  components: { PageHeader, vSelect, Layout },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      //BKI
      gId: "",
      time: "00:00:00",
      cerStartDate: "",
      cerEndDate: "",
      preTest: "",
      postTest: "",
      groupData: [],
      courseData: [],
      pSelect: [],
      poSelect: [],
      pQuizData: -1,
      poQuizData: -1,
      //NHealth
      courseId: "",
      startDate: "",
      stopDate: ""
    };
  },
  async mounted() {
    const group = await api.get(`/v1/companies/${this.comId}/member-groups`);
    const course = await api.get(`/v1/companies/${this.comId}/courses`);
    this.groupData = group.data;
    this.courseData = course.data;
  },
  methods: {
    async exportData(company) {
      this.$refs["spinner"].style.display = "inline-block";
      var getApi = "";
      if (company == "bki") {
        getApi = `/v1/analytics/bki/exportcsv/?comId=${this.comId}&gId=${this.gId}&cerStartDate=${this.cerStartDate} ${this.time}&cerEndDate=${this.cerEndDate} ${this.time}&pretestExamId=${this.preTest}&posttestExamId=${this.postTest}`;
      } else if (company == "nhealth") {
        getApi = `/v1/analytics/nhealth/exportcsv?com_id=${this.comId}&c_id=${this.courseId.courseId}&start_date=${this.startDate}&stop_date=${this.stopDate}`;
      } else if (company == "hrh") {
        getApi = `/v1/analytics/nhealth/exportcsv?com_id=${this.comId}&c_id=${this.courseId.courseId}&start_date=${this.startDate}&stop_date=${this.stopDate}`;
      } else if (company == "mdkku") {
        getApi = `/v1/analytics/nhealth/exportcsv?com_id=${this.comId}&c_id=${this.courseId.courseId}&start_date=${this.startDate}&stop_date=${this.stopDate}`;
      }
      const res = await api.get(getApi).finally(() => {
        this.$refs["spinner"].style.display = "none";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res.data);
      hiddenElement.target = "_blank";
      hiddenElement.download = "export-csv.csv";
      hiddenElement.click();
    },
    //BKI
    async getCourseQuiz(test) {
      if (test == "pretest") {
        this.preTest = "";
        this.pQuizData = -1;
        const quiz = await api.get(
          `/v1/courses/${this.pSelect.courseId}/quiz-lectures`
        );
        this.pQuizData = quiz.data;
      } else if (test == "posttest") {
        this.postTest = "";
        this.poQuizData = -1;
        const quiz = await api.get(
          `/v1/courses/${this.poSelect.courseId}/quiz-lectures`
        );
        this.poQuizData = quiz.data;
      }
    }
  }
};
</script>
<style scoped>
.widthPage {
  max-width: 81.25rem;
}
</style>

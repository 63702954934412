<template>
  <div>
    <PageHeader title="ทรัพยากรในระบบ"></PageHeader>
    <b-row class="pl-5 pr-5 pt-3">
      <b-col cols="12">
        <storage :apiRoute="`/v1/resources/storage-limit`"></storage
      ></b-col>
    </b-row>
    <b-row class="pl-5 pr-5 pt-3">
      <b-col cols="12">
        <learningTime
          :apiRoute="`/v1/resources/learning-time-limit`"
        ></learningTime>
      </b-col>
    </b-row>
    <b-row class="pl-5 pr-5 pt-3">
      <b-col cols="12">
        <maximumUser :apiRoute="`/v1/resources/get-maximum-user`"></maximumUser
      ></b-col>
    </b-row>
  </div>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import learningTime from "./panel/LearningTimeLimitPanel";
import storage from "./panel/StorageLimitPanel";
import maximumUser from "./panel/MaximumUserLimitPanel";
import api from "@/services/api.js";

export default {
  components: {
    PageHeader,
    learningTime,
    storage,
    maximumUser,
  },

  async mounted() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
};
</script>

<style scoped></style>

<template>
  <layout>
    <template v-slot:header>
      <b-row>
        <b-col cols="12">
          <h6>Top Spender</h6>
        </b-col>
      </b-row>
    </template>
    <template v-slot:body>
      <div class>
        <b-table
          striped
          id="course-lecture-ranking-table"
          :per-page="10"
          :current-page="1"
          table-class="tbNumber text-left "
          :items="result"
          :fields="fields"
          small
          sort-icon-left
          responsive
          :busy="isBusy"
          hover
        >
          <template v-slot:cell(name)="data"
            ><span>{{
              (data.item.mp_firstName + ' ' + data.item.mp_lastName) | cut
            }}</span></template
          >

          <template v-slot:cell(amount)="data">
            <span style="float : right;">{{
              data.item.amount | thaiBaht
            }}</span>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <div style="text-align: center" v-if="result.length == 0">
          {{ 'ไม่มีข้อมูล' }}
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import api from '@/services/api.js';
export default {
  components: {
    layout,
  },
  props: ['apiRoute'],
  data() {
    return {
      result: [],
      isBusy: true,
      fields: [
        { key: 'name', label: 'ชื่อผู้เรียน' },
        {
          key: 'amount',
          label: 'จำนวนเงินที่ชำระ',
          class: 'text-right',
        },
      ],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>
<style scoped></style>

<template>
  <li class="dropdown_menu" :class="mainExamListCssObj">
    <a v-b-toggle.etcCollapse @click.prevent="toggleCollapse">
      <b-icon-chat-right-text-fill />

      <span class="link-title menu_hide ml-1">อื่นๆ</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="etcCollapse" :visible="false" accordion="accordion">
      <main-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainCollapseMenu from '@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue';
import _ from 'lodash';

export default {
  components: {
    MainCollapseMenu,
  },
  data() {
    return {
      isActive: false,
      collapseList: [
        {
          title: 'ทรัพยากรในระบบ',
          to: {
            name: 'resource.monitoring.overview',
          },
        },
      ],
    };
  },
  computed: {
    mainExamListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  watch: {
    // $route() {
    //     this.checkActiveRoute()
    // },
  },
  mounted() {
    // this.checkActiveRoute()
  },
  methods: {
    toggleCollapse() {
      // this.isActive = !this.isActive
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>

<template>
  <div>
    <ExamOverallHeader />
    <ExamOverallContent />
  </div>
</template>

<script>
import ExamOverallHeader from "@/components/pages/exam-analytics/exams-overall/ExamOverallHeader.vue";
import ExamOverallContent from "@/components/pages/exam-analytics/exams-overall/ExamOverallContent.vue";
export default {
  components: {
    ExamOverallHeader,
    ExamOverallContent
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{staticStyle:{"margin-left":"-10%"},attrs:{"title":"ภาพรวมผู้เรียน"}}),_c('b-container',{staticClass:"widthPage"},[_c('div',{staticClass:"py-5"},[_c('layout',{staticClass:"px-4 py-2 user-overview"},[_c('LearningStatPanel',{attrs:{"company":_vm.company}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"p-4"},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":_vm.hasRoles([
                  'org/admin',
                  'org/hr',
                  'org/audit',
                  'tutor/admin',
                  'tutor/moderator',
                ])
                  ? 7
                  : 12}},[_c('div',{staticClass:"pl-3 pr-3 pr-lg-0 mb-3 mb-lg-0"},[_c('recent-student-study-panel')],1)]),(
                _vm.hasRoles([
                  'org/admin',
                  'org/hr',
                  'org/audit',
                  'tutor/admin',
                  'tutor/moderator',
                ])
              )?_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('div',{staticClass:"pl-3 pl-lg-0 pr-3"},[_c('most-login-student-panel')],1)]):_vm._e()],1),(
              _vm.hasRoles([
                'org/admin',
                'org/hr',
                'org/audit',
                'tutor/admin',
                'tutor/moderator',
              ])
            )?_c('b-row',{staticClass:"p-3 map-row"},[_c('b-col',{staticClass:"pr-3",attrs:{"cols":"12","lg":"6"}},[_c('province-seperated-panel')],1),_c('b-col',{staticClass:"pr-3",attrs:{"cols":"12","lg":"6"}},[_c('province-many-login-panel')],1)],1):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
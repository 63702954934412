<template>
  <div>
    <b-table
      hover
      responsive
      :show-empty="true"
      empty-text="ไม่มีรายการ live ล่วงหน้า"
      :items="getPlanList"
      :fields="fields"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
      :busy.sync="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <!-- DateTime coloumn -->
      <template v-slot:cell(start_datetime)="data">
        {{ dateFormat(data.value) }}
        <span class="small text-muted d-block">
          {{ timeFormat(data.value) }}
        </span>
      </template>

      <template v-slot:cell(topic)="{ item }">
        <b-link
          class="font-weight-bold"
          :disabled="isEditDelBtnEnable(item.status)"
          @click="toPageEditMeetingForm(item)"
        >
          {{ item.topic }}
        </b-link>
        <span class="small text-muted d-block">
          {{ item.instructor.ownerName }}
        </span>
      </template>

      <!-- <template v-slot:cell(course)="{ item }">
        <div v-if="item.courseName">
          {{ item.courseName }}
          <span class="small text-muted d-block">
            {{ item.instructor.ownerName }}
          </span>
        </div>
        <div v-else>ผู้เรียนทุกคนในระบบ</div>
      </template> -->

      <template v-slot:cell(actions)="row">
        <b-button
          :disabled="isStartBtnEnable(row.item.status)"
          size="sm"
          variant="primary"
          class="mr-2"
          @click="openNewTab(row.item)"
          onclick="this.blur();"
        >
          {{ row.item.type === "ZOOM" ? "เริ่ม Live" : "เปิด Live" }}
        </b-button>

        <b-button
          :disabled="isEditDelBtnEnable(row.item.status)"
          size="sm"
          variant="outline-dark"
          @click="toPageEditMeetingForm(row.item)"
          class="mr-2"
          onclick="this.blur();"
          >แก้ไข</b-button
        >

        <b-button
          :disabled="isEditDelBtnEnable(row.item.status)"
          size="sm"
          variant="outline-danger"
          @click="deleteMeeting(row.item)"
          class="mr-2"
          onclick="this.blur();"
          >ลบ</b-button
        >
      </template>
    </b-table>

    <!-- <b-modal id="third-party-modal">
      <template v-slot:modal-header="">
        <h4>การเริ่ม Live จาก Youtube</h4>
      </template>
      <template v-slot:default="">
        <h5>คำแนะนำ</h5>
        <p>...</p>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="info" @click="ok()">เข้าใจแล้ว</b-button>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "PlanMeetTable",
  data() {
    return {
      sortBy: "start_datetime",
      sortDesc: true,
      fields: [
        {
          key: "start_datetime",
          label: "เริ่มเวลา",
          sortable: true,
        },
        {
          key: "topic",
          label: "หัวข้อ",
          sortable: true,
        },
        // {
        //   key: "course",
        //   label: "สำหรับผู้เรียน",
        //   sortable: true,
        // },
        {
          key: "status",
          label: "สถานะ",
          sortable: true,
          formatter: (value) => {
            let title = {
              DRAFT: "ร่าง",
              PUBLISH: "ยังไม่เริ่ม",
              STARTED: "กำลัง Live",
            };
            return title[value];
            // if (Object?.hasOwnProperty.call(title, value)) return title[value];
            // else return ".....";
          },
        },
        {
          key: "actions",
          label: "",
          sortable: false,
          tdClass: "text-right",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("LiveMeeting", ["getPlanList"]),
    isBusy: {
      get() {
        return this.getIsBusy();
      },
      set(newBool) {
        return this.setIsBusy(newBool);
      },
    },
  },
  methods: {
    ...mapActions("LiveMeeting", [
      "editMeetingForm",
      "setIsBusy",
      "submitDeleteMeeting",
    ]),
    ...mapActions("LiveForm", ["fillInForm"]),
    ...mapGetters("LiveMeeting", ["getIsBusy"]),
    isStartBtnEnable(status) {
      return status == "PUBLISH" ? false : true;
    },
    isEditDelBtnEnable(status) {
      return status == "STARTED" ? true : false;
    },
    toPageEditMeetingForm(item) {
      this.$router.push({
        name: "live.form.edit",
        params: {
          liveID: item.live_id,
          live: item,
        },
      });
      this.fillInForm(item);
    },
    deleteMeeting(item) {
      this.submitDeleteMeeting({ vm: this, meeting: item });
    },
    openNewTab(item) {
      if (item.type == "ZOOM") window.open(item.start_url, "_blank");
      else {
        window.open(item.join_url, "_blank");
        // this.$bvModal.show('third-party-modal')
      }
    },
    dateFormat(date) {
      moment.updateLocale("th", {});
      let year = parseInt(moment(date).format("YYYY"));
      year += 543;
      return moment(date).format(`DD MMM ${year}`);
    },
    timeFormat(time) {
      return moment(time).format("ddd, HH:mm");
    },
  },
};
</script>

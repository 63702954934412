import AffiliateLink from '@/components/pages/affiliate/link/AffiliateLink.vue'
import AffiliateRevenue from '@/components/pages/affiliate/revenue/AffiliateRevenue.vue'

export default [
  {
    path: 'affiliate/link',
    name: 'affiliate.link',
    component: AffiliateLink
  },
  {
    path: 'affiliate/revenue',
    name: 'affiliate.revenue',
    component: AffiliateRevenue
  }
]
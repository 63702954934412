export default {
  methods: {
    // For display the toast
    // Params: options (object with required attributes: variant, title, content)
    displayToast(options) {
      this.$bvToast.toast(options.content, {
        title: options.title,
        variant: options.variant,
        toaster: options.toaster ? options.toaster : 'b-toaster-bottom-right',
        autoHideDelay: options.autoHideDelay ? options.autoHideDelay : 2000,
        appendToast: true,
        noHoverPause: true,
        noCloseButton: true
      })
    }
  }
}
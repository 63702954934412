<!-- HTML Part -->
<template>
  <div>
    <div :class="statColor">{{ statTxt }}</div>
    <p>รูป{{ title }}</p>
    <br />
    <img
      width= "200px"
      v-if="path"
      :value="imgData"
      :src="src"
      style="margin-right: 1em"
    />
    <input
      type="file"
      :id="`imgFile${title}`"
      name="filename"
      @change="uploadImg()"
    />
  </div>
</template>

<!-- Javascript Part -->
<script>
//Import
//--Js
import axios from "axios";
//Export
export default {
  //--Class Property
  props: {
    title: { type: String, default: null },
    endpoint: { type: String, default: null },
    config: { type: Object, default: null },
    path: { type: String, default: null },
    src: { type: String, default: null },
    base: { type: String, default: null },
    imgFormName: { type: String, default: null },
    fd: { type: FormData, default: null },
    method: { type: String, default: "POST" }
  },
  data() {
    return {
      //Global Variables
      token: localStorage.getItem("token"),
      //Local
      formData: this.fd,
      statColor: null,
      statTxt: null,
      imgData: null
    };
  },
  methods: {
    uploadImg() {
      let files = event.target.files;
      let extension = files[0].type.split("/")[1];
      if (extension == "jpeg" || extension == "jpg" || extension == "png") {
        this.upToServer(files);
      } else {
        this.statColor = "red-warning";
        this.statTxt =
          "อัปโหลดรูปภาพไม่สำเร็จ โปรดเลือกเฉพาะไฟล์ .png .jpg .jpeg เท่านั้น";
        this.imgData = "";
      }
    },
    upToServer(files) {
      this.formData.append(this.imgFormName, files[0]);
      axios({
        url: this.base + this.endpoint,
        data: this.formData,
        method: this.method,
        headers: this.config
      })
        .then(result => {
          this.formData.delete(this.imgFormName);
          let response = result.data;
          this.success(response, "Card");
          if (
            response.status == "This member has been registered" &&
            this.title == "ถ่ายหน้าตรง" &&
            this.method == "POST"
          ) {
            this.method = "PUT";
            this.upToServer(files);
          } else if (response.status == "success") {
            this.statColor = "green-warning";
            this.statTxt = "อัปโหลดรูปภาพสำเร็จแล้ว";
          } else {
            this.statColor = "red-warning";
            this.statTxt = "อัปโหลดรูปภาพไม่สำเร็จ";
            this.imgData = "";
          }
        })
        .catch(error => {
          this.formData.delete(this.imgFormName);
          this.statColor = "red-warning";
          this.statTxt = "อัปโหลดรูปภาพไม่สำเร็จ";
          this.imgData = "";
          console.log("[Error] ImgUpload - " + error);
        });
    },
    success(val, name) {
      this.$emit("success", {
        name: name,
        value: val
      });
    }
  }
};
</script>

<!-- Css Part -->
<style scoped>
.red-warning {
  color: red;
  background: #ffcccb;
  border-radius: 5px;
  padding: 0.5em;
}
.green-warning {
  color: green;
  background: #90ee90;
  border-radius: 5px;
  padding: 0.5em;
}
</style>
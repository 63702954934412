<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>จํานวนการดาวน์โหลดเอกสารของหลักสูตร</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/siamkubota/analytics/courses/${cId}/material-downloads`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-table
            id="course-download-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(course)="data">
              <span>{{ data.item.course | cut }}</span>
            </template>

            <template v-slot:cell(download)>
              <span>{{ getRandomInt(1, 5000) }}</span>
            </template>

            <template v-slot:cell(btn)>
              <b-button size="sm" variant="outline-primary">
                <span>ดูข้อมูล</span>
              </b-button>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="course-download-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";

export default {
  components: {
    layout,
  },
  props: ["perPage"],
  data() {
    return {
      cId: this.$route.params.cId,
      currentPage: 1,
      rows: 0,
      isBusy: true,
      fields: [
        { key: "course", sortable: false, label: "เอกสารจากบทเรียน" },
        {
          key: "download",
          sortable: false,
          label: "จำนวนการดาวน์โหลด",
        },
        {
          key: "btn",

          label: "",
        },
      ],
      items: [
        {
          course: "1.1 Roadmap",
          download: this.getRandomInt(),
        },
        {
          course: "2.1 โครงสร้างแทรกเตอร์",
          download: this.getRandomInt(),
        },
        {
          course: "3.1 การใช้งานอย่างปลอดภัย",
          download: this.getRandomInt(),
        },
        {
          course: "3.2 การตรวจเช็คก่อนใช้งาน",
          download: this.getRandomInt(),
        },
        {
          course: "3.3 การสตาร์ทเครื่องยนต์",
          download: this.getRandomInt(),
        },
        {
          course: "3.4 การใช้งานคันควบคุมต่างๆของแทรกเตอร์",
          download: this.getRandomInt(),
        },
        {
          course: "3.5 การตรวจเช็คแทรกเตอร์ในขณะใช้งาน",
          download: this.getRandomInt(),
        },
        {
          course: "4.1 การขับเคลื่อนตัวเปล่า",
          download: this.getRandomInt(),
        },
        {
          course: "5.1 ขั้นตอนการส่งมอบแทรกเตอร์ 1",
          download: this.getRandomInt(),
        },
        {
          course: "6.1 ขั้นตอนการตรวจเช็คแทรกเตอร์ (Part1)",
          download: this.getRandomInt(),
        },
      ],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  mounted: function() {
    this.isBusy = true;
    this.rows = this.items.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },
};
</script>

import { render, staticRenderFns } from "./TextareaForm.vue?vue&type=template&id=995eed60&"
import script from "./TextareaForm.vue?vue&type=script&lang=js&"
export * from "./TextareaForm.vue?vue&type=script&lang=js&"
import style0 from "./TextareaForm.vue?vue&type=style&index=0&id=995eed60&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
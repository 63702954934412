<template>
  <span class="markdown" :style="size" v-html="compileMarkdown(message)" />
</template>

<script>
import Md from "markdown-it";
import Mk from "markdown-it-katex";
import Mv from "markdown-it-video";

export default {
  props: {
    message: {
      required: true,
      type: String
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      markdown: {
        youtube: {
          width: 640,
          height: 390
        }
      }
    };
  },
  computed: {
    size() {
      return {
        ...(!!this.height && {
          height: `${this.height}px`
        }),
        ...(!!this.width && {
          width: `${this.width}px`
        })
      };
    }
  },
  methods: {
    compileMarkdown(text) {
      return Md({
        html: true,
        linkify: true,
        typographer: true,
        breaks: true
      })
        .use(Mk)
        .use(Mv, {
          youtube: {
            width: this.markdown.youtube.width,
            height: this.markdown.youtube.height
          }
        })
        .render(text);
    }
  }
};
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/github-markdown-css/2.2.1/github-markdown.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.5.1/katex.min.css";
</style>

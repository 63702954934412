<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="จํานวนการดาวน์โหลดเอกสารของหลักสูตร"></PageHeader>
      <b-row style="margin:0.5rem 0 0.5rem 0;">
        <strong><p>ข้อมูลของวันที่</p></strong>
        <b-form-datepicker
          v-model="startDate"
          class="mb-2"
          style="width:25%;margin:0 1rem 0 1rem;"
        ></b-form-datepicker>

        <strong><p>ถึง</p></strong>

        <b-form-datepicker
          v-model="endDate"
          class="mb-2"
          style="width:25%;margin:0 1rem 0 1rem;"
        ></b-form-datepicker>
      </b-row>
      <b-row>
        <b-col cols="12">
          <downloadMaterial :perPage="20"></downloadMaterial>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import downloadMaterial from "./panel/CourseMaterialDownloadPanel";
import moment from "moment";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      cId: this.$route.params.cId
    };
  },
  components: {
    PageHeader,
    downloadMaterial
  }
};
</script>
<style scoped></style>

<template>
  <div>
    <page-header :title="'ผู้เรียนที่เข้าเรียนล่าสุด'"></page-header>
    <layout class="m-4">
      <template v-slot:header>
        <div>
          <b-row align-v="center">
            <b-col cols="12" md="3" v-if="!isTutor">
              <div class="d-flex my-2">
                <p class="mr-2 my-auto">สาขา</p>
                <b-form-select
                  class="my-auto"
                  v-model="departmentSelected"
                  :options="departmentOptions"
                  @change="departmentGroupChangedHandler"
                  :disabled="allItemsAmount == 0 || items === null"
                ></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" md="3" v-if="!isTutor">
              <div class="d-flex my-2">
                <p class="mr-2 my-auto">กลุ่มเรียน</p>
                <b-form-select
                  class="my-auto"
                  v-model="groupSelected"
                  :options="groupOptions"
                  @change="departmentGroupChangedHandler"
                  :disabled="allItemsAmount == 0 || items === null"
                ></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" md="2"
              ><b-input
                v-model="fromSearch"
                type="date"
                :max="toSearch"
                @change="departmentGroupChangedHandler"
              ></b-input
            ></b-col>
            ถึง
            <b-col cols="12" md="2"
              ><b-input
                v-model="toSearch"
                type="date"
                :min="fromSearch"
                :max="today"
                @change="departmentGroupChangedHandler"
              ></b-input
            ></b-col>
            <b-col cols="12" md="2" lg="2" style="margin-left: 0px">
              <div class="d-flex my-2">
                <search-box
                  class="w-100"
                  size="md"
                  title="พิมพ์เพื่อค้นหาผู้เรียน"
                  @typed="searchBoxChangedHandler"
                  :disabled="allItemsAmount == 0 || items === null"
                ></search-box>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div class>
          <b-table
            id="recent-study-table"
            table-class="tbNumber text-left"
            :items="items"
            :fields="checkFields()"
            small
            sort-icon-left
            responsive
            :busy="items === null"
            hover
            striped
          >
            <template v-slot:cell(memberName)="data">
              <a
                :href="
                  `/${company.url}/analytics/users/${data.item.m_id}/learning-stats`
                "
                target="_blank"
              >
                {{ data.item.memberName ? data.item.memberName : '-' | cut }}
              </a>
            </template>
            <template v-slot:cell(departmentName)="data">
              <span>{{
                data.item.departmentName ? data.item.departmentName : '-' | cut
              }}</span>
            </template>
            <template v-slot:cell(studyGroupName)="data">
              <span>{{
                data.item.studyGroupName ? data.item.studyGroupName : '-' | cut
              }}</span>
            </template>
            <template v-slot:cell(courseName)="data">
              <span>{{
                data.item.courseName ? data.item.courseName : '-' | cut
              }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <span>{{ getRecordStatus(data.item.status) }}</span>
            </template>
            <template v-slot:cell(lastLogin)="data">
              <span>{{ data.item.lastLogin | thaiDateFilter }}</span>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="allItemsAmount"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import layout from '@/components/pages/analytics/shared/Layout';
import dateMixins from '@/components/pages/analytics/shared/mixins/date.js';
import api from '@/services/api.js';
import SearchBox from '@/components/shared/SearchBox.vue';
import exp from '@/services/export.js';
import AuthMixin from '@/mixins/auth-mixin.js';
import moment from 'moment';
export default {
  components: {
    PageHeader,
    layout,
    SearchBox,
  },
  mixins: [dateMixins, AuthMixin],
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      fromSearch: moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      toSearch: moment().format('YYYY-MM-DD'),
      company: {},
      groupSelected: '*',
      groupOptions: [],
      departmentSelected: '*',
      departmentOptions: [],
      fields: [
        { key: 'memberName', label: 'รายชือผู้เรียน' },
        { key: 'studyGroupName', label: 'กลุ่มเรียน' },
        { key: 'departmentName', label: 'สาขา' },
        { key: 'courseName', label: 'คอร์สที่ลงเรียน' },
        { key: 'status', label: 'สถานะผู้เรียน' },
        { key: 'lastLogin', label: 'เข้าสู่ระบบล่าสุด' },
      ],
      tutorFields: [
        { key: 'memberName', label: 'รายชือผู้เรียน' },
        { key: 'courseName', label: 'คอร์สที่ลงเรียน' },
        { key: 'status', label: 'สถานะผู้เรียน' },
        { key: 'lastLogin', label: 'เข้าสู่ระบบล่าสุด' },
      ],
      allItemsAmount: 0,
      items: null,
      currentPage: 1,
      perPage: 20,
      searchKeyword: '',
    };
  },
  watch: {
    currentPage() {
      this.fetchSelectedRecentStudyStudents();
    },
  },
  created() {
    this.fetchAllRecentStudyStudentAmount();
  },
  mounted() {
    this.setCompanyInfo();
    this.setDepartmentOptions();
    this.setGroupOptions();
    this.fetchSelectedRecentStudyStudents();
  },
  methods: {
    async exportData() {
      let value = [];

      let queryString = `group=${this.groupSelected}&department=${
        this.departmentSelected
      }&start=${this.fromSearch}&end=${this.toSearch}&page=${1}&perpage=${
        this.allItemsAmount
      }`;
      if (this.searchKeyword.trim().length > 0)
        queryString = queryString + `&keyword=${this.searchKeyword.trim()}`;
      const res = await api.get(
        `/v1/analytics/students/learning-recent-study-stats?${queryString}`
      );
      let set = res.data;
      set.forEach((i) => {
        let temp = {
          name: i.memberName,
          id : i.memberStaffId,
          group: i.studyGroupName,
          department: i.departmentName,
          courseName: i.courseName,
          status: this.getRecordStatus(i.status),
          date: this.thaiDateFilter(i.lastLogin),
        };
        value.push(temp);
      });
      let key = [
        'ชื่อผู้เรียน',
        'รหัสผู้เรียน',
        'กลุ่มเรียน',
        'สาขา',
        'คอร์สที่ลงเรียน',
        'สถานะผู้เรียน',
        'เข้าสู่ระบบล่าสุด'
      ];
      let filename = 'User-Recent-Study-' + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },

    checkFields() {
      if (this.isTutor == true) {
        return this.tutorFields;
      } else {
        return this.fields;
      }
    },

    thaiDateFilter(value) {
      if (value) {
        const yearMonthDate = value.split(' ')[0].split('-');
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          'ม.ค.',
          'ก.พ.',
          'มี.ค.',
          'เม.ย.',
          'พ.ค.',
          'มิ.ย.',
          'ก.ค.',
          'ส.ค.',
          'ก.ย.',
          'ต.ค.',
          'พ.ย.',
          'ธ.ค.',
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return '-';
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(`/v1/companies/${this.company.id}/groups`);
        this.groupOptions = results.data.data.map((el) => {
          return { value: el.id, text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: '*',
          text: 'ทั้งหมด',
        });
      } catch (err) {
        console.log('setGroupOptions error', err);
      }
    },
    async setDepartmentOptions() {
      try {
        // Fetch the company departments from backend
        let results = await api.get(
          `/v1/companies/${this.company.id}/departments`
        );
        this.departmentOptions = results.data.map((el) => {
          // Handle null case
          if (!el) {
            return { value: 'null', text: 'ไม่มี' };
          }
          return { value: el.replace(/ /g, '!'), text: el };
        });
        // Add the total option in the first place
        this.departmentOptions.unshift({
          value: '*',
          text: 'ทั้งหมด',
        });
      } catch (err) {
        console.log('setDepartmentOptions error', err);
      }
    },
    async fetchAllRecentStudyStudentAmount() {
      this.allItemsAmount = 0;
      try {
        let queryString = `group=${this.groupSelected}&department=${this.departmentSelected}&start=${this.fromSearch}&end=${this.toSearch}&amount=true`;
        if (this.searchKeyword.trim().length > 0)
          queryString = queryString + `&keyword=${this.searchKeyword.trim()}`;
        const res = await api.get(
          `/v1/analytics/students/learning-recent-study-stats?${queryString}`
        );
        this.allItemsAmount = res.data;
      } catch (err) {
        console.log('fetchAllRecentStudyStudentAmount error!', err);
      }
    },
    async fetchSelectedRecentStudyStudents() {
      this.items = null;
      try {
        let queryString = `group=${this.groupSelected}&department=${this.departmentSelected}&start=${this.fromSearch}&end=${this.toSearch}&page=${this.currentPage}&perpage=${this.perPage}`;
        if (this.searchKeyword.trim().length > 0)
          queryString = queryString + `&keyword=${this.searchKeyword.trim()}`;
        const res = await api.get(
          `/v1/analytics/students/learning-recent-study-stats?${queryString}`
        );
        this.items = res.data;
      } catch (err) {
        console.log('fetchSelectedRecentStudyStudents error!', err);
      }
    },
    getRecordStatus(item) {
      if (!item) {
        return '-';
      } else {
        let message = '-';
        switch (String(item)) {
          case '0':
            message = '';
            break;
          case 'IN_PROGRESS':
            message = 'กำลังเรียน';
            break;
          case 'NOT_STARTED':
            message = 'ยังไม่เริ่มเรียน';
            break;
          case 'REMOVED':
            message = 'ลบข้อมูลการเรียน';
            break;
          case 'CANCEL':
            message = 'ยกเลิกการเรียน';
            break;
          case 'TIMEOUT':
            message = 'หมดเวลาเรียน';
            break;
          case 'BLOCKED':
            message = 'ระงับการเรียนแล้ว';
            break;
          case 'CANCELED':
            message = 'ยกเลิกการเรียนแล้ว';
            break;
          case 'COMPLETED':
            message = 'เรียนเสร็จแล้ว';
            break;
          case 'HOLD':
            message = 'พักการเรียนชั่วคราว';
            break;
          case 'DELETED':
            message = 'ลบข้อมูลการเรียน';
            break;
          case 'EXPIRED':
            message = 'เลยกำหนดเวลาเรียน';
            break;
        }
        return message;
      }
    },
    resetCurrentPageAndFetchData() {
      this.currentPage = 1;
      this.fetchAllRecentStudyStudentAmount();
      this.fetchSelectedRecentStudyStudents();
    },
    departmentGroupChangedHandler() {
      this.resetCurrentPageAndFetchData();
    },
    searchBoxChangedHandler(keyword = null) {
      this.searchKeyword = keyword;
      this.resetCurrentPageAndFetchData();
    },
  },
};
</script>

<style scoped></style>

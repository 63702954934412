import Cookies from "js-cookie";
import authConfig from "../../../config/auth";
import jwt_decode from "jwt-decode";

/**
 * validate is for validting user via cookie jwt token.
 * @param {Object} context - gain from vuex store
 */
export const validate = context => {
  let tokenCookie = Cookies.get(authConfig.JWT_TOKEN_COOKIE);
  let decoded = jwt_decode(tokenCookie);

  let user = decoded["user"];
  if (!user) {
    context.dispatch("validateFailed");
  }
  let accessToken = Cookies.get(authConfig.ACCESS_TOKEN_COOKIE);
  context.dispatch("validateSuccess", {
    user,
    token: tokenCookie,
    accessToken
  });
};

/**
 * validatefailed is an action for setting multiple mutation in case auth successful.
 * @param {Object} context - gain from vuex store
 * @param {Object} userMeta - consist of user data and token string
 */
export const validateSuccess = (context, { user, token, accessToken }) => {
  context.commit("setUser", user);
  context.commit("setIsAuthenticated", true);
  context.commit("setAuthToken", token);
  context.commit("setAccessToken", accessToken);
};

/**
 * validatefailed is an action for setting multiple mutation in case auth failed.
 * @param {Object} context - gain from vuex store
 */
export const validateFailed = context => {
  context.commit("setUser", null);
  context.commit("setIsAuthenticated", false);
  context.commit("setAuthToken", null);
};

<template>
  <div>
    <b-table
      id="member-data-table"
      table-class="tbNumber text-left my-2"
      class="fixed-height"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      small
      sort-icon-left
      responsive
      :busy="isLoading"
      hover
    >
      <template v-slot:cell(number)="data">
        <span>{{ data.item.number }}</span>
      </template>
      <template v-slot:cell(channel)="data">
        <span>{{
          data.item.channel === "NORMAL" ? "ปกติ" : data.item.channel
        }}</span>
      </template>
      <template v-slot:cell(province)="data">
        <span>{{ data.item.province ? data.item.province : "-" }}</span>
      </template>
      <template v-slot:cell(timestamp)="data">
        <span>{{ data.item.timestamp | thaiDateTimeFilter }}</span>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="member-data-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";
import _ from "lodash";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";

export default {
  mixins: [dateMixins],
  props: {
    apiRoute: String
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        { key: "number", label: "ลำดับ" },
        { key: "channel", label: "ช่องทาง" },
        { key: "province", label: "จังหวัด" },
        { key: "timestamp", label: "เวลา" }
      ],
      perPage: 10,
      currentPage: 1
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  mounted() {
    this.fetchMemberLoginStats();
  },
  methods: {
    async fetchMemberLoginStats() {
      this.isLoading = true;
      try {
        let results = await api.get(this.apiRoute);
        let count = 1;
        let orderedResults = _.orderBy(results.data, ["timestamp"], ["desc"]);
        this.items = orderedResults.map(el => {
          return { ...el, number: count++ };
        });
      } catch (err) {
        console.log("fetchMemberLoginStats error!", err);
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.fixed-height {
  height: 380px;
}
</style>

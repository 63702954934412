<template>
  <div class="ManageExamInfo">
    <b-modal
      v-model="showLoading"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="text-center">
        <div
          class="p-5 rounded d-flex align-items-center justify-content-center"
        >
          <div class="align-items-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              style="width: 3rem; height: 3rem;"
            ></b-spinner>
            <div class="ml-3">
              <h2 class="mt-2">กรุณารอสักครู่</h2>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <draggable
      :list="exam.sections"
      class="list-group"
      ghost-class="ghost"
      @change="updateOrderSections()"
      handle=".handle"
    >
      <div
        class="exam-each-section"
        v-for="(section, sectionIndex) in exam.sections"
        :key="sectionIndex"
      >
        <img
          src="/images/icons/icon_movable.png"
          alt="movable"
          height="20"
          class="align-middle pointer icon-move-sections handle"
        />
        <ul>
          <li class="background-sections">
            <div class="sections-header handle">
              <div class="sections-title">
                <div class="exam-sections mr-3">
                  <span> Section </span> {{ sectionIndex + 1 }}
                </div>
                <div class="exam-sections-name">
                  {{ section.title }}
                </div>
              </div>
              <div class="sections-button d-flex align-items-center">
                <b-button
                  v-if="exam.sections.length > 1"
                  class="exams-delete-button mr-3"
                  variant="danger"
                  size="sm"
                  @click="confirmRemoveSections(section.id, sectionIndex)"
                >
                  <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                </b-button>

                <p @click="toggleCollapse(section.id)" class="pointer">
                  <b-icon
                    :icon="getCollapseIcon(section.id)"
                    scale="2"
                  ></b-icon>
                </p>
              </div>
            </div>
            <b-collapse
              visible
              :id="section.id"
              v-model="collapsedSections[section.id]"
            >
              <div class="mt-2">
                <div class="sections-info">
                  <b-col class="mt-3 b-col-sectiontitle">
                    <span class="exam-sections-name" for="input-with-list"
                      >ชื่อ Section</span
                    >
                    <b-form-input
                      autocomplete="off"
                      v-model="section.title"
                      @change="updateTitleSection(section, sectionIndex)"
                      :state="titleSectionState[sectionIndex]"
                    ></b-form-input>
                  </b-col>
                </div>
                <hr class="mt-4" />
              </div>

              <QuestionInfo
                :questions="section.questions"
                :section="section"
                :sectionIndex="sectionIndex"
                :key="componentKey"
              />
              <div style="display: flex;">
                <div>
                  <span v-if="section.questions.length == 0" class="text-danger"><strong>Section นี้ยังไม่มีคำถาม</strong> กรุณากดเพิ่มคำถาม</span>
                </div>
                <div style="margin-left: auto;">
                  <b-dropdown right variant="primary" no-caret>
                    <template #button-content>
                      <i class="fa fa-plus" aria-hidden="true"></i> เพิ่มคำถาม
                    </template>
                    <div v-for="(type, index) in questionType" :key="index">
                      <b-dropdown-item-button
                        @click="
                          createQuestionInfo(section.id, sectionIndex, type.value)
                        "
                      >
                        {{ type.text }}
                      </b-dropdown-item-button>
                    </div>
                  </b-dropdown>
                </div>
              </div>
            </b-collapse>
          </li>
        </ul>
      </div>
    </draggable>
    <div class="mt-4 button-create-new">
      <b-button variant="primary" @click="addSection(exam.id)">
        <i class="fa fa-plus" aria-hidden="true"></i> เพิ่ม section
      </b-button>
    </div>
  </div>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor.css";
import { mapActions, mapGetters } from "vuex";
import draggable from "csq-vue-multi-draggable";
import QuestionInfo from "./QuestionInfo.vue";

export default {
  components: {
    draggable,
    QuestionInfo,
  },

  data() {
    return {
      collapsedSections: {},
      token: localStorage.getItem("token"),
      examId: this.$route.params.quizExamId,
      dragging: false,
      titleSectionState: [],
      questionType: [
        { text: "คำถามหลายตัวเลือก", value: "MULTIPLE_CHOICE" },
        { text: "คำถามเติมคำ", value: "FILL_BLANK" },
        { text: "คำถามตามบทความ", value: "PASSAGE" },
        { text: "คำถามที่มีคำถามย่อย", value: "BUNDLE" },
        // { text: "คำถามจับคู่", value: "MATCHING" },
      ],
      componentKey: 0,
      showLoading: false,
    };
  },

  created() {
    this.fetchExamsById();
  },

  computed: {
    ...mapGetters("ExamBuilder", [
      "exam",
      "answer",
      "tempSection",
      "tempQuestion",
      "tempChoice",
    ]),

    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },

    dragOptions() {
      return {
        animation: 100,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "findExamsbyId",
      "addCollapsedQuestions",
      "addNewSection",
      "deleteExamSection",
      "updateSectionInfo",
      "createMultipleChoiceQuestion",
      "createPassageAndBundleQuestion",
      "createChoiceForMultipleChoicesQuestion",
      "updateExamSectionOrder",
      "sectionOptionsSetting",
      "createFillBlankQuestion",
      "createNewSubQuestion",
      "createChoiceForSubQuestion",
      "setQuestionUnanswered",
      "setQuestionAnswered",
      "countSectionAndQuestion",
    ]),

    async fetchExamsById() {
      await this.findExamsbyId(this.examId);

      this.exam.sections.forEach((section) => {
        this.$set(this.collapsedSections, section.id, true);
      });
    },

    toggleCollapse(sectionId) {
      this.$set(
        this.collapsedSections,
        sectionId,
        !this.collapsedSections[sectionId]
      );
    },

    getCollapseIcon(sectionId) {
      return this.collapsedSections[sectionId] ? "chevron-up" : "chevron-down";
    },

    async addSection() {
      let request = {
        body: {
          title: "Default Section",
        },
        token: this.token,
        id: this.$route.params.quizExamId,
      };
      await this.addNewSection(request);

      this.exam.sections.push(this.tempSection);

      for (let i = 0; i < this.exam.sections.length; i++) {
        if (!this.exam.sections[i].options) {
          let request = {
            body: {
              options: {},
            },
            id: this.$route.params.quizExamId,
            token: this.token,
            sectionIndex: i,
            sectionId: this.exam.sections[i].id,
          };
          this.sectionOptionsSetting(request);
        }
      }

      this.$set(this.collapsedSections, this.tempSection.id, true);
      setTimeout(() => {
          this.countSectionAndQuestion(this.$route.params.quizExamId);
      }, 500);
    },

    confirmRemoveSections(sectionId, sectionIndex) {
      this.$bvModal
        .msgBoxConfirm(`ต้องการลบ Section ${sectionIndex + 1} ใช่หรือไม่?`, {
          title: "แจ้งเตือนการลบ",
          size: "md",
          buttonSize: "sm px-3",
          okVariant: "primary",
          okTitle: "ใช่",
          cancelTitle: "ยกเลิก",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) this.deleteSections(sectionId, sectionIndex);
        });
    },

    async deleteSections(sectionId, sectionIndex) {
      let request = {
        token: this.token,
        id: this.$route.params.quizExamId,
        sectionId: sectionId,
      };
      this.deleteExamSection(request);
      this.exam.sections.splice(sectionIndex, 1);
      setTimeout(() => {
          this.countSectionAndQuestion(this.$route.params.quizExamId);
      }, 500);
    },

    async updateTitleSection(section, sectionIndex) {
      if (section.title == "" || section.title == null) {
        this.$set(this.titleSectionState, sectionIndex, false);
        return;
      }
      this.$set(this.titleSectionState, sectionIndex, null);
      let request = {
        body: { title: section.title },
        id: this.$route.params.quizExamId,
        sectionId: section.id,
        token: this.token,
      };
      try {
        await this.updateSectionInfo(request);
      } catch (error) {
        console.log("error", error);
      }
    },

    async updateOrderSections() {
      for (let i = 0; i < this.exam.sections.length; i++) {
        this.exam.sections[i].index = i;
      }
      let request = {
        body: { sections: this.exam.sections },
        id: this.exam.id,
        token: this.token,
      };
      this.updateExamSectionOrder(request);
    },

    async createQuestionInfo(sectionId, sectionIndex, questionType) {
      const baseRequest = {
        body: {
          title: "ข้อสอบ",
          hasAnswer: false,
        },
        id: this.exam.id,
        sectionId,
        token: this.token,
        sectionIndex,
      };

      switch (questionType) {
        case "MULTIPLE_CHOICE":
          await this.generateMultipleChoiceQuestion(baseRequest);
          break;
        case "FILL_BLANK":
          await this.generateFillBlankQuestion(baseRequest);
          break;
        case "PASSAGE":
          await this.generatePassageQuestion(baseRequest);
          break;
        case "BUNDLE":
          await this.generateBundleQuestion(baseRequest);
          break;
      }
      setTimeout(() => {
          this.countSectionAndQuestion(this.$route.params.quizExamId);
      }, 500);
    },

    async generateMultipleChoiceQuestion(baseRequest) {
      const res = await this.createMultipleChoiceQuestion(baseRequest);

      const createChoiceData = (index) => ({
        body: {
          text: "คำตอบ",
          index,
        },
        sectionId: baseRequest.sectionId,
        token: baseRequest.token,
        id: baseRequest.id,
        sectionIndex: baseRequest.sectionIndex,
        questionId: res.id,
      });

      this.exam.sections[baseRequest.sectionIndex].questions.push(this.tempQuestion);
      const newQuestionIndex = this.exam.sections[baseRequest.sectionIndex].questions.findIndex(
        (question) => question.id == res.id
      );
      this.addCollapsedQuestions(this.tempQuestion.id);
      this.setQuestionUnanswered(this.tempQuestion.id);

      let choice_data = createChoiceData(0);
      await this.createChoiceForMultipleChoicesQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex]._choices.push(this.tempChoice);

      choice_data = createChoiceData(1);
      await this.createChoiceForMultipleChoicesQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex]._choices.push(this.tempChoice);
    },

    async generateFillBlankQuestion(baseRequest) {
      await this.createFillBlankQuestion(baseRequest);
      this.exam.sections[baseRequest.sectionIndex].questions.push(this.tempQuestion);
      this.addCollapsedQuestions(this.tempQuestion.id);
      this.setQuestionUnanswered(this.tempQuestion.id);
    },

    async generatePassageQuestion(baseRequest) {
      baseRequest.body.score = 0;
      const res = await this.createPassageAndBundleQuestion(
        baseRequest
      );

      this.exam.sections[baseRequest.sectionIndex].questions.push(this.tempQuestion);
      const newQuestionIndex = this.exam.sections[baseRequest.sectionIndex].questions.findIndex(
        (question) => question.id == res.id
      );
      this.addCollapsedQuestions(this.tempQuestion.id);
      this.setQuestionUnanswered(this.tempQuestion.id);

      const subQuestionRequest = {
        questionId: res.id,
        questionIndex: newQuestionIndex,
        sectionIndex: baseRequest.sectionIndex,
        body: { score: 1, title: "ข้อสอบ" },
      };

      const subQuestion = await this.createNewSubQuestion(subQuestionRequest);

      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions.push(subQuestion);
      const newSubQuestionIndex = this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions.findIndex(
        (question) => question.id == subQuestion.id
      );
      this.addCollapsedQuestions(subQuestion.id);
      this.setQuestionUnanswered(subQuestion.id);

      const createChoiceData = (index) => ({
        body: {
          text: "คำตอบ",
          index,
        },
        sectionId: baseRequest.sectionId,
        sectionIndex: baseRequest.sectionIndex,
        questionId: res.id,
        questionIndex: newQuestionIndex,
        subQuestionId: subQuestion.id,
        token: baseRequest.token,
      });

      let choice_data = createChoiceData(0);
      await this.createChoiceForSubQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);

      choice_data = createChoiceData(1);
      await this.createChoiceForSubQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);
    },

    async generateBundleQuestion(baseRequest) {
      baseRequest.body.score = 1;
      baseRequest.body.type = "BUNDLE";
      const resQuestion = await this.createPassageAndBundleQuestion(
        baseRequest
      );

      this.exam.sections[baseRequest.sectionIndex].questions.push(this.tempQuestion);
      const newQuestionIndex = this.exam.sections[baseRequest.sectionIndex].questions.findIndex(
        (question) => question.id == resQuestion.id
      );
      this.addCollapsedQuestions(this.tempQuestion.id);
      this.setQuestionUnanswered(this.tempQuestion.id);

      const subQuestionRequest = {
        questionId: resQuestion.id,
        questionIndex: newQuestionIndex,
        sectionIndex: baseRequest.sectionIndex,
        body: {
          score: 0,
          title: "ข้อสอบ",
        },
      };

      const subQuestion = await this.createNewSubQuestion(subQuestionRequest);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions.push(subQuestion);
      const newSubQuestionIndex = this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions.findIndex(
        (question) => question.id == subQuestion.id
      );
      this.addCollapsedQuestions(subQuestion.id);
      this.setQuestionUnanswered(subQuestion.id);

      const createChoiceData = (index) => ({
        body: {
          text: "คำตอบ",
          index,
        },
        sectionId: baseRequest.sectionId,
        sectionIndex: baseRequest.sectionIndex,
        questionId: resQuestion.id,
        questionIndex: newQuestionIndex,
        subQuestionId: subQuestion.id,
        token: baseRequest.token,
      });

      let choice_data = createChoiceData(0);
      await this.createChoiceForSubQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);

      choice_data = createChoiceData(1);
      await this.createChoiceForSubQuestion(choice_data);
      this.exam.sections[baseRequest.sectionIndex].questions[newQuestionIndex].subQuestions[newSubQuestionIndex]._choices.push(this.tempChoice);
    },
  },
};
</script>

<style lang="css" scoped>
h4 {
  margin-top: 50px;
}
.handle {
  cursor: move;
}
.exam-each-section {
  display: flex;
  background-color: #f0f0f0;
  list-style-type: none;
  margin-top: 20px;
  padding: 20px 25px 5px 0px;
  border: 1px solid #d3d9de;
  border-radius: 4px;
  opacity: 1;
}

.background-sections {
  width: 100%;
}

.icon-move-sections {
  margin: 7px 2px 0px 2px;
}
.sections-header {
  display: flex;
  justify-content: space-between;
}

.b-col-sectiontitle {
  padding: 0 !important;
}

.sections-title {
  display: flex;
}

.sections-title .exam-sections {
  background-color: #6fb8ef;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}
.button-create-new {
  display: flex;
  justify-content: flex-end;
}
.exam-sections-name,
.exam-section-desript {
  font-weight: 600;
  padding: 5px;
}

.markdown p {
  margin-block-end: 0 !important;
}

ul {
  padding-left: 0px;
  list-style: none;
  width: 100%;
}
</style>

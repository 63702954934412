import FaceVerificationPage from "@/components/pages/face-verification/FaceVerificationPage.vue";
import FaceVerificationPage2 from "@/components/pages/face-verification/FaceVerificationPage2.vue";

export default [
  {
    path: "faceverify/overview",
    name: "face.verify.overview",
    component: FaceVerificationPage,
  },
  {
    path: "faceverify/overview2",
    name: "face.verify.overview2",
    component: FaceVerificationPage2,
  },
];

<template>
  <div>
    <page-header title="จำนวนผู้เรียนแบ่งตามจังหวัด"></page-header>
    <div class="content-card m-5 p-5">
      <b-row>
        <b-col cols="12" md="6">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:amcharts="http://amcharts.com/ammap"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              viewBox="0 0 600 1100"
              id="map"
              class="wrapper"
              style="height: 600px; width: 100%"
            >
              <defs>
                <amcharts:ammap
                  projection="mercator"
                  leftLongitude="97.344728"
                  topLatitude="20.463430"
                  rightLongitude="105.640023"
                  bottomLatitude="5.614417"
                />

                <!-- All areas are listed in the line below. You can use this list in your script. -->
                <!--{id:"TH-10"},{id:"TH-11"},{id:"TH-12"},{id:"TH-13"},{id:"TH-14"},{id:"TH-15"},{id:"TH-16"},{id:"TH-17"},{id:"TH-18"},{id:"TH-19"},{id:"TH-20"},{id:"TH-21"},{id:"TH-22"},{id:"TH-23"},{id:"TH-24"},{id:"TH-25"},{id:"TH-26"},{id:"TH-27"},{id:"TH-30"},{id:"TH-31"},{id:"TH-32"},{id:"TH-33"},{id:"TH-34"},{id:"TH-35"},{id:"TH-36"},{id:"TH-37"},{id:"TH-38"},{id:"TH-39"},{id:"TH-40"},{id:"TH-41"},{id:"TH-42"},{id:"TH-43"},{id:"TH-44"},{id:"TH-45"},{id:"TH-46"},{id:"TH-47"},{id:"TH-48"},{id:"TH-49"},{id:"TH-50"},{id:"TH-51"},{id:"TH-52"},{id:"TH-53"},{id:"TH-54"},{id:"TH-55"},{id:"TH-56"},{id:"TH-57"},{id:"TH-58"},{id:"TH-60"},{id:"TH-61"},{id:"TH-62"},{id:"TH-63"},{id:"TH-64"},{id:"TH-65"},{id:"TH-66"},{id:"TH-67"},{id:"TH-70"},{id:"TH-71"},{id:"TH-72"},{id:"TH-73"},{id:"TH-74"},{id:"TH-75"},{id:"TH-76"},{id:"TH-77"},{id:"TH-80"},{id:"TH-81"},{id:"TH-82"},{id:"TH-83"},{id:"TH-84"},{id:"TH-85"},{id:"TH-86"},{id:"TH-90"},{id:"TH-91"},{id:"TH-92"},{id:"TH-93"},{id:"TH-94"},{id:"TH-95"},{id:"TH-96"},{id:"TH-LKSG"}-->
              </defs>
              <g>
                <div>test</div>
                <path
                  v-for="(data, i) in mapDatas"
                  :key="i"
                  :id="data.id"
                  :title="data.title"
                  :class="getProviceColorStyle(i)"
                  :d="data.d"
                >
                  <b-tooltip :target="data.id" triggers="hover">
                    {{ data.title }}
                  </b-tooltip>
                </path>
              </g>
            </svg>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-row>
            <b-col cols="6">
              <div class="text-center">
                <p class="mb-2">รายชื่อจังหวัดและจำนวนผู้เรียน</p>
              </div>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button @click="exportData()" class="btn btn-download" href=""
                ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
              >
            </b-col>

            <!-- table -->
            <b-col cols="12" class="mt-3">
              <b-table
                :fields="fields"
                :items="mapDatas"
                :current-page="currentPage"
                :per-page="perPage"
                :busy="isLoading"
                sort-icon-left
                hover
                responsive
                style="height: 550px"
              >
                <template v-slot:cell(province)="data">
                  <span>{{ data.item.title }}</span>
                </template>
                <template v-slot:cell(user)="data">
                  <span>{{ data.item.viewer }}</span>
                </template>
                <div slot="table-busy">
                  <b-spinner class="align-middle text-csq"></b-spinner>
                </div>
              </b-table>
            </b-col>
            <!-- pagination -->
            <b-col cols="12">
              <div class="d-flex justify-content-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import api from '@/services/api';
import Numeral from 'numeral';
import thailandMap from '@/components/pages/analytics/shared/thailandDataMap.js';
import exp from '@/services/export.js';
import _ from 'lodash';

export default {
  components: {
    PageHeader,
  },
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
    },
  },
  props: {
    company: Object,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'province', label: 'จังหวัด' },
        { key: 'user', label: 'จํานวน' },
      ],
      mapDatas: [],
      maximumViewer: 0,
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.mapDatas.length;
    },
  },
  created() {
    this.setMapDatas();
  },
  methods: {
    async exportData() {
      let value = [];
      let set = this.mapDatas;
      set.forEach((i) => {
        let temp = {
          province: i.title,
          count: i.viewer,
        };
        value.push(temp);
      });
      let key = ['จังหวัด', 'จำนวน'];
      let filename = 'User-Province' + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },

    async setMapDatas() {
      this.isLoading = true;
      try {
        // initialize the thailand map
        this.mapDatas = thailandMap.data;
        // fetch provinces from database
        let provinceStats = await api.get(
          '/v1/analytics/companies/province-stats'
        );
        let lastestProvinceStats = provinceStats.data.data;
        // update the viewer
        for (let mapData of this.mapDatas) {
          for (let statData of lastestProvinceStats) {
            if (mapData.title === statData.province) {
              mapData.viewer = statData.amount;
            }
          }
        }
        // find maximum viewer
        for (let mapData of this.mapDatas) {
          if (mapData.viewer > this.maximumViewer) {
            this.maximumViewer = mapData.viewer;
          }
        }
        // sort the results
        this.mapDatas = _.orderBy(
          this.mapDatas,
          ['viewer', 'title'],
          ['desc', 'asc']
        );
      } catch (err) {
        console.log('setMapDatas error!', err);
      }
      this.isLoading = false;
    },
    getProviceColorStyle(index) {
      let currentProvinceViewer = this.mapDatas[index].viewer;
      if (currentProvinceViewer >= this.maximumViewer * 0.75) {
        return 'land-extreme-active';
      } else if (currentProvinceViewer >= this.maximumViewer * 0.5) {
        return 'land-high-active';
      } else if (currentProvinceViewer >= this.maximumViewer * 0.25) {
        return 'land-medium-active';
      } else if (currentProvinceViewer >= 1) {
        return 'land-low-active';
      } else {
        return 'land';
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: block;

  margin: auto;
}
.land-active {
  fill: #e78c06;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 0.5;
}
.land-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
/*  */
.land-extreme-active {
  fill: #eb8123;
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-extreme-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-high-active {
  fill: #eb8123;
  fill-opacity: 0.75;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-high-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-medium-active {
  fill: #eb8123;
  fill-opacity: 0.5;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-medium-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-low-active {
  fill: #eb8123;
  fill-opacity: 0.25;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-low-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land {
  fill: #cccccc;
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
/*  */
.water {
  stroke-width: 0.5;
}
svg {
  transition: 200ms;
  transition-timing-function: ease-in-out;
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
}
</style>

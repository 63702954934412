<template>
  <div>
    <div class="px-3">
      <div class="d-flex">
        <img :src="user.avatarUrl" class="user-profile-image" />
        <div class="px-3 user-name d-flex align-items-center">
          <strong class="my-auto">{{ user.profiles[0].fullName }}</strong>
        </div>
      </div>
      <quill-editor
        ref="myQuillEditor"
        name="content"
        v-model="reply"
        :options="editorOption"
        class="my-3"
      />
    </div>
    <div class="px-3" align="end">
      <b-button variant="primary" @click="answerDiscussion()"
        >ตอบคำถาม</b-button
      >
    </div>
    <div class="w-100 mt-3" style="border-bottom: 0.1rem solid #D1D2D4;"></div>
  </div>
</template>
<script>
import api from "@/services/api.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import { mapActions } from "vuex";

export default {
  name: "CommentForm",
  components: {
    quillEditor,
  },
  props: ["parentId"],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: true,
        },
        placeholder: "พิมพ์คำตอบ..",
      },
      cId: this.$route.params.cId,
      isBusy: true,
      user: JSON.parse(localStorage.getItem("user")),
      mId: JSON.parse(localStorage.getItem("user")).id,
      reply: "",
      response: {},
    };
  },
  methods: {
    ...mapActions("Discussion", ["fetchDiscussions"]),
    async answerDiscussion() {
      let data = {
        message: this.reply,
        courseId: this.cId,
        parentId: this.parentId,
        memberId: this.mId,
      };
      let res = await api.post(`/v1/analytics/discuss/answerDiscussion`, data);
      this.response = res.data;
      if (this.response) {
        this.reply = "";
        this.$bvToast.toast(`ตอบคำถามเรียบร้อยแล้ว`, {
          title: `สำเร็จแล้ว`,
          variant: "success",
          solid: true,
        });
        this.$emit("commentposted");
        this.fetchDiscussions(this.cId);
      } else {
        this.$bvToast.toast(`ไม่สามารถบันทึกการตอบคำถามได้`, {
          title: `ขออภัย`,
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.text-head {
  color: #f0a502;
  text-align: center;
  font-weight: bold;
  font-family: "Sarabun", sans-serif;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
  width: 600px;
}
.expand-collapse-button:hover {
  cursor: pointer;
}
</style>

<template>
  <li class="dropdown_menu" :class="mainanalyticsListCssObj">
    <a v-b-toggle.reportCollapse @click.prevent="toggleCollapse">
      <b-icon-clipboard></b-icon-clipboard>
      <span class="link-title menu_hide ml-1">รายงาน</span>
      <span class="fa arrow menu_hide"></span>
    </a>

    <b-collapse id="reportCollapse" :visible="false" accordion="accordion">
      <main-analytics-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainAnalyticsCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import CompanySideBars from "../CompanySidebarSwitch";
import _ from "lodash";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    MainAnalyticsCollapseMenu,
  },
  mixins: [authMixin],
  data() {
    return {
      isActive: false,
      collapseList: [],
      comId: this.$store.state.Company.companyInfo.id,
    };
  },
  computed: {
    mainanalyticsListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  mounted() {
    this.checkIsTfac();
    if (this.isTutorType() || this.isAccountingType()) {
      this.collapseList.push({
        title: "รายงานสรุปการขายรายหลักสูตร",
        to: {
          name: "report.tfac.purchasesummarybycoursereport",
        },
      });
    }
  },
  methods: {
    ...CompanySideBars,
    checkAuthorizationRoles(roles = []) {
      const companyId = this.$store.state.Company.companyInfo.id;
      const userRoles = this.$store.state.Authentication.authUser.roles
        .filter((el) => el.companyId === companyId)
        .map((el) => el.roleName);
      for (let role of roles) {
        if (userRoles.includes(role)) return true;
      }
      return false;
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
    checkIsTfac() {
      if (this.comId == 485) {
        this.collapseList.push({
          title: "รายงานการรับรู้รายได้",
          to: {
            name: "report.tfac.report",
          },
        });
      }
      if (this.comId == 485 || this.comId == 519 || this.comId == 513) {
        this.collapseList.push({
          title: "รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร",
          to: {
            name: "report.tfac.enrollsummarybycoursereport",
          },
        });
        this.collapseList.push({
          title: "รายงานข้อมูลผู้เรียนรายบุคคล",
          to: {
            name: "report.tfac.individualstudentfile",
          },
        });
      }
      if (
        this.checkAuthorizationRoles([
          "tutor/admin",
          "tutor/instructor",
          "org/admin",
          "org/instructor",
        ]) &&
        this.comId == 519 || this.comId == 513
      ) {
        this.collapseList.push({
          title: "รายงานการจบคอร์สและสอบผ่าน",
          to: {
            name: "report.tfac.completedEnrollByDateReport",
          },
        });
      }
    },
  },
};
</script>

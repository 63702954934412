<template>
  <b-table
    hover
    responsive
    :show-empty="true"
    empty-text="ไม่มีรายการ Live ที่จบแล้ว"
    :items="getEndedList"
    :fields="fields"
    :sortBy.sync="sortBy"
    :sortDesc.sync="sortDesc"
    :busy.sync="isBusy"
  >
    <template v-slot:table-busy>
      <div class="text-center text-info my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>

    <!-- DateTime coloumn -->
    <template v-slot:cell(start_datetime)="data">
      {{ dateFormat(data.value) }}
      <span class="small text-muted d-block">
        {{ timeFormat(data.value) }}
      </span>
    </template>

    <template v-slot:cell(topic)="{ item }">
      <span class="font-weight-bold">
        {{ item.topic }}
      </span>
      <span class="small text-muted d-block">
        {{ item.instructor.ownerName }}
      </span>
    </template>

    <!-- <template v-slot:cell(course)="{ item }">
      <div v-if="item.courseName">
        {{ item.courseName }}
        <span class="small text-muted d-block">
          {{ item.instructor.ownerName }}
        </span>
      </div>
      <div v-else>ผู้เรียนทุกคนในระบบ</div>
    </template> -->

    <!-- Buttons column -->
    <template v-slot:cell(actions)="row">
      <b-button
        :disabled="row.item.url == null"
        size="sm"
        variant="outline-primary"
        @click="openNewTab(row.item.url)"
        onclick="this.blur();"
        class="mr-2"
      >
        ดาวน์โหลด
      </b-button>

      <b-button
        size="sm"
        variant="light"
        v-show="false"
        @click="editMeetingForm(row.item.topic)"
        onclick="this.blur();"
        class="mr-2"
      >
        จัดการคอร์ส
      </b-button>
      <b-button
        size="sm"
        variant="outline-danger"
        @click="deleteMeeting(row.item)"
        onclick="this.blur();"
        class="mr-2"
      >
        ลบ
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "EndMeetTable",
  data() {
    return {
      sortBy: "start_datetime",
      sortDesc: true,
      fields: [
        {
          key: "start_datetime",
          label: "เริ่มเวลา",
          sortable: true,
        },
        {
          key: "topic",
          label: "หัวข้อ",
          sortable: true,
        },
        // {
        //   key: "course",
        //   label: "สำหรับผู้เรียน",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "",
          sortable: false,
          tdClass: "text-right",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("LiveMeeting", ["getEndedList"]),
    isBusy: {
      get() {
        return this.getIsBusy();
      },
      set(newBool) {
        return this.setIsBusy(newBool);
      },
    },
  },
  methods: {
    ...mapActions("LiveForm", ["editMeetingForm", "setIsBusy"]),
    ...mapActions("LiveMeeting", ["submitDeleteMeeting"]),
    ...mapGetters("LiveMeeting", ["getIsBusy"]),
    openNewTab(link) {
      window.open(link, "_blank");
    },
    dateFormat(date) {
      moment.updateLocale("th", {});
      let year = parseInt(moment(date).format("YYYY"));
      year += 543;
      return moment(date).format(`DD MMM ${year}`);
    },
    timeFormat(time) {
      return moment(time).format("ddd, HH:mm");
    },
    deleteMeeting(item) {
      this.submitDeleteMeeting({ vm: this, meeting: item });
    },
  },
};
</script>

import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import Vue from "vue";
import axios from "axios";
import Cookies from "js-cookie";
import authConfig from "../../../config/auth";

const initialState = () => ({
  authToken: null,
  authUser: null,
  isAuthenticated: null,
  accessToken: null
});

const state = {
  ...initialState()
};

export default {
  namespaced: true,
  state,
  actions: {
    ...actions,
    init(context) {
      context.commit("init");
    },

    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common[authConfig.AUTH_SERVICE_TOKEN_KEY] =
          authConfig.AUTH_SERVICE_TOKEN_VALUE;
        axios({
          url: authConfig.AUTH_SERVICE_API_URL + "/login",
          data: user,
          method: "POST"
        })
          .then(resp => {
            const data = resp.data;
            const userWorkAtCompanies = data.user.profiles = data.user.profiles.filter(profile => {
              return data.user.roles.find(role => {
                return role.companyId == profile.company.id;
              });
            });
            const user = JSON.stringify(data.user);
            const companyId = userWorkAtCompanies[0].company.id;

            Cookies.set(authConfig.JWT_TOKEN_COOKIE, data.token);
            Cookies.set(authConfig.ACCESS_TOKEN_COOKIE, data.accessToken);
            Cookies.set("user", user);

            localStorage.setItem("ComId", companyId);
            localStorage.setItem("token", data.accessToken);
            localStorage.setItem("user", user);
            localStorage.setItem("loginTime", Date.now());

            commit("setUser", JSON.parse(user));

            axios.defaults.headers.common["Authorization"] = data.token;
            axios.defaults.headers.common["ComId"] = companyId;
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise(resolve => {
        localStorage.removeItem("token");
        localStorage.removeItem("ComId");
        localStorage.removeItem("user");
        localStorage.removeItem("loginTime");
        Cookies.remove(authConfig.JWT_TOKEN_COOKIE);
        Cookies.remove(authConfig.ACCESS_TOKEN_COOKIE);
        if (Cookies.remove("user")) {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          commit("setAuthToken", null);
        }
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  getters,
  mutations: {
    ...mutations,
    init(state) {
      let reset = initialState();
      for (let f in state) {
        Vue.set(state, f, reset[f]);
      }

      let user = localStorage.getItem("user") || null;
      if (user) state.authUser = JSON.parse(user);
    }
  }
};

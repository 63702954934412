<template>
  <div :class="$options.name">
    <div :class="`${$options.name}__count : ${$options.name}__elem`">
      {{ totalAnswerSheets }}
    </div>
    <div :class="`${$options.name}__text : ${$options.name}__elem`">
      ชุดข้อสอบ
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ExamOverallTotalAnswers",
  computed: {
    ...mapGetters("Company", ["examAnalyticsData", "companyInfo"]),
    totalAnswerSheets() {
      return _.size(this.examAnalyticsData);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_variables.scss";
.ExamOverallTotalAnswers {
  height: 100%;
  padding-top: 40%;
  &__elem {
    height: 75%;
    display: block;
    text-align: center;
  }
  &__text {
    color: #ffb12e;
    font-size: 1.5em;
  }
  &__count {
    font-size: 4em;
  }
}
</style>

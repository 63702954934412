<template>
  <div>
    <hr />
    <div class="exam-to-thelesson">
      <b-form-checkbox
        v-model="ramdomQustionsCheck"
        id="checkbox-5"
        class="mb-3"
        @change="updateRandomQustions()"
        >สุ่มข้อสอบใน Section
      </b-form-checkbox>

      <div
        v-for="(sectionOptions, sectionIndexOptions) in this.exam.sections"
        :key="sectionIndexOptions"
      >
        <div v-if="checkOptionsSetions(sectionOptions.options)">
          <SelectRandom :sectionIndexOptions="sectionIndexOptions" />
        </div>
      </div>

      <div class="add-random pl-4 d-inline-block">
        <p class="mb-0 button-add-random" @click="onCreateNewRandonQuestion()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          เพิ่มการสุ่ม
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SelectRandom from "./SelectRamdomQuestion.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      selectedItem: null,
      lesson: [
        { id: null, text: "กรุณาเลือกบทเรียน" },
        { id: "a", text: "ชีววิทยาม.5" },
        { id: "b", text: "ชีววิทยาม.6" },
      ],
      sectionCheck: {},
      sectionSection: [],
      examId: this.$route.params.quizExamId,
      ramdomQustionsCheck: "",
    };
  },

  components: {
    SelectRandom,
  },

  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),

    sectionSelete() {
      return this.exam.sections;
    },
  },

  mounted() {
    let sum = 0;
    for (let i = 0; i < this.exam.sections.length; i++) {
      if (this.exam.sections[i].options === undefined) {
        sum = sum + 0;
      } else if (Object.keys(this.exam.sections[i].options).length != 0) {
        sum = sum + 1;
      }
    }

    if (sum === 0) {
      this.ramdomQustionsCheck = false;
    } else {
      this.ramdomQustionsCheck = true;
    }
  },

  methods: {
    ...mapActions("ExamBuilder", ["addNewRandomQuestion"]),
    checkOptionsSetions(sectionOptions) {
      if (sectionOptions === null || sectionOptions === undefined) {
        return 0;
      } else {
        return Object.keys(sectionOptions).length;
      }
    },

    sectioncCheck: function () {
      return this.exam.sections.filter((i) => (i.questions.length = 0));
    },

    async onCreateNewRandonQuestion() {
      for (let i = 0; i < this.exam.sections.length; i++) {
        if (Object.keys(this.exam.sections[i].options).length == 0) {
          if (Object.keys(this.exam.sections[i].questions).length != 0) {
            this.exam.sections[i].options = {
              randomQuestion: {
                questionCount: Object.keys(this.exam.sections[i].questions)
                  .length,
              },
            };

            let request = {
              body: { sections: this.exam.sections },
              id: this.examId,
              token: this.token,
            };
            this.ramdomQustionsCheck = true;
            await this.addNewRandomQuestion(request);
            break;
          }
        }
      }
    },

    async updateRandomQustions() {
      if (this.ramdomQustionsCheck === true) {
        for (let i = 0; i < this.exam.sections.length; i++) {
          this.exam.sections[i].options = {};
        }

        let request = {
          body: { sections: this.exam.sections },
          id: this.examId,
          token: this.token,
        };

        await this.addNewRandomQuestion(request);
      }
    },
  },
};
</script>

<style lang="css" scope>
.input-random {
  flex: 1;
}
.exam-to-thelesson
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: green !important;
  background-color: green !important;
}

.button-add-random {
  color: #007bff;
  font-size: 14px;
  cursor: pointer;
}
</style>


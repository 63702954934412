<template>
  <div>
    <label class="h5 mt-2">ตั้งค่าต่อ 4</label>
    <b-row>
      <b-col cols="4">
        <h6>ชื่อหัวข้อ</h6>
      </b-col>
      <b-col cols="3">
        <h6>เวลา</h6>
      </b-col>
      <b-col cols="3">
        <h6>หมวดความรู้</h6>
      </b-col>
    </b-row>
    <div v-for="(pillar, pillarindex) in pillarInfo" :key="pillarindex">
      <div>
        <LevelRenew4InsuranceForm
          :pillarindex="pillarindex"
          :insuranceCoursePillarInfo="pillarInfo"
        />
      </div>
    </div>

    <b-row>
      <b-col cols="6">
        <b-button
          variant="outline-secondary"
          @click="addPillarInfo"
          :disabled="checkDisbled()"
          >เพิ่มหัวข้อใหม่</b-button
        >
      </b-col>
      <b-col cols="3" class="text-right">
        <div v-if="isLoadingInsurance">
          <b-spinner small variant="primary" label="Small Spinning"></b-spinner>
          <span class="text-primary ml-2">กำลังบันทึก...</span>
        </div>
        <div v-if="isInsuranceSucceed">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#28a745"
            class="bi bi-save"
            viewBox="0 0 16 16"
          >
            <path
              d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"
            />
          </svg>
          <span class="text-success ml-2">บันทึกการเปลี่ยนแปลงแล้ว</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <h5>
          <strong
            >รวมจำนวนเวลา
            <span class="text-primary pl-5 pl-2">{{ pillarInfoHours }} </span>
            ชั่วโมง</strong
          >
        </h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LevelRenew4InsuranceForm from "./LevelRenew4InsuranceForm";

export default {
  components: {
    LevelRenew4InsuranceForm,
  },
  data() {
    return {
      selected: "",
      courseId: this.$route.params.courseId,
      pillarInfo: [],
      insuranceId: "",
      insuranceCourseTotalHours: "",
      pillarInfoLenght:"",
      moduleLenght:"",
    };
  },

  async created() {
    this.fetchCourseModulesList({
      courseId: this.courseId,
    });
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoadingInsurance",
      "insuranceCourse",
      "isInsuranceSucceed",
      "courseModuleList",
    ]),

    pillarInfoHours() {
      if (this.pillarInfo === null || this.pillarInfo === undefined) {
        return "00.00";
      } else {
        var sumhr = 0;
        for (var i = 0; i < this.pillarInfo.length; i++) {
          sumhr += this.pillarInfo[i].hours;
        }
        var totalHours = new Date(sumhr * 1000)
          .toISOString()
          .slice(11, 16);
        return totalHours;
      }
    },
  },
  mounted() {
    this.fetchInsuranceCourse();
    this.pillarInfo = null;
    this.insuranceCourseTotalHours = this.pillarInfoHours;
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "getInsuranceCourse",
      "updateInsuranceCourse",
      "fetchCourseModulesList",
    ]),

    async fetchInsuranceCourse() {
      this.getInsuranceCourse(this.courseId).then(() => {
        if (this.insuranceCourse[0].insuranceCoursePillarInfo === null || this.insuranceCourse.insuranceCoursePillarInfo === null) {
          this.pillarInfo = [
            {
              subject: null,
              hours: null,
              pillar: null,
            },
          ];
        } else {
          this.pillarInfo = this.insuranceCourse[0].insuranceCoursePillarInfo;
        }
      });
    },

    addPillarInfo() {
      var data = {
        subject: null,
        hours: null,
        pillar: null,
      };
      this.pillarInfo.push(data);
    },

    checkDisbled() {

      if (this.pillarInfo === null || this.pillarInfo === undefined) {
        this.pillarInfoLenght = 0;
      } else {
        this.pillarInfoLenght = Object.keys(this.pillarInfo).length;
      }

      if(this.courseModuleList === null || this.courseModuleList === undefined){
        this.moduleLenght = 0;
      }else{
        this.moduleLenght = Object.keys(this.courseModuleList).length;
      }

      if(this.moduleLenght === this.pillarInfoLenght){
        return true;
      }else{
        return false;
      }
    },
  },
};
</script>

<template>
  <div>
    <PageHeader title="รายงานการเข้าเรียนรายบุคคล"></PageHeader>
    <b-container>
        <div class="py-2"></div>
        <div class="no-data" v-if="isDisplayNoData">
          ไม่พบข้อมูลรายงานการเข้าเรียน กรุณาเลือกชื่อ/อีเมลของผู้เรียน ชื่อคอร์ส หรือ ข้อมูลเดือน อีกครั้ง
        </div>
        <div class="py-3">
        <div class="inner bg-container">
          <div style="display: flex">
            <h6 style="margin-right: 150px; display: inline">
              <strong class="pre-formatted">กรุณาระบุข้อมูลที่ต้องการดาวน์โหลดรายงาน </strong>
            </h6>
          </div>
          <div class="mt-5">
            <h6 class="mb-3"><strong>ชื่อ/อีเมล ผู้เรียน</strong></h6>
            <v-select
              :options="membersData"
              v-model="selectedMember"
              placeholder="พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา..."
              :disabled="hasMembersData"
              :clearable="false"
            />
          </div>
          <div class="mt-5">
            <h6 class="mb-3"><strong>ชื่อคอร์ส</strong></h6>
            <v-select
              :options="CoursesData"
              v-model="selectedCourseEnroll"
              placeholder="พิมพ์ชื่อคอร์สที่ต้องการค้นหา..."
              :disabled="hasCoursesData"
              :clearable="false"
            />
          </div>
          <div class="mt-5">
            <h6 class="mb-3"><strong>ข้อมูลเดือน</strong></h6>
            <div class="datepickers-container">
              <datepicker
                v-model="selectedStartMonth"
                placeholder="เลือกเดือนที่เข้าเรียน"
                :buddhist="true"
                :format="format"
                :language="languages.th"
                :minimumView="'month'" :maximumView="'year'"
                :disabled-dates="state.disabledStartMonth"
                :disabled="startMonthDisabled"
                @input="handleStartMonthChange"
              />
              <label>ถึง</label>
              <datepicker
                v-model="selectedEndMonth"
                placeholder="เลือกเดือนที่เข้าเรียน"
                :buddhist="true"
                :format="format"
                :language="languages.th"
                :minimumView="'month'" :maximumView="'year'"
                :disabled-dates="state.disabledEndMonth"
                :disabled="endMonthDisabled"
                @input="handleEndMonthChange"
              />
            </div>
          </div>
          <div class="mt-5">
            <MemberEnrollAttendanceReportPDF
              :mId="mId"
              :cerId="cerId"
              :start="range.start"
              :end="range.end"
              :btnDisabled="btnDisabled"
              @no-data="handleNoData"
            ></MemberEnrollAttendanceReportPDF>
          </div>
        </div>
        <!-- /.inner -->
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import MemberEnrollAttendanceReportPDF from "../../reports/attendance-report/MemberEnrollAttendanceReportPDF";
import api from "@/services/api.js";
import vSelect from "vue-select";
import Datepicker from 'vuejs-datepicker-thai';
import * as lang from "vuejs-datepicker-thai/src/locale";

export default {
  components: { PageHeader, vSelect, MemberEnrollAttendanceReportPDF, Datepicker},
  props: {
    company: {
      type: Object,
      default: null,
    },
    user: { default: null, type: Object },
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      membersData: [],
      CoursesData: [],
      mId: null,
      cerId: null,
      selectedMember: null,
      selectedCourseEnroll: null,
      selectedMonth: null,
      selectedStartMonth: null,
      selectedEndMonth: null,
      startMonthDisabled: true,
      endMonthDisabled: true,
      format: "MMMM yyyy",
      range: {
        start: "",
        end: ""
      },
      languages: lang,
      hasMembersData: true,
      hasCoursesData: true,
      btnDisabled: true,
      isDisplayNoData: false,
      state : {
        disabledStartMonth: {
          // to: new Date(2016, 0, 5),
          from: new Date(),
        },
        disabledEndMonth: {
          to: null,
          from: new Date(),
        }
      },
      allCourse: { label: "คอร์สทั้งหมด", value: 'all' }
    };
  },
  computed: {
  },
  async created() {
    await api.get(`/v1/companies/${this.comId}/enrolled-members`).then(({ data }) => {
      this.membersData = data.map(member => ({ label: member.mp_firstName + ' ' + member.mp_lastName + ' (' + member.m_email + ') ', value: member.m_id }));
      this.hasMembersData = false;
    });
  },
  watch: {
    selectedMember(newMember) {
      if (newMember !== null) {
        api.get(`/v1/members/${newMember.value}/enrolls`).then(({ data }) => {
          this.CoursesData = data.map(course => ({ label: course.courseName, value: course.cerId }));
          this.CoursesData.unshift(this.allCourse);
          this.hasCoursesData = false;
        });
        this.mId = newMember.value;
      }
      this.enableBtnReport();
    },
    selectedCourseEnroll(newCourse) {
      if (newCourse !== null) {
        this.selectedCourseEnroll = newCourse;
        this.startMonthDisabled = false;
        this.cerId = newCourse.value;
      }
      this.enableBtnReport();
    },
  },
  mounted() {},
  methods: {
    handleStartMonthChange(selectedStartMonth) {
      if (selectedStartMonth !== null) {
        this.range.start = this.formattedSelectedDate(selectedStartMonth);
        this.state.disabledEndMonth.to = selectedStartMonth;
        this.endMonthDisabled = false;
      } else {
        this.endMonthDisabled = true;
        this.range.start = null;
      }
      this.enableBtnReport();
    },

    handleNoData() {
      this.btnDisabled = true;
      this.isDisplayNoData = true;
    },

    handleEndMonthChange(selectedEndMonth) {
      this.range.end = this.formattedSelectedDate(selectedEndMonth);
      this.enableBtnReport();
    },
    formattedSelectedDate(selectedMonth) {
      if (selectedMonth) {
        const year = selectedMonth.getFullYear();
        const month = String(selectedMonth.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
        return `${year}-${month}`;
      }
      return null;
    },
    enableBtnReport(){
      if( this.selectedMember === null || this.selectedCourseEnroll === null || this.selectedStartMonth === null || this.selectedEndMonth === null ){
        this.btnDisabled = true;
        this.isDisplayNoData = false;
      } else if ( this.selectedStartMonth > this.selectedEndMonth ) {
        this.btnDisabled = true;
        this.selectedEndMonth = null;
        this.isDisplayNoData = false;
      }
      else {
        this.btnDisabled = false;
        this.isDisplayNoData = false;
      }
    }
  },
};
</script>

<style>
.datepickers-container {
  display: flex; 
  align-items: center; 
  gap: 10px; 
}

input,
select {
  padding: 8px;
  font-size: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.no-data {
  font-weight: 600;
  color: #eb8122;
  background-color: #fbe4d0;
  border: 1px solid #f8d7b9;
  padding: 12px 15px;
  border-radius: 4px;
  margin-top: 15px;
}
</style>


<template>
  <div class="h-100">
    <b-row class="h-100" >
      <b-col lg="12">
        <h5 class="my-3">
          <strong>ข้อมูลข้อสอบ</strong>
        </h5>
        <h6>{{ result.title }}</h6>
        <h6>
          {{ result.shortDescription }}
        </h6>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "@/services/exam-api.js";
export default {
  props: ["apiRoute", "apiRoute2"],
  data() {
    return {
      result: {},
      result2: {}
    };
  },
  async mounted() {
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    let res2 = await api.get(this.apiRoute2);
    this.result2 = res2.data;
  }
};
</script>
<style scoped></style>

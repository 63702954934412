<template>
  <div>
    <PageHeader title="แก้ไข Live"></PageHeader>
    <div class="outer">
      <b-container fluid="md" class="edit-page">
        <b-form>
          <FormData />
          <component :is="selectedCom"></component>

          <hr />
          <b-button variant="primary" class="mr-1" @click="saveForm(false)" :disabled="isSubmitting"
            >บันทึก</b-button
          >
          <b-button variant="outline-secondary" :to="{ name: 'live.index' }" :disabled="isSubmitting">
            ยกเลิก
          </b-button>
          <!-- <b-button variant="outline-primary" class="ml-2" @click="saveToListPage"></b-button> -->
          <!-- <b-button
            variant="outline-primary"
            class="ml-2"
            :disabled="!isEnableSaveAsDraft"
            @click="saveForm(true)"
            >Save as a draft</b-button
          > -->
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import FormData from "./form/CreateEditMeetingFormData.vue";
import FormSettings from "./form/CreateEditMeetingFormSetting.vue";
import FormThird from "./form/CreateEditMeetingFormThirdParty.vue";
import ZoomUrl from "./form/CreateEditMeetingFormUrl.vue";
import PageHeader from "@/components/layouts/PageHeader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LiveEditPage",
  data() {
    return {
      isDisabledDraftBtn: !this.isEnableSaveAsDraft,
      selectedCom: null,
      isSubmitting: false,
    };
  },
  props: {
    company: {
      type: String,
      required: true,
    },
    liveID: {
      type: Number,
      required: true,
    },
    live: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions("LiveMeeting", ["submitEditForm"]),
    ...mapActions("LiveForm", ["resetModified"]),
    onSubmit() {
      
    },
    async saveForm(isDraft = false) {
      this.isSubmitting = true;
      await this.submitEditForm({
        vm: this,
        isDraft,
        live: this.live,
        liveID: this.liveID,
      });
      this.isSubmitting = false;
      this.$router.push({
        name: "live.index",
      });
    },
  },
  computed: {
    ...mapGetters("LiveForm", ["isModified", "isEnableSaveAsDraft"]),
  },
  components: {
    FormData,
    FormSettings,
    FormThird,
    ZoomUrl,
    PageHeader,
  },
  beforeMount() {
    if (this.live.type == "ZOOM") {
      this.selectedCom = FormSettings;
    } else if (this.live.type == "ZOOM-URL") {
      this.selectedCom = ZoomUrl;
    } else {
      this.selectedCom = FormThird;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isModified) next();
    else {
      this.$bvModal
        .msgBoxConfirm(
          "หากคุณออกจากหน้านี้ ข้อมูลที่คุณกรอกไว้จะไม่ได้บันทึก",
          {
            title: "คุณยังไม่ได้บันทึกข้อมูล",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "ออกจากหน้านี้",
            cancelVariant: "primary",
            cancelTitle: "บันทึกแบบ draft",
            hideHeaderClose: false,
          }
        )
        .then((confirm) => {
          if (confirm == null) {
            next(false);
            return;
          } else if (!confirm) {
            // Save as draft
            this.submitEditForm({
              vm: this,
              isDraft: true,
              live: this.live,
            });
          } else {
            // Leave without saving
            this.resetModified();
          }
          next();
        });
    }
  },
};
</script>

import { render, staticRenderFns } from "./TagListPanel.vue?vue&type=template&id=12b715d5&scoped=true&"
import script from "./TagListPanel.vue?vue&type=script&lang=js&"
export * from "./TagListPanel.vue?vue&type=script&lang=js&"
import style0 from "./TagListPanel.vue?vue&type=style&index=0&id=12b715d5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b715d5",
  null
  
)

export default component.exports
import { createAsyncMutation } from "@/helpers/store";
export const ASYNC_PARTICIPATION = createAsyncMutation("PARTICIPATION");
export const ASYNC_EXAM_ANALYTICS = createAsyncMutation("EXAM_ANALYTICS");
export const ASYNC_EXAM_ANSWERSHEETS = createAsyncMutation("EXAM_ANSWERSHEETS");
export const ASYNC_EXAM_STAT = createAsyncMutation("EXAM_STAT");
export const ASYNC_EXAM_INFO = createAsyncMutation("EXAM_INFO");
export const ASYNC_COMPANY_EXAMS = createAsyncMutation("COMPANY_EXAMS");
export const ASYNC_EXAM_ANSWERSHEET = createAsyncMutation("EXAM_ANSWERSHEET");
export const ASYNC_EXAM_MEMBER = createAsyncMutation("COMPANY_EXAM_MEMBER");
export const ASYNC_EXAMS_COUNT = createAsyncMutation("COMPANY_EXAMS_COUNT");
export const ASYNC_ANSWERSHEETS_COUNT = createAsyncMutation(
  "COMPANY_ANSWERSHEETS_COUNT"
);
export const ASYNC_UPLOADED_CSV = createAsyncMutation("COMPANY_UPLOADED_CSV");
export const ASYNC_COMPANY_COURSES = createAsyncMutation("COMPANY_COURSES");
export const ASYNC_COMPANY_MODULES = createAsyncMutation("COMPANY_MODULES");
export const ASYNC_COMPANY_INSTRUCTORS = createAsyncMutation(
  "COMPANY_INSTRUCTORS"
);
export const ASYNC_CREATE_EXAM_TEMPLATE = createAsyncMutation("EXAM_TEMPLATE");
export const ASYNC_CREATE_OR_APPEND_EXAM = createAsyncMutation("CREATED_EXAM");

export default {
  ASYNC_PARTICIPATION,
  ASYNC_EXAM_ANALYTICS,
  ASYNC_EXAM_ANSWERSHEETS,
  ASYNC_EXAM_STAT,
  ASYNC_EXAM_INFO,
  ASYNC_COMPANY_EXAMS,
  ASYNC_EXAM_ANSWERSHEET,
  ASYNC_EXAM_MEMBER,
  ASYNC_EXAMS_COUNT,
  ASYNC_ANSWERSHEETS_COUNT,
  ASYNC_UPLOADED_CSV,
  ASYNC_COMPANY_COURSES,
  ASYNC_COMPANY_MODULES,
  ASYNC_COMPANY_INSTRUCTORS,
  ASYNC_CREATE_EXAM_TEMPLATE,
  ASYNC_CREATE_OR_APPEND_EXAM
};

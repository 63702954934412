var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"img"},[_c('b-img',{staticClass:"profile-image-style",attrs:{"src":_vm.memberInfo
                ? _vm.memberInfo.image_path
                  ? `${_vm.baseUrl}/${_vm.memberInfo.image_path.slice(
                      1,
                      -1
                    )}?token=${_vm.token}`
                  : _vm.baseUrlImage
                : '',"rounded":"circle"}})],1),_c('div',{staticClass:"box-name ml-5"},[_c('h6',[_vm._v("ชื่อผู้เรียน")]),_c('p',[_vm._v(_vm._s(_vm.memberInfo ? _vm.memberInfo.mp_name : "-"))])])])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"box-name"},[_c('h6',[_vm._v("สถานะเรียนจบ")]),_c('p',{class:[
            _vm.memberInfo
              ? _vm.memberInfo.cer_status === 'COMPLETED'
                ? 'text-success'
                : _vm.memberInfo.cer_status === 'NOT_STARTED'
                ? 'text-danger'
                : 'text-warning'
              : '',
          ]},[_vm._v(" "+_vm._s(_vm.memberInfo ? _vm.memberInfo.cer_status == "COMPLETED" ? "เรียนจบแล้ว" : _vm.memberInfo.cer_status == "IN_PROGRESS" ? "กำลังเรียน" : _vm.memberInfo.cer_status == "NOT_STARTED" ? "ยังไม่เริ่มเรียน" : "-" : "-")+" ")])])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"box-name"},[_c('h6',[_vm._v("วันที่เรียนจบ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("thaiDateFilter")(_vm.memberInfo ? _vm.memberInfo.cer_endDate ? _vm.memberInfo.cer_endDate : "ยังเรียนไม่จบ" : ""))+" ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-form-group
    :description="description"
    label-for="courseOwner"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
  >
    <template v-slot:label>
      <span>{{ label }}</span><span class="text-danger">*</span>
      <span v-if="courseAttributeUpdated[attribute]" class="text-success">
        <i class="fa fa-check" aria-hidden="true"></i>
      </span>
    </template>

    <v-select
      id="owner"
      v-model="tempValue"
      :options="instructors"
      :reduce="(owner) => owner.ownerId"
      :clearable="false"
      label="ownerName"
      placeholder="เลือกผู้สอน"
      @input="update"
    ></v-select>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'OwnerSelector',
  components: { vSelect },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: { type: Number, default: null },
    attribute: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    company: { default: null, type: Object },
  },
  data() {
    return {
      validFeedback: '',
      invalidFeedback: '',
      temp: '',
      instructors: [],
    };
  },
  computed: {
    ...mapGetters('CourseManagement', [
      'isLoading',
      'courseInfo',
      'courseAttributeUpdated',
    ]),
    ...mapGetters('Company', ['companyInstructorsData']),
    tempValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.temp = val;
      },
    },
  },
  async created() {
    await this.fetchCompanyInstructors(this.company.id);
    this.instructors = this.companyInstructorsData.data.filter(
      (item, pos, self) =>
        self.findIndex((v) => v.ownerId === item.ownerId) === pos
    );
  },
  methods: {
    ...mapActions('CourseManagement', ['updateCourseOwner', 'getCourseInfo']),
    ...mapActions('Company', ['fetchCompanyInstructors']),
    update() {
      this.updateCourseOwner({
        courseId: this.$route.params.courseId,
        memberId: this.temp,
      }).then(() => {
        this.getCourseInfo({
          courseId: this.$route.params.courseId,
          temp: true,
          excepts: 'numberOfStudent,numberOfActiveStudent',
        });
      });
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  padding: 5px 4px;
}
.vs__search,
.vs__search:focus {
  border: 0px solid transparent;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('div',[_c('b-button',{staticClass:"btn btn-download",attrs:{"id":"export-excel"},on:{"click":function($event){return _vm.exportReport()}}},[_c('i',{staticClass:"fa fa-angle-double-down",attrs:{"aria-hidden":"true"}}),_vm._v("ดาวน์โหลดรายงาน")]),_c('b-spinner',{staticClass:"align-middle ml-3",staticStyle:{"display":"none"},attrs:{"id":"export-excel-loading"}})],1)])],1),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.individualStudentInfoReport},scopedSlots:_vm._u([(_vm.comId != 519)?{key:"cell(lec_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1 == _vm.individualStudentInfoReport.length ? "รวม" : data.item.lec_name)+" ")]}}:null,(_vm.comId == 519)?{key:"cell(ผลทดสอบ)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.lel_examResultScore ? parseInt(data.item.lel_examResultScore) + "/" + parseInt(data.item.lel_examResultFullScore) : "-")+" ")])]}}:null,{key:"cell(status)",fn:function(data){return [_c('span',{class:{
            'text-green': data.item.status === 'ผ่าน',
            'text-red': data.item.status === 'ไม่ผ่าน',
          }},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}}],null,true)}),(_vm.comId == 519)?_c('b-table',{staticClass:"mt-3",staticStyle:{"width":"100%"},attrs:{"striped":"","hover":"","fields":_vm.summaryFields,"items":_vm.individualStudentInfoReportSummary},scopedSlots:_vm._u([{key:"cell(summary)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.summary)+" ")])]}},{key:"cell(cer_startDate)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.cer_startDate)+" ")])]}},{key:"cell(cer_completeDate)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.cer_completeDate ? data.item.cer_completeDate.split(' ')[0] : "-")+" ")])]}},{key:"cell(c_duration)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.c_duration ? _vm.formatDuration(data.item.c_duration) : "-")+" ")])]}},{key:"cell(max_examResultPercent)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.max_examResultPercent ? data.item.max_examResultPercent + "%" : "-")+" ")])]}},{key:"cell(cer_status)",fn:function(data){return [_c('span',{class:{
            'text-green': data.item.cer_status === 'COMPLETED',
            'text-red': data.item.cer_status != 'COMPLETED',
          }},[_vm._v(" "+_vm._s(data.item.cer_status === 'COMPLETED' ? "ผ่าน" : "ไม่ผ่าน")+" ")])]}}],null,false,3446891074)}):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('ag-grid-vue',{staticClass:"ag-theme-alpine",staticStyle:{"width":"98%","height":"500px","display":"none"},attrs:{"columnDefs":_vm.columnDefs,"rowData":_vm.individualStudentInfo,"excelStyles":_vm.excelStyles},on:{"grid-ready":function($event){return _vm.onGridReady($event, 1)}}}),_c('ag-grid-vue',{staticClass:"ag-theme-alpine",staticStyle:{"width":"98%","height":"500px","display":"none"},attrs:{"columnDefs":_vm.columnDefs2,"rowData":_vm.individualStudentInfoReport,"excelStyles":_vm.excelStyles},on:{"grid-ready":function($event){return _vm.onGridReady($event, 2)}}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="text-xs-center body-1">
          ชื่อข้อสอบ
        </th>
        <th scope="col" class="text-xs-center body-1">
          จำนวนผู้เข้าสอบ
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="ech in displayedAnalyticsData" :key="ech._id">
        <td class="text-xs-center caption">
          {{ truncateString(ech.examTitle, maximumStringLength) }}
        </td>
        <td class="text-xs-center caption">
          {{ ech.answerSheetCount }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { truncate } from "@/helpers/string";
import _ from "lodash";
export default {
  data() {
    return {
      maximumStringLength: 35,
      limitDisplayedData: 5
    };
  },
  computed: {
    ...mapGetters("Company", ["examAnalyticsData", "companyInfo"]),
    totalAnswerSheets() {
      return _.size(this.examAnalyticsData);
    },
    displayedAnalyticsData() {
      return _.take(this.examAnalyticsData, this.limitDisplayedData);
    }
  },
  methods: {
    truncateString(str, len) {
      return truncate(str, len);
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  font-size: 0.87em;
  margin-bottom: 0%;
}
</style>

<template>
  <div>
    <PageHeader title="ภาพรวมผู้เรียน" style="margin-left: -10%"></PageHeader>

    <!-- LearningStatSummary -->
    <b-container class="widthPage">
      <div class="py-5">
        <layout class="px-4 py-2 user-overview">
          <LearningStatPanel :company="company" />
        </layout>
      </div>

      <!-- AllStudentSummary -->
      <b-row>
        <b-col class="p-4">
          <div>
            <!-- Recent study student / Most login students -->
            <b-row>
              <b-col
                cols="12"
                :lg="
                  hasRoles([
                    'org/admin',
                    'org/hr',
                    'org/audit',
                    'tutor/admin',
                    'tutor/moderator',
                  ])
                    ? 7
                    : 12
                "
              >
                <div class="pl-3 pr-3 pr-lg-0 mb-3 mb-lg-0">
                  <recent-student-study-panel></recent-student-study-panel>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="5"
                v-if="
                  hasRoles([
                    'org/admin',
                    'org/hr',
                    'org/audit',
                    'tutor/admin',
                    'tutor/moderator',
                  ])
                "
              >
                <div class="pl-3 pl-lg-0 pr-3">
                  <most-login-student-panel></most-login-student-panel>
                </div>
              </b-col>
            </b-row>

            <!-- Student seperated by province / Student who studies > 1 province -->
            <b-row
              class="p-3 map-row"
              v-if="
                hasRoles([
                  'org/admin',
                  'org/hr',
                  'org/audit',
                  'tutor/admin',
                  'tutor/moderator',
                ])
              "
            >
              <b-col class="pr-3" cols="12" lg="6">
                <province-seperated-panel></province-seperated-panel>
              </b-col>
              <b-col class="pr-3" cols="12" lg="6">
                <province-many-login-panel></province-many-login-panel>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import AnalyticsCard from '@/components/shared/AnalyticsCard.vue'
import PageHeader from "@/components/layouts/PageHeader.vue";
// import TotalStudentNumberPanel from '@/components/pages/analytics/user/overview/panel/TotalStudentNumber.vue'
// import TopTenActiveCoursePanel from "@/components/pages/analytics/user/overview/panel/TopTenActiveCoursePanel";
import MostLoginStudentPanel from "@/components/pages/analytics/user/overview/panel/MostLoginStudentPanel";
import RecentStudentStudyPanel from "@/components/pages/analytics/user/overview/panel/RecentStudentStudyPanel";
import ProvinceSeperatedPanel from "@/components/pages/analytics/user/overview/panel/ProvinceSeperatedPanel";
import ProvinceManyLoginPanel from "@/components/pages/analytics/user/overview/panel/ProvinceManyLoginPanel";
import LearningStatPanel from "@/components/pages/analytics/user/overview/panel/LearningStatPanel";
import moment from "moment";
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";

export default {
  components: {
    // AnalyticsCard,
    PageHeader,
    // TotalStudentNumberPanel,
    // TopTenActiveCoursePanel,
    MostLoginStudentPanel,
    RecentStudentStudyPanel,
    ProvinceSeperatedPanel,
    ProvinceManyLoginPanel,
    Layout,
    LearningStatPanel
  },

  props: {
    company: Object
  },
  data() {
    return {
      year: "",
      month: "",
      departmentSelected: "*",
      departmentOptions: [],
      groupSelected: "*",
      groupOptions: [],
      userRoles: []
    };
  },
  created() {
    this.setUserRole();
    this.setCurrentYearMonth();
    this.setDepartmentOptions();
    this.setGroupOptions();
  },

  methods: {
    setUserRole() {
      this.userRoles = this.$store.state.Authentication.authUser.roles
        .filter(el => el.companyId === this.company.id)
        .filter(
          el =>
            el.roleName.substring(0, 5) === "tutor" ||
            el.roleName.substring(0, 3) === "org"
        )
        .map(el => el.roleName);
    },
    setCurrentYearMonth() {
      const currentYearMonth = moment()
        .format("YYYY/MM")
        .split("/");
      this.year = currentYearMonth[0];
      this.month = currentYearMonth[1].padStart(2, "0");
    },
    async setDepartmentOptions() {
      try {
        // Fetch the company departments from backend
        let results = await api.get(
          `/v1/companies/${this.company.id}/departments`
        );
        this.departmentOptions = results.data.map(el => {
          // Handle null case
          if (!el) {
            return { value: "null", text: "ไม่มี" };
          }
          return { value: el.replace(/ /g, "!"), text: el };
        });
        // Add the total option in the first place
        this.departmentOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setDepartmentOptions error", err);
      }
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(`/v1/companies/${this.company.id}/groups`);
        this.groupOptions = results.data.data.map(el => {
          return { value: el.id + "", text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setGroupOptions error", err);
      }
    },
    newMonthYearSelected(payload) {
      let newYearMonth = payload._i.split("/");
      this.year = newYearMonth[0];
      this.month = newYearMonth[1].padStart(2, "0");
    },
    hasRoles(roles) {
      for (let role of roles) {
        if (this.userRoles.includes(role)) return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}
.h6 {
  margin-bottom: 0 !important;
}
.map-row {
  padding-top: 2rem !important;
}
.widthPage {
  max-width: 81.25rem;
}
</style>

<template>
  <header
    class="bottom-toolbar border-top fixed-bottom"
    :class="{ 'sidebar-left-hidden': !sidebarOpened }"
  >
    <div class="main-bar">
      <b-row class="p-3">
        <b-col align-self="center" class="text-right">
          <b-link :to="{ name: 'course-group.management.index' }">
            กลับไปหน้ากลุ่มคอร์ส
          </b-link>
        </b-col>
      </b-row>
    </div>
  </header>
</template>

<script>
export default {
  name: "BottomBar",
  props: {
    sidebarOpened: Boolean,
    course: {
      type: Object,
      default: null
    },
    company: { default: null, type: Object }
  }
};
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  padding-left: 250px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  z-index: 80;
}
.sidebar-left-hidden {
  padding-left: 0px !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-row',[_c('b-col',{attrs:{"cols":""}},[_c('layout',{attrs:{"height":300},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',[_vm._v("ช่องทางการชำระ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.mockPieChart)?_c('div',{style:(_vm.mockPieStyle)},[_c('h6',{style:(_vm.mockPieText)},[_vm._v("ไม่มีข้อมูล")])]):_c('div',{staticStyle:{"padding":"0.5rem"}},[_c('b-row',[_c('b-col',{staticClass:"text-left pl-0 mt-2",attrs:{"cols":"7","offset":"5"}}),_c('b-col',{attrs:{"cols":"4"}},[_c('PieChart',{attrs:{"chart-data":_vm.datacollection,"options":_vm.option,"height":200}})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('my-legend',{attrs:{"num":_vm.legendValue.length,"color":[
                    'rgb(235,129,35)',
                    'rgb(253, 236, 210)',
                    'rgb(255, 181, 34)',
                    'rgb(255, 213, 0)',
                    'rgb(255, 145, 0)',
                    'rgb(255, 216, 108)',
                    'rgb(232, 177, 0)',
                    'rgb(255, 229, 159)',
                    'rgb(235, 129, 35)',
                    'rgb(255, 234, 128)',
                    'rgb(237,237,237)',
                  ]},scopedSlots:_vm._u([{key:"1",fn:function(){return [_vm._v(_vm._s(_vm.label[0])+" "+_vm._s(_vm.legendValue[0])+" ครั้ง")]},proxy:true},{key:"2",fn:function(){return [_vm._v(_vm._s(_vm.label[1])+" "+_vm._s(_vm.legendValue[1])+" ครั้ง")]},proxy:true},{key:"3",fn:function(){return [_vm._v(_vm._s(_vm.label[2])+" "+_vm._s(_vm.legendValue[2])+" ครั้ง")]},proxy:true},{key:"4",fn:function(){return [_vm._v(_vm._s(_vm.label[3])+" "+_vm._s(_vm.legendValue[3])+" ครั้ง")]},proxy:true},{key:"5",fn:function(){return [_vm._v(_vm._s(_vm.label[4])+" "+_vm._s(_vm.legendValue[4])+" ครั้ง")]},proxy:true},{key:"6",fn:function(){return [_vm._v(_vm._s(_vm.label[5])+" "+_vm._s(_vm.legendValue[5])+" ครั้ง")]},proxy:true},{key:"7",fn:function(){return [_vm._v(_vm._s(_vm.label[6])+" "+_vm._s(_vm.legendValue[6])+" ครั้ง")]},proxy:true},{key:"8",fn:function(){return [_vm._v(_vm._s(_vm.label[7])+" "+_vm._s(_vm.legendValue[7])+" ครั้ง")]},proxy:true},{key:"9",fn:function(){return [_vm._v(_vm._s(_vm.label[8])+" "+_vm._s(_vm.legendValue[8])+" ครั้ง")]},proxy:true},{key:"10",fn:function(){return [_vm._v(_vm._s(_vm.label[9])+" "+_vm._s(_vm.legendValue[9])+" ครั้ง")]},proxy:true}])})],1)],1)],1)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="d-flex justify-content-center align-items-center">
      <exam-donut-chart
        :bestScoreAnswerSheet="bestScoreAnswerSheet"
      ></exam-donut-chart>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <p>ผลการทำข้อสอบ</p>
    </div>
    <div class="d-flex flex-row flex-wrap">
      <exam-question-block
        v-for="(answer, index) in answers"
        :key="index"
        :answer="answer"
        :answerIndex="index + 1"
        @blockClicked="handleBlockClicked"
        >{{ index + 1 }}</exam-question-block
      >
    </div>
    <!-- modal -->
    <div>
      <b-modal
        v-model="modalShow"
        size="lg"
        hide-footer
        title="ผลการทำข้อสอบ"
        centered
      >
        <div class="d-flex justify-content-end">
          <b-pagination
            v-model="numberAnswerSheet"
            :total-rows="rows"
            :per-page="1"
            first-number
            last-number
            class="custom-pagination"
          >
            <template #page="{ page }">
              <span :class="getClass(page)">
                {{ page }}
              </span>
            </template>
          </b-pagination>
        </div>
        <div v-if="currentAnswerSheet">
          <div class="d-flex ExamFigure mb-2" style="overflow-x: auto;">
            <mark-down :message="currentAnswerSheet.questionTitle"></mark-down>
          </div>
          <div v-if="currentAnswerSheet.questionType != 'BUNDLE'">
            <div v-if="currentAnswerSheet.answerKey.choiceId">
              <div
                v-for="choice in currentAnswerSheet.questionChoices"
                :key="choice.choiceId"
                class="my-2 d-flex py-2 px-3"
                :style="answerKeyHighlight(choice, currentAnswerSheet)"
              >
                <input
                  v-if="isSelectedChoice(choice, currentAnswerSheet)"
                  type="radio"
                  class="disabled-radio"
                  checked
                />
                <input v-else type="radio" class="disabled-radio" />

                <mark-down
                  class="ml-2 mr-1"
                  :message="choice.choiceText"
                ></mark-down>

                <i
                  v-if="isCorrectChoice(choice, currentAnswerSheet)"
                  class="bi bi-check-circle-fill custom-icon-correct ml-auto"
                ></i>
                <i
                  v-else-if="isSelectedChoice(choice, currentAnswerSheet)"
                  class="bi bi-x-circle-fill custom-icon-incorrect ml-auto"
                ></i>
              </div>
            </div>
            <div v-else>
              <div class="my-2 d-flex py-2 px-3">
                <mark-down :message="answerText"></mark-down>
                <i
                  v-if="currentAnswerSheet.result.correct == 1"
                  class="bi bi-check-circle-fill custom-icon-correct ml-auto"
                ></i>
                <i
                  v-else
                  class="bi bi-x-circle-fill custom-icon-incorrect ml-auto"
                ></i>
              </div>
              <div
                class="my-2 d-flex py-2 px-3"
                :style="{ 'background-color': '#b3ffb2' }"
              >
                <mark-down
                  :message="currentAnswerSheet.answerKey.answerText"
                ></mark-down>
                <i
                  class="bi bi-check-circle-fill custom-icon-correct ml-auto"
                ></i>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-for="answer in currentAnswerSheet.subQuestions"
              :key="answer.questionId"
            >
              <div class="d-flex ExamFigure mt-3" style="overflow-x: auto;">
                <mark-down :message="answer.questionTitle"></mark-down>
              </div>
              <div
                v-for="(choice, index) in answer.questionChoices"
                :key="choice.choiceId"
                class="my-2 d-flex py-2 px-3"
                :style="answerKeyHighlight(choice, answer)"
              >
                <input
                  v-if="isSelectedChoice(choice, answer)"
                  type="radio"
                  class="disabled-radio"
                  checked
                />
                <input v-else type="radio" class="disabled-radio" />

                <p class="ml-2 mr-1">{{ index + 1 }}</p>
                <mark-down :message="choice.choiceText"></mark-down>

                <i
                  v-if="isCorrectChoice(choice, answer)"
                  class="bi bi-check-circle-fill custom-icon-correct ml-auto"
                ></i>
                <i
                  v-else-if="isSelectedChoice(choice, answer)"
                  class="bi bi-x-circle-fill custom-icon-incorrect ml-auto"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ExamDonutChart from "./ExamDonutChart.vue";
import ExamQuestionBlock from "./ExamQuestionBlock.vue";
import MarkDown from "@/components/shared/Markdown.vue";

export default {
  components: {
    ExamDonutChart,
    ExamQuestionBlock,
    MarkDown
  },
  props: {
    bestScoreAnswerSheet: Object
  },
  data() {
    return {
      answers: [],
      modalShow: false,
      numberAnswerSheet: 0,
      currentAnswerSheet: null,
      correctAnswer: []
    };
  },
  computed: {
    rows() {
      return this.answers.length;
    },
    answerText() {
      return this.currentAnswerSheet.answer.answerText
        ? this.currentAnswerSheet.answer.answerText
        : "";
    }
  },
  watch: {
    bestScoreAnswerSheet() {
      this.setAnswers();
    },
    numberAnswerSheet() {
      this.currentAnswerSheet = this.answers[this.numberAnswerSheet - 1];
    }
  },
  methods: {
    setAnswers() {
      this.answers = [];
      for (let section of this.bestScoreAnswerSheet.sections) {
        for (let answer of section.answers) {
          if (answer.questionType == "PASSAGE") {
            for (let subQuestion of answer.subQuestions) {
              subQuestion.questionTitle =
                answer.questionTitle + "\n\n " + subQuestion.questionTitle;
              this.answers.push(subQuestion);
            }
          } else {
            this.answers.push(answer);
          }
        }
      }
      console.log(this.answers);
      this.mapCorrectAnswer();
    },
    handleBlockClicked(answerIndex) {
      this.numberAnswerSheet = answerIndex;
      this.modalShow = true;
    },
    answerKeyHighlight(choice, answerSheet) {
      let style = {};
      let answerKeyChoices = answerSheet.answerKey.choiceId;

      for (let answerKey of answerKeyChoices) {
        if (answerKey.choiceId === choice.choiceId) {
          style = { ...style, "background-color": "#b3ffb2" };
          break;
        }
      }
      return style;
    },
    isCorrectChoice(choice, answerSheet) {
      let answerKeyChoices = answerSheet.answerKey.choiceId;
      for (let answerKey of answerKeyChoices) {
        if (answerKey.choiceId === choice.choiceId) return true;
      }
      return false;
    },
    isSelectedChoice(choice, answerSheet) {
      let answerChoices = answerSheet.answer;

      if (answerChoices.choiceId === choice.choiceId) return true;
      return false;
    },
    getClass(page) {
      const classMap = {
        correct: "correct-pagination",
        incorrect: "incorrect-pagination",
        blank: "blank-pagination"
      };

      const answer = this.correctAnswer[page - 1] || "blank"; // Default to 'blank' if out of bounds
      return classMap[answer];
    },
    mapCorrectAnswer() {
      for (let answer of this.answers) {
        let type = answer.result;
        if (type.correct > 0) this.correctAnswer.push("correct");
        if (type.incorrect > 0) this.correctAnswer.push("incorrect");
        if (type.blank > 0) this.correctAnswer.push("blank");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~bootstrap-icons/font/bootstrap-icons.css";

.disabled-radio {
  opacity: 1;
  pointer-events: none;
  cursor: default;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
}

.custom-icon-correct {
  color: #11734b;
}

.modal-header {
  padding: 0.5rem;
}

.custom-icon-incorrect {
  color: #cc0000;
}

// correct
.correct-pagination {
  font-weight: 700;
}

.custom-pagination::v-deep > li > button:has(> .correct-pagination) {
  color: #11734b;
  background-color: #d4edbc;
  border-width: 0px;
  padding: 0.55rem 0.76rem;
}

.custom-pagination::v-deep
  > .page-item.active
  > button:has(> .correct-pagination) {
  padding: 0.38rem 0.648rem;
  border-width: 3px;
  border-color: #11734b !important;
}

// incorrect
.incorrect-pagination {
  font-weight: 700;
}

.custom-pagination::v-deep > li > button:has(> .incorrect-pagination) {
  color: #b10202;
  background-color: #ffcfc9;
  border-width: 0px;
  padding: 0.55rem 0.76rem;
}

.custom-pagination::v-deep
  > .page-item.active
  > button:has(> .incorrect-pagination) {
  padding: 0.38rem 0.648rem;
  border-width: 3px;
  border-color: #b10202 !important;
}

// blank
.blank-pagination {
  font-weight: 700;
}

.custom-pagination::v-deep > li > button:has(> .blank-pagination) {
  color: #474747;
  background-color: #969696;
  border-width: 0px;
  padding: 0.55rem 0.76rem;
}

.custom-pagination::v-deep
  > .page-item.active
  > button:has(> .blank-pagination) {
  padding: 0.38rem 0.648rem;
  border-width: 3px;
  border-color: #474747 !important;
}
</style>

<style>
.ExamFigure table {
  width: 100%;
  border-collapse: collapse;
}

.ExamFigure th,
.ExamFigure td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ExamFigure th {
  background-color: #f2f2f2;
}

.ExamFigure img {
  max-width: 100%;
}
</style>

<template>
  <div>
    <b-tabs content-class="mt-3" v-model="activeTab">
      <b-tab title="รายปี">
        <div v-if="activeTab == 0">
          <YearlyLearningStat :company="company" />
        </div>
      </b-tab>
      <b-tab title="รายเดือน">
        <div v-if="activeTab == 1">
          <MonthlyLearningStat :company="company" />
        </div>
      </b-tab>
      <b-tab title="รายวัน">
        <div v-if="activeTab == 2">
          <DailyLearningStat :company="company" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import YearlyLearningStat from "@/components/pages/analytics/user/overview/panel/YearlyLearningStat.vue";
import MonthlyLearningStat from "@/components/pages/analytics/user/overview/panel/MonthlyLearningStat.vue";
import DailyLearningStat from "@/components/pages/analytics/user/overview/panel/DailyLearningStat.vue";

export default {
  components: {
    YearlyLearningStat,
    MonthlyLearningStat,
    DailyLearningStat,
  },
  props: {
    company: Object,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {},
  methods: {},
};
</script>

<style>
.user-overview .main {
  padding-top: 0px;
}
</style>
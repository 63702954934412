<template>
  <div id="Example">
    <canvas id="chartCanvas" width="360" style="display: none"></canvas>
  </div>
</template>

<script>
import api from "@/services/api";
import attendanceAllCoursesReportMixin from "@/mixins/attendance-all-courses-report-mixin.js";
import generateAttendanceReportPdfMixin from "@/mixins/generate-attendance-report-pdf-mixin.js"; // Import Chart.js

export default {
  mixins: [attendanceAllCoursesReportMixin, generateAttendanceReportPdfMixin],
  name: "example",
  components: {},
  props: {},
  data() {
    return {
      data: {},
      comId: this.$route.params.comId,
      accessToken: this.$route.params.accessToken,
      start: this.$route.query.start_month,
      end: this.$route.query.end_month,
      courseOnlineType: "",
      chartWidth: 360,
      chartHeight: 200,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      pdfAllCourseOption: {
        orientation: "l",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true
      },
      pdfAllCourseConfig: {
        typo: {
          header: 14,
          large: 16,
          normal: 9,
          small: 7,
          chartHeader: 18
        },
        margin: {
          t: 72,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5
        },
        endNormal: 11,
        endRow: 18,
        endTable: 16,
        endRowHeader: 27,
        endLegend: 32
      }
    };
  },
  created() {
    this.generateAllCoursePdf();
  },
  watch: {},
  methods: {
    async getMember() {
      await api
        .get(
          `/v1/attendance-report-all-courses/${this.comId}/${this.accessToken}?start_month=${this.start}&end_month=${this.end}`
        )
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {});
    }
  }
};
</script>

<style></style>

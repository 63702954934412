<template>
  <ul class="nav nav-tabs card-header-tabs float-left">
    <li class="nav-item">
      <!-- <a class="nav-link active" href="#">รายบุคคล</a> -->
      <router-link :to="{ name: 'member.index' }" class="nav-link text-csq"
        >รายบุคคล</router-link
      >
    </li>
    <li v-if="IsTutorAdmin || isOrgAdmin" class="nav-item">
      <router-link :to="{ name: 'group.index' }" class="nav-link text-csq"
        >กลุ่ม</router-link
      >
    </li>
  </ul>
</template>

<style scoped>
.router-link-active {
  color: #495057 !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #fff;
}
</style>

<script>
import AuthMixin from "@/mixins/auth-mixin.js";
export default {
  mixins: [AuthMixin]
};
</script>

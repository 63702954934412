import moment from 'moment';
import api from '@/services/api.js';
const storageUrl = process.env.VUE_APP_API_BASE_STORAGE_URL;
export default {
  async exportData(payload) {
    let date = moment(new Date()).format('DDMMYYYY-Hms');
    const data = {
      key: payload.key,
      filename: payload.filename + '_' + date + '.csv',
      data: payload.data,
    };
    let res = await api.post('/v1/analytics/export-data', data);
    if (res.data) {
      window.open(
        storageUrl + '/' + payload.filename + '_' + date + '.csv',
        '_blank'
      );
    }
  },
};

<template>
  <div class="mt-5" id="verify-member">
    <b-card class="no-shadow rounded-0" header-bg-variant="white">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">ใบหน้าระหว่างเรียนในแต่ละคอร์ส</h5>
          <a
            :href="`/${company.url}/members/face-approve-search-history`"
            target="_blank"
            >ประวัติการตรวจสอบใบหน้า</a
          >
        </div>
      </template>

      <b-container class="mt-2">
        <b-row>
          <b-col cols="9">
            <div class="form-group position-relative" role="group">
              <label class="d-block"
                >ชื่อคอร์ส <span class="text-danger">*</span>
              </label>

              <b-form-input
                placeholder="พิมพ์เพื่อค้นหาคอร์ส"
                @keyup="checkSecrch"
                v-model="search"
              ></b-form-input>
              <div
                :class="[
                  search && isSearch ? 'd-block' : 'd-none',
                  'search-course',
                ]"
              >
                <p class="course-name-error" v-if="search === null ? courseList.data.length === 0 : filteredCourses.length === 0">
                    ไม่มีคอร์สที่ต้องการ
                </p>
                <p class="course-name" v-for="(item, index) in filteredCourses" :key="index" @click="selcetCourse(item.courseId, item.courseName, item.courseNameWithCode)">
                    {{ item.courseNameWithCode }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-form-group label="จำนวนที่ต้องการสุ่ม(%)" label-for="input-2">
              <b-form-select
                v-model="percent"
                :options="optionsPercent"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="วันที่เริ่มเรียน" label-for="input-2">
              <div class="d-inline-block">
                <b-input-group>
                  <b-form-input
                    placeholder="กรุณาเลือกวันที่เริ่มเรียน"
                    :value="showRange"
                    disabled
                  ></b-form-input>
                  <b-input-group-prepend class="start-leaning">
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="showCalendarStartLeaning()"
                      ><b-icon icon="calendar-range"></b-icon
                    ></b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <div
                  ref="calendar"
                  id="calendar-range"
                  style="
                    display: none;
                    float: right;
                    position: absolute;
                    will-change: transform;
                    left: 0px;
                    z-index: 100;
                  "
                >
                  <template>
                    <div
                      style="
                        padding: 10px 0 5px;
                        background: white;
                        width: 674px;
                        border-style: solid solid none solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="startDateLearning"
                        >
                        </b-form-input>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="endDateLearning"
                        >
                        </b-form-input>
                        <b-button
                          style="margin-right: 0.5rem"
                          size="sm"
                          variant="primary"
                          @click="showCalendarStartLeaning()"
                        >
                          Apply
                        </b-button>
                        <b-button
                          style="margin-right: 1rem"
                          size="sm"
                          variant="light"
                          @click="cancelCalendarStartLeaning()"
                        >
                          Cancel
                        </b-button>
                      </b-input-group>
                    </div>
                    <div
                      style="
                        background: white;
                        padding-left: 10px;
                        padding-bottom: 10px;
                        border-style: none solid solid solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <DatePicker
                        v-model="range"
                        :columns="$screens({ default: 1, sm: 2 })"
                        is-range
                      />
                    </div>
                  </template>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="วันที่หมดอายุคอร์ส" label-for="input-2">
              <div class="d-inline-block">
                <b-input-group>
                  <b-form-input
                    :style="{ background: isRangeEnd ? '#E9ECEF' : 'white' }"
                    placeholder="กรุณาเลือกวันที่หมดอายุคอร์ส"
                    :value="showRangeEnd"
                    disabled
                  ></b-form-input>
                  <b-input-group-prepend class="start-leaning">
                    <b-button
                      size="sm"
                      variant="primary"
                      :disabled="isRangeEnd"
                      @click="showCalendarEndLeaning()"
                      ><b-icon icon="calendar-range"></b-icon
                    ></b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <div
                  ref="calendar"
                  id="calendar-range-end"
                  style="
                    display: none;
                    float: right;
                    position: absolute;
                    will-change: transform;
                    left: 0px;
                    z-index: 100;
                  "
                >
                  <template>
                    <div
                      style="
                        padding: 10px 0 5px;
                        background: white;
                        width: 674px;
                        border-style: solid solid none solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="startDateLearningEnd"
                        >
                        </b-form-input>
                        <b-input-group-prepend>
                          <b-icon
                            icon="calendar"
                            class="input-group-text icon"
                          ></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                          class="inputDate"
                          size="sm"
                          style="margin-right: 1rem"
                          :value="endDateLearningEnd"
                        >
                        </b-form-input>
                        <b-button
                          style="margin-right: 0.5rem"
                          size="sm"
                          variant="primary"
                          @click="showCalendarEndLeaning()"
                        >
                          Apply
                        </b-button>
                        <b-button
                          style="margin-right: 1rem"
                          size="sm"
                          variant="light"
                          @click="cancelCalendarEndLeaning()"
                        >
                          Cancel
                        </b-button>
                      </b-input-group>
                    </div>
                    <div
                      style="
                        background: white;
                        padding-left: 10px;
                        padding-bottom: 10px;
                        border-style: none solid solid solid;
                        border-color: whitesmoke;
                        border-width: 1px;
                      "
                    >
                      <DatePicker
                        :key="isRangeEnd ? 'reset' : 'default'"
                        v-model="rangeEnd"
                        :columns="$screens({ default: 1, sm: 2 })"
                        is-range
                      />
                    </div>
                  </template>
                </div>
                <div>
                  <b-form-checkbox  
                    v-model="isRangeEnd"
                  >
                    ไม่มีวันหมดอายุคอร์ส
                  </b-form-checkbox>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="สถานะเรียนจบ" label-for="input-2">
              <b-form-select
                v-model="statusLearning"
                :options="optionsStatusLeaining"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="สถานะยืนยันผลการตรวจสอบใบหน้า"
              label-for="input-3"
            >
              <b-form-select
                v-model="statusFece"
                :options="optionsStatusFece"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="mb-3">
              <b-button
                variant="primary"
                @click="resultData()"
                :disabled="!courseId"
                >ค้นหา</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card class="no-shadow rounded-0 card-result" header-bg-variant="white">
      <b-alert id="result-0" :show="isResult"
        ><strong>ไม่มีรายการที่ค้นหาขณะนี้ </strong
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;รายการทั้งหมด 0 รายการ</b-alert
      >
      <b-container v-if="!isResult">
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center">
              <div class="result">พบ {{ rows }} รายการ</div>
              <div class="btn-save">
                <b-button
                  variant="primary"
                  size="sm"
                  :disabled="!courseIdSearch"
                  @click="modalSearchShow()"
                >
                  บันทึกการค้นหา
                </b-button>
              </div>
            </div>
            <b-table
              id="verify-mmember-course-list-table"
              striped
              hover
              :busy="isBusy"
              :fields="fields"
              :items="listResult"
            >
              <template v-slot:cell(cer_status)="data">
                <template>
                  <span
                    >{{
                      data.item.cer_status === "NOT_STARTED"
                        ? "ยังไม่เริ่มเรียน"
                        : data.item.cer_status === "IN_PROGRESS"
                        ? "กำลังเรียน"
                        : data.item.cer_status === "COMPLETED"
                        ? "เรียนจบแล้ว"
                        : "-"
                    }}
                  </span></template
                >
              </template>
              <template v-slot:cell(cer_approveFaceStatus)="data">
                <template>
                  <span
                    :class="[
                      data.item.cer_approveFaceStatus === 'NOT_APPROVED' || data.item.cer_approveFaceStatus === null
                        ? 'text-danger'
                        : data.item.cer_approveFaceStatus === 'APPROVING'
                        ? 'text-warning'
                        : data.item.cer_approveFaceStatus === 'APPROVED'
                        ? 'text-success'
                        : '',
                    ]"
                    >{{
                      data.item.cer_approveFaceStatus === "NOT_APPROVED" || data.item.cer_approveFaceStatus === null
                        ? "ยังไม่ได้ยืนยัน"
                        : data.item.cer_approveFaceStatus === "APPROVING"
                        ? "กำลังยืนยัน"
                        : data.item.cer_approveFaceStatus === "APPROVED"
                        ? "ยืนยันแล้ว"
                        : "-"
                    }}
                  </span></template
                >
              </template>
              <template v-slot:cell(option)="data">
                <div>
                  <b-button variant="primary" size="sm">
                    <a
                      class="text-light"
                      :href="`${$route.path}/face-approve/${data.item.cer_id}/courses/${courseId}`"
                      target="_blank"
                    >
                      ดูข้อมูล
                    </a>
                  </b-button>
                </div>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-row class="mt-5">
              <b-col cols="12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="verify-mmember-course-list-table"
                  align="left"
                  last-number
                ></b-pagination>
              </b-col>
              <b-col cols="12 ">
                <div class="d-flex justify-content-start align-items-cente">
                  <p class="pr-3">จำนวนแถวต่อหน้า</p>
                  <select
                    class="form-control form-control-sm border-0 bg-light"
                    v-model="selectedShow"
                    style="width: 75px"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-modal
      centered
      v-model="ModalShowSearch"
      title="บันทึกการค้นหา"
      size="lg"
      :hide-footer="true"
      ref="modal-search"
      id="modal-search"
    >
      <div v-if="isShow">
        <b-container>
          <b-row>
            <b-col cols="5 mb-3 d-flex align-items-center">
              <h6><strong>ชื่อการค้นหา</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <b-form-input
                placeholder="ตั้งชื่อบันทึกการค้นหา"
                v-model="nameSearch"
              ></b-form-input>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>ชื่อคอร์ส</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>{{ courseNameSearch }}</h6>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>จำนวนที่ต้องการสุ่ม (%)</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>{{ percentSearch }}</h6>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>วันที่เริ่มเรียน</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>
                {{
                  calendarStartSearchFrom
                    ? calendarStartSearchFrom === calendarStartSearchTo
                      ? calendarStartSearchFrom
                      : calendarStartSearchFrom + " - " + calendarStartSearchTo
                    : "-"
                }}
              </h6>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>วันหมดอายุคอร์ส</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>
                {{
                  calendarEndSearchFrom
                    ? calendarEndSearchFrom === calendarEndSearchTo
                      ? calendarEndSearchFrom
                      : calendarEndSearchFrom + " - " + calendarEndSearchTo
                    : "-"
                }}
              </h6>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>สถานะการเรียนจบ</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>
                {{
                  statusLearningSearch === "NOT_STARTED"
                    ? "ยังไม่เริ่มเรียน"
                    : statusLearningSearch === "IN_PROGRESS"
                    ? "กำลังเรียน"
                    : statusLearningSearch === "COMPLETED"
                    ? "เรียนจบแล้ว"
                    : "ทั้งหมด"
                }}
              </h6>
            </b-col>
            <b-col cols="5 mb-3">
              <h6><strong>สถานะยืนยันผลการตรวจสอบใบหน้า</strong></h6>
            </b-col>
            <b-col cols="7 mb-3">
              <h6>
                {{
                  statusFeceSearch === "NOT_APPROVED"
                    ? "ยังไม่ได้ยืนยัน"
                    : statusFeceSearch === "APPROVING"
                    ? "กำลังยืนยัน"
                    : statusFeceSearch === "APPROVED"
                    ? "ยืนยันแล้ว"
                    : "สถานะทั้งหมด"
                }}
              </h6>
            </b-col>
          </b-row>
          <div class="text-right">
            <b-button
              @click="$bvModal.hide('modal-search')"
              class="mt-4 mr-3"
              variant="outline-primary"
              >ยกเลิก</b-button
            >
            <b-button
              class="mt-4"
              variant="primary"
              :disabled="!nameSearch || isLoading"
              @click="submitFaceApproveSearch()"
              >บันทึก

              <b-spinner
                v-if="isLoading"
                small
                class="ml-2"
                label="Spinning"
              ></b-spinner
            ></b-button>
          </div>
        </b-container>
      </div>
      <div v-else>
        <b-container>
          <b-row>
            <b-col cols="12" class="text-center">
              <b-icon
                icon="check-circle"
                class="py-5"
                scale="5"
                variant="success"
              ></b-icon>
              <h4 class="mt-4">บันทึกการค้นหา {{ nameSearch }} สำเร็จ</h4>
              <b-button
                class="mt-4"
                variant="primary"
                @click="$bvModal.hide('modal-search')"
                >เสร็จสิ้น
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "@/services/api";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: { company: Object },
  components: {
    DatePicker,
  },
  data() {
    return {
      isSearch: false,
      search: null,
      courseName: null,
      courseNameWithCode: null,
      courseId: null,
      statusLearning: null,
      statusFece: null,
      percent: null,
      calendarStart: null,
      calendarEnd: null,
      optionsStatusLeaining: [
        { value: null, text: "สถานะทั้งหมด" },
        { value: "COMPLETED", text: "เรียนจบแล้ว" },
        { value: "NOT_STARTED", text: "ยังไม่เริ่มเรียน" },
        { value: "IN_PROGRESS", text: "กำลังเรียน" },
      ],
      optionsStatusFece: [
        { value: null, text: "สถานะทั้งหมด" },
        { value: "APPROVING", text: "กำลังยืนยัน" },
        { value: "NOT_APPROVED", text: "ยังไม่ได้ยืนยัน" },
        { value: "APPROVED", text: "ยืนยันแล้ว" },
      ],

      optionsPercent: [
        { value: null, text: "จำนวนที่ต้องการสุ่ม", disabled: true },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
        { value: 30, text: "30%" },
        { value: 40, text: "40%" },
        { value: 50, text: "50%" },
        { value: 60, text: "60%" },
        { value: 70, text: "70%" },
        { value: 80, text: "80%" },
        { value: 80, text: "90%" },
        { value: 100, text: "100%" },
      ],

      fetchParams: {
        company_id: this.company.id,
      },

      fields: [
        { key: "mp_name", label: "ชื่อผู้เรียน", thStyle: "width: 180px" },
        {
          key: "cer_startDate",
          label: "วันที่สามารถเริ่มเรียน",
          thStyle: "width:170px",
        },
        {
          key: "cer_endDate",
          label: "วันที่หมดอายุคอร์ส",
          thStyle: "width:160px",
        },
        { key: "cer_status", label: "สถานะการเรียน", thStyle: "width:150px" },
        {
          key: "cer_approveFaceStatus",
          label: "สถานะการยืนยันผลการตรวจสอบใบหน้า",
          thStyle: "width:170px",
        },
        { key: "option", label: "ตรวจสอบใบหน้าผู้เรียน" },
      ],
      isBusy: false,
      items: null,
      selectedShow: 10,
      currentPage: 1,
      perPage: 10,
      range: {
        start: null,
        end: null,
      },
      rangeEnd: {
        start: null,
        end: null,
      },
      isRangeEnd: false,
      isResult: false,

      courseNameSearch: null,
      courseIdSearch: null,
      statusLearningSearch: null,
      statusFeceSearch: null,
      percentSearch: null,
      calendarStartSearchFrom: null,
      calendarStartSearchTo: null,
      calendarEndSearchFrom: null,
      calendarEndSearchTo: null,
      cerIds: [],
      ModalShowSearch: false,
      nameSearch: null,
      isLoading: false,
      isShow: true,
    };
  },

  watch: {
    isRangeEnd(newVal) {
      if (newVal) {
        this.clearDateRangeEnd();
      }
    },

    selectedShow(newVal) {
      this.perPage = parseInt(newVal); // convert to integer
      this.currentPage = 1; // reset to the first page
    }
  },

  async mounted() {
    this.fetchParams.company_id = this.company.id;
    await this.fetchCourseList(this.fetchParams);
  },

  computed: {
    ...mapGetters("CourseManagement", ["courseList"]),

    startDateLearning() {
      if (this.range.start != null) {
        return moment(this.range.start).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    endDateLearning() {
      if (this.range.end != null) {
        return moment(this.range.end).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    startDateLearningEnd() {
      if (this.rangeEnd.start != null) {
        return moment(this.rangeEnd.start).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    endDateLearningEnd() {
      if (this.rangeEnd.end != null) {
        return moment(this.rangeEnd.end).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    rows() {
      if (this.items) {
        return this.items.length;
      } else {
        return 0;
      }
    },
    listResult() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.perPage;
      }

      if (this.items) {
        var showData = this.items.slice(
          firstIndex,
          parseInt(firstIndex) + parseInt(this.perPage)
        );
      }
      return showData;
    },

    showRange() {
      if (this.range.start != null && this.range.end != null) {
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return null;
      }
    },

    showRangeEnd() {
      if (this.rangeEnd.start != null && this.rangeEnd.end != null) {
        return (
          moment(this.rangeEnd.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.rangeEnd.end).format("DD/MM/YYYY")
        );
      } else {
        return null;
      }
    },
    filteredCourses() {
      if (!this.search) {
        return this.courseList.data;
      } else {
        const lowerSearch = this.search.toLowerCase();
        return this.courseList.data.filter(item => {
          if (item.courseNameWithCode !== null) {
            const lowerCourseName = item.courseNameWithCode.toLowerCase();
            return lowerCourseName.includes(lowerSearch);
          }
          return false;
        });
      }
    },
  },
  methods: {
    ...mapActions("CourseManagement", ["fetchCourseList"]),
    showCalendarStartLeaning() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },

    clearDateRangeEnd() {
      this.rangeEnd.start = null;
      this.rangeEnd.end = null;
    },

    cancelCalendarStartLeaning() {
      this.range.start = null;
      this.range.end = null;
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },

    showCalendarEndLeaning() {
      let rangeCalendar = document.getElementById("calendar-range-end");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },

    cancelCalendarEndLeaning() {
      this.rangeEnd.start = null;
      this.rangeEnd.end = null;
      let rangeCalendar = document.getElementById("calendar-range-end");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    selcetCourse(courseId, courseName, courseNameWithCode) {
      this.courseId = courseId;
      this.courseName = courseName;
      this.courseNameWithCode = courseNameWithCode;
      this.search = courseNameWithCode;
      this.isSearch = false;
    },
    checkSecrch() {
      this.isSearch = true;
    },

    async resultData() {
      this.isBusy = true;
      await api
        .get(
          `/v1/course-enroll-face-approve-status/${this.courseId}?random=${
            !this.percent ? 100 : this.percent
          }`
        )
        .then(({ data }) => {
          const calendarStartDateStart = moment(this.range.start).format(
            "YYYY-MM-DD"
          );
          const calendarStratDateEnd = moment(this.range.end).format(
            "YYYY-MM-DD"
          );
          const calendarEndDateStart = moment(this.rangeEnd.start).format(
            "YYYY-MM-DD"
          );
          const calendarEndDateEnd = moment(this.rangeEnd.end).format(
            "YYYY-MM-DD"
          );

          this.items = data
            .filter((i) =>
              this.statusLearning
                ? i.cer_status === this.statusLearning
                : i.cer_status
            )
            .filter((i) =>
              this.statusFece
                ? this.statusFece === "NOT_APPROVED" 
                  ? i.cer_approveFaceStatus === this.statusFece || i.cer_approveFaceStatus === null
                  : i.cer_approveFaceStatus === this.statusFece
                : true
            )
            .filter((i) =>
              this.range.start
                ? i.cer_startDate >= calendarStartDateStart &&
                  i.cer_startDate <= calendarStratDateEnd
                : i.cer_startDate
            )
            .filter((i) =>
              !this.isRangeEnd
                ? (this.rangeEnd.start !== null && this.rangeEnd.end !== null)
                  ? i.cer_endDate >= calendarEndDateStart && i.cer_endDate <= calendarEndDateEnd
                  : true
                : i.cer_endDate === null
            )
            .map((result) => ({
              ...result,
              cer_startDate: moment(result.cer_startDate).format("DD/MM/YYYY"),
              cer_endDate: result.cer_endDate
                ? moment(result.cer_endDate).format("DD/MM/YYYY")
                : "ไม่มีวันหมดอายุ",
            }));

          this.items.filter((i) => this.cerIds.push({ cer_id: i.cer_id }));

          this.courseIdSearch = this.courseId;
          this.courseNameSearch = this.courseNameWithCode;
          this.statusLearningSearch = this.statusLearning;
          this.statusFeceSearch = this.statusFece;
          this.percentSearch = !this.percent ? 100 : this.percent;
          this.calendarStartSearchFrom = this.range.start
            ? calendarStartDateStart
            : null;
          this.calendarStartSearchTo = this.range.end
            ? calendarStratDateEnd
            : null;
          this.calendarEndSearchFrom = this.rangeEnd.start
            ? calendarEndDateStart
            : null;
          this.calendarEndSearchTo = this.rangeEnd.end
            ? calendarEndDateEnd
            : null;
        });

      if (this.items.length === 0) {
        this.isResult = true;
      } else {
        this.isResult = false;
      }

      this.isBusy = false;
    },

    modalSearchShow() {
      (this.nameSearch = null), (this.ModalShowSearch = true);
    },

    async submitFaceApproveSearch() {
      this.isLoading = true;

      setTimeout(() => ((this.isLoading = false), (this.isShow = false)), 2000);
      let request = {
        body: {
          name: this.nameSearch,
          courseId: this.courseIdSearch,
          randomPercent: this.percentSearch,
          cerStartDateFrom: this.calendarStartSearchFrom,
          cerStartDateTo: this.calendarStartSearchTo,
          cerEndDateFrom: this.calendarEndSearchFrom,
          cerEndDateTo: this.calendarEndSearchTo,
          cerStatus: this.statusLearningSearch
            ? this.statusLearningSearch
            : "All",
          cerApproveFaceStatus: this.statusFeceSearch
            ? this.statusFeceSearch
            : "All",
          cerIds: this.cerIds,
        },
      };
      await api
        .post(`/v1/face-approve-search-history`, request.body)
        .then(() => {
          this.isLoading = false;
          this.isShow = false;
        });
    },
  },
};
</script>

<style>
#verify-member .card-result {
  border-top: 0px !important;
}

#verify-member .start-leaning button {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
#verify-member .search-course {
  position: absolute;
  background-color: white;
  z-index: 100;
  top: 67px;
  border: 1px solid #ced4da;

  width: 100%;
}

#verify-member .course-name-error {
  padding: 10px;
}
#verify-member .course-name {
  padding: 10px;
  cursor: pointer;
}

#verify-member .course-name:hover {
  background-color: #007bff;
  color: #fff;
}

::-webkit-input-placeholder {
  font-size: 13px !important;
}

#verify-member:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
#verify-member::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}

#verify-member option[value=""][disabled] {
  display: none;
}

#result-0 {
  background: #fbe4d0;
  color: #ec8a32;
  border-color: #fbe4d0;
}
</style>

<!-- HTML Part -->
<template>
  <div>
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <b-spinner v-if="isLoading" class="align-middle mr-2"></b-spinner>
        <InsuranceForm
          v-if="!isLoading"
          title="ใบอนุญาตบัตรประกันวินาศภัย"
          :id="ins.id"
          :type="ins.nlType"
          :no="ins.nlNo"
          :exp="ins.nlExp"
          :trHrs="ins.nlTrHrs"
          :card="ins.nlCard"
          :opt="nlOpt"
          :nlReducedHours="ins.nlReducedHours"
          :nlLevel="ins.nlLevel"
          :firstName="firstName"
          :lastName="lastName"
          @edit="edit($event, 'nl')"
          @getIns="getIns()"
        />
        <hr />
        <InsuranceForm
          v-if="!isLoading"
          title="ใบอนุญาตบัตรประกันชีวิต"
          :id="ins.id"
          :type="ins.lType"
          :no="ins.lNo"
          :exp="ins.lExp"
          :trHrs="ins.lTrHrs"
          :card="ins.lCard"
          :opt="lOpt"
          :lReducedHours="ins.lReducedHours"
          :lLevel="ins.lLevel"
          :firstName="firstName"
          :lastName="lastName"
          @edit="edit($event, 'l')"
          @getIns="getIns()"
        />
      </div>
    </div>
  </div>
</template>

<!-- Javascript Part -->
<script>
//Import
//--Vue Component
import InsuranceForm from "./InsuranceForm.vue";
//--Js
import api from "@/services/api.js";
//Export
export default {
  //--Vue Component
  components: { InsuranceForm },
  props: { 
    firstName: { type: String, default: null },
    lastName: { type: String, default: null },
   },
  //--Two Ways Binding Data
  data() {
    return {
      //Global Datas
      mId: this.$route.params.id,
      //Non-Life Insurance License
      isLoading: true,
      nlOpt: [
        { label: "นายหน้าประกันวินาศภัย", value: "NONLIFE_BROKER" },
        { label: "ตัวแทนประกันวินาศภัย", value: "NONLIFE_AGENT" }
      ],
      lOpt: [
        { label: "นายหน้าประกันชีวิต", value: "LIFE_BROKER" },
        { label: "ตัวแทนประกันชีวิต", value: "LIFE_AGENT" }
      ],
      ins: {
        id: null,
        lCard: null,
        lExp: null,
        lNo: null,
        lTrHrs: null,
        lType: null,
        nlCard: null,
        nlExp: null,
        nlNo: null,
        nlTrHrs: null,
        nlType: null,
        lReducedHours: null,
        nlReducedHours: null,
        lLevel: null,
        nlLevel: null,
      }
    };
  },
  mounted() {
    this.getIns();
  },
  methods: {
    async getIns() {
      await this.setNull();
      this.isLoading = true;
      await api.get(`v1/insurance/${this.mId}`).then(({ data }) => {
        this.ins = data ? data : this.ins;
        this.isLoading = false;
      });
    },
    edit(input, type) {
      eval(`this.ins.${type}${input.name} = '${input.value}'`);
      this.update(this.ins);
    },
    async update(insuranceData) {
      await api
        .post(`/v1/insurance/${this.mId}/update`, {
          ins: insuranceData
        })
        .then(() => {
          this.getIns();
        });
    },
    setNull() {
      this.ins = {
        id: null,
        lCard: null,
        lExp: null,
        lNo: null,
        lTrHrs: null,
        lType: null,
        nlCard: null,
        nlExp: null,
        nlNo: null,
        nlTrHrs: null,
        nlType: null,
        lReducedHours: null,
        nlReducedHours: null,
        lLevel: null,
        nlLevel: null,
      }
    }
  }
};
</script>


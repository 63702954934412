<template>
  <b-container fluid="xl" class="pt-5">
    <b-row class="d-flex">
      <b-col class="company-selection d-flex">
        <span class="my-auto">Publisher</span>
      </b-col>
      <b-col cols="8" sm="6" md="5" lg="4" xl="3" class="d-flex">
        <b-form-select
          class="my-auto"
          v-model="companySelected"
          :options="options"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    options: Array,
  },
  data() {
    return {
      companySelected: "*",
    };
  },
  watch: {
    companySelected() {
      return this.$emit("selected", this.companySelected);
    },
  },
};
</script>

<style scoped>
.company-selection {
  min-width: 130px;
  width: 130px;
  max-width: 130px;
}
</style>

<template>
  <div>
    <b-row>
      <!-- 1 -->

      <b-col style="padding-left:2rem">
        <div id="ref" ref="test">
          <layout :height="275">
            <template v-slot:header>
              <h6>ข้อมูลคอร์ส</h6>
            </template>
            <template v-slot:body>
              <b-img
                :src="result && result.c_thumbnailPath"
                fluid
                alt="Fluid image"
                class="thumbnail mb-3"
              ></b-img>
              <star-rating
                :rating="result && result.course_review_rating"
                style="margin:5px"
              ></star-rating>

              <p style="margin:0;">
                <strong>{{ result && result.c_name }}</strong>
              </p>
              <p style="margin:0;color:rgb(172,172,172)">
                {{ result && result.mp_firstName + " " + result.mp_lastName }}
              </p>
            </template>
          </layout>
        </div>
      </b-col>
      <!-- 2 -->
      <b-col cols="5">
        <div class="box">
          <p>เวลาเรียนเฉลี่ยของคนที่เรียนจบคอร์ส</p>
          <div class="price-box">
            {{
              result2.learning_time
                ? result2.learning_time + " ชั่วโมง"
                : "ยังไม่มีการเรียน"
            }}
          </div>

          <div class="info-box">
            <p>จากเวลาของคอร์ส {{ result2.c_duration ? result2.c_duration : 'N/A' }} ชั่วโมง</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import layout from "@/components/pages/analytics/shared/Layout";
import starRating from "./CourseInfoStarRating";
import api from "@/services/api.js";

export default {
  components: {
    layout,
    starRating,
  },
  props: ["apiRoute", "apiRoute2"],
  data() {
    return {
      result: {},
      result2: {},
      company: {},
    };
  },
  computed: {},
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;

    let res2 = await api.get(this.apiRoute2);
    this.result2 = res2.data;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },
};
</script>
<style scoped>
.box {
  font-family: "Mitr", sans-serif !important;
  display: flex;
  background-color: rgb(235, 129, 35);
  padding: 1rem;
  color: #fff;
  flex-direction: column;
  height: 100%;
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
}
.thumbnail {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 10rem;
  width: initial;
}
.price-box {
  margin: auto;
  color: white;
}
.price-box p {
  display: inline;
  font-size: 4rem !important;
}
.info-box {
  align-self: flex-end;
  margin: auto;
  color: #fff;
}
</style>

<template>
  <Layout style="width: 100%">
    <div v-if="!isLoading">
      <b-row>
        <b-col md="4">
          <Layout style="text-align: center">
            <p>จำนวนผู้เข้าเรียน</p>
            <h2>{{ data.learningUser }}</h2>
            <p style="opacity: 0.5">คน</p>
          </Layout>
        </b-col>
        <b-col md="4">
          <Layout style="text-align: center">
            <p>จำนวนชั่วโมงเรียนรวม</p>
            <h2>{{ data.learningTime }}</h2>
            <p style="opacity: 0.5">ชั่วโมง</p>
          </Layout>
        </b-col>
        <b-col md="4">
          <Layout style="text-align: center">
            <p>เวลาที่มีคนเรียนมากที่สุด</p>
            <h2>{{ data.hourRange }}</h2>
            <p style="opacity: 0.5">นาฬิกา</p>
          </Layout>
        </b-col>
      </b-row>
    </div>
    <div v-else style="text-align: center">
      <b-spinner class="align-middle mr-2"></b-spinner>
      <strong>กำลังโหลดข้อมูล...</strong>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";
export default {
  components: {
    Layout
  },
  props: {
    company: Object,
    begin: String,
    end: String,
    dep: String,
    group: String,
    course: Number,
    statType: String
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      branch: "",
      gId: "",
      cId: "",
      data: {},
      isLoading: true
    };
  },
  created() {
    this.getDailyLearningSummaryStat();
  },
  methods: {
    async getDailyLearningSummaryStat() {
      this.startDate = this.begin;
      this.endDate = this.end;
      if (this.dep != "*") {
        this.branch = this.dep;
      }
      if (this.group != "*") {
        this.gId = this.group;
      }
      if (this.course) {
        this.cId = this.course;
      }
      let route = `/v1/learning-summary-stat/${this.startDate}/${this.endDate}?dep=${this.branch}&gId=${this.gId}&cId=${this.cId}`;
      const res = await api.get(route);
      this.data = res.data;
      this.isLoading = false;
    }
  }
};
</script>
<template>
  <div>
    <!-- Step 1 -->
    <b-row v-if='step === 1'>
      <b-col cols='12' class='mb-3'>
        <div class='mb-2 font-weight-bold'>
          <span>เลือกคอร์สที่ต้องการ</span>
        </div>
        <div class='w-75'>
          <b-form-select 
            v-model="selectedCourseId" 
            :options="courseOptions"
          ></b-form-select>
        </div>
        <div class='mt-2 text-danger' :style="{fontSize: '0.8rem'}">
          <span>คอร์สที่ "กำลังเผยแพร่" เท่านั้นที่สามารถสร้างลิงค์ได้</span>
        </div>
      </b-col>
      <b-col cols='12' class='mb-3'>
        <img 
          v-if='selectedCourseId' 
          :src='getImageURL' 
          alt='Course Thumbnail'
          :style="{width: '100%'}"
        />
      </b-col>
      <b-col cols='12' class='mb-3' v-if='authorizationRoles(["org/admin"])'>
        <div class='mb-2 font-weight-bold'>
          <span>เลือก Publisher</span>
        </div>
        <div class='w-75'>
          <b-form-select 
            v-model="selectedPublisherId" 
            :options="publisherOptions"
          ></b-form-select>
        </div>
        <div v-if='publisherOptions.length <= 0' class='mt-2 text-danger' :style="{fontSize: '0.8rem'}">
          <span>** ไม่มีตัวเลือก กรุณาติดต่อแอดมิน **</span>
        </div>
      </b-col>
      <b-col cols='12' class='mb-3'>
        <div class='mb-2 font-weight-bold'>
          <span>ตั้งชื่อลิงค์</span>
        </div>
        <div class='w-75'>
          <b-form-input 
            v-model="linkName" 
            placeholder="Enter your link name"
          ></b-form-input>
        </div>
      </b-col>
      <b-col cols='12'>
        <div class='w-100'>
          <b-button 
            variant='primary' 
            class='w-100 py-2' 
            @click='continueButtonClicked()'
            :disabled='disableContinueButton'
          >ดำเนินการต่อ</b-button>
        </div>
      </b-col>
    </b-row>
    <!-- Step 2 -->
    <b-row v-else>
      <b-col cols='12' class='mb-3'>
        <div class='mb-2 font-weight-bold'>
          <span>คอร์ส</span>
        </div>
        <div>
          <span>{{ createdAffiliateLink ? 
            courseOptions.find(el => el.value === createdAffiliateLink.courseId).text : null
          }}</span>
        </div>
      </b-col>
      <b-col cols='12' class='mb-3'>
        <img 
          v-if='selectedCourseId' 
          :src='getImageURL' 
          alt='Course Thumbnail'
          :style="{width: '100%'}"
        />
      </b-col>
      <b-col cols='12' class='mb-3'>
        <div class='mb-2 font-weight-bold'>
          <span>Publisher</span>
        </div>
        <div>
          <span>{{ createdAffiliateLink ? 
            publisherOptions.find(el => el.value === createdAffiliateLink.publisherId).text : null
          }}</span>
        </div>
      </b-col>
      <b-col cols='12' class='mb-3'>
        <div class='mb-2 font-weight-bold'>
          <span>ชื่อลิงค์</span>
        </div>
        <div>
          <span>{{ createdAffiliateLink ? createdAffiliateLink.linkName : '-' }} </span>
        </div>
      </b-col>
      <b-col cols='12' class='mb-3'>
        <b-input-group>
          <b-form-input disabled :value='linkUrl | shortenLink'></b-form-input>
          <template #append>
            <b-input-group-text 
              class='copy-icon' 
              @click='copyButtonClicked()' 
            >
              <b-icon icon='stickies'></b-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </b-col>
      <!-- For copy the link into the clipboard -->
      <b-col>
        <b-row>
          <b-col>
            <input type="hidden" id="add-modal-copied-linkurl" :value="linkUrl">
          </b-col>
        </b-row>
      </b-col>
      <!--  -->
      <b-col cols='12'>
        <div class='w-100'>
          <b-button 
            variant='primary' 
            class='w-100 py-2' 
            @click='closeButtonClicked()'
          >ปิดหน้าต่าง</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import service from '@/services/api.js'
import ToastMixin from '@/mixins/toast-mixin.js'
import AffiliateLinkMixin from '@/components/pages/affiliate/link/mixins/affiliate-link.js'

export default {
  mixins: [ToastMixin, AffiliateLinkMixin],
  props: {
    courseOptions: Array,
    publisherOptions: Array
  },
  data() {
    return {
      step: 1,
      selectedCourseId: null,
      selectedPublisherId: null,
      linkName: '',
      createdAffiliateLink: null,
      linkUrl: ''
    }
  },
  computed: {
    getImageURL() {
      let imgURL = `https://s1-dev.coursesquare.com/data/courses/default/course_default.jpg`
      if (this.selectedCourseId) {
        let courseSelected = this.courseOptions.find(el => el.courseId == this.selectedCourseId)
        imgURL = courseSelected.courseThumbnailPath
      }
      return imgURL
    },
    disableContinueButton() {
      let courseIdNotNull = !!this.selectedCourseId
      let publisherIdNotNull = this.authorizationRoles(["org/admin"]) ? !!this.selectedPublisherId : true
      let hasLinkName = this.linkName.trim().length > 0
      return !(courseIdNotNull && publisherIdNotNull && hasLinkName)
    }
  },
  methods: {
    copyButtonClicked() {
      let testingCodeToCopy = document.querySelector('#add-modal-copied-linkurl')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      document.execCommand('copy');
      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
      // Show some notification
      this.$bvToast.toast(`Copy to clipboard !`, {
        title: 'Success',
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 1000,
        appendToast: true,
        noHoverPause: true,
        noCloseButton: true
      })
    },
    closeButtonClicked() {
      this.$emit('close', null)
    },
    async continueButtonClicked() {
      let newAffiliateLinkObj = {
        courseId: this.selectedCourseId,
        publisherId: this.authorizationRoles(["org/admin"]) ? this.selectedPublisherId : this.$store.state.Authentication.authUser.id,
        linkName: this.linkName
      }
      try {
        let res = await service.post('/v1/affiliate/links', newAffiliateLinkObj)
        this.createdAffiliateLink = {...res.data}
        this.linkUrl = this.createdAffiliateLink.linkUrl
        this.$emit('created')
        this.displayToast({
          variant: 'success',
          title: 'Success',
          content: 'Create new affiliate link successfully'
        })
        this.step = 2
      } catch (err) {
        console.log('Create new affiliate link fail.')
        console.log(err.response)
        this.displayToast({
          variant: 'danger',
          title: 'Fail',
          content: 'Create new affiliate link failed'
        })
      }
    }
  }
}
</script>

<style scoped>
.copy-icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
</style>
<template>
  <div>
    <p class="font-weight-bolder mb-2">
      ใส่คำตอบ [ระบบจะทำการเช็คคำตอบที่ถูกต้องให้]
    </p>
    <p v-if="!textareaValue" class="text-danger">
      ยังไม่กำหนดคำตอบของคำถาม
    </p>
    <b-form-textarea
      v-model="textareaValue"
      @change="handleAnswerFillBlank($event)"
    >
    </b-form-textarea>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  namespaced: true,
  props: ["questionId", "questionIndex", "sectionIndex", "isSubQuestion", "mainQuestionId"],
  data() {
    return {
      token: localStorage.getItem("token"),
      textareaValue: "",
      answer: []
    };
  },
  mounted() {},

  computed: {
    ...mapGetters("ExamBuilder", ["exam", "allAnswer", "hasAnswers"]) ,
  },

  created() {
    this.initTextareaValue(this.questionId);
  },

  methods: {
    ...mapActions("ExamBuilder", [
      "fetchQuestionAnswer",
      "updateQuestionAnswer",
      "createQuestionAnswer",
      "deleteQuestionAnswer",
      "updateSubQuestionInfo",
      "createSubQuestionAnswer",
      "updateSubQuestionAnswer",
      "deleteSubQuestionAnswer",
      "updateQuestionFillBlankInfo",
      "setQuestionAnswered",
      "setQuestionUnanswered",
      "setSolution"
    ]),

    async initTextareaValue(questionId) {
      if (this.hasAnswers[questionId]) {
        this.answer = this.allAnswer.find(item => item.questionId === questionId)
        this.textareaValue = this.answer.answerText;
        this.setSolution(questionId, this.answer.solution)
      } else {
        this.setSolution(questionId, "")
      }
    },

    async handleAnswerFillBlank(textFormEditor){
      if (this.isSubQuestion) {
        await this.generateAnswerFillBlankSubQuestion(textFormEditor);
      } else {
        await this.generateAnswerFillBlankQuestion(textFormEditor);
      }
    },

    async generateAnswerFillBlankQuestion(textFormEditor) {
      if (textFormEditor == "" || textFormEditor == null) {
        this.textareaValue = "";
        this.deleteAnswerFillBlankQuestion();
        return;
      }

      if ( this.hasAnswers[this.questionId] ) {
        this.updateAnswerFillBlankQuestion(textFormEditor);
      } else {
        this.createAnswerFillBlankQuestion(textFormEditor);
      }
    },

    updateAnswerFillBlankQuestion(textFormEditor) {
      let requestAnswer = {
        body: {
          questionFillBlankId: textFormEditor.trim(),
          answerText: textFormEditor.trim(),
        },
        questionId: this.questionId,
        token: this.token,
      };
      this.updateQuestionAnswer(requestAnswer);
    },

    createAnswerFillBlankQuestion(textFormEditor) {
      let requestFillBlankUpdate = {
        body: {
          questionFillBlankId: textFormEditor.trim(),
          answerText: textFormEditor.trim(),
        },
        questionId: this.questionId,
        token: this.token,
        sectionIndex: this.sectionIndex,
      };
      this.createQuestionAnswer(requestFillBlankUpdate);

      let request = {
        body: {
          type: "FILL_BLANK",
          hasAnswer: true,
        },
        questionId: this.questionId,
        token: this.token,
        sectionIndex: this.sectionIndex,
        examId: this.exam.id,
      };
      this.updateQuestionFillBlankInfo(request);
      this.setQuestionAnswered(this.questionId)
    },

    deleteAnswerFillBlankQuestion() {
      let requestFillBlankUpdate = {
        questionId: this.questionId,
        token: this.token,
      };
      this.deleteQuestionAnswer(requestFillBlankUpdate)
      let infoRequest = {
        body: {
          type: "FILL_BLANK",
          hasAnswer: false,
        },
        questionId: this.questionId,
        token: this.token,
      };

      this.updateQuestionFillBlankInfo(infoRequest);
      this.setQuestionUnanswered(this.questionId)
    },

    async generateAnswerFillBlankSubQuestion(textFormEditor) {
      if (textFormEditor == "" || textFormEditor == null) {
        this.textareaValue = "";
        this.deleteAnswerFillBlank();
        return;
      }

      if ( this.hasAnswers[this.questionId] ) {
        this.updateAnswerFillBlankSubQuestion(textFormEditor);
      } else {
        this.createAnswerFillBlankSubQuestion(textFormEditor);
      }
    },

    updateAnswerFillBlankSubQuestion(textFormEditor) {
      let requestAnswer = {
        body: {
          answerText: textFormEditor.trim(),
        },
        questionId: this.mainQuestionId,
        subQuestionId: this.questionId,
        token: this.token,
      };
      this.updateSubQuestionAnswer(requestAnswer);
    },

    createAnswerFillBlankSubQuestion(textFormEditor) {
      let requestFillBlankUpdate = {
        body: {
          answerText: textFormEditor.trim(),
        },
        questionId: this.mainQuestionId,
        subQuestionId: this.questionId,
        token: this.token,
      };
      this.createSubQuestionAnswer(requestFillBlankUpdate);

      const requestInfo = {
        body: {
          hasAnswer: true,
        },
        token: this.token,
        questionId: this.mainQuestionId,
        subQuestionId: this.questionId
      };
      this.updateSubQuestionInfo(requestInfo);
      this.setQuestionAnswered(this.questionId)
    },

    deleteAnswerFillBlankSubQuestion() {
      let requestFillBlankUpdate = {
        questionId: this.mainQuestionId,
        subQuestionId: this.questionId,
        token: this.token,
      };
      this.deleteSubQuestionAnswer(requestFillBlankUpdate)
      
      const requestInfo = {
        body: {
          hasAnswer: true,
        },
        token: this.token,
        questionId: this.mainQuestionId,
        subQuestionId: this.question.id
      };
      this.updateSubQuestionInfo(requestInfo);
      this.setQuestionUnanswered(this.questionId)
    },
  },
};
</script>
<style lang="css" scope>
.editor-button {
  display: flex;
  justify-content: flex-end;
}
</style>

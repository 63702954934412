<template>
  <div>
    <PageHeader title="จัดการคอร์สเรียน"></PageHeader>
    <b-container fluid="xl" class="pt-5">
      <div class="outer">
        <div class="inner bg-container pb-5">
          <CourseNavSteps />
          <b-overlay :show="false" variant="white">
            <Loading
              v-if="isLoading"
              class="mt-2 fixed-top"
              style="z-index: 999999"
            />
            <b-row class="mt-5 mb-5" align-h="between">
              <b-col lg="8" class="px-5">
                <h4>ข้อมูลคอร์ส</h4>
                <b-row class="mt-4">
                  <b-col>
                    <TextInput
                      v-if="courseInfo"
                      id="course_name"
                      label="ชื่อคอร์สเรียน"
                      attribute="courseName"
                      description="ไม่เกิน 150 ตัวอักษร"
                      :value="courseInfo.courseName"
                      :isRequire="true"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <TextInput
                      v-if="courseInfo"
                      id="course_slug"
                      label="URLคอร์สเรียน"
                      attribute="courseSlug"
                      description="ไม่เกิน 150 ตัวอักษร"
                      :value="courseInfo.courseSlug"
                      :isRequire="true"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col col-md="6">
                    <TextInput
                      v-if="courseInfo"
                      id="course_code"
                      label="รหัสคอร์สเรียน"
                      attribute="courseCode"
                      description="กรุณากรอกเป็นภาษาอังกฤษ หรือตัวเลข"
                      :isRequire="false"
                      :value="courseInfo.courseCode"
                    />
                  </b-col>
                  <b-col col-md="6">
                    <OwnerSelector
                      label="ชื่อผู้สอน"
                      attribute="courseOwner"
                      :company="company"
                      :value="courseInfo.courseOwner.ownerId"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <TextInput
                      id="course_short_description"
                      label="คำอธิบายสั้น ๆ"
                      description="ไม่เกิน 250 ตัวอักษร"
                      attribute="courseShortDescription"
                      :value="courseInfo.courseShortDescription"
                      :isRequire="true"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <TextEditor
                      id="course_description"
                      label="รายละเอียดคอร์ส"
                      attribute="courseDescription"
                      :value="courseInfo.courseDescription"
                    />
                  </b-col>
                </b-row>
                <!-- จำนวนผู้สมัครเรียน -->
                 <b-row v-if="comId == 513" class="mt-4">
                  <b-col>
                    <p class="pb-2">วันที่เริ่มหลักสูตร</p>
                    <b-input-group class="mb-3" style="border-color: ;">
                      <b-form-input
                        v-model="formattedstartLearningDate"
                        type="text"
                        placeholder="เลือกวันที่"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="startLearningDate"
                          button-only
                          right
                          locale="th"
                          @input="editCourseInfo({startLearningDate: startLearningDate})"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <ApplicantLimitForm />
                  </b-col>
                 </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <DifficultLevelForm attribute="courseDifficultLevel" />
                  </b-col>
                  <b-col v-if="comId == 512" align="right">
                    <div>
                      <label class="d-block">
                        รูปแบบของคอร์สเรียน
                      </label>
                      <div style="display:inline-block">
                        <div>
                          <span
                            class="mt-2 mr-2"
                            :style="
                              getCourseTypeStyle(courseInfo.courseOnlineType)
                            "
                          >
                            {{
                              courseInfo.courseOnlineType == "ONLINE"
                                ? "คอร์สเรียนออนไลน์"
                                : courseInfo.courseOnlineType == "HYBRID"
                                ? "การเรียนแบบผสมผสาน"
                                : ""
                            }}
                          </span>
                          <b-dropdown
                            v-if="courseInfo.courseOnlineType == 'ONLINE'"
                            right
                            size="sm"
                            variant="outline"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template #button-content>
                              <i class="fas fa-ellipsis-v dropdown-icon"></i>
                            </template>
                            <b-dropdown-item @click="modalShowEditCourseType()">
                              เปลี่ยนคอร์สนี้เป็น
                              <span
                                style="color:white; background-color:#BA55D3;"
                                >การเรียนแบบผสมผสาน</span
                              >
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div v-if="isCompany([485])">
                  <TfacInfoForm class="mt-5" />
                </div>
                <div v-else-if="isCompany([519, 513])">
                  <AccountingInfoForm class="mt-5" :company="company" />
                  <div v-if="isCompany([513])">
                    <ManageCoursePriceAndLearnDuration />
                  </div>
                </div>
                <div v-else>
                  <div v-if="isCompany([500])">
                    <TibaInfoForm />
                  </div>
                  <ManageCoursePriceAndLearnDuration />
                </div>
              </b-col>
              <b-col lg="4" class="border-left px-4">
                <label
                  id="fieldset-1__BV_label_"
                  for="duration"
                  class="d-block mt-4"
                  >รูปหน้าปกคอร์ส</label
                >
                <div
                  :class="[
                    { uploaded: courseAttributeUpdated['courseThumbnail'] },
                    'drop-zone',
                  ]"
                >
                  <b-overlay :show="onUploadCourseThumbnail" variant="white">
                    <b-media class="m-1">
                      <template v-slot:aside>
                        <b-img
                          :src="courseInfo.courseThumbnail"
                          height="50"
                          alt
                        />
                      </template>
                      <b-form-file
                        id="file"
                        ref="file"
                        v-model="courseThumbnail"
                        :state="Boolean(courseThumbnail)"
                        accept="Image/*"
                        :placeholder="
                          courseAttributeUpdated['courseThumbnail']
                            ? 'Uploaded'
                            : 'Choose / Drop file'
                        "
                        drop-placeholder="Drop file here..."
                        browse-text=" "
                        @input="upload"
                      ></b-form-file>
                    </b-media>
                  </b-overlay>
                </div>

                <TagsForm />

                <TypeInsuranceForm
                  v-if="isCompany([470, 500, 503, 506, 502])"
                />
                <label
                  id="fieldset-1__BV_label_"
                  for="duration"
                  class="d-block mt-4"
                  >พรีวิวคอร์ส</label
                >
                <b-link
                  :href="
                    `${coursePreviewUrl}/${coursePreviewName}?preview=1&token=${token}`
                  "
                  target="_blank"
                  class="text-decoration-none"
                >
                  <TfacInfoCard
                    v-if="isCompany([485])"
                    :courseInfo="courseInfo"
                  />
                  <b-card
                    v-else
                    :img-src="courseInfo.courseThumbnail"
                    img-alt
                    img-top
                    style="max-width: 20rem"
                    no-body
                    footer-border-variant="white"
                    footer-bg-variant="white"
                    class="mb-4 text-dark"
                  >
                    <b-card-body>
                      <div class="my-2">
                        <b-badge
                          v-for="(tag, index) in courseInfo.courseTags"
                          :key="index"
                          variant="secondary"
                          class="mr-1"
                          >{{ tag.tagName }}</b-badge
                        >
                      </div>
                      <div class="course-title mt-2 text-crop-2">
                        {{ courseInfo.courseName }}
                      </div>
                      <b-card-text>
                        <a class="text-csq small">{{
                          courseInfo.courseOwner.ownerName
                        }}</a>
                      </b-card-text>
                    </b-card-body>
                    <template v-slot:footer>
                      <div v-if="company.id !== 519">
                        <h5
                          v-if="courseInfo.seasonPrice.enable === true"
                          class="text-right"
                        >
                          <span class="text-danger">
                            ฿{{ displayPrice() }}
                          </span>
                        </h5>
                        <h5
                          v-if="courseInfo.seasonPrice.enable === true"
                          class="float-right"
                        >
                          <del>
                            ฿{{
                              courseInfo.price
                                | formatNumber
                                | displaySeasonPrice
                            }}
                          </del>
                        </h5>
                        <h5 v-else class="float-right">
                          <span> ฿{{ displayPrice() }} </span>
                        </h5>
                      </div>
                      <div v-else>
                        <h5
                          class="text-right text-danger"
                          style="text-decoration: line-through;"
                        >
                          {{ dharmnitiFullPrice() }}
                        </h5>
                        <h5 class="text-right">
                          ฿{{ dharmnitiCoursePrice() }}
                        </h5>
                      </div>
                    </template>
                  </b-card>
                </b-link>
              </b-col>
            </b-row>
          </b-overlay>
          <CourseNavToolbar
            :sidebar-opened="sidebarOpened"
            :course="courseInfo"
            :company="company"
          />
        </div>
      </div>
    </b-container>
    <b-modal
      centered
      v-model="modalConfirmEditCourseType"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
      ref="modal-delete-insurance-form"
    >
      <div>
        <ConfirmEditCourseType
          :courseName="courseInfo.courseName"
          :isConfirmed="isConfirmed"
          @modalHideEditCourseType="modalHideEditCourseType()"
          @edit="editCourseType($event)"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import CourseNavSteps from "./partials/CourseNavSteps";
import CourseNavToolbar from "./partials/CourseNavToolbar";
import TfacInfoCard from "./partials/TfacInfoCard";
import TfacInfoForm from "./forms/TfacInfoForm";
import TibaInfoForm from "./forms/TibaInfoForm";
import AccountingInfoForm from "./forms/AccountingInfoForm";
import TagsForm from "./forms/TagsForm";
import ConfirmEditCourseType from "./ConfirmEditCourseType.vue";
import TypeInsuranceForm from "./forms/TypeInsuranceForm";
import DifficultLevelForm from "./forms/DifficultLevelForm";
import ManageCoursePriceAndLearnDuration from "./forms/ManageCoursePriceAndLearnDurationForm.vue";
import ApplicantLimitForm from "./forms/ApplicantLimitForm.vue";
import TextInput from "./forms/TextInput";
import OwnerSelector from "./forms/OwnerSelector";
import TextEditor from "./forms/TextEditor";
import courseMixin from "./../../../mixins/course-mixin.js";
import Loading from "../../shared/Loading";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";
import Numeral from "numeral";

export default {
  name: "CourseInfoPage",
  components: {
    PageHeader,
    CourseNavSteps,
    CourseNavToolbar,
    TfacInfoCard,
    TfacInfoForm,
    TagsForm,
    DifficultLevelForm,
    ApplicantLimitForm,
    TextInput,
    OwnerSelector,
    TextEditor,
    Loading,
    TibaInfoForm,
    TypeInsuranceForm,
    ConfirmEditCourseType,
    AccountingInfoForm,
    ManageCoursePriceAndLearnDuration,
  },
  filters: {
    wordsCount: function(value) {
      if (value) {
        const cleanCode = value
          .replace(/<(?:.|\n)*?>/gm, "")
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace("&nbsp;", "");

        return cleanCode.trim().length;
      }

      return 0;
    },
    formatNumber: function(value) {
      if (!isNaN(value)) {
        return Numeral(value).format("0,0");
      } else {
        return value;
      }
    },
    displaySeasonPrice: function(value) {
      if (value == 0 || value === null) {
        return "ฟรี";
      }
      return value;
    },
  },
  mixins: [courseMixin],
  props: {
    user: { default: null, type: Object },
    company: { default: null, type: Object },
    sidebarOpened: Boolean,
  },
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      courseId: this.$route.params.courseId,
      showCoursePriceModal: false,
      showCourseTfacPriceModal: false,
      onUploadCourseThumbnail: false,
      courseThumbnail: null,
      token: localStorage.getItem("token"),
      isDropdownOpen: false,
      modalConfirmEditCourseType: false,
      isConfirmed: false,
      startLearningDate: "",
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseInfo",
      "courseAttributeUpdated",
    ]),
    invalidFeedback() {
      if (this.courseInfo.courseName.length > 4) {
        return "";
      } else if (this.courseInfo.courseName.length > 0) {
        return "Enter at least 4 characters";
      } else {
        return "Please enter something";
      }
    },
    validFeedback() {
      return this.state === true ? "Thank you" : "";
    },
    formattedstartLearningDate() {
      if (this.startLearningDate) {
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Date(this.startLearningDate).toLocaleDateString("th", options);
      }
      return "";
    },
  },
  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    await this.getCourseInfo({
      courseId: this.courseId,
      temp: true,
      excepts: "numberOfStudent,numberOfActiveStudent",
    });
    let contentDiv = document.getElementById("content");
    contentDiv.scrollTop = 0;
    this.startLearningDate = this.courseInfo.startLearningDate;
  },
  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateCourseInfo",
      "updateCourseTags",
      "uploadCourseThumbnail",
    ]),
    isCompany(comIdArr) {
      return comIdArr.includes(this.company.id);
    },
    getSearchKeyword(value) {
      console.log(value);
    },
    async editCourseInfo(attribute) {
      await this.updateCourseInfo({
        courseId: this.courseId,
        attribute: attribute,
      }).then(({ data }) => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        console.log("res", data);
      });
    },
    async upload() {
      this.onUploadCourseThumbnail = true;
      await this.uploadCourseThumbnail({
        courseId: this.courseId,
        companyId: this.company.id,
        imageFile: this.courseThumbnail,
      }).then(() => {
        setTimeout(
          () =>
            this.getCourseInfo({
              courseId: this.courseId,
              temp: true,
              excepts: "numberOfStudent,numberOfActiveStudent",
            }),
          5000
        );
        this.onUploadCourseThumbnail = false;
        this.updateCourseInfo({ courseId: this.courseId });
      });
    },
    displayPrice() {
      var price;
      if (
        this.courseInfo.membershipPrice.length > 0 &&
        this.courseInfo.membershipPrice[0].price != null &&
        Number(this.courseInfo.membershipPrice[0].price) <
          Number(this.courseInfo.price)
      ) {
        price = this.courseInfo.membershipPrice[0].price;
      } else if (
        this.courseInfo.seasonPrice.enable &&
        this.courseInfo.seasonPrice.price !== null &&
        Number(this.courseInfo.seasonPrice.price) <
          Number(this.courseInfo.price)
      ) {
        price = this.courseInfo.seasonPrice.price;
      } else if (
        this.courseInfo.promotionPrice !== null &&
        Number(this.courseInfo.promotionPrice) < Number(this.courseInfo.price)
      ) {
        price = this.courseInfo.promotionPrice;
      } else {
        price = this.courseInfo.price;
      }

      if (price > 0) {
        return Numeral(price).format("0,00");
      } else {
        return "ฟรี";
      }
    },
    dharmnitiCoursePrice() {
      var price;
      if (
        this.courseInfo.promotionPrice !== null &&
        Number(this.courseInfo.promotionPrice) < Number(this.courseInfo.price)
      ) {
        price = Number(this.courseInfo.promotionPrice) / 1.07;
      } else {
        price = Number(this.courseInfo.price) / 1.07;
      }

      if (price > 0) {
        return Numeral(price).format("0,00");
      } else {
        return "ฟรี";
      }
    },
    dharmnitiFullPrice() {
      var price;
      if (
        this.courseInfo.promotionPrice !== null &&
        Number(this.courseInfo.promotionPrice) <=
          Number(this.courseInfo.price) &&
        Number(this.courseInfo.promotionPrice) > 0
      ) {
        price = Number(this.courseInfo.price) / 1.07;
      } else {
        price = null;
      }

      if (price > 0) {
        return "฿" + Numeral(price).format("0,00");
      } else {
        return null;
      }
    },
    getCourseTypeStyle(typeName) {
      if (typeName === "ONLINE") {
        return {
          backgroundColor: "#F4A460",
          color: "white",
        };
      } else {
        return {
          backgroundColor: "#BA55D3",
          color: "white",
        };
      }
    },
    modalShowEditCourseType() {
      this.modalConfirmEditCourseType = true;
    },
    async modalHideEditCourseType() {
      this.modalConfirmEditCourseType = false;
      this.isConfirmed = false;
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });
    },
    async editCourseType(courseOnlineType) {
      await api
        .put(`/v1/courses/` + this.courseInfo.courseId + `/type`, {
          courseOnlineType: courseOnlineType,
        })
        .then(() => {
          this.isConfirmed = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-success {
  position: absolute;
  margin: 5px 10px;
  z-index: 9999;
  right: 20px;
}

.uploaded {
  background-image: url("/image/icon_check.png");
}

.drop-zone {
  height: 62px;
  background-image: none;
  background-position: none;
  background-repeat: none;
  background-size: none;
}
del {
  text-decoration-color: #dc3545;
  text-decoration-thickness: 0.1em;
}
</style>

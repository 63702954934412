<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader title="การเข้าทำข้อสอบล่าสุด"></PageHeader>
      <b-row>
        <b-col cols="12">
          <recentSheet
            :apiRoute="
              `exams/${examId}/get-recent-answer-sheet?startDate=2014-01-01&endDate=2014-01-01template=A&bestScore=${bestScoreSelected}`
            "
            :perPage="20"
            :bestScoreSelected="bestScoreSelected"
          ></recentSheet>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import recentSheet from "./panel/IndividualExamRecentAnswerSheetPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
export default {
  data() {
    return { 
      examId: this.$route.params.examId,
      bestScoreSelected: this.$route.query.bestScore 
      };
  },
  components: {
    PageHeader,
    recentSheet,
  },
};
</script>
<style scoped></style>

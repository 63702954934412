<template>
  <div>
    <b-row class="px-4">
      <b-col class="col-12 py-2 mt-4 border">
        <div class="d-flex align-items-center justify-content-between">
          <p>รายการทำแบบทดสอบล่าสุด</p>
          <div class="font-small">
            <a
              :href="`/${company.url}/analytics/exams/recent-exams`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </div>
        </div>
      </b-col>
      <b-col class="col-12 py-2 border">
        <b-table
          table-class="tbNumber text-left"
          style="overflow-x: auto;"
          :items="recentExams ? recentExams.slice(0, 10) : []"
          :fields="fields"
          class="text-truncate border"
          small
          sort-icon-left
          responsive="true"
          :busy="recentExams ? false : true"
          hover
          striped
        >
          <template v-slot:cell(student)="data">
            <span>{{ data.item.metadata.memberName }}</span>
          </template>
          <template v-slot:cell(staffId)="data">
            <span>{{ data.item.staffId ? data.item.staffId : '-' }}</span>
          </template>
          <template v-slot:cell(exam)="data">
            <span>{{ data.item.metadata.examTitle }}</span>
          </template>
          <template v-slot:cell(totalTimeUsed)="data">
            <span>{{
              data.item.totalTimeUsed != null
                ? getTotalTimeUsed(data.item.totalTimeUsed.toFixed(2))
                : getTotalTimeUsed(
                    calculateTimeUsed(data.item.sections).toFixed(2)
                  )
            }}</span>
          </template>
          <template v-slot:cell(date)="data">
            <span>{{
              new Date(data.item.createdAt).toLocaleString('en-GB')
            }}</span>
          </template>

          <template v-slot:cell(start)="data">
            <span>{{
              new Date(
                new Date(data.item.createdAt).getTime() -
                  Math.round(calculateTimeUsed(data.item.sections)) * 1000
              ).toLocaleTimeString('en-GB')
            }}</span>
          </template>
          <template v-slot:cell(score)="data">
            <span
              >{{
                data.item.overallResult.score
                  ? data.item.overallResult.score
                  : '0'
              }}
              ({{
                (
                  data.item.overallResult.score &&
                  (data.item.overallResult.score /
                    data.item.overallResult.fullScore) *
                    100
                ).toFixed(2)
              }}
              %)</span
            >
          </template>
          <template v-slot:cell(button)="data">
            <b-button variant="outline-primary" size="sm">
              <a
                :href="
                  `/${company.url}/analytics/exams/member/${data.item.metadata.memberId}/exam/${data.item.examId}`
                "
                target="_blank"
                >ดูข้อมูล</a
              >
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import totalTimeUsedMixin from '@/components/pages/analytics/exam/mixins/totalTimeUsed.js';
import dateMixin from '@/components/pages/analytics/shared/mixins/date.js';

export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  props: {
    recentExams: Array,
  },
  data() {
    return {
      fields: [
        { key: 'student', label: 'ผู้เรียน' },
        { key: 'staffId', label: 'รหัสพนักงาน' },
        { key: 'exam', label: 'ข้อสอบ' },
        { key: 'totalTimeUsed', label: 'เวลาที่ใช้ทำ' },
        { key: 'date', label: 'วันที่ทำข้อสอบ' },
        { key: 'start', label: 'เวลาที่เริ่มทำ' },
        { key: 'score', label: 'คะแนนที่ได้' },
        { key: 'button', label: '' },
      ],
      items: [],
      isLoading: false,
      company: null,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
  },
  methods: {
    thaiDurationFilter(value) {
      let str = '';
      value = parseFloat(value);
      if (value) {
        if (value >= 3600) {
          let hour = value / 3600;
          value = value % 3600;
          str = hour + ' ชั่วโมง ';
        }
        if (value >= 60) {
          let minute = value / 60;
          value = value % 60;
          str = str + minute + ' นาที ';
        }
        if (value != 0) {
          str = str + value + ' วินาที';
        }
        return str;
      }
      return `-`;
    },
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },
  },
};
</script>

<style scoped></style>

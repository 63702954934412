<template>
  <nav class="navbar navbar-static-top">
    <div class="container-fluid text-center m-0">
      <div class="navbar-brand w-100">
        <img :src="'/image/cs_logo_light.png'" class="cs_logo" alt="" />
      </div>
    </div>
    <!-- /.container-fluid -->
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    profile: { default: null, type: Object },
    sidebarOpened: Boolean,
  },

  data() {
    return {
      // csrf: document
      //   .querySelector('meta[name="csrf-token"]')
      //   .getAttribute("content")
    };
  },

  computed: {
    ...mapGetters('Authentication', ['authUser']),

    userWorkAtCompanies() {
      const roles = this.authUser.roles;
      const profiles = this.authUser.profiles;

      return profiles.filter((profile) => {
        return roles.find((role) => {
          return role.companyId == profile.company.id;
        });
      });
    },
  },

  methods: {
    submit: function() {
      this.$refs.form.submit();
    },
    openSidebar() {
      this.$emit('sidebarOpened', !this.sidebarOpened);
    },
    async logout() {
      await this.$store
        .dispatch('Authentication/logout')
        .then(() => {
          window.location.href = '/login';
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.cs_logo {
  height: 48px;
  margin-top: 0px;
}
.icon-avatar {
  font-size: 16px;
}
#top .navbar-brand {
  padding-top: 3px;
  padding-bottom: 1px;
}
.user-settings .dropdown-menu {
  width: 280px;
  right: 0px;
  top: 34px;
}
.dropdown-item.title {
  text-align: left;
  padding: 0.25rem 1.5rem;
}
.user-settings .dropdown-menu:before {
  position: absolute;
  top: -9px;
  left: 80%;
  display: inline-block;
  content: '';
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ccc;
  border-bottom-color: #f99f4e;
  border-left: 10px solid transparent;
  font-size: 15px;
}
.scrollable-menu {
  height: auto;
  max-height: 300px;
  overflow-x: auto;
}
</style>

<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>ข้อมูลผู้เข้าเรียน</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${companyInfo.url}/analytics/tfac/student-info`"
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row align-v="center">
              <b-col cols="12" md="6">
                <b-form-group label="ค้นหาวันที่ลงทะเบียนคอร์ส">
                  <b-row align-v="center">
                    <b-col cols="5"
                      ><b-input
                        v-model="fromSearch"
                        type="date"
                        :max="toSearch"
                    /></b-col>
                    <b-col cols="auto"><p>ถึง</p></b-col>
                    <b-col cols="5"
                      ><b-input
                        v-model="toSearch"
                        type="date"
                        :min="fromSearch"
                        :max="today"
                    /></b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="สถานะการเรียนจบ">
                  <b-select v-model="status" :options="options" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-v="baseline">
              <b-col cols="12" sm="6" md="3">
                <b-form-group label="ค้นหารหัสหลักสูตร (CPD)">
                  <b-form-input v-model="cpdCourseCodeSearch" />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <b-form-group label="ค้นหาเลขประจำตัวประชาชน">
                  <b-form-input v-model="idCardSearch" />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <b-form-group label="ค้นหาชื่อนามสกุล">
                  <b-form-input v-model="fullNameSearch" />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <b-form-group label="ค้นหาเลขผู้ทำบัญชี">
                  <b-form-input v-model="smNoSearch" />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <b-form-group label="ค้นหาเลขผู้สอบบัญชีรับอนุญาต">
                  <b-form-input v-model="smCpaNoSearch" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10"> </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="course-student-info-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="filterData()"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            >
            <template v-slot:cell(fullName)="data"
              ><span>{{ data.item.name }}</span></template
            >
            <template v-slot:cell(idCard)="data"
              ><span>{{ data.item.mp_idCard }}</span></template
            >
            <template v-slot:cell(idCardExpireDate)="data"
              ><span>{{ data.item.mp_idCardExpireDate }}</span></template
            >

            <template v-slot:cell(smStatus)="data"
              ><span
                >{{
                  data.item.sm_cpaStatus && data.item.sm_dbdStatus
                    ? "ผู้สอบบัญชี/ผู้ทำบัญชี"
                    : data.item.sm_cpaStatus
                    ? "ผู้สอบบัญชี"
                    : data.item.sm_dbdStatus
                    ? "ผู้ทำบัญชี"
                    : ""
                }}
              </span></template
            >
            <template v-slot:cell(smNo)="data"
              ><span>
                {{
                  data.item.sm_dbdStatus
                    ? (data.item.mp_idCard
                      ? data.item.mp_idCard
                      : data.item.sm_membershipId)
                    : ""
                }}
              </span></template
            >
            <template v-slot:cell(smCpaNo)="data"
              ><span>
                {{ data.item.sm_cpaNo }}
              </span></template
            >
            <template v-slot:cell(courseCode)="data"
              ><span>{{ data.item.c_courseCode }}</span></template
            >
            <template v-slot:cell(cpdCourseCode)="data"
              ><span>{{ data.item.tfc_cpdCourseCode }}</span></template
            >
            <template v-slot:cell(courseName)="data"
              ><span>{{ data.item.c_name }}</span></template
            >
            <template v-slot:cell(coursePrice)="data"
              ><span>{{
                data.item.coi_purchasePrice | formatNumber
              }}</span></template
            >
            <template v-slot:cell(learningTime)="data"
              ><span>{{
                data.item.learning_time
                  ? data.item.learning_time + " ชั่วโมง"
                  : ""
              }}</span></template
            >
            <template v-slot:cell(completeDate)="data"
              ><span>{{
                data.item.cer_completeDate | thaiDate
              }}</span></template
            >
            <template v-slot:cell(lelExamResultUpdatedAt)="data"
              ><span>{{
                data.item.lel_examResultUpdatedAt | thaiDate
              }}</span></template
            >
            <template v-slot:cell(lelExamResultPercent)="data"
              ><span>{{
                data.item.lel_examResultUpdatedAt
                  ? data.item.lel_examResultPercent + "%"
                  : ""
              }}</span></template
            >
            <template v-slot:cell(btn)="data">
              <b-icon
                v-if="
                  data.item.cer_completeDate &&
                  data.item.cer_completeDate != null
                "
                icon="download"
                variant="primary"
                class="h5 text-center download-icon-style"
                style="cursor: pointer"
                @click="downloadIconClicked(data.item.m_id, data.item.c_id)"
              ></b-icon>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="filterData().length"
            :per-page="perPage"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import exp from "@/services/export.js";
import api from "@/services/api.js";
import moment from "moment";
import AuthMixin from "@/mixins/auth-mixin.js";
import Numeral from "numeral";
export default {
  components: {
    layout
  },
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0");
    }
  },
  mixins: [AuthMixin],
  props: ["apiRoute", "perPage"],
  data() {
    return {
      companyInfo: this.$store.state.Company.companyInfo,
      search: "",
      currentPage: 1,
      rows: 0,
      cpdCourseCodeSearch: "",
      idCardSearch: "",
      fullNameSearch: "",
      smNoSearch: "",
      smCpaNoSearch: "",
      result: [],
      isBusy: true,
      fields: [
        { key: "fullName", label: "ชื่อผู้เรียน" },
        { key: "idCard", label: "เลขบัตรประจำตัวประชาชน" },
        { key: "smStatus", label: "สถานะผู้ทำ/ผู้สอบบัญชี" },
        { key: "smNo", label: "เลขผู้ทำบัญชี" },
        { key: "smCpaNo", label: "เลขผู้สอบบัญชี" },
        { key: "courseCode", label: "รหัสหลักสูตร" },
        { key: "cpdCourseCode", label: "รหัสหลักสูตร(CPD)" },
        { key: "courseName", label: "หลักสูตร" },
        { key: "coursePrice", label: "ราคา" },
        { key: "learningTime", label: "จำนวนชั่วโมงที่ใช้เรียน" },
        { key: "completeDate", label: "วันที่เรียนจบ" },
        { key: "lelExamResultUpdatedAt", label: "วันที่สอบ" },
        { key: "lelExamResultPercent", label: "ผลทดสอบ" }
        // { key: "btn", label: "ดาวน์โหลด" },
      ],
      options: [
        { value: "ALL", text: "ดูทั้งหมด" },
        { value: true, text: "เรียนจบแล้ว" },
        { value: false, text: "ยังไม่จบ" }
      ],
      status: "ALL",
      today: moment().format("YYYY-MM-DD"),
      fromSearch: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      toSearch: moment().format("YYYY-MM-DD"),
      items: []
    };
  },
  methods: {
    filterData() {
      var data = this.result.filter(i => {
        let passFilter =
          new Date(i.cer_companyAssignDate).setHours(0, 0, 0, 0) >=
            new Date(this.fromSearch).setHours(0, 0, 0, 0) &&
          new Date(i.cer_companyAssignDate).setHours(0, 0, 0, 0) <=
            new Date(this.toSearch).setHours(0, 0, 0, 0);
        if (
          this.cpdCourseCodeSearch != null &&
          this.cpdCourseCodeSearch.length > 0
        ) {
          passFilter =
            passFilter &&
            i.tfc_cpdCourseCode != null &&
            i.tfc_cpdCourseCode
              .toLowerCase()
              .includes(this.cpdCourseCodeSearch.toLowerCase());
        }
        if (this.idCardSearch != null && this.idCardSearch.length > 0) {
          passFilter =
            passFilter &&
            i.mp_idCard != null &&
            i.mp_idCard.toLowerCase().includes(this.idCardSearch.toLowerCase());
        }
        if (this.fullNameSearch != null && this.fullNameSearch.length > 0) {
          passFilter =
            passFilter &&
            i.name != null &&
            i.name.toLowerCase().includes(this.fullNameSearch.toLowerCase());
        }
        if (this.smNoSearch != null && this.smNoSearch.length > 0) {
          passFilter =
            passFilter &&
            i.sm_membershipId != null &&
            i.sm_membershipId
              .toLowerCase()
              .includes(this.smNoSearch.toLowerCase());
        }
        if (this.smCpaNoSearch != null && this.smCpaNoSearch.length > 0) {
          passFilter =
            passFilter &&
            i.sm_cpaNo != null &&
            i.sm_cpaNo.toLowerCase().includes(this.smCpaNoSearch.toLowerCase());
        }
        return passFilter;
      });
      if (this.status == true)
        data = data.filter(
          i => i.cer_completeDate && i.cer_completeDate != null
        );
      if (this.status == false)
        data = data.filter(
          i => !i.cer_completeDate || i.cer_completeDate == null
        );
      return data;
    },
    async exportData() {
      let value = [];
      let set = this.filterData();

      set.forEach(i => {
        let temp = {
          prefixName: i.mp_prefixName,
          firstName: i.mp_firstName,
          lastname: i.mp_lastName,
          idCard: i.mp_idCard,
          idExpire: i.mp_idCardExpireDate,
          mobilePhone: i.mp_mobilePhone,
          email: i.m_email,
          status:
            i.sm_cpaStatus && i.sm_dbdStatus
              ? "ผู้สอบบัญชี/ผู้ทำบัญชี"
              : i.sm_cpaStatus
              ? "ผู้สอบบัญชี"
              : i.sm_dbdStatus
              ? "ผู้ทำบัญชี"
              : "",
          accNo: i.sm_dbdStatus
            ? (i.mp_idCard
              ? i.mp_idCard
              : i.sm_membershipId)
            : "",
          cpaNo: i.sm_cpaNo,
          accHour: parseInt(
            i.sm_cpaStatus ? i.tfc_cpaAccHour || 0 : i.tfc_accAccHour || 0
          ),
          accMin:
            ((i.sm_cpaStatus ? i.tfc_cpaAccHour || 0 : i.tfc_accAccHour || 0) %
              1) *
            60,
          otherHour: parseInt(
            i.sm_cpaStatus ? i.tfc_cpaOtherHour || 0 : i.tfc_accOtherHour || 0
          ),
          otherMin:
            ((i.sm_cpaStatus
              ? i.tfc_cpaOtherHour || 0
              : i.tfc_accOtherHour || 0) %
              1) *
            60,
          code: i.c_courseCode,
          cpdCourseCode: i.tfc_cpdCourseCode,
          c_name: i.c_name,
          price: i.coi_purchasePrice,
          learning_time: i.learning_time ? i.learning_time + " ชั่วโมง" : "",
          startDate: i.cer_startDate
            ? moment(i.cer_startDate).format("DD/MM/YYYY")
            : "",
          completeDate: i.cer_completeDate
            ? moment(i.cer_completeDate).format("DD/MM/YYYY")
            : "",
          lel_examResultUpdatedAt: i.lel_examResultUpdatedAt
            ? moment(i.lel_examResultUpdatedAt).format("DD/MM/YYYY")
            : "",
          lel_examResultPercent: i.lel_examResultPercent
            ? i.lel_examResultPercent + "%"
            : ""
        };
        value.push(temp);
      });
      let key = [
        "คำนำหน้า",
        "ชื่อ",
        "นามสกุล",
        "เลขบัตรประจำตัวประชาชน",
        "วันหมดอายุบัตรประชาชน",
        "เบอร์ติดต่อ",
        "อีเมล",
        "สถานะผู้ทำ/ผู้สอบบัญชี",
        "เลขผู้ทำบัญชี",
        "เลขผู้สอบบัญชี",
        "ชั่วโมง CPD (ด้าน บัญชี)",
        "นาที CPD (ด้าน บัญชี)",
        "ชั่วโมง CPD (ด้านอื่นๆ)",
        "นาที CPD (ด้านอื่นๆ)",
        "รหัสหลักสูตร",
        "รหัสหลักสูตร (CPD)",
        "หลักสูตร",
        "ราคา",
        "จำนวนชั่วโมงที่ใช้เรียน",
        "วันที่เริ่มเรียน",
        "วันที่เรียนจบ",
        "วันที่สอบ",
        "ผลทดสอบ"
      ];
      let filename = "Student-Info-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },
    formatDate: function(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
    async downloadIconClicked(mId, cId) {
      try {
        let results = await api.get(
          `/v1/members/${mId}/course/${cId}/certificate`
        );

        let urlDownloadLink = results.data.domain + results.data.link;
        window.open(urlDownloadLink, "_blank");
      } catch (err) {
        console.log("downloadIconClicked error", err);
      }
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.isBusy = false;
    }
  },

  async mounted() {
    this.fetch();
  }
};
</script>

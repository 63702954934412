<template>
  <div>
    <PageHeader title="ข้อมูลการเข้าชั้นเรียน" />
    <b-container>
      <b-row>
        <b-col>
          <div class="bg-container py-5">
            <h4 style="font-weight: 600;">
              {{ classList.courseName }}
            </h4>
            <div class="info mt-4">
              <b-row>
                <b-col cols="2" md="2"><span>วันที่เข้าเรียน</span></b-col>
                <b-col cols="2" md="2">{{ date }}</b-col>
              </b-row>
              <b-row class="my-2">
                <b-col cols="2" md="2"><span>เวลา</span></b-col>
                <b-col cols="2" md="2">
                  {{ `${startTime} - ${finishTime}` }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2" md="2"><span>จำนวนชั่วโมง</span></b-col>
                <b-col cols="2" md="2">
                  {{ formatClassDuration(classDuration) }}
                </b-col>
              </b-row>
            </div>
            <br />
            <!-- table -->
            <div v-if="!isEditClass">
              <div
                class="my-3 d-flex justify-content-between align-items-center"
              >
                <h6 class="mb-0" style="font-weight: 600;">
                  รายละเอียดการเข้าเรียน
                </h6>
                <div class="d-flex justify-content-baseline">
                  <b-button
                    @click="exportReport()"
                    class="btn-main mr-2"
                    variant="primary"
                  >
                    <b-icon
                      icon="chevron-double-down"
                      style="height: 14px;"
                    ></b-icon>
                    ดาวน์โหลดรายงาน xlsx
                  </b-button>
                  <ag-grid-vue
                    style="width: 100%; height: 800px; display: none"
                    class="ag-theme-alpine"
                    :columnDefs="columnInfo"
                    :rowData="rowDataInfo"
                    :excelStyles="excelStyles"
                    @grid-ready="onGridReady"
                  ></ag-grid-vue>
                  <b-dropdown text="ตัวเลือก">
                    <b-dropdown-item @click="isEditClass = true"
                      >แก้ไขข้อมูล</b-dropdown-item
                    >
                    <b-dropdown-item @click="isDeleteClass = true"
                      >ลบข้อมูล</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      class="align-middle"
                      style="padding-left: 0.75rem !important; width: 200px;"
                    >
                      <img :src="srcImage" alt="SVG Image" />
                      ชื่อผู้เรียน
                    </th>
                    <th
                      class="align-middle"
                      style="padding-left: 0.75rem !important; width: 200px;"
                    >
                      <img :src="srcImage" alt="SVG Image" /> อีเมล
                    </th>
                    <th class="text-center align-middle" style="width: 70px;">
                      เข้าเรียน
                    </th>
                    <th class="text-center align-middle" style="width: 70px;">
                      มาสาย/<br />
                      กลับก่อน
                    </th>
                    <th class="text-center align-middle" style="width: 70px;">
                      ขาดเรียน
                    </th>
                    <th class="text-center align-middle" style="width: 70px;">
                      ลา
                    </th>
                    <th class="text-center align-middle" style="width: 100px;">
                      จำนวนชั่วโมง
                    </th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  <tr v-for="(item, index) in classInfo" :key="index">
                    <td>{{ item.firstName }} {{ item.lastName }}</td>
                    <td>{{ item.email }}</td>
                    <td class="text-center">
                      <b-icon
                        v-if="item.clsa_status === 'PRESENT'"
                        icon="check2"
                      ></b-icon>
                      <b-icon v-else icon="dash"></b-icon>
                    </td>
                    <td class="text-center">
                      <b-icon
                        v-if="item.clsa_status === 'LATE'"
                        icon="check2"
                      ></b-icon>
                      <b-icon v-else icon="dash"></b-icon>
                    </td>
                    <td class="text-center">
                      <b-icon
                        v-if="item.clsa_status === 'ABSENT'"
                        icon="check2"
                      ></b-icon>
                      <b-icon v-else icon="dash"></b-icon>
                    </td>
                    <td class="text-center">
                      <b-icon
                        v-if="item.clsa_status === 'LEAVE'"
                        icon="check2"
                      ></b-icon>
                      <b-icon v-else icon="dash"></b-icon>
                    </td>
                    <td class="text-center">
                      {{ formatClassDuration(item.clsa_duration) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <br />
              <router-link
                :to="{
                  name: 'attendance.info',
                  params: { courseId: courseId },
                }"
              >
                <b-icon icon="arrow-left" variant="primary"></b-icon>
                <span class="pl-2" style="color: #027BFF;">ย้อนกลับ</span>
              </router-link>
            </div>
            <div v-else>
              <div class="mt-4">
                <h6>
                  <span style="font-weight: 600;">รายละเอียดการเข้าเรียน</span>
                  ผู้เรียนในปัจจุบัน {{ classInfo.length }} คน
                </h6>
              </div>
              <EditClassAttendance
                :classInfo="classInfo"
                :courseId="courseId"
                :classId="classId"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <WarningActionModal
      :show="isDeleteClass"
      :title="'ลบข้อมูลการเข้าชั้นเรียนหรือไม่?'"
      :detail="
        'ต้องการลบข้อมูลการเข้าชั้นเรียนหรือไม่<br>(หากลบแล้วไม่สามารถกู้คืนข้อมูลได้)'
      "
      :actionName="'ลบข้อมูล'"
      :showConfirmModal="isShowConfirmModal"
      :successTitle="'ยืนยันลบข้อมูลการเข้าชั้นเรียนสำเร็จ'"
      @canceled="isDeleteClass = false"
      @action="onDeleteClassAttendance()"
      @onSuccess="success()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import PageHeader from "../../layouts/PageHeader";
import WarningActionModal from "./modal/WarningActionAttendanceModal.vue";
import EditClassAttendance from "./partials/EditClassAttendance.vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    PageHeader,
    WarningActionModal,
    EditClassAttendance,
    "ag-grid-vue": AgGridVue,
  },
  data() {
    return {
      classId: this.$route.params.classId,
      courseId: null,
      date: "",
      startTime: "",
      finishTime: "",
      classDuration: null,
      isDeleteClass: false,
      isShowConfirmModal: false,
      isEditClass: false,
      srcImage:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e",
      columnInfo: [
        {
          headerName: " ",
          field: "studentName",
        },
        {
          headerName: " ",
          field: "email",
        },
        {
          headerName: " ",
          field: "present",
        },
        {
          headerName: " ",
          field: "late",
        },
        {
          headerName: " ",
          field: "absent",
        },
        {
          headerName: " ",
          field: "leave",
        },
        {
          headerName: " ",
          field: "total",
        },
      ],
      rowDataInfo: [],
      excelStyles: [
        {
          id: "header",
          font: { fontName: "Cordia New", size: 1, bold: false },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center",
          },
        },
      ],
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters("AttendanceManagement", ["classList", "classInfo"]),
  },
  async mounted() {
    moment.updateLocale("th", {});
    await this.fetchClassInfo(this.classId);
    if (this.classInfo.length > 0) {
      const info = this.classInfo[0];
      this.courseId = info.c_id;

      this.fetchClassList(info.c_id);
      this.date =
        moment(info.cls_date).format("DD MMM ") +
        (moment(info.cls_date).year() + 543);
      this.startTime = this.formatTime(info.cls_startTime);
      this.finishTime = this.formatTime(info.cls_finishTime);
      this.classDuration = info.cls_duration;
    }
  },
  methods: {
    ...mapActions("AttendanceManagement", [
      "fetchClassList",
      "fetchClassInfo",
      "deleteClassAttendance",
    ]),
    formatTime(time) {
      if (time) {
        const [hours, minutes] = time.split(":");
        return `${hours}:${minutes}`;
      }
      return "";
    },
    formatClassDuration(duration) {
      if (duration >= 60) {
        const minutes = duration / 60;

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const hoursStr = hours.toString().padStart(2, "0");
        const minutesStr = remainingMinutes.toString().padStart(2, "0");

        return `${hoursStr}:${minutesStr}`;
      }
      return "00:00";
    },
    async onDeleteClassAttendance() {
      await this.deleteClassAttendance(this.classId);
      this.isDeleteClass = false;
      this.isShowConfirmModal = true;
    },
    success() {
      this.isShowConfirmModal = false;
      this.$router.push({
        name: "attendance.info",
        params: { courseId: this.courseId },
      });
    },
    async exportReport() {
      let classInfo = [
        {
          studentName: "ชื่อคอร์ส :",
          email: `${this.classList.courseName}`,
          present: null,
          late: null,
          absent: null,
          leave: null,
          total: null,
        },
        {
          studentName: "วันที่เข้าเรียน : ",
          email: `${this.date}`,
          present: null,
          late: null,
          absent: null,
          leave: null,
          total: null,
        },
        {
          studentName: "เวลา :",
          email: `${this.startTime} - ${this.finishTime}`,
          present: "น.",
          late: null,
          absent: null,
          leave: null,
          total: null,
        },
        {
          studentName: "จำนวนชั่วโมง :",
          email: `${this.formatClassDuration(this.classDuration)}`,
          present: "ชั่วโมง",
          late: null,
          absent: null,
          leave: null,
          total: null,
        },
        {
          studentName: null,
          email: null,
          present: null,
          late: null,
          absent: null,
          leave: null,
          total: null,
        },
        {
          studentName: "ชื่อผู้เรียน",
          email: "อีเมล",
          present: "เข้าเรียน",
          late: "มาสาย/กลับก่อน",
          absent: "ขาด",
          leave: "ลา",
          total: "จำนวนชั่วโมง",
        },
      ];

      let data = this.classInfo.map((item) => {
        return {
          studentName: item.firstName + " " + item.lastName,
          email: item.email,
          present: item.clsa_status === "PRESENT" ? "✓" : "-",
          late: item.clsa_status === "LATE" ? "✓" : "-",
          absent: item.clsa_status === "ABSENT" ? "✓" : "-",
          leave: item.clsa_status === "LEAVE" ? "✓" : "-",
          total: this.formatClassDuration(item.clsa_duration),
        };
      });
      this.rowDataInfo = [...classInfo, ...data];

      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "ข้อมูลการเข้าชั้นเรียน",
          fileName: `ข้อมูลการเข้าชั้นเรียน`,
        });
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>

<style scoped>
.btn-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail p span {
  font-weight: 600;
}

.table th {
  padding: 0.1rem !important;
}

.info span {
  font-weight: 600;
}

a:hover {
  text-decoration: none !important;
}
</style>

<template>
  <li class="dropdown_menu" :class="mainExamListCssObj">
    <a v-b-toggle.coursesCollapse @click.prevent="toggleCollapse">
      <b-icon-book-half class="mr-1"></b-icon-book-half>
      <span class="link-title menu_hide">คอร์สเรียน</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="coursesCollapse" :visible="false" accordion="accordion">
      <main-exam-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainExamCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import _ from "lodash";
import AuthMixin from "@/mixins/auth-mixin.js";
import CustomUserPermissionMixin from "@/mixins/custom-user-permission-mixin.js";

export default {
  components: {
    MainExamCollapseMenu,
  },
  mixins: [AuthMixin, CustomUserPermissionMixin],
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      memberId: JSON.parse(localStorage.getItem("user")).id,
      isActive: false,
      collapseList: [
        {
          title: "จัดการคอร์สเรียน",
          to: {
            name: "course.management.index",
          },
        },
      ],
    };
  },
  computed: {
    mainExamListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  mounted() {
    if (this.isTutor == true && !this.IsTutorAdmin) {
      this.collapseList = [
        {
          title: "จัดการคอร์สเรียน",
          to: {
            name: "course.management.index",
          },
        },
      ];
    }
    if (this.comId == 277 && this.isHideCourseManagement == true) {
      this.collapseList = [];
    }
    if (!this.isInstructor) {
      this.collapseList.push(
        {
          title: "จัดการหมวดหมู่คอร์ส",
          to: {
            name: "tags.overview",
          },
        },
      )
    }
    if (this.isOrgAdmin) {
      this.collapseList.push(
        {
          title: "จัดการกลุ่มคอร์ส",
          to: {
            name: "course-group.management.index",
          },
        },
      )
    }
    if (this.canSpecificUserAccess) {
      this.collapseList = [
        {
          title: "จัดการคอร์สเรียน",
          to: {
            name: "course.management.index",
          },
        },
      ]
    }
  },
  methods: {
    toggleCollapse() {
      // this.isActive = !this.isActive
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>

<template>
  <div>
    <b-link @click="modalShow = !modalShow">
      <b-icon-box-arrow-up-right />ดูรายชื่อผู้เรียน
    </b-link>
    <b-modal
      :ref="modalRef"
      hide-header
      hide-header-close
      ok-title="ปิด"
      :ok-only="true"
      size="lg"
      body-class="p-4"
      footer-class="border-top-0 px-4"
      ok-variant="primary"
    >
      <h5 class="mb-3">{{ `รายชื่อผู้เรียนในกลุ่ม: ${group.name}` }}</h5>
      <SearchBox
        :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
        @typed="fetchParams.q = $event"
      />
      <b-table
        :fields="fields"
        :items="groupMembers.data"
        :busy="isLoading"
        sort-icon-left
        :sort-by.sync="fetchParams.sort_by"
        :sort-desc.sync="sortDesc"
        no-local-sorting
        class="mt-3"
      >
        <template v-slot:cell(fullname)="{ item: { firstName, lastName } }">
          {{ `${firstName} ${lastName}` }}
        </template>
        <div slot="table-busy" class="text-center text-danger my-2">
          <Loading />
        </div>
      </b-table>

      <Pagination
        :items="groupMembers"
        :limits="perPageOptions"
        @changePage="fetchParams.page = $event"
        @changeLimit="fetchParams.limit = $event"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/shared/Loading";
import SearchBox from "@/components/shared/SearchBox";
import Pagination from "@/components/shared/Pagination";
import MembersTableMixin from "@/mixins/members-table-mixin.js";

export default {
  name: "ShowMembersInMemberGroupsTable",
  components: {
    Loading,
    SearchBox,
    Pagination
  },
  mixins: [MembersTableMixin],
  props: {
    group: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalShow: false,
      fields: [
        {
          label: "ผู้เรียน",
          key: "fullname",
          sortable: false
        },
        { key: "department", label: "แผนก", sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters("MemberManagement", ["groupMembers"]),
    modalRef() {
      return `member-group-${this.group.id}-modal`;
    }
  },
  watch: {
    modalShow(isShow) {
      if (isShow) {
        this.fetch();
        this.$refs[this.modalRef].show();
      }
    }
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchGroupMembers"]),
    async fetch() {
      this.isLoading = true;
      await this.fetchGroupMembers({
        groupId: this.group.id,
        params: this.fetchParams
      }).then(() => (this.isLoading = false));

      this.isLoading = false;
    }
  }
};
</script>

<template>
  <div>
    <!-- Face Recognition -->
    <h5 class="d-block mt-5" v-if="isAccountingType() || isInsuranceType()">

      <b-form-checkbox
        switch
        class="d-inline"
        @change="hasFaceEvaluationToggle"
        v-model="hasFaceState"
      />
      การยืนยันตัวตนด้วยใบหน้า

      <b-button
        size="xs"
        v-b-modal.modal-center
        variant="primary"
        class="mb-2 active-button"
      >
        <b-icon icon="info-circle-fill" scale="1" variant="info"></b-icon>
      </b-button>
      <!-- Modal Face-rec -->
      <b-modal
        size="lg"
        id="modal-center"
        hide-footer
        centered
        title="การยืนยันตัวตนด้วยใบหน้า"
      >
        <b-img
          src="/images/face-recognition-detect.png"
          fluid-grow
          alt="Fluid-grow image"
        ></b-img>
        <h6 class="modal-footer-text">
          แสดงหน้าต่างยืนยันตัวตนที่มุมขวาบนของหน้าจอขณะเรียน
        </h6>
      </b-modal>
      <!-- Modal Face-rec -->
    </h5>

    <!-- Gesture Detect -->
    <div v-if="this.hasFaceState == true && (isAccountingType() || isInsuranceType() || isTutorType())" style="margin-left: 2.5em">
      <h5 class="d-block mt-4">
        <b-form-checkbox
          switch
          class="d-inline"
          @change="toggleGesture"
          v-model="gestureState"
        />
        ตรวจสอบการทำท่าทางของมือ

        <b-button
          size="xs"
          v-b-modal.modal-2
          variant="primary"
          class="mb-2 active-button"
        >
          <b-icon icon="info-circle-fill" scale="1" variant="info"></b-icon>
        </b-button>
        <!-- Modal Gesture -->
        <b-modal
          size="lg"
          id="modal-2"
          hide-footer
          centered
          title="ตรวจสอบการทำท่าทางของมือ"
        >
          <b-img
            src="/images/gesture-detect.png"
            fluid-grow
            alt="Fluid-grow image"
          ></b-img>
          <h6 class="modal-footer-text">
            แสดงหน้าต่างเพื่อถ่ายรูปยืนยันตัวตนโดยทำท่าทางตามที่ระบบกำหนดในระหว่างเรียน
          </h6>
        </b-modal>
        <!-- Modal Gesture -->
      </h5>

      <!-- Select Gesture Time -->
      <div
        v-if="this.gestureState == true"
        style="margin-left: 4em; margin-top: 1em"
      >
        <p>
          กรุณาตั้งเวลาเพื่อแสดงหน้าต่างตรวจสอบท่าทางระหว่างเรียน
        </p>
        <!-- Select Time in Gesture -->
        <div>
          <div
            style="display: flex;">
            <b-form-select
              v-model="selected"
              :options="options"
              @change="gestureTimeSelect"
              size="sm"
              class="mt-3 w-25 ">
            </b-form-select>
            <div style="align-items: center;justify-content: center;margin-left: 10px;margin-top: 18px;">
              <p>นาที</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.active-button {
  background: none !important;
  border: none !important;
}
.select-time-face-rec {
  width: 75px;
  margin-left: 65px;
}
.face-rec-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}
.modal-footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
</style>

<script>
import api from "@/services/api.js";
import { mapGetters, mapActions } from "vuex";
import authMixin from "@/mixins/auth-mixin";

export default {
  props: ["cId"],
  mixins: [authMixin],
  data() {
    return {
      gestureState: "",
      hasFaceState: "",
      mustState: "",
      url: "",
      linkStatus: "",
      statusColor: "",
      courseId: this.$route.params.courseId,
      comId: this.$store.state.Company.companyInfo.id,
      mustDoForm: false,
      isJotform: "",
      selected: null,
      options: [
        { value: null, text: "--" },
        { value: 300, text: "5" },
        { value: 600, text: "10" },
        { value: 900, text: "15" },
        { value: 1800, text: "30" },
        { value: 3600, text: "60" },
      ],
    };
  },

  async mounted() {
    const getFaceRecognition = await api.get(
      `v1/courses/${this.courseId}?temp=1`
    );
    if (getFaceRecognition.data.data.faceRecogntionFlag != 1) {
      this.hasFaceState = false;
    } else {
      this.hasFaceState = true;
    }

    if (getFaceRecognition.data.data.gestureDetectionPeriod >= 1) {
      this.gestureState = true;
    } else {
      this.gestureState = false;
    }
  },



  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
  },

  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateAttributeCourseInfo",
    ]),

    async gestureTimeSelect(gestureTime){
        let requestGesture;
        requestGesture = {
          courseId: this.courseId,
          attribute: {
            name: "c_gestureDetectionPeriod",
            value: gestureTime,
          },
        };

      await this.updateAttributeCourseInfo(requestGesture).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: 1,
        });
      });
        
      },

    // When Toggle GestureDetection
    async toggleGesture() {
      let requestGesture;
      // Toggle On
      if (this.gestureState) {
        requestGesture = {
          courseId: this.courseId,
          attribute: {
            name: "c_gestureDetectionPeriod",
            value: 5,
          },
        };
        // Toggle Off
      } else {
        requestGesture = {
          courseId: this.courseId,
          attribute: {
            name: "c_gestureDetectionPeriod",
            value: 0,
          },
        };
      }
      console.log(requestGesture)
      await this.updateAttributeCourseInfo(requestGesture).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: 1,
        });
      });
    },

    // When Toggle FaceRecognition
    async hasFaceEvaluationToggle() {
      let requestFaceRecognition;
      // Toggle On
      if (this.hasFaceState) {
        requestFaceRecognition = {
          courseId: this.courseId,
          attribute: {
            name: "c_faceRecognitionFlag",
            value: true,
          },
        };
        // Toggle Off
      } else {
        requestFaceRecognition = {
          courseId: this.courseId,
          attribute: {
            name: "c_faceRecognitionFlag",
            value: false,
          },
        };
      }
      console.log(requestFaceRecognition)
      // Update Attribute (enable/disable face-recognition)
      await this.updateAttributeCourseInfo(requestFaceRecognition).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: 1,
        });
      });
      // Disable Gesture When Disable Face-Recognition
      if (this.gestureState == true) {
        this.gestureState = false;
        let gestureDisable = {
          courseId: this.courseId,
          attribute: {
            name: "c_gestureDetectionPeriod",
            value: false,
          },
        };
        await this.updateAttributeCourseInfo(gestureDisable).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: 1,
          });
        });
      }
    },
  },
};
</script>

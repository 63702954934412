<template>
  <b-form-checkbox
    v-model="checked"
    name="check-button"
    button
    button-variant="primary d-block rounded-0"
    size="sm"
    class="d-block"
  >
    {{ checked ? "นำออก" : "เลือก" }}
  </b-form-checkbox>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ToggleSelectionButton",
  props: {
    itemKey: { type: String, default: null },
    itemValue: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      label: "เลือก"
    };
  },
  computed: {
    ...mapGetters("CourseEnrollment", ["selectedItems"]),
    checked: {
      get() {
        return this.selectedItems[this.itemKey].some(
          item => item.id === this.itemValue.id
        );
      },
      set() {
        this.sync();
      }
    }
  },
  methods: {
    ...mapActions("CourseEnrollment", ["syncSelectedItems"]),
    sync() {
      this.syncSelectedItems({
        itemKey: this.itemKey,
        itemValue: this.itemValue
      });
    }
  }
};
</script>

<style>
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #0062cc !important;
  border-color: #0062cc !important;
  background-color: #fff !important;
}
</style>

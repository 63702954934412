<template>
  <div>
    <b-container fluid="xl">
      <b-row>
        <b-col cols="12" class="pb-2">
          <span>คุณสามารถใช้ Affiliate ลิงค์ด้านล่างสําหรับโปรโมทสถาบัน</span>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col class="" cols="12" sm="7" lg="8">
          <b-input-group>
            <b-form-input disabled v-model="companyLink"></b-form-input>
            <template #append>
              <b-input-group-text
                class="copy-icon"
                @click="copyButtonClicked()"
              >
                <b-icon icon="stickies"></b-icon>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-col>
        <b-col cols="12" sm="5" lg="3" class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            class="create-new-link-button d-flex justify-content-center align-items-center"
            @click="addModalShow = true"
          >
            <b-icon icon="plus-square-fill" class="mr-2"></b-icon>สร้างลิงค์ใหม่
          </b-button>
        </b-col>
      </b-row>
      <!-- For copy the link into the clipboard -->
      <b-row>
        <b-col>
          <input type="hidden" id="copied-link" :value="companyLink" />
        </b-col>
      </b-row>
      <!-- Add modal -->
      <b-modal
        v-model="addModalShow"
        title="สร้างลิงค์ Affiliate"
        :hide-footer="true"
      >
        <add-modal
          :courseOptions="courseOptions"
          :publisherOptions="publisherOptions"
          @created="createdNewLinkHandler"
          @close="addModalCloseHandler"
        ></add-modal>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import AddModal from "@/components/pages/affiliate/link/components/AddModal.vue";
import service from "@/services/api.js";

export default {
  components: {
    AddModal,
  },
  props: {
    companyLink: String,
  },
  data() {
    return {
      addModalShow: false,
      courseOptions: [],
      publisherOptions: [],
      createdAffiliateLink: null,
    };
  },
  created() {
    this.fetchCourseOptions();
    this.fetchPublisherOptions();
  },
  methods: {
    copyButtonClicked() {
      let testingCodeToCopy = document.querySelector("#copied-link");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      document.execCommand("copy");
      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      // Show some notification
      this.$bvToast.toast(`Copy to clipboard !`, {
        title: "Success",
        variant: "success",
        toaster: "b-toaster-bottom-right",
        autoHideDelay: 1000,
        appendToast: true,
        noHoverPause: true,
        noCloseButton: true,
      });
    },
    async fetchCourseOptions() {
      try {
        let res = await service.get(`/v1/affiliate/courses`);
        this.courseOptions = [];
        this.courseOptions = res.data.data.map((course) => {
          return {
            ...course,
            value: `${course.courseId}`,
            text: course.courseName,
          };
        });
      } catch (err) {
        console.log("fetchCourseOptions error!");
        console.log(err.response);
      }
    },
    async fetchPublisherOptions() {
      try {
        let res = await service.get(`/v1/affiliate/publishers`);
        this.publisherOptions = [];
        this.publisherOptions = res.data.data.map((publisher) => {
          return {
            ...publisher,
            value: `${publisher.publisherId}`,
            text: publisher.publisherName,
          };
        });
      } catch (err) {
        console.log("fetchPublisherOptions error!");
        console.log(err.response);
      }
    },
    createdNewLinkHandler() {
      this.$emit("newLinkCreated");
    },
    addModalCloseHandler() {
      this.addModalShow = false;
    },
  },
};
</script>

<style scoped>
.copy-icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
@media (max-width: 576px) {
  .create-new-link-button {
    width: 100%;
    margin-top: 10px;
  }
}
</style>

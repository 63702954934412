<template>
  <div>
    <b-form-group
      class="font-weight-bold"
      id="input-1-group"
      label="Url จาก third party"
      label-for="url-input"
    >
      <b-form-input id="url-input" type="url" v-model="url" required />
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FromThirdParty",
  computed: {
    url: {
      get() {
        return this.getThirdURL();
      },
      set(newUrl) {
        return this.setThirdURL(newUrl);
      }
    }
  },
  methods: {
    ...mapActions("LiveForm", ["setThirdURL"]),
    ...mapGetters("LiveForm", ["getThirdURL"])
  }
};
</script>

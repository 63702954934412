<script type="text/babel">
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  extends: Line,
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return value === 0 ? "ยังไม่มีผู้สอบ" : `${value} คน`;
                }
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters("Company", ["participationData"]),
    datacollection() {
      return {
        labels: [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค."
        ],
        datasets: [
          {
            label: "จำนวนผู้สอบ",
            pointBorderColor: "#FFB12E",
            borderColor: "#FFB12E",
            fill: false,
            data: _.toArray(this.participationData)
          }
        ]
      };
    }
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  }
};
</script>

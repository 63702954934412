<template>
  <li class="dropdown_menu" :class="mainAffiliateListCssObj">
    <a v-b-toggle.affiliateCollapse @click.prevent="toggleCollapse">
      <b-icon icon="link45deg"></b-icon>

      <span class="link-title menu_hide ml-1">Affiliate Program</span>
      <span class="fa arrow menu_hide"></span>
    </a>

    <b-collapse id="affiliateCollapse" :visible="false" accordion="accordion">
      <main-affiliate-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainAffiliateCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import _ from "lodash";

export default {
  components: {
    MainAffiliateCollapseMenu,
  },
  data() {
    return {
      isActive: false,
      collapseList: [],
    };
  },
  computed: {
    mainAffiliateListCssObj() {
      return {
        active: this.isActive,
      };
    }
  },
  mounted() {
    this.checkCanAccessAffiliateLink();
    // this.checkCanAccessAffiliateRevenue();
  },
  methods: {
    toggleCollapse() {
      // this.isActive = !this.isActive
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
    checkCanAccessAffiliateLink() {
      let permission = true;
      if (permission)
        this.collapseList.push({
          title: "Affiliate Links",
          to: {
            name: "affiliate.link",
          },
        });
    },
    checkCanAccessAffiliateRevenue() {
      let permission = true;
      if (permission)
        this.collapseList.push({
          title: "ส่วนแบ่งรายได้",
          to: {
            name: "affiliate.revenue",
          },
        });
    },
  },
};
</script>

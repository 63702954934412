<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader :title="'ผู้เรียนที่ยังไม่เริ่มเรียน คอร์ส '+courseInfo.c_name"></PageHeader>
      <b-row>
        <b-col cols="12">
          <studentNotStarted
            :apiRoute="
              `/v1/analytics/courses/getCourseStudentNotStarted?cId=${cId}&template=A`
            "
            :apiRoute2="
              `/v1/analytics/courses/getCourseStudentActive?cId=${cId}`
            "
            :apiCourseInfoRoute="`/v1/analytics/courses/getCourseInfo?cId=${cId}`"
            :perPage="20"
          ></studentNotStarted>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import studentNotStarted from "./panel/CourseStudentNotStartedPanel";
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
export default {
  data() {
    return {
      cId: this.$route.params.cId,
      courseInfo: null
    };
  },
  components: {
    PageHeader,
    studentNotStarted,
  },
  async mounted() {
     this.isBusy = true;
    let res = await api.get(`/v1/analytics/courses/getCourseInfo?cId=${this.cId}`);
    this.courseInfo = res.data;
    setTimeout(() => {
       this.isBusy = false;
    }, 1 * 1000);
  },
};
</script>
<style scoped></style>

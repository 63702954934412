<template>
  <layout>
    <template v-slot:header>จำนวนผู้สมัคร</template>
    <template v-slot:body>
      <bar-chart
        :chart-data="data"
        :options="options"
        :height="300"
      ></bar-chart>
      <br>
      <div style="text-align: center" v-if="checkData">
        ไม่มีข้อมูล
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/pages/analytics/shared/Layout.vue';
import BarChart from '@/components/charts/BarChart.js';
import api from '@/services/api.js';
import chartMockMixin from '@/mixins/chart-mock-mixin.js';
export default {
  mixins: [chartMockMixin],
  components: {
    BarChart,
    Layout,
  },
  props: ['apiRoute'],
  data() {
    return {
      result: {},
      data: {},
      checkData: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20,
                min: 0,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.checkmockBarChart(res.data);
      this.checkData = this.label;
      this.data = {
        labels: [
          'มกราคม',
          'กุมภาพันธ์',
          'มีนาคม',
          'เมษายน',
          'พฤษภาคม',
          'มิถุนายน',
          'กรกฏาคม',
          'สิงหาคม',
          'กันยายน',
          'ตุลาคม',
          'พฤศจิกายน',
          'ธันวาคม',
        ],
        datasets: [
          {
            // Name the series
            data: Object.values(this.result).map((el) => el), // Specify the data values array

            label: '',
            // Add custom color border (Line)
            backgroundColor: this.backgroundColor,
          },
        ],
      };
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped></style>

import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    courseGroupItems: [],
    courseGroupInfo: {
      courseGroupId: null,
      companyId: null,
      courseGroupName: "",
      courseGroupShortDescription: "",
      courseGroupDuration: null,
      courseGroupPrequisiteFlag: null
    },
    courseGroupCourses: []
  },
  mutations: {
    INIT() {},
    SET_COURSE_GROUP_ITEMS(state, data) {
      state.courseGroupItems = data;
    },
    SET_COURSE_GROUP_INFO(state, data) {
      state.courseGroupInfo = data;
    },
    SET_COURSE_GROUP_COURSES(state, data) {
      state.courseGroupCourses = data;
    }
  },
  actions: {
    init(context) {
      context.commit("INIT");
    },

    async fetchCourseGroupItems(context, params) {
      return await api
        .get(`/v1/course-groups/companies/${params.companyId}`)
        .then(({ data }) => {
          context.commit("SET_COURSE_GROUP_ITEMS", data.data);
        })
        .catch(({ errors }) => {
          console.error("fetchCourseGroupItems", errors);
        });
    },

    async getCourseGroupInfo(context, params) {
      return await api
        .get(`/v1/course-groups/${params.courseGroupId}`)
        .then(({ data }) => {
          context.commit("SET_COURSE_GROUP_INFO", data.info);
          context.commit("SET_COURSE_GROUP_COURSES", data.courses);
        })
        .catch(({ errors }) => {
          console.error("getCourseGroupInfo", errors);
        });
    },

    async createCourseGroup(context, companyId) {
      return await api.post(`/v1/course-groups`, {
        cgName: "New Course Group " + new Date().getTime(),
        comId: companyId
      });
    },

    async updateCourseGroupInfo(context, data) {
      return await api
        .put(`/v1/course-groups/${data.courseGroupId}`, {
          cgId: data.courseGroupId,
          cgName: data.courseGroupName,
          cgShortDescription: data.courseGroupShortDescription || "-",
          cgPrerequisiteFlag: data.courseGroupPrequisiteFlag
        })
        .then(() => {
          context.commit("SET_COURSE_GROUP_INFO", data);
        });
    },

    async deleteCourseGroup(context, courseGroupId) {
      return await api.delete(`/v1/course-groups/${courseGroupId}`);
    },

    async addCourseIntoCourseGroup(context, params) {
      return await api.post(
        `/v1/course-groups/${params.courseGroupId}/course/${params.courseId}`
      );
    },

    async removeCourseIntoCourseGroup(context, params) {
      return await api.delete(
        `/v1/course-groups/${params.courseGroupId}/course/${params.courseId}`
      );
    },

    async reorderCourseInCourseGroup(context, payloads) {
      return await api.put(
        `/v1/course-groups/${payloads.courseGroupId}/reorder`,
        {
          selectedCourseId: payloads.selectedCourseId,
          moveToAfterCourseId: payloads.moveToAfterCourseId || null
        }
      );
    },

    async setCoursePrerequisiteMode(context, payloads) {
      return await api.patch(
        `/v1/course-groups/${payloads.courseGroupId}/course/${payloads.courseId}`,
        {
          mode: payloads.mode
        }
      );
    }
  },
  getters: {
    courseGroupItems(state) {
      return state.courseGroupItems;
    },
    courseGroupInfo(state) {
      return state.courseGroupInfo;
    },
    courseGroupCourses(state) {
      return state.courseGroupCourses;
    }
  }
};

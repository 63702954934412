const exams = (state) => {
  return state.exams;
};

const exam = (state) => {
  return state.exam;
};

const answer = (state) => {
  return state.answer;
};

const allAnswer = (state) => {
  return state.allAnswer;
};

const tempSection = (state) => {
  return state.tempSection;
};

const tempQuestion = (state) => {
  return state.tempQuestion;
};

const tempChoice = (state) => {
  return state.tempChoice;
};

const collapsedQuestions = (state) => {
  return state.collapsedQuestions;
};

const hasAnswers = (state) => {
  return state.hasAnswers;
};

const solutions = (state) => {
  return state.solutions;
};

const questionCount = (state) => {
  return state.questionCount;
}

const sectionCount = (state) => {
  return state.sectionCount;
}

export default {
  exams,
  exam,
  answer,
  allAnswer,
  tempSection,
  tempQuestion,
  tempChoice,
  collapsedQuestions,
  hasAnswers,
  solutions,
  sectionCount,
  questionCount
};

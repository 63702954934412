<template>
  <div v-if="IsTutorAdmin || isOrgAdmin">
    <PageHeader title="จัดการกลุ่มผู้เรียน" icon="users"></PageHeader>

    <b-container fluid="xl">
      <div class="pt-5">
        <div class="inner bg-container">
          <div class="card card-csq no-shadow">
            <div class="card-header border-bottom-0">
              <tabs></tabs>
            </div>
            <div class="card-body pt-4">
              <b-row>
                <b-col sm="12" md="6" order-md="2" class="text-right mb-2">
                  <b-button to="groups/create" variant="primary">
                    <b-icon-plus /> เพิ่มกลุ่ม
                  </b-button>
                </b-col>
                <b-col>
                  <SearchBox
                    :title="`พิมพ์ชื่อกลุ่มผู้เรียนที่ต้องการค้นหา`"
                    @typed="getSearchKeyword"
                  />
                </b-col>
              </b-row>

              <div class="table-responsive table-hover mt-3">
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th>
                      <input type="checkbox" class="checkbox">
                    </th>-->
                      <th>ชื่อกลุ่ม</th>
                      <th>จำนวนผู้เรียนในกลุ่ม</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                    <tr v-for="(group, index) in groups.data" :key="index">
                      <!-- <td class="text-center" width="10">
                      <input type="checkbox" class="checkbox">
                    </td>-->
                      <td>
                        <router-link
                          :to="{
                            name: 'group.edit',
                            params: { id: group.id },
                          }"
                          class="text-csq"
                          >{{ group.name }}</router-link
                        >
                      </td>
                      <td>
                        {{ group.membersCount | formatNumber }}
                      </td>
                      <td>
                        <b-button size="sm" variant="outline-danger" @click="confirmRemoveGroup(group.id, group.name)">
                          <i class="fa fa-trash"  aria-hidden="true"></i> ลบกลุ่มผู้เรียน
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="4">
                      <div class="spinner-border text-csq" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="row mt-3">
                <div class="col-lg-4">
                  <form class="form-inline">
                    <div class="form-group mb-2">
                      <label for="staticEmail2">แสดง</label>
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <select
                        v-model="itemsLimit"
                        name
                        @change="changeLimit($event)"
                        id
                        class="form-control form-control-sm border-0 bg-light"
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <label for="inputPassword2"
                        >/
                        {{ groups.meta.total | formatNumber }}
                        รายการ</label
                      >
                    </div>
                  </form>
                </div>
                <div class="col-lg-8">
                  <nav class="float-right" aria-label="Page navigation example">
                    <pagination
                      :data="groups"
                      :limit="5"
                      class="pagination pagination-csq pagination-sm rounded-0"
                      @pagination-change-page="getResults"
                    >
                      <span slot="prev-nav">ก่อนหน้า</span>
                      <span slot="next-nav">ถัดไป</span>
                    </pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader.vue"
import SearchBox from "../../shared/SearchBox";
import Pagination from "laravel-vue-pagination";
import Numeral from "numeral";
import api from "@/services/api";
import Tabs from "../member-management/shared/Tabs";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    PageHeader,
    SearchBox,
    Pagination,
    Tabs,
  },

  mixins: [AuthMixin],

  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    },
  },
  props: ["company"],

  data() {
    return {
      isLoading: true,
      q: "",
      itemsLimit: 10,
      group: {
        name,
      },
      groups: {
        data: [],
        links: {
          first: null,
          last: null,
          prev: null,
          next: null,
        },
        meta: {
          current_page: 0,
          from: 0,
          last_page: 0,
          path: null,
          per_page: 0,
          to: 0,
          total: 0,
        },
      },
      groupSelected: {},
    };
  },

  created() {
    this.getResults();
  },

  methods: {
    fetch(params = "") {
      api
        .get(`/v1/companies/${this.company.id}/member-groups${params}`)
        .catch(() => {})
        .then(({ data }) => {
          this.groups = data;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          if (response.status === 403)
            this.$router.push({
              name: "error",
              params: {
                id: this.company.url,
              },
            });
        });
    },

    getResults(page = 1) {
      this.isLoading = true;
      this.fetch(`?limit=${this.itemsLimit}&page=${page}&q=${this.q}`);
    },

    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },

    changeLimit(event) {
      this.itemsLimit = event.target.value;
      this.getResults();
    },

    confirmRemoveGroup(groupId, groupName)
    {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการลบกลุ่มผู้เรียนชื่อ ${groupName} ใช่หรือไม่?`,
          {
            title: "ยืนยันการลบกลุ่มผู้เรียน",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "ลบกลุ่มผู้เรียน",
            cancelTitle: "ยกเลิก",
            cancelVariant: "light",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) this.removeGroup(groupId, groupName);
      });
    },

    async removeGroup(groupId, groupName) {
      let title = "ไม่สำเร็จ";
      let message = `ลบกลุ่มผู้เรียน ${groupName} ไม่สำเร็จ`;
      let status = "danger";

      const { data } = await api.put(`/v1/companies/${this.company.id}/member-groups/${groupId}`);

      if (data.g_deletedAt != null) {
        title = "สำเร็จ";
        message = `ลบกลุ่มผู้เรียน ${groupName} เรียบร้อยแล้ว`;
        status = "success";
      }

      this.$bvToast.toast(message, {
        title: title,
        variant: status,
        solid: true,
      });

      this.getResults();
    },
  },
};
</script>

import Cookies from "js-cookie";
import axios from "axios";
import authConfig from "../config/auth";

const accessToken = Cookies.get(authConfig.ACCESS_TOKEN_COOKIE);
const baseURL = authConfig.AUTH_SERVICE_API_URL;

const client = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + accessToken,
    ComId: localStorage.getItem("ComId")
  }
});

client.defaults.headers.common[authConfig.AUTH_SERVICE_TOKEN_KEY] =
  authConfig.AUTH_SERVICE_TOKEN_VALUE;

export default {
  async authen(payloads = {}) {
    return await client.post("login", payloads);
  },
  async authorize(payload) {
    return await client.post("authorize", {
      roles: payload.roles || null,
      permissions: payload.permissions || null,
      companyId: localStorage.getItem("ComId"),
      requireAll: false
    });
  },
  async hasPermissions(permsRequired) {
    const response = await this.authorize({
      permissions: permsRequired
    });
    const perms = await response.data?.data.authorized.permissions;
    return perms ? Object.values(perms).includes(true) : false;
  }
};

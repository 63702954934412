<template>
  <div class="outer">
    <div class="inner bg-container">
      <b-container fluid>
        <b-row mb-5 align-content-space-between>
          <b-col md="5">
            <ExamTakerProfileDetail />
          </b-col>
          <b-col md="3">
            <ExamTakerOverallScore />
          </b-col>
          <b-col md="4">
            <ExamTakerAnswerDetails />
          </b-col>
        </b-row>
        <b-row mb-5 align-content-space-between>
          <b-col>
            <ExamTakerAnswerTable />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ExamTakerOverallScore from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerOverallScore.vue";
import ExamTakerAnswerDetails from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerAnswerDetails.vue";
import ExamTakerAnswerTable from "@/components/pages/exam-analytics/exam-taker-details/exam-taker-answer-table/ExamTakerAnswerTable.vue";
import ExamTakerProfileDetail from "@/components/pages/exam-analytics/exam-taker-details/ExamTakerProfileDetail.vue";

export default {
  name: "ExamTakerDetailsContent",
  components: {
    ExamTakerOverallScore,
    ExamTakerAnswerDetails,
    ExamTakerAnswerTable,
    ExamTakerProfileDetail
  }
};
</script>

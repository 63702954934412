<template>
  <b-card
    :class="$options.name"
    title="สัดส่วนคำตอบ"
    class="text-center pie-grade"
  >
    <div :class="`${$options.name}__body`">
      <template v-if="examStatPending">
        <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> กำลังโหลด
      </template>
      <template v-else>
        <ExamScoreSummaryChart :height="chartHeight" :dataset="dataset" />
      </template>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import ExamScoreSummaryChart from "@/components/pages/exam-analytics/exam-insight/charts/ExamScoreSummaryChart.vue";

export default {
  name: "ExamScoreSummary",
  components: {
    ExamScoreSummaryChart
  },
  data() {
    return {
      chartHeight: 109
    };
  },
  computed: {
    ...mapGetters("Company", ["examStatPending", "examStatData"]),
    dataset() {
      let {
        avgPercentCorrectCount,
        avgPercentIncorrectCount,
        avgPercentBlankCount
      } = this.examStatData;
      return [
        avgPercentCorrectCount,
        avgPercentIncorrectCount,
        avgPercentBlankCount
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.pie-grade {
  padding-top: 2em;
}
.card {
  margin-bottom: 1em;
}
</style>

import api from "../../../services/api";
import videoService from "../../../services/video-service";

export default {
  namespaced: true,
  state: {
    dataError: null,
    isLoading: false,
    isLoadingInsurance: false,
    isLoadingCourseTransfer: false,
    isInsuranceSucceed: false,
    isLoadingSpecificCoupon: false,
    courseList: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        path: null,
        per_page: 10,
        to: 0,
        total: 0,
      },
    },
    moduleList: [],
    courseInfo: {
      courseId: null,
      courseName: "",
      courseSlug: "",
      courseShortDescription: "",
      courseDescription: "",
      courseThumbnail: "",
      courseVideoIntro: "",
      courseStatus: null,
      courseCode: "",
      learnDuration: null,
      learnTimeLimit: null,
      courseDifficultLevel: null,
      courseLastUpdate: null,
      courseDuration: null,
      price: null,
      promotionPrice: null,
      startSellingCourseDate: null,
      approveDate: null,
      startLearningDate: null,
      finishLearningDate: null,
      courseType: "",
      coursePrice: {
        coursePrice: null,
        exCoursePrice: null,
        curCoursePrice: null,
        coursePriceStartDate: null,
        coursePriceEndDate: null,
      },
      seasonPrice: {
        price: 0,
        name: null,
        learnDuration: 0,
        learnTimeLimit: 0,
        startDate: null,
        endDate: null,
        enable: false,
      },
      membershipPrice: [
        {
          price: 0,
          promotionPrice: 0,
          level: 1,
        },
      ],
      tfac: {
        accAccHour: 0,
        accOtherHour: 0,
        cpaAccHour: 0,
        cpaOtherHour: 0,
        cpdCourseCode: null,
      },
      courseOwner: {
        ownerId: null,
        ownerName: "",
      },
      courseTags: [],
    },
    courseModuleList: [],
    courseMaterialList: [],
    hasQuizLecturePretestFlag: true,
    hasQuizLecturePosttestFlag: false,
    updated: [],
    uploadPercentage: 0,
    uploadVideoLecturePercentage: [],
    examModuleAndCourseAndLectures: [],
    quizLectureList: [],
    insuranceCourse: {
      typeName: null,
    },
    courseTransferOrder: [],
    courseTransferOrderDetails: [],
    bankTransferList: [],
    coursePromotions: [],
    coursePromotionsGroup: [],
    tabIndex: 0,
    couponList: [],
    specificCoupon: []
  },
  mutations: {
    INIT() {},
    SET_VIDEO_ERROR(state, textError) {
      state.dataError = textError;
    },
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status === null ? !state.isLoading : status;
    },

    SET_LOADING_INSURANCE_STATUS(state, status) {
      state.isLoadingInsurance =
        status === null ? !state.isLoadingInsurance : status;
    },

    SET_LOADING_COURSE_TRANSFER(state, status) {
      state.isLoadingCourseTransfer =
        status === null ? !state.isLoadingCourseTransfer : status;
    },

    SET_LOADING_SPECIFIC_COUPON(state, status) {
      state.isLoadingSpecificCoupon = status === null ? !state.isLoadingSpecificCoupon : status
    },

    SET_INSURANCE_SUCCEED_STATUS(state, status) {
      state.isInsuranceSucceed =
        status === null ? !state.isInsuranceSucceed : status;
    },

    SET_COURSE_LIST(state, data) {
      state.courseList = data;
    },

    SET_MOUDULE_LIST(state, data) {
      state.moduleList = data;
    },
    SET_COURSE_INFO(state, data) {
      state.courseInfo = data;
    },
    SET_COURSE_INFO_ATTRIBUTE(state, data) {
      const attributes = Object.keys(data);
      state.courseInfo[attributes[0]] = data[attributes[0]];
    },
    SET_COURSE_PRICE(state, data) {
      state.courseInfo.coursePrice = data;
    },
    SET_COURSE_TAGS(state, data) {
      state.courseInfo.courseTags = data;
    },
    SET_COURSE_ATTRIBUTE_UPDATED(state, data) {
      state.updated = data.updated;
    },
    SET_COURSE_MODULE_LIST(state, data) {
      const pretestIndex = data.findIndex((item) => {
        return (
          item.lectures.findIndex(
            (lecture) => lecture.quizLectureFlag === "PRETEST"
          ) !== -1
        );
      });
      const posttestIndex = data.findIndex((item) => {
        return (
          item.lectures.findIndex(
            (lecture) => lecture.quizLectureFlag === "POSTTEST"
          ) !== -1
        );
      });

      state.hasQuizLecturePretestFlag = pretestIndex !== -1;
      state.hasQuizLecturePosttestFlag = posttestIndex !== -1;
      state.courseModuleList = data;
    },
    SET_COURSE_MATERIAL_LIST(state, data) {
      state.courseMaterialList = data;
    },
    SET_UPLOAD_PERCENTAGE(state, data) {
      state.uploadPercentage = data;
    },
    SET_UPLOAD_VIDEO_LECTURE_PERCENTAGE(state, data) {
      const index = state.uploadVideoLecturePercentage.findIndex(
        (item) => item.lectureId === data.lectureId
      );

      if (index !== -1) {
        state.uploadVideoLecturePercentage.splice(index, 1);
      }

      state.uploadVideoLecturePercentage.push(data);
      // state.uploadVideoLecturePercentage[data.lectureId] = data.percent
    },
    SET_COURSE_AND_MODULE_BY_EXAM(state, data) {
      state.examModuleAndCourseAndLectures = data;
    },
    SET_LECTURE_BY_COURES_ID(state, data) {
      state.quizLectureList = data;
    },

    SET_CREATE_LECTURE(state, payload) {
      let data = {
        examId: null,
        lecName: payload.data.lec_name,
        lecId: payload.data.lec_id,
        moduleId: payload.moduleId,
      };
      state.quizLectureList.push(data);
    },

    SET_INSURANCE_COURSE(state, data) {
      for (let value of data) {
        if (value.insuranceCourseType === "LIFE_AGENT") {
          value.typeName = "ตัวแทนประกันชีวิต";
        } else if (value.insuranceCourseType === "LIFE_BROKER") {
          value.typeName = "นายหน้าประกันชีวิต";
        } else if (value.insuranceCourseType === "NONLIFE_AGENT") {
          value.typeName = "ตัวแทนประกันวินาศภัย";
        } else if (value.insuranceCourseType === "NONLIFE_BROKER") {
          value.typeName = "นายหน้าประกันวินาศภัย";
        }
      }
      state.insuranceCourse = data;
    },

    SET_COURSE_TRANSFER_ORDER(state, data) {
      state.courseTransferOrder = data;
      state.courseTransferOrder = state.courseTransferOrder.filter(
        (courseTransferOrders) =>
          courseTransferOrders.co_actualPurchaseDateTime != null
      );
    },

    SET_COURSE_TRANSFER_ORDER_DETAIL(state, data) {
      state.courseTransferOrderDetails = data[0];
    },

    SET_BANK_TRANSFER_LIST(state, data) {
      state.bankTransferList = data
    },

    SET_COURSE_PROMOTION_LIST(state, data) {
      state.coursePromotions = data;
    },
    SET_COURSE_PROMOTION_GROUP_LIST(state, data) {
      state.coursePromotionsGroup = data;
    },

    SET_TAB_INDEX(state, data) {
      state.tabIndex = data;
    },

    SET_COUPON_LIST(state, data) {
      state.couponList = data;
    },
    SET_SPECIFIC_COUPON(state, data) {
      state.specificCoupon = data;
    }
  },
  getters: {
    dataError(state) {
      return state.dataError;
    },
    isLoading(state) {
      return state.isLoading;
    },

    isLoadingInsurance(state) {
      return state.isLoadingInsurance;
    },

    isInsuranceSucceed(state) {
      return state.isInsuranceSucceed;
    },

    isLoadingCourseTransfer(state) {
      return state.isLoadingCourseTransfer;
    },

    isLoadingSpecificCoupon(state) {
      return state.isLoadingSpecificCoupon;
    },

    courseList(state) {
      return state.courseList;
    },

    moduleList(state) {
      return state.moduleList;
    },
    courseModuleList(state) {
      return state.courseModuleList;
    },
    courseMaterialList(state) {
      return state.courseMaterialList;
    },
    courseInfo(state) {
      return state.courseInfo;
    },
    courseAttributeUpdated(state) {
      return state.updated;
    },
    uploadPercentage(state) {
      return state.uploadPercentage;
    },
    uploadVideoLecturePercentage(state) {
      return state.uploadVideoLecturePercentage;
    },
    hasQuizLecturePretestFlag(state) {
      return state.hasQuizLecturePretestFlag;
    },
    hasQuizLecturePosttestFlag(state) {
      return state.hasQuizLecturePosttestFlag;
    },
    examModuleAndCourseAndLectures(state) {
      return state.examModuleAndCourseAndLectures;
    },
    quizLectureList(state) {
      return state.quizLectureList;
    },
    insuranceCourse(state) {
      return state.insuranceCourse;
    },
    courseTransferOrder(state) {
      return state.courseTransferOrder;
    },
    courseTransferOrderDetails(state) {
      return state.courseTransferOrderDetails;
    },
    bankTransferList(state) {
      return state.bankTransferList;
    },

    coursePromotions(state) {
      return state.coursePromotions;
    },

    coursePromotionsGroup(state) {
      return state.coursePromotionsGroup;
    },
    tabIndex(state) {
      return state.tabIndex;
    },
    couponList(state) {
      return state.couponList;
    },
    specificCoupon(state) {
      return state.specificCoupon;
    }
  },
  actions: {
    setUploadPercentage(context, percent) {
      context.commit("SET_UPLOAD_PERCENTAGE", percent);
    },
    setUploadVideoLecturePercentage(context, data) {
      context.commit("SET_UPLOAD_VIDEO_LECTURE_PERCENTAGE", data);
    },
    async fetchCourseList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/companies/${params.company_id}/courses?temp=1`, {
          params,
        })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("vuex: ", errors);
        });
    },
    async fetchCourseListEnrollment(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/companies/${params.company_id}/enrollments?temp=1`, {
          params,
        })
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("vuex: ", errors);
        });
    },
    async fetchCourseModulesList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`/v1/courses/${params.courseId}/modulesAndLectures`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_MODULE_LIST", data.data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("vuex: ", errors);
        });
    },
    async createCourse(context, payloads) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .post(`/v1/courses`, payloads)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_INFO", data.data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("createCourse", errors);
        });
    },

    async suspendCourse(context, courseId) {
      await api.put(`/v1/courses/${courseId}/suspend`);
    },

    async disableCourse(context, courseId) {
      await api.put(`/v1/courses/${courseId}/disable`);
    },

    async deleteCourse(context, courseId) {
      await api.delete(`/v1/courses/${courseId}`);
    },

    async deleteCourseIntro(context, courseId) {
      await api.put(`/v1/courses/${courseId}/delete-video-intro`);
    },

    async getCourseInfo(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(
          `/v1/courses/${params.courseId}?temp=${params.temp || 0}&excepts=${
            params.excepts
          }`
        )
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_INFO", data.data);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateCourseInfo(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${params.courseId}`, params.attribute)
        .then((data) => {
          context.commit("SET_COURSE_INFO_ATTRIBUTE", params.attribute);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", data.data);
        })
        .catch((error) => {
          context.commit("SET_LOADING_STATUS", false);
          throw error.response.data.errors;
        });
      context.commit("SET_LOADING_STATUS", false);
    },

    async updateBankTransferDetail(context, params) {
      await api.put(`/v1/bank-transfer-order-details/${params.orderId}`, params.body)
    },

    async updateTfacQuarter(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${params.courseId}/tfacQuarter`, params.attribute)
        .then((data) => {
          context.commit("SET_COURSE_INFO_ATTRIBUTE", params.attribute);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", data.data);
        })
        .catch((error) => {
          context.commit("SET_LOADING_STATUS", false);
          throw error.response.data.errors;
        });
      context.commit("SET_LOADING_STATUS", false);
    },

    async updateAttributeCourseInfo(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${params.courseId}/attribute`, params.attribute)
        .then((data) => {
          context.commit("SET_COURSE_INFO_ATTRIBUTE", params.attribute);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", data.data);
        })
        .catch((error) => {
          context.commit("SET_LOADING_STATUS", false);
          throw error.response.data.errors;
        });
      context.commit("SET_LOADING_STATUS", false);
    },

    async updateTfacCourse(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/tfac`, data.payloads)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch((error) => {
          context.commit("SET_LOADING_STATUS", false);
          throw error.response.data.errors;
        });
    },
    async updateCoursePriceMemberships(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/membership-prices`, {
          membershipPrices: data.membershipPrices,
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateCoursePriceSeason(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/season-price`, data.payloads)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_PRICE", data.payloads);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateCoursePrice(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/prices`, data.payloads)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_PRICE", data.payloads);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateCourseTags(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .post(`/v1/courses/${data.courseId}/tags`, {
          tagIds: data.tags,
        })
        .then((response) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_TAGS", response.data.data);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { courseTags: true },
          });
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateCourseOwner(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/owners`, {
          memberId: data.memberId,
        })
        .then((response) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_INFO_ATTRIBUTE", {
            courseOwner: response.data.data,
          });
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { courseOwner: true },
          });
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },
    async updateQuizLectureFlag(context, { lectureId, flag }) {
      await api.put(`/v1/lectures/${lectureId}/quiz`, {
        flag,
      });
    },
    async createModule(context, courseId) {
      await api.post(`/v1/courses/${courseId}/modules`);
    },

    async fetchModuleList(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses/${courseId}/modules`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_MOUDULE_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("vuex: ", errors);
        });
    },

    async updateModule(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/modules/${data.moduleId}`, {
          moduleName: data.moduleName,
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },

    async reorderModule(context, data) {
      await api.put(`/v1/courses/${data.courseId}/modules/reorder`, {
        moduleOrder: data.moduleOrder,
      });
    },

    async deleteModule(context, data) {
      await api.delete(`/v1/courses/${data.courseId}/modules`, {
        moduleId: data.moduleId,
        moduleOrder: [],
      });
    },

    async createExamtoLecture(context, request) {
      await api.post(`/v1/lecture/content`, request.body);
    },

    async getCourseAndModuleByExamId(context, examId) {
      await api.get(`/v1/exam/${examId}/course`).then(({ data }) => {
        context.commit("SET_COURSE_AND_MODULE_BY_EXAM", data);
      });
    },
    async deleteQuizLecture(context, request) {
      await api.delete(
        `/v1/quiz-lecture/${request.body.lectureId}/courses/${request.body.courseId}`
      );
    },

    async getLectureByCourseId(context, data) {
      await api
        .get(`/v1/courses/${data.courseId}/quiz-lectures`)
        .then(({ data }) => {
          context.commit("SET_LECTURE_BY_COURES_ID", data);
        });
    },

    async createLectureQuiz(context, request) {
      const { data } = await api.post(
        `/v1/courses/${request.courseId}/lecture`,
        request.body
      );

      let payload = {
        data: data,
        moduleId: request.body.modId,
      };
      context.commit("SET_CREATE_LECTURE", payload);
    },

    /**
     * Lecture
     */
    async createLecture(context, payload) {
      await api.post(
        `/v1/courses/${payload.courseId}/module/${payload.moduleId}/lectures`
      );
    },

    async updateLecture(context, payload) {
      context.commit("SET_LOADING_STATUS", true);

      return await api
        .put(`/v1/courses/${payload.courseId}/lectures/${payload.lectureId}`, {
          lectureName: payload.lectureName,
          lectureFreeFlag: payload.lectureFreeFlag,
          lectureType: payload.lectureType,
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("updateLecture", response);
        });
    },

    async reorderLecture(context, payload) {
      await api.put(`/v1/courses/${payload.courseId}/lectures/reorder`, {
        selectedLectureId: payload.lectureId,
        selectedModuleId: payload.moduleId,
        moveTo: {
          moduleId: payload.to.moduleId,
          afterLectureId: payload.to.afterLectureId,
        },
      });
    },

    async deleteLecture(context, payload) {
      await api.delete(`/v1/lectures/${payload.lectureId}`, {
        moduleId: payload.moduleId,
        courseId: payload.courseId,
      });
    },

    /**
     * Course Material
     */
    async fetchCourseMaterialList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses/${params.courseId}/courseMaterials`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_MATERIAL_LIST", data.data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("fetchCourseMaterialList: ", errors);
        });
    },

    async createCourseMaterial(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      return await videoService
        .uploadCourseMaterial(`materials`, data)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("createCourseMaterial: ", errors);
        });
    },

    async updateCourseMaterial(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .put(`/v1/courses/${data.courseId}/materials/${data.materialId}`, {
          materialName: data.materialName,
          materialDescription: data.materialDescription,
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("updateCourseMaterial: ", response);
        });
    },

    async orderCourseMaterial(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .post(`/v1/courses/${data.courseId}/materials/reorder`, {
          materials: data.file,
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("orderCourseMaterial: ", response);
        });
    },

    async deleteCourseMaterial(context, data) {
      await api.delete(`/v1/courses/${data.courseId}/materials`, {
        lmId: data.materialId,
      });
    },

    /**
     * Video
     */
    async updateCourseYoutubeIntro(context, data) {
      context.commit("SET_LOADING_STATUS", true);

      await videoService
        .post(`youtube-videos-intro`, {
          cId: data.cId,
          comId: data.comId,
          youtubeLink: data.youtubeLink,
          accessToken: localStorage.getItem("token"),
        })
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
        })
        .catch(() => {
          context.commit("SET_LOADING_STATUS", false);
        });
    },

    async uploadLectureVideo(context, data) {
      await videoService
        .uploadVideoFile(`videos`, data)
        .then(() => {
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { videoLecture: true },
          });
        })
        .catch(({ response }) => {
          console.error("uploadLectureVideo: ", response);
        });
    },

    async uploadLectureSlide(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await videoService
        .uploadSlideFile(`slides`, data)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { slideLecture: true },
          });
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("uploadLectureSlide: ", response);
        });
    },

    async uploadVideoIntroFile(context, data) {
      await videoService
        .uploadVideoIntroFile(`videos-intro`, data)
        .then(() => {
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { videoFile: true },
          });
          context.commit("SET_VIDEO_ERROR", null);
        })
        .catch(({ response }) => {
          context.commit("SET_VIDEO_ERROR", response.status);
          context.commit("SET_LOADING_STATUS", false);
          console.error("uploadVideoIntroFile: ", response);
        });
    },

    async uploadCourseThumbnail(context, data) {
      context.commit("SET_LOADING_STATUS", true);
      await videoService
        .uploadCourseThumbnail(`courses`, data)
        .then(() => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_ATTRIBUTE_UPDATED", {
            updated: { courseThumbnail: true },
          });
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("uploadLectureSlide: ", response);
        });
    },

    async getVideoProcessStatus(context, data) {
      return await videoService.get("videos", {
        params: {
          lecId: data.lecId,
        },
        data: JSON.stringify({
          cId: data.cId,
          comId: data.comId,
          accessToken: localStorage.getItem("token"),
        }),
      });
    },
    async publishCourse(context, courseId) {
      return await api.put(`/v1/courses/${courseId}/publish`);
    },

    setCourseInfoAttribute(context, data) {
      context.commit("SET_COURSE_INFO_ATTRIBUTE", data);
    },
    init(context) {
      context.commit("INIT");
    },

    /* upload embed web  */

    async createWebEmbedtoLecture(context, request) {
      await api.post(`/v1/embed-web-lecture`, request.body);
    },

    /* insurance */

    async getInsuranceCourse(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return api
        .get(`/v1/courses/${courseId}/insurances`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_INSURANCE_COURSE", data.data);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },

    async createInsuranceCourse(context, request) {
      await api.post(
        `/v1/courses/${request.courseId}/create-insurance`,
        request
      );
    },

    async delectInsuranceCourse(context, request) {
      await api.delete(
        `/v1/courses/${request.courseId}/delete-insurance`,
        request
      );
    },

    async updateInsuranceCourse(context, request) {
      if (request.page === "rules") {
        context.commit("SET_INSURANCE_SUCCEED_STATUS", false);
      }
      context.commit("SET_LOADING_INSURANCE_STATUS", true);
      await api
        .put(
          `/v1/courses/${request.body.courseId}/update-insurance`,
          request.body
        )
        .then(() => {
          context.commit("SET_LOADING_INSURANCE_STATUS", false);
          if (request.page === "rules") {
            context.commit("SET_INSURANCE_SUCCEED_STATUS", true);
          }
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_INSURANCE_STATUS", false);
          console.log(response);
        });
    },

    async getCourseTransferOrder(context) {
      context.commit("SET_LOADING_STATUS", true);
      await api
        .get(`/v1/bank-transfer-order`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_TRANSFER_ORDER", data);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log(response);
        });
    },

    async getCourseTransferOrderDetails(context, orderId) {
      context.commit("SET_LOADING_COURSE_TRANSFER", true);
      await api
        .get(`/v1/bank-transfer-order-details/${orderId}`)
        .then(({ data }) => {
          context.commit("SET_LOADING_COURSE_TRANSFER", false);
          context.commit("SET_COURSE_TRANSFER_ORDER_DETAIL", data.data);
        })
        .catch(({ response }) => {
          context.commit("SET_LOADING_COURSE_TRANSFER", false);
          console.log(response);
        });
    },

    async getBankTransferList(context, comId) {
      await api.get(`/v1/companies/${comId}/payment-account`)
      .then(({ data }) => {
        context.commit("SET_BANK_TRANSFER_LIST", data);
      })
      .catch(({ response }) => {
        console.log(response)
      })
    },

    async confirmOrderPaymentTransfer(context, orderId) {
      await api.put(`/v1/confirm-order`, {
        orderId: orderId,
      });
    },

    async cancelOrderPaymentTransfer(context, orderId) {
      await api.put(`/v1/cancel-order`, {
        orderId: orderId,
      });
    },

    async getPromotionList(context, search) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/company-conditional-discount`, {})
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          var result;
          if (search) {
            result = data.filter((i) => i.ccd_name.includes(search));
          } else {
            result = data;
          }
          context.commit("SET_COURSE_PROMOTION_LIST", result);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("errors ", errors);
        });
    },

    async createPromotions(context, request) {
      await api.post(`/v1/company-conditional-discount`, request.body);
    },

    async updatePromotions(context, request) {
      await api.put(
        `/v1/company-conditional-discount/${request.ccd_id}`,
        request.body
      );
    },

    async ondeletePromotion(context, ccd_id) {
      await api.delete(`/v1/company-conditional-discount/${ccd_id}`);
    },

    async getPromotionsGroup(context, search) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/tag-conditional-discount`, {})
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);

          var result;
          if (search) {
            result = data.filter((i) => i.tcd_name.includes(search));
          } else {
            result = data;
          }
          context.commit("SET_COURSE_PROMOTION_GROUP_LIST", result);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.error("errors ", errors);
        });
    },

    async createPromotionsGroup(context, request) {
      await api.post(`/v1/tag-conditional-discount`, request.body);
      context.commit("SET_TAB_INDEX", 1);
    },

    async editPromotionsGroup(context, request) {
      await api.put(
        `/v1/tag-conditional-discount/${request.tcd_id}`,
        request.body
      );
      context.commit("SET_TAB_INDEX", 1);
    },
    async ondeletePromotionGroupTag(context, tcd_id) {
      await api.delete(`/v1/tag-conditional-discount/${tcd_id}`);
    },

    setTabIndexPromotion(context, tabIndex) {
      context.commit("SET_TAB_INDEX", tabIndex);
    },

    async getCouponList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/promo-code?q=${params.q}&expireDate=${params.expireDate}&status=${params.status}&page=${params.page}&limit=${params.limit}`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COUPON_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("errors ", errors);
        });
    },

    async getSpecificCoupon(context, pcId) {
      context.commit("SET_LOADING_SPECIFIC_COUPON", true);
      return await api
      .get(`/v1/promo-code/${pcId}`)
      .then(({ data }) => {
        context.commit("SET_LOADING_SPECIFIC_COUPON", false);
        context.commit("SET_SPECIFIC_COUPON", data.data[0]);
      })
      .catch(({ errors }) => {
        context.commit("SET_LOADING_SPECIFIC_COUPON", false);
        console.log("errors ", errors);
      })
    },

    async insertNewCoupon(context, params) {
      await api.post(`/v1/promo-code`, params);
    },

    async updateCoupon(context, params) {
      await api.put(`/v1/promo-code/${params.pcId}`, params.body);
    },

    async deleteCoupon(context, pcId) {
      await api.delete(`/v1/promo-code/${pcId}`);
    }
  },
};

<template>
  <div>
    <header
      class="bottom-toolbar border-top fixed-bottom"
      :class="{ 'sidebar-left-hidden': !sidebarOpened }"
    >
      <div class="main-bar p-3">
        <b-row>
          <b-col style="margin-left: 80px" xs="6" align-self="center">
            <b-button
              variant="outline-secondary mr-1"
              :to="{ name: 'course.management.index' }"
            >
              <b-icon icon="arrow-left"></b-icon>
              <span class="d-none d-sm-inline">คอร์สทั้งหมด</span>
            </b-button>
            <b-button
              v-for="(link, index) in links"
              :key="index"
              @click="changePage(link.name)"
              :pressed="isCurrentPage(link.name)"
              variant="outline-secondary mr-1"
              >{{ index + 1 }}</b-button
            >
          </b-col>
          <!-- TODO:
        <b-col align-self="center">
          <b-row no-gutters>
            <b-col cols="12" align-self="center" class="text-left px-1">
              <span class="small">ความสมบูรณ์ของคอร์ส</span>
            </b-col>
            <b-col align-self="center" class="px-1">
              <b-progress :max="100" height="10px">
                <b-progress-bar :value="25"></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
        </b-col>
          -->
          <b-col
            md="auto"
            class="d-none d-lg-inline text-center"
            align-self="center"
          >
            <b-button v-show="false" variant="outline-secondary"
              >ดูความคืบหน้า</b-button
            >

            <b-badge
              class="text-center"
              :class="course.courseStatus | statusColor(statusOptions)"
              role="alert"
              >{{ course.courseStatus | statusText(statusOptions) }}</b-badge
            >
          </b-col>
          <b-col xs="6" class="text-right" align-self="center">
            <b-button
              :href="
                `${coursePreviewUrl}/${coursePreviewName}?preview=1&token=${token}`
              "
              target="_blank"
              variant="outline-secondary mr-1"
            >
              <b-icon icon="box-arrow-in-up-right"></b-icon>
              <span class="d-none d-sm-inline">พรีวิวคอร์ส</span>
            </b-button>
            <b-button
              v-if="currentLinkIndex < links.length - 1 && isLoading || isOverlayVisible"
              variant="primary mr-1"
              disabled
            >
              <span class="d-none d-sm-inline">ขั้นตอนถัดไป</span>
              <b-icon icon="arrow-right-short"></b-icon>
            </b-button>
            <b-button
              v-else-if="currentLinkIndex < links.length - 1"
              variant="primary mr-1"
              @click="changePage(links[currentLinkIndex+1].name)"
            >
              <span class="d-none d-sm-inline">ขั้นตอนถัดไป</span>
              <b-icon icon="arrow-right-short"></b-icon>
            </b-button>
            <b-button
              v-if="
                ((currentLinkIndex <= links.length - 1 &&
                  (course.courseStatus == 'SUSPEND' ||
                    course.courseStatus == 'PUBLISH_EDIT')) ||
                  (course.courseStatus == 'DRAFT' &&
                    currentLinkIndex == links.length - 1)) && isLoading || isOverlayVisible
              "
              disabled
              variant="success"
            >
              เผยแพร่คอร์ส 
            </b-button>
            <b-button
              v-else-if="
                (currentLinkIndex <= links.length - 1 &&
                  (course.courseStatus == 'SUSPEND' ||
                    course.courseStatus == 'PUBLISH_EDIT')) ||
                  (course.courseStatus == 'DRAFT' &&
                    currentLinkIndex == links.length - 1)
              "
              variant="success"
              @click="PublishCourseModal"
            >
              เผยแพร่คอร์ส
            </b-button>
            <b-button
              v-if="
                currentLinkIndex <= links.length - 1 &&
                  course.courseStatus == 'PUBLISH' && isLoading
              "
              variant="warning"
              disabled
            >
              หยุดเผยแพร่
            </b-button>
            <b-button
              v-else-if="
                currentLinkIndex <= links.length - 1 &&
                  course.courseStatus == 'PUBLISH'
              "
              variant="warning"
              @click="confirmSuspendCourse"
            >
              หยุดเผยแพร่
            </b-button>
          </b-col>
        </b-row>
      </div>
    </header>
    <ConfirmPublishCourseModal
      :show="isShowConfirmPublishCourseModal"
      :course="course"
      @closed="isShowConfirmPublishCourseModal = false"
    />
    <AlertIncompleteModal
      :show="isShowAlertIncompleteModal"
      :message="errorMessage"
      @closed="isShowAlertIncompleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import courseMixin from '../../../../mixins/course-mixin';
import ConfirmPublishCourseModal from './ConfirmPublishCourseModal';
import AlertIncompleteModal from './AlertIncompleteModal';
import RequireFieldChecking from './RequireFieldChecking.js';

export default {
  name: 'CourseNavToolbar',
  components: { ConfirmPublishCourseModal,AlertIncompleteModal },
  mixins: [courseMixin],
  props: {
    sidebarOpened: Boolean,
    course: {
      type: Object,
      default: null,
    },
    company: { default: null, type: Object },
    isOverlayVisible: Boolean,
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      links: [
        {
          name: 'course.management.info',
          title: 'ข้อมูลคอร์ส',
        },
        {
          name: 'course.management.contents',
          title: 'เนื้อหาคอร์ส',
        },
        {
          name: 'course.management.materials',
          title: 'เอกสาร',
        },
        {
          name: 'course.management.rules',
          title: 'Rule/Cer',
        },
        {
          name: 'course.management.evaluation',
          title: 'แบบประเมิน',
        },
      ],
      token: localStorage.getItem('token'),
      currentLinkIndex: 0,
      isShowConfirmPublishCourseModal: false,
      isShowAlertIncompleteModal: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters('CourseManagement', ['courseInfo','isLoading']),
    ...mapGetters("Company",["companyInfo"],),
  },
  created() {
    this.currentLinkIndex = this.getCurrentLinkIndex();
  },
  methods: {
    ...mapActions('CourseManagement', ['publishCourse', 'suspendCourse']),

    getCurrentLinkIndex() {
      return this.links.findIndex(
        (data) => data.name === this.$router.currentRoute.name
      );
    },
    PublishCourseModal(){
      if(RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.isShowConfirmPublishCourseModal = true;
      }else{
        this.errorMessage="กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    changePage(routeName){
      if(RequireFieldChecking.checkCourseInfoRequire(this.courseInfo,this.companyInfo.id)){
        this.$router.push({
          name: routeName,
        });
      }else{
        this.errorMessage="กรุณากรอกข้อมูลคอร์สให้ครบก่อนดำเนินการต่อ";
        this.isShowAlertIncompleteModal = true;
      }
    },
    isCurrentPage(name){
      return this.$route.name == name;
    },
    confirmSuspendCourse() {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการหยุดเผยแพร่คอร์ส ${this.course.courseName} ใช่หรือไม่?`,
          {
            title: 'ยืนยันการหยุดเผยแพร่คอร์ส',
            size: 'md',
            buttonSize: 'md',
            okTitle: 'หยุดเผยแพร่',
            okVariant: 'warning',
            cancelTitle: 'ยกเลิก',
            cancelVariant: 'outline-secundary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm)
            this.suspendCourse(this.courseId).then(() => {
              this.$router.push({
                name: 'course.management.index',
              });
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  padding-left: 250px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  z-index: 80;
}
.progress-bar {
  background-color: #f99f4e;
}
.sidebar-left-hidden {
  padding-left: 0px !important;
}

.router-link-exact-active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>

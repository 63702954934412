<template>
  <div>
    <PageHeader title="คำขอลงทะเบียนใบหน้า"></PageHeader>
    <br />
    <br />
    <b-container fluid="xl">
      <layout v-if="!isTutor || IsTutorAdmin">
        <!-- <template v-slot:head>
        รายชื่อผู้สมัครเรียน
      </template> -->
        <!-- <template v-slot:body > -->
        <b-row class="pb-3">
          <b-col cols="3">
            <b-form-input
              v-model="search"
              placeholder="ค้นหารายชื่อ"
              style="padding:1rem;display:inline-block;width:100%"
            >
            </b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-select
              v-model="selected"
              :options="options"
            ></b-form-select>
          </b-col>
        </b-row>

        <b-table
          id="face-verification-table"
          :per-page="perPage"
          :current-page="currentPage"
          table-class="tbNumber text-left "
          :items="
            search == null
              ? result
              : result.filter((i) =>
                  (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
                )
          "
          :fields="fields"
          small
          sort-icon-left
          responsive
          :busy="isBusy"
          hover
        >
          <template v-slot:cell(name)="data"
            ><span>{{
              data.item && data.item.mp_firstName + " " + data.item.mp_lastName
            }}</span></template
          >
          <template v-slot:cell(email)="data">
            <span>{{ data.item.m_email ? data.item.m_email : "-" }}</span>
          </template>

          <template v-slot:cell(date)="data">
            <span>{{ data.item && data.item.created_at | thaiDate }}</span>
          </template>

          <template v-slot:cell(status)="data">
            <b-badge
              class="p-2"
              :variant="
                data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED'
                  ? 'warning'
                  : data.item.verify_status == 'APPROVED' || data.item.verify_status == 'SYSTEM_APPROVED' || data.item.verify_status == 'ADMIN_APPROVED' || data.item.verify_status == 'AUTO_APPROVED'
                  ? 'success'
                  : data.item.verify_status == 'REJECTED' && 'danger'
              "
              >{{
                data.item.verify_status == "PENDING"
                  ? "รอการตรวจสอบ"
                  : data.item.verify_status == "APPROVED" || data.item.verify_status == "ADMIN_APPROVED"
                  ? "ตรงกัน ยืนยันโดยแอดมิน"
                  : data.item.verify_status == "SYSTEM_APPROVED"
                  ? "ตรงกัน ยืนยันโดยระบบ"
                  : data.item.verify_status == "AUTO_APPROVED"
                  ? "ตรงกัน ยืนยันโดยระบบอัตโนมัติ"
                  : data.item.verify_status == "FORCE_APPROVED"
                  ? "ไม่ตรงกัน ยืนยันโดยระบบ"
                  : data.item.verify_status == "REJECTED" && "ไม่ผ่านการตรวจสอบ"
              }}</b-badge
            >
          </template>

          <template v-slot:cell(button)="data">
            <b-button
              :variant="
                data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED' ? 'primary' : 'light'
              "
              :disabled="data.item.verify_status == 'PENDING' || data.item.verify_status == 'FORCE_APPROVED'  ? false : true"
              v-b-modal="'modal-' + data.item.id"
            >
              ตรวจสอบ
            </b-button>
            <b-modal
              size="xl"
              :id="'modal-' + data.item.id"
              title="ยืนยันตัวตนผู้สมัคร"
              :hide-footer="true"
            >
              <VerifyModal :data="data.item" @posted="fetch()"> </VerifyModal>
            </b-modal>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="
            search == null
              ? rows
              : result.filter((i) =>
                  (i.mp_firstName + ' ' + i.mp_lastName).includes(search)
                ).length
          "
          :per-page="perPage"
          aria-controls="face-verification-table"
          align="end"
        ></b-pagination>
        <!-- </template> -->
      </layout>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import VerifyModal from "./FaceVerificationModal";
import api from "@/services/api.js";
import AuthMixin from "@/mixins/auth-mixin.js";
export default {
  components: {
    PageHeader,
    layout,
    VerifyModal,
  },
  mixins: [AuthMixin],
  data() {
    return {
      search: "",
      selected: "PENDING",
      options: [
        { value: "ALL", text: "สถานะทั้งหมด" },
        { value: "PENDING", text: "รอการตรวจสอบ" },
        { value: "APPROVED", text: "ผ่านการตรวจสอบแล้ว" },
        { value: "FORCE_APPROVED", text: "ไม่ตรงกัน ยืนยันโดยระบบ" },
        { value: "REJECTED", text: "ไม่ผ่านการตรวจสอบ" },
      ],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "name", label: "ชื่อผู้เรียน" },
        {
          key: "email",
          label: "อีเมล",
        },
        {
          key: "date",
          label: "วันที่สมัครเรียน",
        },
        {
          key: "status",
          label: "สถานะการสมัคร",
        },
        {
          key: "button",
          label: "ตรวจสอบรูปภาพทั้งหมด",
        },
      ],
      items: [],
    };
  },

  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.isBusy = true;
      let res = await api.get(
        `/v1/faceverify/getFaceVerifyRequest?filter=${this.selected}`
      );
      this.result = res.data;
      this.rows = this.result.length;
      this.currentPage = 1;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.items = this.$store.getters.data;
    },
  },
  watch: {
    selected() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped></style>

<template>
  <div class="mt-4 pt-4 border-top">
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between">
          <h5>
            <strong>{{ courseInfo.courseName }}</strong>
          </h5>
          <b-button
            :disabled="
              memberInfo.cer_approveFaceStatus === 'APPROVED' ||
                memberInfo.cer_status != 'COMPLETED'
            "
            @click="$emit('openModal')"
            class="px-2"
            size="sm"
            variant="success"
          >
            <b-icon class="mr-3" icon="check2-all" aria-hidden="true"></b-icon>
            ยืนยันผลการตรวจใบหน้าของคอร์สนี้
          </b-button>
        </div>
        <div class="btn"></div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between">
          <h6>
            <strong>เนื้อหาคอร์ส</strong>
          </h6>
          <b-button
            @click="toggleCollapse()"
            class="px-2"
            size="sm"
            variant="primary"
          >
            {{ buttonLabel }}
          </b-button>
        </div>

        <div class="box-lecture mt-3">
          <div class="accordion" role="tablist">
            <div
              v-for="(learningFaceItem,
              learningFaceItemIndex) in learningFaceItem"
              :key="learningFaceItemIndex"
              class="box-lecture-collapse"
            >
              <div
                class="box-lecture-collaspe-title"
                :class="[collapseValue ? 'not-collapsed' : 'collapsed']"
                v-b-toggle="'collapse-' + learningFaceItemIndex"
              >
                <b-row>
                  <b-col cols="9">
                    <h6 class="mb-0 text-lecture">
                      {{ learningFaceItem.lec_name }}
                    </h6>
                  </b-col>
                  <b-col cols="3">
                    <div class="d-flex justify-content-end">
                      <h6
                        :class="[
                          learningFaceItem.lel_approveFaceStatus === 'APPROVED'
                            ? 'text-success'
                            : 'text-danger',
                          'mb-0 mr-3',
                        ]"
                      >
                        {{
                          learningFaceItem.lel_approveFaceStatus === "APPROVED"
                            ? "ยืนยันแล้ว"
                            : "ยังไม่ได้ยืนยัน"
                        }}
                      </h6>
                      <b-icon
                        class="down"
                        icon="chevron-down"
                        aria-hidden="true"
                      ></b-icon>
                      <b-icon
                        class="up"
                        icon="chevron-up"
                        aria-hidden="true"
                      ></b-icon>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-collapse
                :id="'collapse-' + learningFaceItemIndex"
                :visible="collapseValue"
                v-model="collapseStates[learningFaceItemIndex]"
              >
                <div class="collapse-details">
                  <b-row v-if="!learningFaceItem.lec_images.length">
                    <b-col cols="12 text-center">
                      <h4>ยังไม่มีข้อมูลใบหน้า</h4>
                    </b-col>
                  </b-row>
                  <div v-else>
                    <b-row class="my-3">
                      <b-col cols="12" class="text-right">
                        <b-button
                          class="px-2"
                          size="sm"
                          :disabled="
                            learningFaceItem.lel_approveFaceStatus ===
                              'APPROVED' || memberInfo.cer_status != 'COMPLETED'
                          "
                          @click="approveLecture(learningFaceItem.lec_id)"
                        >
                          <b-icon
                            class="mr-3"
                            icon="check2"
                            aria-hidden="true"
                          ></b-icon>
                          ยืนยันผลการตรวจใบหน้าของบทเรียนนี้
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col cols="4">
                        <h6><strong>รูปภาพ</strong></h6>
                      </b-col>
                      <b-col cols="4" class="text-center">
                        <h6><strong>เวลาที่ถ่ายรูป(นาที)</strong></h6>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        <h6><strong>ระบบตรวจสอบใบหน้า</strong></h6>
                      </b-col>
                    </b-row>
                    <div class="box-face-images">
                      <b-row
                        v-for="(faceIamgesItem,
                        faceIamgesIndex) in learningFaceItem.lec_images
                          .slice()
                          .reverse()"
                        :key="faceIamgesIndex"
                        class="mb-3"
                      >
                        <b-col cols="4">
                          <div class="img">
                            <img
                              class="img-fluid"
                              :src="
                                `${baseUrl}/${faceIamgesItem.face_path}?token=${token}`
                              "
                            />
                          </div>
                        </b-col>
                        <b-col
                          cols="4"
                          class="d-flex align-items-center justify-content-center"
                        >
                          <div class="time">
                            <h6>
                              {{ secondsToHms(faceIamgesItem.learning_point) }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col
                          cols="4"
                          class="d-flex align-items-center justify-content-end"
                        >
                          <div class="d-flex align-items-center">
                            <div v-if="memberInfo.cer_status != 'COMPLETED'">
                              <b-icon
                                icon="exclamation-triangle-fill"
                                variant="warning"
                                class="mr-3"
                                v-b-tooltip.hover.bottom="
                                  'ไม่สามารถแก้ไขได้ เนื่องจากคอร์สนี้ยังเรียนไม่จบ'
                                "
                              ></b-icon>
                            </div>

                            <select
                              :disabled="
                                learningFaceItem.lel_approveFaceStatus ===
                                  'APPROVED' ||
                                  memberInfo.cer_status != 'COMPLETED'
                              "
                              :class="[
                                faceIamgesItem.face_verified == 1
                                  ? 'text-success'
                                  : 'text-danger',
                                'form-select border-0 bg-light',
                              ]"
                              @change="
                                editApproveLecture($event, faceIamgesItem)
                              "
                            >
                              <option
                                class="text-success"
                                value="1"
                                :selected="faceIamgesItem.face_verified == 1"
                              >
                                ตรวจพบ
                              </option>
                              <option
                                class="text-danger"
                                value="0"
                                :selected="faceIamgesItem.face_verified == 0"
                              >
                                ตรวจไม่พบ
                              </option>
                            </select>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@/services/api.js";
export default {
  components: {},
  emits: ["fetch", "fetchCouseInfo", "fetchLearningFaceApprove"],
  props: ["courseInfo", "memberInfo", "learningFaceItem"],
  data() {
    return {
      cId: this.$route.params.cId,
      token: localStorage.getItem("token"),
      cerId: this.$route.params.cerId,
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com/",
      collapseValue: false,
      collapseStates: [],
    };
  },
  computed: {
    allExpanded() {
      return this.collapseStates.every((state) => state);
    },
    buttonLabel() {
      return this.collapseValue || this.allExpanded
        ? "ซ่อนรูปภาพใบหน้าผู้เรียนทั้งหมด"
        : "แสดงรูปภาพใบหน้าผู้เรียนทั้งหมด";
    },
  },
  watch: {
    allExpanded(newValue) {
      this.collapseValue = newValue;
    },
  },
  mounted() {
    this.collapseStates = this.learningFaceItem.map(() => false);
  },
  methods: {
    toggleCollapse() {
      this.collapseValue = !this.collapseValue;
      this.collapseStates = this.learningFaceItem.map(() => this.collapseValue);
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      var hDisplay = h > 0 ? h + " : " : "";
      var mDisplay = m < 10 ? "0" + m + " : " : m + " : ";
      var sDisplay = s < 10 ? "0" + s : s;
      return hDisplay + mDisplay + sDisplay;
    },
    async approveLecture(lecId) {
      // เลคเชอร์
      let dataLecture = this.learningFaceItem.filter(
        (i) => i.lec_id === lecId
      )[0];

      let dataRequest = {
        approval: [],
      };
      for (let i = 0; i < dataLecture.lec_images.length; i++) {
        dataRequest.approval.push({
          lec_id: lecId,
          stat_id: dataLecture.lec_images[i].stat_id,
          face_verified: dataLecture.lec_images[i].face_verified,
        });
      }
      await api
        .put("/v1/edit-face-approve/" + this.cerId, dataRequest)
        .then(() => {
          this.$emit("fetch");
          this.$emit("fetchCouseInfo");
          this.$emit("fetchLearningFaceApprove");
          window.location.reload();
        });
    },
    hideModal() {
      this.$refs["modal-confirm-all"].hide();
    },
    editApproveLecture(event, faceIamgesItem) {
      faceIamgesItem.face_verified = event.target.value;
    },
  },
};
</script>

<style scoped>
.btn.disabled {
  cursor: context-menu;
}
.not-collapsed .up,
.down {
  display: block;
}
.collapsed .up,
.not-collapsed .down {
  display: none;
}
.box-lecture-collaspe-title.not-collapsed .text-lecture {
  color: #2573e7;
}
.box-lecture-collaspe-title.not-collapsed {
  background-color: #e7f1ff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.collapse-details,
.box-lecture-collaspe-title {
  padding: 20px 30px;
}
.profile-image-style {
  width: 10rem;
  height: 10rem;
}

.test-red-border {
  border: 2px solid red;
  padding: 5px;
}
.box-lecture-collapse {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

p {
  font-family: "Prompt", sans-serif;
}
</style>

<template>
  <div
    class="card bg-frosted-glass border-0 shadow rounded-lg overflow-hidden set-position"
  >
    <div class="row no-gutters">
      <div class="col-md-6 login-box">
        <div
          class="text-right h-100 col-12 col-md-9 offset-md-3"
          style="padding: 80px 50px 0px 50px;"
        >
          <img src="@/assets/images/cs_logo.png" width="100%" />
          <div class="border-bottom border-light mt-4"></div>
          <h5 class="mt-4 font-weight-light text-light">Hub of Learning</h5>
        </div>
      </div>
      <div class="col-md-6 bg-light py-5 px-3">
        <div class="row">
          <div class="col-12 col-md-9">
            <form class="form-signin" @submit.prevent="resetPassword">
              <div class="text-left mb-4">
                <p class="text-secondary">
                  ตั้งค่ารหัสผ่านใหม่
                </p>
              </div>

              <div class="form-group" style="position:relative;">
                <input
                  id="inputPassword"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control form-rounded shadow-sm bg-white pl-4"
                  :class="{ 'is-invalid': hasErrors }"
                  placeholder="รหัสผ่านใหม่"
                  required="true"
                  :disabled="isLoading"
                />
                <button class = "btnShowPassword"
                    @click.prevent="togglePasswordVisibility"
                    @mouseover="toggleButtonVisibility"
                    @mouseout="toggleButtonVisibility">
                    <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </button>
                
              </div>

              <div class="form-group" style="position:relative;">
                <input
                  id="inputConfirmPassword"
                  v-model="confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control form-rounded shadow-sm bg-white pl-4"
                  :class="{ 'is-invalid': hasErrors }"
                  placeholder="ยืนยันรหัสผ่าน"
                  required="true"
                  :disabled="isLoading"
                />
                <button class = "btnShowPassword"
                    @click.prevent="togglePasswordVisibility"
                    @mouseover="toggleButtonVisibility"
                    @mouseout="toggleButtonVisibility">
                    <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </button>
              </div>
              
                <div v-if="hasErrors" class="pl-4 text-danger">
                  <p>กรุณากรอกรหัสผ่านให้ตรงกัน</p>
                </div>
                <div v-if="hasErrorsPattern" class="pl-4 text-danger">
                  <p>กรุณากรอกรหัสผ่านให้ตรงกับข้อกำหนดในการตั้งรหัสผ่าน</p>
                </div>

              <div class="text-left mb-4">
                <p class="text-secondary">
                  ข้อกำหนดในการตั้งรหัสผ่าน
                </p>

                <div :class="{ 'checkbox-container': true, 'checkbox-fail': !passwordRequirements.lengthCase }">
                    <div :class="{ 'checkbox-mark': true, 'checkbox-fail-mark': !passwordRequirements.lengthCase,'checkbox-pass-mark': passwordRequirements.lengthCase}">
                        {{ passwordRequirements.lengthCase ? '✓' : '🞩' }}
                    </div>
                    <p class="text-conditional"> &nbsp;อย่างน้อย 10 ตัวอักษร</p>
                </div>

                <div :class="{ 'checkbox-container': true, 'checkbox-fail': !passwordRequirements.upperCase }">
                    <div :class="{ 'checkbox-mark': true, 'checkbox-fail-mark': !passwordRequirements.upperCase,'checkbox-pass-mark': passwordRequirements.upperCase}">
                        {{ passwordRequirements.upperCase ? '✓' : '🞩' }}
                    </div>
                    <p class="text-conditional"> &nbsp;มีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว</p>
                </div>

                <div :class="{ 'checkbox-container': true, 'checkbox-fail': !passwordRequirements.lowerCase }">
                    <div :class="{ 'checkbox-mark': true, 'checkbox-fail-mark': !passwordRequirements.lowerCase,'checkbox-pass-mark': passwordRequirements.lowerCase}">
                        {{ passwordRequirements.lowerCase ? '✓' : '🞩' }}
                    </div>
                    <p class="text-conditional"> &nbsp;มีตัวอักษรภาษาอังกฤษพิมพ์เล็กอย่างน้อย 1 ตัว</p>
                </div>

                <div :class="{ 'checkbox-container': true, 'checkbox-fail': !passwordRequirements.numberCase }">
                    <div :class="{ 'checkbox-mark': true, 'checkbox-fail-mark': !passwordRequirements.numberCase,'checkbox-pass-mark': passwordRequirements.numberCase}">
                        {{ passwordRequirements.numberCase ? '✓' : '🞩' }}
                    </div>
                    <p class="text-conditional"> &nbsp;มีตัวเลขอย่างน้อย 1 ตัวอักษร</p>
                </div>

                <div :class="{ 'checkbox-container': true, 'checkbox-fail': !passwordRequirements.symbolCase }">
                    <div :class="{ 'checkbox-mark': true, 'checkbox-fail-mark': !passwordRequirements.symbolCase,'checkbox-pass-mark': passwordRequirements.symbolCase}">
                        {{ passwordRequirements.symbolCase ? '✓' : '🞩' }}
                    </div>
                    <p class="text-conditional"> &nbsp;มีอักขระพิเศษ (! @ $ # %) อย่างน้อย 1 ตัวอักษร</p>
                </div>

              </div>

              <button
                class="btn btn-md btn-csq form-rounded shadow-sm mt-4 col-md-7"
                type="submit"
                :disabled="isLoading"
              >
                ยืนยัน
                <span
                  v-if="isLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <i v-else class="far fa-long-arrow-alt-right ml-2" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../services/api";

export default {
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      memberId: JSON.parse(localStorage.getItem("user")).id,
      password: "",
      confirmPassword: "",
      hasErrors: false,
      isLoading: false,
      showPassword: false,
      show: false,
      hasErrorsPattern: false,
    };
  },
  computed: {
    ...mapGetters("Authentication", ["authUser"]),
    userWorkAtCompanies() {
      const roles = this.authUser.roles;
      const profiles = this.authUser.profiles;

      return profiles.filter(profile => {
        return roles.find(role => {
          return role.companyId == profile.company.id;
        });
      });
    },

    passwordRequirements() {
      return {
        lengthCase: this.password.length >= 10,
        upperCase: /[A-Z]/.test(this.password),
        lowerCase: /[a-z]/.test(this.password),
        numberCase: /\d/.test(this.password),
        symbolCase: /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(this.password),
      };
    },

  },
  methods: {
    resetPassword: function(){
      this.isLoading = true;
      let password = this.password;
      let isPasswordValid = false;
      let confirmPassword = this.confirmPassword;
      if(this.passwordRequirements.lengthCase 
        && this.passwordRequirements.upperCase 
        && this.passwordRequirements.lowerCase 
        && this.passwordRequirements.numberCase 
        && this.passwordRequirements.symbolCase){
          isPasswordValid = true;
        } else {
          this.hasErrorsPattern = true;
        }
      const companyUrl = this.userWorkAtCompanies[0].company.url;
      if(password === confirmPassword && isPasswordValid){
        api
        .put(
          `/v1/members/${this.memberId}/reset-password`,
          {
            password: this.password,
            password_confirmation: this.confirmPassword,
            company: this.comId
          }
        )
        .then(() => {
          this.$bvToast.toast("เปลี่ยนรหัสผ่านเรียบร้อยแล้ว.", {
            variant: "success",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });
          this.hasErrorsPattern = false;
          this.hasErrors = false;
          this.isLoading = false;
          window.location.href = "/" + companyUrl;
        })
        .catch(() => {
          this.$bvToast.toast("เปลี่ยนรหัสผ่านไม่สำเร็จ.", {
            variant: "danger",
            autoHideDelay: 3000,
            noCloseButton: true,
            solid: true,
          });
          this.hasErrorsPattern = false;
          this.hasErrors = false;
          this.isLoading = false;
          window.location.href = "/reset-password";
          return false;
        });
      }else{
        if(!(password === confirmPassword)){
          this.hasErrors = true;
        }
        this.isLoading = false;
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleButtonVisibility() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="css" scoped>
.form-rounded {
  border-radius: 2rem;
}

.btn-csq {
  font-family: "Prompt", sans-serif;
  color: #f9fafb;
  background-color: #df812f;
  border-color: #e67424;
}

.btn-csq:hover {
  color: #f9fafb;
  background-color: #f3801c;
  border-color: #f3801c;
}

.text-csq {
  color: #df812f;
}

.login-box {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.text-secondary {
  font-family: "Prompt", sans-serif;
}

.text-conditional {
  font-family: "Prompt", sans-serif;
  font-size: 14px;
  color: #6c757d;
}

.set-position {
  margin-top: -9rem !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.checkbox-mark {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.checkbox-fail-mark {
  background-color: #dc3545;
  border-color: #6c757d;
  color: #ffffff;
}

.checkbox-pass-mark {
  background-color: #28a745;
  border-color: #6c757d;
  color: #ffffff;
}

.checkbox-fail {
  color: #ff0000;
}

.btnShowPassword {
  background-color: transparent;
  border: 0;
  cursor: pointer;
 position:absolute;
 top:50%; 
 right: 7px; 
 transform: translateY(-50%); 
 color: #6c757d;
}

.fas fa-eye {
  display: block;
}

.fas fa-eye-slash {
  display: none;
}

</style>

import MemberIndexPage from "../components/pages/member-management/MemberIndexPage.vue";
import MemberCreatePage from "../components/pages/member-management/MemberCreatePage.vue";
import MemberEditPage from "../components/pages/member-management/MemberEditPage.vue";
import MemberEditRolePage from "../components/pages/member-management/MemberEditRolePage.vue";

import GroupIndexPage from "../components/pages/member-group-management/IndexPage";
import GroupCreatePage from "../components/pages/member-group-management/CreatePage";
import GroupEditPage from "../components/pages/member-group-management/EditPage";

import VerifyMemberFacePage from "../components/pages/member-management/VerifyMemberFacePage";

import MemberFaceApprove from "../components/pages/member-management/MemberFaceApprovePage";

import FaceApproveSearchHistory from "../components/pages/member-management/FaceApproveSearchHistoryPage";

import FaceApproveSearchHistoryDetails from "../components/pages/member-management/FaceApproveSearchHistoryDetails"

export default [
  /** Member management */
  {
    path: "members",
    name: "member.index",
    component: MemberIndexPage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member", "crud-member"],
    },
  },
  {
    path: "member/add",
    name: "member.create",
    component: MemberCreatePage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member"],
    },
  },
  {
    path: "member/:id/edit",
    name: "member.edit",
    component: MemberEditPage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member"],
    },
  },
  {
    path: "members/face-verification",
    name: "members.face-verification.index",
    component: VerifyMemberFacePage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member", "read-member"],
    },
  },

  {
    path: "members/role",
    name: "members.role",
    component: MemberEditRolePage,
    meta: {
      authRequired: true,
      permsRequired: ["read-member", "crud-member"],
    },
  },

  /** Member-group management */
  {
    path: "groups",
    name: "group.index",
    component: GroupIndexPage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member-group"],
    },
  },

  {
    path: "groups/create",
    name: "group.create",
    component: GroupCreatePage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member-group"],
    },
  },

  {
    path: "groups/:id",
    name: "group.edit",
    component: GroupEditPage,
    meta: {
      authRequired: true,
      permsRequired: ["crud-member-group"],
    },
  },

  {
    path: "members/face-verification/face-approve/:cerId/courses/:cId",
    name: "members.face-verification.face-approve.courses",
    component: MemberFaceApprove,
    meta: {
      template: "A",
    },
  },

  {
    path: "members/face-approve-search-history",
    name: "members.face-approve-search-history",
    component: FaceApproveSearchHistory,
    meta: {
      template: "A",
    },
  },

  {
    path: "members/face-approve-search-history/:fashId/courses/:cId",
    name: "members.face-approve-search-history.details",
    component: FaceApproveSearchHistoryDetails,
    meta: {
      template: "A",
    },
  },
];

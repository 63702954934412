export default {
  methods: {
    getTotalTimeUsed(time) {
      if (time) {
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        var timeString = date.toISOString().substr(11, 8);
        const hour = parseInt(timeString.split(":")[0]);
        const minute = parseInt(timeString.split(":")[1]);
        const second = parseInt(timeString.split(":")[2]);
        return `${hour > 0 ? hour + " ชั่วโมง" : ""} ${
          minute > 0 ? minute + " นาที" : ""
        } ${second > 0 ? second + " วินาที" : ""}`;
      } else return "-";
    }
  }
};

<template>
  <div>
    <PageHeader title="สรุปภาพรวมผู้เรียน"></PageHeader>
    <div class="p-4">
      <b-row>
        <b-col cols="4">
          <courseInfo
            :apiRoute="`/v1/analytics/courses/getCourseInfo?cId=${cId}`"
            :apiRoute2="
              `/v1/analytics/courses/getCourseAverageFinishTime?cId=${cId}`
            "
          ></courseInfo>
        </b-col>
        <b-col cols="4"
          ><studentAmount
            :apiRoute="
              `/v1/analytics/courses/getCourseStudentActive?cId=${cId}`
            "
          ></studentAmount
        ></b-col>
        <b-col cols="4"
          ><courseStat
            :apiRoute="
              `/v1/analytics/courses/getCourseStudentComplete?cId=${cId}`
            "
          ></courseStat
        ></b-col>
        <!-- <b-col cols="3"><compareTest></compareTest></b-col> -->
      </b-row>
      <b-row style="margin-top:1rem">
        <b-col cols="4"
          ><studentNotStarted
            :apiRoute="
              `/v1/analytics/courses/getCourseStudentNotStarted?cId=${cId}&template=panel`
            "
            :apiRoute2="
              `/v1/analytics/courses/getCourseStudentActive?cId=${cId}`
            "
            :perPage="10"
          ></studentNotStarted
        ></b-col>
        <b-col cols="8"
          ><totalTimeStudy
            :apiRoute="`/v1/analytics/courses/getTotalLearningTime?cId=${cId}`"
          ></totalTimeStudy
        ></b-col>
      </b-row>
      <b-col cols="12" style="padding:0;margin-top:1rem"
        ><infoTable></infoTable
      ></b-col>
      <b-col cols="12" class="p-0 mt-3"
        ><stdInfo
          :apiRoute="
            `/v1/analytics/courses/getStudentInfo?cId=${cId}&template=panel`
          "
          :perPage="10"
        ></stdInfo
      ></b-col>
      <b-col cols="12" class="p-0 mt-3"
        ><certInfo
          :apiRoute="
            `/v1/analytics/courses/getCourseLearnerCertificate?cId=${cId}&template=panel`
          "
          :perPage="10"
        ></certInfo
      ></b-col>
    </div>
  </div>
</template>
<script>
import courseInfo from "./panel/CourseInfoPanel";
// import courseAverageFinishTime from "./panel/CourseAverageFinishTimePanel";
import studentAmount from "./panel/CourseStudentAmountPanel";
import courseStat from "./panel/CourseCompletePanel";
// import compareTest from "./panel/CourseCompareTestPanel";
import studentNotStarted from "./panel/CourseStudentNotStartedPanel";
import totalTimeStudy from "./panel/CourseTotalLearningTimePanel";
import infoTable from "./panel/CourseViewAndDownloadPanel";
import stdInfo from "./panel/CourseStudentInfoPanel";
import certInfo from "./panel/LearnerIndividualCourseCertificatePanel";
import PageHeader from "@/components/layouts/PageHeader.vue";

export default {
  components: {
    courseInfo,
    PageHeader,
    // courseAverageFinishTime,
    studentAmount,
    courseStat,
    // compareTest,
    studentNotStarted,
    totalTimeStudy,
    infoTable,
    stdInfo,
    certInfo
  },
  data() {
    return {
      cId: this.$route.params.cId
    };
  }
};
</script>

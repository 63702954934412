<template>
  <div>
    <ExamEditor
        class="mt-4"
        :editorText="editorText"
        :EditType="markdown"
        :showButton="false"
        @updateDesc="onUpdateExamDescription($event)"
    />
  </div>
</template>
  
  <script>
import "@toast-ui/editor/dist/toastui-editor.css";
import { mapActions, mapGetters } from "vuex";
import ExamEditor from "../modal/ModalExamEditor.vue";

export default {
  components: {ExamEditor},

  data() {
    return {
        token: localStorage.getItem("token"),
        markdown: "markdown",
    };
  },

  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
    editorText() {
      return this.exam.instruction || '';
    },
  },

  watch: {},

  methods: {
    ...mapActions("ExamBuilder", ["updateExamInfo"]),
    async onUpdateExamDescription(text) {
      let request = {
        body: { instruction: text },
        id: this.exam.id,
        token: this.token,
      };
      await this.updateExamInfo(request)
        .then(() => {
          this.exam.instruction = text;
        })
        .catch(() => {
          this.titleExamState = false;
        });
    },
  },
};
</script>
  
<style lang="css" scoped>
</style>
  
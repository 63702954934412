<template>
  <b-card-group>
    <b-card>
      <div slot="header">
        <i class="fa fa-star" aria-hidden="true" /> จำนวนคำถาม
      </div>
      <div class="num-exam-space">
        <template v-if="examAnalyticsPending">
          กำลังโหลด
        </template>
        <template v-else>
          <b-row>
            <b-col
              cols="4"
              class="content-left"
              :class="`${$options.name}__body--left`"
            >
              <ExamTotalAnswers />
            </b-col>
            <b-col
              cols="8"
              class="content-right"
              :class="`${$options.name}__body--right`"
            >
              <ExamMetaInfo />
            </b-col>
          </b-row>
        </template>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ExamTotalAnswers from "@/components/pages/exam-analytics/exams-overall/exam-meta/ExamTotalAnswers.vue";
import ExamMetaInfo from "@/components/pages/exam-analytics/exams-overall/exam-meta/ExamMetaInfo.vue";

export default {
  name: "ExamOverallExamMeta",
  components: {
    ExamTotalAnswers,
    ExamMetaInfo
  },
  computed: {
    ...mapGetters("Company", ["examAnalyticsPending", "companyInfo"])
  },
  created() {
    this.fetchExamAnalytics(this.companyInfo.id);
  },
  methods: {
    ...mapActions("Company", ["fetchExamAnalytics"])
  }
};
</script>

<style lang="scss" scoped>
.content-left {
  text-align: center;
}
.content-right {
  padding-top: 5%;
}
.num-exam-space {
  margin-bottom: 0.5em;
}
</style>

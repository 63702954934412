export default {
  data() {
    return {
      items: {
        data: [],
        links: {
          first: null,
          last: null,
          prev: null,
          next: null
        },
        meta: {
          current_page: 0,
          from: 0,
          last_page: 0,
          path: null,
          per_page: 10,
          to: 0,
          total: 0
        }
      },
      allSelectedItems: []
    };
  },
  computed: {
    isAllItemsSelected() {
      let selectedItems = this.items.data.filter(item =>
        this.isSelectedItem(item)
      );
      return selectedItems.length == this.items.data.length;
    },
    allSelectedItemIds() {
      return this.allSelectedItems.map(item => item.id);
    }
  },
  methods: {
    rowClicked(item) {
      const index = item ? this.getSelectedItemIndex(item) : -1;

      if (index > -1) {
        this.allSelectedItems.splice(index, 1);
      } else {
        this.allSelectedItems.push(item);
      }
    },
    selectAllRows() {
      this.items.data.forEach(item => {
        const index = this.getSelectedItemIndex(item);
        if (index === -1) this.allSelectedItems.push(item);
      });
    },
    clearSelected() {
      this.items.data.forEach(item => {
        const index = this.getSelectedItemIndex(item);
        if (index > -1) this.allSelectedItems.splice(index, 1);
      });
    },
    getSelectedItemIndex(item) {
      return this.allSelectedItems.findIndex(elem => elem.id === item.id);
    },
    isSelectedItem(item) {
      const index = item ? this.getSelectedItemIndex(item) : -1;
      return index > -1;
    },
    tbodyRowClass(item) {
      if (this.isSelectedItem(item)) {
        return ["b-table-row-selected", "table-warning", "cursor-pointer"];
      } else {
        return ["cursor-pointer"];
      }
    }
  },
  watch: {}
};

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <page-header
          :title="
            latestAnswerSheet
              ? `ผลการทำข้อสอบ ${latestAnswerSheet.metadata.examTitle} ของ ${latestAnswerSheet.metadata.memberName}`
              : 'Loading ...'
          "
        ></page-header>
        <b-button
          @click="exportData()"
          style="float: right"
          class="mt-3 mr-3 btn btn-download"
          href=""
          ><i class="fa fa-angle-double-down"  aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <b-table
          id="exam-result-table"
          table-class="tbNumber text-left"
          style="overflow-x: auto"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          class="text-truncate border m-3"
          small
          sort-icon-left
          responsive="true"
          :busy="isLoading"
          hover
          striped
        >
          <template v-slot:cell(no)="data">
            <span>{{ data.item.no }}</span>
          </template>
          <template v-slot:cell(examDate)="data">
            <span>{{ data.item.createdAt | thaiDateFilterTimestamp }}</span>
          </template>
          <template v-slot:cell(totalTimeUsed)="data">
            <span>{{
              data.item.totalTimeUsed != null
                ? getTotalTimeUsed(data.item.totalTimeUsed.toFixed(2))
                : getTotalTimeUsed(
                    calculateTimeUsed(data.item.sections).toFixed(2)
                  )
            }}</span>
          </template>
          <template v-slot:cell(score)="data">
            <span>{{ data.item.overallResult.score }}</span>
          </template>
          <template v-slot:cell(percent)="data">
            <span>{{ data.item.overallResult.percent }} %</span>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-12 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="exam-result-table"
          class="m-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import totalTimeUsedMixin from "@/components/pages/analytics/exam/mixins/totalTimeUsed.js";
import dateMixin from "@/components/pages/analytics/shared/mixins/date.js";
import axios from "@/services/exam-api.js";
import exp from "@/services/export.js";
export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  components: {
    PageHeader,
  },
  data() {
    return {
      examId: null,
      memberId: null,
      latestAnswerSheet: null,
      totalAnswerSheets: null,
      isLoading: true,
      fields: [
        { key: "no", label: "ลำดับ" },
        { key: "examDate", label: "วันที่ทำข้อสอบ" },
        { key: "totalTimeUsed", label: "เวลาที่ใช้ทำข้อสอบ" },
        { key: "score", label: "คะแนนที่ได้" },
        { key: "percent", label: "คิดเป็น % จากผู้ทำทั้งระบบ" },
      ],
      items: [],
      currentPage: 1,
      perPage: 15,
    };
  },
  computed: {
    rows() {
      return this.totalAnswerSheets ? this.totalAnswerSheets.length : 0;
    },
  },
  watch: {
    totalAnswerSheets() {
      this.updateTable();
    },
  },
  created() {
    this.examId = this.$route.params.examId;
    this.memberId = this.$route.params.memberId;
    this.fetchLatestAnswerSheet();
    this.fetchAllAnswerSheet();
  },
  methods: {
    async fetchLatestAnswerSheet() {
      try {
        const endpoint = `/answer-sheets/get-latest-answer-sheet/exam/${
          this.examId
        }/member/${this.memberId}?filter={"comId":${
          this.$store.state.Company.companyInfo.id
        }}&access_token=${localStorage.getItem("token")}`;
        const result = await axios.get(endpoint);
        this.latestAnswerSheet = result.data;
      } catch (err) {
        console.log("fetchLatestAnswerSheet error!", err);
      }
    },
    async fetchAllAnswerSheet() {
      try {
        const endpoint = `/answer-sheets/get-all-answer-sheets/exam/${
          this.examId
        }/member/${this.memberId}?filter={"comId":${
          this.$store.state.Company.companyInfo.id
        }}&access_token=${localStorage.getItem("token")}`;
        const result = await axios.get(endpoint);
        this.totalAnswerSheets = result.data;
      } catch (err) {
        console.log("fetchAllAnswerSheet error!", err);
      }
    },
    updateTable() {
      this.isLoading = true;
      this.items = [...this.totalAnswerSheets];
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].no = i + 1;
      }
      this.isLoading = false;
    },
    getTotalTimeUsed(time) {
      if (time) {
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        var timeString = date.toISOString().substr(11, 8);
        const hour = parseInt(timeString.split(":")[0]);
        const minute = parseInt(timeString.split(":")[1]);
        const second = parseInt(timeString.split(":")[2]);
        return `${hour > 0 ? hour + " ชั่วโมง" : ""} ${
          minute > 0 ? minute + " นาที" : ""
        } ${second > 0 ? second + " วินาที" : ""}`;
      } else return "-";
    },

    thaiDateFilterTimestamp(value) {
      if (value) {
        const yearMonthDate = value.split("T")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },

    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach((i) => {
        let temp = {
          create: i.createdAt ? this.thaiDateFilterTimestamp(i.createdAt) : "-",
          time:
            i.totalTimeUsed != null
              ? this.getTotalTimeUsed(i.totalTimeUsed.toFixed(2))
              : this.getTotalTimeUsed(
                  this.calculateTimeUsed(i.sections).toFixed(2)
                ),
          score: i.overallResult.score ? i.overallResult.score : 0,
          per: i.overallResult.percent ? i.overallResult.percent + "%" : "-",
        };
        value.push(temp);
      });
      let key = [
        "วันที่ทำข้อสอบ",
        "เวลาที่ใช้ทำข้อสอบ",
        "คะแนนที่ได้",
        "คิดเป็น % จากผู้ทำทั้งระบบ",
      ];
      let filename =
        "Exam-Individual-Learner-Result-" +
        this.$store.state.Company.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
  },
};
</script>

<style></style>

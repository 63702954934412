<template>
  <div>
    <b-row>
      <b-col cols="12">
        <page-header title="รายการทำแบบทดสอบล่าสุด"></page-header>
        <b-button
          @click="exportData()"
          style="float: right"
          class="mt-3 mr-3 btn btn-download"
          href=""
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
        >
      </b-col>
    </b-row>

    <b-row class="p-3">
      <b-col cols="2"
        ><b-input v-model="fromSearch" type="date" :max="today"></b-input
      ></b-col>
      <p>ถึง</p>
      <b-col cols="2"
        ><b-input v-model="toSearch" type="date" :max="today"></b-input
      ></b-col>

      <b-col cols="2">
        <div></div>
        <b-form-input
          v-model="userSearch"
          placeholder="ชื่อผู้สอบ"
          style="padding:1rem;display:inline-block;width:100%"
        >
        </b-form-input>
      </b-col>
      <b-col cols="2">
        <b-form-input
          v-model="examSearch"
          placeholder="ชื่อข้อสอบ"
          style="padding:1rem;display:inline-block;width:100%"
        >
        </b-form-input>
      </b-col>
      <b-col cols="2">
        <div></div>
        <b-button variant="info" @click="fetchAvailableQuizIds()"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col-12">
        <b-table
          id="my-table"
          table-class="tbNumber text-left"
          style="overflow-x: auto"
          class="text-truncate border m-3"
          responsive="true"
          :items="items"
          :fields="fields"
          :busy="isLoading || answerSheetAmount == null"
          small
          sort-icon-left
          hover
          striped
        >
          <template v-slot:cell(student)="data">
            <span>{{
              data.item.metadata.memberName
                ? data.item.metadata.memberName
                : '-'
            }}</span>
          </template>

          <template v-slot:cell(staffId)="data">
            <span>{{ data.item.staffId ? data.item.staffId : '-' }}</span>
          </template>
          <template v-slot:cell(exam)="data">
            <span>{{
              data.item.metadata.examTitle ? data.item.metadata.examTitle : '-'
            }}</span>
          </template>
          <template v-slot:cell(totalTimeUsed)="data">
            <span>{{
              data.item.totalTimeUsed != null
                ? getTotalTimeUsed(data.item.totalTimeUsed.toFixed(2))
                : getTotalTimeUsed(
                    calculateTimeUsed(data.item.sections).toFixed(2)
                  )
            }}</span>
          </template>
          <template v-slot:cell(date)="data">
            <span>{{
              formatDate(data.item.markedAt.$date.$numberLong)
            }}</span>
          </template>

          <template v-slot:cell(start)="data">
            <span>{{
              new Date(
                formatTime(data.item.markedAt.$date.$numberLong) -
                Math.round(data.item.totalTimeUsed != null
                  ? data.item.totalTimeUsed
                  : calculateTimeUsed(data.item.sections)) * 1000
              ).toLocaleTimeString('en-GB')
            }}</span>
          </template>
          <template v-slot:cell(score)="data">
            <span
              >{{
                data.item.overallResult.score
                  ? data.item.overallResult.score
                  : '0'
              }}
              ({{
                (
                  data.item.overallResult.score &&
                  (data.item.overallResult.score /
                    data.item.overallResult.fullScore) *
                    100
                ).toFixed(2)
              }}
              %)</span
            >
          </template>
          <template v-slot:cell(button)="data">
            <b-button variant="outline-primary" size="sm">
              <a
                :href="
                  `/${company.url}/analytics/exams/member/${data.item.metadata.memberId}/exam/${data.item.examId}`
                "
                target="_blank"
                >ดูข้อมูล</a
              >
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-12">
        <div class="d-flex justify-content-end align-items-center m-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="answerSheetAmount"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from '@/components/layouts/PageHeader.vue';
import totalTimeUsedMixin from '@/components/pages/analytics/exam/mixins/totalTimeUsed.js';
import dateMixin from '@/components/pages/analytics/shared/mixins/date.js';

import adminService from '@/services/api.js';
import exp from '@/services/export.js';
import moment from 'moment';
export default {
  mixins: [totalTimeUsedMixin, dateMixin],
  components: {
    PageHeader,
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      fromSearch: moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      toSearch: moment().format('YYYY-MM-DD'),
      examSearch: null,
      userSearch: null,
      answerSheetAmount: null,
      company: null,
      quizIds: [],
      items: [],
      fields: [
        { key: 'student', label: 'ผู้เรียน' },
        { key: 'staffId', label: 'รหัสพนักงาน' },
        { key: 'exam', label: 'ข้อสอบ' },
        { key: 'totalTimeUsed', label: 'เวลาที่ใช้ทำ' },
        { key: 'date', label: 'วันที่ทำข้อสอบ' },
        { key: 'start', label: 'เวลาที่เริ่มทำ' },
        { key: 'score', label: 'คะแนนที่ได้' },
        { key: 'button', label: '' },
      ],
      currentPage: 2,
      perPage: 15,
      isLoading: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.fetchAnswerSheets();
  },
  watch: {
    currentPage() {
      this.fetchRecentExams();
    },
  },
  methods: {
    thaiDurationFilter(value) {
      let str = '';
      value = parseFloat(value);
      if (value) {
        if (value >= 3600) {
          let hour = value / 3600;
          value = value % 3600;
          str = hour + ' ชั่วโมง ';
        }
        if (value >= 60) {
          let minute = value / 60;
          value = value % 60;
          str = str + minute + ' นาที ';
        }
        if (value != 0) {
          str = str + value + ' วินาที';
        }
        return str;
      }
      return `-`;
    },
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },

    async fetchAvailableQuizIds() {
      this.fetchRecentExams();
      this.fetchAnswerSheets();
    },
    async fetchRecentExams() {
      this.isLoading = true;
      try {
        const endpoint = `/v1/analytics/exams/recent-answer-sheets/${
          this.company.id
        }?limit=${this.perPage}&q=${this.currentPage}&user_search=${
          this.userSearch ? this.userSearch : '*'
        }&exam_search=${this.examSearch ? this.examSearch : '*'}&start_date=${
          this.fromSearch
        }&end_date=${moment(this.toSearch)
          .add(1, 'days')
          .format('YYYY-MM-DD')}`;
        const result = await adminService.get(endpoint);
        this.items = result.data;
        this.isLoading = false;
      } catch (err) {
        console.log('fetchRecentExams error!', err);

        this.isLoading = false;
      }
    },

    async fetchAnswerSheets() {
      this.isLoading = true;
      const endpoint = `/v1/analytics/exams/count-answer-sheets/${
        this.company.id
      }?user_search=${this.userSearch ? this.userSearch : '*'}&exam_search=${
        this.examSearch ? this.examSearch : '*'
      }&start_date=${this.fromSearch}&end_date=${moment(this.toSearch)
        .add(1, 'days')
        .format('YYYY-MM-DD')}`;
      const res = await adminService.get(endpoint);
      this.answerSheetAmount = res.data;
      this.isLoading = false;
    },
    getTotalTimeUsed(time) {
      if (time) {
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        var timeString = date.toISOString().substr(11, 8);
        const hour = parseInt(timeString.split(':')[0]);
        const minute = parseInt(timeString.split(':')[1]);
        const second = parseInt(timeString.split(':')[2]);
        return `${hour > 0 ? hour + ' ชั่วโมง' : ''} ${
          minute > 0 ? minute + ' นาที' : ''
        } ${second > 0 ? second + ' วินาที' : ''}`;
      } else return '-';
    },

    thaiDateFilterTimestamp(value) {
      if (value) {
        const yearMonthDate = value.split('T')[0].split('-');
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const day = yearMonthDate[2].split(' ')[0];
        const thaiMonth = [
          'ม.ค.',
          'ก.พ.',
          'มี.ค.',
          'เม.ย.',
          'พ.ค.',
          'มิ.ย.',
          'ก.ค.',
          'ส.ค.',
          'ก.ย.',
          'ต.ค.',
          'พ.ย.',
          'ธ.ค.',
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${day} ${thaiMonth} ${thaiYear}`;
      }
      return '-';
    },
    async exportData() {
      let value = [];
      const endpoint = `/v1/analytics/exams/recent-answer-sheets/${
        this.company.id
      }?limit=${this.answerSheetAmount}&q=${1}&user_search=${
        this.userSearch ? this.userSearch : '*'
      }&exam_search=${this.examSearch ? this.examSearch : '*'}&start_date=${
        this.fromSearch
      }&end_date=${moment(this.toSearch)
        .add(1, 'days')
        .format('YYYY-MM-DD')}`;
      const result = await adminService.get(endpoint);
      let set = result.data;
      set.forEach((i) => {
        let temp = {
          name: i.metadata.memberName ? i.metadata.memberName : '-',
          staff: i.staffId ? i.staffId.toString() : '-',
          title: i.metadata.examTitle ? i.metadata.examTitle : '-',
          time:
            i.totalTimeUsed != null
              ? this.getTotalTimeUsed(i.totalTimeUsed.toFixed(2))
              : this.getTotalTimeUsed(
                  this.calculateTimeUsed(i.sections).toFixed(2)
                ),
          create: i.markedAt.$date.$numberLong ? this.formatDate(i.markedAt.$date.$numberLong) : '-',
          start: new Date(
                  this.formatTime(i.markedAt.$date.$numberLong) -
                  Math.round(i.totalTimeUsed != null
                    ? i.totalTimeUsed
                    : this.calculateTimeUsed(i.sections)) * 1000
                ).toLocaleTimeString('en-GB'),
          score: i.overallResult.score ? i.overallResult.score : '0',
        };
        value.push(temp);
      });
      let key = [
        'ผู้เรียน',
        'รหัสพนักงาน',
        'ข้อสอบ',
        'เวลาที่ใช้ทำ',
        'วันที่ทำข้อสอบ',
        'เวลาที่เริ่มทำ',
        'คะแนนที่ได้',
      ];
      let filename = 'Exam-Overview-Recent-Exams-' + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    formatDate(timestamp) {
      const date = new Date(Number(timestamp));
      return date.toLocaleString('en-GB');
    },
    formatTime(timestamp) {
      const date = new Date(Number(timestamp));
      const seconds = date.getUTCHours() * 3600 * 1000 +
        date.getUTCMinutes() * 60 * 1000 +
        date.getUTCSeconds() * 1000 +
        date.getUTCMilliseconds();
        console.log(date)
        console.log(seconds)
      return seconds;
    }
  },
};
</script>

<style></style>

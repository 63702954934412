<template>
  <b-container fluid="xl" class="pt-5">
    <div>
      <layout>
        <template v-slot:header>พื้นที่จัดเก็บทั้งหมด</template>
        <template v-slot:body>
          <span v-if="max == null"
            >ถูกใช้ไป
              {{ Math.round(total) }} GB</span
          >
          <span v-if="max != null"
            >ถูกใช้ไป
            {{ Math.round((total / max) * 100) }}
            % ({{ Math.round(total) }}/{{ max }} GB)</span
          >
          <span style="float:right" v-if="max == null">
            พื้นที่ว่าง Unlimited</span
          >
          <span style="float:right" v-if="max != null">
            พื้นที่ว่าง
            {{ max - total >= 0 ? Math.round(((max - total) / max) * 100) : 0 }}
            % ({{ max - total >= 0 ? Math.round(max - total) : 0 }} GB)</span
          >
          <b-progress
            class="mt-2"
            :max="max"
            height="1.5rem"
            v-if="total <= max"
          >
            <b-progress-bar
              :value="bytesToGB(parseInt(result.video))"
              variant="info"
            ></b-progress-bar>
            <b-progress-bar
              :value="bytesToGB(parseInt(result.other))"
              variant="success"
            ></b-progress-bar>
          </b-progress>

          <b-progress
            class="mt-2"
            height="1.5rem"
            :max="total"
            v-if="total > max"
          >
            <b-progress-bar :value="max" variant="info"></b-progress-bar>
            <b-progress-bar v-if="max == null"
              :value="total - max"
              variant="info"
            ></b-progress-bar>
            <b-progress-bar v-if="max != null"
              :value="total - max"
              variant="danger"
            ></b-progress-bar>
          </b-progress>

          <span v-if="max == null"> Unlimited</span>
          <span v-if="max != null"> {{ max }} GB</span>
        </template>
      </layout>
    </div>
  </b-container>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
export default {
  components: {
    layout,
  },
  props: ["apiRoute"],
  data() {
    return {
      company: {},
      result: {},
      isBusy: true,
      max: null,
      total: null,
    };
  },
  methods: {
    bytesToGB(size) {
      var result = size / (1024 * 1024 * 1024);
      return parseInt(result);
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.max = this.result.max;
    this.total = this.bytesToGB(
      parseInt(this.result.video) + parseInt(this.result.other)
    );
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped>
h6 {
  margin-bottom: 0 !important;
}
</style>

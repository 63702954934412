<template>
  <div>
    <div v-if="onEditing || errors" class="input-group mb-3">
      <input
        ref="refInput"
        v-model="input"
        :type="type"
        :min="minDate"
        :maxlength="max"
        class="form-control"
        :class="{ 'is-invalid': errors }"
        :placeholder="placeholder"
        @change="edited"
        @keyup.enter="edited"
        @keydown.esc="onEditing = false"
        @keypress="onKeyPress"
      />
      <b-form-invalid-feedback>
        <p v-if="errors">{{ errors }}</p>
      </b-form-invalid-feedback>
    </div>

    <div v-else class="input-group mb-3 border-bottom">
      <div v-if="type == 'date'">
        <input
          type="text"
          class="form-control-plaintext text-muted"
          :value="dateformat(value)"
          readonly
        />
      </div>
      <div v-else>
        <input
          :type="type"
          class="form-control-plaintext text-muted"
          :value="value"
          readonly
        />
      </div>
      <div v-if="isEditable" class="input-group-append">
        <button
          :disabled="isDisabled"
          class="btn btn-link text-csq"
          type="button"
          @click="editing"
        >
          <b-icon-pencil variant="secondary" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInvalidFeedback } from "bootstrap-vue";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";

export default {
  components: { BFormInvalidFeedback },
  mixins: [dateMixins],
  props: {
    name: { type: String, default: "" },
    value: { type: String, default: "" },
    isEditable: { type: Boolean, default: true },
    type: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    minDate: { type: String, default: null },
    max:{ type: String, default: null },
    errors: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
    errorText: {type: String, default: ""}
  },

  data() {
    return {
      onEditing: true,
      input: ""
    };
  },

  mounted() {
    if (this.value != "") this.onEditing = false;
    this.input = this.value;
  },

  methods: {
    editing() {
      this.onEditing = true;
      this.input = this.value;
    },

    inserted() {
      this.onEditing = true;
      this.$emit("edited", { name: this.name, value: this.input });
    },

    edited() {
      if (!this.input) {
        this.input = "";
        this.errors = this.errorText;
      } else if (
        this.name == "phoneNumber" &&
        (this.input.length < 9 || this.input.length > 15)
      ) {
        this.errors = "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง";
      } else {
        this.errors = "";
      }
      this.onEditing = false;
      this.$emit("edited", { name: this.name, value: this.input });
    },
    onKeyPress(event) {
      if (this.type === "number") {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
        }
      }
    },
    dateformat(date) {
      if (date == "9999-12-31") {
        return "-";
      } else {
        return this.thaiDateFilter(date);
      }
    },
  },
};
</script>

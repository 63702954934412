export default {
  data() {
    return {
      isLoading: false,
      fetchParams: {
        page: null,
        limit: 10,
        q: null,
        sort_by: "fullname",
        sort_order: "asc",
        excepts: "profiles,groups,roles"
      },
      tableFields: [
        {
          label: "ผู้เรียน",
          key: "fullname",
          sortable: true
        },
        {
          label: "อีเมล",
          key: "email",
          sortable: true
        },
        { key: "department", label: "แผนก", sortable: true }
      ],
      perPageOptions: [10, 25, 50]
    };
  },
  computed: {
    sortDesc: {
      get() {
        return false;
      },
      set(isDesc) {
        this.fetchParams.sort_order = isDesc ? "desc" : "asc";
      }
    }
  },
  methods: {
    setSearchKeyword(q) {
      this.fetchParams.page = 1;
      this.fetchParams.q = q;
    }
  },
  watch: {
    fetchParams: {
      handler() {
        this.fetch();
      },
      deep: true
    }
  }
};

import { render, staticRenderFns } from "./ExamMeta.vue?vue&type=template&id=271e94c0&scoped=true&"
import script from "./ExamMeta.vue?vue&type=script&lang=js&"
export * from "./ExamMeta.vue?vue&type=script&lang=js&"
import style0 from "./ExamMeta.vue?vue&type=style&index=0&id=271e94c0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271e94c0",
  null
  
)

export default component.exports
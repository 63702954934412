<template>
  <b-row>
    <b-col cols='12' class='mb-3'>
      <div class='font-weight-bold mb-2'>คอร์ส</div>
      <div>{{affiliateItem.courseName}}</div>
    </b-col>
    <b-col cols='12' class='mb-3'>
        <img 
          v-if='affiliateItem' 
          :src='getImageURL' 
          alt='Course Thumbnail'
          :style="{width: '100%'}"
        />
    </b-col>
    <b-col cols='12' class='mb-4'>
      <div class='mb-2'>แก้ไขชื่อลิงค์</div>
      <div>
        <b-form-input
          v-model="newLinkName"
        ></b-form-input>
      </div>
    </b-col>
    <b-col cols='12'>
      <b-input-group>
        <b-form-input disabled :value='affiliateItem.linkUrl | shortenLink'></b-form-input>
        <template #append>
          <b-input-group-text 
            class='copy-icon' 
            @click='copyButtonClicked()' 
          >
            <b-icon icon='stickies'></b-icon>
          </b-input-group-text>
        </template>
      </b-input-group>
    </b-col>
    <!-- For copy the link into the clipboard -->
    <b-col>
      <b-row>
        <b-col>
          <input type="hidden" id="edit-modal-copied-linkurl" :value="affiliateItem.linkUrl">
        </b-col>
      </b-row>
    </b-col>
    <!--  -->
  </b-row>
</template>

<script>
import AffiliateMixin from '@/components/pages/affiliate/link/mixins/affiliate-link.js'

export default {
  mixins: [AffiliateMixin],
  props: {
    affiliateItem: Object
  },
  data() {
    return {
      originalLinkName: '',
      newLinkName: ''
    }
  },
  computed: {
    getImageURL() {
      let imgURL = `https://s1-dev.coursesquare.com/data/courses/default/course_default.jpg`
      if (this.affiliateItem.courseId) {
        imgURL = this.affiliateItem.courseThumbnailPath
      }
      return imgURL
    }
  },
  watch: {
    newLinkName() {
      if (this.originalLinkName.trim() !== this.newLinkName.trim()) {
        this.$emit('linkNameChanged', {
          id: this.affiliateItem.affiliateId,
          value: this.newLinkName
        })
      }
    }
  },
  created() {
    this.originalLinkName = this.affiliateItem.linkName
    this.newLinkName = this.affiliateItem.linkName
  },
  methods: {
    copyButtonClicked() {
      let testingCodeToCopy = document.querySelector('#edit-modal-copied-linkurl')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      document.execCommand('copy');
      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
      // Show some notification
      this.$bvToast.toast(`Copy to clipboard !`, {
        title: 'Success',
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 1000,
        appendToast: true,
        noHoverPause: true,
        noCloseButton: true
      })
    }
  }
}
</script>

<style scoped>
.copy-icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
</style>
<template>
  <div
    class="d-flex flex-column justify-content-center h-100 p-2"
    style="border : 1px solid rgb(200, 200, 200)"
  >
    <span class="text-left">ราคาคอร์สเฉลี่ยต่อชั่วโมงของวิดีโอ</span>

    <h1 class="text-head pt-4">
      {{
        (result && parseInt(result.price) / parseInt(result.duration))
          | roundDecimal
      }}
    </h1>
    <span class="text-right">บาท / ชั่วโมง</span>
  </div>
</template>

<script>
import api from "@/services/api.js";

export default {
  components: {},
  props: ["apiRoute"],
  data() {
    return {
      result: {},
    };
  },
  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
.text-head {
  color: rgb(235, 129, 35);
  text-align: center;
  font-weight: bold;
  font-family: "Sarabun", sans-serif;
}

.text-null {
  color: #ddd;
  font-weight: 400;
  text-align: center;
  font-family: "Sarabun", sans-serif;
}
.pl-1 {
  margin-bottom: 0.65rem !important;
}
.p-1 {
  margin-bottom: 0.4rem !important;
}
</style>

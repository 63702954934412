var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('layout',{scopedSlots:_vm._u([(_vm.perPage == 10)?{key:"header",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h6',[_vm._v("อันดับนักเรียน")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('a',{attrs:{"href":`/${_vm.companyInfo.url}/analytics/courses/${_vm.cId}/student-ranking`,"target":"_blank"}},[_vm._v("ดูทั้งหมด")])])],1)]},proxy:true}:null,{key:"body",fn:function(){return [_c('div',{},[(_vm.perPage > 10)?_c('div',{staticClass:"mb-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.courseInfo.data.courseName)+" ")]),_c('b-form-input',{staticStyle:{"padding":"1rem","display":"inline-block","width":"20%"},attrs:{"placeholder":"ค้นหารายชื่อ"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"btn btn-download",attrs:{"href":""},on:{"click":function($event){return _vm.exportData()}}},[_c('i',{staticClass:"fa fa-angle-double-down",attrs:{"aria-hidden":"true"}}),_vm._v("ดาวน์โหลดรายงาน")])],1)],1)],1):_vm._e(),_c('b-table',{attrs:{"striped":"","id":"course-student-ranking-table","per-page":_vm.perPage,"current-page":_vm.currentPage,"table-class":"tbNumber text-left ","items":_vm.search == null
              ? _vm.result
              : _vm.result.filter((i) =>
                  (i.mp_firstName + i.mp_lastName).replace(/ /g, '').includes(_vm.search.replace(/ /g, '')) ||
                  i.m_email.includes(_vm.search)
                ),"fields":_vm.filteredFields,"small":"","sort-icon-left":"","responsive":"","busy":_vm.isBusy,"hover":""},scopedSlots:_vm._u([{key:"cell(fullName)",fn:function(data){return [_c('a',{attrs:{"href":`/${_vm.company.url}/analytics/users/${data.item.m_id}/learning-stats`,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm._f("cut")((data.item.mp_firstName + " " + data.item.mp_lastName),_vm.perPage)))])])]}},{key:"cell(email)",fn:function(data){return [_vm._v(_vm._s(data.item.m_email)+" ")]}},{key:"cell(mobilePhone)",fn:function(data){return [_vm._v(_vm._s(data.item.mp_mobilePhone)+" ")]}},{key:"cell(startDate)",fn:function(data){return [_vm._v(_vm._s(_vm._f("thaiDate")(data.item.cer_startDate))+" ")]}},{key:"cell(endDate)",fn:function(data){return [_vm._v(_vm._s(_vm._f("thaiDate")(data.item.cer_endDate))+" ")]}},{key:"cell(learning_time)",fn:function(data){return [_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(data.item.learning_time ? data.item.learning_time : 0)+" ชั่วโมง")])]}},{key:"cell(remainingTime)",fn:function(data){return [_c('span',{staticStyle:{"float":"right"},domProps:{"textContent":_vm._s(data.item.learning_alltime ? data.item.cer_timeLimit - data.item.learning_alltime <= 0 ? '0 ชั่วโมง' : _vm.convertTime(data.item.cer_timeLimit - data.item.learning_alltime) + ' ชั่วโมง' : _vm.convertTime(data.item.cer_timeLimit) + ' ชั่วโมง')}})]}},{key:"cell(completeLecture)",fn:function(data){return [_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(data.item.completeLecture)+" บทเรียน")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}])}),(_vm.perPage > 10)?_c('b-pagination',{attrs:{"total-rows":_vm.search == null
              ? _vm.rows
              : _vm.result.filter((i) =>
                  (i.mp_firstName + ' ' + i.mp_lastName).includes(_vm.search)
                ).length,"per-page":_vm.perPage,"aria-controls":"course-student-ranking-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
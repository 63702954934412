<template>
  <div>
    <layout class="user-learn-progress">
      <template v-slot:header> </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between mb-3">
          <p>รายละเอียดเนื้อหาคอร์ส</p>
        </div>
        <b-table
          striped
          hover
          :fields="fields"
          :items="datalectures"
          :busy="isLoading"
        >
          <template v-slot:cell(lec_name)="data">
            <span>
              <i
                :class="[
                  data.item.lec_type === 'VIDEO'
                    ? 'fa-solid fa-video'
                    : data.item.lec_type === 'SLIDE'
                    ? 'fa-brands fa-slideshare'
                    : 'fa-solid fa-globe',
                  'mr-2',
                ]"
              ></i>
              {{ data.item.lec_name }}
            </span>
          </template>
          <template v-slot:cell(vid_secondLength)="data">
            <span>
              {{
                data.item.vid_secondLength
                  ? timeFormat(data.item.vid_secondLength)
                  : "-" | thaiDurationFilter
              }}
            </span>
          </template>
          <template v-slot:cell(lel_status)="data">
            <span
              :class="[
                data.item.lel_status === 'COMPLETED'
                  ? 'text-success'
                  : data.item.lel_status === 'NOT_STARTED'
                  ? 'text-danger'
                  : 'text-warning',
              ]"
            >
              {{
                data.item.lel_status === "COMPLETED"
                  ? "เรียบจบ"
                  : data.item.lel_status === "NOT_STARTED"
                  ? "ไม่ได้เรียน"
                  : "เรียนไม่จบ"
              }}
            </span>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </template>
    </layout>
  </div>
</template>
<script>
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import api from "@/services/api.js";
import layout from "@/components/pages/analytics/shared/Layout";
export default {
  components: {
    layout,
  },

  mixins: [timeMixins],
  data() {
    return {
      datalectures: [],
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      fields: [
        { key: "lec_name", label: "เนื้อหาคอร์ส" },
        { key: "vid_secondLength", label: "เวลา" },
        { key: "lel_status", label: "สถานะ" },
      ],
      isLoading:true,
    };
  },
  async mounted() {
    await api.get("/v1/learning-lectures/" + this.cerId).then(({ data }) => {
      this.datalectures = data;
      this.isLoading = false;
    });
  },

  methods: {
    timeFormat(seconds) {
      var date = new Date(0);
      date.setSeconds(seconds); // specify value for SECONDS here
      var timeString = date.toISOString().substring(11, 19);
      return timeString;
    },
  },
};
</script>
<style scoped>
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css);
</style>
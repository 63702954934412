<template>
  <b-modal
    :id="id"
    ref="modal"
    size="md"
    centered
    hide-header-close
    hide-footer
    header-border-variant="white"
  >
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title"></h5>
      <b-icon-x style="width: 30px; height: 30px;" @click="close()"></b-icon-x>
    </template>
    <div class="mx-4">
      <h5 class="modal-title">คุณต้องการลบวิดีโอตัวอย่างใช่หรือไม่</h5>
    </div>
    <div class="m-4 text-right">
      <b-button variant="primary" class="m-2" @click="$refs['modal'].hide()"
        >ยกเลิก</b-button
      >
      <b-button variant="outline-primary" class="m-2" @click="submit()"
        >ลบวิดีโอ</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DeleteIntroModal',
  data() {
    return {
      courseId: this.$route.params.courseId,
    };
  },
  props: {
    id: {
      type: String,
      default: 'deleteIntroVideoModal',
    },
    attribute: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions('CourseManagement', ['updateCourseInfo', 'getCourseInfo', 'deleteCourseIntro']),
    async submit() {
      this.deleteCourseIntro(this.$route.params.courseId)
        .then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: 'numberOfStudent,numberOfActiveStudent',
          });
          this.$bvToast.toast(`ลบวิดีโอแนะนำคอร์สแล้ว`, {
            title: `สำเร็จแล้ว`,
            variant: 'success',
            solid: true,
          });
          this.$emit('submit-successful');
          this.$refs['modal'].hide();
        })
        .catch((err) => {
          this.$bvToast.toast(`ลบวิดีโอแนะนำคอร์สล้มเหลว`, {
            title: `เกิดข้อผิดพลาด`,
            variant: 'danger',
            solid: true,
            noAutoHide: true,
          });
          console.log(err);
        });
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('layout',{staticClass:"user-learn-progress",scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('p',[_vm._v("การตรวจพบใบหน้า")]),_c('a',{attrs:{"href":`/${_vm.company.url}/analytics/users/${_vm.$route.params.mId}/learning-stats/learn-progress/${_vm.$route.params.cerId}/courses/${_vm.$route.params.cId}/face-detect`,"target":"_blank"}},[_vm._v("ดูทั้งหมด")])]),_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.datalectures,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"cell(lec_name)",fn:function(data){return [_c('span',[_c('i',{class:[
                data.item.lec_type === 'VIDEO'
                  ? 'fa-solid fa-video'
                  : data.item.lec_type === 'SLIDE'
                  ? 'fa-brands fa-slideshare'
                  : data.item.lec_type === 'QUIZ'
                  ? 'fa-solid fa-list'
                  : 'fa-solid fa-globe',
                'mr-2',
              ]}),_vm._v(" "+_vm._s(data.item.lec_name)+" ")])]}},{key:"cell(llfd_status)",fn:function(data){return [_c('span',{class:[
              data.item.llfd_status === 'ผ่าน'
                ? 'text-success'
                : data.item.llfd_status === 'ไม่ผ่าน'
                ? 'text-danger'
                : '',
            ]},[_vm._v(" "+_vm._s(data.item.llfd_status)+" ")])]}},{key:"cell(llfd_learningFaceApprovedProgress)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.llfd_learningFaceApprovedProgress)),(data.item.llfd_learningFaceApprovedProgress != '-')?_c('span',[_vm._v("%")]):_vm._e()])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
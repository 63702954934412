<template>
  <div>
    <b-card no-body class="mb-2 no-shadow">
      <b-card-header
        header-tag="header"
        class="bg-white border-bottom-0 pl-1 pr-2"
        role="tab"
      >
        <b-media no-body>
          <b-media-aside vertical-align="center">
            <img
              src="/images/icons/icon_movable.png"
              alt="movable"
              height="20"
              class="align-middle pointer"
            />
          </b-media-aside>
          <b-media-body>
            <b-row no-gutters align-v="center">
              <p>{{ item.lec_no }}</p>
              <b-col cols="auto" class="py-0 pt-1 px-2">
                <b-form-checkbox
                  :id="`FreeFlag_${item.lectureId}`"
                  v-model="freeFlag"
                  :name="`FreeFlag_${item.lectureId}`"
                  size="sm"
                >
                  เนื้อหาฟรี
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div class="border-left py-0 px-2">
                  <div v-if="!onEditing">
                    <a href="#" class="" @click="toggleEditing(true)">
                      <u>{{ item.lectureName }}</u>
                    </a>
                  </div>
                  <div v-else>
                    <b-input-group>
                      <b-form-input
                        v-model="item.lectureName"
                        type="text"
                        size="sm"
                        class="border-right-0"
                        autofocus
                        :state="validateLectureName"
                        @blur="toggleEditing(false)"
                        @keydown.esc="toggleEditing(false)"
                        @keyup.enter="update()"
                      ></b-form-input>

                      <template v-slot:append>
                        <b-input-group-text class="bg-white">
                          <span
                            v-if="isLoading"
                            class="
                              spinner-border spinner-border-sm
                              text-warning
                            "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                    <span
                      v-if="!validateLectureName"
                      class="mt-1 small text-danger"
                    >
                      ไม่เกิน 100 ตัวอักษร
                    </span>
                  </div>
                </div>
              </b-col>

              <b-col
                v-if="uploadVideoPercent > 0 && uploadVideoPercent < 100"
                class="text-right"
                cols="3"
              >
                <b-row no-gutters align-v="center">
                  <b-col
                    v-if="videoFile"
                    cols="12"
                    class="text-truncate small text-muted text-left"
                  >
                    <b-icon-x-circle
                      size="sm"
                      variant="danger"
                      style="cursor: pointer"
                      @click="reloadPage()"
                    />
                    {{ videoFile.name }}
                  </b-col>
                  <b-col cols="9">
                    <b-progress
                      :max="100"
                      variant="warning"
                      :value="uploadVideoPercent"
                      height="10px"
                      width="100px"
                      animated
                    ></b-progress>
                  </b-col>
                  <b-col cols="3" class="pl-1 text-left">
                    <span class="small text-warning">
                      {{ `${uploadVideoPercent.toFixed(0)}%` }}
                    </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="auto">
                <b-row no-gutters>
                  <b-col class="text-right">
                    <LectureVideoProcess
                      v-if="item.lectureType === 'VIDEO' && onProcessing"
                      :ref="`lecVidProcess_${item.lectureId}`"
                      :lecture-id="item.lectureId"
                      :company="company"
                      class="mr-2"
                      @processed="onProcessing = false"
                    />
                    <QuizLectureOptions
                      v-else-if="item.lectureType === 'QUIZ'"
                      :flag="item.quizLectureFlag"
                      :lectureId="item.lectureId"
                      @updated="updateQuizLectureFlag"
                    />
                    <span v-else class="mr-2">
                      <b-icon
                        v-if="item.lectureType"
                        :icon="getLectureTypeIcon(item.lectureType)"
                        scale="1.2"
                        variant="success"
                        class="mt-2"
                      />
                    </span>
                  </b-col>
                  <b-col v-if="checktype" cols="auto">
                    <span class="small text-danger">
                      กรุณาแนบไฟล์ {{ messageCheckType }} เท่านั้น
                    </span>
                  </b-col>
                  <b-col cols="auto">
                    <b-dropdown
                      size="sm"
                      variant="outline-primary"
                      text="แก้ไขไฟล์"
                      class="mr-1"
                    >
                      <b-dropdown-item
                        @click="browseFile(`videoFile_${item.lectureId}`)"
                        >เลือกไฟล์วิดีโอ
                      </b-dropdown-item>
                      <b-dropdown-item v-b-modal="`modal-${item.lectureId}`"
                        >เลือกวีดีโอจาก Youtube
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="browseFile(`slideFile_${item.lectureId}`)"
                        >เลือกไฟล์สไลด์ (PDF)
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          addModalExamShow = true;
                          selectExam();
                        "
                        >เลือกข้อสอบ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="isAccountingType() || isInsuranceType()"
                        @click="
                          addModalWebEmbedShow = true;
                          selectWebEmbed();
                        "
                        >เลือกเว็ปไซต์ (URL)
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="checkBeforeRemove"
                    >
                      <b-icon-trash scale="0.7" />ลบ
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
        <b-form-file
          :id="`videoFile_${item.lectureId}`"
          :ref="`videoFile_${item.lectureId}`"
          v-model="videoFile"
          accept=".mp4"
          :name="`videoFile_${item.lectureId}`"
          :state="Boolean(videoFile)"
          drop-placeholder="Drop file here..."
          browse-text=" "
          class="d-none"
          @input="_uploadVideoFile"
        ></b-form-file>

        <b-form-file
          :id="`slideFile_${item.lectureId}`"
          :ref="`slideFile_${item.lectureId}`"
          v-model="slideFile"
          accept=".pdf"
          :name="`slideFile_${item.lectureId}`"
          :state="Boolean(slideFile)"
          drop-placeholder="Drop file here..."
          browse-text=" "
          class="d-none"
          @input="_uploadSlideFile"
        ></b-form-file>
      </b-card-header>
      <YoutubeUploadModal
        :id="`modal-${item.lectureId}`"
        @youtube-url="_updateYoutubeVideo"
      />
    </b-card>

    <div>
      <b-modal
        v-model="addModalExamShow"
        title="เลือกข้อสอบ"
        size="xl"
        :hide-footer="true"
        ref="modal-exam"
        centered
      >
        <ExamModal
          :lecId="item.lectureId"
          :courseId="courseId"
          @hideModalExam="hideModalExam($event)"
        />
      </b-modal>
    </div>

    <div>
      <b-modal
        v-model="addModalWebEmbedShow"
        title="กรุณาใส่ข้อมูล Embed Website"
        size="xl"
        :hide-footer="true"
        ref="modal-webembed"
        centered
        id="modal-webembed"
      >
        <WebEmbedModal
          :lecId="item.lectureId"
          :courseId="courseId"
          @hideModalWebEmbedModal="hideModalWebEmbedModal($event)"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LectureVideoProcess from "./LectureVideoProcess";
import YoutubeUploadModal from "./YoutubeUploadModal";
import QuizLectureOptions from "./QuizLectureOptions";
import ExamModal from "./ExamModal";
import WebEmbedModal from "./WebEmbedModal";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  name: "LectureItem",
  components: {
    LectureVideoProcess,
    YoutubeUploadModal,
    QuizLectureOptions,
    ExamModal,
    WebEmbedModal,
  },
  props: {
    company: { default: null, type: Object },
    item: {
      type: Object,
      default: null,
    },
    moduleId: {
      type: Number,
      default: null,
    },
  },
  mixins: [authMixin],
  data() {
    return {
      addModalExamShow: false,
      addModalWebEmbedShow: false,
      lectureName: this.item.lectureName,
      lectureFreeFlag: this.item.lectureFreeFlag === 1,
      lectureType: this.item.lectureType,
      courseId: this.$route.params.courseId,
      onEditing: false,
      videoFile: null,
      slideFile: null,
      onUploading: false,
      onProcessing: true,
      checktype: null,
      messageCheckType: "",
    };
  },

  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseAttributeUpdated",
      "uploadVideoLecturePercentage",
    ]),
    freeFlag: {
      get() {
        return this.item.lectureFreeFlag == 1;
      },
      set(val) {
        this.item.lectureFreeFlag = val;
        this.update();
      },
    },
    type: {
      get() {
        return this.item.lectureType;
      },
      set(val) {
        this.lectureType = val;
        this.update();
      },
    },

    uploadVideoPercent: {
      get() {
        const lecture = this.uploadVideoLecturePercentage.find(
          (item) => item.lectureId === this.item.lectureId
        );

        return lecture ? lecture.percent : null;
      },
    },

    validateLectureName() {
      return this.item.lectureName.length >= 0 && this.item.lectureName.length <= 100;
    },
  },

  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "fetchCourseModulesList",
      "updateCourseInfo",
      "updateLecture",
      "deleteLecture",
      "uploadLectureVideo",
      "uploadLectureSlide",
      "getVideoProcessStatus",
    ]),

    selectExam() {
      this.addModalExamShow = true;
    },

    selectWebEmbed() {
      this.addModalWebEmbedShow = true;
    },

    async update(data = null) {
      if (data) {
        data = {
          courseId: this.courseId,
          lectureId: this.item.lectureId,
          ...data,
        };
      } else {
        data = {
          courseId: this.courseId,
          lectureId: this.item.lectureId,
          lectureName: this.item.lectureName,
          lectureFreeFlag: this.item.lectureFreeFlag == 1,
          lectureType: this.item.lectureType,
        };
      }

      if (this.validateLectureName) {
        await this.updateLecture(data).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: "numberOfStudent,numberOfActiveStudent",
          });
          this.toggleEditing(false);
          this.fetchCourseModulesList({
            courseId: this.courseId,
          }).then(() => {
            this.onProcessing = true;
          });
        });
      }
    },
    checkBeforeRemove() {
      if (this.item.quizLectureFlag) {
        const flagLabels = {
          PRETEST: "แบบทดสอบก่อนเรียน",
          POSTTEST: "แบบทดสอบหลังเรียน",
        };
        this.$bvModal
          .msgBoxConfirm(
            `คุณไม่สามารถลบข้อสอบ ${this.item.lectureName} ได้เนื่องจากเป็น ${
              flagLabels[this.item.quizLectureFlag]
            }`,
            {
              title: "แจ้งเตือนการลบแบบทดสอบ",
              buttonSize: "sm",
              okTitle: "ลบข้อสอบ",
              okVariant: "secondary disabled",
              cancelTitle: "ปิด",
              cancelVariant: "outline-primary",
              hideHeaderClose: false,
              headerCloseVariant: "dark",
              headerCloseContent: "&times;",
              headerBgVariant: "white",
              headerClass: "py-2 px-3",
              footerClass: "p-3 border-0",
              centered: true,
              okDisabled: true,
            }
          )
          .then(() => {});
      } else {
        this.remove();
      }
    },
    remove() {
      this.$bvModal
        .msgBoxConfirm(
          `คุณต้องการลบบทเรียน ${this.item.lectureName} ใช่หรือไม่?`,
          {
            title: "แจ้งเตือนการลบบทเรียน",
            buttonSize: "sm",
            okTitle: "ลบบทเรียน",
            okVariant: "primary",
            cancelTitle: "ยกเลิก",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            headerCloseVariant: "white",
            headerCloseContent: "&times;",
            headerBgVariant: "danger",
            headerClass: "py-2 px-3 border-0",
            footerClass: "p-3 border-0",
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.updateCourseInfo({
              courseId: this.courseId,
            });
            this.deleteLecture({
              moduleId: this.moduleId,
              courseId: this.courseId,
              lectureId: this.item.lectureId,
            }).then(() => {
              this.getCourseInfo({
                courseId: this.courseId,
                temp: true,
                excepts: "numberOfStudent,numberOfActiveStudent",
              });
              this.fetchCourseModulesList({
                courseId: this.courseId,
              });
            });
          }
        });
    },
    toggleCollapse(collapseId) {
      this.$root.$emit("bv::toggle::collapse", collapseId);
    },
    browseFile(elementId) {
      var elem = document.getElementById(elementId);
      if (elem && document.createEvent) {
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("click", true, false);
        elem.dispatchEvent(evt);
      }
    },

    async _uploadVideoFile() {
      const ext = this.videoFile.type.split("/").pop();
      const allowedExtensions = ["mp4"];

      if (!allowedExtensions.includes(ext)) {
        this.checktype = true;
        this.messageCheckType = "mp4";
      } else {
        this.checktype = false;
        await this.uploadLectureVideo({
          courseId: this.courseId,
          companyId: this.company.id,
          videoFile: this.videoFile,
          lectureId: this.item.lectureId,
          youtubeUrl: "",
          moduleId: this.moduleId,
        }).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: "numberOfStudent,numberOfActiveStudent",
          });
          this.onUploading = false;
          this.lectureType = "VIDEO";
          this.update({
            lectureType: "VIDEO",
          });
        });
      }
    },

    async _uploadSlideFile() {
      const ext = this.slideFile.type.split("/").pop();
      const allowedExtensions = ["pdf"];
      console.log(this.slideFile.type.split("/").pop());

      if (!allowedExtensions.includes(ext)) {
        this.checktype = true;
        this.messageCheckType = "pdf";
      } else {
        this.checktype = false;
        await this.uploadLectureSlide({
          courseId: this.courseId,
          companyId: this.company.id,
          slideFile: this.slideFile,
          lectureId: this.item.lectureId,
        }).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: true,
            excepts: "numberOfStudent,numberOfActiveStudent",
          });
          this.onUploading = false;
          this.lectureType = "SLIDE";
          this.update({
            lectureType: "SLIDE",
          });
        });
      }
    },

    async _updateYoutubeVideo(youtubeUrl) {
      await this.uploadLectureVideo({
        courseId: this.courseId,
        companyId: this.company.id,
        lectureId: this.item.lectureId,
        moduleId: this.moduleId,
        youtubeUrl: youtubeUrl,
      }).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
        this.onUploading = false;
        this.lectureType = "VIDEO";
        this.update({
          lectureType: "VIDEO",
        });
      });
    },

    updateQuizLectureFlag() {
      this.update({
        lectureType: "QUIZ",
      }).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: true,
          excepts: "numberOfStudent,numberOfActiveStudent",
        });
      });
    },

    getLectureTypeIcon(type) {
      if (type === "VIDEO") return "camera-video";
      else if (type === "SLIDE") return "file-earmark-text";
      else if (type === "QUIZ") return "pencil-square";
      else if (type === "EMBED_WEB") return "globe";
      else return "file-earmark-check";
    },

    toggleEditing(val) {
      this.onEditing = val;
      this.$emit("on-editing", val);
    },

    reloadPage() {
      window.location.reload();
    },

    hideModalExam() {
      this.$refs["modal-exam"].hide();
    },

    hideModalWebEmbedModal() {
      this.$refs["modal-webembed"].hide();
    },
  },
};
</script>

<style lang="scss" scope>
.border-left-dashed {
  border-left: 1px dashed #dee2e6 !important;
}
iframe {
  width: 100%;
  height: 100%;
}
.dropdown-menu.show{
  width: auto !important;
}
</style>

<template>
  <div class="mb-5">
    <PageHeader title="ลงทะเบียนเรียน" />
    <b-container fluid class="outer">
      <div>
        <b-nav tabs>
          <b-nav-item
            v-for="(item, index) in tabs"
            :key="index"
            :active="activeTabIndex === index"
            @click="activeTabIndex = index"
          >
            {{ item.label }}
          </b-nav-item>
        </b-nav>
      </div>
      <b-card class="border-top-0 rounded-0 no-shadow" body-class="py-4">
        <div class="border text-right p-2">
          <span class="text-primary">จำนวนผู้เรียนที่เลือก:</span>
          {{ selectedItems["members"].length }},

          <span class="text-primary">จำนวนกลุ่มผู้เรียนที่เลือก:</span>
          {{ selectedItems["memberGroups"].length }}
        </div>
        <SelectMembersTable v-if="activeTabIndex === 0" class="mt-4" />
        <SelectMemberGroupsTable v-if="activeTabIndex === 1" class="mt-4" />
      </b-card>
    </b-container>
    <CourseEnrollNavbar :sidebar-opened="sidebarOpened" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageHeader from "@/components/layouts/PageHeader";
import CourseEnrollNavbar from "./partials/CourseEnrollNavbar";
import SelectMembersTable from "./partials/SelectMembersTable";
import SelectMemberGroupsTable from "./partials/SelectMemberGroupsTable";

export default {
  name: "CourseEnrollStep2Page",
  components: {
    PageHeader,
    CourseEnrollNavbar,
    SelectMembersTable,
    SelectMemberGroupsTable
  },
  props: {
    sidebarOpened: Boolean
  },
  data() {
    return {
      tabs: [
        { label: "เลือกผู้เรียน", isActive: false },
        { label: "เลือกกลุ่มผู้เรียน", isActive: false }
      ],
      activeTabIndex: 0
    };
  },
  computed: {
    ...mapGetters("CourseEnrollment", ["selectedItems"])
  }
};
</script>

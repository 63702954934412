<template>
  <div>
    <PageHeader
      title="ประวัติการเข้าเรียน"
      style="margin-left: -10%;"
    ></PageHeader>
    <b-container class="widthPage">
      <b-row class="mt-5">
        <b-col cols="6">
          <h6 class="mb-3"><strong>ค้นหาผู้เรียน</strong></h6>
          <v-select
            :options="membersData"
            v-model="selectedMember"
            placeholder="พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา..."
            :disabled="hasMembersData"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6">
          <h6 class="mb-3"><strong>เลือกคอร์สเรียน</strong></h6>
          <v-select
            :options="coursesData"
            v-model="selectedCourseEnroll"
            placeholder="พิมพ์ชื่อคอร์สที่ต้องการค้นหา..."
            :disabled="hasCoursesData"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-button
        @click="searchForLearningHistory()"
        class="mt-4"
        variant="primary"
      >
        ค้นหาประวัติการเข้าเรียน
      </b-button>

      <LearningHistoryLogPanel
        :isLoading="isLoading"
        :learningHistoryList="learningHistoryList"
        :isCardShow="isCardShow"
        :memberName="selectedMember ? selectedMember.label : ''"
      />
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";
import vSelect from "vue-select";
import PageHeader from "@/components/layouts/PageHeader.vue";
import LearningHistoryLogPanel from "./panel/LearningHistoryLogPanel.vue";

export default {
  components: {
    PageHeader,
    vSelect,
    LearningHistoryLogPanel,
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      membersData: [],
      selectedMember: null,
      hasMembersData: true,
      coursesData: [],
      selectedCourseEnroll: null,
      hasCoursesData: true,
      isCardShow: false,
    };
  },
  created() {
    this.getMemberList();
  },
  computed: {
    ...mapGetters("LearningHistory", ["isLoading", "learningHistoryList"]),
  },
  methods: {
    ...mapActions("LearningHistory", ["getLearningHistoryList"]),
    async getMemberList() {
      await api
        .get(`/v1/companies/${this.comId}/enrolled-members`)
        .then(({ data }) => {
          this.membersData = data.map((member) => ({
            label: member.mp_firstName + " " + member.mp_lastName,
            value: member.m_id,
          }));
          this.hasMembersData = false;
        });
    },
    async searchForLearningHistory() {
      this.isCardShow = true;
      let cerId = this.selectedCourseEnroll.value;
      await this.getLearningHistoryList(cerId);
    },
  },
  watch: {
    selectedMember(newMember) {
      if (newMember !== null) {
        api.get(`v1/members/${newMember.value}/enrolls`).then(({ data }) => {
          this.coursesData = data.map((course) => ({
            label: course.courseName,
            value: course.cerId,
          }));
          this.hasCoursesData = false;
        });
      }
    },
    selectedCourseEnroll(newCourse) {
      if (newCourse !== null) {
        this.selectedCourseEnroll = newCourse;
      }
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}

.widthPage {
  max-width: 81.25rem;
}
</style>

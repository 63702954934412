<template>
  <div>
    <PageHeader title="ตั้งค่าอื่นๆ"></PageHeader>
    <div class="outer">
      <div class="inner bg-container pb-5">
        <CourseNavSteps />
        <b-overlay :show="false" variant="white">
          <Loading
            v-if="isLoading"
            class="mt-2 fixed-top"
            style="z-index: 999999"
          />
          <div class="ml-4">
            <br />
            <PreqCourse v-if="comId != 515" :cId="courseId" />
            <FaceGesture />
            <h5 class="d-block mt-5">
              <b-form-checkbox
                switch
                class="d-inline"
                @input="hasEvaluationToggle"
                v-model="hasState"
              />
              แบบประเมินหลังเรียนจบ
            </h5>
            <div v-if="this.hasState" style="margin-left: 2.5em">
              <p class="ml-4">กรุณาใส่ลิงค์ของแบบประเมิน</p>
              <b-form-input
                type="text"
                class="w-75 h-50 ml-4 d-inline mb-2"
                placeholder="https://form.jotform.com/{formId}"
                v-model="url"
                @keyup.enter="checkUrl"
              />
              <button
                class="ml-4 btn btn-primary p-1 mb-1"
                style="width: 50px"
                @click="openUrl"
              >
                <b-icon
                  style="margin-bottom: 5%"
                  icon="box-arrow-up-right"
                ></b-icon>
              </button>
              <p
                class="mt-3 ml-4"
                name="validation"
                :style="'color:' + this.statusColor"
              >
                {{ this.linkStatus }}
              </p>
              <div v-if="isJotform">
                <label class="d-block ml-5 mb-3"
                  >ต้องทำแบบประเมินก่อนถึงจะเรียนจบ
                  <b-form-checkbox
                    switch
                    class="d-inline"
                    v-model="mustState"
                    @input="mustDoToggle"
                  />
                </label>
                <div v-if="this.mustState" style="display: inline-block">
                  <div
                    style="width: 60%"
                    class="alert alert-warning ml-5 h-100"
                  >
                    <div
                      style="
                        width: 60%;
                        display: inline-block;
                        vertical-align: middle;
                      "
                    >
                      กรุณาใช้แบบฟอร์มที่รองรับการตั้งค่า Hidden Field และ
                      Webhook URL เช่น <a>Jotform</a>
                      หากตั้งค่าฟอร์มไม่ถูกต้อง
                      ระบบจะไม่สามารถตรวจสอบได้ว่าผู้เรียนนั้นได้ทำแบบประเมินเสร็จเรียบร้อยแล้ว
                    </div>
                    <ReportGuideModal style="display: inline-block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <CourseNavToolbar
          :sidebar-opened="sidebarOpened"
          :course="courseInfo"
          :company="company"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";
import PageHeader from "../../layouts/PageHeader";
import CourseNavSteps from "./partials/CourseNavSteps";
import PreqCourse from "./partials/PreqCourse";
import FaceGesture from "./FaceGestureEnable";
import ReportGuideModal from "./ReportGuideModal";
import Loading from "../../shared/Loading";
import CourseNavToolbar from "./partials/CourseNavToolbar";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EvaluationReportForm",
  components: {
    PageHeader,
    CourseNavSteps,
    CourseNavToolbar,
    ReportGuideModal,
    Loading,
    PreqCourse,
    FaceGesture
  },
  props: {
    company: { default: null, type: Object },
    sidebarOpened: Boolean
  },
  data() {
    return {
      hasState: null,
      mustState: null,
      url: "",
      linkStatus: "",
      statusColor: "",
      courseId: this.$route.params.courseId,
      mustDoForm: false,
      isJotform: "",
      comId: this.$store.state.Company.companyInfo.id,
    };
  },

  async created() {
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },

  async mounted() {
    const getEvaluation = await api.get(
      `/v1/courses/` + this.courseId + `/getEvaluation`
    );
    this.hasState = getEvaluation.data[0].hasEvaluation == 1 ? true : false;
    this.url = getEvaluation.data[0].url;
    await this.fetchCourseCompleteRules(this.courseId).then(() => {});
    await this.fetchCourseExamRules(this.courseId).then(() => {});
    this.mustState = this.courseCompleteRule.evaluation ? true : false;
    this.checkUrl();
  },

  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
    ...mapGetters("CourseCompleteRule", [
      "courseCompleteRule",
      "courseCompleteActions",
      "courseLectures",
      "courseExamRules"
    ])
  },
  methods: {
    ...mapActions("CourseManagement", ["getCourseInfo"]),
    ...mapActions("CourseCompleteRule", [
      "fetchCourseCompleteRules",
      "fetchCourseExamRules",
      "updateCourseCompleteRules",
      "updateCourseCompleteActions"
    ]),
    async update() {
      await this.updateCourseCompleteRules({
        courseId: this.courseId,
        isUseExamRules: this.isUseExamRules
      });

      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent"
      });
    },
    async updateActions() {
      await this.updateCourseCompleteActions({
        courseId: this.courseId,
        hasPdfAction: this.hasPdfAction
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent"
      });
    },

    async hasEvaluationToggle() {
      await api
        .put(
          `/v1/courses/` +
            this.courseId +
            `/hasEvaluation/${this.hasState ? 1 : 0}`
        )
        .then(() => {
          if (!this.hasState) {
            this.courseCompleteRule.evaluation = 0;
          }
          this.update();
        })
        .catch(() => {
          console.log("ERROR");
        });
    },

    mustDoToggle() {
      this.courseCompleteRule.evaluation = this.mustState ? 1 : 0;
      this.update();
    },

    openUrl() {
      window.open(this.url);
      this.checkUrl();
    },

    async checkUrl() {
      if (
        this.url.includes("docs.google.com/forms") == true ||
        this.url.includes("forms.gle") == true
      ) {
        this.linkStatus = "ลิงค์นี้เป็นลิงค์ของ Google Form";
        this.statusColor = "green";
        this.mustDoForm = false;
        this.courseCompleteRule.evaluation = 0;
        this.mustState = false;
        this.isJotform = false;
        this.update();
        await api.put(`/v1/courses/` + this.courseId + `/evaluationUrl`, {
          evaluationUrl: this.url
        });
        return 0;
      } else if (this.url.includes("form.jotform.com") == false) {
        this.linkStatus =
          "ลิงค์นี้ไม่ใช่ลิงค์ของ Jotform หรือ Google Form กรุณาใส่ลิงค์ใหม่";
        this.url = "";
        this.statusColor = "red";
        this.mustDoForm = false;
        this.courseCompleteRule.evaluation = 0;
        this.mustState = false;
        this.isJotform = false;
        this.update();
        return 0;
      }
      axios
        .get(this.url)
        .then(() => {
          this.linkStatus =
            "ลิงค์แบบประเมินนี้สามารถใช้งานได้ กรุณากดปุ่มพรีวิวด้านขวาเพื่อตรวจสอบความถูกต้องของแบบประเมิน";
          this.statusColor = "green";
          this.isJotform = true;
          this.mustDoForm = true;
        })
        .catch(() => {
          this.linkStatus = "ลิงค์นี้ไม่สามารถใช้งานได้ กรุณาใส่ลิงค์ใหม่";
          this.statusColor = "red";
          this.url = "";
          this.mustState = false;
          this.mustDoForm = false;
          this.courseCompleteRule.evaluation = 0;
          this.isJotform = false;
          this.update();
        });
      await api.put(`/v1/courses/` + this.courseId + `/evaluationUrl`, {
        evaluationUrl: this.url
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent"
      });
    }
  }
};
</script>

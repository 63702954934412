<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <h6>ผู้เรียนที่ยังไม่เริ่มเรียน</h6>
      </template>
      <b-row class="mb-3 mt-3" v-if="perPage == 10">
        <b-col cols="9">
          จำนวน {{ result && result.length }} คน คิดเป็น
          {{
            stat.active == 0
              ? (0.0).toFixed(2)
              : stat &&
                result &&
                ((result.length / stat.active) * 100).toFixed(2)
          }}
          % ของผู้เรียนที่ Active
        </b-col>
        <b-col cols="3" class="text-right" style="padding-bottom:1rem">
          <a
            :href="
              `/${companyInfo.url}/analytics/courses/${cId}/student-not-started`
            "
            target="_blank"
            >ดูทั้งหมด</a
          >
        </b-col>
      </b-row>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-form-input
              v-model="nameSearch"
              placeholder="ค้นหารายชื่อ"
              style="padding:1rem;display:inline-block;width:20%"
            >
            </b-form-input>
            <b-row>
            <b-col cols="10"> </b-col>
            <b-col cols="2" class="text-right">
              <b-button @click="exportData()" class="btn btn-download" href=""
                ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
              >
            </b-col>
          </b-row>
          </div>
          <b-table
            striped
            id="course-student-not-started-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="filterData()"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(fullName)="data"
              ><span>{{
                data.item.mp_firstName + ' ' + data.item.mp_lastName
              }}</span></template
            >
            <template v-slot:cell(email)="data"
              ><span>{{
                data.item.m_email
              }}</span></template
            >            
            <template v-slot:cell(username)="data"
              ><span>{{
                data.item.m_username
              }}</span></template
            >
            <template v-slot:cell(startDate)="data"
              ><span>{{
                data.item.cer_startDate
              }}</span></template
            >
            <template v-slot:cell(timeout)="data">
              <span>{{
                data.item.dayLeft
                  ? data.item.dayLeft + ' วัน'
                  : 'ไม่มีวันหมดอายุ'
              }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="filterData().length"
            :per-page="perPage"
            aria-controls="course-student-not-started-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import exp from "@/services/export.js";
import api from '@/services/api.js';
export default {
  components: {
    layout,
  },
  props: {apiRoute:String, perPage:Number, apiRoute2:String},
  data() {
    return {
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      nameSearch: null,
      currentPage: 1,
      rows: 0,
      isBusy: true,
      result: [],
      stat: {},
      fields: [
        { key: 'fullName', sortable: false, label: 'รายชือผู้เรียน' },
        this.perPage <=10 ? null : { key: 'email', sortable: false, label: 'อีเมล' },
        this.perPage <=10 ? null : { key: 'username', sortable: false, label: 'ชื่อผู้ใช้งาน' },
        this.perPage <=10 ? null : { key: 'startDate', sortable: false, label: 'วันที่ลงทะเบียนเรียน' },
        { key: 'timeout', sortable: false, label: 'เหลือเวลาเรียน' },
      ],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    filterData() {
      var data = this.result.filter(
        (i) =>{
          let passFilter = true;
          if(this.nameSearch!=null){
            passFilter = passFilter && (i.mp_firstName + ' ' + i.mp_lastName).includes(this.nameSearch)
          }
          return passFilter;
        }
      );
      return data;
    },
    async exportData() {
      let value = [];
      let set = this.filterData();
      set.forEach((i) => {
        let temp = {
          name: i.mp_firstName + ' ' + i.mp_lastName,
          email: i.m_email,
          username: i.m_username,
          startDate: i.cer_startDate,
          dayLeft: i.dayLeft ? i.dayLeft + ' วัน' : 'ไม่มีวันหมดอายุ',
        };
        value.push(temp);
      });
      let key = [
        "ชื่อ",
        "อีเมล",
        "ชื่อผู้ใช้งาน",
        "วันที่ลงทะเบียนเรียน",
        "เหลือเวลาเรียน",
      ];
      let filename = "Student-Not-Started-" + this.cId;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    let res2 = await api.get(this.apiRoute2);
    this.stat = res2.data;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>

<template>
  <div>
    <Loading v-if="onLoading" class="fixed-top mt-2" />

    <PageHeader title="จัดการกลุ่มคอร์ส"></PageHeader>
    <b-container fluid="xl">
      <div class="outer">
        <div class="inner bg-container pb-5">
          <h4 class="mb-3">ข้อมูลกลุ่มคอร์ส</h4>
          <CourseGroupInfoForm />

          <h4 class="mt-4">เลือกคอร์ส</h4>

          <b-alert :show="hasStudent" variant="danger">
            ไม่สามารถจัดการคอร์สได้ เนื่องจากมีผู้เรียนลงทะเบียนเรียนแล้ว
          </b-alert>

          <b-row>
            <b-col v-if="!isTutorType() || comId == 524">
              <b-form-checkbox
                v-model="form.courseGroupPrequisiteFlag"
                class="mb-2"
                :value="1"
                :unchecked-value="0"
                switch
                :disabled="hasStudent"
                @change="update"
                >ตั้งเป็น Pre-requisite
              </b-form-checkbox>
            </b-col>
            <b-col class="text-right">
              <b-button
                variant="primary"
                :disabled="hasStudent"
                @click="openSelectCoursesModal"
              >
                <b-icon-plus />เพิ่มคอร์ส
              </b-button>
            </b-col>
          </b-row>

          <b-card body-class="pb-0" class="mt-2 no-shadow">
            <b-table-simple borderless class="my-0">
              <thead>
                <tr>
                  <th v-if="!hasStudent" width="20"></th>
                  <th class="text-right">ลำดับคอร์ส</th>
                  <th>ชื่อคอร์ส</th>
                  <th>โดย</th>
                  <th class="text-center">เวลาที่ใช้เรียน</th>
                  <th v-if="form.courseGroupPrequisiteFlag"></th>
                  <th></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                :list="courseGroupCourses"
                :move="checkMove"
                :disabled="hasStudent"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
              >
                <tr
                  v-for="(element, index) in courseGroupCourses"
                  :key="element.courseId"
                  class="border-top bg-white"
                >
                  <td v-if="!hasStudent" class="pl-0">
                    <img
                      src="/images/icons/icon_movable.png"
                      alt="movable"
                      height="20"
                      class="align-middle pointer"
                    />
                  </td>
                  <td class="text-right">
                    {{
                      courseGroupInfo.courseGroupPrequisiteFlag === 1
                        ? element.courseInGroupPrerequisiteOrder
                        : element.courseInGroupOrder
                    }}
                  </td>
                  <td>{{ element.courseName }}</td>
                  <td>{{ element.courseOwner.ownerName }}</td>
                  <td class="text-center">
                    {{ element.courseDuration | duration }}
                  </td>
                  <td v-if="courseGroupInfo.courseGroupPrequisiteFlag">
                    <b-select
                      v-if="index > 0"
                      :id="`select-prerequisiteMode-${element.courseId}`"
                      :ref="`select-prerequisiteMode-${element.courseId}`"
                      :value="element.courseInGroupPrerequisiteMode"
                      :options="preRequisiteModeOptions"
                      :disabled="hasStudent"
                      size="sm"
                      @change="setPrequisiteMode(element.courseId)"
                    />
                  </td>
                  <td>
                    <b-icon-x
                      v-show="!hasStudent"
                      scale="1.5"
                      class="text-muted"
                      @click="removeCourse(element.courseId)"
                    />
                  </td>
                </tr>
              </draggable>
            </b-table-simple>
          </b-card>
          <div v-if="company.id == 225">
            <h4 style="display: inline-block" class="mt-4 mr-3">
              เพิ่มการจอง Workshop เมื่อเรียนจบทุกคอร์ส
            </h4>
            <b-form-checkbox
              style="display: inline-block"
              v-model="workshopState"
              @change="updateWorkshopState"
              class="mb-2"
              switch
            />
            <div
              v-if="workshopState == true"
              id="workshop-field"
              class="ml-5 mt-4"
            >
              <b-form-group label="ตั้งค่า Workshop" label-for="title">
                <div class="ml-5 mt-2">
                  <p style="color: red">{{ statusText }}</p>
                  <b-form-group label="ชื่อ Workshop" label-for="title">
                    <b-form-input
                      id="ws-name"
                      placeholder="ชื่อ Workshop"
                      class="w-50"
                      @change="updateWorkshopName"
                      @input="checkNameInput"
                      :value="workshopName"
                      :state="workshopNameValid"
                    />
                  </b-form-group>
                  <b-form-group label="ลิงค์ Workshop" label-for="title">
                    <b-form-input
                      id="ws-url"
                      class="w-50"
                      placeholder="ลิงค์ Workshop"
                      @change="updateWorkshopUrl"
                      @input="checkUrlInput"
                      :value="workshopUrl"
                      :state="workshopUrlValid"
                    />
                  </b-form-group>
                  <b-form-group
                    label="เลือกรูปหน้าปก Workshop"
                    label-for="title"
                  >
                    <div style="display: flex; height: 175px">
                      <div
                        style="
                        display: inline-block;
                        height: 175px;
                        width: 311px;
                        overflow: hidden;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      "
                      >
                        <img style="width: 311px" :src="imagePreview" />
                      </div>
                      <div style="width: 311px">
                        <input
                          class="ml-3"
                          style="margin-top: 70px"
                          type="file"
                          name="picture"
                          id="picture"
                          @change="updateWorkshopPic"
                        />
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </b-form-group>
            </div>
          </div>
          <BottomBar :company="company"></BottomBar>
        </div>
      </div>
    </b-container>

    <b-modal
      id="addCourseModal"
      ref="add-course-modal"
      header-class="border-0 pb-0"
      :hide-footer="true"
      size="lg"
      title="เลือกคอร์สเรียน"
      centered
    >
      <div class="my-0 mx-2">
        <SearchBox @typed="getSearchKeyword" />
        <b-table
          :fields="[{ key: 'courseName', label: 'เลือกคอร์ส' }]"
          :items="courseList.data"
          :busy="onCourseLoading"
          selectable
          select-mode="single"
          selected-variant="warning"
          :hide-footer="true"
          small
          @row-selected="onCourseSelected"
        >
          <template v-slot:table-busy>
            <Loading />
          </template>
        </b-table>
        <b-button
          :disabled="onCourseLoading"
          variant="primary"
          class="my-3"
          @click="addCourse"
        >
          <b-spinner v-show="onCourseLoading" small></b-spinner>เพิ่มคอร์ส
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import BottomBar from "./partials/BottomBar";
import Loading from "../../shared/Loading";
import SearchBox from "../../shared/SearchBox";
import draggable from "csq-vue-multi-draggable";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";

import CourseGroupInfoForm from "./CourseGroupInfoForm";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import courseMixin from "./../../../mixins/course-mixin.js";
import authMixin from "@/mixins/auth-mixin";

export default {
  name: "CourseGroupEdit",
  components: {
    CourseGroupInfoForm,
    PageHeader,
    BottomBar,
    Loading,
    draggable,
    SearchBox,
  },
  mixins: [courseMixin, authMixin, timeMixins],
  props: {
    company: { default: null, type: Object },
  },
  data() {
    return {
      statusText: null,
      workshopState: null,
      workshopName: null,
      workshopUrl: null,
      workshopNameInput: null,
      workshopUrlInput: null,
      workshopNameValid: null,
      workshopUrlValid: null,
      imagePreview: null,
      showPreview: false,
      onLoading: true,
      onCourseLoading: true,
      courseSelectedItems: [],
      courseGroupId: this.$route.params.id,
      isShowWarning: false,
      form: {
        courseGroupPrequisiteFlag: null,
      },
      fetchCourseListParams: {
        company_id: this.company.id,
        page: 1,
        limit: 10,
        q: null,
        sort_by: "updated",
        sort_order: "desc",
        excepts: "numberOfStudent,numberOfActiveStudent",
      },
      preRequisiteModeOptions: [
        { value: "CONTINUE", text: "เรียนต่อกัน" },
        { value: "SAME_AS_PREVIOUS", text: "เรียนได้พร้อมกับคอร์สก่อนหน้า" },
      ],
      dragging: false,
      dragMovedContext: {
        fromIndex: null,
        fromElement: null,
        toIndex: null,
        toElement: null,
      },
    };
  },
  async mounted() {
    const workshop = await api.get(
      `/v1/course-groups/${this.courseGroupId}/workshop`
    );
    const workshopData = workshop.data;
    this.workshopName = workshopData.name;
    this.workshopUrl = workshopData.url;
    if (workshopData.state == 1) {
      this.workshopState = true;
    } else {
      this.workshopState = false;
    }
    if (workshopData.pic == null) {
      this.imagePreview = "/images/placeholder-image.png";
    } else {
      const workshopimg = await api.getImg(
        `/v1/course-groups/${this.courseGroupId}/workshopimg`
      );
      this.imagePreview = window.URL.createObjectURL(
        new Blob([workshopimg.data])
      );
    }
    if (this.workshopName == null) {
      this.workshopNameValid = false;
      this.statusText =
        "** กรุณากรอกข้อมูลชื่อ workshop และ ลิงค์ workshop ให้ครบถ้วน เพื่อเปิดใช้งานระบบ";
    }
    if (this.workshopUrl == null) {
      this.workshopUrlValid = false;
      this.statusText =
        "** กรุณากรอกข้อมูลชื่อ workshop และ ลิงค์ workshop ให้ครบถ้วน เพื่อเปิดใช้งานระบบ";
    } else if (
      this.workshopName.length > 0 &&
      this.workshopUrl.length > 0 &&
      this.workshopState == true
    ) {
      await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
        workshopState: 1,
      });
    }
  },
  computed: {
    ...mapGetters("CourseGroupManagement", [
      "courseGroupInfo",
      "courseGroupCourses",
    ]),
    ...mapGetters("CourseManagement", ["courseList"]),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    hasStudent() {
      return this.courseGroupInfo.allStudentsCount > 0;
    },
  },
  watch: {
    dragging: function(newValue) {
      if (newValue === false) {
        this.reorder();
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions("CourseGroupManagement", [
      "getCourseGroupInfo",
      "updateCourseGroupInfo",
      "addCourseIntoCourseGroup",
      "removeCourseIntoCourseGroup",
      "reorderCourseInCourseGroup",
      "setCoursePrerequisiteMode",
    ]),

    ...mapActions("CourseManagement", ["fetchCourseList"]),

    checkMove: function(e) {
      this.dragMovedContext = {
        fromIndex: e.draggedContext.index,
        fromElement: e.draggedContext.element,
        toIndex: e.relatedContext.index,
        toElement: e.relatedContext.element,
        toModuleId: e.to.id,
      };
    },

    async updateWorkshopState(event) {
      if (event == false) {
        await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopState: 0,
        });
      } else if (
        event == true &&
        this.workshopName.length > 0 &&
        this.workshopUrl.length > 0
      ) {
        await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopState: 1,
        });
      }
    },

    async updateWorkshopName(event) {
      await api
        .post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopName: event,
        })
        .then(() => {
          if (this.workshopNameInput.length > 0) {
            this.workshopNameValid = true;
          }
        });
      if (
        this.workshopNameValid == true &&
        (this.workshopUrlValid == true ||
          (this.workshopUrlValid == null && this.workshopUrl.length > 0))
      ) {
        await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopState: 1,
        });
        this.statusText = null;
      }
    },

    async updateWorkshopUrl(event) {
      await api
        .post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopUrl: event,
        })
        .then(() => {
          if (this.workshopUrlInput.length > 0) {
            this.workshopUrlValid = true;
          }
        });
      if (
        this.workshopUrlValid == true &&
        (this.workshopNameValid == true ||
          (this.workshopNameValid == null && this.workshopName.length > 0))
      ) {
        await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, {
          workshopState: 1,
        });
        this.statusText = null;
      }
    },

    async updateWorkshopPic(event) {
      let data = new FormData();
      data.append("workshopPic", event.target.files[0]);
      await api.post(`/v1/course-groups/${this.courseGroupId}/workshop`, data);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false
      );
      reader.readAsDataURL(event.target.files[0]);
    },

    async fetch() {
      this.onLoading = true;

      await this.getCourseGroupInfo({
        courseGroupId: this.courseGroupId,
      }).then(() => {
        this.onLoading = false;

        this.form = {
          courseGroupPrequisiteFlag: this.courseGroupInfo
            .courseGroupPrequisiteFlag,
        };

        // TODO: Course-group edit confirmation
        // if (this.courseGroupInfo.courseGroupPrequisiteFlag == 1 && !this.isShowWarning) {
        //   this.showWarningModal()
        // } else {
        //   this.fetchCourseList(this.fetchCourseListParams).then(() => {
        //     this.onCourseLoading = false
        //   })
        // }
      });
    },

    async update(value) {
      this.onLoading = true;
      await this.updateCourseGroupInfo({
        courseGroupId: this.courseGroupId,
        courseGroupName: this.courseGroupInfo.courseGroupName,
        courseGroupShortDescription: this.courseGroupInfo
          .courseGroupShortDescription,
        courseGroupPrequisiteFlag: value,
      }).then(() => {
        this.fetch();
      });
    },

    async getSearchKeyword(value) {
      this.onCourseLoading = true;
      this.fetchCourseListParams.q = value;
      await this.fetchCourseList(this.fetchCourseListParams).then(() => {
        this.onCourseLoading = false;
      });
    },

    onCourseSelected(items) {
      this.courseSelectedItems = items;
    },

    openSelectCoursesModal() {
      this.$refs["add-course-modal"].show();

      this.fetchCourseList(this.fetchCourseListParams).then(() => {
        this.onCourseLoading = false;
      });
    },

    async addCourse() {
      this.onCourseLoading = true;
      await this.addCourseIntoCourseGroup({
        courseGroupId: this.courseGroupId,
        courseId: this.courseSelectedItems[0].courseId,
      }).then(() => {
        this.$refs["add-course-modal"].hide();
        this.onCourseLoading = false;
        this.onLoading = true;
        this.fetch();
      });
    },

    async removeCourse(courseId) {
      this.onLoading = true;
      await this.removeCourseIntoCourseGroup({
        courseGroupId: this.courseGroupId,
        courseId: courseId,
      }).then(() => {
        this.fetch();
      });
    },

    async reorder() {
      this.onLoading = true;

      const selectedCourseId = this.dragMovedContext.fromElement.courseId;
      const fromIndex = this.dragMovedContext.fromIndex;
      const toIndex = this.dragMovedContext.toIndex;
      const moveToAfterIndex = toIndex > 0 ? toIndex - 1 : null;
      let moveToAfterCourseId = null;

      if (moveToAfterIndex === 0 && fromIndex < toIndex) {
        // ถ้า reorder ลงจาก index 0 ไป 1 ให้ swap position
        moveToAfterCourseId = this.dragMovedContext.toElement.courseId;
      } else if (moveToAfterIndex === 0 || moveToAfterIndex) {
        // หา courseId ที่อยู่เหนือคอร์สที่ถูกแทนที่ 1 ระดับ
        const moveToAfterCourse = this.courseGroupCourses[moveToAfterIndex];
        moveToAfterCourseId = moveToAfterCourse.courseId;
      }

      await this.reorderCourseInCourseGroup({
        courseGroupId: this.courseGroupId,
        selectedCourseId,
        moveToAfterCourseId,
      }).then(() => {
        this.onLoading = false;
        this.fetch();
      });
    },

    async setPrequisiteMode(courseId) {
      const mode = this.$refs[`select-prerequisiteMode-${courseId}`][0].$data
        .localValue;
      this.onLoading = true;
      await this.setCoursePrerequisiteMode({
        courseGroupId: this.courseGroupId,
        courseId: courseId,
        mode: mode,
      }).then(() => {
        this.onLoading = false;
        this.fetch();
      });
    },

    checkNameInput(event) {
      this.workshopNameInput = event;
      if (event.length == 0) {
        this.workshopNameValid = false;
        this.statusText =
          "** กรุณากรอกข้อมูลชื่อ workshop และ ลิงค์ workshop ให้ครบถ้วน เพื่อเปิดใช้งานระบบ";
      }
    },
    checkUrlInput(event) {
      this.workshopUrlInput = event;
      if (event.length == 0) {
        this.workshopUrlValid = false;
        this.statusText =
          "** กรุณากรอกข้อมูลชื่อ workshop และ ลิงค์ workshop ให้ครบถ้วน เพื่อเปิดใช้งานระบบ";
      }
    },

    // TODO: Course-group edit confirmation
    // showWarningModal() {
    //   this.isShowWarning = true
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       "เนื่องจากกลุ่มคอร์สได้มีการตั้งค่า Prerequisite ไว้ เพื่อเป็นการป้องกันความสับสนของผู้เรียน ทางระบบจึงขอไม่อนุญาตให้มีการเปลี่ยนแปลงหรือแก้ไขลำดับ Prerequisite ที่ได้ตั้งค่าไว้ ทั้งนี้ผู้แลสามารถแก้ไขชื่อและรายละเอียดกลุ่มคอร์สได้ตามปกติ",
    //       {
    //         title: 'แจ้งเตือนการแก้ไขกลุ่มคอร์ส',
    //         buttonSize: 'sm',
    //         okTitle: 'แก้ไขกลุ่มคอร์ส',
    //         okVariant: 'primary',
    //         cancelTitle: 'กลับหน้าเดิม',
    //         cancelVariant: 'outline-primary',
    //         hideHeaderClose: false,
    //         headerCloseVariant: 'white',
    //         headerCloseContent: '&times;',
    //         headerBgVariant: 'warning',
    //         headerClass: 'py-2 px-3 border-0',
    //         footerClass: 'p-3 border-0',
    //         centered: true,
    //       },
    //     )
    //     .then((confirm) => {
    //       if (confirm) {
    //         this.fetchCourseList(this.fetchCourseListParams).then(() => {
    //           this.onCourseLoading = false
    //         })
    //       } else {
    //         this.$router.push({
    //           name: 'course-group.management.index',
    //         })
    //       }
    //     })
    // }
  },
};
</script>

<style lang="css">
.ghost {
  opacity: 0;
  background: #fff;
}
</style>

<template>
  <b-card :class="$options.name">
    <div slot="header">
      <i class="fas fa-file-alt" aria-hidden="true"></i> รายละเอียด
    </div>
    <b-container :class="`${$options.name}__body`" fluid>
      <template v-if="examInfoPending">
        <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> กำลังโหลด
      </template>
      <template v-else>
        <b-row column>
          <h4>ข้อสอบ {{ this.examInfoData.title }}</h4>
          <!-- <b-col md="3">
                       <h2> Image Area</h2>
                    </b-col> -->
          <b-col md="9">
            <h5>เกี่ยวกับข้อสอบ</h5>
            {{ description }}
          </b-col>
        </b-row>
      </template>
    </b-container>
    <!-- <em slot="footer" class="text-warning">โดย ใส่ชื่อผู้สอน</em> -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ExamInfo",
  computed: {
    ...mapGetters("Company", ["examInfoPending", "examInfoData"]),
    description() {
      if (this.examInfoData.fullDescription)
        return this.examInfoData.fullDescription;
      if (this.examInfoData.shortDescription)
        return this.examInfoData.shortDescription;
      return "ไม่ได้ระบุข้อมูลเกี่ยวกับข้อสอบ";
    }
  }
};
</script>

<style lang="scss" scoped>
.ExamInfo {
  &__body {
    padding-top: 3em;
    height: 198px;
  }
}
.card-footer {
  text-align: end;
  background-color: #fff;
  border: 0;
}
.card {
  margin-bottom: 1em;
}
</style>

<template>
  <b-form-group :id="id" :label-for="id">
    <template v-slot:label>
      {{ label }}<span v-if="isRequire" class="text-danger">*</span>
    </template>
    <b-form-input
      :id="id"
      v-model="temp"
      trim
      :state="validation()"
      :disabled="onUpdating"
      @blur="editCourseInfo"
      @keyup.enter="editCourseInfo"
      @keypress="inputValidation($event)"
    />
    <b-form-invalid-feedback :state="validation()">
      <div class="d-flex justify-content-between">
        <span>{{ invalidFeedback }}</span>
        <span v-if="formatError">{{ temp | wordsCount }} ตัวอักษร</span>
      </div>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TextInput",
  filters: {
    wordsCount: function(value) {
      if (value) {
        return value.length;
      }

      return 0;
    }
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    isRequire: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    attribute: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      invalidFeedback: "",
      temp: "",
      formatError: false,
      returnError: false,
      onUpdating: false
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseInfo",
      "courseAttributeUpdated"
    ])
  },
  created() {
    this.temp = this.value;
  },
  watch: {
    courseInfo: {
      deep: true,
      handler(newcourseInfo) {
        if (this.attribute === "courseName") {
          this.temp = newcourseInfo.courseName;
        }
        if (this.attribute === "courseSlug") {
          this.temp = newcourseInfo.courseSlug;
        }
        if (this.attribute === "courseShortDescription") {
          this.temp = newcourseInfo.courseShortDescription;
        }
        if (this.attribute === "courseCode") {
          this.temp = newcourseInfo.courseCode;
        }
      }
    }
  },
  methods: {
    ...mapActions("CourseManagement", ["updateCourseInfo", "getCourseInfo"]),
    inputValidation(e) {
      if (this.attribute === "courseName" && e.key == "/") {
        e.preventDefault();
      }
      if (
        this.attribute === "courseSlug" &&
        e.key.match(/[^a-zก-๛0-9-]+$/)
      ) {
        e.preventDefault();
      }
    },
    editCourseInfo() {
      this.invalidFeedback = "";
      if (this.value != this.temp) {
        if (!this.formatError) {
          this.returnError = false;
          if (
            this.attribute === "courseName" &&
            (!this.temp || this.temp.length <= 0)
          ) {
            this.invalidFeedback = "กรุณากรอกชื่อคอร์ส";
            this.returnError = true;
          } else if (
            this.attribute === "courseSlug" &&
            (!this.temp || this.temp.length <= 0)
          ) {
            this.invalidFeedback = "กรุณากรอกURLคอร์ส";
            this.returnError = true;
          } else if (
            this.attribute === "courseSlug" &&
            (/[&'"<>\\$/#@*\s%+=?,;:[\]{}^|]/.test(this.temp))
          ) {
            this.invalidFeedback = "URL คอร์สไม่รองรับอักขระพิเศษ";
            this.returnError = true;
          } else if (
            this.attribute === "courseShortDescription" &&
            (!this.temp || this.temp.length <= 0)
          ) {
            this.invalidFeedback = "กรุณากรอกคำอธิบายสั้น ๆ";
            this.returnError = true;
          } else {
            this.onUpdating = true;
            this.updateCourseInfo({
              courseId: this.$route.params.courseId,
              attribute: {
                [this.attribute]: this.temp == "" ? null : this.temp
              }
            })
              .then(() => {
                this.onUpdating = false;
                this.invalidFeedback = "";
                this.getCourseInfo({
                  courseId: this.$route.params.courseId,
                  temp: true,
                  excepts: "numberOfStudent,numberOfActiveStudent"
                });
              })
              .catch(error => {
                this.onUpdating = false;
                if (
                  this.attribute === "courseSlug" && error == 'course slug already used'
                ) {
                  this.invalidFeedback = "URLคอร์สนี้ถูกใช้ไปแล้ว";
                } else if (
                  this.attribute === "courseCode" &&
                  error.courseCode &&
                  error.courseCode.length
                ) {
                  if (
                    error.courseCode[0] ==
                    "The course code has already been taken."
                  ) {
                    this.invalidFeedback = "รหัสคอร์สเรียนนี้ถูกใช้ไปแล้ว";
                  } else if (
                    error.courseCode[0] == "The course code format is invalid."
                  ) {
                    this.invalidFeedback =
                      "กรุณากรอกเป็นตัวอักษรภาษาอังกฤษหรือตัวเลข";
                  }
                } else {
                  this.invalidFeedback = "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง";
                }
                this.returnError = true;
              });
          }
        }
      }
    },
    validation() {
      this.formatError = false;
      if (
        this.attribute === "courseName" &&
        this.temp &&
        this.temp.length > 150
      ) {
        this.invalidFeedback = this.description;
        this.formatError = true;
      }
      if (
        this.attribute === "courseSlug" &&
        this.temp &&
        this.temp.length > 150
      ) {
        this.invalidFeedback = this.description;
        this.formatError = true;
      }
      if (
        this.attribute === "courseShortDescription" &&
        this.temp &&
        this.temp.length > 250
      ) {
        this.invalidFeedback = this.description;
        this.formatError = true;
      }
      if (
        this.attribute === "courseCode" &&
        this.temp &&
        this.temp.length > 100
      ) {
        this.invalidFeedback = this.description;
        this.formatError = true;
      }

      return (
        !this.formatError &&
        !this.returnError &&
        this.courseAttributeUpdated[this.attribute]
      );
    }
  }
};
</script>

<template>
  <div>
    <PageHeader title="ลงทะเบียนเรียน" />
    <b-container fluid="xl">
      <div class="py-5">
        <b-alert
          v-if="alert"
          :show="dismissCountDown"
          dismissible
          :variant="alert.type"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          >{{ alert.message }}</b-alert
        >

        <b-row align-h="between">
          <b-col md="6" lg="4">
            <SearchBox
              :title="`พิมพ์ชื่อคอร์สเรียนที่ต้องการค้นหา`"
              @typed="getSearchKeyword"
            />
          </b-col>

          <b-col md="6" lg="4" class="text-right">
            <b-button
              v-if="
                hasRoles([
                  'csq/superadmin',
                  'csq/moderator',
                  'org/admin',
                  'org/hr',
                  'org/group_admin',
                  'org/instructor',
                  'tutor/admin'
                ]) && 
                !isAccountingType() && 
                (!isTutorType() || isAllowedToSpecificCompany())
              "
              variant="primary"
              size="sm"
              @click="addEnrollment"
            >
              <b-icon-plus />เพิ่มการลงทะเบียนคอร์สเรียน
            </b-button>
          </b-col>
        </b-row>

        <b-table
          :fields="tableFields"
          :items="courseList.data"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          id="course-list"
          :busy="isLoading"
          :per-page="perPage"
          responsive
          sort-icon-left
          table-class="mt-4 border-bottom"
        >
          <template v-slot:cell(image)="props">
            <b-img-lazy
              slot="aside"
              v-bind="imageProps"
              :src="props.item.courseThumbnail"
              class="course-image"
              alt
            ></b-img-lazy>
          </template>
          <template v-slot:cell(courseName)="props">
            <router-link
              v-if="!isTutorType() || comId == 512"
              :to="{
                name: 'enrollment.members',
                params: { id: props.item.courseId },
              }"
              >{{ props.item.courseName }}</router-link
            >
            <span v-else>{{ props.item.courseName }}</span>
          </template>
          <div slot="table-busy" class="text-center text-danger my-2">
            <Loading />
          </div>
        </b-table>

        <div class="row mt-3">
          <div class="col-lg-6">
            <form class="form-inline">
              <div class="form-group mb-2">
                <label>แสดง</label>
              </div>
              <div class="form-group ml-2 mb-2">
                <b-form-select
                  v-model="perPage"
                  :options="[10, 15, 30, 50]"
                  size="sm"
                  @input="setFetchParamsPerPage"
                ></b-form-select>
              </div>
              <div class="form-group ml-2 mb-2">
                <label>
                  จาก
                  {{ courseList.meta.total | formatNumber }}
                  รายการ
                </label>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <nav class="float-right" aria-label="Page navigation example">
              <b-pagination
                v-model="currentPage"
                :total-rows="courseList.meta.total"
                :per-page="perPage"
                @change="setFetchParamsPage"
                aria-controls="course-list"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CourseEnrollmentMixin from "@/mixins/course-enrollment-mixin";
import PageHeader from "@/components/layouts/PageHeader";
import SearchBox from "@/components/shared/SearchBox";

import Loading from "@/components/shared/Loading";
import AuthMixin from "@/mixins/auth-mixin.js";
import api from "@/services/api.js";

export default {
  name: "CourseEnrollIndexPage",
  components: { PageHeader, SearchBox, Loading },
  mixins: [CourseEnrollmentMixin, AuthMixin],
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      search: null,
      perPage: 10,
      currentPage: 1,
      dismissSecs: 5,
      dismissCountDown: 0,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["courseList", "isLoading"]),
    ...mapGetters("CourseEnrollment", ["alert"]),
  },
  async mounted() {
    this.fetchParams.company_id = this.company.id;
    this.fetchParams.limit = this.perPage;
    this.fetchParams.page = this.currentPage;
    if (this.alert) this.showAlert();
    await this.fetchCourseListEnrollment(this.fetchParams);
  },
  async created() {
    localStorage.removeItem("backToUrl");
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
  },
  methods: {
    ...mapActions("CourseManagement", ["fetchCourseListEnrollment"]),
    ...mapActions("CourseEnrollment", ["setAlert", "clearSelectedItems"]),

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (dismissCountDown == 0) this.setAlert(null);
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    addEnrollment() {
      this.clearSelectedItems();

      this.$router.push({
        name: "course.enroll.step1",
      });
    },

    async getSearchKeyword(value) {
      this.fetchParams.q = value;
      this.fetchParams.page = this.currentPage = 1;
      this.fetchCourseListEnrollment(this.fetchParams);
    },
    async setFetchParamsPage(page) {
      this.fetchParams.page = page; //page = this.currentPage
      await this.fetchCourseListEnrollment(this.fetchParams);
    },
    async setFetchParamsPerPage() {
      this.fetchParams.limit = this.perPage;
      await this.fetchCourseListEnrollment(this.fetchParams);
    },
  },
};
</script>
<style scoped></style>

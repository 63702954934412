<template>
  <div>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col class="text-left" cols="6"
            ><h6>จํานวนผู้เรียนแบ่งตามจังหวัด</h6>
          </b-col>
          <b-col class="text-right" cols="6"
            ><a
              :href="`/${companyInfo ? companyInfo.url : ''}/analytics/users/provinces`"
              target="_blank"
              >ดูทั้งหมด</a
            ></b-col
          >
        </b-row>
      </template>
      <template v-slot:body>
        <div>
          <map-com></map-com>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import map from "@/components/pages/analytics/shared/Map";
import layout from "@/components/pages/analytics/shared/Layout";

export default {
  data() {
    return {
      companyInfo: null
    };
  },
  components: {
    "map-com": map,
    layout
  },
  mounted() {
    this.setCompanyInfo();
  },
  methods: {
    setCompanyInfo() {
      this.companyInfo = this.$store.state.Company.companyInfo;
    }
  }
};
</script>

<template>
  <div>
    <PageHeader
      :title="`ประวัติการเข้าเรียนของ ${memberName}`"
      style="margin-left: -10%;"
    ></PageHeader>
    <b-container style="max-width: 81.25rem; margin-top: 50px;">
      <LearningHistoryLogPanel
        :isCardShow="true"
        :isLoading="isLoading"
        :learningHistoryList="learningHistoryList"
        :memberName="memberName"
      />
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layouts/PageHeader.vue";
import LearningHistoryLogPanel from "./panel/LearningHistoryLogPanel.vue";

export default {
  components: {
    PageHeader,
    LearningHistoryLogPanel,
  },
  data() {
    return {
      cerId: this.$route.params.cerId,
      memberName: "",
    };
  },
  async created() {
    await this.getLearningHistoryList(this.cerId);
    this.memberName =
      this.learningHistoryList.mp_firstName +
      " " +
      this.learningHistoryList.mp_lastName;
  },
  computed: {
    ...mapGetters("LearningHistory", ["isLoading", "learningHistoryList"]),
  },
  methods: {
    ...mapActions("LearningHistory", ["getLearningHistoryList"]),
  },
};
</script>

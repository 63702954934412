<template>
  <div>
    <PageHeader title="เพิ่มกลุ่ม" icon="plus"></PageHeader>
    <b-container fluid="xl" class="pt-5">
      <div class="inner bg-container">
        <div class="card card-csq no-shadow">
          <div class="card-body">
            <b-row>
              <b-col md="12">
                <b-row class="my-3">
                  <b-col sm="12">
                    <b-alert v-model="showAlert" variant="danger" dismissible>
                      {{ errorMessage }}
                    </b-alert>
                    <b-form-group
                      label="ชื่อกลุ่ม :"
                      label-cols-sm="3"
                      label-cols-lg="2"
                      label-for="group-name"
                    >
                      <b-form-input
                        id="group-name"
                        v-model="inputs.name"
                        placeholder="พิมพ์ชื่อกลุ่ม"
                        rows="3"
                        max-rows="3"
                        class="rounded-0 border-top-0 border-left-0 border-right-0 border-bottom px-1"
                        autofocus
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="12">
                    <b-form-group
                      label="คำอธิบายกลุ่ม :"
                      label-cols-sm="3"
                      label-cols-lg="2"
                      label-for="group-description"
                    >
                      <b-form-textarea
                        id="group-description"
                        v-model="inputs.description"
                        placeholder="พิมพ์คำอธิบายกลุ่ม"
                        size="md"
                        class="rounded-0 border-0  notes"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col md="7">
                <div class="form-inline">
                  <label for class="text-muted ml-2 mt-3"
                    >เลือกผู้เรียนจำนวน {{ allSelectedItems.length }} คน</label
                  >
                </div>
              </b-col>
              <b-col md="5">
                <search-box
                  :title="`พิมพ์ชื่อกลุ่มผู้เรียนที่ต้องการค้นหา`"
                  @typed="getSearchKeyword"
                ></search-box>
              </b-col>
            </b-row>

            <b-table
              ref="selectableTable"
              :items="items.data"
              :fields="fields"
              :current-page="items.meta.current_page"
              :busy="onLoading"
              hover
              responsive
              class="mt-3"
              @row-clicked="rowClicked"
              :tbody-tr-class="tbodyRowClass"
              primary-key="id"
            >
              <template v-slot:header(selected)="">
                <b-link
                  v-if="isAllItemsSelected"
                  class="text-dark"
                  @click="clearSelected"
                >
                  <b-icon-check2-square scale="1.2" />
                </b-link>
                <b-link v-else class="text-dark" @click="selectAllRows">
                  <b-icon-square />
                </b-link>
              </template>

              <template v-slot:cell(selected)="{ item }">
                <template v-if="isSelectedItem(item)">
                  <b-icon-check2-square scale="1.2" />
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <b-icon-square />
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template
                v-slot:cell(fullname)="{ item: { firstName, lastName } }"
              >
                {{ `${firstName} ${lastName}` }}
              </template>

              <div slot="table-busy">
                <b-spinner class="align-middle text-csq"></b-spinner>
              </div>
            </b-table>

            <Pagination
              v-if="items.data.length"
              :items="items"
              :limits="[10, 50, 100]"
              @changePage="getResults"
              @changeLimit="getResults(1)"
            />
          </div>
          <div class="card-footer mb-2 border-top-0 bg-white text-right">
            <b-button
              :to="{ name: 'group.index' }"
              class="text-csq"
              variant="link"
              >ยกเลิก</b-button
            >
            <b-button
              :disabled="!inputs.name"
              variant="dark"
              @click="createGroup"
              >เพิ่มกลุ่ม</b-button
            >
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from '../../layouts/PageHeader.vue'
import api from '@/services/api';
import SearchBox from '@/components/shared/SearchBox';
import Pagination from '@/components/shared/Pagination';
import Numeral from 'numeral';
import SelectableTableMixin from '@/mixins/selectable-table-mixin.js';

export default {
  components: {
    PageHeader,
    SearchBox,
    Pagination,
  },
  mixins: [SelectableTableMixin],
  filters: {
    formatNumber: function(value) {
      return Numeral(value).format('0,0');
    },
  },
  props: { company: { type: Object, default: null } },
  data() {
    return {
      inputs: {
        name: null,
        description: null,
      },
      fields: [
        { key: 'selected', thStyle: { width: '20px' } },
        { label: 'ชื่อผู้เรียน', key: 'fullname' },
        { label: 'ชื่อผู้ใช้', key: 'username' },
        { label: 'อีเมล', key: 'email' },
        { label: 'รหัสพนักงาน', key: 'staffId' },
        { label: 'แผนก', key: 'department' },
      ],
      q: '',
      onLoading: true,
      actionOptions: null,
      showAlert: false,
      errorMessage: '',
    };
  },
  created() {
    this.getResults();
  },
  methods: {
    getResults(page = 1) {
      this.onLoading = true;

      api
        .get(
          `/v1/companies/${this.company.id}/members?page=${page}&limit=${this.items.meta.per_page}&q=${this.q}&sort_by=fullname&excepts=profiles,groups,roles`
        )
        .then(({ data }) => {
          this.items = data;
          this.onLoading = false;
        });
    },
    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },
    async createGroup() {
      await api
        .post('/v1/member-groups', {
          name: this.inputs.name,
          description: this.inputs.description,
          company_id: this.company.id,
        })
        .then((response) => {
          const { id } = response.data.data;
          if (id) this.syncMembers(id);
          this.$bvToast.toast('สร้างกลุ่มเรียบร้อยแล้ว', {
            title: 'สำเร็จ',
            variant: 'success',
            solid: true,
          });
          this.$router.push({ name: 'group.index' });
        })
        .catch(({ response }) => {
          this.showAlert = true;
          this.errorMessage = response.data.errors.name[0];
        });
    },
    async syncMembers(groupId) {
      const members = this.allSelectedItems.map((item) => item.id);
      await api.post(`/v1/member-groups/${groupId}/members`, {
        members,
      });

      this.$bvToast.toast('เพิ่มผู้เรียนเข้ากลุ่มเรียบร้อยแล้ว', {
        title: 'สำเร็จ',
        variant: 'success',
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 0px, transparent 0px),
    linear-gradient(to left, white 0px, transparent 0px),
    repeating-linear-gradient(
      white,
      white 30px,
      #ced4da 30px,
      #ced4da 31px,
      white 31px
    );
  line-height: 31px;
  padding: 0px 0px;
}
</style>

<template>
  <form @submit.prevent="update">
    <div class="card border-0 no-shadow bg-light mb-3">
      <div class="card-header border-0 bg-transparent">
        <h5 class="card-title"><a href="#" class="" @click="backPaymentAddress()">ที่อยู่การชำระเงิน</a>/ที่อยู่สำหรับจัดส่งเอกสาร</h5>
      </div>
      <div class="card-body pb-0">
        <div class="form-row">
          <b-form-group class="col-md-2">
            <label>คำนำหน้า</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.titleName"
              type="text"
              placeholder="คำนำหน้า"
              :class="{ 'is-invalid': hasError('titleName') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกคำนำหน้า
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-3">
            <label>ชื่อ</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.firstName"
              type="text"
              placeholder="ชื่อ"
              :class="{ 'is-invalid': hasError('firstName') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อ
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-3">
            <label>นามสกุล</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.lastName"
              type="text"
              placeholder="นามสกุล"
              :class="{ 'is-invalid': hasError('lastName') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกนามสกุล
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>เบอร์โทร</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.phoneNumber"
              type="text"
              placeholder="เบอร์โทร"
              v-mask="['##########']"
              maxlength="10"
              :class="{ 'is-invalid': hasError('phoneNumber') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกเบอร์โทร
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>บ้านเลขที่</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.number"
              type="text"
              placeholder="บ้านเลขที่"
              :class="{ 'is-invalid': hasError('number') }"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกบ้านเลขที่
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>สถานที่/อาคาร (ไม่ต้องระบุคำว่าอาคาร)</label>
            <b-form-input
              v-model="form.buildingName"
              type="text"
              placeholder="สถานที่/อาคาร"
              trim
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>ชั้นที่ (ไม่ต้องระบุคำว่าชั้น)</label>
            <b-form-input
              v-model="form.floor"
              type="text"
              placeholder="ชั้นที่"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>หมู่บ้าน (ไม่ต้องระบุคำว่าหมู่บ้าน)</label>
            <b-form-input
              v-model="form.village"
              type="text"
              placeholder="หมู่บ้าน"
              trim
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>หมู่</label>
            <b-form-input
              v-model="form.moo"
              type="text"
              placeholder="หมู่"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>ซอย (ไม่ต้องระบุคำว่าซอย)</label>
            <b-form-input 
              v-model="form.soi"
              type="text"
              placeholder="ซอย"
            />
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>ถนน (ไม่ต้องระบุคำว่าถนน)</label>
            <b-form-input
              v-model="form.street"
              type="text"
              placeholder="ถนน"
              trim
            />
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>แขวง/ตำบล</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.subDistrict"
              :class="{ 'is-invalid': hasError('subDistrict') }"
              type="text"
              placeholder="แขวง/ตำบล"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกแขวง/ตำบล
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>เขต/อำเภอ</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.district"
              :class="{ 'is-invalid': hasError('district') }"
              type="text"
              placeholder="เขต/อำเภอ"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกเขต/อำเภอ
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>จังหวัด</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.province"
              :class="{ 'is-invalid': hasError('province') }"
              type="text"
              placeholder="จังหวัด"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกจังหวัด
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
          <b-form-group class="col-md-4">
            <label>รหัสไปรษณีย์</label><span class="text-danger">*</span>
            <b-form-input
              v-model="form.postCode"
              :class="{ 'is-invalid': hasError('postCode') }"
              v-mask="['#####']"
              type="text"
              placeholder="รหัสไปรษณีย์"
              trim
            />
            <b-form-invalid-feedback>
              กรุณากรอกรหัสไปรษณีย์
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group class="col-md-4">
            <label>ตั้งชื่อให้ข้อมูลที่อยู่นี้ (เช่น บ้าน ที่ทำงาน)</label>
            <b-form-input
              v-model="form.shippingName"
              :class="{ 'is-invalid': hasError('shippingName') }"
              type="text"
              placeholder="ชื่อที่อยู่"
            />
            <b-form-invalid-feedback>
              กรุณากรอกชื่อที่อยู่
            </b-form-invalid-feedback>
            <b-form-valid-feedback></b-form-valid-feedback>
          </b-form-group>
        </div>
        <div class="text-left">
          <button
            class="btn btn-dark"
            type="button"
            @click="handleSubmit"
            :disabled="submitted"
          >
            บันทึก
          </button>
          <button
            class="btn btn-outline-dark ml-3"
            type="button"
            @click="backPaymentAddress()"
            :disabled="submitted"
          >
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import api from "../../../../services/api.js"
import {mask} from 'vue-the-mask';

export default {
  components: {},
  directives:{mask},
  props: {
    memberId: { type: String, default: null },
    shippingAddress: { type: Object, default: null }
  },

  data() {
    return {
      isEditing: this.shippingAddress ? true : false,
      comId: this.$store.state.Company.companyInfo.id,
      form: {
        titleName: this.shippingAddress ? this.shippingAddress.msa_titleName : "",
        firstName: this.shippingAddress ? this.shippingAddress.msa_firstName : "",
        lastName: this.shippingAddress ? this.shippingAddress.msa_lastName : "",
        number: this.shippingAddress ? this.shippingAddress.msa_number : null,
        buildingName: this.shippingAddress ? this.shippingAddress.msa_buildingName : null,
        floor: this.shippingAddress ? this.shippingAddress.msa_floor : null,
        village: this.shippingAddress ? this.shippingAddress.msa_village : null,
        moo: this.shippingAddress ? this.shippingAddress.msa_moo : null,
        soi: this.shippingAddress ? this.shippingAddress.msa_soi : null,
        street: this.shippingAddress ? this.shippingAddress.msa_street : null,
        phoneNumber: this.shippingAddress ? this.shippingAddress.msa_phone : null,
        subDistrict: this.shippingAddress ? this.shippingAddress.msa_subDistrict : null,
        district: this.shippingAddress ? this.shippingAddress.msa_district : null,
        province: this.shippingAddress ? this.shippingAddress.msa_province : null,
        postCode: this.shippingAddress ? this.shippingAddress.msa_postcode : null,
        shippingName: this.shippingAddress ? this.shippingAddress.msa_name : null,
      },
      submitted: false,
      errors: [],
      msaNumber: "",
      msaBuildingName: "",
      errorMessage: ""
    };
  },

  computed: {},

  mounted() {},

  methods: {
    async handleSubmit() {
      this.submitted = true;

      if (await this.validateForm() && !this.isEditing) {
        this.addShippingAddress(this.form);
        this.submitted = false;
      } else if (this.isEditing) {
        this.editShippingAddress(this.form);
        this.submitted = false;
      } else {
        console.log(this.errors);
        this.submitted = false;
      }
    },
    validateForm: async function() {
      this.errors = [];

      const form = this.form;

      if (!form.titleName) this.errors.push("titleName");
      if (!form.firstName) this.errors.push("firstName");
      if (!form.lastName) this.errors.push("lastName");
      if (!form.phoneNumber) this.errors.push("phoneNumber");
      if (!form.number) this.errors.push("number");
      if (!form.subDistrict) this.errors.push("subDistrict");
      if (!form.district) this.errors.push("district");
      if (!form.province) this.errors.push("province");
      if (!form.postCode) this.errors.push("postCode");
      if (!form.shippingName) this.errors.push("shippingName");
      return !this.errors.length;
    },

    addShippingAddress(form) {
      api
        .post(`/v1/members/${this.memberId}/shipping-address`, {
          msa_name: form.shippingName,
          msa_titleName: form.titleName,
          msa_firstName: form.firstName,
          msa_lastName: form.lastName,
          msa_buildingName: form.buildingName,
          msa_floor: form.floor,
          msa_number: form.number,
          msa_moo: form.moo,
          msa_village: form.village,
          msa_soi: form.soi,
          msa_street: form.street,
          msa_subDistrict: form.subDistrict,
          msa_district: form.district,
          msa_province: form.province,
          msa_postcode: form.postCode,
          msa_phone: form.phoneNumber
        })
        .then(() => {
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },
    editShippingAddress(form) {
      api
        .put(`/v1/members/${this.memberId}/shipping-address/${this.shippingAddress.msa_id}`, {
          msa_name: form.shippingName,
          msa_titleName: form.titleName,
          msa_firstName: form.firstName,
          msa_lastName: form.lastName,
          msa_buildingName: form.buildingName,
          msa_floor: form.floor,
          msa_number: form.number,
          msa_moo: form.moo,
          msa_village: form.village,
          msa_soi: form.soi,
          msa_street: form.street,
          msa_subDistrict: form.subDistrict,
          msa_district: form.district,
          msa_province: form.province,
          msa_postcode: form.postCode,
          msa_phone: form.phoneNumber
        })
        .then(() => {
          this.backPaymentAddress();
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
        });
    },

    backPaymentAddress() {
      this.$emit("backPaymentAddressList");
    },

    hasError(field) {
      const errors = this.errors;
      return errors.includes(field);
    }
  }
};
</script>

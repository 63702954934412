var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('layout',{staticClass:"user-learn-progress",scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('p',[_vm._v("รายละเอียดเนื้อหาคอร์ส")])]),_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.datalectures,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"cell(lec_name)",fn:function(data){return [_c('span',[_c('i',{class:[
                data.item.lec_type === 'VIDEO'
                  ? 'fa-solid fa-video'
                  : data.item.lec_type === 'SLIDE'
                  ? 'fa-brands fa-slideshare'
                  : 'fa-solid fa-globe',
                'mr-2',
              ]}),_vm._v(" "+_vm._s(data.item.lec_name)+" ")])]}},{key:"cell(vid_secondLength)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("thaiDurationFilter")(data.item.vid_secondLength ? _vm.timeFormat(data.item.vid_secondLength) : "-"))+" ")])]}},{key:"cell(lel_status)",fn:function(data){return [_c('span',{class:[
              data.item.lel_status === 'COMPLETED'
                ? 'text-success'
                : data.item.lel_status === 'NOT_STARTED'
                ? 'text-danger'
                : 'text-warning',
            ]},[_vm._v(" "+_vm._s(data.item.lel_status === "COMPLETED" ? "เรียบจบ" : data.item.lel_status === "NOT_STARTED" ? "ไม่ได้เรียน" : "เรียนไม่จบ")+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
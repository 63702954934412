<template>
  <div>
    <PageHeader title="จัดการคูปอง" />
    <b-container>
      <div class="bg-container py-5">
        <div
          class="inner bg-container"
          style="padding-left: 0; padding-right: 0;"
        >
          <div class="card card-csq no-shadow">
            <div class="card-body pt-4">
              <b-row>
                <b-col cols="4">
                  <SearchBox
                    title="พิมพ์ชื่อคูปองที่ต้องการค้นหา"
                    @typed="getSearchKeyword"
                  />
                </b-col>
                <b-col cols="3">
                  <b-input-group>
                    <b-form-input
                      id="dateSearch"
                      v-model="searchDateFormatted"
                      type="text"
                      placeholder="ค้นหาวันที่"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="searchDate"
                        button-only
                        right
                        locale="en-US"
                        aria-controls="dateSearch"
                        button-variant="primary"
                        @input="getSearchDate"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="2">
                  <b-form-select
                    v-model="selectStatus"
                    :options="statusOptions"
                    @input="getSelectStatus"
                  ></b-form-select>
                </b-col>
                <b-col cols="3">
                  <b-button
                    @click="createNewCoupon()"
                    variant="primary"
                    style="float: right;"
                  >
                    <b-icon icon="plus"></b-icon>
                    สร้างคูปองใหม่
                  </b-button>
                </b-col>
              </b-row>

              <!-- table -->
              <template v-if="isLoading">
                <div class="text-center text-csq mt-5">
                  <b-spinner class="align-middle" variant="primary"></b-spinner>
                  <p>Loading</p>
                </div>
              </template>
              <div v-else-if="couponList.total == 0" class="text-center">
                <p class="mt-5">ไม่มีโปรโมชั่น</p>
              </div>
              <div v-else>
                <b-row
                  class="pb-3 table-header"
                  style="border-bottom: 1px solid #000; margin-top: 35px;"
                >
                  <b-col cols="2">
                    <strong>คูปอง</strong>
                  </b-col>
                  <b-col>
                    <strong>จำนวนคูปอง</strong>
                  </b-col>
                  <b-col>
                    <strong>ส่วนลด</strong>
                  </b-col>
                  <b-col>
                    <strong>วันที่เริ่มคูปอง</strong>
                  </b-col>
                  <b-col>
                    <strong>วันที่สิ้นสุดคูปอง</strong>
                  </b-col>
                  <b-col>
                    <strong>สถานะ</strong>
                  </b-col>
                  <b-col></b-col>
                </b-row>

                <div class="mt-3 table-body">
                  <b-row
                    v-for="(coupon, index) in couponList.data"
                    :key="index"
                  >
                    <b-col cols="2">
                      <strong>{{ coupon.pc_code }}</strong>
                    </b-col>
                    <b-col>
                      <p>
                        {{
                          coupon.redeem_count +
                            "/" +
                            coupon.pc_amount +
                            " ครั้ง"
                        }}
                      </p>
                    </b-col>
                    <b-col>
                      <p>
                        {{
                          coupon.pc_type == "FIX"
                            ? formatDiscountPrice(coupon.pc_discountValue) +
                              " บาท"
                            : coupon.pc_type == "PERCENT"
                            ? coupon.pc_discountPercent + "%"
                            : "-"
                        }}
                      </p>
                    </b-col>
                    <b-col>
                      <p>{{ coupon.pc_startDate | thaiDateFilter }}</p>
                    </b-col>
                    <b-col>
                      <p>{{ coupon.pc_expireDate | thaiDateFilter }}</p>
                    </b-col>
                    <b-col>
                      <p
                        class="statusStyle"
                        :class="getCouponStatus(coupon).color"
                      >
                        {{ getCouponStatus(coupon).text }}
                      </p>
                    </b-col>
                    <b-col>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        style="margin-right: 10px;"
                      >
                        <b-icon
                          icon="pencil-square"
                          @click="editCoupon(coupon.pc_id, coupon.redeem_count)"
                        ></b-icon>
                      </b-button>
                      <b-button
                        @click="showDeleteModal(coupon.pc_id, coupon.pc_code)"
                        size="sm"
                        variant="outline-danger"
                        :disabled="coupon.redeem_count > 0"
                      >
                        <b-icon icon="trash-fill" style="color: red;"></b-icon>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <p v-if="coupon.c_id">คอร์ส: {{ coupon.c_name }}</p>
                      <p v-else-if="coupon.tag_id">
                        หมวดหมู่: {{ coupon.tag_name }}
                      </p>
                      <p v-else>คอร์สทั้งหมด</p>
                      <hr />
                    </b-col>
                  </b-row>
                </div>
              </div>

              <!-- pagination -->
              <b-row class="mt-4">
                <b-col cols="6">
                  <div class="d-flex justify-content-start align-items-center">
                    <p class="pr-3">แสดง</p>
                    <b-form-select
                      v-model="perPage"
                      :options="[5, 10, 15, 20]"
                      style="width: 75px;"
                      @input="changePerPage"
                    >
                    </b-form-select>
                    <p class="pl-3">จาก {{ couponList.total }} รายการ</p>
                  </div>
                </b-col>
                <b-col cols="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="couponList.total"
                    :per-page="perPage"
                    @change="changePage"
                    align="right"
                    aria-controls="coupon-list"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-model="isShowCouponModal"
        :title="couponTitle"
        size="lg"
        :hide-footer="true"
        ref="modal-coupon"
        centered
      >
        <div>
          <ModalCoupon
            :courses="courses"
            :tags="tags"
            :couponId="couponId"
            :actionType="actionType"
            :isCouponUsed="isCouponUsed"
            @hideCouponModal="hideCouponModal"
            @reloadData="fetchData()"
          />
        </div>
      </b-modal>
      <!-- delete modal -->
      <b-modal v-model="deleteModal" hide-footer hide-header centered>
        <div class="text-center">
          <b-icon
            icon="exclamation-triangle"
            font-scale="4"
            variant="warning"
          ></b-icon>
          <h4 style="margin-top: 30px;">ลบคูปอง</h4>
          <h6 style="margin-bottom: 40px;">
            ต้องการลบคูปอง <strong>{{ couponName }}</strong> หรือไม่
          </h6>
          <b-button @click="deleteModal = false" variant="outline-secondary">
            ยกเลิก
          </b-button>
          <b-button
            @click="deleteSpecificCoupon()"
            variant="danger"
            style="margin-left: 15px; width: 75px;"
          >
            ลบ
          </b-button>
        </div>
      </b-modal>
      <!-- success modal -->
      <b-modal v-model="successModal" hide-footer hide-header centered>
        <div class="text-center">
          <b-icon icon="check-circle" font-scale="4" variant="success"></b-icon>
          <h4 class="success-title">{{ successTitle }}คูปองเรียบร้อยแล้ว</h4>
          <b-button variant="primary" @click="closeSuccessModal()">
            เสร็จสิ้น
          </b-button>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layouts/PageHeader.vue";
import SearchBox from "@/components/shared/SearchBox.vue";
import ModalCoupon from "@/components/pages/purchase/modal/ModalCoupon.vue";
import moment from "moment";
import api from "@/services/api";
import dateMixins from "@/components/pages/analytics/shared/mixins/date";
import Numeral from "numeral";

export default {
  components: { PageHeader, SearchBox, ModalCoupon },
  mixins: [dateMixins],
  data() {
    return {
      comId: JSON.parse(localStorage.getItem("ComId")),
      fetchParams: {
        q: "",
        expireDate: "",
        status: "",
        page: "",
        limit: "",
      },
      searchDate: null,
      selectStatus: "",
      statusOptions: [
        { value: "", text: "สถานะทั้งหมด" },
        { value: "ACTIVE", text: "เปิดใช้งาน" },
        { value: "INACTIVE", text: "ระงับการใช้งาน" },
        { value: "TIMEOUT", text: "คูปองหมดอายุ" },
      ],
      statusColor: "",
      perPage: 5,
      currentPage: 1,
      isShowCouponModal: false,
      courses: [],
      tags: [],
      couponId: null,
      actionType: "",
      couponTitle: "",
      successModal: false,
      successTitle: "",
      deleteModal: false,
      couponName: "",
      isCouponUsed: false,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "couponList"]),
    searchDateFormatted() {
      if (this.searchDate) {
        return moment(this.searchDate).format("DD/MM/YYYY");
      }
      return "";
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions("CourseManagement", ["getCouponList", "deleteCoupon"]),
    async fetchData() {
      this.fetchParams.limit = this.perPage;
      this.fetchParams.page = 1;
      await this.getCouponList(this.fetchParams);
    },
    async getSearchKeyword(keyword) {
      this.fetchParams.q = keyword;
      this.fetchParams.page = this.currentPage = 1;
      await this.getCouponList(this.fetchParams);
    },
    async getSearchDate(date) {
      this.fetchParams.expireDate = date;
      this.fetchParams.page = this.currentPage = 1;
      await this.getCouponList(this.fetchParams);
    },
    async getSelectStatus(status) {
      this.fetchParams.status = status;
      this.fetchParams.page = this.currentPage = 1;
      await this.getCouponList(this.fetchParams);
    },
    getCouponStatus(coupon) {
      if (coupon.pc_status === "ACTIVE") {
        return { text: "เปิดใช้งาน", color: "active" };
      } else if (coupon.pc_status === "TIMEOUT") {
        return { text: "คูปองหมดอายุ", color: "expired" };
      } else if (coupon.pc_status === "INACTIVE") {
        return { text: "ระงับการใช้งาน", color: "in-active" };
      }
    },
    async changePerPage() {
      this.fetchParams.limit = this.perPage;
      this.fetchParams.page = this.currentPage = 1;
      await this.getCouponList(this.fetchParams);
    },
    async changePage(page) {
      this.fetchParams.page = page;
      await this.getCouponList(this.fetchParams);
    },
    async createNewCoupon() {
      this.getCoursesAndTags();
      this.isShowCouponModal = true;
      this.actionType = "create";
      this.couponTitle = "สร้างคูปอง";
      this.isCouponUsed = false;
    },
    async editCoupon(id, redeemCount) {
      this.getCoursesAndTags();
      this.isShowCouponModal = true;
      this.actionType = "edit";
      this.couponId = id;
      this.couponTitle = "แก้ไขคูปอง";
      this.isCouponUsed = redeemCount > 0 ? true : false;
    },
    async getCoursesAndTags() {
      await api.get(`v1/companies/${this.comId}/courses`).then(({ data }) => {
        this.courses = data.data;
      });
      await api.get(`v1/tags/get-available-tags`).then(({ data }) => {
        this.tags = data.data;
      });
    },
    async deleteSpecificCoupon() {
      await this.deleteCoupon(this.couponId);
      this.deleteModal = false;
      this.successModal = true;
      this.successTitle = "ลบ";
    },
    showDeleteModal(id, name) {
      this.deleteModal = true;
      this.couponId = id;
      this.couponName = name;
    },
    closeSuccessModal() {
      this.successModal = false;
      this.fetchData();
    },
    hideCouponModal(action) {
      this.isShowCouponModal = false;
      this.successModal = true;
      this.successTitle = action;
    },
    formatDiscountPrice(price) {
      return Numeral(price).format("0,0.00");
    },
  },
};
</script>

<style scoped>
.table-header strong {
  font-family: "Prompt", sans-serif;
}

.table-body p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.statusStyle {
  padding: 2px;
  border-radius: 25px;
  font-size: 14px;
  text-align: center;
  color: white;
}

.active {
  background-color: #28a745;
}

.expired {
  background-color: #dc3545;
}

.in-active {
  background-color: #6c757d;
}

h4 {
  font-weight: 600;
}

.success-title {
  margin: 30px 0 50px;
}
</style>

<template>
  <div>
    <b-row>
      <b-col class="col-12 col-lg-3 my-2 my-lg-0">
        <div class="border p-3 d-flex align-items-center h-100">
          <div class="mx-4">
            <div class="border border-info p-3 rounded-circle" >
              <div style="width: 20px; height: 20px; display: flex; align-items: center; justify-content: center;">
                  <i class="bi bi-clipboard-check-fill" style="font-size: 20px; color: #17a2b8;"></i>
              </div>
            </div>
          </div>
          <div v-if="bestScoreAnswerSheet" class="d-flex flex-column">
            <span class="detail-font font-weight-bold text-info">
              {{ bestScoreAnswerSheet.overallResult.score }} คะแนน จาก
              {{ bestScoreAnswerSheet.overallResult.fullScore }} คะแนน
            </span>
            <span class="topic-font">ที่สอบได้ดีที่สุด</span>
          </div>
          <status-loading v-else></status-loading>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-3 my-2 my-lg-0">
        <div class="border p-3 d-flex align-items-center h-100">
          <div class="mx-4">
            <div class="border border-primary p-3 rounded-circle">
              <b-icon
                style="width: 20px; height: 20px;"
                icon="stack"
                variant="primary"
              ></b-icon>
            </div>
          </div>
          <div v-if="totalAnswerSheetsAmount" class="d-flex flex-column">
            <span class="detail-font font-weight-bold text-primary">{{
              totalAnswerSheetsAmount
            }}</span>
            <span class="topic-font">ครั้งที่สอบ</span>
          </div>
          <status-loading v-else></status-loading>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-3 my-2 my-lg-0">
        <div class="border p-3 d-flex align-items-center h-100">
          <div class="mx-4">
            <div class="border border-success p-3 rounded-circle">
              <b-icon
                style="width: 20px; height: 20px;"
                icon="stopwatch-fill"
                variant="success"
              ></b-icon>
            </div>
          </div>
          <div v-if="bestScoreAnswerSheet" class="d-flex flex-column">
            <span class="detail-font font-weight-bold text-success">{{
              bestScoreAnswerSheet.totalTimeUsed != null
                ? getTotalTimeUsed(bestScoreAnswerSheet.totalTimeUsed.toFixed(2))
                : getTotalTimeUsed(
                    calculateTimeUsed(bestScoreAnswerSheet.sections).toFixed(2)
                  )
            }}</span>
            <span class="topic-font">ในการทำข้อสอบที่ดีที่สุด</span>
          </div>
          <status-loading v-else></status-loading>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-3 my-2 my-lg-0">
        <div class="border p-3 d-flex align-items-center h-100">
          <div class="mx-4">
            <div class="border border-warning p-3 rounded-circle">
              <div style="width: 20px; height: 20px; display: flex; align-items: center; justify-content: center;">
                  <i class="bi bi-graph-up" style="font-size: 20px; color: #ffc107;"></i>
              </div>
            </div>
          </div>
          <div v-if="bestScoreAnswerSheet" class="d-flex flex-column">
            <span class="detail-font font-weight-bold text-warning">{{
              getLatestAnswerSheetStatus
            }}</span>
            <span class="topic-font">สถานะ</span>
          </div>
          <status-loading v-else></status-loading>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatusLoading from "./ExamStatusLoading.vue";
import TotalTimeUsedMixin from "../../mixins/totalTimeUsed";

export default {
  components: {
    StatusLoading
  },
  mixins: [TotalTimeUsedMixin],
  props: {
    bestScoreAnswerSheet: Object,
    totalAnswerSheetsAmount: Number
  },
  computed: {
    getLatestAnswerSheetStatus() {
      return this.bestScoreAnswerSheet.overallResult.grade === "F"
        ? "สอบตก"
        : "สอบผ่าน";
    }
  },
  methods: {
    calculateTimeUsed(sections) {
      var time = 0;
      sections.forEach((i) => {
        i.answers.forEach((j) => {
          if (j.timeUsed != null) {
            time = time + j.timeUsed;
          }
        });
      });
      return time;
    },
  }
};
</script>

<style scoped>
.detail-font {
  font-size: 16px;
}
.topic-font {
  font-size: 12px;
}
</style>

<template>
  <div>
    <label class="font-weight-bold mb-0" for="inst-radio-group"
      >ตั้งค่ากล้อง</label
    >

    <b-row class="mt-0">
      <b-col>
        <b-form-group
          id="inst-radio-group"
          label="ผู้สอน"
          label-for="inst-radio"
          label-cols-md="2"
        >
          <b-form-radio-group
            id="inst-radio"
            v-model="isInstructorCam"
            :options="radioOptions.instStud"
            value-field="item"
            text-field="name"
            class="mt-2"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="stud-radio-group"
          label="ผู้เรียน"
          label-for="inst-radio"
          label-cols-md="2"
        >
          <b-form-radio-group
            id="stud-radio"
            v-model="isStudentCam"
            :options="radioOptions.instStud"
            value-field="item"
            text-field="name"
            class="mt-2"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- <label class="font-weight-bold" for="calendar-radio">เพิ่มเข้าปฏิทินส่วนตัว</label>
    <b-form-radio-group
      id="calendar-radio"
      v-model="calendar"
      :options="radioOptions.calendar"
      value-field="item"
      text-field="name"
    ></b-form-radio-group>
    <hr /> -->
    <!-- <label class="font-weight-bold mt-3" for="rec-radio"
      >บันทึกวิดิโออัตโนมัติ</label
    >
    <b-form-radio-group
      id="rec-radio"
      v-model="record"
      :options="radioOptions.record"
      value-field="item"
      text-field="name"
    ></b-form-radio-group>
    <hr /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FormSettings",
  data() {
    return {
      radioOptions: {
        instStud: [
          { item: "1", name: "เปิด" },
          { item: "0", name: "ปิด" }
        ],
        calendar: [
          { item: "i-cal", name: "iCal" },
          { item: "google-cal", name: "Google Calendar" },
          { item: "outlook", name: "Outlook" },
          { item: "others", name: "อื่นๆ" }
        ],
        record: [
          { item: "1", name: "Cloud" },
          { item: "0", name: "บันทึกลงเครื่อง" }
        ]
      }
    };
  },
  computed: {
    isInstructorCam: {
      get() {
        return this.getIsInstructorCam();
      },
      set(newBool) {
        return this.setIsInstructorCam(newBool);
      }
    },
    isStudentCam: {
      get() {
        return this.getIsStudentCam();
      },
      set(newBool) {
        return this.setIsStudentCam(newBool);
      }
    },
    calendar: {
      get() {
        return this.getCalendar();
      },
      set(newCal) {
        return this.setCalendar(newCal);
      }
    },
    record: {
      get() {
        return this.getRecord();
      },
      set(newRec) {
        return this.setRecord(newRec);
      }
    }
  },
  methods: {
    ...mapActions("LiveForm", [
      "setIsInstructorCam",
      "setIsStudentCam",
      "setCalendar",
      "setRecord"
    ]),

    ...mapGetters("LiveForm", [
      "getIsInstructorCam",
      "getIsStudentCam",
      "getCalendar",
      "getRecord"
    ])
  }
};
</script>

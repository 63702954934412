<template>
  <div>
    <div>
      <div class="my-3 d-flex justify-content-baseline align-items-center">
        <h6 class="mb-0 mr-3">เลือกผู้เรียนจำนวน {{ checkedCount }} คน</h6>
        <div>
          <b-dropdown text="เลือกตัวเลือก">
            <div v-for="(status, index) in statusList" :key="index">
              <b-dropdown-item @click="selectStudentstatus(status)">
                {{ status.text }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
      </div>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                class="align-middle"
                style="padding-left: 0.75rem !important; width: 200px;"
              >
                <div class="d-flex justify-content-baseline">
                  <b-form-checkbox
                    v-model="selectAllStudent"
                    @change="toggleAll"
                  >
                  </b-form-checkbox>
                  <img
                    @click="toggleSort('firstName')"
                    :src="srcImage"
                    alt="SVG Image"
                  />
                  ชื่อผู้เรียน
                </div>
              </th>
              <th
                class="align-middle"
                style="padding-left: 0.75rem !important; width: 200px;"
              >
                <img
                  @click="toggleSort('email')"
                  :src="srcImage"
                  alt="SVG Image"
                />
                อีเมล
              </th>
              <th class="text-center align-middle" style="width: 70px;">
                เข้าเรียน
              </th>
              <th class="text-center align-middle" style="width: 70px;">
                มาสาย/<br />
                กลับก่อน
              </th>
              <th class="text-center align-middle" style="width: 70px;">
                ขาดเรียน
              </th>
              <th class="text-center align-middle" style="width: 70px;">
                ลา
              </th>
              <th class="text-center align-middle" style="width: 100px;">
                จำนวนชั่วโมง
              </th>
            </tr>
          </thead>
          <tbody id="table-body">
            <tr v-for="(item, index) in classInfo" :key="index">
              <td>
                <b-form-checkbox
                  v-model="selected[item.cerId]"
                  :key="item.cerId"
                  @change="toggleSelectAllStudent"
                >
                  {{ item.firstName + " " + item.lastName }}
                </b-form-checkbox>
              </td>
              <td>{{ item.email }}</td>
              <td class="text-center">
                <b-form-radio
                  v-model="selectStatus[index]"
                  value="PRESENT"
                ></b-form-radio>
              </td>
              <td class="text-center">
                <b-form-radio
                  v-model="selectStatus[index]"
                  value="LATE"
                ></b-form-radio>
              </td>
              <td class="text-center">
                <b-form-radio
                  v-model="selectStatus[index]"
                  value="ABSENT"
                ></b-form-radio>
              </td>
              <td class="text-center">
                <b-form-radio
                  v-model="selectStatus[index]"
                  value="LEAVE"
                ></b-form-radio>
              </td>
              <td class="text-center">
                <b-form-input
                  id="time-input"
                  v-model="inputValues[index]"
                  class="no-time-icon"
                  type="text"
                  v-mask="['##:##']"
                  :disabled="selectStatus[index] !== 'LATE'"
                  @blur="updateDurationHours(index, $event)"
                  placeholder="--:--"
                  :class="{ 'is-invalid': hasError('time', index) || errors.includes(index) }"
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div class="submit-btn">
        <router-link
          :to="{ name: 'attendance.info', params: { courseId: courseId } }"
        >
          <b-button variant="link">ยกเลิก</b-button>
        </router-link>
        <b-button
          class="ml-2"
          variant="primary"
          :disabled="disableButtonSave"
          @click="onSave()"
          >บันทึก</b-button
        >
      </div>
    </div>
    <ConfirmSuccessModal
      :title="'เพิ่มข้อมูลการเข้าชั้นเรียนสำเร็จ'"
      :show="isShowSuccessModal"
      @success="success()"
    />
    <WarningActionModal
      :show="isShowWarningAction"
      :title="'ยืนยันการเปลี่ยนวันที่เข้าเรียนหรือไม่?'"
      :detail="
        `ต้องการบันทึกข้อมูลการเข้าชั้นเรียนของคอร์ส<br>“${courseName}”<br>ก่อนเปลี่ยนวันที่เข้าเรียนหรือไม่`
      "
      :actionName="'บันทึก'"
      :showConfirmModal="isShowConfirmChangeDateModal"
      :successTitle="'เพิ่มข้อมูลการเข้าชั้นเรียนสำเร็จ'"
      @canceled="handleCloseModal()"
      @action="actionSave()"
      @onSuccess="closeSuccessModal()"
    />
    <WarningActionModal
      :show="isConfirmStatus"
      :title="'ยืนยันสถานะการเข้าเรียนหรือไม่?'"
      :detail="
        `ต้องการเลือกสถานะ${studentStatus.text} ให้ผู้เรียนที่ถูกเลือกหรือไม่`
      "
      :actionName="'ยืนยัน'"
      :showConfirmModal="isShowConfirmStatusModal"
      :successTitle="'ยืนยันสถานะการเข้าเรียนสำเร็จ'"
      @canceled="isConfirmStatus = false"
      @action="addStudentstatus()"
      @onSuccess="isShowConfirmStatusModal = false"
    />
    <WarningActionModal
      :show="isDataIncomplete"
      :title="'ยืนยันการบันทึกข้อมูลหรือไม่?'"
      :detail="formattedDetail"
      :actionName="'ยืนยัน'"
      :showConfirmModal="isShowConfirmModal"
      :successTitle="'ยืนยันสถานะการเข้าเรียนสำเร็จ'"
      @canceled="isDataIncomplete = false"
      @action="saveDataIncomplete()"
      @onSuccess="success()"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import ConfirmSuccessModal from "../modal/ConfirmSuccessModal.vue";
import WarningActionModal from "../modal/WarningActionAttendanceModal.vue";
import {mask} from 'vue-the-mask';

export default {
  name: "editClassAttendance",
  components: { ConfirmSuccessModal, WarningActionModal },
  directives:{mask},
  props: [
    "classInfo",
    "courseId",
    "classId",
    "startTime",
    "finishTime",
    "durationTime",
    "durationFormat",
    "classList",
    "attendDate",
    "oldAttendDate",
    "courseName",
    "isDateChange",
    "oldStartTime",
    "oldEndTime",
  ],
  data() {
    return {
      errors: [],
      currentRouteName: this.$route.name,
      selected: {},
      selectStatus: [],
      studentStatus: {},
      isShowSuccessModal: false,
      isConfirmStatus: false,
      isShowConfirmStatusModal: false,
      isShowConfirmModal: false,
      isShowConfirmChangeDateModal: false,
      isDataIncomplete: false,
      selectAllStudent: false,
      isShowWarningAction: false,
      statusList: [
        { text: "เข้าเรียน", value: "PRESENT" },
        { text: "มาสาย", value: "LATE" },
        { text: "ขาดเรียน", value: "ABSENT" },
        { text: "ลา", value: "LEAVE" },
      ],
      srcImage:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e",
      sortBy: "",
      sortDesc: false,
      disableButtonSave: false,
      duration: [],
    };
  },
  watch: {
    isDateChange(newValue) {
      if (newValue && this.radioActiveCount > 0) {
        this.isShowWarningAction = true;
      }
    },
    selectStatus: {
      handler(newSelectStatus) {
        this.duration = this.calculateDurations(newSelectStatus);
      },
      deep: true, // Watch for changes in nested arrays/objects
    },
    sortBy: "sortData",
    sortDesc: "sortData",
  },
  computed: {
    checkedCount() {
      // Use Object.values to get an array of checkbox values (true/false).
      const checkboxValues = Object.values(this.selected);
      const checkedCount = checkboxValues.filter((value) => value === true)
        .length;
      return checkedCount;
    },
    totalHours() {
      const result = [];
      // Loop through each row's selectStatus
      for (let i = 0; i < this.classInfo.length; i++) {
        if (this.selectStatus[i] === "PRESENT") {
          result.push(this.durationTime);
        } else if (this.selectStatus[i] === "LATE") {
          const [courseHours, courseMinutes] = this.durationTime.split(":");
          const durationTime = Number(courseHours) * 3600 + Number(courseMinutes) * 60;
          
          const [hours, minutes] = this.duration[i].split(":");
          const totalMinutes = Number(hours) * 3600 + Number(minutes) * 60;
          
          if (totalMinutes == durationTime || totalMinutes == 0){
            const [hh1, mm1] = this.startTime.split(":").map(Number);
            const [hh2, mm2] = this.finishTime.split(":").map(Number);

            const convertHour1 = hh1 * 60;
            const convertHour2 = hh2 * 60;
            const minutes = convertHour2 + mm2 - (convertHour1 + mm1) - 30;

            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;

            const hoursStr = hours.toString().padStart(2, "0");
            const minutesStr = remainingMinutes.toString().padStart(2, "0");
            result.push(`${hoursStr}:${minutesStr}`);

          } else {
            result.push(this.duration[i]);
          }
          
        } else {
          result.push("00:00");
        }
      }
      return result;
    },
    inputValues() {
      // Calculate and return input values based on the route and index
      return this.classInfo.map((item, index) => {
        if (this.currentRouteName === "attendance.add") {
          return this.totalHours[index];
        } else if (this.currentRouteName === "class.attendance.info") {
          return this.duration[index];
        }
        return "";
      });
    },
    radioActiveCount() {
      const count = this.selectStatus.filter((value) => value !== null).length;
      return count;
    },
    formattedDetail() {
      return `
        กรอกรายละเอียดการเข้าเรียนของผู้เรียน
        <span style="color: #FF8800;">ไม่ครบจำนวน</span>
        <br>
        ต้องการบันทึกข้อมูลการเข้าชั้นเรียนของคอร์ส หรือไม่
      `;
    },
  },
  mounted() {
    // Initialize selectStatus based on clsa_status values in items.
    if (this.currentRouteName === "class.attendance.info") {
      this.selectStatus = this.classInfo.map((item) => item.clsa_status);
      this.duration = this.classInfo.map((item) =>
        this.formatClassDuration(item.clsa_duration)
      );
    }
  },
  methods: {
    ...mapActions("AttendanceManagement", [
      "updateClassAttendanceInfo",
      "insertClassAttendance",
    ]),
    updateDurationHours(index, newTimeValue) {
      var time = null;
      if (this.currentRouteName === "attendance.add") {
        time = this.durationFormat;
      } else if (this.currentRouteName === "class.attendance.info") {
        time = this.classInfo[index].cls_duration;
      }

      const [hours, minutes] = newTimeValue.target._value.split(":");
      const totalMinutes = Number(hours) * 3600 + Number(minutes) * 60;

      if (!this.isValidTime(newTimeValue.target._value) || totalMinutes > time) {
        if (!this.errors.includes(index)) {
          this.errors.push(index);
        }
      } else {
        this.errors = this.errors.filter((errorIndex) => errorIndex !== index);

        if (this.currentRouteName === "attendance.add") {
          this.totalHours[index] = newTimeValue.target._value;
          this.duration[index] = newTimeValue.target._value;
        } else if (this.currentRouteName === "class.attendance.info") {
          this.duration[index] = newTimeValue.target._value;
        }
      }
    },
    hasError(type, index) {
      if (type === 'time') {
        return !this.isValidTime(this.inputValues[index]);
      }
      return false;
    },
    isValidTime(time) {
      const regex = /^(0\d|1\d|2[0-3]):([0-5]\d)$/;
      return regex.test(time);
    },
    hasErrors() {
      return this.errors.length > 0;
    },
    calculateDurations(selectStatus) {
      const result = [];
      // Loop through each row's selectStatus
      for (let i = 0; i < this.classInfo.length; i++) {
        if (selectStatus[i] === "PRESENT") {
          result.push(this.formatClassDuration(this.classInfo[i].cls_duration));
          this.errors = this.errors.filter((errorIndex) => errorIndex !== i);

        } else if (selectStatus[i] === "LATE") {
          const duration = this.classInfo[i].cls_duration;
          const now = this.inputValues[i];


          const lateTime = this.classInfo[i].cls_duration - 30 * 60;

          const [hours, minutes] = now.split(":");
          const totalMinutes = Number(hours) * 3600 + Number(minutes) * 60; 

          if(totalMinutes == duration || totalMinutes == 0 ){
            result.push(this.formatClassDuration(lateTime));
          } else {
            result.push(this.formatClassDuration(totalMinutes));
          }

        } else {
          result.push("00:00");
          this.errors = this.errors.filter((errorIndex) => errorIndex !== i);
        }
      }
      return result;
    },
    toggleSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc; // Toggle sort direction
      } else {
        this.sortBy = column; // Set the new column to sort by
        this.sortDesc = false; // Default to ascending order
      }
    },
    // Function to sort the data array
    sortData() {
      const column = this.sortBy;
      const desc = this.sortDesc ? -1 : 1;

      // Use JavaScript's Array.sort() method to sort the data
      this.classInfo.sort((a, b) => {
        const aValue = a[column];
        const bValue = b[column];

        return aValue.localeCompare(bValue) * desc;
      });
    },
    formatClassDuration(duration) {
      if (duration > 0) {
        const minutes = duration / 60;

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const hoursStr = hours.toString().padStart(2, "0");
        const minutesStr = remainingMinutes.toString().padStart(2, "0");

        return `${hoursStr}:${minutesStr}`;
      }
      return "00:00";
    },
    toggleAll() {
      if (this.selectAllStudent) {
        // If selectAllStudent is true, set all items to true
        this.classInfo.forEach((item) => {
          Vue.set(this.selected, item.cerId, true);
        });
      } else {
        // If selectAllStudent is false, set all items to false
        this.classInfo.forEach((item) => {
          Vue.set(this.selected, item.cerId, false);
        });
      }
    },
    addStudentstatus() {
      this.classInfo.forEach((item, index) => {
        if (this.selected[item.cerId]) {
          Vue.set(this.selectStatus, index, this.studentStatus.value);
          this.selected[item.cerId] = false;

          if (this.selectAllStudent === true) {
            this.selectAllStudent = false;
          }
        }
      });
      this.isConfirmStatus = false;
      this.isShowConfirmStatusModal = true;
    },
    toggleSelectAllStudent() {
      const itemLength = this.classInfo.length;
      const checkboxLength = Object.keys(this.selected).length;
      const allChecked = Object.values(this.selected).every((value) => value);

      if (itemLength === checkboxLength && allChecked) {
        this.selectAllStudent = true;
      } else {
        this.selectAllStudent = false;
      }
    },
    async editClassAttention() {
      let request = {
        classId: this.classId,
        body: {
          class_attendance: [],
        },
      };
      this.classInfo.forEach((item, index) => {
        const cerId = item.cerId;
        const classAttendanceStatus = this.selectStatus[index];

        const [hours, minutes] = this.duration[index].split(":");
        const classAttendanceDuration =
          Number(hours) * 3600 + Number(minutes) * 60;

        const classAttendance = {
          cer_id: cerId,
          class_attendance_status: classAttendanceStatus
            ? classAttendanceStatus
            : "",
          class_attendance_duration: classAttendanceDuration
            ? classAttendanceDuration
            : null,
        };

        request.body.class_attendance.push(classAttendance);
      });
      await this.updateClassAttendanceInfo(request);
    },
    async saveClassAttention(attendDate, startTime, finishTime) {
      let request = {
        c_id: this.courseId,
        class_name: this.courseId + "Class" + this.classList.length + 1,
        class_date: attendDate,
        class_start_time: startTime,
        class_finish_time: finishTime,
        class_duration: this.durationFormat,
        class_attendance: [],
      };

      this.classInfo.forEach((item, index) => {
        const cerId = item.cerId;
        const classAttendanceStatus = this.selectStatus[index];

        const [hours, minutes] = this.totalHours[index].split(":");
        const classAttendanceDuration =
          Number(hours) * 3600 + Number(minutes) * 60;

        const classAttendance = {
          cer_id: cerId,
          class_attendance_status: classAttendanceStatus
            ? classAttendanceStatus
            : "",
          class_attendance_duration: classAttendanceDuration
            ? classAttendanceDuration
            : null,
        };

        request.class_attendance.push(classAttendance);
      });

      await this.insertClassAttendance(request);
    },
    async onSave() {
      this.disableButtonSave = true;
      if (this.hasErrors()) {
        this.disableButtonSave = false;
      } else {
        if (this.currentRouteName === "attendance.add") {
          if (this.classInfo.length !== this.radioActiveCount) {
            this.isDataIncomplete = true;
          } else {
            await this.saveClassAttention(
              this.attendDate,
              this.startTime,
              this.finishTime
            );
            this.isShowSuccessModal = true;
          }
        } else if (this.currentRouteName === "class.attendance.info") {
          await this.editClassAttention();
          this.isShowSuccessModal = true;
        }
      }
    },
    selectStudentstatus(status) {
      this.studentStatus = status;
      this.isConfirmStatus = true;
    },
    success() {
      this.$router.push({
        name: "attendance.info",
        params: { courseId: this.courseId },
      });
    },
    async actionSave() {
      if (this.isDateChange.includes("attendDate")) {
        await this.saveClassAttention(
          this.oldAttendDate,
          this.startTime,
          this.finishTime
        );
      } else if (this.isDateChange.includes("startTime")) {
        await this.saveClassAttention(
          this.attendDate,
          this.oldStartTime,
          this.finishTime
        );
      } else if (this.isDateChange.includes("endTime")) {
        await this.saveClassAttention(
          this.attendDate,
          this.startTime,
          this.oldEndTime
        );
      }
      this.isShowWarningAction = false;
      this.isShowConfirmChangeDateModal = true;
    },
    async saveDataIncomplete() {
      await this.saveClassAttention(
        this.attendDate,
        this.startTime,
        this.finishTime
      );
      this.isDataIncomplete = false;
      this.isShowConfirmModal = true;
    },
    handleCloseModal() {
      this.$emit("cancelModal", this.isDateChange);
      this.isShowWarningAction = false;
    },
    closeSuccessModal() {
      this.selectStatus = [];
      this.isShowConfirmChangeDateModal = false;
    },
  },
};
</script>

<style scoped>
.table th {
  padding: 0.1rem !important;
}
.table tbody td {
  vertical-align: middle;
  padding: 0.4rem 0.75rem 0.4rem !important;
}
.submit-btn {
  display: flex;
  justify-content: flex-end;
}

.no-time-icon::-webkit-calendar-picker-indicator {
  display: none;
}
</style>

<template>
  <div>
    <b-form-group
      class="mb-0"
      :label="label"
      label-for="textarea-formatter"
      :description="description"
      v-if="onEditing"
    >
      <b-form-textarea
        id="textarea-formatter"
        v-model="input"
        :placeholder="placeholder"
        @keyup.enter="edited"
        @keydown.esc="onEditing = false"
      ></b-form-textarea>
    </b-form-group>
    <div v-else>
      <label class="d-block">
        {{ label }}
        <b-icon-pencil
          variant="secondary"
          v-if="isEditable"
          @click="editing"
          scale="1.2"
          class="float-right cursor-pointer"
        />
      </label>
      <b-form-textarea disabled :value="value"></b-form-textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextareaForm",
  props: {
    name: {
      type: String,
      default: "textarea"
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    isEditable: { type: Boolean, default: true }
  },
  data() {
    return {
      input: null,
      onEditing: false
    };
  },
  mounted() {
    if (this.value != "") this.onEditing = false;
    this.input = this.value;
  },
  methods: {
    editing() {
      this.onEditing = true;
      this.input = this.value;
    },

    edited() {
      this.onEditing = false;
      this.$emit("edited", { name: this.name, value: this.input });
    }
  }
};
</script>

<style lang="css">
.cursor-pointer {
  cursor: pointer;
}
</style>

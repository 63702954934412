<template>
  <ul :class="collapseCssObj">
    <!-- <li v-for="ech in collapseList" :key="ech.title" :class="getCollapseListIsActive(ech.to.name)">
            <collapse-list-item :collapse-name="ech.title" :path="ech.to" :icon="ech.iconClass"/> -->
    <li
      v-for="ech in collapseList"
      :key="ech.title"
      :class="getCollapseListIsActive(ech)"
    >
      <collapse-list-item
        :collapse-name="ech.title"
        :path="ech.to"
        :icon="ech.iconClass"
      />
    </li>
  </ul>
</template>

<script>
import CollapseListItem from "@/components/layouts/sidebars/collapse-menu/CollapseListItem.vue";
export default {
  components: {
    CollapseListItem
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    collapseList: {
      type: Array,
      required: true
    }
  },
  computed: {
    collapseCssObj() {
      return {
        show: this.isActive
      };
    }
  },
  methods: {
    getCollapseListIsActive(eachSidebarRoute) {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      if (useModuleMetaMatcher) {
        return {
          active:
            !!eachSidebarRoute.meta &&
            !!eachSidebarRoute.meta.module &&
            this.$route.meta.module === eachSidebarRoute.meta.module
        };
      }
      return {
        active: this.$route.name === eachSidebarRoute.to.name
      };
    }
  }
};
</script>

<template>
  <div v-if="preqLec != null">
    <h5 class="d-block mt-5">
      <b-form-checkbox
        switch
        class="d-inline"
        v-model="preqLec"
        @input="update"
      />
      การเข้าเรียนบทถัดไป
      <b-spinner v-if="updating"></b-spinner>
    </h5>
    <div style="margin-left: 4em">
      <p>
        ผู้เรียนจะต้องดูวิดีโอ อ่านเนื้อหา และทำข้อสอบ
        ที่อยู่ในบทเรียนครบตามกำหนด จึงจะสามารถเข้าเรียนบทเรียนถัดไปได้
      </p>
    </div>
  </div>
  <div v-else>
    <b-spinner class="align-middle mr-2"></b-spinner>
    <strong>Loading...</strong>
  </div>
</template>

<script>
import api from "@/services/api.js";
export default {
  props: ["cId"],
  data() {
    return {
      preqLec: null,
      updating: null
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await api.get(`/v1/courses/${this.cId}?temp=true`).then(({ data }) => {
        this.preqLec = data.data.preqLec
          ? data.data.preqLec == 1
            ? true
            : false
          : false;
      });
    },
    async update() {
      this.updating = true;
      await api
        .put(`/v1/courses/${this.cId}/attribute`, {
          name: "c_prerequisiteLectureFlag",
          value: this.preqLec
        })
        .then(() => {
          this.updating = false;
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<template>
  <div class="container">
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:amcharts="http://amcharts.com/ammap"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              viewBox="0 0 600 1100"
              width="100%"
              height="100%"
              id="map"
              class="wrapper"
            >
              <defs>
                <amcharts:ammap
                  projection="mercator"
                  leftLongitude="97.344728"
                  topLatitude="20.463430"
                  rightLongitude="105.640023"
                  bottomLatitude="5.614417"
                />

                <!-- All areas are listed in the line below. You can use this list in your script. -->
                <!--{id:"TH-10"},{id:"TH-11"},{id:"TH-12"},{id:"TH-13"},{id:"TH-14"},{id:"TH-15"},{id:"TH-16"},{id:"TH-17"},{id:"TH-18"},{id:"TH-19"},{id:"TH-20"},{id:"TH-21"},{id:"TH-22"},{id:"TH-23"},{id:"TH-24"},{id:"TH-25"},{id:"TH-26"},{id:"TH-27"},{id:"TH-30"},{id:"TH-31"},{id:"TH-32"},{id:"TH-33"},{id:"TH-34"},{id:"TH-35"},{id:"TH-36"},{id:"TH-37"},{id:"TH-38"},{id:"TH-39"},{id:"TH-40"},{id:"TH-41"},{id:"TH-42"},{id:"TH-43"},{id:"TH-44"},{id:"TH-45"},{id:"TH-46"},{id:"TH-47"},{id:"TH-48"},{id:"TH-49"},{id:"TH-50"},{id:"TH-51"},{id:"TH-52"},{id:"TH-53"},{id:"TH-54"},{id:"TH-55"},{id:"TH-56"},{id:"TH-57"},{id:"TH-58"},{id:"TH-60"},{id:"TH-61"},{id:"TH-62"},{id:"TH-63"},{id:"TH-64"},{id:"TH-65"},{id:"TH-66"},{id:"TH-67"},{id:"TH-70"},{id:"TH-71"},{id:"TH-72"},{id:"TH-73"},{id:"TH-74"},{id:"TH-75"},{id:"TH-76"},{id:"TH-77"},{id:"TH-80"},{id:"TH-81"},{id:"TH-82"},{id:"TH-83"},{id:"TH-84"},{id:"TH-85"},{id:"TH-86"},{id:"TH-90"},{id:"TH-91"},{id:"TH-92"},{id:"TH-93"},{id:"TH-94"},{id:"TH-95"},{id:"TH-96"},{id:"TH-LKSG"}-->
              </defs>
              <g>
                <div>test</div>
                <path
                  v-for="(data, i) in mapDatas"
                  :key="i"
                  :id="data.id"
                  :title="data.title"
                  :class="getProviceColorStyle(i)"
                  :d="data.d"
                >
                  <b-tooltip :target="data.id" triggers="hover">
                    {{ data.title }}
                  </b-tooltip>
                </path>
              </g>
            </svg>
          </div>
        </b-col>
        <b-col>
          <b-list-group v-for="(data, i) in dataActive" :key="i" hidden>
            <b-list-group-item button>
              <b-container>
                <b-row align-h="between">
                  <b-col>{{ data.title }}</b-col>
                  <b-col>{{ data.viewer }}</b-col>
                </b-row>
              </b-container>
              <div></div>
            </b-list-group-item>
          </b-list-group>

          <div>
            <b-table
              table-class="tbNumber text-left "
              :items="getTopTwentyActiveProvinces"
              :fields="fields"
              style="overflow-y: auto; max-height: 100%;"
              small
              sort-icon-left
              responsive
              striped
              hover
            >
              <template v-slot:cell(province)="data" style="width: 70%;">{{
                data.item.title
              }}</template>

              <template v-slot:cell(user)="data" style="width: 30%;">{{
                data.item.viewer
              }}</template>

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import thailandMap from './thailandDataMap.js';
import api from '@/services/api.js';

export default {
  data() {
    return {
      activeProvinces: [],
      fields: [
        { key: 'province', label: 'จังหวัด' },
        { key: 'user', label: 'จํานวน' },
      ],
      hover: false,
      mapDatas: [],
      user: {
        ip: '',
        address: { country: '', country_code: '', city: '', region_code: '' },
      },
      company: {},
      maximumViewer: 0,
    };
  },
  computed: {
    dataActive() {
      let result = this.mapDatas.filter((data) => {
        return data.viewer > 0;
      });
      return result;
    },
    getTopTwentyActiveProvinces() {
      let activeProvinces = this.activeProvinces;
      return activeProvinces
        .sort((a, b) => {
          if (a.viewer > b.viewer) {
            return -1;
          }
          if (a.viewer < b.viewer) {
            return 1;
          }
          return 0;
        })
        .slice(0, 20);
    },
  },
  created() {
    this.setCompanyInfo();
    this.setMapDatas();
  },
  methods: {
    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    async setMapDatas() {
      try {
        // initialize the thailand map
        this.mapDatas = thailandMap.data;
        // fetch provinces from database
        let provinceStats = await api.get(
          '/v1/analytics/companies/province-stats'
        );
        let lastestProvinceStats = provinceStats.data.data;
        // update the viewer
        for (let mapData of this.mapDatas) {
          for (let statData of lastestProvinceStats) {
            if (mapData.title === statData.province) {
              mapData.viewer = statData.amount;
            }
          }
        }
        // filter provinces which viewer > 0
        this.activeProvinces = this.mapDatas.filter((el) => el.viewer > 0);
        // find maximum viewer
        for (let mapData of this.mapDatas) {
          if (mapData.viewer > this.maximumViewer) {
            this.maximumViewer = mapData.viewer;
          }
        }
      } catch (err) {
        console.log('setMapDatas error!', err);
      }
    },
    getProviceColorStyle(index) {
      let currentProvinceViewer = this.mapDatas[index].viewer;
      if (currentProvinceViewer >= this.maximumViewer * 0.75) {
        return 'land-extreme-active';
      } else if (currentProvinceViewer >= this.maximumViewer * 0.5) {
        return 'land-high-active';
      } else if (currentProvinceViewer >= this.maximumViewer * 0.25) {
        return 'land-medium-active';
      } else if (currentProvinceViewer >= 1) {
        return 'land-low-active';
      } else {
        return 'land';
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: block;

  margin: auto;
}
.land-active {
  fill: #e78c06;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 0.5;
}
.land-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
/*  */
.land-extreme-active {
  fill: #eb8123;
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-extreme-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-high-active {
  fill: #eb8123;
  fill-opacity: 0.75;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-high-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-medium-active {
  fill: #eb8123;
  fill-opacity: 0.5;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-medium-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land-low-active {
  fill: #eb8123;
  fill-opacity: 0.25;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land-low-active:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
.land {
  fill: #cccccc;
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.land:hover {
  fill: #ffbe4f;
  stroke-width: 2;
  stroke: white;
  stroke-opacity: 1;
}
/*  */
.water {
  stroke-width: 0.5;
}
svg {
  transition: 200ms;
  transition-timing-function: ease-in-out;
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
}
</style>

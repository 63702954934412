<template>
  <div class="border">
    <div style="padding:1rem">
      <PageHeader
        :title="`รายละเอียดผู้เรียนในคอร์ส ${courseInfo.courseName}`"
      ></PageHeader>
      <b-row>
        <b-col cols="12">
          <stdInfo
            :apiRoute="
              `/v1/analytics/courses/getStudentInfo?cId=${cId}&template=A`
            "
            :perPage="20"
            :tableActionPath="`/${companyInfo.url}/report/learning-history-report/enrolls/`"
          ></stdInfo>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import stdInfo from './panel/CourseStudentInfoPanel';
import moment from 'moment';
import PageHeader from '@/components/layouts/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      cId: this.$route.params.cId,
      companyInfo: this.$store.state.Company.companyInfo,
    };
  },
  components: {
    PageHeader,
    stdInfo,
  },
  computed: {
    ...mapGetters('CourseManagement', ['isLoading', 'courseInfo']),
  },
  async created() {
    await this.getCourseInfo({
      courseId: this.cId,
      temp: true,
      excepts: 'numberOfStudent,numberOfActiveStudent',
    });
  },
  methods: {
    ...mapActions('CourseManagement', ['getCourseInfo']),
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <!-- <AnswerSheetListHeader /> -->
    <AnswerSheetListContent />
  </div>
</template>

<script>
// import AnswerSheetListHeader from "@/components/pages/exam-analytics/answer-sheet-list/AnswerSheetListHeader.vue";
import AnswerSheetListContent from "@/components/pages/exam-analytics/answer-sheet-list/AnswerSheetListContent.vue";

export default {
  components: {
    // AnswerSheetListHeader,
    AnswerSheetListContent
  }
};
</script>

<template>
  <div>
    <b-row v-if="processSuccessCount == 4">
      <b-col v-show="false">
        <span class="small text-success">
          <b-icon-check scale="1.2" class="mt-2 ml-1" />

          อัปโหลดสำเร็จ
          <b-icon-camera-video scale="1.2" class="mt-2 ml-1" />
        </span>
      </b-col>
    </b-row>
    <b-row v-else-if="errorMessage">
      <b-col>
        <span class="small text-danger">{{ errorMessage }}</span>
      </b-col>
    </b-row>
    <b-row v-else no-gutters align-v="center">
      <b-col>
        <span class="small text-warning">
          <b-spinner variant="warning" small label="Loading..."></b-spinner>
          กำลังประมวลผล
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LectureVideoProcess",
  props: {
    company: { default: null, type: Object },
    lectureId: {
      type: Number,
      default: null
    },
    uploading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processSuccessCount: 0,
      errorMessage: "",
      status: null
    };
  },

  async created() {
    await this._getVideoProcessStatus();
  },

  watch: {
    processSuccessCount: function(newValue) {
      if (this.status && newValue < 4) {
        let refreshId = setInterval(() => {
          this._getVideoProcessStatus();
          if (this.processSuccessCount >= 4 || this.errorMessage != "") {
            clearInterval(refreshId);
          }
        }, 10000);
      } else {
        this.$emit("processed", true);
      }
    }
  },

  methods: {
    ...mapActions("CourseManagement", ["getVideoProcessStatus"]),
    async _getVideoProcessStatus() {
      await this.getVideoProcessStatus({
        lecId: this.lectureId,
        cId: this.$route.params.courseId,
        comId: this.company.id
      })
        .then(response => {
          if (response.data.result) {
            let completedCount = 0;
            this.status = response.data.result[0];
            if (this.status) {
              if (
                this.status.vid_uploadStatus == "COMPLETED" ||
                this.status.vid_hlsStatus === null
              ) {
                completedCount += 1;
              }

              if (
                this.status.vid_hlsStatus == "COMPLETED" ||
                this.status.vid_hlsStatus === null
              ) {
                completedCount += 1;
              } else if (this.status.vid_hlsStatus == "FAILED") {
                this.errorMessage =
                  "อัปโหลดวิดีโอไม่สำเร็จ! กรุณาอัปโหลดใหม่อีกครั้ง.";
              }

              if (
                this.status.vid_dashStatus == "COMPLETED" ||
                this.status.vid_hlsStatus === null
              ) {
                completedCount += 1;
              } else if (
                this.status.vid_dashStatus == "FAILED" ||
                this.status.vid_dashStatus == "ENCRYPTION FAILED"
              ) {
                this.errorMessage =
                  "มีข้อผิดพลาดขณะที่ประมวลวิดีโอแบบ Dash, กรุณาติดต่อผู้ดูแลระบบ";
              }

              if (
                this.status.vid_fpsStatus == "COMPLETED" ||
                this.status.vid_hlsStatus === null
              ) {
                completedCount += 1;
              } else if (this.status.vid_fpsStatus == "FAILED") {
                this.errorMessage =
                  "มีข้อผิดพลาดขณะที่ประมวลวิดีโอแบบ FPS, กรุณาติดต่อผู้ดูแลระบบ";
              }
            } else {
              completedCount = 4;
            }
            this.processSuccessCount = completedCount;
          }
        })
        .catch(error => {
          if (error.response.data && error.response.status === 403)
            this.errorMessage = "ไม่มีสิทธิ์ดูข้อมูลวิดีโอ";
          else this.errorMessage = error;
        });
    }
  }
};
</script>
